import { useQuery, QueryObserverOptions } from 'react-query';

import getCTokenBalanceOf, {
  GetCTokenBalanceOfOutput,
  IGetCTokenBalanceOfInput,
} from 'clients/api/queries/getCTokenBalanceOf';
import { useCTokenContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import { CTokenId } from 'types';

type Options = QueryObserverOptions<
  GetCTokenBalanceOfOutput,
  Error,
  GetCTokenBalanceOfOutput,
  GetCTokenBalanceOfOutput,
  [FunctionKey.GET_V_TOKEN_BALANCE, CTokenId]
>;

const useGetCTokenBalanceOf = (
  { account, cTokenId }: Omit<IGetCTokenBalanceOfInput, 'tokenContract'> & { cTokenId: CTokenId },
  options?: Options,
) => {
  const tokenContract = useCTokenContract(cTokenId as CTokenId);
  return useQuery(
    [FunctionKey.GET_V_TOKEN_BALANCE, cTokenId],
    () => getCTokenBalanceOf({ tokenContract, account }),
    options,
  );
};

export default useGetCTokenBalanceOf;
