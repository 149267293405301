import BigNumber from 'bignumber.js';

const calculateNetApy = ({
  supplyBalanceCents,
  yearlyBdammEarningsCents,
  borrowBalanceCents,
}: {
  supplyBalanceCents: BigNumber;
  yearlyBdammEarningsCents: BigNumber;
  borrowBalanceCents: BigNumber;
}) => {
  // Calculate net APY as a percentage of supply balance, based on yearly interests
  let netApyPercentage: number | undefined;
  if (supplyBalanceCents?.isEqualTo(0)) {
    netApyPercentage = 0;
  } else if (supplyBalanceCents && yearlyBdammEarningsCents.isGreaterThan(0)) {
    netApyPercentage = +yearlyBdammEarningsCents
      .multipliedBy(100)
      .dividedBy(supplyBalanceCents.minus(borrowBalanceCents))
      .toFixed(2);
  } else if (supplyBalanceCents && yearlyBdammEarningsCents.isLessThan(0)) {
    netApyPercentage = +yearlyBdammEarningsCents
      .multipliedBy(100)
      .dividedBy(borrowBalanceCents.minus(supplyBalanceCents))
      .toFixed(2);
  }
  return netApyPercentage;
};

export default calculateNetApy;
