import * as React from 'react';
import { SVGProps } from 'react';

const SvgWbtc = (props: SVGProps<SVGSVGElement>) => (
  <svg width="247" height="250" viewBox="0 0 247 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse cx="124.892" cy="125" rx="121.846" ry="120" fill="#D9D9D9" />
    <path d="M203.849 52.4668L196.984 59.3312C213.364 77.2433 222.447 100.637 222.447 124.909C222.447 149.181 213.364 172.574 196.984 190.486L203.849 197.351C222.064 177.592 232.178 151.702 232.178 124.829C232.178 97.9547 222.064 72.065 203.849 52.3066V52.4668Z" fill="#5A5564" />
    <path d="M59.4903 53.0614C77.4025 36.682 100.796 27.5987 125.068 27.5987C149.34 27.5987 172.733 36.682 190.645 53.0614L197.51 46.1971C177.751 27.9814 151.862 17.8682 124.988 17.8682C98.1139 17.8682 72.2242 27.9814 52.4658 46.1971L59.4903 53.0614Z" fill="#5A5564" />
    <path d="M53.0614 190.555C36.7014 172.647 27.63 149.268 27.63 125.012C27.63 100.756 36.7014 77.377 53.0614 59.4689L46.1971 52.6045C27.9814 72.3628 17.8682 98.2526 17.8682 125.126C17.8682 152 27.9814 177.89 46.1971 197.648L53.0614 190.555Z" fill="#5A5564" />
    <path d="M190.556 196.893C172.643 213.272 149.25 222.355 124.978 222.355C100.706 222.355 77.3126 213.272 59.4005 196.893L52.5361 203.757C72.2945 221.973 98.1842 232.086 125.058 232.086C151.932 232.086 177.822 221.973 197.58 203.757L190.556 196.893Z" fill="#5A5564" />
    <path d="M168.338 102.096C166.965 87.7723 154.61 82.9673 138.982 81.5029V61.7793H126.9V81.1368C123.72 81.1368 120.471 81.1368 117.245 81.1368V61.7793H105.255V81.6402H80.749V94.5681C80.749 94.5681 89.6727 94.4079 89.5354 94.5681C91.1444 94.3911 92.7593 94.8456 94.0398 95.8358C95.3203 96.826 96.1664 98.2747 96.3998 99.8765V154.242C96.3654 154.807 96.2181 155.359 95.9665 155.866C95.715 156.373 95.3644 156.825 94.9354 157.194C94.5144 157.57 94.0217 157.857 93.487 158.038C92.9522 158.219 92.3864 158.29 91.8235 158.246C91.9837 158.384 83.0371 158.246 83.0371 158.246L80.749 172.684H105.026V192.866H117.107V172.982H126.763V192.774H138.867V172.822C159.277 171.586 173.509 166.552 175.294 147.447C176.736 132.07 169.505 125.206 157.95 122.437C164.975 118.982 169.322 112.576 168.338 102.096ZM151.406 145.067C151.406 160.077 125.688 158.361 117.496 158.361V131.727C125.688 131.75 151.406 129.393 151.406 145.067ZM145.8 107.542C145.8 121.27 124.338 119.6 117.519 119.6V95.3918C124.338 95.3918 145.8 93.2409 145.8 107.542Z" fill="#F09242" />
    <path d="M124.977 250C100.256 249.996 76.0921 242.661 55.5395 228.924C34.9869 215.187 18.9689 195.665 9.51073 172.825C0.0526186 149.985 -2.42087 124.854 2.40301 100.608C7.22689 76.3627 19.1315 54.0919 36.6117 36.6117C54.0919 19.1315 76.3626 7.22689 100.608 2.40301C124.854 -2.42087 149.985 0.0526186 172.825 9.51074C195.665 18.9689 215.187 34.9869 228.924 55.5395C242.661 76.0921 249.995 100.256 250 124.977C250.003 141.396 246.771 157.655 240.489 172.825C234.207 187.995 224.998 201.778 213.388 213.388C201.778 224.998 187.995 234.207 172.825 240.489C157.655 246.771 141.396 250.003 124.977 250V250ZM124.977 9.74745C102.202 9.76555 79.943 16.5352 61.0141 29.2007C42.0852 41.8663 27.336 59.8592 18.6307 80.9052C9.92541 101.951 7.65476 125.106 12.1058 147.442C16.5568 169.778 27.5297 190.293 43.6375 206.395C59.7453 222.496 80.265 233.461 102.603 237.903C124.941 242.345 148.094 240.066 169.137 231.352C190.18 222.638 208.167 207.882 220.825 188.948C233.483 170.014 240.244 147.753 240.253 124.977C240.259 109.839 237.281 94.849 231.489 80.863C225.698 66.877 217.206 54.1697 206.5 43.4678C195.794 32.766 183.083 24.2794 169.095 18.4934C155.107 12.7074 140.115 9.73542 124.977 9.74745V9.74745Z" fill="#282138" />
  </svg>


);

export default SvgWbtc;
