import BigNumber from 'bignumber.js';
import { Comptroller } from 'types/contracts';

export interface IGetDammAccruedInput {
  comptroller: Comptroller;
  accountAddress: string;
}


export type GetDammAccruedOutput = BigNumber;

const getDammAccrued = async ({
  comptroller,
  accountAddress,
}: IGetDammAccruedInput): Promise<GetDammAccruedOutput> => {
  try {
    const res = await comptroller.methods.compAccrued(accountAddress).call();
    return new BigNumber(res.toString());
  } catch (e) {
    return new BigNumber(0);
  }
};

export default getDammAccrued;
