import BigNumber from 'bignumber.js';
import { restService } from 'utilities';
import { VBEP_TOKENS } from 'constants/tokens';
import { Market } from 'types';
import { notUndefined } from 'utilities/common';

export interface IGetMarketsResponse {
  dailyBdamm: BigNumber;
  markets: Market[];
  request: { addresses: string[] };
  venusRate: string;
  totalSupply: string;
  totalBorrows: string;
  liquidity: string;
  bdamm: number | null;
  damm: number | null;
}

export type GetMarketsOutput = {
  markets: Market[];
  dailyBdamm: BigNumber | undefined;
  liquidity: string | undefined;
  totalSupply: string | undefined;
  totalBorrows: string | undefined;
  bdamm: number;
  damm: number;
};

const getMarkets = async (blockNumber?: number): Promise<GetMarketsOutput> => {
  const response = await restService<IGetMarketsResponse>({
    endpoint: `/data${blockNumber ? `?blockNumber=${blockNumber}` : ''}`,
    method: 'GET',
  });
  if ('result' in response && response.result === 'error') {
    throw new Error(response.message);
  }
  let markets: Market[] = [];
  let dailyBdamm;
  let bdamm = 0;
  let damm = 0;
  let totalSupply = 'loading...';
  let totalBorrows = 'loading...';
  let liquidity = 'loading...';
  if (response && response.data && response.data.data) {
    dailyBdamm = new BigNumber(response.data.data.dailyBdamm);
    ({ totalSupply } = response.data.data);
    ({ totalBorrows } = response.data.data);
    ({ liquidity } = response.data.data);
    if (response.data.data.bdamm) {
      ({ bdamm } = response.data.data);
    }
    if (response.data.data.damm) {
      ({ damm } = response.data.data);
    }
    markets = Object.keys(VBEP_TOKENS)
      .map(item =>
        response.data?.data.markets.find(
          (market: Market) => market.underlyingSymbol.toLowerCase() === item.toLowerCase(),
        ),
      )
      .filter(notUndefined);
  }
  return { markets, dailyBdamm, totalBorrows, totalSupply, liquidity, bdamm, damm };
};

export default getMarkets;
