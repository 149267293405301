import BigNumber from 'bignumber.js';
import { shortenNumberWithSuffix } from 'utilities';

import PLACEHOLDER_KEY from 'constants/placeholderKey';

const formatCentsToReadableValue = ({
  value,
  shorthand = false,
}: {
  value: number | BigNumber | undefined;
  shorthand?: boolean;
}) => {
  if (value === undefined) {
    return PLACEHOLDER_KEY;
  }

  const wrappedValueDollars = new BigNumber(value).dividedBy(100);

  if (!shorthand) {
    return `$${wrappedValueDollars.toFormat(2)}`;
  }

  // Shorten value
  const shortenedValue = shortenNumberWithSuffix(wrappedValueDollars);
  return `$${shortenedValue}`;
};

export default formatCentsToReadableValue;
