/** @jsxImportSource @emotion/react */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'translation';
import isOnMarketDetailsPage from './isOnMarketDetailsPage';
import { menuItems } from '../../constants';
import { useStyles } from './styles';

const Title: React.FC = () => {
  const styles = useStyles();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  // Handle special case of Market Details page
  if (isOnMarketDetailsPage(pathname)) {
    return null;
  }

  const currentItem = menuItems.find(item => item.href === pathname);
  const currentItemKey = currentItem?.i18nTitleKey || currentItem?.i18nKey;

  if (!currentItemKey) {
    console.error(`Missing translation for key: ${pathname}`);
    return null;
  }

  return (
    <div css={styles.title}>
      <h3>
        {t(currentItemKey)}
      </h3>
    </div>
  );
};

export default Title;
