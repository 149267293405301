/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, InputHTMLAttributes, useMemo } from 'react';
import BigNumber from 'bignumber.js';

import useGetRedemptionUSDCPrice from 'clients/api/queries/useGetRedemptionUsdcPrice';
import { useField } from 'formik';
import { Typography } from '@mui/material';
import { Delimiter, Spinner } from 'components';
import { useGetBalanceOf } from 'clients/api';
import { convertWeiToCoins, formatCoinsToReadableValue } from 'utilities/common';
import { AuthContext } from 'context/AuthContext';
// import { IconName } from '../Icon';
import { getContractAddress } from 'utilities';
import { TertiaryButton } from '../Button';
import {
  TextField,
  // ITextFieldProps,
} from '../TextField';
import { useStyles } from '../../pages/Bonding/Redemption/RedemptionModal/styles';

// Note: although we display all the values in tokens (equivalent of ether for
// the given token) to the user, the underlying values (maxWei, value) are
// expressed in wei to make them easier to use with contracts
// export interface IRedemptionTextFieldsProps
//   extends Omit<ITextFieldProps, 'onChange' | 'value' | 'max' | 'min'> {
//   tokenId: 'bdamm' | 'usdc';
//   value: string;
//   onChange: (newValue: string) => void;
//   rightMaxButton?: {
//     label: string;
//     valueOnClick: string;
//   };
//   max?: string;
// }

/*
  name="feeAmount"
  tokenId={'usdc' as TokenId}
  disabled
  css={styles.input}
*/
// export const RedemptionTextFields: React.FC<IRedemptionTextFieldsProps> = () => {
export const RedemptionTextFields: React.FC = () => {
  const styles = useStyles();
  const css = styles.input;

  const { account } = useContext(AuthContext);
  // const [{ value: redeemAmountValue, onBlur }, { error }, { setValue }] = useField('redeemAmount');
  const [{ value: redeemAmountValue, onBlur: onBlurRedeem }, , { setValue: setRedeemAmountValue }] = useField<string>('redeemAmount');
  // const [{ value: feeAmountValue, onBlur }, { error }, { setValue }] = useField('feeAmount');
  const [{ value: feeAmountValue, onBlur: onBlurFee }, , { setValue: setFeeAmountValue }] = useField('feeAmount');

  const redemptionAddress = useMemo(() => getContractAddress('redemption'), []);
  const { data: dammLiquidity } = useGetBalanceOf({ accountAddress: redemptionAddress, tokenId: 'damm' });
  const { data: redemptionUSDCPrice = new BigNumber(1) } = useGetRedemptionUSDCPrice();

  const redemptionUSDCPriceFormatted = redemptionUSDCPrice.shiftedBy(-6);

  // const handleChange: ITextFieldProps['onChange'] = ({ currentTarget: { value } }) => {
  //   console.log(value);
  // Forbid values with more decimals than the token provided supports
  // const valueDecimals = value.includes('.') ? value.split('.')[1].length : 0;

  // if (valueDecimals <= bdammDecimals) {
  //   onChange(value);
  // }
  // };

  useEffect(() => {
    const feeAmountCalculated = redemptionUSDCPriceFormatted.times(redeemAmountValue);
    if (!(feeAmountCalculated.isEqualTo(feeAmountValue))) {
      setFeeAmountValue(feeAmountCalculated.toFixed(4));
    }
  }, [redeemAmountValue]);
  const { data: userUsdcBalance } = useGetBalanceOf({
    tokenId: 'usdc',
    accountAddress: account?.address || '',
  });
  const { data: userBdammBalance } = useGetBalanceOf({
    tokenId: 'bdamm',
    accountAddress: account?.address || '',
  });
  const usdcBalanceParsed = (new BigNumber(userUsdcBalance || 0));
  const bdammBalanceParsed = (new BigNumber(userBdammBalance || 0));
  const usdcBalance = convertWeiToCoins({
    valueWei: usdcBalanceParsed,
    tokenId: 'usdc',
    shorthand: true,
  });
  const bdammBalance = convertWeiToCoins({
    valueWei: bdammBalanceParsed,
    tokenId: 'bdamm',
    shorthand: true,
  });

  const readableUsdcBalance = formatCoinsToReadableValue({
    value: usdcBalance,
    tokenId: 'usdc',
    shorthand: true,
  });
  const readableBdammBalance = formatCoinsToReadableValue({
    value: bdammBalance,
    tokenId: 'bdamm',
    shorthand: true,
  });

  const handleChangeBdamm: InputHTMLAttributes<HTMLInputElement>['onChange'] = ({ currentTarget: { value } }) => {
    // Forbid values with more decimals than the token provided supports
    const valueDecimals = value.includes('.') ? value.split('.')[1].length : 0;

    if (valueDecimals <= 2) {
      setRedeemAmountValue(value);
    }
  };
  const maxRedeem = BigNumber.min(
    bdammBalance,
    dammLiquidity?.shiftedBy(-18) || new BigNumber(Infinity),
    usdcBalance.div(redemptionUSDCPriceFormatted).toFixed(4, BigNumber.ROUND_DOWN),
  );
  return (
    <>
      <TextField
        placeholder="0.00"
        min={0}
        max={maxRedeem.toNumber()}
        step="0.01"
        onChange={handleChangeBdamm}
        type="number"
        leftIconName="bdamm"
        rightAdornment={
          <TertiaryButton
            onClick={() => setRedeemAmountValue(maxRedeem.toFixed(2))}
            small
            disabled={false}
          >
            MAX
          </TertiaryButton>
        }
        disabled={false}
        css={css}
        name="redeemAmount"
        value={redeemAmountValue}
        onBlur={onBlurRedeem}
      />
      <Typography
        component="div"
        variant="small2"
        css={[styles.greyLabel, styles.getRow({ isLast: true })]}
      >
        bdAMM Balance: {readableBdammBalance}
      </Typography>

      <TextField
        placeholder="0.00"
        min={0}
        max={usdcBalance.div(redemptionUSDCPrice).toFixed(4, BigNumber.ROUND_DOWN)}
        step="0.0001"
        // onChange={}
        type="number"
        leftIconName="usdc"
        disabled
        css={css}
        name="feeAmount"
        value={feeAmountValue}
        onBlur={onBlurFee}
      />
      <Typography
        component="div"
        variant="small2"
        css={[styles.greyLabel, styles.getRow({ isLast: true })]}
      >
        USDC Balance: {readableUsdcBalance}
      </Typography>

      <Delimiter css={styles.getRow({ isLast: true })} />
      <TextField
        placeholder="0.00"
        min={0}
        max={maxRedeem.toNumber()}
        step="0.01"
        onChange={handleChangeBdamm}
        type="number"
        leftIconName="bdamm"
        rightAdornment={
          <TertiaryButton
            onClick={() => setRedeemAmountValue(maxRedeem.toFixed(2))}
            small
            disabled={false}
          >
            MAX
          </TertiaryButton>
        }
        disabled={false}
        css={css}
        name="redeemAmount"
        value={redeemAmountValue}
      />
      <Typography
        component="div"
        variant="small2"
        css={[styles.greyLabel, styles.getRow({ isLast: true })]}
      >
        dAMM Redeemable {dammLiquidity ? dammLiquidity.shiftedBy(-18).toFixed(0) : <Spinner variant="small" /> }
      </Typography>
    </>
  );
};
