import * as React from 'react';
import { SVGProps } from 'react';

const SvgAeth = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
<svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M124.987 245.002C192.397 245.002 247.044 190.375 247.044 122.989C247.044 55.6035 192.397 0.976562 124.987 0.976562C57.5764 0.976562 2.92969 55.6035 2.92969 122.989C2.92969 190.375 57.5764 245.002 124.987 245.002Z" fill="#EDF0F2" />
<path d="M125 250C116.538 250.002 108.097 249.152 99.8049 247.461C83.8156 244.186 68.6277 237.794 55.1077 228.651C35.0107 215.085 19.2794 195.982 9.81975 173.656C6.62477 166.096 4.18612 158.237 2.5395 150.196C-0.846499 133.572 -0.846499 116.436 2.5395 99.8119C5.81438 83.8227 12.2055 68.6348 21.348 55.1147C34.9144 35.0166 54.0183 19.2842 76.345 9.8238C83.9054 6.62738 91.7637 4.18742 99.8049 2.53964C116.429 -0.846546 133.565 -0.846546 150.189 2.53964C166.178 5.81447 181.366 12.2055 194.886 21.3482C214.983 34.9145 230.714 54.018 240.174 76.3442C243.371 83.9048 245.812 91.7634 247.461 99.805C250.847 116.429 250.847 133.565 247.461 150.189C244.186 166.178 237.795 181.366 228.652 194.886C215.085 214.982 195.982 230.714 173.656 240.174C166.096 243.371 158.237 245.812 150.195 247.461C141.904 249.152 133.463 250.002 125 250V250ZM125 13.3922C117.445 13.3905 109.91 14.1502 102.507 15.6597C88.2305 18.5835 74.6695 24.29 62.5979 32.4536C44.6566 44.5678 30.613 61.6247 22.1684 81.558C19.3146 88.3093 17.1363 95.3266 15.6654 102.507C12.6422 117.35 12.6422 132.65 15.6654 147.492C18.5903 161.769 24.2978 175.329 32.4623 187.401C44.5754 205.344 61.632 219.39 81.5657 227.837C88.3174 230.69 95.335 232.868 102.516 234.339C117.358 237.363 132.658 237.363 147.501 234.339C161.778 231.415 175.339 225.708 187.409 217.542C205.347 205.428 219.388 188.373 227.832 168.443C230.686 161.691 232.864 154.673 234.335 147.492C237.358 132.65 237.358 117.35 234.335 102.507C231.41 88.2312 225.702 74.6708 217.538 62.6C205.427 44.6591 188.373 30.6149 168.442 22.1685C161.691 19.3149 154.673 17.1366 147.492 15.6656C140.09 14.1545 132.555 13.3931 125 13.3931V13.3922Z" fill={`url(#${id}__a`} />
<path d="M124.713 44.2365V44.3153C124.791 44.7094 124.791 45.1823 124.791 45.6552V102.483C124.713 102.798 124.476 102.877 124.24 103.034C122.584 103.823 121.008 104.532 119.353 105.241C117.067 106.266 114.703 107.369 112.417 108.394L104.141 112.177C101.855 113.202 99.5697 114.227 97.3628 115.251C94.683 116.512 91.9244 117.695 89.2446 118.956C86.9589 119.98 84.6732 121.084 82.3086 122.108C80.417 122.975 78.5254 123.764 76.7126 124.631C76.5549 124.709 76.3973 124.788 76.2397 124.788C76.1608 124.788 76.1608 124.788 76.082 124.709L78.2101 121.163C82.3086 114.384 86.3283 107.685 90.4269 100.906C94.7618 93.6552 99.1756 86.4039 103.511 79.1527C107.53 72.4532 111.629 65.7537 115.649 59.0542C118.565 54.1675 121.56 49.2808 124.476 44.3941C124.555 44.2365 124.634 44.1576 124.634 44H124.713C124.634 44.0788 124.713 44.1576 124.713 44.2365Z" fill="#8A92B2" />
<path d="M173.103 124.709L173.182 124.788L161.596 131.645L125.182 153.162C125.025 153.241 124.867 153.32 124.788 153.399C124.552 153.399 124.552 153.162 124.552 153.083V152.374V103.665C124.552 103.428 124.552 103.113 124.631 102.877C124.709 102.561 124.946 102.64 125.182 102.719C126.207 103.192 127.31 103.665 128.335 104.138C131.409 105.556 134.483 106.975 137.557 108.315C140.236 109.497 142.837 110.758 145.517 111.941C148.197 113.123 150.877 114.384 153.557 115.566C155.842 116.591 158.207 117.694 160.493 118.719C162.778 119.744 165.143 120.847 167.429 121.872C169.241 122.66 171.054 123.527 172.867 124.315C172.867 124.551 172.946 124.63 173.103 124.709Z" fill="#454A75" />
<path d="M124.709 202.345C124.709 202.424 124.63 202.503 124.63 202.582H124.551C124.551 202.424 124.393 202.345 124.315 202.188C119.428 195.331 114.541 188.395 109.654 181.537C104.689 174.523 99.6446 167.429 94.6791 160.414C89.8712 153.636 84.9845 146.779 80.1766 140C78.9156 138.188 77.6545 136.454 76.3934 134.641C76.3146 134.483 76.2358 134.404 76.0781 134.168C76.3146 134.168 76.4722 134.326 76.551 134.404C83.4082 138.424 90.1865 142.444 97.0436 146.464C104.925 151.114 112.728 155.764 120.61 160.414L124.63 162.779C124.787 162.936 124.787 163.094 124.787 163.252V201.242C124.787 201.636 124.787 202.03 124.709 202.345Z" fill="#8A92B2" />
<path d="M76 124.867V124.788C78.5222 123.685 80.9655 122.503 83.4877 121.399C86.7192 119.902 89.9507 118.483 93.1823 116.985C95.6256 115.882 98.1478 114.7 100.591 113.596C104.217 111.941 107.764 110.365 111.389 108.71C113.833 107.606 116.276 106.503 118.798 105.32C120.532 104.532 122.345 103.744 124.079 102.956C124.236 102.877 124.473 102.798 124.552 102.641C124.631 102.641 124.631 102.719 124.552 102.798V152.848C124.552 153.084 124.473 153.32 124.631 153.478C124.473 153.715 124.315 153.478 124.236 153.399C123.527 153.005 122.818 152.611 122.108 152.138C106.897 143.153 91.6059 134.089 76.3941 125.104C76.3153 125.025 76.1576 124.946 76 124.867Z" fill="#62688F" />
<path d="M172.945 134.168H173.024C173.024 134.326 172.866 134.483 172.788 134.641C158.285 155.055 143.783 175.547 129.28 195.961C127.783 198.089 126.206 200.217 124.709 202.345C124.63 202.267 124.63 202.188 124.63 202.109V201.636V163.409V162.7C127.94 160.73 131.172 158.838 134.482 156.867C147.251 149.301 160.019 141.813 172.709 134.247C172.788 134.326 172.866 134.247 172.945 134.168Z" fill="#62688F" />
<path d="M124.63 102.799V102.642V102.169V44.9469C124.63 44.7105 124.551 44.5529 124.709 44.3164C140.709 70.878 156.709 97.3607 172.63 123.922C172.788 124.159 173.024 124.474 173.103 124.789C172 124.395 170.975 123.843 169.951 123.371C168.689 122.819 167.35 122.188 166.088 121.637C165.3 121.243 164.433 120.927 163.645 120.533C162.305 119.903 160.965 119.351 159.625 118.72C158.837 118.405 158.049 118.011 157.261 117.617L152.059 115.252C151.192 114.858 150.325 114.464 149.379 114.07L145.596 112.415C144.808 112.1 144.019 111.706 143.231 111.311L138.029 108.947C137.162 108.553 136.295 108.159 135.35 107.765L131.566 106.11C130.699 105.715 129.911 105.321 129.044 104.927C127.547 104.218 126.049 103.509 124.473 102.878C124.709 102.799 124.63 102.799 124.63 102.799Z" fill="#62688F" />
<defs>
<linearGradient id={`${id}__a`} x1="210.75" y1="33.75" x2="51.5003" y2="221.5" gradientUnits="userSpaceOnUse">
<stop stopColor="#B6509E" />
<stop offset="1" stopColor="#2EBAC6" />
</linearGradient>
</defs>
</svg>

);

export default SvgAeth;
