import * as React from 'react';
import { SVGProps } from 'react';

const SvgBdammtype = (props: SVGProps<SVGSVGElement>) => (

  <svg width="76" height="17" viewBox="0 0 76 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8 17H6.5H5V15.5H3.5V17H2H0.5V15.5V14V12.5V11V9.5V8V6.5V5V3.5V2V0.5H2H3.5V2V3.5V5V6.5H5V5H6.5H8H9.5V6.5H11V8V9.5V11V12.5V14V15.5H9.5V17H8ZM6.5 14H8V12.5V11V9.5V8H6.5H5V9.5H3.5V11V12.5H5V14H6.5ZM23 17H21.5V15.5H20V17H18.5H17H15.5V15.5H14V14V12.5V11V9.5V8V6.5H15.5V5H17H18.5H20V6.5H21.5V5V3.5V2V0.5H23H24.5V2V3.5V5V6.5V8V9.5V11V12.5V14V15.5V17H23ZM18.5 14H20V12.5H21.5V11V9.5H20V8H18.5H17V9.5V11V12.5V14H18.5ZM38 17H36.5V15.5V14V12.5V11H35H33.5H32H30.5V12.5V14V15.5V17H29H27.5V15.5V14V12.5V11V9.5V8V6.5V5V3.5H29V2H30.5V0.5H32H33.5H35H36.5V2H38V3.5H39.5V5V6.5V8V9.5V11V12.5V14V15.5V17H38ZM35 8H36.5V6.5V5H35V3.5H33.5H32V5H30.5V6.5V8H32H33.5H35ZM56 17H54.5V15.5V14V12.5V11V9.5V8V6.5H53V8H51.5V9.5H50H48.5V8H47V6.5H45.5V8V9.5V11V12.5V14V15.5V17H44H42.5V15.5V14V12.5V11V9.5V8V6.5V5V3.5V2V0.5H44H45.5V2H47V3.5H48.5V5H50H51.5V3.5H53V2H54.5V0.5H56H57.5V2V3.5V5V6.5V8V9.5V11V12.5V14V15.5V17H56ZM74 17H72.5V15.5V14V12.5V11V9.5V8V6.5H71V8H69.5V9.5H68H66.5V8H65V6.5H63.5V8V9.5V11V12.5V14V15.5V17H62H60.5V15.5V14V12.5V11V9.5V8V6.5V5V3.5V2V0.5H62H63.5V2H65V3.5H66.5V5H68H69.5V3.5H71V2H72.5V0.5H74H75.5V2V3.5V5V6.5V8V9.5V11V12.5V14V15.5V17H74Z" fill="white" />
  </svg>
);

export default SvgBdammtype;
