/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { useTranslation } from 'translation';
import { AuthContext } from 'context/AuthContext';
import { useUserMarketInfo } from 'clients/api';
import { Asset } from 'types';
import { Tabs } from 'components';
import CompMarkets from './CompMarkets';
import AaveMarkets from './AaveMarkets';
import { useStyles } from './styles';
import Header from './Header';

interface IBoostUiProps {
  accountAddress: string;
  assets: Asset[];
}

const BoostUi: React.FC<IBoostUiProps> = ({
  accountAddress,
  assets,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { compMarkets, aaveMarkets } =
    useMemo(() => {
      const sortedAssets = assets.reduce(
        (acc, curr) => {
          if (curr.assetType === 'aave') {
            acc.aaveMarkets.push(curr);
          } else if (curr.assetType === 'comp') {
            acc.compMarkets.push(curr);
          }
          return acc;
        },
        {
          aaveMarkets: [] as Asset[],
          compMarkets: [] as Asset[],
        },
      );
      return sortedAssets;
    }, [JSON.stringify(assets)]);

    const tabsContent = [
      {
        title: t('Compound'),
        content: (
          <CompMarkets
            accountAddress={accountAddress}
            compMarkets={compMarkets}
          />
        ),
      },
      {
        title: t('Aave'),
        content: (
          <AaveMarkets
            accountAddress={accountAddress}
            aaveMarkets={aaveMarkets}
          />
        ),
      },
    ];

  return (
    <div css={styles.container}>
        <Header />
        <Tabs
          css={styles.tabsHeader}
          tabsContent={tabsContent}
        />
    </div>
  );
};

const Boost: React.FC = () => {
  const { account } = React.useContext(AuthContext);
  const {
    assets,
  } = useUserMarketInfo({
    accountAddress: account?.address || '',
  });

  return (
    <BoostUi
      accountAddress={account?.address || ''}
      assets={assets}
    />
  );
};

export default Boost;
