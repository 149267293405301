/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { Table, Token, TableProps, LayeredValues } from 'components';
import { useTranslation } from 'translation';
import { Asset, TokenId } from 'types';
import {
  formatCoinsToReadableValue,
  formatToReadablePercentage,
} from 'utilities/common';
import { formatCentsToReadableValue } from 'utilities';
import { useStyles } from './styles';
import { useStyles as useSharedStyles } from '../styles';

export interface IBorrowMarketTableProps extends Pick<TableProps, 'rowOnClick'> {
  assets: Asset[];
}

const BorrowMarketTable: React.FC<IBorrowMarketTableProps> = ({
  assets,
  rowOnClick,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const sharedStyles = useSharedStyles();

  const columns = useMemo(
    () => [
      { key: 'asset', label: t('markets.columns.asset'), orderable: false },
      { key: 'totalBorrows', label: t('market.columns.totalBorrow'), orderable: true },
      { key: 'apy', label: t('markets.columns.apy'), orderable: true },
      { key: 'wallet', label: t('markets.columns.wallet'), orderable: true },
      { key: 'liquidity', label: t('markets.columns.liquidity'), orderable: true },
    ],
    [],
  );

  // Format assets to rows
  const rows: TableProps['data'] = assets.map(asset => {
    const { borrowApy } = asset;

    return [
      {
        key: 'asset',
        render: () => <Token symbol={asset.symbol as TokenId} />,
        value: asset.id,
      },
      {
        key: 'totalBorrows',
        render: () => (
          <LayeredValues
            topValue={formatCentsToReadableValue({
              value: asset.totalBorrowsUsd.times(100),
              shorthand: true,
            })}
            bottomValue={formatCoinsToReadableValue({
              value: asset.totalBorrows2,
              tokenId: asset.id as TokenId,
              shorthand: true,
              suffix: true,
            })}
          />
        ),
        align: 'right',
        value: asset.totalBorrowsUsd.toFixed(),
      },
      {
        key: 'apy',
        render: () => formatToReadablePercentage(borrowApy),
        value: borrowApy.toNumber(),
      },
      {
        key: 'wallet',
        render: () =>
          formatCoinsToReadableValue({
            value: asset.walletBalance,
            tokenId: asset.id as TokenId,
            shorthand: true,
          }),
        value: asset.walletBalance.toFixed(),
      },
      {
        key: 'liquidity',
        render: () =>
          formatCentsToReadableValue({
            value: asset.liquidity.multipliedBy(100),
            shorthand: true,
          }),
        value: asset.liquidity.toNumber(),
      },
    ];
  });

  return (
    <>
      <h4 css={styles.title}>Borrow Markets</h4>
      <Table
        columns={columns}
        data={rows}
        initialOrder={{
          orderBy: 'apy',
          orderDirection: 'desc',
        }}
        rowKeyIndex={0}
        rowOnClick={rowOnClick}
        tableCss={sharedStyles.table}
        cardsCss={sharedStyles.cards}
        css={[sharedStyles.marketTable, sharedStyles.generalMarketTable, styles.cardContentGrid]}
      />
    </>
  );
};

export default BorrowMarketTable;
