import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    appBar: css`
      background-image: none;
      background-color: #050a30;
      box-shadow: none;
      padding: 0;
      position: sticky;
      top: 0;
      z-index: 1000;
      display: flex;
      justify-content: space-between;
      max-width: 1440px;
      margin: auto;
      ${theme.breakpoints.down('md')} {
        display: none;
      }
    `,
    toolbar: css`
      padding: ${theme.spacing(3, 10, 3, 10)} !important;
      display: flex;
      position: sticky;

      ${theme.breakpoints.down('lg')} {
        padding-left: ${theme.spacing(6)} !important;
        padding-right: ${theme.spacing(6)} !important;
      }

      ${theme.breakpoints.down('md')} {
        padding: ${theme.spacing(6, 4, 0)} !important;
      }
    `,
    claimDammButton: css`
      margin-right: ${theme.spacing(6)};
    `,
    ctaContainer: css`
      display: flex;
      margin-left: none;
    `,
    logo: css`
      display: flex;
      margin-right: 37px;
      height: 33px;
      width: 120px;
      }
    `,
    list: css`
      display: flex;
      margin-left: auto;
      margin-right: auto;
      flex-direction: row;
      .activeMenuItem {
        color: ${theme.palette.text.primary};
      }
      ${theme.breakpoints.down('md')} {
        flex-direction: column;
        margin-top: 50%;
        transform: translateY(-50%);
        width: 90%;
        margin-top: 50%;
        transform: translateY(-50%);
      }
    `,
    listItem: css`
      transition: color 0.3s;
      color: ${theme.palette.text.secondary};
      padding: 0;
      border-radius: ${theme.shape.borderRadius.medium}px;
      margin-left: .5em;
      margin-right: .5em;

      a {
        padding: ${theme.spacing(4)};
        display: flex;
        justify-content: end;
        width: 100%;
        border-radius: ${theme.shape.borderRadius.medium}px;
      }

      .active-menu-item {
        background-color: ${theme.palette.secondary.light};
        
        p {
          color: ${theme.palette.text.primary};
        }
      }
    `,
    listItemText: css`
      text-transform: none;
      display: inline-block;
    `,
    mobileMenuBox: css`
      display: none;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: ${theme.spacing(4)};
      ${theme.breakpoints.down('md')} {
        display: flex;
      }
    `,
    mobileLogo: css`
      flex-shrink: 0;
      height: ${theme.spacing(10)};
      width: ${theme.spacing(10)};
    `,
    burger: css`
      height: ${theme.spacing(6)};
      width: ${theme.spacing(6)};
    `,
    mobileMenu: css`
      .MuiMenu-list {
        display: flex;
        flex-direction: column;
      }

      > .MuiPaper-root {
        background-color: ${theme.palette.background.default};
        height: 100vh;
        max-height: 100vh;
        width: 100vw;
        max-width: 100vw;
        border-radius: 0;
        border: 0;
        box-shadow: none;
        padding: 0;
 
      }
    `,
    mobileListItem: css`
      a {
        color: ${theme.palette.text.primary};
        display: flex;
        flex-direction: row;
        flex: 1;
        padding-top: ${theme.spacing(4)};
        padding-bottom: ${theme.spacing(4)};
        justify-content: space-between;
        padding-left: ${theme.spacing(6)};
        padding-right: ${theme.spacing(6)};
      }
      :hover {
        margin-left: 0;
        margin-right: 0;
        border-radius: ${theme.shape.borderRadius.medium}px;
      }
      .active-mobile-menu-item {
        background-color: ${theme.palette.secondary.light};
        svg {
          color: ${theme.palette.interactive.primary};
        }
      }
    `,
    mobileListItemText: css`
      color: ${theme.palette.text.primary};
    `,
    mobileArrow: css`
      height: ${theme.spacing(6)};
      width: ${theme.spacing(6)};
    `,
    mobileLabel: css`
      flex-direction: row;
      justify-content: center;
      display: inline-flex;
      align-items: center;
      svg {
        margin-right: ${theme.spacing(4)};
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `,
    doublePadding: css`
      padding: ${theme.spacing(2)} ${theme.spacing(4)}; ;
    `,
    actionButton: css`
      flex-shrink: 0;
      width: ${theme.spacing(10)};
      cursor: pointer;
      box-shadow: none;
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: center;
    `,
    mobileConnectButton: css`
      margin-left: ${theme.spacing(8)};
      margin-right: ${theme.spacing(8)};
    `,
    claimDammRewardButton: css`
      margin: ${theme.spacing(4, 4, 0, 4)};
    `,
  };
};
