/** @jsxImportSource @emotion/react */
import React from 'react';
import BigNumber from 'bignumber.js';
// import { MarketContext } from 'context/MarketContext';
import { Typography } from '@mui/material';
import {
  toast,
  FormikTokenTextField,
  Delimiter,
  LabeledInlineContent,
  FormikSubmitButton,
  // BorrowBalanceAccountHealth,
  ValueUpdate,
} from 'components';
import { AmountForm, IAmountFormProps, ErrorCode } from 'containers/AmountForm';
import { useTranslation } from 'translation';
import { Asset, TokenId } from 'types';
import {
  // getBigNumber,
  format,
  // convertCoinsToWei,
  formatCoinsToReadableValue,
  // isWhitelisted,
} from 'utilities/common';
import {
  // calculateYearlyEarningsForAssets,
  // calculateDailyEarningsCents,
  // calculateCollateralValue,
  // calculateYearlyBdammEarningsForAssets,
  // calculateDailyBdammEarningsCents,
  // getContractAddress,
} from 'utilities';
import { useStyles } from '../../styles';


interface IStakingFormUiProps {
  asset: Asset;
  type: 'stake' | 'unstake';
  maxInput: BigNumber;
  inputLabel: string;
  enabledButtonKey: string;
  disabledButtonKey: string;
  calculateNewBalance: (initial: BigNumber, amount: BigNumber) => BigNumber;
  isTransactionLoading: boolean;
  amountValue: string;
}

export const StakingContent: React.FC<IStakingFormUiProps> = ({
  asset,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type,
  maxInput,
  inputLabel,
  enabledButtonKey,
  disabledButtonKey,
  calculateNewBalance,
  isTransactionLoading,
  amountValue,
}) => {
  const styles = useStyles();
  const { t, Trans } = useTranslation();
  const { id: assetId } = asset;
  const amount = new BigNumber(amountValue || 0);
  const validAmount = amount && !amount.isZero() && !amount.isNaN();

  const hypotheticalTokenSupplyBalance = amountValue
    ? calculateNewBalance(asset.supplyBalance, amount)
    : undefined;


  // const [dailyEarningsCents, hypotheticalDailyEarningCents] = useMemo(() => {
  //   let hypotheticalDailyEarningCentsValue;
  //   const hypotheticalAssets = [...assets];
  //   const yearlyEarningsCents = calculateYearlyEarningsForAssets({
  //     assets,
  //   });
  //   const dailyEarningsCentsValue =
  //     yearlyEarningsCents && calculateDailyEarningsCents(yearlyEarningsCents);

  //   // Modify asset with hypotheticalBalance
  //   if (validAmount) {
  //     const hypotheticalAsset = {
  //       ...asset,
  //       supplyBalance: calculateNewBalance(asset.supplyBalance, amount),
  //     };
  //     const currentIndex = assets.findIndex(a => a.id === asset.id);
  //     hypotheticalAssets.splice(currentIndex, 1, hypotheticalAsset);
  //     const hypotheticalYearlyEarningsCents = calculateYearlyEarningsForAssets({
  //       assets: hypotheticalAssets,
  //     });
  //     hypotheticalDailyEarningCentsValue =
  //       hypotheticalYearlyEarningsCents &&
  //       calculateDailyEarningsCents(hypotheticalYearlyEarningsCents);
  //   }
  //   return [dailyEarningsCentsValue, hypotheticalDailyEarningCentsValue];
  // }, [amount, asset.id, JSON.stringify(assets)]);

  // bdamm earnings

  // const [dailyBdammEarningsCents, hypotheticalDailyBdammEarningsCents] = useMemo(() => {
  //   let hypotheticalDailyBDammEarningsCentsValue;
  //   const hypotheticalAssets = [...assets];
  //   const yearlyBdammEarningsCents = calculateYearlyBdammEarningsForAssets({
  //     assets,
  //   });
  //   const dailyBdammEarningsCentsValue =
  //     yearlyBdammEarningsCents && calculateDailyBdammEarningsCents(yearlyBdammEarningsCents);

  //   // Modify asset with hypotheticalBalance
  //   if (validAmount) {
  //     const hypotheticalAsset = {
  //       ...asset,
  //       supplyBalance: calculateNewBalance(asset.supplyBalance, amount),
  //     };
  //     const currentIndex = assets.findIndex(a => a.id === asset.id);
  //     hypotheticalAssets.splice(currentIndex, 1, hypotheticalAsset);
  //     const hypotheticalYearlyBdammEarningsCents = calculateYearlyBdammEarningsForAssets({
  //       assets: hypotheticalAssets,
  //     });
  //     hypotheticalDailyBDammEarningsCentsValue =
  //       hypotheticalYearlyBdammEarningsCents &&
  //       calculateDailyBdammEarningsCents(hypotheticalYearlyBdammEarningsCents);
  //   }
  //   return [dailyBdammEarningsCentsValue, hypotheticalDailyBDammEarningsCentsValue];
  // }, [amount, asset.id, JSON.stringify(assets)]);

  // const totalDailyEarnings = dailyEarningsCents?.plus(dailyBdammEarningsCents || 0);
  // const hypotheticalTotalDailyEarnings = hypotheticalDailyEarningCents?.plus(hypotheticalDailyBdammEarningsCents || 0);


  // const { userTotalBorrowLimit } = useContext(MarketContext);

  return (
    <>
      <FormikTokenTextField
        name="amount"
        tokenId={assetId as TokenId}
        disabled={isTransactionLoading}
        rightMaxButton={{
          label: t('Staking.max').toUpperCase(),
          valueOnClick: maxInput.toFixed(),
        }}
        css={styles.input}
        // Only display error state if amount is higher than borrow limit
        displayableErrorCodes={[ErrorCode.HIGHER_THAN_MAX]}
      />
      <Typography
        component="div"
        variant="small2"
        css={[styles.greyLabel, styles.getRow({ isLast: true })]}
      >
        <Trans
          i18nKey={inputLabel}
          components={{
            White: <span css={styles.whiteLabel} />,
          }}
          values={{ amount: format(maxInput, asset.decimals), symbol: assetId?.toUpperCase() }}
        />
      </Typography>

      <Delimiter css={styles.getRow({ isLast: true })} />

      {/* <LabeledInlineContent
        label={t('Staking.dailyEarnings')}
        css={styles.getRow({ isLast: false })}
        className="info-row"
      >
        <ValueUpdate original={dailyEarningsCents} update={hypotheticalDailyEarningCents} />
      </LabeledInlineContent>
      <LabeledInlineContent
        label={t('Daily bdAMM Earnings')}
        css={styles.getRow({ isLast: false })}
        className="info-row"
      >
        <ValueUpdate original={dailyBdammEarningsCents} update={hypotheticalDailyBdammEarningsCents} />
      </LabeledInlineContent>
      <LabeledInlineContent
        label={t('Total Daily Earnings')}
        css={styles.getRow({ isLast: false })}
        className="info-row"
      >
        <ValueUpdate original={totalDailyEarnings} update={hypotheticalTotalDailyEarnings} />
      </LabeledInlineContent> */}
      <LabeledInlineContent
        label={t('Staking.supplyBalance')}
        css={styles.bottomRow}
        className="info-row"
      >
        <ValueUpdate
          original={asset.supplyBalance}
          update={hypotheticalTokenSupplyBalance}
          format={(value: BigNumber | undefined) =>
            formatCoinsToReadableValue({
              value,
              tokenId: asset.id,
              shorthand: true,
            })
          }
        />
      </LabeledInlineContent>
      <FormikSubmitButton
        fullWidth
        disabled={!validAmount}
        loading={isTransactionLoading}
        enabledLabel={enabledButtonKey}
        disabledLabel={disabledButtonKey}
      />
    </>
  );
};

interface IStakingFormProps extends Omit<IStakingFormUiProps, 'amountValue'> {
  onSubmit: IAmountFormProps['onSubmit'];
}

const StakingForm: React.FC<IStakingFormProps> = ({
  onSubmit,
  maxInput,
  ...props
}) => {
  const onSubmitHandleError: IAmountFormProps['onSubmit'] = async (value: string) => {
    try {
      await onSubmit(value);
    } catch (err) {
      toast.error({ message: (err as Error).message });
    }
  };
  return (
    <AmountForm onSubmit={onSubmitHandleError} maxAmount={maxInput.toFixed()}>
      {({ values }) => (
        <StakingContent maxInput={maxInput} amountValue={values.amount} {...props} />
      )}
    </AmountForm>
  );
};

export default StakingForm;
