import * as React from 'react';
import { SVGProps } from 'react';

const SvgPerp = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
    <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${id}__a`}>
        <path d="M109 0.423866C90.9896 2.81761 73.4417 8.57336 58 18.3197C44.0437 27.1286 32.0181 38.5792 22.4275 51.9995C13.9257 63.8962 8.02211 76.9839 4 90.9995C0.752164 102.317 0 113.285 0 124.999C0 139.905 1.66547 153.858 6.6983 167.999C26.4974 223.63 84.0239 256.834 142 249.714C159.03 247.622 176.461 241.374 191 232.315C208.415 221.463 223.048 206.647 233.547 188.999C264.101 137.64 252.205 68.0462 207 29.2997C192.676 17.0224 176.038 8.83465 158 3.70936C142.738 -0.627144 124.762 -1.67107 109 0.423866Z" fill="white" />
        <path d="M105 54C106.769 54.7789 108.036 54.9124 110 55C108.231 54.2211 106.964 54.0876 105 54Z" fill="#D1FAEB" />
        <path d="M101.667 55.3339C101.222 55.7784 102.278 55.7229 102.333 55.6673C102.778 55.2228 101.722 55.2784 101.667 55.3339Z" fill="#78F0C4" />
        <path d="M113 188C111.919 177.712 118.149 172.851 125 166C122.205 162.033 118.797 158.737 115.17 155.514C113.48 154.013 110.727 152.259 110.167 149.907C108.627 143.434 121.193 138.861 122.654 133C123.366 130.147 120.733 127.845 118.98 126C115.217 122.038 111.155 118.479 108 114C103.523 117.154 99.9369 121.202 96.0002 124.991C94.4175 126.514 92.4226 128.944 90.0002 128.944C87.5778 128.944 85.583 126.514 84.0002 124.991C80.3953 121.521 77.0985 117.887 73.0002 115L98.0002 90.0007C100.395 87.6177 104.15 82.5193 108 82.8857C113.605 83.4191 120.973 93.7031 124 97.9999C128.799 94.6195 132.938 90.2555 136.98 85.9999C138.502 84.3976 140.793 82.422 140.793 79.9999C140.793 76.2138 135.439 72.3948 133 70.0146C125.125 62.3287 116.873 53.9405 105 55.1743C92.9758 56.4237 83.0854 68.9277 75.0002 76.9999C66.0483 85.9374 49.9217 96.9162 47.4693 110C45.1859 122.183 53.1047 130.104 61.0002 138C72.5638 149.564 84.212 160.959 96.0897 172.184C101.682 177.469 106.697 183.56 113 188Z" fill="#3CEAAA" />
        <path d="M112.667 55.3339C112.222 55.7784 113.278 55.7228 113.333 55.6673C113.778 55.2229 112.722 55.2784 112.667 55.3339Z" fill="#A1F5D6" />
        <path d="M98 56L99 57L98 56Z" fill="#BAF7E1" />
        <path d="M96 57L97 58L96 57Z" fill="#BCF8E2" />
        <path d="M118 57L119 58L118 57Z" fill="#A5F5D7" />
        <path d="M93 59L94 60L93 59Z" fill="#BAF7E0" />
        <path d="M121 59L122 60L121 59Z" fill="#AFF6DC" />
        <path d="M92 60L93 61L92 60Z" fill="#9BF4D3" />
        <path d="M122 60L123 61L122 60Z" fill="#8BF3CC" />
        <path d="M91 61L92 62L91 61Z" fill="#92F3D0" />
        <path d="M123 61L124 62L123 61Z" fill="#83F1CA" />
        <path d="M90 62L91 63L90 62Z" fill="#92F3D0" />
        <path d="M124 62L125 63L124 62Z" fill="#7FF1C7" />
        <path d="M89 63L90 64L89 63Z" fill="#86F1CA" />
        <path d="M125 63L126 64L125 63Z" fill="#7FF0C7" />
        <path d="M88 64L89 65L88 64Z" fill="#8BF2CC" />
        <path d="M126 64L127 65L126 64Z" fill="#7FF1C7" />
        <path d="M87 65L88 66L87 65ZM86 66L87 67L86 66Z" fill="#85F1C9" />
        <path d="M85 67L86 68L85 67Z" fill="#85F2C9" />
        <path d="M84 68L85 69L84 68Z" fill="#7FF0C7" />
        <path d="M157 71C158.769 71.7789 160.036 71.9124 162 72C160.231 71.2211 158.964 71.0876 157 71Z" fill="#D4F3FA" />
        <path d="M154.667 72.3339C154.222 72.7784 155.278 72.7228 155.333 72.6673C155.778 72.2228 154.722 72.2784 154.667 72.3339Z" fill="#54D1ED" />
        <path d="M125 97.0001C128.103 101.405 131.98 104.996 135.995 108.576C137.803 110.188 140.741 112.204 140.369 115.004C139.449 121.925 127.207 124.814 127.207 132C127.207 139.019 141.681 142.811 139.793 150C138.992 153.049 135.275 155.749 133.09 157.816C125.595 164.905 106.121 177.361 114.603 189.956C118.052 195.078 125.614 196.447 131 193.682C140.95 188.575 148.74 176.769 156.961 169.289C168.008 159.237 178.441 148.559 189 138C195.193 131.807 201.742 126.208 202.815 117C204.205 105.067 195.694 97.6943 188 90.0001C179.24 81.2403 169.88 69.9123 156 72.4685C143.163 74.8326 133.72 88.2801 125 97.0001Z" fill="#03BCE4" />
        <path d="M163 72C164.248 72.6848 164.549 72.7488 166 73C164.752 72.3152 164.452 72.2512 163 72Z" fill="#4FD0EC" />
        <path d="M151 73L152 74L151 73Z" fill="#80DDF1" />
        <path d="M168 73L169 74L168 73Z" fill="#86DEF1" />
        <path d="M149 74L150 75L149 74Z" fill="#6BD7EF" />
        <path d="M170 74L171 75L170 74Z" fill="#7BDCF0" />
        <path d="M147 75L148 76L147 75ZM172 75L173 76L172 75Z" fill="#A2E6F5" />
        <path d="M146 76L147 77L146 76Z" fill="#57D2EC" />
        <path d="M173 76L174 77L173 76Z" fill="#61D5EE" />
        <path d="M107 81L73 114C76.1197 118.429 80.0712 122.248 84 125.98C85.6023 127.502 87.5779 129.793 90 129.793C93.8117 129.793 97.5752 124.424 100 122C107.733 114.271 119.351 105.97 124 96C117.96 91.3983 113.194 85.3629 107 81Z" fill="white" />
        <path d="M108 81L109 82L108 81Z" fill="#85F2CA" />
        <path d="M109 82L110 83L109 82Z" fill="#8BF3CD" />
        <path d="M104 83L105 84L104 83Z" fill="#7FF1C8" />
        <path d="M110 83L111 84L110 83Z" fill="#8AF2CC" />
        <path d="M103 84L104 85L103 84Z" fill="#7FF1C7" />
        <path d="M111 84L112 85L111 84Z" fill="#8BF3CD" />
        <path d="M102 85L103 86L102 85Z" fill="#87F2CB" />
        <path d="M112 85L113 86L112 85Z" fill="#9EF4D5" />
        <path d="M101 86L102 87L101 86Z" fill="#87F2CB" />
        <path d="M113 86L114 87L113 86Z" fill="#98F4D2" />
        <path d="M100 87L101 88L100 87Z" fill="#8DF3CD" />
        <path d="M114 87L115 88L114 87Z" fill="#A7F6D8" />
        <path d="M99 88L100 89L99 88Z" fill="#97F4D1" />
        <path d="M115 88L116 89L115 88Z" fill="#9FF5D4" />
        <path d="M98 89L99 90L98 89Z" fill="#95F4D1" />
        <path d="M116 89L117 90L116 89Z" fill="#9FF5D5" />
        <path d="M97 90L98 91L97 90Z" fill="#9DF5D4" />
        <path d="M117 90L118 91L117 90Z" fill="#A9F6D9" />
        <path d="M96 91L97 92L96 91Z" fill="#9DF5D5" />
        <path d="M118 91L119 92L118 91Z" fill="#A6F5D8" />
        <path d="M189 91L190 92L189 91Z" fill="#B7ECF7" />
        <path d="M95 92L96 93L95 92Z" fill="#97F4D2" />
        <path d="M119 92L120 93L119 92Z" fill="#AFF6DC" />
        <path d="M190 92L191 93L190 92Z" fill="#B2EBF6" />
        <path d="M94 93L95 94L94 93Z" fill="#A3F6D7" />
        <path d="M120 93L121 94L120 93Z" fill="#B4F7DF" />
        <path d="M191 93L192 94L191 93Z" fill="#A9E8F5" />
        <path d="M93 94L94 95L93 94Z" fill="#A5F5D7" />
        <path d="M121 94L122 95L121 94Z" fill="#B7F7E0" />
        <path d="M192 94L193 95L192 94Z" fill="#A8E8F5" />
        <path d="M92 95L93 96L92 95Z" fill="#B0F7DD" />
        <path d="M193 95L194 96L193 95Z" fill="#B0EAF7" />
        <path d="M91 96L92 97L91 96Z" fill="#B3F7DE" />
        <path d="M194 96L195 97L194 96Z" fill="#A8E8F5" />
        <path d="M90 97L91 98L90 97Z" fill="#B0F7DD" />
        <path d="M195 97L196 98L195 97Z" fill="#9EE5F5" />
        <path d="M53 98L54 99L53 98Z" fill="#BBF7E1" />
        <path d="M89 98L90 99L89 98Z" fill="#B6F8DF" />
        <path d="M123 98L124 99L123 98Z" fill="#7BD1EF" />
        <path d="M124 99.3454C119.689 100.42 114.917 106.809 112.063 110C110.825 111.385 109.207 112.993 109.207 115C109.207 118.5 113.777 121.777 116 124C118.358 126.358 122.111 131.624 126 130.654C130.504 129.531 135.992 122.458 138.847 119C140.017 117.583 141.317 115.983 141.079 114.015C140.666 110.603 136.288 108.042 134 105.91C131.548 103.627 127.904 98.3722 124 99.3454Z" fill="#00A6E2" />
        <path d="M159.667 98.3339C159.222 98.7784 160.278 98.7228 160.333 98.6673C160.778 98.2228 159.722 98.2783 159.667 98.3339Z" fill="#A9E7F6" />
        <path d="M196 98L197 99L196 98Z" fill="#A3E7F5" />
        <path d="M88 99L89 100L88 99Z" fill="#B0F6DD" />
        <path d="M122 99L123 100L122 99Z" fill="#79CFEF" />
        <path d="M158 99L159 100L158 99Z" fill="#B3EBF7" />
        <path d="M143 114C146.064 118.349 154.863 130.557 161 129.248C166.877 127.996 172.895 119.105 177 115C173.865 110.55 165.293 99.1555 159 100.056C153.184 100.889 146.957 110.043 143 114Z" fill="white" />
        <path d="M161 99L162 100L161 99Z" fill="#A8E8F6" />
        <path d="M197 99L198 100L197 99Z" fill="#A6E7F5" />
        <path d="M87 100L88 101L87 100Z" fill="#BCF8E2" />
        <path d="M121 100L122 101L121 100Z" fill="#6CCCED" />
        <path d="M162 100L163 101L162 100Z" fill="#B7ECF7" />
        <path d="M86 101L87 102L86 101Z" fill="#BCF8E2" />
        <path d="M120 101L121 102L120 101Z" fill="#72CDED" />
        <path d="M163 101L164 102L163 101Z" fill="#B2EBF7" />
        <path d="M50 102L51 103L50 102Z" fill="#8FF2CE" />
        <path d="M119 102L120 103L119 102Z" fill="#79D0EE" />
        <path d="M164 102L165 103L164 102Z" fill="#B9ECF7" />
        <path d="M199 102L200 103L199 102Z" fill="#61D5EE" />
        <path d="M118 103L119 104L118 103Z" fill="#71CEED" />
        <path d="M117 104L118 105L117 104Z" fill="#6FCDEC" />
        <path d="M116 105L117 106L116 105Z" fill="#64C8EB" />
        <path d="M115 106L116 107L115 106Z" fill="#6ACBEB" />
        <path d="M114 107L115 108L114 107Z" fill="#66CAEC" />
        <path d="M47.333 108.668C47.2774 108.723 47.2218 109.779 47.6663 109.334C47.7218 109.279 47.7775 108.223 47.333 108.668Z" fill="#97F4D1" />
        <path d="M113 108L114 109L113 108Z" fill="#5AC6EA" />
        <path d="M202.333 108.668C202.278 108.723 202.222 109.779 202.667 109.334C202.722 109.279 202.778 108.223 202.333 108.668Z" fill="#6ED8EF" />
        <path d="M112 109L113 110L112 109Z" fill="#55C4E9" />
        <path d="M111 110L112 111L111 110Z" fill="#52C3E9" />
        <path d="M110 111L111 112L110 111Z" fill="#52C3E8" />
        <path d="M203 111V117C203.951 114.715 203.951 113.285 203 111Z" fill="#E0F7FB" />
        <path d="M109 112L110 113L109 112Z" fill="#5FC8EB" />
        <path d="M108 113L109 114L108 113Z" fill="#4EC2E8" />
        <path d="M47 117V120C47.6961 118.446 47.6961 118.554 47 117Z" fill="#70EFC1" />
        <path d="M202.333 119.668C202.278 119.723 202.222 120.779 202.667 120.334C202.722 120.279 202.778 119.223 202.333 119.668Z" fill="#A8E8F5" />
        <path d="M201 122L202 123L201 122Z" fill="#4BD0EC" />
        <path d="M200 125L201 126L200 125Z" fill="#A8E8F6" />
        <path d="M50 126L51 127L50 126Z" fill="#7FF1C7" />
        <path d="M198 128L199 129L198 128Z" fill="#A6E7F6" />
        <path d="M52 129L53 130L52 129Z" fill="#B1F6DD" />
        <path d="M197 129L198 130L197 129Z" fill="#87DFF3" />
        <path d="M53 130L54 131L53 130Z" fill="#9AF4D3" />
        <path d="M159.667 130.334C159.222 130.779 160.278 130.723 160.333 130.668C160.778 130.223 159.722 130.279 159.667 130.334Z" fill="#C7F0F8" />
        <path d="M196 130L197 131L196 130Z" fill="#84DFF3" />
        <path d="M54 131L55 132L54 131Z" fill="#A3F5D7" />
        <path d="M195 131L196 132L195 131Z" fill="#78DCF1" />
        <path d="M55 132L56 133L55 132Z" fill="#ADF6DB" />
        <path d="M124.667 132.334C124.222 132.779 125.278 132.723 125.333 132.668C125.778 132.223 124.722 132.279 124.667 132.334Z" fill="#7FDDF2" />
        <path d="M194 132L195 133L194 132Z" fill="#81DEF2" />
        <path d="M56 133L57 134L56 133Z" fill="#A8F5D9" />
        <path d="M123 133L124 134L123 133Z" fill="#93F4D0" />
        <path d="M124 134.345C120.31 135.265 109.626 144.905 110.037 149.038C110.353 152.216 113.92 154.908 116.001 156.999C118.35 159.361 122.117 164.621 126 163.653C130.019 162.651 140.642 152.533 140.079 148.089C139.71 145.181 135.936 142.821 133.999 140.96C131.586 138.641 127.906 133.371 124 134.345Z" fill="white" />
        <path d="M126 133L127 134L126 133Z" fill="#75DAF1" />
        <path d="M193 133L194 134L193 133Z" fill="#89E0F3" />
        <path d="M57 134L58 135L57 134Z" fill="#B5F7DF" />
        <path d="M122 134L123 135L122 134Z" fill="#98F4D2" />
        <path d="M127 134L128 135L127 134Z" fill="#88DFF2" />
        <path d="M192 134L193 135L192 134Z" fill="#91E2F4" />
        <path d="M58 135L59 136L58 135Z" fill="#B0F6DC" />
        <path d="M121 135L122 136L121 135Z" fill="#A0F5D6" />
        <path d="M128 135L129 136L128 135Z" fill="#80DDF1" />
        <path d="M191 135L192 136L191 135Z" fill="#98E4F4" />
        <path d="M59 136L60 137L59 136Z" fill="#B6F7E0" />
        <path d="M120 136L121 137L120 136Z" fill="#A4F5D8" />
        <path d="M129 136L130 137L129 136Z" fill="#99E3F4" />
        <path d="M190 136L191 137L190 136Z" fill="#91E2F4" />
        <path d="M60 137L61 138L60 137Z" fill="#B6F7E0" />
        <path d="M119 137L120 138L119 137Z" fill="#A0F5D6" />
        <path d="M130 137L131 138L130 137Z" fill="#9AE4F4" />
        <path d="M189 137L190 138L189 137Z" fill="#89E0F2" />
        <path d="M61 138L62 139L61 138Z" fill="#B7F7DF" />
        <path d="M118 138L119 139L118 138Z" fill="#AAF6DA" />
        <path d="M131 138L132 139L131 138Z" fill="#98E4F5" />
        <path d="M188 138L189 139L188 138Z" fill="#99E4F5" />
        <path d="M62 139L63 140L62 139Z" fill="#BCF8E2" />
        <path d="M117 139L118 140L117 139Z" fill="#AAF6DA" />
        <path d="M132 139L133 140L132 139Z" fill="#A0E5F5" />
        <path d="M187 139L188 140L187 139Z" fill="#9DE5F5" />
        <path d="M116 140L117 141L116 140Z" fill="#B0F6DD" />
        <path d="M133 140L134 141L133 140Z" fill="#99E4F4" />
        <path d="M186 140L187 141L186 140Z" fill="#ABE8F7" />
        <path d="M115 141L116 142L115 141Z" fill="#ACF6DB" />
        <path d="M134 141L135 142L134 141Z" fill="#A4E7F5" />
        <path d="M185 141L186 142L185 141Z" fill="#AAE8F6" />
        <path d="M114 142L115 143L114 142Z" fill="#A9F7DA" />
        <path d="M135 142L136 143L135 142Z" fill="#A8E8F6" />
        <path d="M184 142L185 143L184 142Z" fill="#A3E7F6" />
        <path d="M113 143L114 144L113 143Z" fill="#B8F8E0" />
        <path d="M136 143L137 144L136 143Z" fill="#AAE9F6" />
        <path d="M183 143L184 144L183 143Z" fill="#AFEAF7" />
        <path d="M112 144L113 145L112 144Z" fill="#BBF8E2" />
        <path d="M137 144L138 145L137 144Z" fill="#B6EBF7" />
        <path d="M182 144L183 145L182 144Z" fill="#AFEAF7" />
        <path d="M181 145L182 146L181 145Z" fill="#B3ECF8" />
        <path d="M180 146L181 147L180 146Z" fill="#B8ECF8" />
        <path d="M109 147L110 148L109 147Z" fill="#B8F8E0" />
        <path d="M179 147L180 148L179 147Z" fill="#B3EBF7" />
        <path d="M108.333 148.668C108.278 148.723 108.222 149.779 108.667 149.334C108.722 149.279 108.778 148.223 108.333 148.668Z" fill="#97F3D1" />
        <path d="M141.333 148.668C141.278 148.723 141.222 149.779 141.667 149.334C141.722 149.279 141.778 148.223 141.333 148.668Z" fill="#77DBF0" />
        <path d="M109 150L110 151L109 150Z" fill="#8BF2CC" />
        <path d="M140 150L141 151L140 150Z" fill="#78DBF0" />
        <path d="M110 151L111 152L110 151Z" fill="#86F2CA" />
        <path d="M139 151L140 152L139 151Z" fill="#81DDF1" />
        <path d="M111 152L112 153L111 152Z" fill="#80F1C8" />
        <path d="M138 152L139 153L138 152Z" fill="#6AD7EE" />
        <path d="M137 153L138 154L137 153Z" fill="#67D6EF" />
        <path d="M113 154L114 155L113 154Z" fill="#83F1CA" />
        <path d="M136 154L137 155L136 154Z" fill="#5FD4ED" />
        <path d="M135 155L136 156L135 155Z" fill="#61D4ED" />
        <path d="M134 156L135 157L134 156Z" fill="#6DD8EE" />
        <path d="M133 157L134 158L133 157Z" fill="#5FD4ED" />
        <path d="M132 158L133 159L132 158Z" fill="#59D3ED" />
        <path d="M131 159L132 160L131 159Z" fill="#4CCFEC" />
        <path d="M129 161L130 162L129 161Z" fill="#4BCFEB" />
        <path d="M161 164L162 165L161 164Z" fill="#4ACFEC" />
        <path d="M92 168L93 169L92 168Z" fill="#7FF1C7" />
        <path d="M157 168L158 169L157 168Z" fill="#50D0ED" />
        <path d="M156 169L157 170L156 169Z" fill="#5DD4EE" />
        <path d="M94 170L95 171L94 170Z" fill="#88F2CC" />
        <path d="M155 170L156 171L155 170Z" fill="#5AD3EE" />
        <path d="M95 171L96 172L95 171Z" fill="#95F3D1" />
        <path d="M154 171L155 172L154 171Z" fill="#5CD4ED" />
        <path d="M96 172L97 173L96 172Z" fill="#92F3CF" />
        <path d="M153 172L154 173L153 172Z" fill="#62D5EE" />
        <path d="M97 173L98 174L97 173Z" fill="#92F3CF" />
        <path d="M152 173L153 174L152 173Z" fill="#64D7EF" />
        <path d="M98 174L99 175L98 174Z" fill="#8FF3CE" />
        <path d="M151 174L152 175L151 174Z" fill="#6ED9F0" />
        <path d="M99 175L100 176L99 175Z" fill="#95F4D1" />
        <path d="M150 175L151 176L150 175Z" fill="#68D7EF" />
        <path d="M100 176L101 177L100 176Z" fill="#A0F5D5" />
        <path d="M149 176L150 177L149 176Z" fill="#67D7EF" />
        <path d="M101 177L102 178L101 177Z" fill="#A1F5D6" />
        <path d="M148 177L149 178L148 177Z" fill="#77DBF0" />
        <path d="M102 178L103 179L102 178Z" fill="#A4F5D7" />
        <path d="M147 178L148 179L147 178Z" fill="#79DCF1" />
        <path d="M103 179L104 180L103 179Z" fill="#A4F6D7" />
        <path d="M146 179L147 180L146 179Z" fill="#89E0F3" />
        <path d="M104 180L105 181L104 180Z" fill="#AAF6DA" />
        <path d="M145 180L146 181L145 180Z" fill="#81DEF2" />
        <path d="M105 181L106 182L105 181Z" fill="#B0F6DD" />
        <path d="M112 181V185C112.71 183.241 112.71 182.759 112 181Z" fill="#21D4C5" />
        <path d="M144 181L145 182L144 181Z" fill="#79DCF1" />
        <path d="M106 182L107 183L106 182Z" fill="#AAF6DA" />
        <path d="M143 182L144 183L143 182Z" fill="#81DEF1" />
        <path d="M107 183L108 184L107 183Z" fill="#B0F6DC" />
        <path d="M142 183L143 184L142 183Z" fill="#89E0F3" />
        <path d="M108 184L109 185L108 184Z" fill="#AAF6DA" />
        <path d="M141 184L142 185L141 184Z" fill="#93E3F4" />
        <path d="M109 185L110 186L109 185Z" fill="#BCF8E2" />
        <path d="M140 185L141 186L140 185Z" fill="#99E4F4" />
        <path d="M139 186L140 187L139 186Z" fill="#8EE1F3" />
        <path d="M138 187L139 188L138 187Z" fill="#9FE6F6" />
        <path d="M137 188L138 189L137 188Z" fill="#A1E6F5" />
        <path d="M113 189L114 190L113 189Z" fill="#B7F8DF" />
        <path d="M136 189L137 190L136 189Z" fill="#A3E7F6" />
        <path d="M135 190L136 191L135 190Z" fill="#A5E7F6" />
        <path d="M115 191L116 192L115 191Z" fill="#B9EEF4" />
        <path d="M134 191L135 192L134 191Z" fill="#9DE5F5" />
        <path d="M133 192L134 193L133 192Z" fill="#B1EAF7" />
        <path d="M131 193L132 194L131 193Z" fill="#52D1ED" />
        <path d="M119 194L120 195L119 194Z" fill="#B8ECF7" />
        <path d="M130 194L131 195L130 194Z" fill="#ADE9F7" />
        <path d="M122 195C124.052 195.874 125.747 195.953 128 196C125.948 195.126 124.253 195.047 122 195Z" fill="#75DAF0" />
      </g>
      <defs>
        <clipPath id={`url(#${id}__a`}>
        <rect width="250" height="250" fill="white" />
        </clipPath>
      </defs>
    </svg>
);

export default SvgPerp;
