import * as React from 'react';
import { SVGProps } from 'react';

const SvgDausdc = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
<svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M124.987 245.002C192.397 245.002 247.044 190.375 247.044 122.989C247.044 55.6035 192.397 0.976562 124.987 0.976562C57.5764 0.976562 2.92969 55.6035 2.92969 122.989C2.92969 190.375 57.5764 245.002 124.987 245.002Z" fill="#EDF0F2" />
    <path d="M125 250C116.538 250.002 108.097 249.152 99.8049 247.461C83.8156 244.186 68.6277 237.794 55.1077 228.651C35.0107 215.085 19.2794 195.982 9.81975 173.656C6.62477 166.096 4.18612 158.237 2.5395 150.196C-0.846499 133.572 -0.846499 116.436 2.5395 99.8119C5.81438 83.8227 12.2055 68.6348 21.348 55.1147C34.9144 35.0166 54.0183 19.2842 76.345 9.8238C83.9054 6.62738 91.7637 4.18742 99.8049 2.53964C116.429 -0.846546 133.565 -0.846546 150.189 2.53964C166.178 5.81447 181.366 12.2055 194.886 21.3482C214.983 34.9145 230.714 54.018 240.174 76.3442C243.371 83.9048 245.812 91.7634 247.461 99.805C250.847 116.429 250.847 133.565 247.461 150.189C244.186 166.178 237.795 181.366 228.652 194.886C215.085 214.982 195.982 230.714 173.656 240.174C166.096 243.371 158.237 245.812 150.195 247.461C141.904 249.152 133.463 250.002 125 250V250ZM125 13.3922C117.445 13.3905 109.91 14.1502 102.507 15.6597C88.2305 18.5835 74.6695 24.29 62.5979 32.4536C44.6566 44.5678 30.613 61.6247 22.1684 81.558C19.3146 88.3093 17.1363 95.3266 15.6654 102.507C12.6422 117.35 12.6422 132.65 15.6654 147.492C18.5903 161.769 24.2978 175.329 32.4623 187.401C44.5754 205.344 61.632 219.39 81.5657 227.837C88.3174 230.69 95.335 232.868 102.516 234.339C117.358 237.363 132.658 237.363 147.501 234.339C161.778 231.415 175.339 225.708 187.409 217.542C205.347 205.428 219.388 188.373 227.832 168.443C230.686 161.691 232.864 154.673 234.335 147.492C237.358 132.65 237.358 117.35 234.335 102.507C231.41 88.2312 225.702 74.6708 217.538 62.6C205.427 44.6591 188.373 30.6149 168.442 22.1685C161.691 19.3149 154.673 17.1366 147.492 15.6656C140.09 14.1545 132.555 13.3931 125 13.3931V13.3922Z" fill={`url(#${id}__a`} />
    <path d="M125.001 220.982C143.984 220.982 162.541 215.353 178.325 204.806C194.109 194.26 206.411 179.27 213.676 161.731C220.941 144.193 222.841 124.895 219.138 106.276C215.435 87.6576 206.293 70.5554 192.87 57.1322C179.447 43.7089 162.345 34.5675 143.727 30.8639C125.108 27.1604 105.81 29.061 88.2713 36.3254C70.733 43.5899 55.7427 55.8919 45.196 71.6758C34.6494 87.4597 29.02 106.017 29.0198 125C28.9893 137.613 31.4511 150.108 36.2638 161.766C41.0764 173.425 48.1452 184.018 57.0639 192.937C65.9827 201.856 76.5757 208.925 88.2344 213.738C99.8932 218.55 112.388 221.012 125.001 220.982V220.982Z" fill="#2775CA" />
    <path d="M151.396 140.196C151.396 126.199 142.998 121.4 126.201 119.401C114.203 117.802 111.801 114.602 111.801 109.003C111.801 103.403 115.804 99.8047 123.799 99.8047C130.997 99.8047 134.997 102.204 136.996 108.203C137.202 108.783 137.581 109.285 138.082 109.642C138.582 110 139.181 110.195 139.796 110.202H146.194C146.564 110.211 146.932 110.145 147.276 110.007C147.619 109.87 147.932 109.664 148.193 109.402C148.455 109.141 148.661 108.829 148.799 108.485C148.936 108.141 149.003 107.773 148.994 107.403V107.003C148.22 102.669 146.036 98.7118 142.781 95.7468C139.527 92.7817 135.384 90.9747 130.997 90.6064V81.0088C130.997 79.4092 129.797 78.209 127.798 77.8086H121.799C120.199 77.8086 118.999 79.0088 118.598 81.0088V90.207C106.6 91.8066 99.0028 99.8056 99.0028 109.804C99.0028 123.001 107.001 128.199 123.798 130.2C134.996 132.199 138.595 134.595 138.595 140.998C138.595 147.401 132.996 151.796 125.398 151.796C115 151.796 111.4 147.396 110.201 141.397C110.081 140.737 109.738 140.137 109.228 139.7C108.718 139.263 108.073 139.015 107.402 138.998H100.602C100.232 138.989 99.8643 139.055 99.5207 139.193C99.1771 139.331 98.865 139.537 98.6033 139.798C98.3416 140.06 98.1358 140.372 97.9983 140.716C97.8609 141.06 97.7946 141.428 97.8035 141.798V142.198C99.4022 152.195 105.802 159.394 118.995 161.394V170.993C118.995 172.592 120.195 173.792 122.194 174.192H128.193C129.793 174.192 130.993 172.992 131.393 170.993V161.394C143.391 159.394 151.389 150.996 151.389 140.198L151.396 140.196Z" fill="white" />
    <path d="M104.605 182.189C93.0952 177.97 83.1591 170.319 76.1396 160.27C69.12 150.22 65.3558 138.258 65.3558 126C65.3558 113.742 69.12 101.779 76.1396 91.7302C83.1591 81.6809 93.0952 74.0297 104.605 69.811C105.372 69.477 106.016 68.9115 106.446 68.1936C106.876 67.4757 107.071 66.6413 107.004 65.8071V60.2085C107.058 59.4752 106.845 58.7471 106.404 58.1588C105.963 57.5706 105.324 57.1622 104.605 57.0093C104.041 56.9501 103.474 57.0915 103.005 57.4087C88.445 62.0356 75.7353 71.1759 66.7155 83.5065C57.6957 95.837 52.834 110.718 52.834 125.995C52.834 141.273 57.6957 156.154 66.7155 168.484C75.7353 180.815 88.445 189.955 103.005 194.582C103.337 194.775 103.711 194.887 104.095 194.907C104.478 194.928 104.862 194.857 105.213 194.701C105.564 194.545 105.874 194.308 106.115 194.009C106.357 193.71 106.525 193.359 106.605 192.983C107.005 192.583 107.005 192.183 107.005 191.384V185.784C107.004 184.588 105.804 182.988 104.605 182.189ZM146.993 57.4126C146.661 57.2197 146.287 57.1083 145.903 57.0876C145.519 57.0669 145.136 57.1375 144.785 57.2936C144.434 57.4497 144.124 57.6868 143.883 57.9856C143.641 58.2843 143.473 58.6362 143.393 59.0122C142.993 59.4126 142.993 59.812 142.993 60.6118V66.2105C143.061 67.0166 143.313 67.7965 143.729 68.4905C144.145 69.1845 144.714 69.7741 145.392 70.2143C156.902 74.433 166.838 82.0843 173.857 92.1335C180.877 102.183 184.641 114.145 184.641 126.403C184.641 138.661 180.877 150.624 173.857 160.673C166.838 170.722 156.902 178.373 145.392 182.592C144.625 182.926 143.981 183.492 143.551 184.209C143.121 184.927 142.926 185.762 142.993 186.596V192.195C142.939 192.928 143.152 193.656 143.593 194.244C144.034 194.832 144.673 195.241 145.392 195.394C145.956 195.453 146.522 195.312 146.992 194.994C161.544 190.301 174.234 181.114 183.235 168.754C192.236 156.394 197.085 141.498 197.085 126.207C197.085 110.917 192.236 96.0207 183.235 83.6606C174.234 71.3004 161.544 62.1133 146.992 57.4204L146.993 57.4126Z" fill="white" />
    <rect x="25" y="119" width="100" height="100" rx="50" fill={`url(#${id}__b`} />
    <path d="M87.75 202H81.5V195.75H75.25V202H69H62.75H56.5V195.75H50.25V189.5V183.25V177V170.75V164.5V158.25H56.5V152H62.75H69H75.25V158.25H81.5V152V145.75V139.5V133.25H87.75H94V139.5V145.75V152V158.25V164.5V170.75V177V183.25V189.5V195.75V202H87.75ZM69 189.5H75.25V183.25H81.5V177V170.75H75.25V164.5H69H62.75V170.75V177V183.25V189.5H69Z" fill={`url(#${id}__c`} />
    <defs>
    <linearGradient id={`${id}__a`} x1="210.75" y1="33.75" x2="51.5003" y2="221.5" gradientUnits="userSpaceOnUse">
    <stop stopColor="#B6509E" />
    <stop offset="1" stopColor="#2EBAC6" />
    </linearGradient>
    <radialGradient id={`${id}__b`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(50.6 147) rotate(71.2791) scale(76.0221)">
    <stop stopColor="#66A9F7" />
    <stop offset="1" stopColor="#0075FF" />
    </radialGradient>
    <radialGradient id={`${id}__c`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(80.0548 136.987) rotate(76.1548) scale(89.6165 97.9959)">
    <stop stopColor="#334155" />
    <stop offset="1" stopColor="#050A30" />
    </radialGradient>
    </defs>
</svg>


);

export default SvgDausdc;
