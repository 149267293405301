import * as React from 'react';
import { SVGProps } from 'react';

const SvgDcrv = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M125 250C194.036 250 250 194.036 250 125C250 55.9644 194.036 0 125 0C55.9644 0 0 55.9644 0 125C0 194.036 55.9644 250 125 250Z" fill="white" />
<g clipPath={`url(#${id}__a`}>
<path d="M119.053 146.18L103.817 149.555L101.888 142.419L118.137 139.478L119.053 146.18Z" fill="#0000FF" />
<path d="M103.817 149.555L90.5093 154.039L87.6646 146.614L101.888 142.419L103.817 149.555Z" fill="#0000FF" />
<path d="M118.089 139.478L101.888 142.419L100.201 134.078L117.269 131.57L118.089 139.478Z" fill="#0028FF" />
<path d="M101.888 142.419L87.6642 146.613L85.0605 137.934L100.2 134.077L101.888 142.419Z" fill="#0018FF" />
<path d="M120.066 151.628L105.842 155.341L103.769 149.555L119.053 146.18L120.066 151.628Z" fill="#0000F1" />
<path d="M105.842 155.341L93.5951 160.066L90.5093 154.039L103.817 149.555L105.842 155.341Z" fill="#0000DA" />
<path d="M117.269 131.57L100.201 134.077L98.7061 124.771L116.546 122.746L117.269 131.57Z" fill="#0058FF" />
<path d="M100.201 134.077L85.0607 137.934L82.8428 128.195L98.7058 124.771L100.201 134.077Z" fill="#0048FF" />
<path d="M135.736 144.01L119.053 146.179L118.137 139.477L135.687 137.693L135.736 144.01Z" fill="#0014FF" />
<path d="M121.174 155.63L107.963 159.584L105.842 155.341L120.066 151.628L121.174 155.63Z" fill="#0000DA" />
<path d="M90.5091 154.039L79.6605 159.632L75.8032 151.965L87.6644 146.613L90.5091 154.039Z" fill="#0000E8" />
<path d="M107.964 159.584L96.8257 164.55L93.5952 160.066L105.842 155.341L107.964 159.584Z" fill="#0000C4" />
<path d="M135.688 137.693L118.137 139.477L117.269 131.57L135.639 130.316L135.688 137.693Z" fill="#003CFF" />
<path d="M87.6644 146.613L75.8514 151.965L72.3799 142.997L85.0607 137.935L87.6644 146.613Z" fill="#0008FF" />
<path d="M135.832 149.024L120.066 151.628L119.053 146.179L135.736 144.01L135.832 149.024Z" fill="#0000FF" />
<path d="M93.5951 160.066L83.759 165.804L79.6606 159.632L90.5092 154.039L93.5951 160.066Z" fill="#0000C8" />
<path d="M116.546 122.746L98.7059 124.771L97.5005 114.694L115.967 113.199L116.546 122.746Z" fill="#0090FF" />
<path d="M98.706 124.771L82.843 128.195L81.0107 117.636L97.5006 114.694L98.706 124.771Z" fill="#0084FF" />
<path d="M135.639 130.316L117.269 131.57L116.546 122.746L135.591 122.071L135.639 130.316Z" fill="#0068FF" />
<path d="M85.0608 137.934L72.38 142.997L69.3906 132.968L82.8429 128.194L85.0608 137.934Z" fill="#003CFF" />
<path d="M135.928 152.737L121.174 155.63L120.065 151.628L135.832 149.024L135.928 152.737Z" fill="#0000F6" />
<path d="M122.235 158.089L110.133 162.235L107.963 159.584L121.175 155.63L122.235 158.089Z" fill="#0000D1" />
<path d="M96.8253 164.55L88.05 170.384L83.7588 165.803L93.5949 160.065L96.8253 164.55Z" fill="#0000AD" />
<path d="M110.133 162.235L100.104 167.298L96.8257 164.549L107.964 159.583L110.133 162.235Z" fill="#0000BB" />
<path d="M135.591 122.072L116.546 122.747L115.967 113.2L135.591 113.104V122.072Z" fill="#009CFF" />
<path d="M82.8429 128.195L69.3907 132.968L66.9316 122.072L81.0107 117.636L82.8429 128.195Z" fill="#0078FF" />
<path d="M136.025 154.954L122.235 158.088L121.174 155.629L135.928 152.736L136.025 154.954Z" fill="#0000ED" />
<path d="M115.967 113.199L97.5006 114.694L96.5845 104.086L115.533 103.122L115.967 113.199Z" fill="#00CCFF" />
<path d="M153.093 142.852L135.736 144.01L135.688 137.693L153.817 137.066L153.093 142.852Z" fill="#002CFF" />
<path d="M97.5003 114.694L80.9622 117.636L79.564 106.546L96.5842 104.087L97.5003 114.694Z" fill="#00C4FF" />
<path d="M152.322 147.481L135.832 149.024L135.736 144.01L153.094 142.853L152.322 147.481Z" fill="#0014FF" />
<path d="M153.817 137.067L135.687 137.694L135.639 130.365L154.492 130.269L153.817 137.067Z" fill="#0050FF" />
<path d="M79.6604 159.631L71.3673 166.14L66.6421 158.378L75.8031 151.965L79.6604 159.631Z" fill="#0000DA" />
<path d="M75.8035 151.965L66.6424 158.378L62.3994 149.217L72.3801 142.997L75.8035 151.965Z" fill="#0000FF" />
<path d="M100.104 167.298L92.3897 173.18L88.0503 170.384L96.8256 164.55L100.104 167.298Z" fill="#0000A4" />
<path d="M83.7587 165.803L76.3817 172.409L71.3672 166.141L79.6603 159.632L83.7587 165.803Z" fill="#0000B6" />
<path d="M123.344 158.957L112.303 163.2L110.133 162.235L122.236 158.089L123.344 158.957Z" fill="#0000D6" />
<path d="M151.55 150.808L135.928 152.737L135.832 149.024L152.322 147.481L151.55 150.808Z" fill="#0004FF" />
<path d="M154.492 130.268L135.64 130.316L135.591 122.071L155.071 122.602L154.492 130.268Z" fill="#0078FF" />
<path d="M112.303 163.2L103.383 168.311L100.104 167.298L110.133 162.235L112.303 163.2Z" fill="#0000BF" />
<path d="M135.591 113.103L115.967 113.199L115.533 103.122L135.591 103.604V113.103Z" fill="#00D8FF" />
<path d="M72.3799 142.997L62.3992 149.217L58.6865 138.995L69.3905 132.968L72.3799 142.997Z" fill="#0034FF" />
<path d="M136.122 155.678L123.344 158.957L122.235 158.137L136.025 154.955L136.122 155.678Z" fill="#0000F1" />
<path d="M88.0501 170.384L81.6856 176.99L76.3818 172.409L83.7589 165.804L88.0501 170.384Z" fill="#00009F" />
<path d="M80.9626 117.636L66.9317 122.071L65.0513 110.596L79.5643 106.546L80.9626 117.636Z" fill="#00BCFF" />
<path d="M150.779 152.736L136.025 154.954L135.929 152.736L151.551 150.808L150.779 152.736Z" fill="#0000FF" />
<path d="M115.533 103.122L96.5845 104.087L96.0059 93.1898L115.244 92.7559L115.533 103.122Z" fill="#23FFD4" />
<path d="M155.071 122.602L135.591 122.072V113.104L155.601 114.261L155.071 122.602Z" fill="#00ACFF" />
<path d="M96.5844 104.087L79.6124 106.546L78.6963 95.1673L96.0058 93.1904L96.5844 104.087Z" fill="#1CFFDB" />
<path d="M103.383 168.31L96.7291 174.193L92.3896 173.18L100.104 167.298L103.383 168.31Z" fill="#0000AD" />
<path d="M69.3905 132.968L58.6865 138.995L55.6489 127.809L66.9315 122.071L69.3905 132.968Z" fill="#0070FF" />
<path d="M124.405 158.186L114.376 162.477L112.303 163.2L123.344 158.957L124.405 158.186Z" fill="#0000E8" />
<path d="M92.3895 173.18L87.0375 179.738L81.6855 176.989L88.0501 170.384L92.3895 173.18Z" fill="#000096" />
<path d="M135.591 103.604L115.533 103.122L115.244 92.7559L135.64 93.8648L135.591 103.604Z" fill="#26FFD1" />
<path d="M150.008 153.267L136.121 155.629L136.025 154.954L150.779 152.736L150.008 153.267Z" fill="#0000FF" />
<path d="M170.259 142.708L153.094 142.852L153.817 137.066L171.657 137.5L170.259 142.708Z" fill="#0044FF" />
<path d="M168.764 146.855L152.322 147.481L153.094 142.853L170.259 142.708L168.764 146.855Z" fill="#002CFF" />
<path d="M114.424 162.477L106.517 167.539L103.383 168.311L112.303 163.2L114.424 162.477Z" fill="#0000D1" />
<path d="M155.601 114.26L135.591 113.103V103.604L155.987 105.437L155.601 114.26Z" fill="#00E0FB" />
<path d="M136.218 154.81L124.405 158.185L123.344 158.956L136.121 155.677L136.218 154.81Z" fill="#0000FF" />
<path d="M171.657 137.501L153.817 137.067L154.492 130.269L172.91 131.281L171.657 137.501Z" fill="#0064FF" />
<path d="M79.6122 106.546L65.051 110.596L63.7974 98.7832L78.6961 95.167L79.6122 106.546Z" fill="#19FFDE" />
<path d="M167.269 149.796L151.551 150.808L152.322 147.481L168.764 146.854L167.269 149.796Z" fill="#001CFF" />
<path d="M71.3676 166.141L65.5817 173.373L60.1333 165.61L66.6425 158.378L71.3676 166.141Z" fill="#0000D1" />
<path d="M76.3819 172.409L71.4639 179.593L65.5815 173.373L71.3675 166.141L76.3819 172.409Z" fill="#0000A8" />
<path d="M115.244 92.7562L96.0057 93.1901L95.7646 82.2451L115.147 82.3898L115.244 92.7562Z" fill="#5AFF9D" />
<path d="M172.91 131.28L154.492 130.268L155.07 122.602L174.019 124.241L172.91 131.28Z" fill="#008CFF" />
<path d="M66.6424 158.378L60.085 165.61L55.167 156.401L62.3994 149.217L66.6424 158.378Z" fill="#0000FF" />
<path d="M66.9314 122.071L55.6488 127.857L53.3345 116.044L65.051 110.596L66.9314 122.071Z" fill="#00B8FF" />
<path d="M96.0057 93.1901L78.6961 95.167L78.2622 83.788L95.7646 82.2451L96.0057 93.1901Z" fill="#5AFF9D" />
<path d="M165.726 151.435L150.779 152.737L151.551 150.808L167.269 149.796L165.726 151.435Z" fill="#0018FF" />
<path d="M106.517 167.539L100.972 173.325L96.729 174.193L103.383 168.311L106.517 167.539Z" fill="#0000BF" />
<path d="M96.7291 174.193L92.3897 180.654L87.0859 179.738L92.3897 173.229L96.7291 174.193Z" fill="#00009B" />
<path d="M81.6858 176.99L77.6356 184.077L71.5122 179.593L76.382 172.409L81.6858 176.99Z" fill="#000092" />
<path d="M149.236 152.351L136.218 154.81L136.122 155.677L150.008 153.267L149.236 152.351Z" fill="#000CFF" />
<path d="M62.399 149.217L55.1666 156.401L50.8271 146.083L58.6864 138.995L62.399 149.217Z" fill="#002CFF" />
<path d="M135.639 93.8651L115.244 92.7561L115.147 82.3896L135.687 84.1254L135.639 93.8651Z" fill="#5DFF9A" />
<path d="M155.987 105.437L135.591 103.605L135.64 93.8652L156.276 96.3243L155.987 105.437Z" fill="#2CFFCA" />
<path d="M174.019 124.241L155.07 122.602L155.601 114.261L174.984 116.527L174.019 124.241Z" fill="#00B8FF" />
<path d="M125.418 155.774L116.401 159.969L114.424 162.476L124.405 158.185L125.418 155.774Z" fill="#0000FF" />
<path d="M136.362 152.447L125.417 155.774L124.405 158.185L136.218 154.81L136.362 152.447Z" fill="#000CFF" />
<path d="M164.183 151.772L150.008 153.267L150.779 152.736L165.726 151.435L164.183 151.772Z" fill="#0018FF" />
<path d="M116.401 159.969L109.506 164.935L106.517 167.539L114.425 162.476L116.401 159.969Z" fill="#0000F1" />
<path d="M78.6962 95.1671L63.7975 98.7833L63.2671 86.9703L78.2623 83.7881L78.6962 95.1671Z" fill="#56FFA0" />
<path d="M87.0856 179.738L83.9033 186.729L77.6353 184.077L81.6854 176.989L87.0856 179.738Z" fill="#000089" />
<path d="M58.6866 138.995L50.8274 146.082L47.3076 134.8L55.649 127.809L58.6866 138.995Z" fill="#006CFF" />
<path d="M65.0509 110.596L53.3344 116.045L51.7915 103.942L63.7973 98.7832L65.0509 110.596Z" fill="#16FFE1" />
<path d="M174.983 116.526L155.601 114.26L155.986 105.437L175.707 108.378L174.983 116.526Z" fill="#06ECF1" />
<path d="M115.147 82.3899L95.7646 82.2452L95.8611 71.6377L115.196 72.3127L115.147 82.3899Z" fill="#94FF63" />
<path d="M148.561 150.037L136.362 152.448L136.218 154.81L149.236 152.351L148.561 150.037Z" fill="#0024FF" />
<path d="M100.972 173.325L97.597 179.641L92.3896 180.654L96.7291 174.193L100.972 173.325Z" fill="#0000AD" />
<path d="M184.241 147.096L168.764 146.855L170.258 142.708L186.266 143.431L184.241 147.096Z" fill="#0044FF" />
<path d="M186.266 143.431L170.258 142.707L171.657 137.5L188.195 138.754L186.266 143.431Z" fill="#005CFF" />
<path d="M156.276 96.3237L135.639 93.8646L135.687 84.125L156.468 87.2108L156.276 96.3237Z" fill="#5DFF9A" />
<path d="M95.7646 82.2452L78.2622 83.7881L78.4069 72.6502L95.861 71.6377L95.7646 82.2452Z" fill="#94FF63" />
<path d="M162.737 150.76L149.236 152.351L150.008 153.267L164.183 151.772L162.737 150.76Z" fill="#0028FF" />
<path d="M109.506 164.936L104.926 170.577L100.972 173.325L106.517 167.539L109.506 164.936Z" fill="#0000DF" />
<path d="M182.12 149.651L167.269 149.796L168.764 146.854L184.241 147.096L182.12 149.651Z" fill="#0038FF" />
<path d="M188.195 138.754L171.657 137.5L172.91 131.28L189.931 133.112L188.195 138.754Z" fill="#0078FF" />
<path d="M135.687 84.1254L115.147 82.3896L115.196 72.3125L135.736 74.5787L135.687 84.1254Z" fill="#90FF66" />
<path d="M92.3898 180.653L90.1237 187.452L83.9038 186.729L87.0861 179.737L92.3898 180.653Z" fill="#00008D" />
<path d="M126.382 151.772L118.185 155.823L116.401 159.969L125.418 155.774L126.382 151.772Z" fill="#001CFF" />
<path d="M179.95 151.001L165.726 151.435L167.269 149.796L182.071 149.651L179.95 151.001Z" fill="#0034FF" />
<path d="M136.459 148.59L126.382 151.772L125.417 155.774L136.363 152.447L136.459 148.59Z" fill="#0030FF" />
<path d="M189.93 133.113L172.91 131.281L174.019 124.241L191.473 126.748L189.93 133.113Z" fill="#00A0FF" />
<path d="M175.707 108.378L155.986 105.437L156.276 96.3242L176.237 99.9404L175.707 108.378Z" fill="#33FFC4" />
<path d="M55.6489 127.809L47.3075 134.8L44.6074 122.842L53.3345 116.044L55.6489 127.809Z" fill="#00B0FF" />
<path d="M65.5816 173.373L62.2064 181.088L56.083 173.421L60.0849 165.61L65.5816 173.373Z" fill="#0000C8" />
<path d="M71.5122 179.593L68.7639 187.163L62.2065 181.088L65.5817 173.373L71.5122 179.593Z" fill="#0000A4" />
<path d="M118.185 155.822L112.255 160.644L109.506 164.935L116.401 159.969L118.185 155.822Z" fill="#0008FF" />
<path d="M60.1332 165.611L56.083 173.422L50.5864 164.309L55.1669 156.401L60.1332 165.611Z" fill="#0000FF" />
<path d="M78.2623 83.7883L63.2671 86.9705L63.4117 75.4469L78.4069 72.6504L78.2623 83.7883Z" fill="#94FF63" />
<path d="M177.828 151.146L164.183 151.772L165.726 151.435L179.95 151.001L177.828 151.146Z" fill="#0034FF" />
<path d="M161.386 148.445L148.561 150.036L149.236 152.351L162.737 150.76L161.386 148.445Z" fill="#003CFF" />
<path d="M63.7975 98.7826L51.7917 103.942L51.1167 91.7913L63.2671 86.9697L63.7975 98.7826Z" fill="#56FFA0" />
<path d="M77.6354 184.077L75.6103 191.454L68.7637 187.163L71.512 179.593L77.6354 184.077Z" fill="#000089" />
<path d="M147.983 146.275L136.459 148.59L136.363 152.447L148.561 150.036L147.983 146.275Z" fill="#0044FF" />
<path d="M191.474 126.748L174.02 124.241L174.984 116.526L192.776 119.709L191.474 126.748Z" fill="#00C8FF" />
<path d="M156.469 87.2107L135.688 84.1249L135.736 74.5781L156.469 78.2908V87.2107Z" fill="#90FF66" />
<path d="M115.196 72.3121L95.8613 71.6371L96.2953 61.5117L115.485 62.7171L115.196 72.3121Z" fill="#CAFF2C" />
<path d="M104.926 170.576L102.515 176.7L97.5967 179.641L100.972 173.324L104.926 170.576Z" fill="#0000CD" />
<path d="M55.1668 156.401L50.5863 164.309L45.7646 153.99L50.8273 146.083L55.1668 156.401Z" fill="#0028FF" />
<path d="M95.8609 71.6371L78.4067 72.6496L79.0335 62.0903L96.2949 61.5117L95.8609 71.6371Z" fill="#CEFF29" />
<path d="M135.736 74.5789L115.196 72.3128L115.485 62.7178L135.784 65.5143L135.736 74.5789Z" fill="#C7FF30" />
<path d="M97.597 179.642L96.1988 186.247L90.1235 187.453L92.4379 180.654L97.597 179.642Z" fill="#00009F" />
<path d="M176.237 99.94L156.276 96.3238L156.469 87.2109L176.527 91.4539L176.237 99.94Z" fill="#60FF97" />
<path d="M175.852 150.037L162.737 150.76L164.183 151.773L177.828 151.146L175.852 150.037Z" fill="#0040FF" />
<path d="M112.255 160.596L108.59 166.044L104.926 170.576L109.506 164.935L112.255 160.596Z" fill="#0000FF" />
<path d="M83.9035 186.729L82.6017 193.865L75.6104 191.454L77.6354 184.077L83.9035 186.729Z" fill="#000080" />
<path d="M53.3348 116.045L44.6077 122.843L42.8237 110.5L51.7919 103.942L53.3348 116.045Z" fill="#16FFE1" />
<path d="M192.775 119.709L174.983 116.526L175.707 108.378L193.788 112.235L192.775 119.709Z" fill="#0FF8E7" />
<path d="M136.555 143.335L127.202 146.276L126.382 151.773L136.459 148.591L136.555 143.335Z" fill="#0058FF" />
<path d="M127.201 146.275L119.824 150.084L118.185 155.822L126.382 151.772L127.201 146.275Z" fill="#0048FF" />
<path d="M160.181 144.829L147.982 146.276L148.561 150.036L161.386 148.445L160.181 144.829Z" fill="#0058FF" />
<path d="M50.8274 146.083L45.7647 153.991L41.811 142.66L47.3077 134.801L50.8274 146.083Z" fill="#0068FF" />
<path d="M197.742 148.059L184.241 147.095L186.266 143.431L200.201 144.877L197.742 148.059Z" fill="#0060FF" />
<path d="M195.186 150.23L182.12 149.651L184.241 147.096L197.742 148.06L195.186 150.23Z" fill="#0054FF" />
<path d="M200.201 144.877L186.266 143.431L188.195 138.754L202.515 140.683L200.201 144.877Z" fill="#0074FF" />
<path d="M147.452 141.262L136.555 143.335L136.459 148.591L147.983 146.276L147.452 141.262Z" fill="#006CFF" />
<path d="M119.824 150.085L114.665 154.617L112.254 160.596L118.185 155.823L119.824 150.085Z" fill="#0038FF" />
<path d="M173.923 147.674L161.387 148.445L162.737 150.76L175.803 150.036L173.923 147.674Z" fill="#0054FF" />
<path d="M156.469 78.2909L135.736 74.5783L135.784 65.5137L156.421 69.7567L156.469 78.2909Z" fill="#C1FF36" />
<path d="M90.1233 187.453L89.4964 194.396L82.6016 193.914L83.9034 186.729L90.1233 187.453Z" fill="#000084" />
<path d="M63.2671 86.9699L51.1167 91.7915L51.3096 79.8822L63.4118 75.4463L63.2671 86.9699Z" fill="#97FF60" />
<path d="M192.582 151.242L179.95 151.001L182.119 149.651L195.186 150.23L192.582 151.242Z" fill="#004CFF" />
<path d="M78.4068 72.6501L63.4116 75.4467L64.2313 64.4534L79.0336 62.0908L78.4068 72.6501Z" fill="#D1FF26" />
<path d="M202.515 140.683L188.195 138.755L189.931 133.113L204.636 135.669L202.515 140.683Z" fill="#0090FF" />
<path d="M193.788 112.236L175.707 108.378L176.237 99.9404L194.463 104.473L193.788 112.236Z" fill="#36FFC1" />
<path d="M176.527 91.454L156.469 87.211V78.291L176.527 83.1608V91.454Z" fill="#8DFF6A" />
<path d="M115.485 62.7171L96.2949 61.5117L97.0664 52.2061L115.871 53.8454L115.485 62.7171Z" fill="#FFEA00" />
<path d="M108.59 166.044L106.999 171.975L102.515 176.7L104.926 170.576L108.59 166.044Z" fill="#0000FF" />
<path d="M190.027 151.194L177.828 151.146L179.95 151.001L192.582 151.242L190.027 151.194Z" fill="#0050FF" />
<path d="M102.515 176.7L101.888 183.113L96.1987 186.247L97.597 179.641L102.515 176.7Z" fill="#0000C4" />
<path d="M204.637 135.668L189.931 133.113L191.474 126.748L206.517 129.882L204.637 135.668Z" fill="#00B0FF" />
<path d="M135.784 65.514L115.485 62.7174L115.871 53.8457L135.88 57.1244L135.784 65.514Z" fill="#FBF100" />
<path d="M159.12 140.056L147.452 141.261L147.983 146.276L160.181 144.829L159.12 140.056Z" fill="#0080FF" />
<path d="M96.295 61.5117L79.0337 62.0903L80.1909 52.3507L97.0665 52.2061L96.295 61.5117Z" fill="#FFE200" />
<path d="M51.7918 103.942L42.8236 110.5L42.0039 98.1083L51.1167 91.792L51.7918 103.942Z" fill="#53FFA4" />
<path d="M47.3078 134.8L41.8112 142.66L38.8218 130.654L44.6077 122.843L47.3078 134.8Z" fill="#00B0FF" />
<path d="M114.666 154.617L111.773 159.776L108.59 166.044L112.255 160.596L114.666 154.617Z" fill="#002CFF" />
<path d="M172.235 144.202L160.181 144.829L161.387 148.445L173.923 147.674L172.235 144.202Z" fill="#0070FF" />
<path d="M68.7636 187.163L68.1851 194.492L61.3384 188.513L62.2063 181.088L68.7636 187.163Z" fill="#00009F" />
<path d="M62.2065 181.088L61.3386 188.513L54.9741 180.944L56.0831 173.422L62.2065 181.088Z" fill="#0000C8" />
<path d="M136.7 136.777L127.925 139.429L127.202 146.276L136.556 143.335L136.7 136.777Z" fill="#008CFF" />
<path d="M187.616 149.988L175.803 150.037L177.828 151.145L190.027 151.194L187.616 149.988Z" fill="#005CFF" />
<path d="M127.925 139.429L121.175 142.852L119.825 150.084L127.202 146.275L127.925 139.429Z" fill="#007CFF" />
<path d="M206.517 129.883L191.474 126.749L192.775 119.709L208.06 123.518L206.517 129.883Z" fill="#00D8FF" />
<path d="M75.6103 191.454L75.321 198.639L68.1851 194.492L68.7636 187.163L75.6103 191.454Z" fill="#000089" />
<path d="M96.1986 186.247L96.2468 192.901L89.4966 194.396L90.1234 187.452L96.1986 186.247Z" fill="#00009B" />
<path d="M194.463 104.472L176.237 99.9401L176.527 91.4541L194.849 96.6614L194.463 104.472Z" fill="#63FF94" />
<path d="M56.083 173.421L54.974 180.943L49.2363 171.879L50.5864 164.309L56.083 173.421Z" fill="#0000FA" />
<path d="M147.018 135.041L136.7 136.777L136.604 143.334L147.452 141.261L147.018 135.041Z" fill="#009CFF" />
<path d="M156.421 69.7566L135.784 65.5136L135.881 57.124L156.18 61.8974L156.421 69.7566Z" fill="#F1FC06" />
<path d="M176.526 83.1609L156.469 78.2429L156.42 69.7568L176.334 75.2052L176.526 83.1609Z" fill="#BEFF39" />
<path d="M121.175 142.853L116.691 147.096L114.666 154.617L119.825 150.085L121.175 142.853Z" fill="#0070FF" />
<path d="M82.6019 193.913V200.953L75.3213 198.638L75.6106 191.454L82.6019 193.913Z" fill="#000080" />
<path d="M185.302 147.674H173.923L175.851 150.036L187.616 149.988L185.302 147.674Z" fill="#006CFF" />
<path d="M63.4118 75.4464L51.3096 79.9305L52.3221 68.5515L64.2315 64.4531L63.4118 75.4464Z" fill="#D4FF23" />
<path d="M208.06 123.518L192.775 119.709L193.788 112.235L209.217 116.719L208.06 123.518Z" fill="#19FFDE" />
<path d="M79.0338 62.0902L64.2314 64.4528L65.7744 54.3274L80.1909 52.3506L79.0338 62.0902Z" fill="#FFDB00" />
<path d="M50.5862 164.308L49.2361 171.878L44.2217 161.608L45.7646 153.99L50.5862 164.308Z" fill="#0024FF" />
<path d="M170.789 139.67L159.121 140.056L160.181 144.829L172.235 144.202L170.789 139.67Z" fill="#0094FF" />
<path d="M158.253 134.174L147.019 135.042L147.453 141.262L159.121 140.056L158.253 134.174Z" fill="#00ACFF" />
<path d="M115.871 53.8462L97.0664 52.2069L98.1754 43.9619L116.497 45.987L115.871 53.8462Z" fill="#FFB200" />
<path d="M44.6075 122.843L38.8216 130.654L36.8447 118.215L42.8235 110.5L44.6075 122.843Z" fill="#13FCE4" />
<path d="M106.999 171.975L107.096 178.098L101.888 183.113L102.515 176.7L106.999 171.975Z" fill="#0000F1" />
<path d="M135.88 57.1242L115.871 53.8455L116.449 45.9863L135.977 49.699L135.88 57.1242Z" fill="#FFBD00" />
<path d="M111.772 159.776L110.953 165.418L106.999 171.975L108.59 166.044L111.772 159.776Z" fill="#0020FF" />
<path d="M194.848 96.6134L176.526 91.4543V83.1611L194.848 88.9471V96.6134Z" fill="#8DFF6A" />
<path d="M205.601 151.338L195.186 150.229L197.741 148.06L208.445 149.554L205.601 151.338Z" fill="#006CFF" />
<path d="M208.446 149.555L197.742 148.06L200.201 144.878L211.194 146.807L208.446 149.555Z" fill="#0078FF" />
<path d="M51.1167 91.7922L42.0039 98.1085L42.245 85.958L51.3096 79.8828L51.1167 91.7922Z" fill="#97FF60" />
<path d="M202.708 152.11L192.583 151.242L195.186 150.229L205.601 151.338L202.708 152.11Z" fill="#0068FF" />
<path d="M211.194 146.806L200.201 144.877L202.515 140.683L213.798 143.142L211.194 146.806Z" fill="#008CFF" />
<path d="M97.0665 52.2061L80.1909 52.3508L81.8303 43.6719L98.1755 43.9612L97.0665 52.2061Z" fill="#FFA700" />
<path d="M136.797 129.111L128.455 131.329L127.925 139.381L136.7 136.778L136.797 129.111Z" fill="#00C4FF" />
<path d="M89.4964 194.395L89.7857 201.338L82.6016 200.953V193.913L89.4964 194.395Z" fill="#000084" />
<path d="M101.888 183.113L102.563 189.526L96.2469 192.901L96.1987 186.247L101.888 183.113Z" fill="#0000BB" />
<path d="M183.277 144.299L172.235 144.202L173.923 147.674H185.302L183.277 144.299Z" fill="#0084FF" />
<path d="M116.69 147.096L114.473 151.966L111.772 159.825L114.665 154.617L116.69 147.096Z" fill="#0068FF" />
<path d="M209.217 116.72L193.788 112.235L194.463 104.473L210.037 109.632L209.217 116.72Z" fill="#3CFFBA" />
<path d="M146.729 127.809L136.797 129.11L136.7 136.777L147.018 135.041L146.729 127.809Z" fill="#00D0FF" />
<path d="M45.7648 153.99L44.2219 161.608L40.1235 150.277L41.8111 142.659L45.7648 153.99Z" fill="#0064FF" />
<path d="M199.911 151.868L190.027 151.193L192.582 151.242L202.708 152.109L199.911 151.868Z" fill="#0068FF" />
<path d="M128.455 131.329L122.235 134.367L121.174 142.853L127.925 139.429L128.455 131.329Z" fill="#00B8FF" />
<path d="M213.798 143.141L202.515 140.682L204.637 135.668L216.16 138.706L213.798 143.141Z" fill="#00A4FF" />
<path d="M176.333 75.2051L156.42 69.7567L156.179 61.8975L175.851 67.828L176.333 75.2051Z" fill="#EBFF0C" />
<path d="M156.179 61.8979L135.88 57.1245L135.977 49.6992L155.842 54.8583L156.179 61.8979Z" fill="#FFC800" />
<path d="M169.583 134.125L158.252 134.173L159.12 140.056L170.789 139.67L169.583 134.125Z" fill="#00BCFF" />
<path d="M197.163 150.567L187.616 149.988L190.027 151.194L199.911 151.869L197.163 150.567Z" fill="#0074FF" />
<path d="M216.16 138.706L204.636 135.669L206.468 129.883L218.185 133.547L216.16 138.706Z" fill="#00C4FF" />
<path d="M157.626 127.375L146.729 127.809L147.018 135.041L158.253 134.173L157.626 127.375Z" fill="#00DCFE" />
<path d="M122.235 134.366L118.282 138.223L116.69 147.095L121.175 142.852L122.235 134.366Z" fill="#00B0FF" />
<path d="M194.849 88.9466L176.527 83.1607L176.286 75.2051L194.511 81.5696L194.849 88.9466Z" fill="#B7FF40" />
<path d="M181.493 139.959L170.789 139.67L172.236 144.202L183.277 144.299L181.493 139.959Z" fill="#00A4FF" />
<path d="M210.037 109.631L194.463 104.472L194.849 96.6611L210.471 102.447L210.037 109.631Z" fill="#63FF94" />
<path d="M96.2468 192.9L96.7772 199.699L89.7859 201.338L89.4966 194.395L96.2468 192.9Z" fill="#000096" />
<path d="M42.8236 110.5L36.8448 118.214L35.9287 105.678L42.0039 98.1084L42.8236 110.5Z" fill="#53FFA4" />
<path d="M64.2316 64.4535L52.3223 68.5518L54.2509 58.089L65.7745 54.3281L64.2316 64.4535Z" fill="#FFD700" />
<path d="M116.449 45.9861L98.1753 43.961L99.6218 36.9697L117.221 39.3323L116.449 45.9861Z" fill="#FF7A00" />
<path d="M80.191 52.3508L65.7744 54.3276L67.9923 45.3112L81.8303 43.6719L80.191 52.3508Z" fill="#FF9C00" />
<path d="M135.977 49.6994L116.498 45.9868L117.221 39.333L136.073 43.3832L135.977 49.6994Z" fill="#FF8900" />
<path d="M194.656 148.252L185.302 147.674L187.616 149.988L197.163 150.567L194.656 148.252Z" fill="#0084FF" />
<path d="M68.1851 194.491L69.0047 201.145L62.2063 195.215L61.3384 188.513L68.1851 194.491Z" fill="#00009F" />
<path d="M218.185 133.547L206.517 129.882L208.06 123.518L219.921 127.761L218.185 133.547Z" fill="#00E4F8" />
<path d="M61.3386 188.513L62.2065 195.215L55.842 187.645L54.9741 180.943L61.3386 188.513Z" fill="#0000C8" />
<path d="M110.953 165.418L111.724 171.3L107.095 178.099L106.999 171.975L110.953 165.418Z" fill="#0018FF" />
<path d="M41.8109 142.66L40.1233 150.326L36.9893 138.272L38.8215 130.654L41.8109 142.66Z" fill="#00ACFF" />
<path d="M75.321 198.639L76.1407 205.341L69.0047 201.146L68.1851 194.492L75.321 198.639Z" fill="#000089" />
<path d="M114.472 151.965L114.231 157.317L110.953 165.417L111.772 159.824L114.472 151.965Z" fill="#005CFF" />
<path d="M136.845 120.48L128.841 122.216L128.455 131.329L136.796 129.111L136.845 120.48Z" fill="#16FFE1" />
<path d="M107.096 178.099L108.397 184.222L102.563 189.526L101.888 183.113L107.096 178.099Z" fill="#0000ED" />
<path d="M175.851 67.8285L156.179 61.8979L155.842 54.8584L175.128 61.2229L175.851 67.8285Z" fill="#FFD700" />
<path d="M146.536 119.709L136.845 120.48L136.796 129.111L146.729 127.809L146.536 119.709Z" fill="#1CFFDB" />
<path d="M51.3097 79.8825L42.2451 85.9577L43.4505 74.2895L52.3223 68.5518L51.3097 79.8825Z" fill="#D7FF1F" />
<path d="M54.974 180.944L55.8419 187.646L50.1042 178.629L49.2363 171.879L54.974 180.944Z" fill="#0000FA" />
<path d="M168.667 127.761L157.626 127.375L158.253 134.173L169.584 134.125L168.667 127.761Z" fill="#02E8F4" />
<path d="M98.1753 43.9615L81.8301 43.6722L83.9516 36.3916L99.6218 36.9702L98.1753 43.9615Z" fill="#FF6F00" />
<path d="M155.842 54.8582L135.977 49.6991L136.073 43.3828L155.408 48.8794L155.842 54.8582Z" fill="#FF9800" />
<path d="M128.841 122.216L122.959 124.771L122.235 134.366L128.455 131.329L128.841 122.216Z" fill="#13FCE4" />
<path d="M192.341 145.022L183.277 144.299L185.302 147.674L194.608 148.253L192.341 145.022Z" fill="#009CFF" />
<path d="M118.282 138.224L116.546 142.756L114.473 151.965L116.691 147.095L118.282 138.224Z" fill="#00A8FF" />
<path d="M179.998 134.752L169.583 134.125L170.789 139.67L181.493 139.959L179.998 134.752Z" fill="#00CCFF" />
<path d="M219.921 127.761L208.06 123.518L209.217 116.72L221.223 121.59L219.921 127.761Z" fill="#1FFFD7" />
<path d="M82.5537 200.953L83.3734 207.655L76.141 205.341L75.3213 198.639L82.5537 200.953Z" fill="#000080" />
<path d="M210.471 102.448L194.849 96.6618V88.9473L210.471 95.36V102.448Z" fill="#8DFF6A" />
<path d="M194.511 81.5697L176.334 75.2052L175.852 67.8281L193.836 74.6748L194.511 81.5697Z" fill="#E1FF16" />
<path d="M157.24 119.805L146.536 119.709L146.729 127.809L157.626 127.375L157.24 119.805Z" fill="#23FFD4" />
<path d="M49.2361 171.878L50.104 178.629L45.0896 168.359L44.2217 161.608L49.2361 171.878Z" fill="#0024FF" />
<path d="M212.688 152.978L205.601 151.339L208.445 149.555L215.678 151.532L212.688 152.978Z" fill="#0084FF" />
<path d="M102.563 189.525L103.383 196.179L96.7774 199.699L96.2471 192.901L102.563 189.525Z" fill="#0000BB" />
<path d="M209.651 153.46L202.708 152.11L205.601 151.339L212.689 152.978L209.651 153.46Z" fill="#0080FF" />
<path d="M215.678 151.532L208.445 149.555L211.194 146.807L218.619 149.169L215.678 151.532Z" fill="#0090FF" />
<path d="M206.661 153.075L199.911 151.869L202.708 152.11L209.651 153.46L206.661 153.075Z" fill="#0084FF" />
<path d="M218.619 149.169L211.194 146.806L213.798 143.142L221.319 145.986L218.619 149.169Z" fill="#00A0FF" />
<path d="M122.959 124.771L119.342 128.195L118.282 138.224L122.235 134.366L122.959 124.771Z" fill="#0CF4EB" />
<path d="M38.8215 130.654L36.9893 138.272L34.916 125.832L36.8447 118.214L38.8215 130.654Z" fill="#13FCE4" />
<path d="M190.364 140.924L181.493 139.96L183.277 144.299L192.341 145.023L190.364 140.924Z" fill="#00B8FF" />
<path d="M136.073 43.3828L117.221 39.3327L118.089 34.0771L136.218 38.3684L136.073 43.3828Z" fill="#FF6000" />
<path d="M221.223 121.589L209.217 116.72L210.036 109.632L222.139 115.128L221.223 121.589Z" fill="#43FFB4" />
<path d="M42.0039 98.1084L35.9287 105.678L36.1698 93.2868L42.245 85.958L42.0039 98.1084Z" fill="#97FF60" />
<path d="M89.7857 201.339L90.6054 208.041L83.373 207.655L82.6016 200.953L89.7857 201.339Z" fill="#000084" />
<path d="M117.221 39.3322L99.6221 36.9696L101.261 31.4248L118.089 34.0767L117.221 39.3322Z" fill="#FF4E00" />
<path d="M175.128 61.2232L155.842 54.8587L155.408 48.8799L174.212 55.5819L175.128 61.2232Z" fill="#FFAB00" />
<path d="M203.769 151.676L197.163 150.567L199.911 151.869L206.662 153.075L203.769 151.676Z" fill="#008CFF" />
<path d="M168.089 120.722L157.24 119.806L157.626 127.376L168.667 127.761L168.089 120.722Z" fill="#2CFFCA" />
<path d="M221.319 145.987L213.797 143.142L216.16 138.706L223.826 142.033L221.319 145.987Z" fill="#00B8FF" />
<path d="M65.7741 54.3279L54.2505 58.0888L56.9506 48.7349L67.9921 45.3115L65.7741 54.3279Z" fill="#FF9400" />
<path d="M81.8302 43.6722L67.9922 45.3116L70.7887 37.6452L83.9517 36.3916L81.8302 43.6722Z" fill="#FF6400" />
<path d="M178.937 128.821L168.667 127.761L169.584 134.125L179.998 134.752L178.937 128.821Z" fill="#0CF4EB" />
<path d="M146.488 110.934L136.941 111.175L136.845 120.48L146.536 119.709L146.488 110.934Z" fill="#50FFA7" />
<path d="M136.941 111.175L129.082 112.284L128.841 122.216L136.845 120.48L136.941 111.175Z" fill="#4DFFAA" />
<path d="M210.471 95.3601L194.849 88.9474L194.511 81.5703L210.037 88.5134L210.471 95.3601Z" fill="#B4FF43" />
<path d="M114.231 157.317L115.581 162.91L111.724 171.3L110.953 165.418L114.231 157.317Z" fill="#0058FF" />
<path d="M155.408 48.8793L136.073 43.3826L136.218 38.3682L154.877 44.1541L155.408 48.8793Z" fill="#FF6F00" />
<path d="M44.2219 161.608L45.0898 168.358L40.9914 157.124L40.1235 150.277L44.2219 161.608Z" fill="#0068FF" />
<path d="M193.836 74.6749L175.852 67.8283L175.128 61.2227L192.824 68.5033L193.836 74.6749Z" fill="#FFE200" />
<path d="M111.724 171.3L113.508 177.182L108.398 184.222L107.096 178.098L111.724 171.3Z" fill="#0014FF" />
<path d="M116.546 142.756L116.835 147.77L114.231 157.317L114.473 151.965L116.546 142.756Z" fill="#00A0FF" />
<path d="M201.117 149.41L194.607 148.301L197.163 150.567L203.768 151.676L201.117 149.41Z" fill="#009CFF" />
<path d="M157.095 111.656L146.488 110.981L146.536 119.709L157.24 119.805L157.095 111.656Z" fill="#53FFA4" />
<path d="M223.827 142.033L216.16 138.706L218.185 133.547L225.948 137.404L223.827 142.033Z" fill="#00D4FF" />
<path d="M99.6219 36.97L83.9517 36.3914L86.5071 30.6055L101.261 31.4251L99.6219 36.97Z" fill="#FF3F00" />
<path d="M52.3219 68.5517L43.4502 74.2894L45.6681 63.5373L54.2506 58.0889L52.3219 68.5517Z" fill="#FFD300" />
<path d="M129.082 112.283L123.344 114.356L122.958 124.771L128.841 122.216L129.082 112.283Z" fill="#49FFAD" />
<path d="M188.725 136.054L179.998 134.752L181.493 139.959L190.364 140.924L188.725 136.054Z" fill="#00DCFE" />
<path d="M222.139 115.128L210.037 109.631L210.471 102.447L222.573 108.571L222.139 115.128Z" fill="#66FF90" />
<path d="M119.342 128.194L117.992 132.341L116.546 142.756L118.282 138.223L119.342 128.194Z" fill="#09F0EE" />
<path d="M96.7775 199.747L97.5489 206.449L90.6058 208.04L89.7861 201.338L96.7775 199.747Z" fill="#00009B" />
<path d="M108.397 184.222L109.458 190.779L103.383 196.179L102.563 189.525L108.397 184.222Z" fill="#0000E8" />
<path d="M198.658 146.276L192.341 145.022L194.656 148.253L201.117 149.41L198.658 146.276Z" fill="#00B0FF" />
<path d="M225.948 137.404L218.185 133.547L219.921 127.761L227.78 132.245L225.948 137.404Z" fill="#0CF4EB" />
<path d="M178.214 122.313L168.089 120.722L168.667 127.761L178.937 128.822L178.214 122.313Z" fill="#33FFC4" />
<path d="M167.848 113.151L157.047 111.656L157.24 119.805L168.089 120.721L167.848 113.151Z" fill="#56FFA0" />
<path d="M136.218 38.3681L118.089 34.0769L119.053 30.3643L136.315 34.8483L136.218 38.3681Z" fill="#FF3B00" />
<path d="M69.005 201.145L70.3068 207.172L63.5566 201.29L62.2065 195.215L69.005 201.145Z" fill="#0000A8" />
<path d="M210.037 88.5128L194.511 81.5697L193.836 74.6748L209.217 82.1483L210.037 88.5128Z" fill="#D7FF1F" />
<path d="M174.212 55.5815L155.408 48.8795L154.877 44.1543L173.103 51.0974L174.212 55.5815Z" fill="#FF8600" />
<path d="M62.2063 195.215L63.5564 201.291L57.2401 193.769L55.8418 187.646L62.2063 195.215Z" fill="#0000CD" />
<path d="M36.8447 118.214L34.9161 125.832L34 113.199L35.9286 105.678L36.8447 118.214Z" fill="#53FFA4" />
<path d="M123.344 114.356L119.921 117.298L119.342 128.194L122.958 124.771L123.344 114.356Z" fill="#46FFB1" />
<path d="M118.089 34.0775L101.261 31.4256L103.19 27.5684L119.053 30.3649L118.089 34.0775Z" fill="#FF2900" />
<path d="M40.1233 150.277L40.9912 157.124L37.8571 145.118L36.9893 138.271L40.1233 150.277Z" fill="#00B0FF" />
<path d="M146.585 101.773L136.99 101.339L136.941 111.175L146.488 110.982L146.585 101.773Z" fill="#80FF77" />
<path d="M76.1409 205.34L77.3463 211.319L70.3067 207.173L69.0049 201.146L76.1409 205.34Z" fill="#000092" />
<path d="M192.823 68.5029L175.128 61.2223L174.212 55.5811L191.521 63.1992L192.823 68.5029Z" fill="#FFB900" />
<path d="M136.989 101.339L129.082 101.869V112.284L136.941 111.175L136.989 101.339Z" fill="#80FF77" />
<path d="M187.52 130.509L178.938 128.821L179.998 134.8L188.725 136.054L187.52 130.509Z" fill="#16FFE1" />
<path d="M154.877 44.1543L136.218 38.3684L136.314 34.8486L154.202 40.731L154.877 44.1543Z" fill="#FF4E00" />
<path d="M222.573 108.571L210.471 102.447V95.3594L222.573 102.061V108.571Z" fill="#8AFF6D" />
<path d="M42.2451 85.9573L36.1699 93.2862L37.5682 81.4732L43.4505 74.2891L42.2451 85.9573Z" fill="#D7FF1F" />
<path d="M55.8417 187.645L57.24 193.769L51.5505 184.849L50.104 178.629L55.8417 187.645Z" fill="#0000FF" />
<path d="M196.585 142.37L190.365 140.924L192.342 145.022L198.658 146.276L196.585 142.37Z" fill="#00C8FF" />
<path d="M157.192 103.123L146.584 101.772L146.488 110.934L157.047 111.657L157.192 103.123Z" fill="#80FF77" />
<path d="M227.78 132.245L219.921 127.761L221.223 121.589L229.178 126.7L227.78 132.245Z" fill="#29FFCE" />
<path d="M83.9515 36.3914L70.7886 37.645L74.1637 31.618L86.4588 30.6055L83.9515 36.3914Z" fill="#FF3000" />
<path d="M67.9922 45.3119L56.9507 48.7352L60.4222 40.7795L70.7887 37.6455L67.9922 45.3119Z" fill="#FF5900" />
<path d="M212.833 155.244L209.651 153.46L212.688 152.978L215.919 154.954L212.833 155.244Z" fill="#0098FF" />
<path d="M215.919 154.955L212.688 152.978L215.678 151.531L218.957 153.797L215.919 154.955Z" fill="#009CFF" />
<path d="M83.373 207.655L84.5302 213.586L77.346 211.32L76.1406 205.341L83.373 207.655Z" fill="#000084" />
<path d="M209.747 154.665L206.662 153.074L209.651 153.46L212.833 155.244L209.747 154.665Z" fill="#0098FF" />
<path d="M129.082 101.869L123.344 103.364V114.357L129.082 112.284V101.869Z" fill="#80FF77" />
<path d="M218.956 153.798L215.678 151.532L218.619 149.169L221.898 151.821L218.956 153.798Z" fill="#00A4FF" />
<path d="M116.835 147.771L118.571 153.074L115.581 162.91L114.231 157.317L116.835 147.771Z" fill="#009CFF" />
<path d="M103.383 196.18L104.154 202.93L97.597 206.45L96.7773 199.748L103.383 196.18Z" fill="#0000BB" />
<path d="M115.581 162.91L117.799 168.551L113.508 177.182L111.724 171.3L115.581 162.91Z" fill="#0054FF" />
<path d="M101.261 31.4249L86.459 30.6052L89.3519 26.5068L103.19 27.5676L101.261 31.4249Z" fill="#FF1600" />
<path d="M177.925 115.321L167.848 113.151L168.089 120.721L178.214 122.312L177.925 115.321Z" fill="#5AFF9D" />
<path d="M206.854 153.219L203.769 151.676L206.662 153.074L209.747 154.665L206.854 153.219Z" fill="#00A0FF" />
<path d="M50.1043 178.628L51.5508 184.848L46.6328 174.723L45.0898 168.358L50.1043 178.628Z" fill="#0028FF" />
<path d="M117.992 132.342L118.619 137.019L116.835 147.771L116.546 142.756L117.992 132.342Z" fill="#06ECF1" />
<path d="M209.217 82.1481L193.836 74.6746L192.824 68.5029L207.964 76.3621L209.217 82.1481Z" fill="#FBF100" />
<path d="M167.992 105.292L157.192 103.122L157.047 111.656L167.848 113.151L167.992 105.292Z" fill="#80FF77" />
<path d="M221.898 151.82L218.619 149.169L221.319 145.986L224.694 149.024L221.898 151.82Z" fill="#00B4FF" />
<path d="M194.849 137.838L188.725 136.054L190.364 140.924L196.584 142.37L194.849 137.838Z" fill="#02E8F4" />
<path d="M173.103 51.0969L154.878 44.1538L154.203 40.7305L171.801 47.8182L173.103 51.0969Z" fill="#FF6400" />
<path d="M54.2509 58.0883L45.6685 63.5367L48.8025 53.8453L56.951 48.7344L54.2509 58.0883Z" fill="#FF9100" />
<path d="M229.13 126.7L221.223 121.589L222.139 115.128L230.094 120.817L229.13 126.7Z" fill="#46FFB1" />
<path d="M186.7 124.482L178.214 122.312L178.937 128.822L187.52 130.509L186.7 124.482Z" fill="#39FFBE" />
<path d="M136.315 34.849L119.053 30.3649L120.114 28.3398L136.459 32.8721L136.315 34.849Z" fill="#FF2200" />
<path d="M222.573 102.061L210.471 95.3111L210.037 88.5127L222.091 95.7451L222.573 102.061Z" fill="#ADFF49" />
<path d="M119.921 117.298L118.715 121.01L117.992 132.341L119.342 128.195L119.921 117.298Z" fill="#43FFB4" />
<path d="M204.106 151.001L201.117 149.41L203.769 151.676L206.854 153.219L204.106 151.001Z" fill="#00B0FF" />
<path d="M191.522 63.1999L174.212 55.5817L173.103 51.0977L189.979 58.9086L191.522 63.1999Z" fill="#FF9800" />
<path d="M113.508 177.183L114.762 183.595L109.458 190.78L108.397 184.222L113.508 177.183Z" fill="#0014FF" />
<path d="M146.777 92.4185L137.037 91.3096L136.989 101.338L146.584 101.772L146.777 92.4185Z" fill="#B4FF43" />
<path d="M224.694 149.025L221.319 145.987L223.827 142.033L227.202 145.553L224.694 149.025Z" fill="#00C8FF" />
<path d="M90.6054 208.04L91.6662 213.971L84.5302 213.585L83.373 207.654L90.6054 208.04Z" fill="#00008D" />
<path d="M154.203 40.7313L136.314 34.8489L136.459 32.8721L153.479 38.7544L154.203 40.7313Z" fill="#FF3800" />
<path d="M36.9893 138.272L37.8572 145.118L35.8321 132.727L34.916 125.832L36.9893 138.272Z" fill="#13FCE4" />
<path d="M157.53 94.4922L146.777 92.4189L146.584 101.773L157.192 103.123L157.53 94.4922Z" fill="#ADFF49" />
<path d="M137.038 91.3097L128.938 91.165L129.082 101.869L136.99 101.339L137.038 91.3097Z" fill="#B7FF40" />
<path d="M119.053 30.3643L103.19 27.5678L105.263 25.4463L120.114 28.3392L119.053 30.3643Z" fill="#F60B00" />
<path d="M123.344 103.363L119.921 105.774V117.298L123.344 114.357V103.363Z" fill="#80FF77" />
<path d="M35.9286 105.678L34 113.2L34.2411 100.76L36.1697 93.335L35.9286 105.678Z" fill="#97FF60" />
<path d="M201.647 147.963L198.658 146.275L201.117 149.409L204.106 151.001L201.647 147.963Z" fill="#00C0FF" />
<path d="M45.0896 168.358L46.6325 174.723L42.5823 163.585L40.9912 157.124L45.0896 168.358Z" fill="#0068FF" />
<path d="M193.547 132.679L187.52 130.509L188.725 136.054L194.848 137.838L193.547 132.679Z" fill="#1FFFD7" />
<path d="M227.202 145.553L223.778 142.033L225.948 137.404L229.371 141.454L227.202 145.553Z" fill="#00E0FB" />
<path d="M178.069 108.137L167.992 105.292L167.848 113.151L177.925 115.321L178.069 108.137Z" fill="#80FF77" />
<path d="M230.094 120.818L222.139 115.129L222.573 108.571L230.528 114.791L230.094 120.818Z" fill="#66FF90" />
<path d="M207.963 76.3622L192.823 68.503L191.521 63.1992L206.372 71.3959L207.963 76.3622Z" fill="#FFCC00" />
<path d="M168.474 97.3369L157.529 94.4922L157.192 103.123L167.992 105.293L168.474 97.3369Z" fill="#AAFF4D" />
<path d="M128.937 91.165L123.007 92.0811L123.344 103.364L129.082 101.869L128.937 91.165Z" fill="#BAFF3C" />
<path d="M186.363 118.07L177.925 115.321L178.214 122.313L186.7 124.482L186.363 118.07Z" fill="#5AFF9D" />
<path d="M171.802 47.8185L154.203 40.7308L153.479 38.7539L170.403 45.8417L171.802 47.8185Z" fill="#FF4E00" />
<path d="M43.4502 74.2893L37.5679 81.4735L40.0269 70.432L45.6682 63.5371L43.4502 74.2893Z" fill="#FFD000" />
<path d="M222.09 95.7454L210.036 88.513L209.217 82.1484L221.126 89.863L222.09 95.7454Z" fill="#D1FF26" />
<path d="M86.5074 30.6052L74.1641 31.6177L77.9731 27.3265L89.3521 26.5068L86.5074 30.6052Z" fill="#F10800" />
<path d="M199.526 144.251L196.584 142.37L198.658 146.276L201.647 147.963L199.526 144.251Z" fill="#00D8FF" />
<path d="M109.458 190.779L110.181 197.53L104.154 202.93L103.383 196.179L109.458 190.779Z" fill="#0000ED" />
<path d="M189.93 58.908L173.103 51.097L171.801 47.8184L188.147 55.774L189.93 58.908Z" fill="#FF7A00" />
<path d="M103.19 27.5678L89.3521 26.507L92.4861 24.2891L105.263 25.4462L103.19 27.5678Z" fill="#DF0000" />
<path d="M147.115 83.1125L137.038 81.2803V91.3092L146.777 92.4182L147.115 83.1125Z" fill="#E4FF13" />
<path d="M136.459 32.8714L120.114 28.3391L121.223 28.0498L136.555 32.4857L136.459 32.8714Z" fill="#FA0F00" />
<path d="M70.7888 37.6452L60.4224 40.7792L64.5207 34.4629L74.1639 31.6182L70.7888 37.6452Z" fill="#FF2500" />
<path d="M229.371 141.454L225.948 137.404L227.78 132.245L231.203 136.777L229.371 141.454Z" fill="#16FFE1" />
<path d="M97.5968 206.449L98.5611 212.428L91.6662 213.971L90.6055 208.04L97.5968 206.449Z" fill="#00009F" />
<path d="M153.479 38.7544L136.459 32.8721L136.555 32.4863L152.708 38.3205L153.479 38.7544Z" fill="#FF2500" />
<path d="M158.156 85.9098L147.115 83.1133L146.777 92.419L157.53 94.4923L158.156 85.9098Z" fill="#DBFF1C" />
<path d="M137.037 81.2802L128.6 80.5088L128.937 91.1645L137.037 91.3092V81.2802Z" fill="#EBFF0C" />
<path d="M118.619 137.019L120.644 142.033L118.571 153.074L116.835 147.771L118.619 137.019Z" fill="#02E8F4" />
<path d="M192.727 127.086L186.7 124.482L187.52 130.509L193.547 132.679L192.727 127.086Z" fill="#3CFFBA" />
<path d="M118.571 153.074L121.175 158.474L117.799 168.552L115.582 162.91L118.571 153.074Z" fill="#0098FF" />
<path d="M118.715 121.011L119.487 125.35L118.619 137.018L117.992 132.342L118.715 121.011Z" fill="#43FFB4" />
<path d="M120.114 28.3395L105.263 25.4465L107.481 25.1572L121.223 28.0502L120.114 28.3395Z" fill="#DF0000" />
<path d="M70.3069 207.172L71.5605 212.187L64.9549 206.401L63.5566 201.29L70.3069 207.172Z" fill="#0000B2" />
<path d="M230.528 114.791L222.573 108.571V102.062L230.528 108.86V114.791Z" fill="#8AFF6D" />
<path d="M63.5565 201.29L64.9066 206.401L58.7831 199.072L57.2402 193.769L63.5565 201.29Z" fill="#0000DA" />
<path d="M178.6 100.857L168.426 97.3369L167.992 105.293L178.069 108.137L178.6 100.857Z" fill="#A7FF50" />
<path d="M119.921 105.774L118.715 109.053V121.011L119.921 117.298V105.774Z" fill="#80FF77" />
<path d="M197.742 139.959L194.849 137.838L196.584 142.37L199.526 144.251L197.742 139.959Z" fill="#0CF4EB" />
<path d="M211.917 157.318L212.785 155.244L215.919 154.955L215.003 157.221L211.917 157.318Z" fill="#00A8FF" />
<path d="M206.372 71.3961L191.522 63.1994L189.931 58.9082L204.492 67.2978L206.372 71.3961Z" fill="#FFAB00" />
<path d="M40.9915 157.124L42.5826 163.585L39.4968 151.772L37.8574 145.118L40.9915 157.124Z" fill="#00B0FF" />
<path d="M169.246 89.4772L158.156 85.9092L157.529 94.4916L168.426 97.3364L169.246 89.4772Z" fill="#D4FF23" />
<path d="M208.928 156.594L209.747 154.665L212.785 155.244L211.917 157.317L208.928 156.594Z" fill="#00ACFF" />
<path d="M56.9508 48.7349L48.8022 53.8458L52.8524 45.6009L60.4223 40.7793L56.9508 48.7349Z" fill="#FF5500" />
<path d="M34.9161 125.832L35.8322 132.727L34.9161 120.142L34 113.199L34.9161 125.832Z" fill="#53FFA4" />
<path d="M77.3462 211.319L78.4551 216.237L71.5603 212.187L70.3066 207.173L77.3462 211.319Z" fill="#00009B" />
<path d="M170.403 45.842L153.479 38.7543L152.708 38.3203L168.86 45.3116L170.403 45.842Z" fill="#FF3F00" />
<path d="M215.003 157.221L215.919 154.955L218.957 153.798L217.992 156.305L215.003 157.221Z" fill="#00ACFF" />
<path d="M123.007 92.0811L119.342 94.0097L119.921 105.774L123.344 103.364L123.007 92.0811Z" fill="#BEFF39" />
<path d="M117.8 168.552L119.246 174.82L114.762 183.595L113.508 177.182L117.8 168.552Z" fill="#0050FF" />
<path d="M186.507 111.512L178.069 108.137L177.925 115.321L186.363 118.069L186.507 111.512Z" fill="#80FF77" />
<path d="M231.204 136.777L227.78 132.245L229.13 126.7L232.602 131.763L231.204 136.777Z" fill="#30FFC7" />
<path d="M221.126 89.8628L209.217 82.1482L207.963 76.3623L219.776 84.5108L221.126 89.8628Z" fill="#F1FC06" />
<path d="M206.035 155.099L206.854 153.219L209.747 154.665L208.928 156.594L206.035 155.099Z" fill="#00B4FF" />
<path d="M57.2403 193.769L58.735 199.072L53.1901 190.297L51.5508 184.849L57.2403 193.769Z" fill="#0000FF" />
<path d="M188.147 55.7743L171.802 47.8187L170.403 45.8418L186.17 53.7974L188.147 55.7743Z" fill="#FF6400" />
<path d="M218.041 156.304L218.957 153.797L221.898 151.82L220.933 154.617L218.041 156.304Z" fill="#00B8FF" />
<path d="M147.549 74.1449L137.038 71.6377V81.2809L147.115 83.1131L147.549 74.1449Z" fill="#FFD700" />
<path d="M128.6 80.5088L122.284 80.8463L122.959 92.0806L128.937 91.1645L128.6 80.5088Z" fill="#F1FC06" />
<path d="M136.555 32.4857L121.223 28.0498L122.38 29.4963L136.652 33.7875L136.555 32.4857Z" fill="#ED0400" />
<path d="M152.708 38.3205L136.556 32.4863L136.652 33.7882L151.888 39.4294L152.708 38.3205Z" fill="#FF1E00" />
<path d="M158.976 77.6643L147.549 74.1445L147.115 83.1127L158.156 85.9092L158.976 77.6643Z" fill="#FFE200" />
<path d="M203.334 152.881L204.106 151.001L206.854 153.219L206.035 155.148L203.334 152.881Z" fill="#00C0FF" />
<path d="M36.1699 93.2866L34.2412 100.76L35.6877 88.8507L37.5681 81.4736L36.1699 93.2866Z" fill="#D7FF1F" />
<path d="M84.5304 213.585L85.4947 218.504L78.4552 216.237L77.3462 211.319L84.5304 213.585Z" fill="#000092" />
<path d="M137.038 71.6379L128.118 70.1914L128.6 80.5096L137.038 81.2811V71.6379Z" fill="#FFCC00" />
<path d="M220.934 154.617L221.898 151.821L224.646 149.024L223.634 152.207L220.934 154.617Z" fill="#00C4FF" />
<path d="M192.342 121.203L186.363 118.069L186.7 124.482L192.727 127.086L192.342 121.203Z" fill="#5DFF9A" />
<path d="M196.44 135.186L193.547 132.679L194.849 137.838L197.79 139.959L196.44 135.186Z" fill="#26FFD1" />
<path d="M230.529 108.86L222.573 102.061L222.091 95.7451L229.998 103.026L230.529 108.86Z" fill="#AAFF4D" />
<path d="M105.263 25.4465L92.4858 24.2893L95.8127 24L107.481 25.1572L105.263 25.4465Z" fill="#C80000" />
<path d="M104.154 202.93L105.071 208.957L98.5614 212.428L97.5488 206.449L104.154 202.93Z" fill="#0000C4" />
<path d="M200.875 149.988L201.647 147.963L204.106 151L203.335 152.881L200.875 149.988Z" fill="#00D0FF" />
<path d="M89.3521 26.507L77.9731 27.3267L82.1197 24.9641L92.4862 24.2891L89.3521 26.507Z" fill="#CD0000" />
<path d="M179.612 93.7205L169.246 89.4775L168.426 97.3368L178.6 100.857L179.612 93.7205Z" fill="#CEFF29" />
<path d="M168.86 45.3116L152.708 38.3203L151.937 39.4293L167.269 46.1795L168.86 45.3116Z" fill="#FF3800" />
<path d="M170.403 81.9553L158.976 77.6641L158.156 85.909L169.246 89.477L170.403 81.9553Z" fill="#FBF100" />
<path d="M232.602 131.762L229.178 126.7L230.094 120.817L233.518 126.41L232.602 131.762Z" fill="#4DFFAA" />
<path d="M51.5508 184.849L53.1902 190.297L48.3686 180.365L46.6328 174.724L51.5508 184.849Z" fill="#0030FF" />
<path d="M121.223 28.0502L107.481 25.1572L109.747 26.7484L122.38 29.4967L121.223 28.0502Z" fill="#D10000" />
<path d="M204.492 67.298L189.979 58.9085L188.146 55.7744L202.274 64.2604L204.492 67.298Z" fill="#FF9100" />
<path d="M114.762 183.595L115.533 190.393L110.229 197.529L109.458 190.779L114.762 183.595Z" fill="#0014FF" />
<path d="M148.127 65.7549L137.038 62.5244V71.6372L147.549 74.1445L148.127 65.7549Z" fill="#FFA700" />
<path d="M223.682 152.207L224.694 149.024L227.201 145.553L226.141 149.169L223.682 152.207Z" fill="#00D8FF" />
<path d="M187.134 104.858L178.6 100.856L178.069 108.137L186.507 111.512L187.134 104.858Z" fill="#A4FF53" />
<path d="M186.17 53.7975L170.403 45.8419L168.86 45.3115L184.048 53.1707L186.17 53.7975Z" fill="#FF5500" />
<path d="M219.776 84.5112L207.963 76.3627L206.372 71.3965L217.992 79.8343L219.776 84.5112Z" fill="#FFDB00" />
<path d="M151.936 39.4294L136.652 33.7881L136.748 36.7293L151.117 42.033L151.936 39.4294Z" fill="#FF1E00" />
<path d="M45.6681 63.5371L40.0269 70.432L43.5466 60.5477L48.8022 53.8457L45.6681 63.5371Z" fill="#FF8D00" />
<path d="M160.037 69.9497L148.127 65.7549L147.549 74.1445L158.976 77.6642L160.037 69.9497Z" fill="#FFB600" />
<path d="M136.652 33.7883L122.38 29.4971L123.537 32.7275L136.748 36.7295L136.652 33.7883Z" fill="#F10800" />
<path d="M74.1637 31.6184L64.5205 34.4631L69.2457 29.9308L77.9728 27.3271L74.1637 31.6184Z" fill="#E40000" />
<path d="M198.802 146.421L199.525 144.251L201.647 147.964L200.876 149.989L198.802 146.421Z" fill="#02E8F4" />
<path d="M128.118 70.1907L121.223 69.9014L122.283 80.7982L128.6 80.5089L128.118 70.1907Z" fill="#FFC100" />
<path d="M137.037 62.5245L127.442 60.4512L128.117 70.1908L137.037 71.6373V62.5245Z" fill="#FF9800" />
<path d="M91.6662 213.971L92.4859 218.889L85.4946 218.503L84.5303 213.585L91.6662 213.971Z" fill="#000096" />
<path d="M122.284 80.7988L118.233 82.1971L119.342 94.01L123.007 92.0814L122.284 80.7988Z" fill="#F8F500" />
<path d="M195.572 130.027L192.727 127.086L193.547 132.679L196.44 135.186L195.572 130.027Z" fill="#43FFB4" />
<path d="M37.8571 145.119L39.4964 151.773L37.4714 139.526L35.832 132.728L37.8571 145.119Z" fill="#13FCE4" />
<path d="M167.269 46.179L151.888 39.4287L151.068 42.0324L165.678 48.4933L167.269 46.179Z" fill="#FF3800" />
<path d="M119.342 94.0098L117.992 96.8545L118.715 109.053L119.921 105.774L119.342 94.0098Z" fill="#C1FF36" />
<path d="M192.486 115.224L186.507 111.512L186.363 118.069L192.342 121.203L192.486 115.224Z" fill="#80FF77" />
<path d="M148.754 58.1366L136.989 54.2793L137.037 62.5242L148.127 65.7547L148.754 58.1366Z" fill="#FF7E00" />
<path d="M226.141 149.169L227.201 145.552L229.371 141.454L228.31 145.456L226.141 149.169Z" fill="#09F0EE" />
<path d="M118.715 109.102L119.487 113.055V125.35L118.715 121.011V109.102Z" fill="#83FF73" />
<path d="M171.85 74.9647L160.037 69.9502L158.976 77.6648L170.403 81.956L171.85 74.9647Z" fill="#FFC400" />
<path d="M151.117 42.0333L136.749 36.7295L136.845 41.2136L150.297 46.1316L151.117 42.0333Z" fill="#FF2900" />
<path d="M119.487 125.35L121.705 130.123L120.644 142.081L118.619 137.018L119.487 125.35Z" fill="#40FFB7" />
<path d="M161.242 63.0065L148.802 58.1367L148.127 65.7548L160.037 69.9496L161.242 63.0065Z" fill="#FF9100" />
<path d="M229.998 103.026L222.091 95.7456L221.126 89.8633L228.986 97.5778L229.998 103.026Z" fill="#CAFF2C" />
<path d="M233.518 126.411L230.095 120.818L230.529 114.839L234 120.914L233.518 126.411Z" fill="#6AFF8D" />
<path d="M180.963 86.9223L170.403 81.9561L169.246 89.4778L179.613 93.7208L180.963 86.9223Z" fill="#F1FC06" />
<path d="M120.644 142.081L123.441 147.24L121.174 158.474L118.571 153.074L120.644 142.081Z" fill="#00E4F8" />
<path d="M202.274 64.2607L188.147 55.7747L186.17 53.7979L199.911 62.3321L202.274 64.2607Z" fill="#FF7A00" />
<path d="M184.048 53.1707L168.86 45.3115L167.269 46.1794L181.83 53.8458L184.048 53.1707Z" fill="#FF4E00" />
<path d="M136.748 36.7295L123.537 32.7275L124.646 37.5491L136.845 41.2136L136.748 36.7295Z" fill="#FF1300" />
<path d="M122.38 29.4964L109.748 26.748L112.014 30.1714L123.537 32.7268L122.38 29.4964Z" fill="#D60000" />
<path d="M149.478 51.5316L136.941 47.0957L136.99 54.2799L148.754 58.1372L149.478 51.5316Z" fill="#FF5900" />
<path d="M34 113.199L34.9161 120.143L35.1572 107.799L34.2411 100.76L34 113.199Z" fill="#97FF60" />
<path d="M150.297 46.1309L136.845 41.2129L136.941 47.0952L149.526 51.5311L150.297 46.1309Z" fill="#FF3B00" />
<path d="M46.6327 174.723L48.3684 180.364L44.3665 169.516L42.5825 163.585L46.6327 174.723Z" fill="#0070FF" />
<path d="M197.066 142.371L197.741 139.96L199.525 144.251L198.802 146.469L197.066 142.371Z" fill="#16FFE1" />
<path d="M165.678 48.4941L151.068 42.0332L150.297 46.1316L164.135 52.1103L165.678 48.4941Z" fill="#FF4300" />
<path d="M136.989 54.2798L126.623 51.6279L127.443 60.4515L137.038 62.5247L136.989 54.2798Z" fill="#FF6C00" />
<path d="M162.64 56.9797L149.525 51.5312L148.802 58.1368L161.242 63.0067L162.64 56.9797Z" fill="#FF6F00" />
<path d="M188.195 98.3977L179.612 93.7207L178.6 100.857L187.134 104.859L188.195 98.3977Z" fill="#C7FF30" />
<path d="M60.4225 40.7792L52.8525 45.6008L57.6741 38.9952L64.5208 34.4629L60.4225 40.7792Z" fill="#FF1E00" />
<path d="M107.481 25.1572L95.813 24L99.2363 25.6393L109.747 26.7483L107.481 25.1572Z" fill="#BB0000" />
<path d="M217.992 79.834L206.372 71.3962L204.492 67.2979L215.822 75.9767L217.992 79.834Z" fill="#FFBD00" />
<path d="M164.135 52.1096L150.297 46.1309L149.525 51.5311L162.64 56.9795L164.135 52.1096Z" fill="#FF5500" />
<path d="M121.174 158.475L122.717 164.646L119.246 174.82L117.799 168.552L121.174 158.475Z" fill="#0098FF" />
<path d="M136.845 41.2132L124.646 37.5488L125.659 43.9133L136.941 47.0956L136.845 41.2132Z" fill="#FF2900" />
<path d="M136.941 47.0953L125.659 43.9131L126.623 51.6276L136.989 54.2795L136.941 47.0953Z" fill="#FF4700" />
<path d="M173.537 68.6963L161.242 63.0068L160.037 69.9499L171.85 74.9644L173.537 68.6963Z" fill="#FFA300" />
<path d="M127.443 60.4509L119.825 59.583L121.223 69.9012L128.118 70.1905L127.443 60.4509Z" fill="#FF8900" />
<path d="M228.311 145.456L229.371 141.454L231.204 136.777L230.095 141.261L228.311 145.456Z" fill="#1CFFDB" />
<path d="M181.83 53.846L167.269 46.1797L165.678 48.4941L179.66 55.7747L181.83 53.846Z" fill="#FF5200" />
<path d="M195.234 124.578L192.341 121.203L192.727 127.085L195.572 129.978L195.234 124.578Z" fill="#60FF97" />
<path d="M204.154 158.86L208.928 156.594L211.917 157.317L207.047 159.68L204.154 158.86Z" fill="#00BCFF" />
<path d="M92.4861 24.2893L82.1196 24.9643L86.5555 24.5786L95.813 24L92.4861 24.2893Z" fill="#B20000" />
<path d="M207.047 159.68L211.917 157.317L215.003 157.221L210.037 159.728L207.047 159.68Z" fill="#00B8FF" />
<path d="M110.23 197.529L111.049 203.653L105.07 208.956L104.154 202.929L110.23 197.529Z" fill="#0000F1" />
<path d="M201.31 157.318L206.035 155.1L208.928 156.594L204.154 158.86L201.31 157.318Z" fill="#00C4FF" />
<path d="M182.65 80.6051L171.85 74.9639L170.403 81.9552L180.963 86.9214L182.65 80.6051Z" fill="#FFD700" />
<path d="M193.161 109.198L187.134 104.858L186.507 111.512L192.486 115.177L193.161 109.198Z" fill="#A0FF56" />
<path d="M199.911 62.3319L186.17 53.7977L184.048 53.1709L197.356 61.5605L199.911 62.3319Z" fill="#FF6F00" />
<path d="M123.537 32.7273L112.014 30.1719L114.183 35.331L124.646 37.5489L123.537 32.7273Z" fill="#E40000" />
<path d="M175.418 63.2964L162.64 57.0283L161.242 63.0071L173.537 68.6966L175.418 63.2964Z" fill="#FF8200" />
<path d="M37.5679 81.4731L35.6875 88.8502L38.2429 77.7123L40.0269 70.4316L37.5679 81.4731Z" fill="#FFD000" />
<path d="M98.5614 212.428L99.2846 217.394L92.4862 218.889L91.6665 213.971L98.5614 212.428Z" fill="#0000A8" />
<path d="M121.223 69.9014L116.642 70.7693L118.233 82.1964L122.283 80.7982L121.223 69.9014Z" fill="#FFB900" />
<path d="M195.716 137.887L196.44 135.187L197.741 139.96L197.066 142.371L195.716 137.887Z" fill="#30FFC7" />
<path d="M210.037 159.728L215.003 157.221L217.992 156.305L212.93 159.053L210.037 159.728Z" fill="#00BCFF" />
<path d="M234 120.914L230.528 114.839V108.86L233.952 115.418L234 120.914Z" fill="#87FF70" />
<path d="M179.66 55.7748L165.678 48.4941L164.135 52.1103L177.491 58.957L179.66 55.7748Z" fill="#FF5900" />
<path d="M228.986 97.5773L221.126 89.8627L219.776 84.5107L227.491 92.5628L228.986 97.5773Z" fill="#E7FF0F" />
<path d="M198.706 155.1L203.335 152.882L206.035 155.148L201.31 157.318L198.706 155.1Z" fill="#00D0FF" />
<path d="M177.491 58.957L164.135 52.1104L162.64 57.0284L175.417 63.2965L177.491 58.957Z" fill="#FF6C00" />
<path d="M126.623 51.6278L118.185 50.2295L119.824 59.5834L127.443 60.4513L126.623 51.6278Z" fill="#FF5D00" />
<path d="M71.5602 212.187L72.4281 215.996L65.9671 210.403L64.9546 206.401L71.5602 212.187Z" fill="#0000C4" />
<path d="M189.689 92.2739L180.962 86.9219L179.612 93.7203L188.195 98.3973L189.689 92.2739Z" fill="#E7FF0F" />
<path d="M212.93 159.053L217.992 156.305L220.934 154.617L215.774 157.655L212.93 159.053Z" fill="#00C4FF" />
<path d="M215.823 75.9772L204.492 67.2984L202.274 64.2607L213.412 73.0361L215.823 75.9772Z" fill="#FFA700" />
<path d="M124.646 37.549L114.183 35.3311L116.256 42.0813L125.659 43.9135L124.646 37.549Z" fill="#FF1300" />
<path d="M64.9549 206.401L65.9674 210.403L59.9886 203.267L58.7832 199.072L64.9549 206.401Z" fill="#0000E8" />
<path d="M230.095 141.261L231.204 136.777L232.602 131.763L231.445 136.681L230.095 141.261Z" fill="#36FFC1" />
<path d="M196.343 152.254L200.876 149.988L203.335 152.881L198.706 155.099L196.343 152.254Z" fill="#00E0FB" />
<path d="M125.658 43.9133L116.256 42.0811L118.185 50.2296L126.623 51.6278L125.658 43.9133Z" fill="#FF3400" />
<path d="M118.233 82.1963L116.498 84.6071L117.992 96.854L119.342 94.0092L118.233 82.1963Z" fill="#FBF100" />
<path d="M119.246 174.819L120.017 181.666L115.533 190.393L114.762 183.595L119.246 174.819Z" fill="#0054FF" />
<path d="M77.9732 27.3265L69.2461 29.9301L74.4052 27.3747L82.1198 24.9639L77.9732 27.3265Z" fill="#BB0000" />
<path d="M109.747 26.7486L99.2363 25.6396L102.66 29.2076L112.014 30.172L109.747 26.7486Z" fill="#BF0000" />
<path d="M184.675 75.0126L173.537 68.6963L171.85 74.9644L182.65 80.6056L184.675 75.0126Z" fill="#FFB600" />
<path d="M197.307 61.5605L184.048 53.1709L181.83 53.8459L194.704 61.9944L197.307 61.5605Z" fill="#FF6800" />
<path d="M78.4549 216.238L79.13 219.95L72.4279 215.997L71.5601 212.188L78.4549 216.238Z" fill="#0000AD" />
<path d="M42.5824 163.585L44.3664 169.516L41.377 157.896L39.4966 151.772L42.5824 163.585Z" fill="#00B4FF" />
<path d="M35.8321 132.727L37.4715 139.525L36.5554 127.134L34.916 120.143L35.8321 132.727Z" fill="#53FFA4" />
<path d="M195.331 119.082L192.486 115.225L192.341 121.203L195.234 124.579L195.331 119.082Z" fill="#7DFF7A" />
<path d="M48.8024 53.8455L43.5469 60.5475L48.0792 52.0133L52.8526 45.6006L48.8024 53.8455Z" fill="#FF4E00" />
<path d="M215.774 157.655L220.934 154.617L223.634 152.255L218.426 155.534L215.774 157.655Z" fill="#00D4FF" />
<path d="M58.783 199.073L59.9884 203.268L54.5882 194.733L53.1899 190.346L58.783 199.073Z" fill="#0004FF" />
<path d="M194.897 133.065L195.572 130.027L196.44 135.186L195.717 137.887L194.897 133.065Z" fill="#49FFAD" />
<path d="M117.992 96.8545L118.619 100.471L119.535 113.055L118.715 109.101L117.992 96.8545Z" fill="#C1FF36" />
<path d="M194.27 148.88L198.802 146.469L200.876 149.989L196.343 152.255L194.27 148.88Z" fill="#0CF4EB" />
<path d="M194.27 103.364L188.195 98.3975L187.134 104.858L193.113 109.198L194.27 103.364Z" fill="#C1FF36" />
<path d="M186.941 70.1908L175.418 63.2959L173.537 68.6961L184.675 75.0124L186.941 70.1908Z" fill="#FF9800" />
<path d="M119.824 59.583L114.521 60.0169L116.642 70.7691L121.223 69.9012L119.824 59.583Z" fill="#FF7E00" />
<path d="M194.704 61.9942L181.83 53.8457L179.661 55.7743L192.052 63.6336L194.704 61.9942Z" fill="#FF6C00" />
<path d="M233.952 115.418L230.528 108.861L229.998 103.026L233.373 110.066L233.952 115.418Z" fill="#A7FF50" />
<path d="M227.491 92.563L219.776 84.5109L217.992 79.834L225.61 88.1754L227.491 92.563Z" fill="#FFEA00" />
<path d="M191.57 86.6325L182.65 80.6055L180.962 86.9218L189.69 92.2737L191.57 86.6325Z" fill="#FFE600" />
<path d="M213.412 73.036L202.274 64.2607L199.911 62.332L210.712 71.1073L213.412 73.036Z" fill="#FF9400" />
<path d="M119.535 113.055L121.705 117.587V130.123L119.487 125.35L119.535 113.055Z" fill="#83FF73" />
<path d="M189.448 66.3823L177.491 58.957L175.417 63.2965L186.941 70.1914L189.448 66.3823Z" fill="#FF8200" />
<path d="M192.052 63.6336L179.66 55.7744L177.491 58.9567L189.448 66.3819L192.052 63.6336Z" fill="#FF7300" />
<path d="M85.4946 218.503L85.9768 222.168L79.1301 219.95L78.4551 216.237L85.4946 218.503Z" fill="#0000A4" />
<path d="M95.8131 24L86.5557 24.5786L91.1362 26.2179L99.2365 25.6393L95.8131 24Z" fill="#A40000" />
<path d="M231.444 136.681L232.602 131.763L233.518 126.411L232.36 131.811L231.444 136.681Z" fill="#50FFA7" />
<path d="M218.426 155.533L223.634 152.255L226.141 149.169L220.837 152.785L218.426 155.533Z" fill="#00E4F8" />
<path d="M112.014 30.1714L102.66 29.207L105.987 34.6072L114.183 35.3305L112.014 30.1714Z" fill="#CD0000" />
<path d="M121.705 130.123L124.646 135.138L123.489 147.24L120.644 142.032L121.705 130.123Z" fill="#40FFB7" />
<path d="M192.583 144.974L197.067 142.37L198.802 146.468L194.27 148.879L192.583 144.974Z" fill="#1FFFD7" />
<path d="M34.2412 100.76L35.1573 107.799L36.6038 95.8901L35.6877 88.8506L34.2412 100.76Z" fill="#D7FF1F" />
<path d="M64.5205 34.463L57.6738 39.0435L63.1705 34.2701L69.2457 29.9307L64.5205 34.463Z" fill="#DA0000" />
<path d="M105.07 208.957L105.697 214.02L99.2843 217.395L98.561 212.429L105.07 208.957Z" fill="#0000CD" />
<path d="M53.1898 190.346L54.588 194.733L49.8629 185.09L48.3682 180.413L53.1898 190.346Z" fill="#003CFF" />
<path d="M118.185 50.2295H112.014L114.521 60.0173L119.825 59.6316L118.185 50.2295Z" fill="#FF4E00" />
<path d="M195.957 113.633L193.113 109.197L192.486 115.224L195.331 119.082L195.957 113.633Z" fill="#9DFF5A" />
<path d="M194.511 128.05L195.234 124.578L195.572 130.027L194.897 133.064L194.511 128.05Z" fill="#63FF94" />
<path d="M114.183 35.3307L105.986 34.6074L109.12 41.647L116.256 42.0809L114.183 35.3307Z" fill="#E80000" />
<path d="M193.836 81.6183L184.675 75.0127L182.65 80.6058L191.57 86.6327L193.836 81.6183Z" fill="#FFC400" />
<path d="M116.642 70.7695L114.376 72.7946L116.497 84.6075L118.233 82.1967L116.642 70.7695Z" fill="#FFAE00" />
<path d="M210.712 71.1073L199.911 62.332L197.356 61.5605L207.867 70.1912L210.712 71.1073Z" fill="#FF8600" />
<path d="M195.813 97.8183L189.69 92.2734L188.195 98.3969L194.27 103.363L195.813 97.8183Z" fill="#DEFF19" />
<path d="M123.44 147.24L125.128 153.364L122.717 164.646L121.174 158.475L123.44 147.24Z" fill="#00E4F8" />
<path d="M116.257 42.0814L109.121 41.6475L112.014 50.2299H118.185L116.257 42.0814Z" fill="#FF2200" />
<path d="M115.533 190.394L116.353 196.613L111.049 203.653L110.229 197.53L115.533 190.394Z" fill="#0018FF" />
<path d="M225.611 88.1752L217.992 79.8338L215.823 75.9766L223.344 84.5108L225.611 88.1752Z" fill="#FFD000" />
<path d="M220.837 152.785L226.141 149.169L228.31 145.456L222.91 149.458L220.837 152.785Z" fill="#0FF8E7" />
<path d="M233.373 110.065L229.998 103.026L228.985 97.5771L232.312 105.002L233.373 110.065Z" fill="#C1FF36" />
<path d="M191.281 140.73L195.717 137.886L197.067 142.37L192.583 144.973L191.281 140.73Z" fill="#33FFC4" />
<path d="M92.486 218.889L92.7752 222.553L85.9768 222.167L85.4946 218.503L92.486 218.889Z" fill="#0000A8" />
<path d="M232.361 131.811L233.518 126.411L234 120.914L232.795 126.748L232.361 131.811Z" fill="#6AFF8D" />
<path d="M82.1193 24.9639L74.4048 27.3747L79.8532 26.8925L86.5552 24.5781L82.1193 24.9639Z" fill="#9F0000" />
<path d="M195.668 161.416L204.154 158.86L207.047 159.68L198.465 162.332L195.668 161.416Z" fill="#00C8FF" />
<path d="M196.343 77.4238L186.941 70.1914L184.675 75.013L193.836 81.6186L196.343 77.4238Z" fill="#FFAB00" />
<path d="M193.017 159.825L201.31 157.317L204.155 158.86L195.668 161.416L193.017 159.825Z" fill="#00D0FF" />
<path d="M207.867 70.1912L197.308 61.5605L194.704 61.9945L204.926 70.4323L207.867 70.1912Z" fill="#FF8200" />
<path d="M39.4968 151.772L41.3772 157.896L39.4003 145.89L37.4717 139.525L39.4968 151.772Z" fill="#16FFE1" />
<path d="M40.0272 70.4321L38.2432 77.7127L41.9558 67.6838L43.5469 60.5479L40.0272 70.4321Z" fill="#FF8D00" />
<path d="M198.465 162.332L207.047 159.68L210.037 159.728L201.31 162.524L198.465 162.332Z" fill="#00C8FF" />
<path d="M190.461 157.607L198.706 155.1L201.31 157.318L192.968 159.825L190.461 157.607Z" fill="#00D8FF" />
<path d="M99.236 25.6396L91.1357 26.2182L95.7163 29.8344L102.659 29.2076L99.236 25.6396Z" fill="#A80000" />
<path d="M116.498 84.6074L116.787 87.9825L118.619 100.47L117.992 96.8543L116.498 84.6074Z" fill="#FEED00" />
<path d="M199.092 74.0482L189.449 66.3818L186.941 70.1909L196.344 77.4233L199.092 74.0482Z" fill="#FF9800" />
<path d="M204.926 70.4319L194.704 61.9941L192.052 63.6335L201.985 71.7338L204.926 70.4319Z" fill="#FF8200" />
<path d="M194.608 122.988L195.331 119.082L195.234 124.579L194.511 128.05L194.608 122.988Z" fill="#7DFF7A" />
<path d="M197.115 108.33L194.27 103.363L193.161 109.197L196.006 113.633L197.115 108.33Z" fill="#BAFF3C" />
<path d="M197.79 92.8045L191.57 86.6328L189.689 92.2741L195.813 97.8189L197.79 92.8045Z" fill="#FBF100" />
<path d="M201.31 162.524L210.037 159.728L212.93 159.053L204.154 161.994L201.31 162.524Z" fill="#00C8FF" />
<path d="M222.91 149.458L228.31 145.457L230.094 141.262L224.646 145.649L222.91 149.458Z" fill="#26FFD1" />
<path d="M188.195 154.762L196.343 152.255L198.706 155.1L190.461 157.607L188.195 154.762Z" fill="#02E8F4" />
<path d="M48.3681 180.364L49.8628 185.089L46.0056 174.53L44.3662 169.516L48.3681 180.364Z" fill="#0078FF" />
<path d="M201.984 71.7341L192.052 63.6338L189.448 66.3821L199.091 74.0967L201.984 71.7341Z" fill="#FF8900" />
<path d="M223.344 84.5115L215.822 75.9773L213.412 73.0361L220.74 81.6668L223.344 84.5115Z" fill="#FFB900" />
<path d="M122.718 164.646L123.489 171.59L120.018 181.667L119.246 174.82L122.718 164.646Z" fill="#0098FF" />
<path d="M34.916 120.143L36.5554 127.134L36.8447 114.888L35.1571 107.8L34.916 120.143Z" fill="#97FF60" />
<path d="M114.521 60.0176L111.531 61.6569L114.376 72.7948L116.642 70.7697L114.521 60.0176Z" fill="#FF7300" />
<path d="M190.461 136.198L194.897 133.064L195.716 137.886L191.281 140.731L190.461 136.198Z" fill="#4DFFAA" />
<path d="M232.312 105.003L228.985 97.5779L227.491 92.5635L230.818 100.326L232.312 105.003Z" fill="#DEFF19" />
<path d="M52.8525 45.6007L48.0791 52.0134L53.4793 45.1668L57.6741 38.9951L52.8525 45.6007Z" fill="#FF1A00" />
<path d="M204.154 161.994L212.93 159.053L215.774 157.654L206.854 160.788L204.154 161.994Z" fill="#00D0FF" />
<path d="M186.218 151.435L194.27 148.88L196.343 152.255L188.195 154.762L186.218 151.435Z" fill="#13FCE4" />
<path d="M232.795 126.749L234 120.915L233.952 115.418L232.747 121.686L232.795 126.749Z" fill="#87FF70" />
<path d="M118.619 100.471L120.644 104.762L121.705 117.587L119.535 113.055L118.619 100.471Z" fill="#C4FF33" />
<path d="M102.659 29.207L95.7163 29.8338L100.152 35.3787L105.986 34.6072L102.659 29.207Z" fill="#B60000" />
<path d="M200.152 88.3684L193.836 81.6182L191.57 86.6326L197.79 92.8043L200.152 88.3684Z" fill="#FFD700" />
<path d="M220.741 81.6667L213.364 73.0361L210.712 71.1074L217.944 79.7381L220.741 81.6667Z" fill="#FFA700" />
<path d="M111.049 203.652L111.531 208.811L105.697 214.019L105.07 208.956L111.049 203.652Z" fill="#0000FA" />
<path d="M99.2843 217.395L99.3807 221.059L92.7751 222.554L92.4858 218.889L99.2843 217.395Z" fill="#0000BB" />
<path d="M224.646 145.649L230.094 141.261L231.444 136.681L225.948 141.406L224.646 145.649Z" fill="#3CFFBA" />
<path d="M206.854 160.789L215.774 157.655L218.426 155.533L209.362 158.957L206.854 160.789Z" fill="#00DCFE" />
<path d="M198.658 103.363L195.813 97.8184L194.27 103.363L197.115 108.329L198.658 103.363Z" fill="#D4FF23" />
<path d="M112.014 50.2295L108.205 51.4831L111.531 61.6567L114.521 60.0173L112.014 50.2295Z" fill="#FF3F00" />
<path d="M195.234 117.973L196.006 113.634L195.331 119.082L194.607 122.988L195.234 117.973Z" fill="#9AFF5D" />
<path d="M69.2461 29.9304L63.1709 34.2699L69.1979 31.4734L74.4052 27.375L69.2461 29.9304Z" fill="#B20000" />
<path d="M72.4282 215.996L72.7175 218.455L66.4977 213.007L65.9673 210.403L72.4282 215.996Z" fill="#0000D6" />
<path d="M184.579 147.674L192.582 144.974L194.27 148.879L186.218 151.435L184.579 147.674Z" fill="#26FFD1" />
<path d="M65.9671 210.404L66.4974 213.007L60.7597 206.161L59.9883 203.268L65.9671 210.404Z" fill="#0000FA" />
<path d="M190.075 131.521L194.511 128.05L194.897 133.064L190.461 136.198L190.075 131.521Z" fill="#63FF94" />
<path d="M121.705 117.587L124.694 122.457L124.646 135.138L121.705 130.123V117.587Z" fill="#83FF73" />
<path d="M105.986 34.6074L100.152 35.3789L104.347 42.6595L109.121 41.647L105.986 34.6074Z" fill="#D60000" />
<path d="M86.5555 24.5781L79.8535 26.8443L85.4948 28.4354L91.1361 26.2175L86.5555 24.5781Z" fill="#960000" />
<path d="M230.818 100.326L227.491 92.5634L225.61 88.1758L228.889 96.1796L230.818 100.326Z" fill="#F4F802" />
<path d="M109.121 41.6475L104.347 42.66L108.156 51.4835L112.014 50.2299L109.121 41.6475Z" fill="#FF1300" />
<path d="M79.1302 219.95L79.1785 222.264L72.7175 218.455L72.4282 215.996L79.1302 219.95Z" fill="#0000BF" />
<path d="M202.804 84.6562L196.343 77.4238L193.836 81.6186L200.152 88.3689L202.804 84.6562Z" fill="#FFC100" />
<path d="M35.6875 88.8508L36.6036 95.8903L39.1591 84.8006L38.2429 77.7129L35.6875 88.8508Z" fill="#FFD000" />
<path d="M217.944 79.7382L210.711 71.1075L207.867 70.1914L214.906 78.7739L217.944 79.7382Z" fill="#FF9C00" />
<path d="M232.747 121.685L233.952 115.417L233.373 110.065L232.168 116.719L232.747 121.685Z" fill="#A0FF56" />
<path d="M114.376 72.7949L114.135 75.8325L116.787 87.9348L116.497 84.6078L114.376 72.7949Z" fill="#FFAB00" />
<path d="M59.9886 203.267L60.7118 206.16L55.5045 197.963L54.5884 194.732L59.9886 203.267Z" fill="#0014FF" />
<path d="M209.362 158.957L218.426 155.533L220.837 152.785L211.676 156.45L209.362 158.957Z" fill="#06ECF1" />
<path d="M44.3663 169.516L46.0057 174.53L43.0645 163.296L41.377 157.896L44.3663 169.516Z" fill="#00BCFF" />
<path d="M120.017 181.666L120.741 188.031L116.353 196.613L115.533 190.393L120.017 181.666Z" fill="#0054FF" />
<path d="M183.373 143.623L191.28 140.73L192.582 144.973L184.578 147.674L183.373 143.623Z" fill="#39FFBE" />
<path d="M205.697 81.7624L199.092 74.0479L196.343 77.423L202.804 84.6554L205.697 81.7624Z" fill="#FFAE00" />
<path d="M124.646 135.138L126.382 141.165L125.128 153.363L123.441 147.24L124.646 135.138Z" fill="#40FFB7" />
<path d="M214.907 78.7739L207.867 70.1914L204.926 70.4325L211.821 78.8221L214.907 78.7739Z" fill="#FF9800" />
<path d="M37.4718 139.525L39.4004 145.89L38.4843 133.788L36.5557 127.134L37.4718 139.525Z" fill="#56FFA0" />
<path d="M225.948 141.406L231.444 136.68L232.36 131.811L226.816 136.921L225.948 141.406Z" fill="#53FFA4" />
<path d="M200.683 98.8799L197.79 92.8047L195.813 97.8192L198.706 103.364L200.683 98.8799Z" fill="#EEFF09" />
<path d="M208.735 79.7855L201.985 71.7334L199.092 74.0478L205.697 81.7623L208.735 79.7855Z" fill="#FF9F00" />
<path d="M211.82 78.8212L204.926 70.4316L201.984 71.7335L208.735 79.7855L211.82 78.8212Z" fill="#FF9800" />
<path d="M196.343 113.2L197.115 108.33L196.006 113.634L195.234 117.973L196.343 113.2Z" fill="#B4FF43" />
<path d="M85.977 222.168L85.7842 224.386L79.1786 222.265L79.1304 219.95L85.977 222.168Z" fill="#0000B6" />
<path d="M181.589 162.621L192.968 159.824L195.668 161.415L184.145 164.26L181.589 162.621Z" fill="#00D8FF" />
<path d="M228.889 96.179L225.61 88.1752L223.344 84.5107L226.575 92.7075L228.889 96.179Z" fill="#FFDE00" />
<path d="M190.22 126.796L194.607 122.987L194.511 128.05L190.075 131.522L190.22 126.796Z" fill="#7DFF7A" />
<path d="M184.145 164.261L195.668 161.416L198.465 162.332L186.845 165.273L184.145 164.261Z" fill="#00D0FF" />
<path d="M179.178 160.355L190.461 157.606L192.968 159.824L181.589 162.621L179.178 160.355Z" fill="#00E0FB" />
<path d="M186.845 165.273L198.513 162.332L201.31 162.525L189.545 165.611L186.845 165.273Z" fill="#00D0FF" />
<path d="M43.5467 60.5479L41.9556 67.6839L46.6807 59.0532L48.079 52.0137L43.5467 60.5479Z" fill="#FF4E00" />
<path d="M177.009 157.51L188.195 154.762L190.461 157.606L179.179 160.355L177.009 157.51Z" fill="#09F0EE" />
<path d="M211.676 156.449L220.837 152.785L222.91 149.458L213.653 153.412L211.676 156.449Z" fill="#19FFDE" />
<path d="M54.5884 194.733L55.5046 197.964L51.0205 188.706L49.8633 185.09L54.5884 194.733Z" fill="#0048FF" />
<path d="M182.554 139.284L190.461 136.198L191.281 140.731L183.373 143.624L182.554 139.284Z" fill="#50FFA7" />
<path d="M232.168 116.719L233.373 110.066L232.361 105.003L231.107 111.946L232.168 116.719Z" fill="#BEFF39" />
<path d="M91.1359 26.2178L85.4946 28.4357L91.1359 32.0519L95.7164 29.834L91.1359 26.2178Z" fill="#960000" />
<path d="M203.046 94.9255L200.153 88.3682L197.79 92.804L200.683 98.8793L203.046 94.9255Z" fill="#FFE600" />
<path d="M105.697 214.02L105.601 217.829L99.3811 221.059L99.2847 217.395L105.697 214.02Z" fill="#0000DA" />
<path d="M189.545 165.61L201.31 162.525L204.154 161.994L192.197 165.225L189.545 165.61Z" fill="#00D0FF" />
<path d="M116.787 87.9346L118.571 92.0329L120.644 104.762L118.619 100.471L116.787 87.9346Z" fill="#FFEA00" />
<path d="M175.128 154.231L186.218 151.435L188.195 154.761L177.009 157.51L175.128 154.231Z" fill="#16FFE1" />
<path d="M111.531 61.6562L110.664 64.4046L114.135 75.8318L114.376 72.7941L111.531 61.6562Z" fill="#FF6C00" />
<path d="M35.1572 107.799L36.8448 114.887L38.2431 103.17L36.6037 95.8896L35.1572 107.799Z" fill="#D7FF1F" />
<path d="M226.816 136.922L232.361 131.811L232.795 126.748L227.202 132.196L226.816 136.922Z" fill="#6DFF8A" />
<path d="M57.6743 39.0429L53.4795 45.1664L59.6029 40.1037L63.1709 34.2695L57.6743 39.0429Z" fill="#D10000" />
<path d="M226.575 92.7075L223.344 84.5108L220.741 81.666L223.923 89.9592L226.575 92.7075Z" fill="#FFCC00" />
<path d="M125.128 153.363L125.9 160.306L123.489 171.589L122.717 164.646L125.128 153.363Z" fill="#00E4F8" />
<path d="M197.886 108.715L198.706 103.363L197.115 108.33L196.343 113.199L197.886 108.715Z" fill="#CEFF29" />
<path d="M74.4051 27.3747L69.1978 31.4731L75.5623 30.7981L79.8535 26.8926L74.4051 27.3747Z" fill="#960000" />
<path d="M190.798 122.167L195.234 117.973L194.607 122.987L190.22 126.796L190.798 122.167Z" fill="#97FF60" />
<path d="M92.7755 222.554L92.2933 224.723L85.7842 224.386L85.977 222.168L92.7755 222.554Z" fill="#0000BB" />
<path d="M192.197 165.225L204.154 161.994L206.854 160.789L194.752 164.212L192.197 165.225Z" fill="#00D8FF" />
<path d="M116.353 196.613L116.739 201.965L111.531 208.812L111.049 203.653L116.353 196.613Z" fill="#0020FF" />
<path d="M213.653 153.412L222.91 149.458L224.646 145.649L215.292 149.844L213.653 153.412Z" fill="#29FFCE" />
<path d="M173.537 150.519L184.579 147.674L186.218 151.435L175.128 154.231L173.537 150.519Z" fill="#29FFCE" />
<path d="M205.745 91.6948L202.804 84.6553L200.152 88.3679L203.045 94.9253L205.745 91.6948Z" fill="#FFD000" />
<path d="M182.168 134.848L190.075 131.521L190.461 136.198L182.554 139.284L182.168 134.848Z" fill="#66FF90" />
<path d="M231.107 111.946L232.312 105.003L230.818 100.326L229.612 107.559L231.107 111.946Z" fill="#D4FF23" />
<path d="M223.923 89.9591L220.74 81.6659L217.944 79.7373L221.078 88.0305L223.923 89.9591Z" fill="#FFB900" />
<path d="M95.7163 29.834L91.1357 32.0519L96.5842 37.645L100.152 35.3788L95.7163 29.834Z" fill="#A80000" />
<path d="M41.3772 157.895L43.0648 163.296L41.1844 151.627L39.4004 145.89L41.3772 157.895Z" fill="#19FFDE" />
<path d="M120.644 104.762L123.537 109.535L124.694 122.457L121.705 117.587L120.644 104.762Z" fill="#C4FF33" />
<path d="M108.205 51.4834L106.517 54.0388L110.664 64.4053L111.532 61.657L108.205 51.4834Z" fill="#FF3400" />
<path d="M49.8631 185.09L51.0203 188.706L47.3077 178.532L46.0059 174.53L49.8631 185.09Z" fill="#0080FF" />
<path d="M208.638 89.2362L205.697 81.7627L202.804 84.6557L205.745 91.6952L208.638 89.2362Z" fill="#FFC100" />
<path d="M194.752 164.213L206.854 160.837L209.361 158.957L197.163 162.525L194.752 164.213Z" fill="#00E4F8" />
<path d="M227.202 132.197L232.795 126.748L232.746 121.686L227.153 127.471L227.202 132.197Z" fill="#87FF70" />
<path d="M172.38 146.469L183.325 143.624L184.579 147.722L173.537 150.519L172.38 146.469Z" fill="#3CFFBA" />
<path d="M199.863 104.666L200.683 98.8799L198.706 103.364L197.886 108.716L199.863 104.666Z" fill="#E4FF13" />
<path d="M221.078 88.0309L217.944 79.7378L214.906 78.7734L217.992 86.9702L221.078 88.0309Z" fill="#FFB200" />
<path d="M100.152 35.3789L96.5845 37.6451L101.792 45.0703L104.347 42.6595L100.152 35.3789Z" fill="#C80000" />
<path d="M104.347 42.6592L101.792 45.07L106.517 54.0382L108.204 51.4827L104.347 42.6592Z" fill="#F10800" />
<path d="M191.811 117.732L196.343 113.199L195.234 117.973L190.798 122.167L191.811 117.732Z" fill="#B1FF46" />
<path d="M211.724 87.5971L208.687 79.7861L205.697 81.763L208.638 89.2365L211.724 87.5971Z" fill="#FFB200" />
<path d="M38.2432 77.7125L39.1593 84.8003L42.8719 74.8678L41.9558 67.6836L38.2432 77.7125Z" fill="#FF8D00" />
<path d="M215.292 149.844L224.646 145.649L225.948 141.406L216.546 145.89L215.292 149.844Z" fill="#40FFB7" />
<path d="M217.992 86.9702L214.907 78.7734H211.821L214.858 86.8255L217.992 86.9702Z" fill="#FFAB00" />
<path d="M214.858 86.8255L211.821 78.7734L208.687 79.786L211.724 87.597L214.858 86.8255Z" fill="#FFAE00" />
<path d="M182.264 130.365L190.22 126.797L190.075 131.522L182.168 134.849L182.264 130.365Z" fill="#7DFF7A" />
<path d="M167.896 165.707L181.589 162.621L184.145 164.26L170.355 167.491L167.896 165.707Z" fill="#00DCFE" />
<path d="M165.63 163.344L179.179 160.354L181.589 162.621L167.896 165.706L165.63 163.344Z" fill="#00E4F8" />
<path d="M36.5557 127.134L38.4843 133.787L38.7736 121.83L36.845 114.887L36.5557 127.134Z" fill="#97FF60" />
<path d="M229.612 107.559L230.818 100.326L228.889 96.1797L227.684 103.653L229.612 107.559Z" fill="#EBFF0C" />
<path d="M72.7174 218.455L72.4281 219.42L66.4976 214.26V213.007L72.7174 218.455Z" fill="#0000ED" />
<path d="M123.489 171.589L124.212 178.05L120.741 188.031L120.018 181.714L123.489 171.589Z" fill="#009CFF" />
<path d="M124.694 122.457L126.43 128.436L126.382 141.165L124.646 135.138L124.694 122.457Z" fill="#83FF73" />
<path d="M170.355 167.491L184.145 164.261L186.845 165.273L172.862 168.6L170.355 167.491Z" fill="#00D4FF" />
<path d="M66.4975 213.007V214.26L61.0008 207.703L60.7598 206.16L66.4975 213.007Z" fill="#0000FF" />
<path d="M114.135 75.832L115.485 79.7375L118.571 92.0326L116.787 87.9343L114.135 75.832Z" fill="#FFA300" />
<path d="M163.557 160.451L177.009 157.51L179.179 160.355L165.63 163.344L163.557 160.451Z" fill="#0CF4EB" />
<path d="M197.163 162.524L209.362 158.956L211.676 156.449L199.333 160.21L197.163 162.524Z" fill="#0CF4EB" />
<path d="M99.3808 221.059L98.6576 223.325L92.3413 224.723L92.7753 222.553L99.3808 221.059Z" fill="#0000CD" />
<path d="M171.56 142.274L182.553 139.284L183.325 143.624L172.38 146.468L171.56 142.274Z" fill="#50FFA7" />
<path d="M79.1785 222.264L78.5999 223.036L72.4282 219.419L72.7175 218.455L79.1785 222.264Z" fill="#0000D6" />
<path d="M202.178 101.146L203.046 94.9258L200.683 98.8795L199.863 104.665L202.178 101.146Z" fill="#F8F500" />
<path d="M172.862 168.599L186.845 165.272L189.545 165.61L175.418 169.033L172.862 168.599Z" fill="#00D4FF" />
<path d="M79.8532 26.8926L75.562 30.7981L82.1194 32.2446L85.4945 28.4355L79.8532 26.8926Z" fill="#890000" />
<path d="M161.772 157.124L175.128 154.231L177.009 157.51L163.556 160.5L161.772 157.124Z" fill="#19FFDE" />
<path d="M111.531 208.812L111.29 212.765L105.601 217.828L105.697 214.019L111.531 208.812Z" fill="#0000FF" />
<path d="M227.153 127.472L232.746 121.686L232.168 116.72L226.575 122.795L227.153 127.472Z" fill="#A0FF56" />
<path d="M60.7599 206.16L61.001 207.703L56.0348 199.892L55.5044 197.963L60.7599 206.16Z" fill="#0024FF" />
<path d="M48.0789 52.0137L46.6807 59.0532L52.2737 52.0619L53.4791 45.167L48.0789 52.0137Z" fill="#FF1600" />
<path d="M193.306 113.634L197.886 108.716L196.343 113.2L191.811 117.732L193.306 113.634Z" fill="#C7FF30" />
<path d="M227.684 103.653L228.889 96.2278L226.575 92.708L225.417 100.326L227.684 103.653Z" fill="#FEED00" />
<path d="M216.546 145.89L225.948 141.406L226.816 136.922L217.366 141.695L216.546 145.89Z" fill="#56FFA0" />
<path d="M63.1705 34.2702L59.6025 40.1043L66.3046 37.1149L69.1975 31.4736L63.1705 34.2702Z" fill="#A80000" />
<path d="M175.417 169.034L189.545 165.61L192.197 165.225L177.973 168.793L175.417 169.034Z" fill="#00D4FF" />
<path d="M182.795 126.025L190.798 122.168L190.22 126.797L182.264 130.365L182.795 126.025Z" fill="#97FF60" />
<path d="M160.278 153.363L173.537 150.519L175.128 154.231L161.773 157.124L160.278 153.363Z" fill="#29FFCE" />
<path d="M46.0056 174.53L47.3075 178.532L44.4627 167.684L43.0645 163.296L46.0056 174.53Z" fill="#00C4FF" />
<path d="M199.333 160.211L211.676 156.45L213.653 153.412L201.213 157.318L199.333 160.211Z" fill="#1CFFDB" />
<path d="M85.7838 224.386L84.9159 225.061L78.5996 223.036L79.1782 222.265L85.7838 224.386Z" fill="#0000CD" />
<path d="M171.223 137.934L182.168 134.849L182.553 139.285L171.56 142.274L171.223 137.934Z" fill="#66FF90" />
<path d="M204.829 98.3009L205.745 91.6953L203.045 94.9258L202.177 101.146L204.829 98.3009Z" fill="#FFDE00" />
<path d="M36.6035 95.8905L38.2429 103.171L40.7983 92.226L39.159 84.8008L36.6035 95.8905Z" fill="#FFD300" />
<path d="M126.382 141.165L127.153 148.205L125.9 160.307L125.128 153.364L126.382 141.165Z" fill="#40FFB7" />
<path d="M177.973 168.792L192.197 165.224L194.752 164.212L180.384 167.876L177.973 168.792Z" fill="#00DCFE" />
<path d="M225.418 100.326L226.575 92.7083L223.923 89.96L222.814 97.6745L225.418 100.326Z" fill="#FFDB00" />
<path d="M226.575 122.795L232.168 116.719L231.107 111.994L225.562 118.31L226.575 122.795Z" fill="#B7FF40" />
<path d="M118.571 92.0332L121.174 96.7102L123.537 109.536L120.644 104.762L118.571 92.0332Z" fill="#FFE600" />
<path d="M55.5041 197.964L56.0345 199.892L51.7433 191.02L51.02 188.706L55.5041 197.964Z" fill="#0054FF" />
<path d="M39.4 145.89L41.184 151.628L40.3161 139.863L38.4839 133.788L39.4 145.89Z" fill="#56FFA0" />
<path d="M159.121 149.362L172.38 146.469L173.537 150.519L160.278 153.412L159.121 149.362Z" fill="#3CFFBA" />
<path d="M120.741 188.031L121.078 193.528L116.739 201.966L116.353 196.614L120.741 188.031Z" fill="#005CFF" />
<path d="M110.664 64.4053L111.435 68.2143L115.485 79.738L114.135 75.8325L110.664 64.4053Z" fill="#FF6400" />
<path d="M207.674 96.1794L208.638 89.2363L205.745 91.6953L204.829 98.3009L207.674 96.1794Z" fill="#FFD000" />
<path d="M195.234 109.969L199.863 104.665L197.886 108.715L193.306 113.633L195.234 109.969Z" fill="#DEFF19" />
<path d="M85.4947 28.4355L82.1196 32.2446L88.7252 35.7644L91.136 32.0517L85.4947 28.4355Z" fill="#890000" />
<path d="M217.365 141.695L226.816 136.921L227.201 132.196L217.751 137.259L217.365 141.695Z" fill="#6DFF8A" />
<path d="M222.766 97.6745L223.923 89.9599L221.078 88.0312L219.921 95.7458L222.766 97.6745Z" fill="#FFCC00" />
<path d="M183.807 121.878L191.811 117.731L190.798 122.167L182.794 126.025L183.807 121.878Z" fill="#ADFF49" />
<path d="M201.213 157.317L213.653 153.412L215.292 149.844L202.804 153.99L201.213 157.317Z" fill="#2CFFCA" />
<path d="M171.271 133.643L182.264 130.364L182.168 134.848L171.223 137.934L171.271 133.643Z" fill="#7DFF7A" />
<path d="M153.72 170.384L169.101 166.671L166.739 164.598L151.31 168.262L153.72 170.384Z" fill="#00DCFE" />
<path d="M151.31 168.262L166.739 164.598L164.569 161.994L149.092 165.61L151.31 168.262Z" fill="#02E8F4" />
<path d="M210.711 94.8291L211.724 87.5967L208.638 89.236L207.674 96.1791L210.711 94.8291Z" fill="#FFC400" />
<path d="M105.601 217.828L104.636 220.191L98.6577 223.325L99.381 221.059L105.601 217.828Z" fill="#0000ED" />
<path d="M180.384 167.876L194.752 164.212L197.163 162.524L182.65 166.285L180.384 167.876Z" fill="#02E8F4" />
<path d="M156.227 171.83L171.608 168.117L169.101 166.671L153.72 170.384L156.227 171.83Z" fill="#00D4FF" />
<path d="M92.3412 224.723L91.1358 225.398L84.8677 225.061L85.7838 224.386L92.3412 224.723Z" fill="#0000D1" />
<path d="M219.921 95.745L221.078 88.0305L217.992 86.9697L216.932 94.6361L219.921 95.745Z" fill="#FFC400" />
<path d="M158.349 145.166L171.609 142.273L172.38 146.468L159.121 149.361L158.349 145.166Z" fill="#53FFA4" />
<path d="M149.092 165.611L164.569 161.994L162.64 158.86L147.115 162.428L149.092 165.611Z" fill="#0FF8E7" />
<path d="M213.798 94.2987L214.858 86.8252L211.724 87.5966L210.712 94.829L213.798 94.2987Z" fill="#FFC100" />
<path d="M225.562 118.311L231.107 111.946L229.612 107.559L224.067 114.164L225.562 118.311Z" fill="#CEFF29" />
<path d="M216.931 94.6362L217.992 87.0181L214.858 86.8252L213.797 94.2987L216.931 94.6362Z" fill="#FFBD00" />
<path d="M158.831 172.649L174.164 168.889L171.608 168.117L156.228 171.83L158.831 172.649Z" fill="#00D4FF" />
<path d="M197.452 106.835L202.177 101.146L199.863 104.665L195.234 109.969L197.452 106.835Z" fill="#F1FC06" />
<path d="M106.517 54.0381L106.614 57.7507L111.435 68.2136L110.664 64.4045L106.517 54.0381Z" fill="#FF2D00" />
<path d="M147.115 162.428L162.64 158.86L160.953 155.292L145.427 158.812L147.115 162.428Z" fill="#1CFFDB" />
<path d="M41.9556 67.6834L42.8717 74.8676L47.5968 66.2369L46.6807 59.0527L41.9556 67.6834Z" fill="#FF4E00" />
<path d="M123.537 109.535L125.225 115.466L126.43 128.436L124.694 122.457L123.537 109.535Z" fill="#C4FF33" />
<path d="M91.1359 32.0518L88.7251 35.7644L95.0896 41.3092L96.5843 37.6448L91.1359 32.0518Z" fill="#9B0000" />
<path d="M36.8447 114.887L38.7734 121.83L40.1716 110.355L38.243 103.171L36.8447 114.887Z" fill="#D4FF23" />
<path d="M51.0202 188.706L51.7435 191.02L48.1755 181.329L47.3076 178.532L51.0202 188.706Z" fill="#008CFF" />
<path d="M217.751 137.26L227.202 132.197L227.153 127.472L217.655 132.775L217.751 137.26Z" fill="#87FF70" />
<path d="M161.435 172.746L176.719 168.985L174.164 168.889L158.831 172.65L161.435 172.746Z" fill="#00D4FF" />
<path d="M69.1976 31.4739L66.3047 37.1151L73.3924 36.199L75.5622 30.7988L69.1976 31.4739Z" fill="#8D0000" />
<path d="M185.205 118.07L193.306 113.634L191.811 117.732L183.807 121.879L185.205 118.07Z" fill="#C4FF33" />
<path d="M202.804 153.99L215.292 149.843L216.546 145.89L203.961 150.229L202.804 153.99Z" fill="#43FFB4" />
<path d="M182.65 166.285L197.163 162.524L199.333 160.21L184.723 164.115L182.65 166.285Z" fill="#0FF8E7" />
<path d="M116.738 201.965L116.305 206.111L111.29 212.765L111.531 208.812L116.738 201.965Z" fill="#0028FF" />
<path d="M125.9 160.307L126.575 166.96L124.212 178.05L123.489 171.589L125.9 160.307Z" fill="#02E8F4" />
<path d="M171.801 129.449L182.795 126.025L182.264 130.365L171.271 133.644L171.801 129.449Z" fill="#94FF63" />
<path d="M43.0645 163.296L44.4628 167.684L42.6306 156.498L41.1841 151.628L43.0645 163.296Z" fill="#1CFFDB" />
<path d="M72.4284 219.42L71.8016 218.889L66.2085 214.02L66.4978 214.261L72.4284 219.42Z" fill="#0000FF" />
<path d="M158.012 140.876L171.223 137.935L171.56 142.274L158.349 145.167L158.012 140.876Z" fill="#66FF90" />
<path d="M66.4976 214.261L66.2083 214.019L61.001 207.848V207.703L66.4976 214.261Z" fill="#0010FF" />
<path d="M145.427 158.812L160.953 155.292L159.651 151.387L144.077 154.858L145.427 158.812Z" fill="#30FFC7" />
<path d="M224.067 114.164L229.612 107.559L227.684 103.653L222.187 110.404L224.067 114.164Z" fill="#E4FF13" />
<path d="M101.792 45.0703L101.117 48.6865L106.613 57.7511L106.517 54.0385L101.792 45.0703Z" fill="#E80000" />
<path d="M96.5845 37.6455L95.0898 41.3099L101.117 48.687L101.792 45.0708L96.5845 37.6455Z" fill="#BB0000" />
<path d="M53.4793 45.1672L52.2739 52.0621L58.6867 46.9029L59.6028 40.1045L53.4793 45.1672Z" fill="#D10000" />
<path d="M200.008 104.28L204.829 98.3008L202.177 101.146L197.452 106.835L200.008 104.28Z" fill="#FEED00" />
<path d="M78.5997 223.035L77.6354 222.36L71.8013 218.889L72.4281 219.419L78.5997 223.035Z" fill="#0000F1" />
<path d="M115.485 79.7373L117.751 84.366L121.223 96.7093L118.571 92.0324L115.485 79.7373Z" fill="#FF9F00" />
<path d="M163.942 172.168L179.179 168.407L176.72 168.986L161.435 172.747L163.942 172.168Z" fill="#00D8FF" />
<path d="M61.0009 207.703V207.847L56.2757 200.47L56.0347 199.892L61.0009 207.703Z" fill="#0038FF" />
<path d="M98.6574 223.325L97.211 224.097L91.1357 225.398L92.2929 224.723L98.6574 223.325Z" fill="#0000E3" />
<path d="M217.655 132.776L227.153 127.472L226.575 122.795L217.076 128.292L217.655 132.776Z" fill="#9DFF5A" />
<path d="M144.077 154.858L159.651 151.386L158.687 147.288L143.113 150.663L144.077 154.858Z" fill="#43FFB4" />
<path d="M222.187 110.403L227.684 103.653L225.418 100.326L219.969 107.221L222.187 110.403Z" fill="#F4F802" />
<path d="M184.723 164.116L199.333 160.21L201.213 157.317L186.507 161.319L184.723 164.116Z" fill="#1FFFD7" />
<path d="M186.99 114.694L195.234 109.969L193.306 113.633L185.206 118.069L186.99 114.694Z" fill="#D7FF1F" />
<path d="M137.857 174.483L153.72 170.384L151.31 168.263L135.35 172.313L137.857 174.483Z" fill="#00D8FF" />
<path d="M203.961 150.23L216.546 145.89L217.365 141.695L204.733 146.179L203.961 150.23Z" fill="#56FFA0" />
<path d="M135.35 172.312L151.31 168.262L149.092 165.61L133.036 169.516L135.35 172.312Z" fill="#00E4F8" />
<path d="M202.756 102.448L207.674 96.1797L204.829 98.3012L200.008 104.328L202.756 102.448Z" fill="#FFDE00" />
<path d="M158.06 136.585L171.271 133.644L171.223 137.935L158.012 140.876L158.06 136.585Z" fill="#7DFF7A" />
<path d="M172.717 125.495L183.807 121.879L182.795 126.025L171.801 129.449L172.717 125.495Z" fill="#AAFF4D" />
<path d="M84.8677 225.06L83.5658 224.241L77.6353 222.36L78.5996 223.035L84.8677 225.06Z" fill="#0000E8" />
<path d="M140.509 175.977L156.228 171.83L153.72 170.384L137.857 174.482L140.509 175.977Z" fill="#00D0FF" />
<path d="M38.4839 133.788L40.3161 139.863L40.6054 128.291L38.7732 121.83L38.4839 133.788Z" fill="#94FF63" />
<path d="M133.036 169.515L149.092 165.61L147.115 162.428L131.011 166.189L133.036 169.515Z" fill="#0CF4EB" />
<path d="M166.305 170.866L181.541 167.153L179.179 168.407L163.942 172.168L166.305 170.866Z" fill="#00E4F8" />
<path d="M126.43 128.436L127.153 135.523V148.204L126.382 141.165L126.43 128.436Z" fill="#83FF73" />
<path d="M39.1592 84.8007L40.7985 92.2259L44.4629 82.3899L42.8718 74.8682L39.1592 84.8007Z" fill="#FF9100" />
<path d="M111.29 212.766L110.133 215.369L104.636 220.191L105.601 217.828L111.29 212.766Z" fill="#0008FF" />
<path d="M219.969 107.221L225.417 100.326L222.814 97.6738L217.414 104.569L219.969 107.221Z" fill="#FFE600" />
<path d="M143.161 176.796L158.831 172.65L156.228 171.83L140.509 175.977L143.161 176.796Z" fill="#00CCFF" />
<path d="M124.212 178.05L124.453 183.788L121.078 193.527L120.741 188.031L124.212 178.05Z" fill="#00A0FF" />
<path d="M47.3076 178.532L48.1755 181.329L45.4754 171.01L44.4629 167.684L47.3076 178.532Z" fill="#00CCFF" />
<path d="M205.697 101.338L210.712 94.8291L207.674 96.1792L202.756 102.447L205.697 101.338Z" fill="#FFD300" />
<path d="M56.0349 199.892L56.276 200.471L52.2258 192.081L51.7437 191.021L56.0349 199.892Z" fill="#0064FF" />
<path d="M131.011 166.189L147.115 162.428L145.427 158.812L129.227 162.476L131.011 166.189Z" fill="#1CFFDB" />
<path d="M143.113 150.663L158.686 147.288L158.156 142.997L142.486 146.372L143.113 150.663Z" fill="#5AFF9D" />
<path d="M75.5623 30.7988L73.3926 36.199L80.7214 37.4044L82.1197 32.2453L75.5623 30.7988Z" fill="#800000" />
<path d="M217.076 128.291L226.575 122.795L225.562 118.311L216.112 124L217.076 128.291Z" fill="#B4FF43" />
<path d="M217.414 104.569L222.814 97.6738L219.921 95.7451L214.666 102.64L217.414 104.569Z" fill="#FFDB00" />
<path d="M189.159 111.801L197.452 106.835L195.234 109.969L186.989 114.694L189.159 111.801Z" fill="#E7FF0F" />
<path d="M208.735 101.001L213.798 94.2988L210.712 94.8292L205.697 101.338L208.735 101.001Z" fill="#FFD000" />
<path d="M145.861 176.942L161.435 172.747L158.831 172.65L143.161 176.845L145.861 176.942Z" fill="#00D0FF" />
<path d="M186.507 161.319L201.213 157.317L202.804 153.99L187.954 158.089L186.507 161.319Z" fill="#30FFC7" />
<path d="M214.665 102.64L219.921 95.7451L216.931 94.5879L211.724 101.435L214.665 102.64Z" fill="#FFD000" />
<path d="M211.724 101.435L216.932 94.6363L213.798 94.2988L208.735 101.001L211.724 101.435Z" fill="#FFCC00" />
<path d="M204.733 146.18L217.365 141.696L217.751 137.26L205.07 141.889L204.733 146.18Z" fill="#6DFF8A" />
<path d="M158.542 132.486L171.801 129.448L171.271 133.643L158.06 136.632L158.542 132.486Z" fill="#94FF63" />
<path d="M174.067 121.878L185.205 118.069L183.807 121.878L172.717 125.495L174.067 121.878Z" fill="#C1FF36" />
<path d="M168.474 168.985L183.711 165.272L181.541 167.153L166.305 170.866L168.474 168.985Z" fill="#0CF4EB" />
<path d="M129.227 162.477L145.427 158.812L144.077 154.858L127.78 158.33L129.227 162.477Z" fill="#2CFFCA" />
<path d="M111.435 68.2139L113.315 72.8426L117.751 84.3662L115.485 79.7375L111.435 68.2139Z" fill="#FF6000" />
<path d="M121.174 96.71L122.814 102.689L125.224 115.466L123.537 109.535L121.174 96.71Z" fill="#FFE600" />
<path d="M91.1358 225.447L89.4483 224.579L83.5659 224.241L84.8677 225.061L91.1358 225.447Z" fill="#0000ED" />
<path d="M41.1843 151.628L42.6308 156.498L41.8111 145.215L40.3164 139.863L41.1843 151.628Z" fill="#56FFA0" />
<path d="M142.486 146.372L158.156 142.997L157.963 138.706L142.341 141.985L142.486 146.372Z" fill="#6DFF8A" />
<path d="M148.465 176.315L163.942 172.168L161.435 172.747L145.861 176.941L148.465 176.315Z" fill="#00D4FF" />
<path d="M46.6807 59.0528L47.5968 66.2852L53.1898 59.2939L52.2737 52.0615L46.6807 59.0528Z" fill="#FF1A00" />
<path d="M216.112 124L225.562 118.311L224.068 114.164L214.666 119.998L216.112 124Z" fill="#CAFF2C" />
<path d="M71.8013 218.889L71.1745 217.057L65.9189 212.477L66.2082 214.019L71.8013 218.889Z" fill="#000CFF" />
<path d="M104.637 220.191L102.901 221.108L97.2114 224.097L98.6579 223.325L104.637 220.191Z" fill="#0000FF" />
<path d="M122.524 178.966L137.857 174.482L135.35 172.312L119.873 176.652L122.524 178.966Z" fill="#00D0FF" />
<path d="M119.873 176.652L135.35 172.312L133.036 169.516L117.414 173.71L119.873 176.652Z" fill="#00DCFE" />
<path d="M66.2083 214.02L65.919 212.477L61.0492 206.643L61.001 207.848L66.2083 214.02Z" fill="#0028FF" />
<path d="M191.57 109.535L200.008 104.279L197.452 106.835L189.159 111.801L191.57 109.535Z" fill="#F8F500" />
<path d="M59.6026 40.1046L58.6865 46.9031L65.6778 43.7208L66.3047 37.1152L59.6026 40.1046Z" fill="#A40000" />
<path d="M125.273 180.606L140.509 175.977L137.857 174.482L122.524 178.967L125.273 180.606Z" fill="#00C8FF" />
<path d="M121.078 193.527L120.548 197.963L116.305 206.112L116.739 201.965L121.078 193.527Z" fill="#0064FF" />
<path d="M38.2432 103.171L40.1718 110.355L42.679 99.6508L40.7986 92.2256L38.2432 103.171Z" fill="#FFD700" />
<path d="M51.6951 191.021L52.2254 192.081L48.8503 182.92L48.1753 181.329L51.6951 191.021Z" fill="#0098FF" />
<path d="M127.78 158.329L144.077 154.858L143.113 150.663L126.768 153.99L127.78 158.329Z" fill="#43FFB4" />
<path d="M117.414 173.711L133.036 169.516L131.011 166.189L115.196 170.191L117.414 173.711Z" fill="#06ECF1" />
<path d="M77.6353 222.36L76.6227 220.287L71.1743 217.057L71.8011 218.889L77.6353 222.36Z" fill="#0000FF" />
<path d="M187.954 158.089L202.756 153.99L203.961 150.229L189.063 154.424L187.954 158.089Z" fill="#43FFB4" />
<path d="M205.071 141.888L217.751 137.259L217.655 132.775L204.974 137.549L205.071 141.888Z" fill="#83FF73" />
<path d="M82.1195 32.2451L80.7212 37.4042L88.05 40.7794L88.725 35.7649L82.1195 32.2451Z" fill="#840000" />
<path d="M170.403 166.43L185.64 162.766L183.711 165.273L168.475 168.986L170.403 166.43Z" fill="#19FFDE" />
<path d="M127.105 148.204L127.829 155.003L126.575 166.96L125.9 160.306L127.105 148.204Z" fill="#40FFB7" />
<path d="M128.118 181.473L143.161 176.844L140.509 175.977L125.273 180.605L128.118 181.473Z" fill="#00C4FF" />
<path d="M175.755 118.696L186.989 114.694L185.205 118.069L174.067 121.879L175.755 118.696Z" fill="#D4FF23" />
<path d="M159.41 128.629L172.718 125.495L171.801 129.449L158.542 132.486L159.41 128.629Z" fill="#AAFF4D" />
<path d="M61.001 207.847L61.0493 206.642L56.6134 199.747L56.2759 200.47L61.001 207.847Z" fill="#004CFF" />
<path d="M150.924 175.013L166.305 170.866L163.942 172.168L148.465 176.315L150.924 175.013Z" fill="#00E0FB" />
<path d="M115.196 170.191L131.011 166.189L129.227 162.477L113.315 166.237L115.196 170.191Z" fill="#16FFE1" />
<path d="M214.665 119.998L224.067 114.164L222.187 110.403L212.833 116.382L214.665 119.998Z" fill="#DEFF19" />
<path d="M106.613 57.751L107.963 62.4279L113.315 72.8426L111.435 68.2138L106.613 57.751Z" fill="#FF2900" />
<path d="M194.222 107.944L202.756 102.447L200.008 104.279L191.57 109.535L194.222 107.944Z" fill="#FFEA00" />
<path d="M142.341 141.984L157.963 138.706L158.253 134.511L142.582 137.741L142.341 141.984Z" fill="#83FF73" />
<path d="M44.4631 167.684L45.4756 171.011L43.7398 160.355L42.6309 156.498L44.4631 167.684Z" fill="#1FFFD7" />
<path d="M83.5662 224.241L82.1679 222.071L76.623 220.287L77.6356 222.36L83.5662 224.241Z" fill="#0000FF" />
<path d="M130.962 181.618L145.861 176.942L143.161 176.797L128.118 181.474L130.962 181.618Z" fill="#00C4FF" />
<path d="M126.767 153.99L143.113 150.663L142.486 146.372L126.141 149.506L126.767 153.99Z" fill="#56FFA0" />
<path d="M97.211 224.097L95.1859 223.325L89.4482 224.579L91.1358 225.447L97.211 224.097Z" fill="#0000FF" />
<path d="M113.315 166.238L129.227 162.477L127.78 158.33L111.772 161.85L113.315 166.238Z" fill="#29FFCE" />
<path d="M212.833 116.382L222.187 110.403L219.969 107.221L210.712 113.199L212.833 116.382Z" fill="#EEFF09" />
<path d="M88.6771 35.7646L88.0503 40.7791L95.138 46.1793L95.0898 41.3095L88.6771 35.7646Z" fill="#920000" />
<path d="M116.305 206.112L114.955 208.957L110.133 215.37L111.29 212.766L116.305 206.112Z" fill="#0038FF" />
<path d="M197.018 107.028L205.697 101.339L202.756 102.448L194.222 107.944L197.018 107.028Z" fill="#FFDE00" />
<path d="M204.974 137.548L217.655 132.775L217.076 128.291L204.443 133.209L204.974 137.548Z" fill="#9AFF5D" />
<path d="M56.2757 200.47L56.6132 199.747L52.8042 191.888L52.2256 192.081L56.2757 200.47Z" fill="#0074FF" />
<path d="M189.062 154.425L203.961 150.23L204.733 146.18L189.786 150.423L189.062 154.425Z" fill="#5AFF9D" />
<path d="M108.445 183.885L122.524 178.967L119.873 176.652L105.601 181.378L108.445 183.885Z" fill="#00C4FF" />
<path d="M105.601 181.377L119.873 176.652L117.414 173.711L102.949 178.195L105.601 181.377Z" fill="#00D0FF" />
<path d="M38.7734 121.83L40.6056 128.291L41.9557 117.153L40.1717 110.354L38.7734 121.83Z" fill="#D1FF26" />
<path d="M177.78 116.045L189.159 111.802L186.989 114.695L175.755 118.697L177.78 116.045Z" fill="#E4FF13" />
<path d="M101.117 48.6865L101.84 53.4599L107.963 62.4281L106.613 57.7511L101.117 48.6865Z" fill="#DF0000" />
<path d="M172.043 163.393L187.279 159.776L185.64 162.766L170.403 166.43L172.043 163.393Z" fill="#29FFCE" />
<path d="M125.225 115.466L125.948 122.602L127.153 135.524L126.43 128.436L125.225 115.466Z" fill="#C4FF33" />
<path d="M111.483 185.62L125.273 180.605L122.524 178.966L108.445 183.884L111.483 185.62Z" fill="#00BCFF" />
<path d="M153.142 173.035L168.474 168.985L166.305 170.866L150.924 175.012L153.142 173.035Z" fill="#09F0EE" />
<path d="M160.615 125.109L174.067 121.879L172.717 125.495L159.41 128.629L160.615 125.109Z" fill="#BEFF39" />
<path d="M95.0898 41.3096L95.1381 46.1794L101.84 53.46L101.117 48.6866L95.0898 41.3096Z" fill="#B20000" />
<path d="M133.711 180.943L148.465 176.314L145.861 176.941L130.962 181.618L133.711 180.943Z" fill="#00CCFF" />
<path d="M102.949 178.195L117.414 173.711L115.196 170.191L100.586 174.386L102.949 178.195Z" fill="#00E0FB" />
<path d="M210.711 113.2L219.969 107.221L217.413 104.569L208.252 110.645L210.711 113.2Z" fill="#FBF100" />
<path d="M42.8716 74.8676L44.4627 82.3893L49.1397 73.9515L47.5968 66.2852L42.8716 74.8676Z" fill="#FF5200" />
<path d="M199.912 106.835L208.735 101.001L205.697 101.338L197.019 107.028L199.912 106.835Z" fill="#FFDB00" />
<path d="M117.751 84.3662L119.246 90.345L122.814 102.688L121.174 96.7095L117.751 84.3662Z" fill="#FF9F00" />
<path d="M48.1757 181.329L48.8507 182.92L46.2953 173.18L45.4756 171.011L48.1757 181.329Z" fill="#00D4FF" />
<path d="M71.1745 217.057L71.0298 213.972L66.16 209.729L65.9189 212.477L71.1745 217.057Z" fill="#0028FF" />
<path d="M114.521 186.584L128.117 181.473L125.273 180.605L111.483 185.62L114.521 186.584Z" fill="#00B8FF" />
<path d="M142.583 137.741L158.253 134.511L158.928 130.509L143.209 133.691L142.583 137.741Z" fill="#9AFF5D" />
<path d="M126.574 166.961L126.767 172.892L124.453 183.788L124.212 178.051L126.574 166.961Z" fill="#02E8F4" />
<path d="M65.9191 212.477L66.1602 209.728L61.6279 204.328L61.0493 206.642L65.9191 212.477Z" fill="#0040FF" />
<path d="M126.141 149.506L142.486 146.372L142.342 141.984L125.9 144.974L126.141 149.506Z" fill="#6DFF8A" />
<path d="M111.773 161.849L127.78 158.33L126.768 153.99L110.664 157.221L111.773 161.849Z" fill="#40FFB7" />
<path d="M208.252 110.596L217.414 104.569L214.665 102.641L205.601 108.668L208.252 110.596Z" fill="#FFE600" />
<path d="M202.804 107.414L211.724 101.435L208.735 101.001L199.911 106.835L202.804 107.414Z" fill="#FFD700" />
<path d="M89.4486 224.579L87.7128 222.361L82.168 222.071L83.5662 224.241L89.4486 224.579Z" fill="#0000FF" />
<path d="M205.601 108.667L214.665 102.64L211.724 101.435L202.804 107.365L205.601 108.667Z" fill="#FFDB00" />
<path d="M100.538 174.386L115.196 170.191L113.315 166.237L98.5132 170.095L100.538 174.386Z" fill="#0FF8E7" />
<path d="M76.6228 220.287L76.1407 216.96L71.0298 213.971L71.1744 217.057L76.6228 220.287Z" fill="#0018FF" />
<path d="M66.3045 37.1143L65.6777 43.7682L73.103 42.7074L73.3923 36.1982L66.3045 37.1143Z" fill="#890000" />
<path d="M110.133 215.369L108.108 216.478L102.901 221.107L104.637 220.191L110.133 215.369Z" fill="#0018FF" />
<path d="M204.395 133.209L217.076 128.291L216.112 124L203.431 129.014L204.395 133.209Z" fill="#B1FF46" />
<path d="M180.095 113.923L191.57 109.535L189.159 111.801L177.78 116.044L180.095 113.923Z" fill="#F1FC06" />
<path d="M61.0492 206.642L61.6277 204.328L57.5294 197.915L56.6133 199.747L61.0492 206.642Z" fill="#0060FF" />
<path d="M189.786 150.422L204.733 146.179L205.071 141.888L190.075 146.227L189.786 150.422Z" fill="#6DFF8A" />
<path d="M117.558 186.729L130.962 181.618L128.117 181.474L114.521 186.585L117.558 186.729Z" fill="#00B8FF" />
<path d="M40.3164 139.863L41.8111 145.215L42.0522 134.077L40.5575 128.291L40.3164 139.863Z" fill="#94FF63" />
<path d="M136.315 179.545L150.924 175.013L148.465 176.315L133.711 180.943L136.315 179.545Z" fill="#00D8FF" />
<path d="M96.3914 189.092L108.445 183.884L105.601 181.377L93.2573 186.343L96.3914 189.092Z" fill="#00B4FF" />
<path d="M173.344 159.873L188.532 156.305L187.279 159.776L172.042 163.392L173.344 159.873Z" fill="#3CFFBA" />
<path d="M155.167 170.383L170.403 166.43L168.474 168.985L153.142 173.035L155.167 170.383Z" fill="#16FFE1" />
<path d="M93.2577 186.343L105.601 181.377L102.949 178.194L90.3647 182.871L93.2577 186.343Z" fill="#00C0FF" />
<path d="M52.1775 192.081L52.8043 191.888L49.6703 183.306L48.8506 182.92L52.1775 192.081Z" fill="#00A8FF" />
<path d="M162.255 122.023L175.755 118.696L174.067 121.879L160.615 125.109L162.255 122.023Z" fill="#D1FF26" />
<path d="M52.3223 52.0615L53.1902 59.2939L59.5547 54.183L58.6868 46.9023L52.3223 52.0615Z" fill="#D10000" />
<path d="M99.6703 190.973L111.483 185.621L108.446 183.885L96.3916 189.092L99.6703 190.973Z" fill="#00A8FF" />
<path d="M98.5132 170.095L113.316 166.237L111.773 161.85L96.8257 165.418L98.5132 170.095Z" fill="#23FFD4" />
<path d="M82.1676 222.072L81.2997 218.6L76.1406 216.961L76.6228 220.24L82.1676 222.072Z" fill="#0010FF" />
<path d="M90.3644 182.872L102.949 178.195L100.538 174.386L87.7607 178.773L90.3644 182.872Z" fill="#00D4FF" />
<path d="M110.664 157.22L126.768 153.99L126.141 149.506L109.94 152.447L110.664 157.22Z" fill="#56FFA0" />
<path d="M102.901 221.107L100.586 220.48L95.186 223.325L97.2111 224.097L102.901 221.107Z" fill="#0008FF" />
<path d="M125.9 144.974L142.342 141.984L142.583 137.741L126.141 140.538L125.9 144.974Z" fill="#83FF73" />
<path d="M143.209 133.692L158.928 130.51L159.989 126.797L144.27 129.883L143.209 133.692Z" fill="#B1FF46" />
<path d="M203.431 129.014L216.112 124L214.665 119.998L202.081 125.061L203.431 129.014Z" fill="#C4FF33" />
<path d="M102.997 192.033L114.521 186.584L111.483 185.62L99.6704 190.972L102.997 192.033Z" fill="#00A8FF" />
<path d="M182.602 112.525L194.222 107.944L191.57 109.535L180.095 113.923L182.602 112.525Z" fill="#FEED00" />
<path d="M124.453 183.788L123.827 188.513L120.548 197.964L121.078 193.528L124.453 183.788Z" fill="#00A8FF" />
<path d="M120.499 186.006L133.71 180.943L130.962 181.618L117.558 186.729L120.499 186.006Z" fill="#00C0FF" />
<path d="M71.03 213.971L72.0425 209.921L67.5584 206.016L66.1602 209.728L71.03 213.971Z" fill="#0040FF" />
<path d="M56.6133 199.747L57.5294 197.915L54.0096 190.586L52.8042 191.888L56.6133 199.747Z" fill="#0088FF" />
<path d="M42.6307 156.497L43.7397 160.355L42.9682 149.603L41.811 145.215L42.6307 156.497Z" fill="#5AFF9D" />
<path d="M66.1602 209.728L67.5585 206.015L63.3637 201.001L61.6279 204.328L66.1602 209.728Z" fill="#0058FF" />
<path d="M40.7983 92.2257L42.6788 99.651L46.295 90.056L44.4628 82.3896L40.7983 92.2257Z" fill="#FF9400" />
<path d="M87.7608 178.774L100.586 174.386L98.513 170.095L85.4946 174.097L87.7608 178.774Z" fill="#06ECF1" />
<path d="M86.6519 194.492L96.3915 189.092L93.2574 186.344L83.2285 191.503L86.6519 194.492Z" fill="#00A0FF" />
<path d="M138.677 177.472L153.142 173.036L150.924 175.013L136.314 179.594L138.677 177.472Z" fill="#02E8F4" />
<path d="M83.2286 191.503L93.2576 186.344L90.3646 182.872L80.0464 187.694L83.2286 191.503Z" fill="#00B0FF" />
<path d="M127.153 135.523L127.876 142.467L127.828 154.954L127.105 148.204L127.153 135.523Z" fill="#83FF73" />
<path d="M76.1407 216.96L76.7193 212.669L72.0423 209.921L71.0298 213.971L76.1407 216.96Z" fill="#0034FF" />
<path d="M190.075 146.228L205.071 141.888L204.974 137.549L189.979 141.888L190.075 146.228Z" fill="#83FF73" />
<path d="M95.1859 223.325L93.0644 221.155L87.7124 222.361L89.4482 224.579L95.1859 223.325Z" fill="#0008FF" />
<path d="M96.8255 165.417L111.772 161.849L110.664 157.221L95.6201 160.403L96.8255 165.417Z" fill="#39FFBE" />
<path d="M113.315 72.8428L114.617 78.8216L119.246 90.3452L117.751 84.3664L113.315 72.8428Z" fill="#FF6000" />
<path d="M90.2198 196.565L99.6702 190.972L96.3915 189.092L86.6519 194.492L90.2198 196.565Z" fill="#0094FF" />
<path d="M164.135 119.419L177.78 116.044L175.755 118.696L162.254 122.023L164.135 119.419Z" fill="#E1FF16" />
<path d="M156.806 167.25L172.043 163.393L170.403 166.43L155.167 170.432L156.806 167.25Z" fill="#29FFCE" />
<path d="M174.212 155.967L189.448 152.447L188.532 156.305L173.344 159.873L174.212 155.967Z" fill="#53FFA4" />
<path d="M61.6277 204.328L63.3634 201.001L59.6026 195.07L57.5293 197.915L61.6277 204.328Z" fill="#0074FF" />
<path d="M106.276 192.177L117.558 186.729L114.521 186.584L102.997 192.032L106.276 192.177Z" fill="#00A8FF" />
<path d="M202.081 125.061L214.665 119.998L212.833 116.382L200.345 121.493L202.081 125.061Z" fill="#D7FF1F" />
<path d="M80.0464 187.693L90.3646 182.872L87.761 178.773L77.2017 183.161L80.0464 187.693Z" fill="#00C4FF" />
<path d="M185.253 111.753L197.018 107.028L194.222 107.944L182.602 112.525L185.253 111.753Z" fill="#FFE600" />
<path d="M45.4755 171.01L46.2952 173.18L44.6559 163.103L43.7397 160.354L45.4755 171.01Z" fill="#26FFD1" />
<path d="M87.7125 222.361L86.4589 218.89L81.2998 218.648L82.1677 222.072L87.7125 222.361Z" fill="#0014FF" />
<path d="M109.94 152.447L126.141 149.506L125.9 144.974L109.699 147.626L109.94 152.447Z" fill="#6DFF8A" />
<path d="M73.3923 36.1982L73.103 42.7074L80.7212 43.8164V37.4036L73.3923 36.1982Z" fill="#800000" />
<path d="M72.0422 209.921L74.6941 205.147L70.5957 201.531L67.5581 206.015L72.0422 209.921Z" fill="#005CFF" />
<path d="M79.4679 199.94L86.6521 194.491L83.2287 191.502L75.707 196.661L79.4679 199.94Z" fill="#008CFF" />
<path d="M67.5586 206.015L70.5962 201.531L66.7389 196.999L63.3638 201.001L67.5586 206.015Z" fill="#0070FF" />
<path d="M85.4948 174.097L98.5131 170.095L96.8256 165.418L83.6626 168.938L85.4948 174.097Z" fill="#1CFFDB" />
<path d="M75.7068 196.662L83.2285 191.502L80.0462 187.693L72.187 192.467L75.7068 196.662Z" fill="#009CFF" />
<path d="M93.8841 197.722L102.997 192.032L99.6701 190.972L90.2197 196.565L93.8841 197.722Z" fill="#0094FF" />
<path d="M81.2997 218.648L81.4926 214.212L76.7192 212.669L76.1406 216.96L81.2997 218.648Z" fill="#002CFF" />
<path d="M122.814 102.688L123.585 109.873L125.948 122.602L125.225 115.466L122.814 102.688Z" fill="#FFE600" />
<path d="M123.296 184.511L136.314 179.593L133.711 180.943L120.5 186.005L123.296 184.511Z" fill="#00CCFF" />
<path d="M120.548 197.963L119.005 201.145L114.955 208.956L116.305 206.111L120.548 197.963Z" fill="#0070FF" />
<path d="M126.141 140.538L142.582 137.742L143.209 133.691L126.816 136.295L126.141 140.538Z" fill="#9AFF5D" />
<path d="M144.27 129.882L159.988 126.797L161.387 123.47L145.716 126.556L144.27 129.882Z" fill="#C4FF33" />
<path d="M74.6946 205.148L79.4679 199.892L75.7071 196.613L70.5962 201.531L74.6946 205.148Z" fill="#0074FF" />
<path d="M83.3733 202.207L90.2199 196.565L86.652 194.492L79.4678 199.892L83.3733 202.207Z" fill="#0080FF" />
<path d="M76.7194 212.669L78.9856 207.655L74.6944 205.147L72.0425 209.921L76.7194 212.669Z" fill="#004CFF" />
<path d="M48.8504 182.92L49.67 183.306L47.3075 174.193L46.2949 173.181L48.8504 182.92Z" fill="#00E0FB" />
<path d="M200.345 121.492L212.833 116.381L210.712 113.199L198.272 118.31L200.345 121.492Z" fill="#E7FF0F" />
<path d="M70.5961 201.531L75.7069 196.613L72.2354 192.467L66.7388 196.999L70.5961 201.531Z" fill="#0084FF" />
<path d="M188.002 111.705L199.911 106.835L197.018 107.028L185.253 111.753L188.002 111.705Z" fill="#FFE200" />
<path d="M77.2017 183.161L87.761 178.774L85.4948 174.097L74.7427 178.05L77.2017 183.161Z" fill="#00E0FB" />
<path d="M72.1873 192.467L80.0465 187.693L77.2017 183.161L69.0532 187.501L72.1873 192.467Z" fill="#00B4FF" />
<path d="M63.3634 201.001L66.7385 196.999L63.267 191.551L59.6025 195.071L63.3634 201.001Z" fill="#008CFF" />
<path d="M52.8045 191.888L54.0099 190.586L51.0687 182.631L49.6704 183.306L52.8045 191.888Z" fill="#00B4FF" />
<path d="M78.9856 207.654L83.3732 202.206L79.4677 199.892L74.6943 205.147L78.9856 207.654Z" fill="#0068FF" />
<path d="M189.979 141.888L204.974 137.548L204.443 133.209L189.448 137.548L189.979 141.888Z" fill="#9AFF5D" />
<path d="M57.5295 197.915L59.6028 195.07L56.3241 188.368L54.0098 190.586L57.5295 197.915Z" fill="#009CFF" />
<path d="M140.75 174.675L155.167 170.432L153.142 173.035L138.677 177.471L140.75 174.675Z" fill="#13FCE4" />
<path d="M109.506 191.454L120.5 186.006L117.558 186.729L106.276 192.178L109.506 191.454Z" fill="#00B0FF" />
<path d="M40.1719 110.355L41.9559 117.154L44.4149 106.836L42.6791 99.6514L40.1719 110.355Z" fill="#FFDB00" />
<path d="M95.6201 160.403L110.663 157.221L109.94 152.447L94.8486 155.244L95.6201 160.403Z" fill="#53FFA4" />
<path d="M166.304 117.49L180.094 113.971L177.78 116.044L164.135 119.467L166.304 117.49Z" fill="#EEFF09" />
<path d="M198.272 118.311L210.712 113.2L208.253 110.645L195.958 115.707L198.272 118.311Z" fill="#F4F802" />
<path d="M66.7386 196.999L72.187 192.466L69.053 187.5L63.2671 191.55L66.7386 196.999Z" fill="#00A0FF" />
<path d="M190.75 112.38L202.804 107.414L199.911 106.835L188.002 111.705L190.75 112.38Z" fill="#FFE200" />
<path d="M87.375 203.509L93.8841 197.723L90.2197 196.565L83.373 202.207L87.375 203.509Z" fill="#007CFF" />
<path d="M97.5005 197.916L106.276 192.178L102.997 192.033L93.8843 197.723L97.5005 197.916Z" fill="#0094FF" />
<path d="M114.955 208.957L112.737 210.404L108.108 216.479L110.133 215.37L114.955 208.957Z" fill="#0044FF" />
<path d="M47.5967 66.2853L49.1396 73.9516L54.6844 67.1049L53.1897 59.2939L47.5967 66.2853Z" fill="#FF1E00" />
<path d="M174.742 151.772L189.979 148.349L189.448 152.447L174.212 155.967L174.742 151.772Z" fill="#66FF90" />
<path d="M81.4926 214.212L83.373 209.053L78.9854 207.654L76.7192 212.669L81.4926 214.212Z" fill="#0048FF" />
<path d="M195.958 115.707L208.253 110.644L205.601 108.667L193.402 113.73L195.958 115.707Z" fill="#FEED00" />
<path d="M158.156 163.586L173.344 159.873L172.042 163.393L156.806 167.25L158.156 163.586Z" fill="#3CFFBA" />
<path d="M193.402 113.73L205.601 108.668L202.804 107.414L190.75 112.38L193.402 113.73Z" fill="#FFE200" />
<path d="M83.6625 168.938L96.8255 165.418L95.6201 160.403L82.3125 163.537L83.6625 168.938Z" fill="#36FFC1" />
<path d="M83.373 209.053L87.3749 203.508L83.373 202.206L78.9854 207.654L83.373 209.053Z" fill="#0064FF" />
<path d="M69.0531 187.5L77.2016 183.161L74.7426 178.05L66.353 181.859L69.0531 187.5Z" fill="#00D0FF" />
<path d="M107.963 62.4277L109.072 68.4547L114.617 78.8212L113.315 72.8424L107.963 62.4277Z" fill="#FF2900" />
<path d="M109.699 147.626L125.9 144.974L126.141 140.538L109.94 142.949L109.699 147.626Z" fill="#87FF70" />
<path d="M86.4589 218.889L86.2178 214.453L81.4927 214.212L81.2998 218.6L86.4589 218.889Z" fill="#0030FF" />
<path d="M74.7424 178.05L85.4945 174.097L83.6623 168.938L72.7173 172.409L74.7424 178.05Z" fill="#13FCE4" />
<path d="M127.828 154.955L127.973 161.223L126.768 172.891L126.575 166.961L127.828 154.955Z" fill="#43FFB4" />
<path d="M59.6029 195.07L63.2673 191.551L60.2779 185.379L56.3242 188.368L59.6029 195.07Z" fill="#00ACFF" />
<path d="M108.108 216.478L105.504 216.141L100.586 220.48L102.901 221.107L108.108 216.478Z" fill="#002CFF" />
<path d="M58.6865 46.9026L59.5544 54.1832L66.5457 51.0492L65.6778 43.7686L58.6865 46.9026Z" fill="#A80000" />
<path d="M125.851 182.245L138.677 177.472L136.314 179.593L123.296 184.511L125.851 182.245Z" fill="#00DCFE" />
<path d="M80.7212 37.4043V43.817L88.2911 47.0475L88.05 40.7794L80.7212 37.4043Z" fill="#800000" />
<path d="M93.0646 221.204L91.4252 217.78L86.459 218.889L87.7126 222.361L93.0646 221.204Z" fill="#0020FF" />
<path d="M145.716 126.555L161.387 123.469L163.171 120.673L147.452 123.662L145.716 126.555Z" fill="#D7FF1F" />
<path d="M63.2672 191.551L69.0531 187.501L66.353 181.859L60.2778 185.379L63.2672 191.551Z" fill="#00C0FF" />
<path d="M100.586 220.48L98.1271 218.503L93.0645 221.155L95.186 223.325L100.586 220.48Z" fill="#0020FF" />
<path d="M126.816 136.296L143.209 133.644L144.27 129.883L127.877 132.39L126.816 136.296Z" fill="#B1FF46" />
<path d="M189.448 137.549L204.395 133.209L203.431 129.015L188.484 133.354L189.448 137.549Z" fill="#B1FF46" />
<path d="M40.6055 128.291L42.0519 134.077L43.402 123.373L41.9555 117.153L40.6055 128.291Z" fill="#CEFF29" />
<path d="M91.3287 203.701L97.5004 197.916L93.8842 197.723L87.375 203.509L91.3287 203.701Z" fill="#007CFF" />
<path d="M168.667 116.141L182.602 112.524L180.094 113.923L166.305 117.491L168.667 116.141Z" fill="#FBF100" />
<path d="M112.496 189.815L123.296 184.511L120.5 186.005L109.506 191.454L112.496 189.815Z" fill="#00BCFF" />
<path d="M54.0095 190.586L56.3239 188.368L53.6238 180.991L51.0684 182.631L54.0095 190.586Z" fill="#00C4FF" />
<path d="M101.02 197.095L109.506 191.454L106.276 192.177L97.5005 197.915L101.02 197.095Z" fill="#009CFF" />
<path d="M94.8484 155.244L109.94 152.448L109.699 147.626L94.5591 150.085L94.8484 155.244Z" fill="#6DFF8A" />
<path d="M86.2178 214.453L87.7125 209.294L83.3731 209.053L81.4927 214.212L86.2178 214.453Z" fill="#004CFF" />
<path d="M142.534 171.3L156.806 167.25L155.167 170.432L140.75 174.675L142.534 171.3Z" fill="#23FFD4" />
<path d="M87.7125 209.294L91.3287 203.701L87.375 203.508L83.373 209.053L87.7125 209.294Z" fill="#0064FF" />
<path d="M66.3531 181.86L74.7427 178.05L72.7176 172.409L64.0869 175.688L66.3531 181.86Z" fill="#09F0EE" />
<path d="M101.84 53.46L102.708 59.5834L109.072 68.4551L107.963 62.4281L101.84 53.46Z" fill="#DF0000" />
<path d="M174.791 147.433L190.075 144.058L189.979 148.349L174.743 151.772L174.791 147.433Z" fill="#7DFF7A" />
<path d="M49.6702 183.306L51.0685 182.631L48.8987 174.145L47.3076 174.193L49.6702 183.306Z" fill="#02E8F4" />
<path d="M41.811 145.215L42.9682 149.554L43.2093 138.995L42.1003 134.077L41.811 145.215Z" fill="#94FF63" />
<path d="M88.0503 40.7793L88.2914 47.0474L95.7166 52.3511L95.138 46.1795L88.0503 40.7793Z" fill="#920000" />
<path d="M159.072 159.487L174.212 155.967L173.344 159.872L158.156 163.585L159.072 159.487Z" fill="#50FFA7" />
<path d="M82.3122 163.537L95.6198 160.403L94.8484 155.244L81.4443 157.896L82.3122 163.537Z" fill="#50FFA7" />
<path d="M72.7172 172.409L83.6623 168.937L82.3122 163.537L71.1743 166.478L72.7172 172.409Z" fill="#30FFC7" />
<path d="M188.484 133.354L203.431 129.014L202.081 125.061L187.182 129.4L188.484 133.354Z" fill="#C4FF33" />
<path d="M46.2951 173.181L47.3076 174.193L45.7647 164.743L44.6558 163.104L46.2951 173.181Z" fill="#2CFFCA" />
<path d="M56.3241 188.369L60.2778 185.379L57.8188 178.629L53.624 180.991L56.3241 188.369Z" fill="#00D4FF" />
<path d="M43.7397 160.355L44.6558 163.103L43.9326 152.929L42.9683 149.603L43.7397 160.355Z" fill="#5AFF9D" />
<path d="M95.1377 46.1797L95.7163 52.3513L102.708 59.5837L101.84 53.4603L95.1377 46.1797Z" fill="#B20000" />
<path d="M60.2779 185.379L66.3531 181.859L64.0869 175.688L57.8188 178.629L60.2779 185.379Z" fill="#00E4F8" />
<path d="M171.175 115.514L185.254 111.753L182.602 112.524L168.667 116.141L171.175 115.514Z" fill="#FFEA00" />
<path d="M109.94 142.949L126.141 140.538L126.816 136.295L110.615 138.465L109.94 142.949Z" fill="#9DFF5A" />
<path d="M147.452 123.662L163.171 120.673L165.196 118.358L149.525 121.348L147.452 123.662Z" fill="#E7FF0F" />
<path d="M128.07 179.255L140.75 174.675L138.677 177.471L125.852 182.245L128.07 179.255Z" fill="#09F0EE" />
<path d="M126.768 172.891L126.093 177.953L123.827 188.513L124.453 183.787L126.768 172.891Z" fill="#06ECF1" />
<path d="M91.4251 217.78L90.7983 213.44L86.2178 214.453L86.4589 218.889L91.4251 217.78Z" fill="#003CFF" />
<path d="M95.1859 202.833L101.02 197.096L97.5003 197.915L91.3286 203.701L95.1859 202.833Z" fill="#0088FF" />
<path d="M125.948 122.602L126.671 129.738L127.876 142.467L127.153 135.523L125.948 122.602Z" fill="#C4FF33" />
<path d="M119.246 90.3457L120.018 97.5781L123.586 109.873L122.814 102.689L119.246 90.3457Z" fill="#FF9F00" />
<path d="M127.876 132.39L144.27 129.883L145.716 126.556L129.323 128.87L127.876 132.39Z" fill="#C7FF30" />
<path d="M44.4629 82.3899L46.2951 90.0563L50.8756 81.8114L49.1398 73.9521L44.4629 82.3899Z" fill="#FF5900" />
<path d="M115.244 187.356L125.851 182.245L123.296 184.511L112.496 189.815L115.244 187.356Z" fill="#00D0FF" />
<path d="M187.182 129.401L202.081 125.061L200.345 121.493L185.543 125.784L187.182 129.401Z" fill="#D7FF1F" />
<path d="M104.299 195.359L112.495 189.814L109.506 191.454L101.02 197.095L104.299 195.359Z" fill="#00ACFF" />
<path d="M173.778 115.562L188.002 111.705L185.254 111.753L171.223 115.514L173.778 115.562Z" fill="#FFE600" />
<path d="M91.9072 208.33L95.1859 202.833L91.3286 203.701L87.7124 209.294L91.9072 208.33Z" fill="#0070FF" />
<path d="M90.7983 213.441L91.9073 208.33L87.7125 209.294L86.2178 214.454L90.7983 213.441Z" fill="#0054FF" />
<path d="M94.5591 150.085L109.699 147.626L109.94 142.949L94.7519 144.974L94.5591 150.085Z" fill="#87FF70" />
<path d="M174.453 143.046L189.738 139.719L190.075 144.058L174.791 147.433L174.453 143.046Z" fill="#94FF63" />
<path d="M64.0866 175.688L72.7173 172.409L71.1744 166.479L62.4473 169.179L64.0866 175.688Z" fill="#26FFD1" />
<path d="M143.884 167.442L158.156 163.585L156.806 167.249L142.534 171.3L143.884 167.442Z" fill="#39FFBE" />
<path d="M98.1273 218.504L96.1022 215.321L91.4253 217.78L93.0646 221.155L98.1273 218.504Z" fill="#0038FF" />
<path d="M51.0686 182.631L53.6241 180.992L51.599 173.181L48.8989 174.145L51.0686 182.631Z" fill="#0CF4EB" />
<path d="M159.554 155.148L174.742 151.772L174.26 155.967L159.072 159.535L159.554 155.148Z" fill="#66FF90" />
<path d="M123.826 188.513L122.187 192.032L119.005 201.145L120.548 197.963L123.826 188.513Z" fill="#00B0FF" />
<path d="M185.543 125.784L200.345 121.493L198.272 118.311L183.566 122.554L185.543 125.784Z" fill="#E4FF13" />
<path d="M81.4444 157.896L94.8485 155.244L94.5592 150.085L81.1069 152.255L81.4444 157.896Z" fill="#6AFF8D" />
<path d="M176.382 116.334L190.75 112.38L188.002 111.705L173.778 115.562L176.382 116.334Z" fill="#FFE600" />
<path d="M71.1748 166.478L82.3127 163.537L81.4448 157.896L70.2104 160.306L71.1748 166.478Z" fill="#49FFAD" />
<path d="M149.525 121.348L165.196 118.359L167.462 116.72L151.792 119.661L149.525 121.348Z" fill="#F4F802" />
<path d="M65.6777 43.7688L66.5456 51.0494L73.9227 50.0368L73.103 42.708L65.6777 43.7688Z" fill="#8D0000" />
<path d="M105.504 216.141L102.708 214.453L98.1274 218.503L100.586 220.528L105.504 216.141Z" fill="#0040FF" />
<path d="M57.8185 178.629L64.0866 175.688L62.4473 169.179L55.9863 171.445L57.8185 178.629Z" fill="#1FFFD7" />
<path d="M53.6237 180.991L57.8185 178.629L55.9863 171.444L51.5986 173.18L53.6237 180.991Z" fill="#16FFE1" />
<path d="M183.566 122.554L198.272 118.311L195.958 115.707L181.348 119.902L183.566 122.554Z" fill="#F1FC06" />
<path d="M110.615 138.465L126.816 136.295L127.877 132.39L111.724 134.27L110.615 138.465Z" fill="#B4FF43" />
<path d="M178.937 117.78L193.402 113.73L190.75 112.38L176.382 116.334L178.937 117.78Z" fill="#FFE600" />
<path d="M119.005 201.146L116.594 202.93L112.737 210.403L114.955 208.957L119.005 201.146Z" fill="#007CFF" />
<path d="M181.348 119.902L195.958 115.707L193.402 113.73L178.938 117.781L181.348 119.902Z" fill="#FEED00" />
<path d="M129.95 175.639L142.534 171.3L140.75 174.675L128.069 179.255L129.95 175.639Z" fill="#1CFFDB" />
<path d="M112.737 210.403H109.844L105.504 216.141L108.108 216.479L112.737 210.403Z" fill="#0054FF" />
<path d="M53.1899 59.2945L54.6846 67.1055L61.0009 62.0428L59.5545 54.1836L53.1899 59.2945Z" fill="#D60000" />
<path d="M98.8022 200.856L104.299 195.312L101.02 197.096L95.186 202.833L98.8022 200.856Z" fill="#0098FF" />
<path d="M129.323 128.869L145.717 126.555L147.452 123.662L131.155 125.88L129.323 128.869Z" fill="#D7FF1F" />
<path d="M42.6787 99.6506L44.4145 106.835L47.886 97.5291L46.2949 90.0557L42.6787 99.6506Z" fill="#FF9C00" />
<path d="M47.3076 174.193L48.8987 174.144L47.4522 165.369L45.7646 164.742L47.3076 174.193Z" fill="#30FFC7" />
<path d="M173.682 138.706L189.014 135.476L189.738 139.719L174.453 143.045L173.682 138.706Z" fill="#AAFF4D" />
<path d="M96.1021 215.321L95.1378 211.126L90.7983 213.44L91.4251 217.78L96.1021 215.321Z" fill="#0050FF" />
<path d="M117.703 184.126L128.07 179.256L125.852 182.245L115.244 187.356L117.703 184.126Z" fill="#00E4F8" />
<path d="M107.336 192.659L115.244 187.355L112.496 189.814L104.299 195.311L107.336 192.659Z" fill="#00C0FF" />
<path d="M151.84 119.66L167.462 116.719L169.921 115.755L154.299 118.696L151.84 119.66Z" fill="#FEED00" />
<path d="M94.752 144.974L109.94 142.949L110.615 138.465L95.4752 140.152L94.752 144.974Z" fill="#A0FF56" />
<path d="M144.849 163.103L159.072 159.535L158.156 163.585L143.884 167.443L144.849 163.103Z" fill="#50FFA7" />
<path d="M95.8609 206.257L98.8021 200.856L95.1859 202.833L91.9072 208.33L95.8609 206.257Z" fill="#0080FF" />
<path d="M62.4473 169.178L71.1744 166.478L70.2101 160.307L61.3384 162.428L62.4473 169.178Z" fill="#46FFB1" />
<path d="M127.876 142.467L128.021 148.976L127.973 161.223L127.828 154.955L127.876 142.467Z" fill="#83FF73" />
<path d="M159.603 150.663L174.791 147.433L174.743 151.772L159.555 155.147L159.603 150.663Z" fill="#7DFF7A" />
<path d="M95.1378 211.126L95.861 206.208L91.9073 208.33L90.7983 213.44L95.1378 211.126Z" fill="#0068FF" />
<path d="M44.6559 163.103L45.7648 164.743L45.0898 155.244L43.9326 152.93L44.6559 163.103Z" fill="#5DFF9A" />
<path d="M114.617 78.8213L115.389 86.1019L120.017 97.5773L119.246 90.3449L114.617 78.8213Z" fill="#FF6000" />
<path d="M41.9556 117.153L43.402 123.373L45.7646 113.441L44.4146 106.835L41.9556 117.153Z" fill="#FFE200" />
<path d="M81.1069 152.254L94.5592 150.085L94.7521 144.974L81.2998 146.709L81.1069 152.254Z" fill="#87FF70" />
<path d="M70.2104 160.307L81.4448 157.896L81.1073 152.255L69.8247 154.135L70.2104 160.307Z" fill="#6AFF8D" />
<path d="M172.524 134.559L187.905 131.377L189.014 135.475L173.682 138.706L172.524 134.559Z" fill="#BEFF39" />
<path d="M48.8986 174.145L51.5987 173.18L50.2969 165.08L47.4521 165.369L48.8986 174.145Z" fill="#36FFC1" />
<path d="M111.724 134.27L127.876 132.39L129.323 128.87L113.219 130.558L111.724 134.27Z" fill="#CAFF2C" />
<path d="M131.155 125.88L147.452 123.662L149.526 121.348L133.277 123.469L131.155 125.88Z" fill="#E7FF0F" />
<path d="M42.9683 149.554L43.9326 152.93L44.1737 142.949L43.2093 138.995L42.9683 149.554Z" fill="#90FF66" />
<path d="M42.1001 134.077L43.2091 138.995L44.4627 128.821L43.4019 123.373L42.1001 134.077Z" fill="#CAFF2C" />
<path d="M55.9867 171.444L62.4476 169.178L61.3386 162.428L54.7812 164.067L55.9867 171.444Z" fill="#40FFB7" />
<path d="M154.299 118.696L169.921 115.755L172.476 115.466L156.902 118.407L154.299 118.696Z" fill="#FFE600" />
<path d="M102.708 214.453L100.345 211.512L96.1021 215.321L98.1271 218.503L102.708 214.453Z" fill="#0054FF" />
<path d="M131.396 171.444L143.884 167.442L142.534 171.3L129.95 175.639L131.396 171.444Z" fill="#33FFC4" />
<path d="M51.5987 173.18L55.9864 171.444L54.781 164.067L50.2969 165.128L51.5987 173.18Z" fill="#3CFFBA" />
<path d="M102.081 197.915L107.288 192.659L104.299 195.311L98.8022 200.856L102.081 197.915Z" fill="#00ACFF" />
<path d="M73.103 42.708L73.9227 50.0368L81.5408 51.1458L80.6729 43.817L73.103 42.708Z" fill="#800000" />
<path d="M123.585 109.873L124.309 117.105L126.671 129.738L125.948 122.602L123.585 109.873Z" fill="#FFEA00" />
<path d="M171.03 130.654L186.411 127.52L187.905 131.377L172.524 134.559L171.03 130.654Z" fill="#D1FF26" />
<path d="M159.217 146.083L174.453 143.045L174.791 147.433L159.603 150.663L159.217 146.083Z" fill="#94FF63" />
<path d="M119.728 180.172L129.95 175.64L128.07 179.256L117.703 184.126L119.728 180.172Z" fill="#16FFE1" />
<path d="M109.94 189.091L117.703 184.125L115.244 187.355L107.289 192.659L109.94 189.091Z" fill="#00D8FF" />
<path d="M145.331 158.523L159.555 155.147L159.072 159.535L144.849 163.151L145.331 158.523Z" fill="#66FF90" />
<path d="M156.903 118.407L172.477 115.466L175.08 115.852L159.506 118.793L156.903 118.407Z" fill="#FFE200" />
<path d="M95.4756 140.152L110.615 138.464L111.724 134.27L96.6328 135.668L95.4756 140.152Z" fill="#B7FF40" />
<path d="M127.973 161.223L127.25 166.623L126.093 177.954L126.768 172.891L127.973 161.223Z" fill="#43FFB4" />
<path d="M49.1396 73.9521L50.8754 81.8114L56.3238 75.1093L54.6845 67.1055L49.1396 73.9521Z" fill="#FF2500" />
<path d="M61.3382 162.428L70.21 160.306L69.8243 154.135L60.9043 155.629L61.3382 162.428Z" fill="#66FF90" />
<path d="M99.4775 203.026L102.081 197.915L98.8025 200.856L95.8613 206.208L99.4775 203.026Z" fill="#0098FF" />
<path d="M109.844 210.403L106.758 209.053L102.708 214.453L105.505 216.14L109.844 210.403Z" fill="#0068FF" />
<path d="M169.149 127.183L184.579 124.097L186.411 127.52L171.03 130.654L169.149 127.183Z" fill="#E1FF16" />
<path d="M133.277 123.469L149.526 121.348L151.84 119.66L135.639 121.733L133.277 123.469Z" fill="#F8F500" />
<path d="M100.345 211.512L99.0914 207.606L95.1377 211.126L96.102 215.321L100.345 211.512Z" fill="#006CFF" />
<path d="M109.072 68.4551L109.844 75.7839L115.389 86.1021L114.617 78.8215L109.072 68.4551Z" fill="#FF2900" />
<path d="M45.7649 164.743L47.4524 165.37L46.8256 156.546L45.0898 155.244L45.7649 164.743Z" fill="#60FF97" />
<path d="M159.506 118.841L175.08 115.852L177.684 117.009L162.11 119.95L159.506 118.841Z" fill="#FFE600" />
<path d="M113.219 130.558L129.323 128.87L131.155 125.881L115.147 127.376L113.219 130.558Z" fill="#DEFF19" />
<path d="M81.2998 146.71L94.7521 144.974L95.4753 140.152L82.0713 141.406L81.2998 146.71Z" fill="#A4FF53" />
<path d="M99.0914 207.655L99.4771 203.026L95.8609 206.257L95.1377 211.127L99.0914 207.655Z" fill="#0080FF" />
<path d="M69.8247 154.135L81.1072 152.255L81.3001 146.71L70.0176 148.06L69.8247 154.135Z" fill="#87FF70" />
<path d="M167.028 124.193L182.457 121.155L184.578 124.096L169.149 127.182L167.028 124.193Z" fill="#EEFF09" />
<path d="M162.11 119.95L177.684 116.961L180.143 118.745L164.665 121.734L162.11 119.95Z" fill="#FFEA00" />
<path d="M59.5547 54.1829L61.0012 62.0421L67.9443 59.0045L66.546 51.0488L59.5547 54.1829Z" fill="#AD0000" />
<path d="M164.666 121.734L180.143 118.744L182.457 121.155L167.028 124.193L164.666 121.734Z" fill="#FBF100" />
<path d="M126.093 177.954L124.357 181.908L122.187 192.033L123.826 188.513L126.093 177.954Z" fill="#0CF4EB" />
<path d="M158.397 141.551L173.682 138.706L174.453 143.045L159.217 146.083L158.397 141.551Z" fill="#AAFF4D" />
<path d="M132.409 166.816L144.849 163.104L143.884 167.443L131.396 171.445L132.409 166.816Z" fill="#4DFFAA" />
<path d="M116.594 202.93L113.46 203.315L109.844 210.403H112.737L116.594 202.93Z" fill="#0088FF" />
<path d="M80.7212 43.8164L81.5409 51.1452L89.159 54.4239L88.2911 47.0469L80.7212 43.8164Z" fill="#800000" />
<path d="M54.781 164.068L61.3384 162.428L60.9044 155.63L54.2988 156.594L54.781 164.068Z" fill="#66FF90" />
<path d="M122.187 192.033L119.584 194.251L116.594 202.93L119.005 201.146L122.187 192.033Z" fill="#00B8FF" />
<path d="M104.974 194.01L109.94 189.092L107.288 192.66L102.081 197.915L104.974 194.01Z" fill="#00C8FF" />
<path d="M145.379 153.701L159.603 150.663L159.554 155.147L145.331 158.522L145.379 153.701Z" fill="#7DFF7A" />
<path d="M47.4525 165.369L50.2972 165.08L49.7186 156.98L46.8257 156.546L47.4525 165.369Z" fill="#60FF97" />
<path d="M135.639 121.734L151.792 119.661L154.299 118.696L138.195 120.721L135.639 121.734Z" fill="#FFEA00" />
<path d="M102.708 59.583L103.479 66.8636L109.844 75.7836L109.073 68.4548L102.708 59.583Z" fill="#DF0000" />
<path d="M50.2969 165.08L54.7809 164.067L54.2988 156.594L49.7183 156.979L50.2969 165.08Z" fill="#63FF94" />
<path d="M43.9326 152.93L45.0898 155.244L45.2827 145.89L44.1737 142.949L43.9326 152.93Z" fill="#90FF66" />
<path d="M121.271 175.591L131.396 171.444L129.95 175.639L119.728 180.171L121.271 175.591Z" fill="#2CFFCA" />
<path d="M96.6328 135.669L111.724 134.27L113.219 130.558L98.2722 131.667L96.6328 135.669Z" fill="#D1FF26" />
<path d="M46.2949 90.0555L47.886 97.529L52.3701 89.5251L50.8754 81.8105L46.2949 90.0555Z" fill="#FF6000" />
<path d="M112.158 184.752L119.728 180.172L117.703 184.126L109.94 189.092L112.158 184.752Z" fill="#0CF4EB" />
<path d="M88.291 47.0469L89.1589 54.4239L96.536 59.6795L95.7163 52.3506L88.291 47.0469Z" fill="#920000" />
<path d="M115.147 127.375L131.155 125.881L133.277 123.47L117.365 124.82L115.147 127.375Z" fill="#EEFF09" />
<path d="M157.192 137.211L172.525 134.56L173.682 138.706L158.397 141.551L157.192 137.211Z" fill="#C1FF36" />
<path d="M106.758 209.053L104.106 206.498L100.345 211.513L102.708 214.454L106.758 209.053Z" fill="#007CFF" />
<path d="M95.7163 52.3506L96.536 59.6794L103.479 66.8636L102.708 59.583L95.7163 52.3506Z" fill="#B20000" />
<path d="M126.671 129.737L126.864 136.536L128.021 148.975L127.877 142.466L126.671 129.737Z" fill="#C1FF36" />
<path d="M60.9043 155.629L69.8243 154.135L70.0171 148.06L61.0972 148.927L60.9043 155.629Z" fill="#8AFF6D" />
<path d="M138.195 120.722L154.299 118.697L156.903 118.407L140.895 120.432L138.195 120.722Z" fill="#FFE200" />
<path d="M82.0713 141.406L95.4753 140.152L96.6325 135.668L83.3249 136.536L82.0713 141.406Z" fill="#BEFF39" />
<path d="M102.66 198.735L104.974 194.01L102.081 197.915L99.4775 203.026L102.66 198.735Z" fill="#00B4FF" />
<path d="M120.018 97.5771L120.789 105.002L124.309 117.105L123.586 109.824L120.018 97.5771Z" fill="#FFA300" />
<path d="M43.2095 138.995L44.1738 142.949L45.3792 133.354L44.4631 128.821L43.2095 138.995Z" fill="#C7FF30" />
<path d="M70.0176 148.06L81.3001 146.71L82.0716 141.406L70.8373 142.226L70.0176 148.06Z" fill="#A7FF50" />
<path d="M44.4146 106.835L45.7646 113.441L49.1397 104.521L47.8861 97.5293L44.4146 106.835Z" fill="#FFA300" />
<path d="M132.939 161.849L145.331 158.522L144.848 163.151L132.409 166.816L132.939 161.849Z" fill="#63FF94" />
<path d="M144.945 148.88L159.217 146.083L159.602 150.664L145.379 153.701L144.945 148.88Z" fill="#97FF60" />
<path d="M43.4023 123.373L44.4631 128.821L46.7292 119.371L45.7649 113.44L43.4023 123.373Z" fill="#FFEA00" />
<path d="M155.601 133.21L171.03 130.654L172.524 134.56L157.192 137.212L155.601 133.21Z" fill="#D4FF23" />
<path d="M104.106 206.497L102.515 202.978L99.0918 207.606L100.345 211.512L104.106 206.497Z" fill="#0090FF" />
<path d="M102.515 202.977L102.612 198.734L99.4775 203.026L99.0918 207.654L102.515 202.977Z" fill="#00A0FF" />
<path d="M140.895 120.432L156.903 118.407L159.506 118.841L143.595 120.866L140.895 120.432Z" fill="#FFDE00" />
<path d="M45.0898 155.244L46.8256 156.545L47.0667 147.867L45.2827 145.89L45.0898 155.244Z" fill="#8DFF6A" />
<path d="M98.272 131.618L113.219 130.557L115.148 127.375L100.297 128.195L98.272 131.618Z" fill="#E4FF13" />
<path d="M117.365 124.819L133.277 123.469L135.639 121.733L119.872 122.987L117.365 124.819Z" fill="#FEED00" />
<path d="M54.2988 156.594L60.9044 155.63L61.0973 148.928L54.4917 149.265L54.2988 156.594Z" fill="#8AFF6D" />
<path d="M107.385 189.284L112.158 184.752L109.94 189.091L104.974 194.009L107.385 189.284Z" fill="#02E8F4" />
<path d="M113.46 203.315L110.181 202.447L106.758 209.053L109.844 210.403L113.46 203.315Z" fill="#0098FF" />
<path d="M122.38 170.528L132.409 166.815L131.396 171.444L121.271 175.591L122.38 170.528Z" fill="#46FFB1" />
<path d="M153.624 129.593L169.15 127.183L171.03 130.654L155.601 133.21L153.624 129.593Z" fill="#E4FF13" />
<path d="M66.5459 51.0496L67.9442 59.0053L75.2248 57.9928L73.9229 50.0371L66.5459 51.0496Z" fill="#920000" />
<path d="M54.6846 67.1047L56.3239 75.1085L62.4956 70.1905L61.0009 62.042L54.6846 67.1047Z" fill="#DF0000" />
<path d="M143.595 120.866L159.506 118.841L162.158 119.95L146.344 122.023L143.595 120.866Z" fill="#FFDE00" />
<path d="M113.894 179.737L121.271 175.591L119.728 180.171L112.158 184.752L113.894 179.737Z" fill="#26FFD1" />
<path d="M128.021 148.976L127.298 154.762L127.25 166.623L127.973 161.222L128.021 148.976Z" fill="#83FF73" />
<path d="M151.406 126.46L167.028 124.193L169.149 127.183L153.624 129.594L151.406 126.46Z" fill="#F1FC06" />
<path d="M146.343 122.023L162.158 119.95L164.665 121.782L148.947 123.904L146.343 122.023Z" fill="#FFE600" />
<path d="M46.8257 156.546L49.7186 156.98L49.9597 148.976L47.0668 147.867L46.8257 156.546Z" fill="#8DFF6A" />
<path d="M49.7183 156.979L54.2988 156.594L54.4916 149.265L49.9593 148.976L49.7183 156.979Z" fill="#8DFF6A" />
<path d="M144.077 144.058L158.397 141.551L159.217 146.083L144.945 148.88L144.077 144.058Z" fill="#ADFF49" />
<path d="M148.947 123.903L164.665 121.733L167.028 124.192L151.406 126.459L148.947 123.903Z" fill="#FEED00" />
<path d="M83.3252 136.536L96.6328 135.668L98.2722 131.666L85.061 132.148L83.3252 136.536Z" fill="#D7FF1F" />
<path d="M132.939 156.642L145.379 153.701L145.331 158.523L132.939 161.85V156.642Z" fill="#7DFF7A" />
<path d="M61.0972 148.928L70.0171 148.06L70.8368 142.226L61.9651 142.563L61.0972 148.928Z" fill="#AAFF4D" />
<path d="M119.583 194.251L116.305 195.167L113.46 203.316L116.594 202.93L119.583 194.251Z" fill="#00C4FF" />
<path d="M119.873 122.988L135.639 121.734L138.195 120.722L122.573 121.879L119.873 122.988Z" fill="#FFE200" />
<path d="M70.8369 142.226L82.0712 141.406L83.3249 136.536L72.187 136.874L70.8369 142.226Z" fill="#C4FF33" />
<path d="M105.263 193.527L107.385 189.284L104.974 194.009L102.611 198.735L105.263 193.527Z" fill="#00D8FF" />
<path d="M127.25 166.623L125.466 171.011L124.357 181.908L126.093 177.954L127.25 166.623Z" fill="#46FFB1" />
<path d="M44.1733 142.948L45.2823 145.89L46.4395 136.921L45.3787 133.354L44.1733 142.948Z" fill="#C1FF36" />
<path d="M115.389 86.1025L116.257 93.6725L120.789 105.003L120.017 97.5779L115.389 86.1025Z" fill="#FF6400" />
<path d="M100.297 128.194L115.147 127.375L117.365 124.819L102.659 125.446L100.297 128.194Z" fill="#F8F500" />
<path d="M110.181 202.447L107.24 200.374L104.106 206.497L106.758 209.053L110.181 202.447Z" fill="#00A8FF" />
<path d="M124.357 181.907L121.609 184.607L119.583 194.251L122.187 192.033L124.357 181.907Z" fill="#0FF8E7" />
<path d="M142.775 139.477L157.192 137.211L158.397 141.55L144.077 144.058L142.775 139.477Z" fill="#C4FF33" />
<path d="M122.91 165.128L132.939 161.85L132.409 166.816L122.38 170.528L122.91 165.128Z" fill="#63FF94" />
<path d="M124.309 117.105L124.55 124.193L126.864 136.488L126.671 129.738L124.309 117.105Z" fill="#FFEA00" />
<path d="M50.8755 81.8114L52.3702 89.526L57.6739 83.065L56.3239 75.1094L50.8755 81.8114Z" fill="#FF3000" />
<path d="M105.408 197.336L105.263 193.527L102.66 198.735L102.515 202.978L105.408 197.336Z" fill="#00C8FF" />
<path d="M122.573 121.878L138.195 120.721L140.895 120.432L125.418 121.589L122.573 121.878Z" fill="#FFDB00" />
<path d="M107.24 200.375L105.408 197.337L102.515 202.978L104.106 206.498L107.24 200.375Z" fill="#00B8FF" />
<path d="M109.265 183.787L113.846 179.737L112.158 184.752L107.385 189.284L109.265 183.787Z" fill="#1CFFDB" />
<path d="M54.4917 149.265L61.0973 148.927L61.9652 142.563L55.4078 142.273L54.4917 149.265Z" fill="#ADFF49" />
<path d="M73.9229 50.0371L75.2247 57.9928L82.7464 59.1017L81.541 51.1461L73.9229 50.0371Z" fill="#840000" />
<path d="M132.457 151.435L144.945 148.88L145.379 153.701L132.939 156.643L132.457 151.435Z" fill="#97FF60" />
<path d="M115.051 174.193L122.332 170.528L121.271 175.591L113.846 179.738L115.051 174.193Z" fill="#43FFB4" />
<path d="M44.4629 128.821L45.379 133.354L47.5005 124.434L46.729 119.371L44.4629 128.821Z" fill="#FBF100" />
<path d="M141.04 135.186L155.601 133.209L157.192 137.211L142.775 139.477L141.04 135.186Z" fill="#D7FF1F" />
<path d="M85.0605 132.148L98.2717 131.618L100.297 128.194L87.2303 128.387L85.0605 132.148Z" fill="#EEFF09" />
<path d="M45.2827 145.89L47.0667 147.867L48.0792 139.574L46.4399 136.922L45.2827 145.89Z" fill="#BEFF39" />
<path d="M102.66 125.446L117.366 124.82L119.873 122.987L105.36 123.469L102.66 125.446Z" fill="#FFE600" />
<path d="M125.417 121.589L140.895 120.432L143.595 120.866L128.31 122.023L125.417 121.589Z" fill="#FFD700" />
<path d="M47.8862 97.5293L49.1398 104.521L53.4311 96.8542L52.3703 89.5254L47.8862 97.5293Z" fill="#FF6C00" />
<path d="M61.9653 142.563L70.8371 142.226L72.1871 136.874L63.4118 136.633L61.9653 142.563Z" fill="#CAFF2C" />
<path d="M49.9595 148.976L54.4918 149.265L55.4079 142.274L50.9238 141.31L49.9595 148.976Z" fill="#B4FF43" />
<path d="M45.7646 113.44L46.729 119.371L49.9594 110.885L49.1398 104.521L45.7646 113.44Z" fill="#FFAE00" />
<path d="M72.187 136.873L83.3249 136.536L85.0607 132.148L74.0674 132.052L72.187 136.873Z" fill="#DEFF19" />
<path d="M109.844 75.7842L110.76 83.4505L116.256 93.6723L115.389 86.1024L109.844 75.7842Z" fill="#FF2D00" />
<path d="M138.966 131.329L153.624 129.593L155.601 133.209L141.04 135.186L138.966 131.329Z" fill="#EBFF0C" />
<path d="M116.305 195.167L112.785 194.781L110.181 202.448L113.46 203.315L116.305 195.167Z" fill="#00CCFF" />
<path d="M61.001 62.0425L62.4957 70.191L69.2941 67.2498L67.9441 59.0049L61.001 62.0425Z" fill="#BB0000" />
<path d="M47.0664 147.867L49.9594 148.976L50.9237 141.31L48.0789 139.574L47.0664 147.867Z" fill="#B7FF40" />
<path d="M128.311 122.023L143.595 120.866L146.343 122.023L131.204 123.277L128.311 122.023Z" fill="#FFD700" />
<path d="M122.91 159.439L132.939 156.643V161.85L122.91 165.129V159.439Z" fill="#7DFF7A" />
<path d="M131.493 146.227L144.077 144.058L144.945 148.879L132.457 151.435L131.493 146.227Z" fill="#B1FF46" />
<path d="M107.336 187.549L109.265 183.788L107.385 189.285L105.263 193.528L107.336 187.549Z" fill="#16FFE1" />
<path d="M136.604 128.002L151.406 126.459L153.624 129.593L138.966 131.329L136.604 128.002Z" fill="#F8F500" />
<path d="M131.204 123.277L146.343 122.023L148.947 123.904L133.952 125.302L131.204 123.277Z" fill="#FFDB00" />
<path d="M81.541 51.1455L82.7464 59.1494L90.2681 62.3316L89.1591 54.4242L81.541 51.1455Z" fill="#890000" />
<path d="M133.952 125.302L148.947 123.903L151.406 126.459L136.604 128.002L133.952 125.302Z" fill="#FFE600" />
<path d="M126.864 136.536L126.141 142.708L127.298 154.762L128.021 148.976L126.864 136.536Z" fill="#C1FF36" />
<path d="M105.36 123.47L119.873 122.988L122.573 121.879L108.253 122.265L105.36 123.47Z" fill="#FFD700" />
<path d="M87.2305 128.388L100.297 128.195L102.66 125.447L89.8341 125.398L87.2305 128.388Z" fill="#FFEA00" />
<path d="M115.63 168.262L122.91 165.128L122.332 170.528L115.051 174.193L115.63 168.262Z" fill="#60FF97" />
<path d="M110.519 177.713L115.051 174.193L113.846 179.738L109.265 183.788L110.519 177.713Z" fill="#3CFFBA" />
<path d="M103.479 66.8643L104.492 74.6752L110.76 83.4506L109.844 75.7842L103.479 66.8643Z" fill="#E40000" />
<path d="M112.785 194.781L109.699 193.238L107.24 200.374L110.181 202.448L112.785 194.781Z" fill="#00D8FF" />
<path d="M121.657 184.607L118.233 186.054L116.305 195.167L119.583 194.251L121.657 184.607Z" fill="#16FFE1" />
<path d="M107.626 190.779L107.336 187.549L105.263 193.528L105.408 197.337L107.626 190.779Z" fill="#0CF4EB" />
<path d="M55.4077 142.273L61.9651 142.563L63.4116 136.632L56.9988 135.812L55.4077 142.273Z" fill="#D1FF26" />
<path d="M89.1592 54.4238L90.2681 62.3313L97.597 67.5868L96.5362 59.6794L89.1592 54.4238Z" fill="#9B0000" />
<path d="M45.3789 133.354L46.4397 136.922L48.4165 128.628L47.5004 124.434L45.3789 133.354Z" fill="#F4F802" />
<path d="M130.046 141.262L142.775 139.478L144.077 144.058L131.493 146.228L130.046 141.262Z" fill="#C7FF30" />
<path d="M120.789 105.003L121.127 112.284L124.55 124.193L124.309 117.105L120.789 105.003Z" fill="#FFA700" />
<path d="M96.5361 59.6797L97.5969 67.5871L104.492 74.6749L103.479 66.8639L96.5361 59.6797Z" fill="#BB0000" />
<path d="M109.699 193.238L107.626 190.779L105.408 197.337L107.24 200.374L109.699 193.238Z" fill="#02E8F4" />
<path d="M127.298 154.762L125.466 159.632V171.011L127.25 166.623L127.298 154.762Z" fill="#80FF77" />
<path d="M74.0674 132.052L85.0606 132.149L87.2304 128.388L76.43 127.954L74.0674 132.052Z" fill="#F8F500" />
<path d="M122.38 153.749L132.457 151.435L132.939 156.642L122.91 159.438L122.38 153.749Z" fill="#9AFF5D" />
<path d="M125.466 171.011L122.669 174.241L121.657 184.608L124.357 181.908L125.466 171.011Z" fill="#49FFAD" />
<path d="M108.252 122.264L122.573 121.878L125.417 121.589L111.29 121.926L108.252 122.264Z" fill="#FFD000" />
<path d="M63.4116 136.633L72.1869 136.874L74.0674 132.052L65.4849 131.329L63.4116 136.633Z" fill="#E7FF0F" />
<path d="M56.3242 75.1094L57.6743 83.0651L63.7013 78.3399L62.4959 70.1914L56.3242 75.1094Z" fill="#F10800" />
<path d="M50.9238 141.309L55.4079 142.274L56.999 135.765L52.6596 134.222L50.9238 141.309Z" fill="#DBFF1C" />
<path d="M128.214 136.633L141.039 135.187L142.775 139.478L130.046 141.262L128.214 136.633Z" fill="#DEFF19" />
<path d="M89.834 125.398L102.659 125.446L105.36 123.47L92.7269 123.229L89.834 125.398Z" fill="#FFD700" />
<path d="M46.4399 136.922L48.0793 139.574L49.9597 131.859L48.4168 128.629L46.4399 136.922Z" fill="#EBFF0C" />
<path d="M67.9438 59.0047L69.2939 67.2014L76.4781 66.2853L75.2245 57.9922L67.9438 59.0047Z" fill="#9F0000" />
<path d="M108.735 180.847L110.519 177.713L109.265 183.788L107.336 187.549L108.735 180.847Z" fill="#36FFC1" />
<path d="M46.729 119.371L47.5005 124.433L50.5381 116.478L49.9595 110.885L46.729 119.371Z" fill="#FFB900" />
<path d="M111.29 121.926L125.417 121.589L128.31 122.023L114.424 122.457L111.29 121.926Z" fill="#FFCC00" />
<path d="M115.63 162.042L122.91 159.438V165.128L115.63 168.262V162.042Z" fill="#80FF77" />
<path d="M48.0791 139.574L50.9238 141.31L52.6596 134.222L49.9595 131.859L48.0791 139.574Z" fill="#E1FF16" />
<path d="M125.948 132.486L138.966 131.329L141.039 135.186L128.214 136.633L125.948 132.486Z" fill="#F1FC06" />
<path d="M111.194 171.156L115.63 168.263L115.051 174.193L110.519 177.713L111.194 171.156Z" fill="#5DFF9A" />
<path d="M52.3701 89.5254L53.4309 96.8542L58.5418 90.6826L57.6739 83.0645L52.3701 89.5254Z" fill="#FF3B00" />
<path d="M118.234 186.054L114.569 186.247L112.785 194.781L116.305 195.167L118.234 186.054Z" fill="#1CFFDB" />
<path d="M121.319 148.06L131.493 146.228L132.457 151.435L122.38 153.749L121.319 148.06Z" fill="#B7FF40" />
<path d="M114.424 122.457L128.311 122.023L131.203 123.277L117.51 123.807L114.424 122.457Z" fill="#FFCC00" />
<path d="M49.1396 104.521L49.9593 110.885L54.0577 103.605L53.4309 96.8545L49.1396 104.521Z" fill="#FF7A00" />
<path d="M123.393 128.87L136.604 128.002L138.966 131.329L125.948 132.486L123.393 128.87Z" fill="#FFEA00" />
<path d="M76.4302 127.954L87.2306 128.388L89.8342 125.398L79.2749 124.627L76.4302 127.954Z" fill="#FFDB00" />
<path d="M117.51 123.807L131.204 123.276L133.952 125.301L120.548 125.976L117.51 123.807Z" fill="#FFD000" />
<path d="M56.999 135.813L63.4118 136.632L65.485 131.329L59.217 129.979L56.999 135.813Z" fill="#F4F802" />
<path d="M120.548 125.977L133.952 125.302L136.604 128.002L123.393 128.87L120.548 125.977Z" fill="#FFDB00" />
<path d="M92.7271 123.229L105.36 123.47L108.253 122.264L95.8611 121.927L92.7271 123.229Z" fill="#FFC800" />
<path d="M116.256 93.6719L116.642 101.242L121.126 112.283L120.789 105.003L116.256 93.6719Z" fill="#FF6C00" />
<path d="M124.55 124.193L123.923 130.751L126.141 142.708L126.864 136.537L124.55 124.193Z" fill="#FBF100" />
<path d="M109.169 183.499L108.735 180.847L107.336 187.549L107.626 190.779L109.169 183.499Z" fill="#30FFC7" />
<path d="M65.4849 131.329L74.0673 132.052L76.4299 127.954L68.0885 126.845L65.4849 131.329Z" fill="#FFE600" />
<path d="M114.569 186.247L111.339 185.331L109.699 193.238L112.785 194.781L114.569 186.247Z" fill="#23FFD4" />
<path d="M111.339 185.331L109.169 183.499L107.626 190.78L109.699 193.239L111.339 185.331Z" fill="#29FFCE" />
<path d="M119.728 142.66L130.046 141.262L131.493 146.228L121.319 148.06L119.728 142.66Z" fill="#D1FF26" />
<path d="M115.003 155.774L122.38 153.749L122.91 159.439L115.63 162.042L115.003 155.774Z" fill="#9DFF5A" />
<path d="M47.5005 124.434L48.4166 128.629L51.2613 121.155L50.5381 116.479L47.5005 124.434Z" fill="#FFC400" />
<path d="M122.669 174.241L119.198 176.266L118.233 186.054L121.609 184.608L122.669 174.241Z" fill="#4DFFAA" />
<path d="M75.2246 57.9922L76.4782 66.2853L83.8071 67.3943L82.7463 59.1012L75.2246 57.9922Z" fill="#920000" />
<path d="M95.8613 121.927L108.253 122.264L111.29 121.927L99.1882 121.541L95.8613 121.927Z" fill="#FFC100" />
<path d="M52.6597 134.222L56.9991 135.813L59.217 129.979L55.0705 127.857L52.6597 134.222Z" fill="#FEED00" />
<path d="M109.41 173.711L111.194 171.155L110.519 177.713L108.735 180.847L109.41 173.711Z" fill="#5AFF9D" />
<path d="M62.4956 70.1912L63.701 78.3397L70.2584 75.4467L69.2941 67.25L62.4956 70.1912Z" fill="#C80000" />
<path d="M126.141 142.708L124.405 148.012L125.466 159.632L127.298 154.762L126.141 142.708Z" fill="#BEFF39" />
<path d="M111.146 164.356L115.63 162.042V168.262L111.194 171.155L111.146 164.356Z" fill="#80FF77" />
<path d="M79.2749 124.627L89.8342 125.399L92.7272 123.229L82.4089 122.265L79.2749 124.627Z" fill="#FFC800" />
<path d="M125.466 159.632L122.718 163.393L122.669 174.241L125.466 171.011V159.632Z" fill="#80FF77" />
<path d="M117.655 137.597L128.214 136.633L130.095 141.262L119.728 142.66L117.655 137.597Z" fill="#E7FF0F" />
<path d="M48.4165 128.629L49.9594 131.859L52.6113 124.964L51.2612 121.155L48.4165 128.629Z" fill="#FFD300" />
<path d="M110.76 83.4502L111.242 91.2612L116.642 101.242L116.256 93.672L110.76 83.4502Z" fill="#FF3400" />
<path d="M49.9595 131.859L52.6596 134.221L55.0704 127.857L52.6114 124.964L49.9595 131.859Z" fill="#FFDE00" />
<path d="M99.188 121.541L111.29 121.927L114.424 122.457L102.611 122.071L99.188 121.541Z" fill="#FFB900" />
<path d="M59.2168 129.979L65.4849 131.329L68.0885 126.845L62.0615 125.013L59.2168 129.979Z" fill="#FFD700" />
<path d="M68.0889 126.845L76.4302 127.954L79.275 124.627L71.1265 123.181L68.0889 126.845Z" fill="#FFCC00" />
<path d="M49.9595 110.885L50.5381 116.478L54.3953 109.631L54.0578 103.604L49.9595 110.885Z" fill="#FF8600" />
<path d="M113.797 149.554L121.319 148.06L122.38 153.749L115.003 155.774L113.797 149.554Z" fill="#BAFF3C" />
<path d="M82.7466 59.1494L83.8073 67.3944L91.1844 70.5766L90.2683 62.3317L82.7466 59.1494Z" fill="#960000" />
<path d="M115.196 133.065L125.948 132.486L128.214 136.633L117.655 137.597L115.196 133.065Z" fill="#FBF100" />
<path d="M109.94 175.64L109.41 173.711L108.735 180.847L109.169 183.499L109.94 175.64Z" fill="#56FFA0" />
<path d="M119.198 176.267L115.485 177.086L114.569 186.247L118.233 186.054L119.198 176.267Z" fill="#4DFFAA" />
<path d="M57.6738 83.065L58.5417 90.6831L64.3276 86.1508L63.7008 78.3398L57.6738 83.065Z" fill="#FF1300" />
<path d="M102.611 122.071L114.424 122.457L117.51 123.807L105.986 123.566L102.611 122.071Z" fill="#FFBD00" />
<path d="M121.126 112.283L120.548 119.226L123.923 130.75L124.55 124.193L121.126 112.283Z" fill="#FFAE00" />
<path d="M104.492 74.6748L105.119 82.6304L111.242 91.2611L110.76 83.4501L104.492 74.6748Z" fill="#F10800" />
<path d="M82.4087 122.216L92.7269 123.228L95.861 121.926L85.8802 120.817L82.4087 122.216Z" fill="#FFB900" />
<path d="M112.351 129.111L123.393 128.87L125.948 132.486L115.196 133.065L112.351 129.111Z" fill="#FFDB00" />
<path d="M53.4312 96.8543L54.058 103.605L58.9278 97.7704L58.5421 90.6826L53.4312 96.8543Z" fill="#FF4A00" />
<path d="M105.986 123.566L117.51 123.807L120.548 125.977L109.265 125.929L105.986 123.566Z" fill="#FFC100" />
<path d="M109.265 125.929L120.548 125.977L123.392 128.87L112.351 129.111L109.265 125.929Z" fill="#FFCC00" />
<path d="M90.2681 62.332L91.1842 70.577L98.3684 75.6879L97.5969 67.5876L90.2681 62.332Z" fill="#A40000" />
<path d="M110.471 157.462L115.003 155.774L115.63 162.042L111.146 164.357L110.471 157.462Z" fill="#A0FF56" />
<path d="M97.5967 67.5869L98.3681 75.6872L105.118 82.6303L104.492 74.6747L97.5967 67.5869Z" fill="#C40000" />
<path d="M112.158 176.845L109.94 175.64L109.169 183.499L111.339 185.331L112.158 176.845Z" fill="#53FFA4" />
<path d="M109.362 166.237L111.146 164.356L111.194 171.155L109.41 173.71L109.362 166.237Z" fill="#80FF77" />
<path d="M115.485 177.086L112.158 176.845L111.338 185.331L114.569 186.247L115.485 177.086Z" fill="#50FFA7" />
<path d="M112.062 143.623L119.728 142.659L121.319 148.059L113.797 149.554L112.062 143.623Z" fill="#D7FF1F" />
<path d="M55.0703 127.857L59.2169 129.979L62.0616 125.012L58.2044 122.457L55.0703 127.857Z" fill="#FFC800" />
<path d="M69.2939 67.2495L70.2583 75.4462L77.2014 74.5783L76.4299 66.2852L69.2939 67.2495Z" fill="#AD0000" />
<path d="M50.5381 116.479L51.2613 121.155L54.8775 114.743L54.3954 109.632L50.5381 116.479Z" fill="#FF9800" />
<path d="M71.1743 123.18L79.2746 124.627L82.4086 122.264L74.6459 120.528L71.1743 123.18Z" fill="#FFB600" />
<path d="M122.718 163.393L119.198 166.044V176.266L122.669 174.241L122.718 163.393Z" fill="#80FF77" />
<path d="M85.8804 120.818L95.8611 121.927L99.188 121.541L89.5448 120.384L85.8804 120.818Z" fill="#FFAE00" />
<path d="M62.0615 125.013L68.0885 126.845L71.1261 123.18L65.4366 121.011L62.0615 125.013Z" fill="#FFB900" />
<path d="M123.923 130.75L122.235 136.536L124.405 148.011L126.141 142.708L123.923 130.75Z" fill="#F8F500" />
<path d="M52.6113 124.965L55.0703 127.858L58.2044 122.458L55.9382 119.034L52.6113 124.965Z" fill="#FFB600" />
<path d="M51.2612 121.155L52.6113 124.964L55.9382 119.033L54.8774 114.742L51.2612 121.155Z" fill="#FFA700" />
<path d="M124.405 148.012L121.657 152.399L122.717 163.393L125.466 159.632L124.405 148.012Z" fill="#BAFF3C" />
<path d="M109.795 138.031L117.655 137.597L119.728 142.659L112.062 143.624L109.795 138.031Z" fill="#F1FC06" />
<path d="M109.169 150.615L113.798 149.555L115.003 155.775L110.471 157.462L109.169 150.615Z" fill="#C1FF36" />
<path d="M109.892 167.491L109.362 166.237L109.41 173.711L109.94 175.639L109.892 167.491Z" fill="#80FF77" />
<path d="M116.642 101.242L116.208 108.475L120.548 119.227L121.127 112.332L116.642 101.242Z" fill="#FF7300" />
<path d="M89.5449 120.384L99.1881 121.541L102.611 122.071L93.2576 121.011L89.5449 120.384Z" fill="#FFA700" />
<path d="M54.0576 103.605L54.3951 109.632L59.0239 104.087L58.9274 97.7705L54.0576 103.605Z" fill="#FF5D00" />
<path d="M108.638 158.619L110.47 157.462L111.145 164.357L109.361 166.237L108.638 158.619Z" fill="#A4FF53" />
<path d="M63.7012 78.3392L64.328 86.1502L70.6443 83.4019L70.2585 75.4463L63.7012 78.3392Z" fill="#DA0000" />
<path d="M107.096 133.064H115.196L117.655 137.597L109.796 138.031L107.096 133.064Z" fill="#FFE200" />
<path d="M74.646 120.528L82.4088 122.264L85.8803 120.818L78.4068 118.985L74.646 120.528Z" fill="#FFA300" />
<path d="M119.197 166.044L115.485 167.539V177.085L119.197 176.266V166.044Z" fill="#80FF77" />
<path d="M76.4302 66.2852L77.2016 74.5301L84.3858 75.6391L83.8072 67.3941L76.4302 66.2852Z" fill="#A40000" />
<path d="M93.2573 121.011L102.611 122.071L105.986 123.566L96.97 122.65L93.2573 121.011Z" fill="#FFAB00" />
<path d="M58.1562 122.409L62.0617 125.012L65.4369 121.011L61.8689 118.069L58.1562 122.409Z" fill="#FFA700" />
<path d="M112.158 167.973L109.892 167.491L109.94 175.64L112.158 176.845V167.973Z" fill="#80FF77" />
<path d="M58.542 90.6827L58.9277 97.7705L64.4243 93.431L64.3279 86.1504L58.542 90.6827Z" fill="#FF2500" />
<path d="M103.961 128.726L112.351 129.111L115.196 133.065H107.095L103.961 128.726Z" fill="#FFCC00" />
<path d="M96.9702 122.65L105.987 123.566L109.265 125.929L100.586 125.254L96.9702 122.65Z" fill="#FFAE00" />
<path d="M115.485 167.539L112.158 167.973V176.845L115.485 177.086V167.539Z" fill="#80FF77" />
<path d="M65.437 121.01L71.1747 123.18L74.6463 120.528L69.2461 118.117L65.437 121.01Z" fill="#FF9F00" />
<path d="M100.586 125.254L109.265 125.929L112.351 129.111L103.962 128.725L100.586 125.254Z" fill="#FFBD00" />
<path d="M107.24 144.058L112.062 143.624L113.798 149.555L109.169 150.615L107.24 144.058Z" fill="#E1FF16" />
<path d="M111.242 91.2617L111.001 98.8316L116.208 108.475L116.642 101.242L111.242 91.2617Z" fill="#FF3F00" />
<path d="M54.3955 109.632L54.8777 114.743L59.1689 109.632L59.0242 104.087L54.3955 109.632Z" fill="#FF6F00" />
<path d="M120.548 119.227L119.005 125.495L122.235 136.536L123.923 130.75L120.548 119.227Z" fill="#FFB600" />
<path d="M78.4067 118.985L85.8802 120.817L89.4964 120.383L82.4087 118.503L78.4067 118.985Z" fill="#FF9800" />
<path d="M55.9385 119.034L58.1564 122.409L61.869 118.07L59.9404 114.261L55.9385 119.034Z" fill="#FF9400" />
<path d="M121.657 152.399L118.233 155.63L119.198 166.045L122.717 163.393L121.657 152.399Z" fill="#B7FF40" />
<path d="M83.8071 67.3945L84.3857 75.6395L91.5217 78.7253L91.1842 70.5768L83.8071 67.3945Z" fill="#A40000" />
<path d="M109.072 159.198L108.638 158.619L109.361 166.237L109.892 167.491L109.072 159.198Z" fill="#A7FF50" />
<path d="M107.192 151.146L109.169 150.615L110.471 157.462L108.638 158.619L107.192 151.146Z" fill="#C7FF30" />
<path d="M54.8774 114.743L55.9382 119.034L59.9401 114.261L59.1687 109.632L54.8774 114.743Z" fill="#FF8200" />
<path d="M122.235 136.536L119.583 141.454L121.657 152.351L124.405 148.012L122.235 136.536Z" fill="#F1FC06" />
<path d="M104.733 137.935L109.796 138.031L112.062 143.624L107.24 144.058L104.733 137.935Z" fill="#FBF100" />
<path d="M105.118 82.6309L105.022 90.4901L111.001 98.8314L111.242 91.2615L105.118 82.6309Z" fill="#FF1300" />
<path d="M70.2583 75.4464L70.644 83.402L77.3461 82.5823L77.2014 74.5303L70.2583 75.4464Z" fill="#C40000" />
<path d="M91.1841 70.5762L91.5216 78.7247L98.4647 83.7391L98.3683 75.6871L91.1841 70.5762Z" fill="#B60000" />
<path d="M82.4087 118.503L89.4964 120.383L93.2573 121.01L86.5071 119.178L82.4087 118.503Z" fill="#FF9400" />
<path d="M69.2461 118.118L74.6463 120.528L78.4071 118.985L73.3927 116.382L69.2461 118.118Z" fill="#FF8D00" />
<path d="M61.8687 118.069L65.4366 121.01L69.2457 118.117L66.0152 114.887L61.8687 118.069Z" fill="#FF8D00" />
<path d="M98.3682 75.6875L98.4646 83.7396L105.022 90.4898L105.118 82.6306L98.3682 75.6875Z" fill="#D10000" />
<path d="M58.9277 97.7701L59.0242 104.086L64.2315 100.036L64.4244 93.4307L58.9277 97.7701Z" fill="#FF3800" />
<path d="M111.29 158.957L109.072 159.198L109.892 167.491L112.158 167.973L111.29 158.957Z" fill="#ADFF49" />
<path d="M118.233 155.63L114.569 157.8L115.485 167.539L119.198 166.045L118.233 155.63Z" fill="#B4FF43" />
<path d="M101.744 132.438L107.096 133.064L109.796 138.031L104.733 137.934L101.744 132.438Z" fill="#FFD300" />
<path d="M64.3276 86.1507L64.4241 93.4313L70.4511 90.8758L70.6439 83.4023L64.3276 86.1507Z" fill="#F10800" />
<path d="M86.5073 119.179L93.2576 121.011L96.9702 122.65L90.6057 120.963L86.5073 119.179Z" fill="#FF9400" />
<path d="M114.569 157.848L111.29 158.957L112.158 167.973L115.485 167.539L114.569 157.848Z" fill="#B1FF46" />
<path d="M105.07 143.913L107.24 144.058L109.169 150.615L107.192 151.145L105.07 143.913Z" fill="#EBFF0C" />
<path d="M116.208 108.475L114.858 115.177L119.005 125.495L120.548 119.227L116.208 108.475Z" fill="#FF7E00" />
<path d="M98.3203 127.713L103.962 128.725L107.096 133.065L101.744 132.438L98.3203 127.713Z" fill="#FFB900" />
<path d="M90.6055 120.962L96.97 122.649L100.586 125.253L94.5592 123.855L90.6055 120.962Z" fill="#FF9C00" />
<path d="M107.481 151.001L107.192 151.146L108.638 158.619L109.072 159.198L107.481 151.001Z" fill="#CEFF29" />
<path d="M94.5591 123.855L100.586 125.254L103.961 128.725L98.3199 127.713L94.5591 123.855Z" fill="#FFA700" />
<path d="M59.9404 114.261L61.8691 118.07L66.0156 114.887L64.4727 110.789L59.9404 114.261Z" fill="#FF7700" />
<path d="M73.3926 116.382L78.407 118.986L82.409 118.503L77.7802 115.852L73.3926 116.382Z" fill="#FF8200" />
<path d="M59.0239 104.087L59.1686 109.632L64.0384 105.871L64.2313 100.037L59.0239 104.087Z" fill="#FF4A00" />
<path d="M77.2017 74.5303L77.3463 82.5823L84.2412 83.6431L84.3858 75.6392L77.2017 74.5303Z" fill="#B60000" />
<path d="M119.583 141.454L116.256 145.36L118.233 155.63L121.657 152.399L119.583 141.454Z" fill="#EBFF0C" />
<path d="M59.1689 109.632L59.9404 114.261L64.4727 110.789L64.0388 105.871L59.1689 109.632Z" fill="#FF6000" />
<path d="M66.0156 114.888L69.2461 118.118L73.3927 116.382L70.5479 112.959L66.0156 114.888Z" fill="#FF7700" />
<path d="M119.005 125.495L116.498 130.944L119.583 141.455L122.235 136.537L119.005 125.495Z" fill="#FFC100" />
<path d="M102.322 137.211L104.733 137.934L107.24 144.058L105.071 143.913L102.322 137.211Z" fill="#FFE200" />
<path d="M109.554 150.037L107.481 151.001L109.072 159.198L111.29 158.957L109.554 150.037Z" fill="#D7FF1F" />
<path d="M111.001 98.8311L109.795 105.919L114.858 115.176L116.208 108.474L111.001 98.8311Z" fill="#FF4A00" />
<path d="M77.7803 115.852L82.409 118.503L86.5074 119.178L82.2644 116.623L77.7803 115.852Z" fill="#FF7A00" />
<path d="M64.4243 93.4314L64.2314 100.037L69.8727 97.6262L70.4513 90.876L64.4243 93.4314Z" fill="#FF1A00" />
<path d="M70.644 83.4017L70.4512 90.8752L76.8157 90.0555L77.3461 82.582L70.644 83.4017Z" fill="#DA0000" />
<path d="M105.167 143.094L105.07 143.913L107.192 151.146L107.481 151.001L105.167 143.094Z" fill="#F4F802" />
<path d="M116.256 145.359L112.688 148.204L114.521 157.847L118.233 155.629L116.256 145.359Z" fill="#E4FF13" />
<path d="M84.3859 75.6396L84.2412 83.6435L91.0879 86.5847L91.5218 78.7255L84.3859 75.6396Z" fill="#BB0000" />
<path d="M98.9951 131.185L101.743 132.438L104.733 137.935L102.322 137.212L98.9951 131.185Z" fill="#FFC100" />
<path d="M112.689 148.204L109.555 150.036L111.29 158.956L114.521 157.847L112.689 148.204Z" fill="#DEFF19" />
<path d="M64.4727 110.789L66.0156 114.887L70.5479 112.959L69.3907 108.716L64.4727 110.789Z" fill="#FF6000" />
<path d="M82.2642 116.623L86.5072 119.178L90.6055 120.962L86.7483 118.552L82.2642 116.623Z" fill="#FF7E00" />
<path d="M70.5479 112.958L73.3926 116.381L77.7803 115.851L75.3212 112.428L70.5479 112.958Z" fill="#FF6C00" />
<path d="M105.022 90.4902L104.058 97.9155L109.795 105.919L111.001 98.8316L105.022 90.4902Z" fill="#FF1E00" />
<path d="M95.2344 125.929L98.3202 127.713L101.744 132.438L98.9952 131.184L95.2344 125.929Z" fill="#FFA700" />
<path d="M91.5218 78.7256L91.0879 86.5848L97.7899 91.4064L98.4649 83.7401L91.5218 78.7256Z" fill="#C80000" />
<path d="M86.7485 118.552L90.6058 120.963L94.5595 123.856L91.1362 121.686L86.7485 118.552Z" fill="#FF8600" />
<path d="M64.2314 100.037L64.0386 105.871L69.3423 103.605L69.8727 97.626L64.2314 100.037Z" fill="#FF3000" />
<path d="M114.858 115.177L112.544 121.156L116.498 130.943L119.005 125.495L114.858 115.177Z" fill="#FF8900" />
<path d="M91.1357 121.686L94.5591 123.855L98.3199 127.713L95.2341 125.929L91.1357 121.686Z" fill="#FF9400" />
<path d="M98.4646 83.7393L97.7896 91.4056L104.058 97.9148L105.022 90.4895L98.4646 83.7393Z" fill="#E40000" />
<path d="M116.498 130.943L113.315 135.427L116.257 145.36L119.584 141.454L116.498 130.943Z" fill="#FFC800" />
<path d="M64.0386 105.871L64.4725 110.789L69.3906 108.715L69.3423 103.604L64.0386 105.871Z" fill="#FF4A00" />
<path d="M102.177 135.765L102.322 137.211L105.07 143.913L105.167 143.093L102.177 135.765Z" fill="#FFD300" />
<path d="M106.999 141.502L105.167 143.093L107.481 151.001L109.555 150.036L106.999 141.502Z" fill="#FEED00" />
<path d="M77.3463 82.582L76.8159 90.0555L83.3733 91.1163L84.2412 83.6428L77.3463 82.582Z" fill="#CD0000" />
<path d="M75.3213 112.428L77.7803 115.851L82.2644 116.623L80.2393 113.199L75.3213 112.428Z" fill="#FF6400" />
<path d="M70.4511 90.8753L69.8726 97.6256L75.8996 96.9023L76.8157 90.0557L70.4511 90.8753Z" fill="#F10800" />
<path d="M113.315 135.427L109.988 138.947L112.688 148.204L116.256 145.359L113.315 135.427Z" fill="#FFD300" />
<path d="M69.3906 108.716L70.5478 112.959L75.3212 112.428L74.598 108.089L69.3906 108.716Z" fill="#FF5200" />
<path d="M109.988 138.947L106.999 141.503L109.554 150.085L112.689 148.205L109.988 138.947Z" fill="#FFE200" />
<path d="M98.561 129.159L98.995 131.184L102.322 137.211L102.177 135.765L98.561 129.159Z" fill="#FFB200" />
<path d="M109.795 105.919L107.722 112.283L112.544 121.155L114.858 115.176L109.795 105.919Z" fill="#FF5900" />
<path d="M80.2393 113.199L82.2643 116.623L86.7484 118.551L85.1573 115.369L80.2393 113.199Z" fill="#FF6400" />
<path d="M69.8727 97.6256L69.3423 103.604L74.9836 102.929L75.8997 96.9023L69.8727 97.6256Z" fill="#FF2200" />
<path d="M103.768 133.499L102.177 135.765L105.167 143.094L106.999 141.503L103.768 133.499Z" fill="#FFC400" />
<path d="M69.3423 103.605L69.3905 108.716L74.5978 108.089L74.9836 102.93L69.3423 103.605Z" fill="#FF3800" />
<path d="M112.544 121.155L109.555 126.218L113.316 135.427L116.498 130.943L112.544 121.155Z" fill="#FF9400" />
<path d="M84.2409 83.6436L83.373 91.117L89.9304 93.9136L91.0876 86.5847L84.2409 83.6436Z" fill="#D10000" />
<path d="M94.4629 123.422L95.2343 125.929L98.9952 131.185L98.5612 129.16L94.4629 123.422Z" fill="#FF9400" />
<path d="M85.1572 115.369L86.7484 118.551L91.136 121.685L89.9306 118.792L85.1572 115.369Z" fill="#FF6C00" />
<path d="M89.9307 118.793L91.1361 121.686L95.2344 125.929L94.463 123.422L89.9307 118.793Z" fill="#FF7E00" />
<path d="M104.058 97.915L102.274 104.665L107.722 112.283L109.796 105.919L104.058 97.915Z" fill="#FF3000" />
<path d="M74.5977 108.089L75.3209 112.428L80.2389 113.2L79.9496 108.957L74.5977 108.089Z" fill="#FF4A00" />
<path d="M76.816 90.0557L75.8999 96.9023L82.0715 97.8667L83.3734 91.1164L76.816 90.0557Z" fill="#E80000" />
<path d="M91.0878 86.584L89.9307 93.961L96.2952 98.4933L97.7899 91.4056L91.0878 86.584Z" fill="#DF0000" />
<path d="M106.469 130.316L103.769 133.499L107.047 141.503L109.988 138.947L106.469 130.316Z" fill="#FFB200" />
<path d="M97.7896 91.4062L96.2949 98.494L102.274 104.666L104.058 97.8672L97.7896 91.4062Z" fill="#FA0F00" />
<path d="M109.603 126.218L106.469 130.316L109.989 138.947L113.364 135.427L109.603 126.218Z" fill="#FFA300" />
<path d="M99.8147 126.267L98.561 129.16L102.177 135.765L103.768 133.499L99.8147 126.267Z" fill="#FF9F00" />
<path d="M107.722 112.283L105.07 117.876L109.554 126.218L112.544 121.155L107.722 112.283Z" fill="#FF6800" />
<path d="M74.9834 102.93L74.5977 108.089L79.9496 108.957L80.7693 103.846L74.9834 102.93Z" fill="#FF3000" />
<path d="M79.9497 108.957L80.239 113.2L85.157 115.37L85.3017 111.32L79.9497 108.957Z" fill="#FF4A00" />
<path d="M75.8995 96.9023L74.9834 102.929L80.7693 103.845L82.0712 97.8667L75.8995 96.9023Z" fill="#FF1A00" />
<path d="M95.379 120.095L94.4629 123.422L98.5612 129.159L99.8631 126.266L95.379 120.095Z" fill="#FF7E00" />
<path d="M83.3731 91.1162L82.0713 97.8665L88.2429 100.567L89.9305 93.9127L83.3731 91.1162Z" fill="#ED0400" />
<path d="M85.3019 111.319L85.1572 115.369L89.9306 118.793L90.461 115.032L85.3019 111.319Z" fill="#FF5500" />
<path d="M102.226 122.554L99.8149 126.266L103.769 133.499L106.469 130.316L102.226 122.554Z" fill="#FF8D00" />
<path d="M90.461 115.032L89.9307 118.793L94.463 123.422L95.3791 120.095L90.461 115.032Z" fill="#FF6800" />
<path d="M105.07 117.877L102.226 122.554L106.469 130.317L109.603 126.218L105.07 117.877Z" fill="#FF7A00" />
<path d="M102.274 104.665L99.9111 110.692L105.07 117.876L107.722 112.283L102.274 104.665Z" fill="#FF4300" />
<path d="M80.7694 103.846L79.9497 108.957L85.3017 111.319L86.5071 106.353L80.7694 103.846Z" fill="#FF3400" />
<path d="M89.9307 93.9131L88.2432 100.567L94.2702 104.858L96.2952 98.4936L89.9307 93.9131Z" fill="#FA0F00" />
<path d="M82.0714 97.8672L80.7695 103.846L86.5072 106.353L88.243 100.567L82.0714 97.8672Z" fill="#FF1A00" />
<path d="M96.2951 98.4941L94.27 104.859L99.9113 110.693L102.274 104.666L96.2951 98.4941Z" fill="#FF2200" />
<path d="M97.404 115.852L95.3789 120.095L99.8148 126.266L102.226 122.554L97.404 115.852Z" fill="#FF6800" />
<path d="M99.9115 110.692L97.4043 115.851L102.226 122.554L105.071 117.877L99.9115 110.692Z" fill="#FF5500" />
<path d="M86.5072 106.353L85.3018 111.319L90.4609 115.031L92.1002 110.403L86.5072 106.353Z" fill="#FF3B00" />
<path d="M92.1003 110.403L90.4609 115.032L95.379 120.095L97.404 115.852L92.1003 110.403Z" fill="#FF4E00" />
<path d="M88.2431 100.567L86.5073 106.353L92.1004 110.403L94.2701 104.859L88.2431 100.567Z" fill="#FF2500" />
<path d="M94.2216 104.858L92.1001 110.403L97.4039 115.852L99.9111 110.693L94.2216 104.858Z" fill="#FF3800" />
</g>
    <rect x="25" y="121" width="100" height="100" rx="50" fill={`url(#${id}__a`} />
    <path d="M87.75 204H81.5V197.75H75.25V204H69H62.75H56.5V197.75H50.25V191.5V185.25V179V172.75V166.5V160.25H56.5V154H62.75H69H75.25V160.25H81.5V154V147.75V141.5V135.25H87.75H94V141.5V147.75V154V160.25V166.5V172.75V179V185.25V191.5V197.75V204H87.75ZM69 191.5H75.25V185.25H81.5V179V172.75H75.25V166.5H69H62.75V172.75V179V185.25V191.5H69Z" fill={`url(#${id}__b`} />
    <defs>
      <radialGradient
        id={`${id}__a`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(50.6 149) rotate(71.2791) scale(76.0221)"
      >
        <stop stopColor="#66A9F7" />
        <stop offset={1} stopColor="#0075FF" />
      </radialGradient>
      <radialGradient
        id={`${id}__b`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(80.0548 138.253) rotate(75.4706) scale(85.4812 97.7006)"
      >
        <stop stopColor="#334155" />
        <stop offset={1} stopColor="#050A30" />
      </radialGradient>
      <clipPath id={`${id}__a`}>
        <rect width="200" height="201.446" fill="white" transform="translate(34 24)" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgDcrv;
