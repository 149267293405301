import * as React from 'react';
import { SVGProps } from 'react';

const SvgAuni = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
<svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M125.057 245.026C192.467 245.026 247.114 190.399 247.114 123.013C247.114 55.627 192.467 1 125.057 1C57.6467 1 3 55.627 3 123.013C3 190.399 57.6467 245.026 125.057 245.026Z" fill="#EDF0F2" />
<path d="M125 250C116.538 250.002 108.097 249.152 99.8049 247.461C83.8156 244.186 68.6277 237.794 55.1077 228.651C35.0107 215.085 19.2794 195.982 9.81975 173.656C6.62477 166.096 4.18612 158.237 2.5395 150.196C-0.846499 133.572 -0.846499 116.436 2.5395 99.8119C5.81438 83.8227 12.2055 68.6348 21.348 55.1147C34.9144 35.0166 54.0183 19.2842 76.345 9.8238C83.9054 6.62738 91.7637 4.18742 99.8049 2.53964C116.429 -0.846546 133.565 -0.846546 150.189 2.53964C166.178 5.81447 181.366 12.2055 194.886 21.3482C214.983 34.9145 230.714 54.018 240.174 76.3442C243.371 83.9048 245.812 91.7634 247.461 99.805C250.847 116.429 250.847 133.565 247.461 150.189C244.186 166.178 237.795 181.366 228.652 194.886C215.085 214.982 195.982 230.714 173.656 240.174C166.096 243.371 158.237 245.812 150.195 247.461C141.904 249.152 133.463 250.002 125 250V250ZM125 13.3922C117.445 13.3905 109.91 14.1502 102.507 15.6597C88.2305 18.5835 74.6695 24.29 62.5979 32.4536C44.6566 44.5678 30.613 61.6247 22.1684 81.558C19.3146 88.3093 17.1363 95.3266 15.6654 102.507C12.6422 117.35 12.6422 132.65 15.6654 147.492C18.5903 161.769 24.2978 175.329 32.4623 187.401C44.5754 205.344 61.632 219.39 81.5657 227.837C88.3174 230.69 95.335 232.868 102.516 234.339C117.358 237.363 132.658 237.363 147.501 234.339C161.778 231.415 175.339 225.708 187.409 217.542C205.347 205.428 219.388 188.373 227.832 168.443C230.686 161.691 232.864 154.673 234.335 147.492C237.358 132.65 237.358 117.35 234.335 102.507C231.41 88.2312 225.702 74.6708 217.538 62.6C205.427 44.6591 188.373 30.6149 168.442 22.1685C161.691 19.3149 154.673 17.1366 147.492 15.6656C140.09 14.1545 132.555 13.3931 125 13.3931V13.3922Z" fill={`url(#${id}__a`} />
<path d="M125 220.707C177.855 220.707 220.703 177.859 220.703 125.004C220.703 72.1484 177.855 29.3008 125 29.3008C72.1445 29.3008 29.2969 72.1484 29.2969 125.004C29.2969 177.859 72.1445 220.707 125 220.707Z" fill="white" />
<path d="M125.001 220.704C118.522 220.706 112.06 220.054 105.712 218.758C93.4703 216.253 81.8417 211.36 71.4909 204.359C56.1042 193.973 44.0598 179.347 36.8171 162.255C34.3697 156.464 32.5019 150.445 31.241 144.286C28.6488 131.559 28.6488 118.439 31.241 105.712C33.749 93.4714 38.6422 81.8444 45.6413 71.4941C56.0273 56.1059 70.6536 44.0603 87.7477 36.8174C93.5381 34.3688 99.5571 32.5006 105.716 31.2403C118.444 28.6465 131.563 28.6465 144.291 31.2403C156.532 33.7481 168.159 38.6412 178.51 45.6407C193.895 56.0284 205.937 70.6549 213.178 87.748C215.625 93.5372 217.493 99.5545 218.754 105.712C221.347 118.439 221.347 131.559 218.754 144.286C216.247 156.527 211.354 168.155 204.355 178.507C193.969 193.894 179.344 205.939 162.252 213.184C156.462 215.632 150.443 217.499 144.284 218.759C137.938 220.055 131.477 220.706 125.001 220.704V220.704ZM125.001 31.544C118.674 31.5419 112.364 32.1776 106.165 33.4415C94.2095 35.8893 82.8534 40.6677 72.7448 47.5039C57.7176 57.6471 45.955 71.9312 38.8826 88.6249C36.493 94.2785 34.669 100.155 33.4373 106.168C30.9054 118.597 30.9054 131.41 33.4373 143.84C35.886 155.794 40.6644 167.149 47.4997 177.258C57.6436 192.284 71.9275 204.045 88.6207 211.118C94.2746 213.508 100.151 215.332 106.165 216.563C118.594 219.093 131.406 219.093 143.835 216.563C155.79 214.115 167.146 209.336 177.254 202.501C192.28 192.357 204.043 178.074 211.116 161.382C213.506 155.728 215.33 149.852 216.562 143.84C219.094 131.41 219.094 118.598 216.562 106.168C214.113 94.2129 209.335 82.8571 202.499 72.748C192.355 57.7222 178.072 45.9602 161.379 38.8868C155.726 36.4977 149.849 34.6737 143.836 33.4415C137.638 32.1775 131.327 31.5418 125.001 31.544V31.544Z" fill="#FF007A" />
<path d="M103.439 73.1626C101.932 72.9292 101.869 72.9019 102.578 72.7925C104.855 72.6615 107.139 72.8642 109.358 73.3941C114.521 74.6216 119.221 77.7652 124.233 83.3482L125.565 84.8316L127.471 84.5249C135.265 83.2042 143.275 84.21 150.501 87.4165C152.337 88.2512 154.083 89.2701 155.713 90.4575C155.96 91.1091 156.122 91.7899 156.194 92.4829C156.702 95.8472 156.448 98.4253 155.42 100.351C155.163 100.667 155.006 101.052 154.967 101.457C154.929 101.862 155.011 102.269 155.204 102.627C155.383 102.998 155.662 103.311 156.01 103.531C156.358 103.751 156.76 103.869 157.171 103.872C158.87 103.872 160.697 101.124 161.544 97.3061L161.879 95.7895L162.546 96.5444C166.201 100.685 169.072 106.332 169.565 110.351L169.693 111.4L169.079 110.447C168.197 108.991 167.01 107.743 165.599 106.79C163.147 105.168 160.555 104.616 153.685 104.251C147.484 103.925 143.974 103.395 140.494 102.26C134.573 100.33 131.589 97.7583 124.554 88.5308C122.452 85.6176 120.119 82.8777 117.579 80.3374C113.772 76.4709 108.808 73.9519 103.439 73.1626V73.1626Z" fill="#FF007A" />
<path d="M157.115 82.3115C157.14 80.1779 157.573 78.0689 158.393 76.0986C158.558 75.6822 158.765 75.2834 159.01 74.9082C158.964 75.2777 158.868 75.6392 158.724 75.9824C158.154 78.0539 158.066 80.228 158.465 82.3388C158.94 85.581 159.211 86.0498 162.638 89.5507C164.245 91.1933 166.114 93.2617 166.792 94.1543L168.024 95.7714L166.792 94.6152C164.988 92.9621 163.07 91.4372 161.053 90.0517C160.54 89.7871 160.467 89.792 160.146 90.1074C159.853 90.4004 159.793 90.8339 159.756 92.8964C159.841 95.4345 159.305 97.9548 158.193 100.238C157.622 101.354 157.531 101.117 158.049 99.8564C158.439 98.916 158.475 98.5029 158.472 95.3906C158.465 89.1406 157.723 87.6338 153.369 85.0586C152.057 84.2984 150.709 83.6007 149.331 82.9678C148.651 82.6943 147.991 82.3722 147.357 82.0039C149.425 82.4415 151.457 83.0343 153.436 83.7773C155.975 84.7539 156.397 84.8818 156.706 84.7636C156.914 84.6855 157.014 84.0849 157.115 82.3115V82.3115Z" fill="#FF007A" />
<path d="M106.367 93.0097C103.212 88.4732 101.618 83.0337 101.824 77.5117L101.951 76.0176L102.648 76.1445C104.261 76.4964 105.816 77.0751 107.267 77.8633C110.164 79.6211 111.418 81.9404 112.695 87.8896C113.069 89.6318 113.56 91.6006 113.784 92.2724C114.558 94.102 115.514 95.849 116.637 97.4873C117.442 98.6591 116.907 99.2177 115.13 99.0576C112.415 98.8124 108.739 96.2753 106.367 93.0097Z" fill="#FF007A" />
<path d="M153.403 124.359C139.105 118.603 134.067 113.605 134.067 105.174C134.037 104.42 134.07 103.666 134.165 102.918C134.596 103.192 135.007 103.496 135.394 103.828C138.294 106.153 141.541 107.148 150.531 108.458C154.264 108.894 157.949 109.666 161.543 110.764C170.269 113.658 175.669 119.531 176.957 127.531C177.297 130.531 177.145 133.565 176.505 136.516C176.025 138.33 174.564 141.601 174.178 141.726C174.07 141.762 173.965 141.35 173.937 140.789C173.823 139.221 173.39 137.692 172.665 136.296C171.941 134.9 170.94 133.666 169.723 132.67C166.819 130.176 162.925 128.192 153.403 124.359V124.359Z" fill="#FF007A" />
<path d="M143.363 126.752C143.195 125.73 142.964 124.719 142.673 123.725L142.307 122.637L142.985 123.397C144.023 124.629 144.807 126.054 145.292 127.591C145.765 128.869 145.946 130.237 145.821 131.595C145.94 132.908 145.766 134.231 145.314 135.47C144.698 137.284 143.664 138.928 142.297 140.269C139.685 142.928 136.327 144.399 131.481 145.01C130.638 145.115 128.183 145.295 126.025 145.407C120.586 145.69 117.006 146.274 113.79 147.404C113.5 147.533 113.189 147.607 112.873 147.623C112.742 147.494 114.932 146.188 116.742 145.317C119.293 144.089 121.832 143.418 127.52 142.471C130.331 142.002 133.233 141.435 133.965 141.208C140.93 139.074 144.508 133.568 143.363 126.752Z" fill="#FF007A" />
<path d="M149.92 138.394C148.07 134.782 147.609 130.618 148.626 126.689C148.737 126.299 148.919 125.975 149.023 125.975C149.38 126.104 149.716 126.286 150.02 126.514C150.899 127.1 152.651 128.096 157.329 130.646C163.166 133.828 166.493 136.292 168.754 139.107C170.76 141.625 172.069 144.624 172.553 147.806C172.849 150.661 172.728 153.545 172.194 156.366C171.524 159.271 170.25 162.003 168.455 164.384C166.66 166.765 164.384 168.741 161.775 170.184C161.284 170.467 160.776 170.72 160.254 170.939C160.38 170.388 160.588 169.859 160.872 169.37C161.733 167.648 162.232 165.768 162.34 163.846C162.448 161.924 162.161 160 161.498 158.192C160.688 155.768 159.036 152.808 155.701 147.807C151.825 141.991 150.875 140.444 149.92 138.394V138.394Z" fill="#FF007A" />
<path d="M96.2148 160.423C101.334 156.031 107.512 153.053 114.137 151.784C117.238 151.383 120.385 151.504 123.446 152.144C127.293 153.129 130.734 155.334 132.528 157.962C134.277 160.529 135.027 162.767 135.808 167.745C136.117 169.709 136.452 171.681 136.554 172.127C136.918 174.324 138.028 176.33 139.697 177.805C141.943 179.45 145.814 179.552 149.62 178.068C150.016 177.883 150.435 177.752 150.866 177.677C151.005 177.814 149.088 179.097 147.735 179.773C146.141 180.627 144.355 181.061 142.546 181.034C139.059 181.034 136.165 179.26 133.75 175.643C132.862 174.115 132.069 172.533 131.376 170.907C128.826 165.09 127.567 163.319 124.606 161.38C123.385 160.554 121.974 160.051 120.505 159.917C119.037 159.784 117.558 160.024 116.208 160.617C115.447 160.97 114.778 161.495 114.254 162.15C113.729 162.805 113.363 163.572 113.184 164.391C113.005 165.211 113.018 166.061 113.222 166.875C113.426 167.689 113.816 168.444 114.36 169.083C115.458 170.226 116.898 170.98 118.462 171.231C119.084 171.314 119.717 171.262 120.318 171.079C120.919 170.895 121.473 170.585 121.943 170.169C122.413 169.753 122.788 169.24 123.043 168.666C123.298 168.092 123.426 167.47 123.42 166.842C123.477 166.077 123.275 165.315 122.847 164.678C122.418 164.041 121.787 163.567 121.057 163.333C118.747 162.291 116.264 163.509 116.271 165.676C116.254 166.099 116.375 166.516 116.617 166.864C116.858 167.211 117.205 167.471 117.607 167.603C118.203 167.873 118.217 167.896 117.73 167.794C115.611 167.354 115.114 164.798 116.819 163.106C118.865 161.07 123.097 161.968 124.55 164.748C125.173 166.312 125.226 168.046 124.699 169.645C124.06 171.194 122.869 172.45 121.356 173.17C119.843 173.89 118.117 174.022 116.512 173.542C114.113 172.929 113.137 172.266 110.244 169.287C105.22 164.111 103.269 163.107 96.0234 161.976L94.6348 161.759L96.2148 160.423Z" fill="#FF007A" />
<path fillRule="evenodd" clipRule="evenodd" d="M75.9078 69.1484C92.6899 89.5165 100.975 96.5507 102.259 98.3261C103.32 99.7909 102.92 101.11 101.104 102.142C99.817 102.794 98.4145 103.187 96.9761 103.299C95.6776 103.247 94.4435 102.72 93.5093 101.816C92.8257 101.17 90.0747 97.0585 83.7193 87.1982C78.857 79.6533 74.7876 73.3935 74.6763 73.289C74.4195 73.0469 74.4244 73.0547 83.2232 88.7998C88.7495 98.6864 90.6187 102.184 90.6187 102.649C90.6187 103.6 90.3589 104.1 89.186 105.408C87.2329 107.59 86.354 110.04 85.7261 115.113C85.0191 120.799 83.0298 124.815 77.5181 131.689C74.2954 135.714 73.7632 136.451 72.9497 138.073C71.9917 139.841 71.5022 141.824 71.5279 143.835C71.364 146.24 71.6861 148.653 72.4751 150.93C73.2719 153.359 74.4304 155.653 75.9117 157.735C77.5913 160.337 78.5581 162.269 78.5581 163.026C78.5581 163.628 78.6734 163.629 81.2759 163.041C87.5044 161.635 92.562 159.161 95.4067 156.13C96.1764 155.451 96.7755 154.602 97.1555 153.649C97.5355 152.696 97.6858 151.667 97.5942 150.645C97.7199 149.617 97.5445 148.575 97.0893 147.644C96.3491 146.07 95.0024 144.761 92.0327 142.73C88.1421 140.07 86.48 137.93 86.021 134.985C85.6441 132.569 86.0816 130.864 88.2339 126.352C90.4614 121.683 91.0142 119.693 91.3872 114.986C91.6284 111.946 91.9624 110.746 92.8364 109.784C93.7476 108.78 94.5679 108.44 96.8227 108.133C100.499 107.631 102.84 106.68 104.764 104.91C105.534 104.267 106.154 103.464 106.582 102.557C107.009 101.65 107.234 100.661 107.24 99.6581L107.318 97.9648L106.384 96.8769C103.004 92.9384 73.646 66.0508 73.438 66.0508C73.3931 66.0508 74.5054 67.4453 75.9078 69.1484V69.1484ZM80.4507 146.923C80.819 146.27 80.9288 145.503 80.7585 144.773C80.5881 144.043 80.15 143.404 79.5308 142.982C78.3238 142.18 76.4488 142.557 76.4488 143.603C76.4488 143.923 76.6255 144.155 77.023 144.359C77.6929 144.704 77.7417 145.092 77.2183 145.884C76.6949 146.676 76.73 147.391 77.3394 147.87C78.3228 148.643 79.7193 148.219 80.4507 146.923V146.923Z" fill="#FF007A" />
<path fillRule="evenodd" clipRule="evenodd" d="M109.635 109.02C108.693 109.379 107.849 109.953 107.168 110.695C106.487 111.438 105.988 112.329 105.712 113.298C105.402 114.586 105.521 115.94 106.05 117.154C106.816 118.179 107.558 118.448 109.565 118.434C113.496 118.407 116.913 116.722 117.311 114.613C117.637 112.886 116.139 110.492 114.068 109.437C112.664 108.864 111.121 108.719 109.635 109.02V109.02ZM114.229 112.613C114.373 112.417 114.47 112.191 114.513 111.952C114.556 111.713 114.544 111.468 114.477 111.234C114.411 111 114.292 110.785 114.129 110.605C113.967 110.424 113.765 110.283 113.54 110.192C111.576 108.993 108.608 109.985 108.608 111.84C108.608 112.763 110.156 113.77 111.575 113.77C112.577 113.747 113.53 113.331 114.229 112.613Z" fill="#FF007A" />
<defs>
<linearGradient id={`${id}__a`} x1="210.75" y1="33.75" x2="51.5003" y2="221.5" gradientUnits="userSpaceOnUse">
<stop stopColor="#B6509E" />
<stop offset="1" stopColor="#2EBAC6" />
</linearGradient>
</defs>
</svg>

);

export default SvgAuni;
