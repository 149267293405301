import usdc from 'assets/img/coins/usdc.png';
import usdt from 'assets/img/coins/usdt.png';
import damm from 'assets/img/coins/damm.png';
import bdamm from 'assets/img/coins/bdamm.png';
import dusdc from 'assets/img/coins/dAssets/dUSDC.png';
import dusdt from 'assets/img/coins/dAssets/dUSDT.png';
// import eth from 'assets/img/coins/eth.png';
// import deth from 'assets/img/coins/ceth.png';
import aave from 'assets/img/coins/aave.png';
import daave from 'assets/img/coins/dAssets/daave.png';
import ageur from 'assets/img/coins/ageur.png';
import dageur from 'assets/img/coins/dAssets/dageur.png';
import angle from 'assets/img/coins/angle.png';
import dangle from 'assets/img/coins/dAssets/dangle.png';
import ape from 'assets/img/coins/ape.png';
import dape from 'assets/img/coins/dAssets/dape.png';
import btrfly from 'assets/img/coins/btrfly.png';
import dbtrfly from 'assets/img/coins/dAssets/dBTRFLY.png';
import crv from 'assets/img/coins/crv.png';
import dcrv from 'assets/img/coins/dAssets/dCRV.png';
import cnv from 'assets/img/coins/cnv.png';
import dcnv from 'assets/img/coins/dAssets/dcnv.png';
import comp from 'assets/img/coins/comp.png';
import dcomp from 'assets/img/coins/dAssets/dcomp.png';
import cvx from 'assets/img/coins/cvx.png';
import dcvx from 'assets/img/coins/dAssets/dCVX.png';
import dai from 'assets/img/coins/DAI.png';
import ddai from 'assets/img/coins/dAssets/dDAI.png';
import frax from 'assets/img/coins/frax.png';
import dfrax from 'assets/img/coins/dAssets/dFRAX.png';
import fxs from 'assets/img/coins/fxs.png';
import dfxs from 'assets/img/coins/dAssets/dFXS.png';
import knc from 'assets/img/coins/knc.png';
import dknc from 'assets/img/coins/dAssets/dKNC.png';
import link from 'assets/img/coins/link.png';
import dlink from 'assets/img/coins/dAssets/dlink.png';
import matic from 'assets/img/coins/matic.png';
import dmatic from 'assets/img/coins/dAssets/dmatic.png';
import rbn from 'assets/img/coins/rbn.png';
import drbn from 'assets/img/coins/dAssets/dRBN.png';
import snx from 'assets/img/coins/snx.png';
import dsnx from 'assets/img/coins/dAssets/dsnx.png';
import steth from 'assets/img/coins/steth.png';
import dsteth from 'assets/img/coins/dAssets/dSTETH.png';
import uni from 'assets/img/coins/uni.png';
import duni from 'assets/img/coins/dAssets/duni.png';
import wbtc from 'assets/img/coins/WBTC.png';
import dwbtc from 'assets/img/coins/dAssets/dWBTC.png';
import weth from 'assets/img/coins/weth.png';
import dweth from 'assets/img/coins/dAssets/dweth.png';
import woo from 'assets/img/coins/woo.png';
import dwoo from 'assets/img/coins/dAssets/dWOO.png';
import adai from 'assets/img/coins/adai.png';
import dadai from 'assets/img/coins/dAssets/dadai.png';
import aeth from 'assets/img/coins/aeth.png';
import daeth from 'assets/img/coins/dAssets/daeth.png';
import alink from 'assets/img/coins/alink.png';
import dalink from 'assets/img/coins/dAssets/dalink.png';
import atusd from 'assets/img/coins/atusd.png';
import datusd from 'assets/img/coins/dAssets/datusd.png';
import auni from 'assets/img/coins/auni.png';
import dauni from 'assets/img/coins/dAssets/dauni.png';
import ausdc from 'assets/img/coins/ausdc.png';
import dausdc from 'assets/img/coins/dAssets/dausdc.png';
import ausdt from 'assets/img/coins/ausdt.png';
import dausdt from 'assets/img/coins/dAssets/dausdt.png';
import awbtc from 'assets/img/coins/awbtc.png';
import dawbtc from 'assets/img/coins/dAssets/dawbtc.png';
import cdai from 'assets/img/coins/cdai.png';
import dcdai from 'assets/img/coins/dAssets/dcdai.png';
import ceth from 'assets/img/coins/ceth.png';
import dceth from 'assets/img/coins/dAssets/dceth.png';
import clink from 'assets/img/coins/clink.png';
import dclink from 'assets/img/coins/dAssets/dclink.png';
import ctusd from 'assets/img/coins/ctusd.png';
import dctusd from 'assets/img/coins/dAssets/dctusd.png';
import cuni from 'assets/img/coins/cuni.png';
import dcuni from 'assets/img/coins/dAssets/dcuni.png';
import cusdc from 'assets/img/coins/cusdc.png';
import dcusdc from 'assets/img/coins/dAssets/dcusdc.png';
import cusdt from 'assets/img/coins/cusdt.png';
import dcusdt from 'assets/img/coins/dAssets/dcusdt.png';
import cwbtc from 'assets/img/coins/cwbtc.png';
import dcwbtc from 'assets/img/coins/dAssets/dcwbtc.png';
import tusd from 'assets/img/coins/tusd.png';
import dtusd from 'assets/img/coins/dAssets/dtusd.png';


import { CHAIN_ID } from 'config';
import GDAMM_JSON from './contracts/addresses/gdamm.json';
import TOKEN_ADDRESSES from './contracts/addresses/tokens.json';
import VBEP_TOKEN_ADDRESSES from './contracts/addresses/cErcTokens.json';

const gdamm = damm;

export const TOKENS = {
  usdc: {
    id: 'usdc',
    symbol: 'USDC',
    decimals: 6,
    address: TOKEN_ADDRESSES.usdc[CHAIN_ID],
    asset: usdc,
    vasset: dusdc,
    assetType: 'base',
  },
  usdt: {
    id: 'usdt',
    symbol: 'USDT',
    decimals: 6,
    address: TOKEN_ADDRESSES.usdt[CHAIN_ID],
    asset: usdt,
    vasset: dusdt,
    assetType: 'base',
  },
  bdamm: {
    id: 'bdamm',
    symbol: 'bdAMM',
    decimals: 18,
    address: TOKEN_ADDRESSES.bdamm[CHAIN_ID],
    asset: bdamm,
    vasset: undefined,
    assetType: 'base',
  },
  damm: {
    id: 'damm',
    symbol: 'dAMM',
    decimals: 18,
    address: TOKEN_ADDRESSES.damm[CHAIN_ID],
    asset: damm,
    vasset: gdamm,
    assetType: 'reward',
  },
  aave: {
    id: 'aave',
    symbol: 'AAVE',
    decimals: 18,
    address: TOKEN_ADDRESSES.aave[CHAIN_ID],
    asset: aave,
    vasset: daave,
    assetType: 'base',
  },
  ageur: {
    id: 'ageur',
    symbol: 'AGEUR',
    decimals: 18,
    address: TOKEN_ADDRESSES.ageur[CHAIN_ID],
    asset: ageur,
    vasset: dageur,
    assetType: 'base',
  },
  angle: {
    id: 'angle',
    symbol: 'ANGLE',
    decimals: 18,
    address: TOKEN_ADDRESSES.angle[CHAIN_ID],
    asset: angle,
    vasset: dangle,
    assetType: 'base',
  },
  ape: {
    id: 'ape',
    symbol: 'APE',
    decimals: 18,
    address: TOKEN_ADDRESSES.ape[CHAIN_ID],
    asset: ape,
    vasset: dape,
    assetType: 'base',
  },
  btrfly: {
    id: 'btrfly',
    symbol: 'BTRFLY',
    decimals: 18,
    address: TOKEN_ADDRESSES.btrfly[CHAIN_ID],
    asset: btrfly,
    vasset: dbtrfly,
    assetType: 'base',
  },
  crv: {
    id: 'crv',
    symbol: 'CRV',
    decimals: 18,
    address: TOKEN_ADDRESSES.crv[CHAIN_ID],
    asset: crv,
    vasset: dcrv,
    assetType: 'base',
  },
  cnv: {
    id: 'cnv',
    symbol: 'CNV',
    decimals: 18,
    address: TOKEN_ADDRESSES.cnv[CHAIN_ID],
    asset: cnv,
    vasset: dcnv,
    assetType: 'base',
  },
  comp: {
    id: 'comp',
    symbol: 'COMP',
    decimals: 18,
    address: TOKEN_ADDRESSES.comp[CHAIN_ID],
    asset: comp,
    vasset: dcomp,
    assetType: 'base',
  },
  cvx: {
    id: 'cvx',
    symbol: 'CVX',
    decimals: 18,
    address: TOKEN_ADDRESSES.cvx[CHAIN_ID],
    asset: cvx,
    vasset: dcvx,
    assetType: 'base',
  },
  dai: {
    id: 'dai',
    symbol: 'DAI',
    decimals: 18,
    address: TOKEN_ADDRESSES.dai[CHAIN_ID],
    asset: dai,
    vasset: ddai,
    assetType: 'base',
  },
  frax: {
    id: 'frax',
    symbol: 'FRAX',
    decimals: 18,
    address: TOKEN_ADDRESSES.frax[CHAIN_ID],
    asset: frax,
    vasset: dfrax,
    assetType: 'base',
  },
  fxs: {
    id: 'fxs',
    symbol: 'FXS',
    decimals: 18,
    address: TOKEN_ADDRESSES.fxs[CHAIN_ID],
    asset: fxs,
    vasset: dfxs,
    assetType: 'base',
  },
  knc: {
    id: 'knc',
    symbol: 'KNC',
    decimals: 18,
    address: TOKEN_ADDRESSES.knc[CHAIN_ID],
    asset: knc,
    vasset: dknc,
    assetType: 'base',
  },
  link: {
    id: 'link',
    symbol: 'LINK',
    decimals: 18,
    address: TOKEN_ADDRESSES.link[CHAIN_ID],
    asset: link,
    vasset: dlink,
    assetType: 'base',
  },
  matic: {
    id: 'matic',
    symbol: 'MATIC',
    decimals: 18,
    address: TOKEN_ADDRESSES.matic[CHAIN_ID],
    asset: matic,
    vasset: dmatic,
    assetType: 'base',
  },
  rbn: {
    id: 'rbn',
    symbol: 'RBN',
    decimals: 18,
    address: TOKEN_ADDRESSES.rbn[CHAIN_ID],
    asset: rbn,
    vasset: drbn,
    assetType: 'base',
  },
  snx: {
    id: 'snx',
    symbol: 'SNX',
    decimals: 18,
    address: TOKEN_ADDRESSES.snx[CHAIN_ID],
    asset: snx,
    vasset: dsnx,
    assetType: 'base',
  },
  steth: {
    id: 'steth',
    symbol: 'STETH',
    decimals: 18,
    address: TOKEN_ADDRESSES.steth[CHAIN_ID],
    asset: steth,
    vasset: dsteth,
    assetType: 'base',
  },
  uni: {
    id: 'uni',
    symbol: 'UNI',
    decimals: 18,
    address: TOKEN_ADDRESSES.uni[CHAIN_ID],
    asset: uni,
    vasset: duni,
    assetType: 'base',
  },
  wbtc: {
    id: 'wbtc',
    symbol: 'WBTC',
    decimals: 8,
    address: TOKEN_ADDRESSES.wbtc[CHAIN_ID],
    asset: wbtc,
    vasset: dwbtc,
    assetType: 'base',
  },
  weth: {
    id: 'weth',
    symbol: 'WETH',
    decimals: 18,
    address: TOKEN_ADDRESSES.weth[CHAIN_ID],
    asset: weth,
    vasset: dweth,
    assetType: 'base',
  },
  woo: {
    id: 'woo',
    symbol: 'WOO',
    decimals: 18,
    address: TOKEN_ADDRESSES.woo[CHAIN_ID],
    asset: woo,
    vasset: dwoo,
    assetType: 'base',
  },
  tusd: {
    id: 'tusd',
    symbol: 'TUSD',
    decimals: 18,
    address: TOKEN_ADDRESSES.tusd[CHAIN_ID],
    asset: tusd,
    vasset: dtusd,
    assetType: 'base',
  },
  ausdc: {
    id: 'ausdc',
    symbol: 'aUSDC',
    decimals: 6,
    address: TOKEN_ADDRESSES.ausdc[CHAIN_ID],
    asset: ausdc,
    vasset: dausdc,
    assetType: 'aave',
  },
  ausdt: {
    id: 'ausdt',
    symbol: 'aUSDT',
    decimals: 6,
    address: TOKEN_ADDRESSES.ausdt[CHAIN_ID],
    asset: ausdt,
    vasset: dausdt,
    assetType: 'aave',
  },
  adai: {
    id: 'adai',
    symbol: 'aDAI',
    decimals: 18,
    address: TOKEN_ADDRESSES.adai[CHAIN_ID],
    asset: adai,
    vasset: dadai,
    assetType: 'aave',
  },
  aeth: {
    id: 'aeth',
    symbol: 'aETH',
    decimals: 18,
    address: TOKEN_ADDRESSES.aeth[CHAIN_ID],
    asset: aeth,
    vasset: daeth,
    assetType: 'aave',
  },
  alink: {
    id: 'alink',
    symbol: 'aLINK',
    decimals: 18,
    address: TOKEN_ADDRESSES.alink[CHAIN_ID],
    asset: alink,
    vasset: dalink,
    assetType: 'aave',
  },
  auni: {
    id: 'auni',
    symbol: 'aUNI',
    decimals: 18,
    address: TOKEN_ADDRESSES.auni[CHAIN_ID],
    asset: auni,
    vasset: dauni,
    assetType: 'aave',
  },
  awbtc: {
    id: 'awbtc',
    symbol: 'aWBTC',
    decimals: 8,
    address: TOKEN_ADDRESSES.awbtc[CHAIN_ID],
    asset: awbtc,
    vasset: dawbtc,
    assetType: 'aave',
  },
  atusd: {
    id: 'atusd',
    symbol: 'aTUSD',
    decimals: 18,
    address: TOKEN_ADDRESSES.atusd[CHAIN_ID],
    asset: atusd,
    vasset: datusd,
    assetType: 'aave',
  },
  cusdc: {
    id: 'cusdc',
    symbol: 'cUSDC',
    decimals: 8,
    address: TOKEN_ADDRESSES.cusdc[CHAIN_ID],
    asset: cusdc,
    vasset: dcusdc,
    assetType: 'comp',
  },
  cusdt: {
    id: 'cusdt',
    symbol: 'cUSDT',
    decimals: 8,
    address: TOKEN_ADDRESSES.cusdt[CHAIN_ID],
    asset: cusdt,
    vasset: dcusdt,
    assetType: 'comp',
  },
  cdai: {
    id: 'cdai',
    symbol: 'cDAI',
    decimals: 8,
    address: TOKEN_ADDRESSES.cdai[CHAIN_ID],
    asset: cdai,
    vasset: dcdai,
    assetType: 'comp',
  },
  ceth: {
    id: 'ceth',
    symbol: 'cETH',
    decimals: 8,
    address: TOKEN_ADDRESSES.ceth[CHAIN_ID],
    asset: ceth,
    vasset: dceth,
    assetType: 'comp',
  },
  clink: {
    id: 'clink',
    symbol: 'cLINK',
    decimals: 8,
    address: TOKEN_ADDRESSES.clink[CHAIN_ID],
    asset: clink,
    vasset: dclink,
    assetType: 'comp',
  },
  cuni: {
    id: 'cuni',
    symbol: 'cUNI',
    decimals: 8,
    address: TOKEN_ADDRESSES.cuni[CHAIN_ID],
    asset: cuni,
    vasset: dcuni,
    assetType: 'comp',
  },
  cwbtc: {
    id: 'cwbtc',
    symbol: 'cWBTC',
    decimals: 8,
    address: TOKEN_ADDRESSES.cwbtc[CHAIN_ID],
    asset: cwbtc,
    vasset: dcwbtc,
    assetType: 'comp',
  },
  ctusd: {
    id: 'ctusd',
    symbol: 'cTUSD',
    decimals: 8,
    address: TOKEN_ADDRESSES.ctusd[CHAIN_ID],
    asset: ctusd,
    vasset: dctusd,
    assetType: 'comp',
  },
};

export const VBEP_TOKEN_DECIMALS = 8;

export const VBEP_TOKENS = {
  usdc: {
    id: 'usdc',
    symbol: 'dUSDC',
    address: VBEP_TOKEN_ADDRESSES.usdc[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  usdt: {
    id: 'usdt',
    symbol: 'dUSDT',
    address: VBEP_TOKEN_ADDRESSES.usdt[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  // eth: {
  //   id: 'eth',
  //   symbol: 'dETH',
  //   address: VBEP_TOKEN_ADDRESSES.eth[CHAIN_ID],
  //   decimals: VBEP_TOKEN_DECIMALS,
  // },
  aave: {
    id: 'aave',
    symbol: 'dAAVE',
    address: VBEP_TOKEN_ADDRESSES.aave[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  ageur: {
    id: 'ageur',
    symbol: 'dAGEUR',
    address: VBEP_TOKEN_ADDRESSES.ageur[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  angle: {
    id: 'angle',
    symbol: 'dANGLE',
    address: VBEP_TOKEN_ADDRESSES.angle[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,

  },
  ape: {
    id: 'ape',
    symbol: 'dAPE',
    address: VBEP_TOKEN_ADDRESSES.ape[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  btrfly: {
    id: 'btrfly',
    symbol: 'dBTRFLY',
    address: VBEP_TOKEN_ADDRESSES.btrfly[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  crv: {
    id: 'crv',
    symbol: 'dCRV',
    address: VBEP_TOKEN_ADDRESSES.crv[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  cnv: {
    id: 'cnv',
    symbol: 'dCNV',
    address: VBEP_TOKEN_ADDRESSES.cnv[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  comp: {
    id: 'comp',
    symbol: 'dCOMP',
    address: VBEP_TOKEN_ADDRESSES.comp[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  cvx: {
    id: 'cvx',
    symbol: 'dCVX',
    address: VBEP_TOKEN_ADDRESSES.cvx[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  dai: {
    id: 'dai',
    symbol: 'dDAI',
    address: VBEP_TOKEN_ADDRESSES.dai[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  frax: {
    id: 'frax',
    symbol: 'dFRAX',
    address: VBEP_TOKEN_ADDRESSES.frax[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  fxs: {
    id: 'fxs',
    symbol: 'dFXS',
    address: VBEP_TOKEN_ADDRESSES.fxs[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  knc: {
    id: 'knc',
    symbol: 'dKNC',
    address: VBEP_TOKEN_ADDRESSES.knc[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  link: {
    id: 'link',
    symbol: 'dLINK',
    address: VBEP_TOKEN_ADDRESSES.link[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  matic: {
    id: 'matic',
    symbol: 'dMATIC',
    address: VBEP_TOKEN_ADDRESSES.matic[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  rbn: {
    id: 'rbn',
    symbol: 'dRBN',
    address: VBEP_TOKEN_ADDRESSES.rbn[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  snx: {
    id: 'snx',
    symbol: 'dSNX',
    address: VBEP_TOKEN_ADDRESSES.snx[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  steth: {
    id: 'steth',
    symbol: 'dSTETH',
    address: VBEP_TOKEN_ADDRESSES.steth[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  uni: {
    id: 'uni',
    symbol: 'dUNI',
    address: VBEP_TOKEN_ADDRESSES.uni[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  wbtc: {
    id: 'wbtc',
    symbol: 'dWBTC',
    address: VBEP_TOKEN_ADDRESSES.wbtc[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  weth: {
    id: 'weth',
    symbol: 'dWETH',
    address: VBEP_TOKEN_ADDRESSES.weth[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  woo: {
    id: 'woo',
    symbol: 'dWOO',
    address: VBEP_TOKEN_ADDRESSES.woo[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  tusd: {
    id: 'tusd',
    symbol: 'dTUSD',
    address: VBEP_TOKEN_ADDRESSES.tusd[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  //
  // boost
  // aave
  //

  ausdc: {
    id: 'ausdc',
    symbol: 'daUSDC',
    address: VBEP_TOKEN_ADDRESSES.ausdc[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  ausdt: {
    id: 'ausdt',
    symbol: 'daUSDT',
    address: VBEP_TOKEN_ADDRESSES.ausdt[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  adai: {
    id: 'adai',
    symbol: 'daDAI',
    address: VBEP_TOKEN_ADDRESSES.adai[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  aeth: {
    id: 'aeth',
    symbol: 'daETH',
    address: VBEP_TOKEN_ADDRESSES.aeth[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  alink: {
    id: 'alink',
    symbol: 'daLINK',
    address: VBEP_TOKEN_ADDRESSES.alink[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  auni: {
    id: 'auni',
    symbol: 'daUNI',
    address: VBEP_TOKEN_ADDRESSES.auni[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  awbtc: {
    id: 'awbtc',
    symbol: 'daWBTC',
    address: VBEP_TOKEN_ADDRESSES.awbtc[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  atusd: {
    id: 'atusd',
    symbol: 'daTUSD',
    address: VBEP_TOKEN_ADDRESSES.atusd[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },

  //
  // comp
  //

  cusdc: {
    id: 'cusdc',
    symbol: 'dcUSDC',
    address: VBEP_TOKEN_ADDRESSES.cusdc[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  cusdt: {
    id: 'cusdt',
    symbol: 'dcUSDT',
    address: VBEP_TOKEN_ADDRESSES.cusdt[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  cdai: {
    id: 'cdai',
    symbol: 'dcDAI',
    address: VBEP_TOKEN_ADDRESSES.cdai[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  ceth: {
    id: 'ceth',
    symbol: 'dcETH',
    address: VBEP_TOKEN_ADDRESSES.ceth[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  clink: {
    id: 'clink',
    symbol: 'dcLINK',
    address: VBEP_TOKEN_ADDRESSES.clink[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  cuni: {
    id: 'cuni',
    symbol: 'dcUNI',
    address: VBEP_TOKEN_ADDRESSES.cuni[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  cwbtc: {
    id: 'cwbtc',
    symbol: 'dcWBTC',
    address: VBEP_TOKEN_ADDRESSES.cwbtc[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  ctusd: {
    id: 'ctusd',
    symbol: 'dcTUSD',
    address: VBEP_TOKEN_ADDRESSES.ctusd[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
  damm: {
    id: 'damm',
    symbol: 'gdAMM',
    address: GDAMM_JSON.gdamm[CHAIN_ID],
    decimals: VBEP_TOKEN_DECIMALS,
  },
};
