import { useQuery, QueryObserverOptions } from 'react-query';

import FunctionKey from 'constants/functionKey';
import { useRedemptionContract } from 'clients/contracts/hooks';
import getRedemptionUSDCPrice, { GetRedemptionPriceOutput } from './getRedemptionUsdcPrice';

type Options = QueryObserverOptions<
  GetRedemptionPriceOutput,
  Error,
  GetRedemptionPriceOutput,
  GetRedemptionPriceOutput,
  FunctionKey.GET_REDEMPTION_PRICE
>;

const useGetRedemptionUSDCPrice = (
  options?: Options,
) => {
  const redemptionContract = useRedemptionContract();

  return useQuery(
    FunctionKey.GET_REDEMPTION_PRICE,
    () => getRedemptionUSDCPrice({ redemptionContract }),
    options,
  );
};

export default useGetRedemptionUSDCPrice;
