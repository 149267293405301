import { MutationObserverOptions, useMutation } from 'react-query';

import {
  queryClient,
  claimBorrowerDammReward,
  IClaimBorrowerDammRewardInput,
  ClaimBorrowerDammRewardOutput,
} from 'clients/api';
import FunctionKey from 'constants/functionKey';
import { useComptrollerContract } from 'clients/contracts/hooks';

type Options = MutationObserverOptions<
  ClaimBorrowerDammRewardOutput,
  Error,
  Omit<IClaimBorrowerDammRewardInput, 'comptrollerContract' | 'venusLensContract'>
>;

const useClaimBorrowerDammReward = (options?: Options) => {
  const comptrollerContract = useComptrollerContract();
  // const venusLensContract = useVenusLensContract();

  return useMutation(
    FunctionKey.CLAIM_BORROWER_DAMM_REWARD,
    (params: Omit<IClaimBorrowerDammRewardInput, 'comptrollerContract' | 'venusLensContract'>) =>
      claimBorrowerDammReward({
        comptrollerContract,
        // venusLensContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: async (...onSuccessParams) => {
        // Trigger refetch of DAMM reward
        await Promise.all([
          queryClient.resetQueries(FunctionKey.GET_BDAMM_ACCRUED),
          queryClient.resetQueries(FunctionKey.GET_DAMM_INITIAL_INDEX),
        ]);

        queryClient.resetQueries(FunctionKey.GET_DAMM_REWARD);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useClaimBorrowerDammReward;
