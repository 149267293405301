import BigNumber from 'bignumber.js';
import type { TransactionReceipt } from 'web3-core';
import { checkForTokenTransactionError } from 'utilities/errors';
import { CErc20 } from 'types/contracts';
import { accountRestService } from 'utilities/accountRestService';
import { toast } from 'react-toastify';
import { VBEP_TOKENS } from 'constants/tokens';


export interface ISupplyNonEthInput {
  tokenContract: CErc20;
  account: string;
  amountWei: BigNumber;
}

export type SupplyNonEthOutput = TransactionReceipt;

const supplyNonEth = async ({
  tokenContract,
  account,
  amountWei,
}: ISupplyNonEthInput): Promise<SupplyNonEthOutput> => {
  const dTokenSymbol = await tokenContract.methods.symbol().call();
  // strip out lowercase chars from the dToken symbol
  // right now only 'd' will be used as prefix; 'c' might get added later
  const underlyingSymbol = dTokenSymbol.toLowerCase() === 'gdamm'
    ? 'damm'
    : dTokenSymbol.replace(/[a-z]/, '').toLowerCase();
  if (!(underlyingSymbol in VBEP_TOKENS)) {
    throw new Error(`${underlyingSymbol} is not in market list`);
  }
  const marketAddress = VBEP_TOKENS[underlyingSymbol as keyof typeof VBEP_TOKENS].address;
  const accountRegistration = await accountRestService({
    method: 'GET',
    params: {
      account,
      market: marketAddress,
    },
  });
  if ('error' in accountRegistration) {
    if (accountRegistration.status === 403) {
      toast.error({ message: 'account not permitted to supply' });
      throw new Error('account not permitted to supply');
    } else {
      toast.error({ message: accountRegistration.error });
      throw new Error('account supply check failed');
    }
  }
  const resp = await tokenContract.methods.mint(amountWei.toFixed()).send({ from: account });
  return checkForTokenTransactionError(resp);
};

export default supplyNonEth;
