import BigNumber from 'bignumber.js';
import { DammToken, Erc20 } from 'types/contracts';

export interface IGetAllowanceInput {
  tokenContract: DammToken | Erc20;
  ownerAddress: string;
  spenderAddress: string;
}

export type GetAllowanceOutput = BigNumber;

const getAllowance = async ({
  tokenContract,
  ownerAddress,
  spenderAddress,
}: IGetAllowanceInput): Promise<GetAllowanceOutput> => {
  const resp = await tokenContract.methods.allowance(ownerAddress, spenderAddress).call();
  return new BigNumber(resp);
};

export default getAllowance;
