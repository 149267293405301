import * as React from 'react';
import { SVGProps } from 'react';

const SvgDusdt = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="125" cy="125" r="125" fill="#D9D9D9" />
    <path d="M246.262 155.989C229.7 222.423 162.414 262.853 95.9724 246.287C29.5589 229.725 -10.8712 162.434 5.69823 96.0059C22.2531 29.5651 89.5395 -10.868 155.96 5.69414C222.397 22.2563 262.824 89.5541 246.26 155.991L246.262 155.989H246.262Z" fill="#50AF95" />
    <path fillRule="evenodd" clipRule="evenodd" d="M141.684 140.05V140.05C140.807 140.116 136.277 140.386 126.171 140.386C118.133 140.386 112.426 140.145 110.424 140.05V140.05C79.362 138.683 56.1766 133.276 56.1766 126.802C56.1766 120.328 79.362 114.928 110.424 113.54V134.664C112.456 134.811 118.272 135.154 126.31 135.154C135.955 135.154 140.785 134.752 141.655 134.672V113.554C172.651 114.935 195.786 120.343 195.786 126.802C195.786 133.262 172.659 138.669 141.655 140.042V140.042L141.684 140.05ZM141.684 111.369V92.466H184.942V63.6396H67.1664V92.466H110.417V111.362C75.2627 112.977 48.8257 119.941 48.8257 128.285C48.8257 136.63 75.2627 143.586 110.417 145.208V205.784H141.677V145.187C176.751 143.572 203.144 136.615 203.144 128.278C203.144 119.941 176.772 112.984 141.677 111.362V111.362L141.684 111.369Z" fill="white" />
    <rect x="25" y="121" width="100" height="100" rx="50" fill={`url(#${id}__a`} />
    <path d="M87.75 204H81.5V197.75H75.25V204H69H62.75H56.5V197.75H50.25V191.5V185.25V179V172.75V166.5V160.25H56.5V154H62.75H69H75.25V160.25H81.5V154V147.75V141.5V135.25H87.75H94V141.5V147.75V154V160.25V166.5V172.75V179V185.25V191.5V197.75V204H87.75ZM69 191.5H75.25V185.25H81.5V179V172.75H75.25V166.5H69H62.75V172.75V179V185.25V191.5H69Z" fill={`url(#${id}__b`} />
    <defs>
      <radialGradient
        id={`${id}__a`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(50.6 149) rotate(71.2791) scale(76.0221)"
      >
        <stop stopColor="#66A9F7" />
        <stop offset={1} stopColor="#0075FF" />
      </radialGradient>
      <radialGradient
        id={`${id}__b`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(80.0548 138.253) rotate(75.4706) scale(85.4812 97.7006)"
      >
        <stop stopColor="#334155" />
        <stop offset={1} stopColor="#050A30" />
      </radialGradient>
    </defs>
  </svg>
);

export default SvgDusdt;
