import BigNumber from 'bignumber.js';
import { Redemption } from 'types/contracts';

export interface IGetRedemptionPriceInput {
  redemptionContract: Redemption;
}

export type GetRedemptionPriceOutput = BigNumber;

const getRedemptionUSDCPrice = async ({
  redemptionContract,
}: IGetRedemptionPriceInput): Promise<GetRedemptionPriceOutput> => {
  const resp = await redemptionContract.methods.redemptionUSDCPrice().call();
  return new BigNumber(resp);
};

export default getRedemptionUSDCPrice;
