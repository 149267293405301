/* eslint-disable react/no-unescaped-entities */
/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import { Paper, Typography } from '@mui/material';
import {
  EllipseText,
  Icon,
  PrimaryButton,
  TertiaryButton,
  Tooltip,
} from 'components';
import copy from 'copy-to-clipboard';
import { getToken, generateEtherScanUrl } from 'utilities';
import {
  convertCoinsToWei,
  formatToReadablePercentage } from 'utilities/common';
import { useTranslation } from 'translation';
import { MarketContext } from 'context/MarketContext';
import { useDiscountedRedemption, useGetRedemptionUsdcPrice } from 'clients/api';
import useSuccessfulTransactionModal from 'hooks/useSuccessfulTransactionModal';
import BigNumber from 'bignumber.js';
import { AuthContext } from 'context/AuthContext';
import { IAmountAndFeeFormProps } from 'containers/AmountAndFeeForm';
import { useStyles } from '../styles';
import { RedemptionModal } from './RedemptionModal';


interface IRedemptionProps {
  className?: string;
  // TODO:DAMM (?) use onClose?
  // onClose: IModalProps['handleClose'];
}

export const Redemption: React.FC<IRedemptionProps> = ({
  className,
  // onClose,
}) => {
  const styles = useStyles();
  const dammAddress = getToken('damm').address;
  const copyAddress = () => copy(dammAddress);
  const { t } = useTranslation();

  const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();
  const { dammPrice } = useContext(MarketContext);
  const { data: redemptionUSDCPrice = new BigNumber(1) } = useGetRedemptionUsdcPrice();
  const redemptionUSDCPriceFormatted = redemptionUSDCPrice.shiftedBy(-6).toNumber();
  const discountRate = (((1 - (redemptionUSDCPriceFormatted / dammPrice)) * 100) > 0)
  ? formatToReadablePercentage((1 - (redemptionUSDCPriceFormatted / dammPrice)) * 100)
  : 0;

  const { account } = useContext(AuthContext);

  const [isRedeemModalOpen, setIsRedeemModalOpen] = React.useState(false);
  const openRedeemModal = () => setIsRedeemModalOpen(true);
  const closeRedeemModal = () => setIsRedeemModalOpen(false);

  const { mutateAsync: redemption, isLoading: isRedemptionLoading } =
    useDiscountedRedemption({
      account: account?.address || '',
    });
  const onSubmit: IAmountAndFeeFormProps['onSubmit'] = async value => {
    const supplyAmount = new BigNumber(value.redeemAmount).times(new BigNumber(10).pow(18));
    const usdcAmount = new BigNumber(value.feeAmount).times(new BigNumber(10).pow(6));
    const res = await redemption({
      amountWei: supplyAmount,
      redemptionFee: usdcAmount,
    });
    // onClose();
    closeRedeemModal();

    openSuccessfulTransactionModal({
      title: t('supplyWithdraw.successfulRedeemTransactionModal.title'),
      content: t('supplyWithdraw.successfulRedeemTransactionModal.message'),
      amount: {
        valueWei: convertCoinsToWei({ value: new BigNumber(value.redeemAmount), tokenId: 'damm' }),
        tokenId: 'damm',
      },
      transactionHash: res.transactionHash,
    });
  };


  return (
    <>
      <Paper className={className}>
        <div css={styles.headerRoot}>
          <div>
            <h3 css={styles.whiteText}>
              Redemption:
            </h3>
            <p>
              Redeem your bdAMM for dAMM Tokens at a discount of market price.
            </p>
          </div>
          { discountRate > 0 ?
            <PrimaryButton onClick={openRedeemModal}>
              Redeem bdAMM for dAMM
            </PrimaryButton>
            :
            <Tooltip title="Redemptions Disabled">
              <TertiaryButton>
                Redeem bdAMM for dAMM
              </TertiaryButton>
            </Tooltip>
          }
          <RedemptionModal
            isOpen={isRedeemModalOpen}
            onClose={closeRedeemModal}
            bdammAddress=""
            swapContractAddress=""
            title="Enable Contract to Redeem bdAMM"
            onSubmit={onSubmit}
            enabledButtonKey="Redeem bdAMM"
            disabledButtonKey="Enter bdAMM to redeem"
            isTransactionLoading={isRedemptionLoading}
          />
        </div>
        <div css={styles.headerRoot}>
          <EllipseText css={styles.address} text={dammAddress}>
            <div css={styles.dammIconContainer}>
              <Icon name="damm" size={styles.iconSizeXl} />
            </div>
            <div>
              <Icon name="dammtype" css={styles.dammTypeContainer} />
            </div>
            <Typography
              className="ellipse-text"
              href={generateEtherScanUrl('damm', 'token')}
              target="_blank"
              rel="noreferrer"
              variant="small2"
              component="a"
              css={[styles.whiteText, styles.addressText]}
            />
            <div css={styles.copyIconContainer}>
              <Tooltip title="Copy Address">
                <Icon name="copy" onClick={copyAddress} css={styles.copyIcon} size={styles.iconSizeXl} />
              </Tooltip>
            </div>
          </EllipseText>
        </div>
        <div css={styles.headerRoot}>
          <div css={styles.itemContainer}>
            <div css={styles.rowItem}>
              <Typography component="span" variant="small2" css={styles.itemLabel}>
                {t('dAMM Price')}
              </Typography>
            </div>

            <Typography
              variant="h2"
              component="span"
            >
              ${dammPrice}
            </Typography>
          </div>
          <ul css={styles.list}>
            <Typography component="li" variant="h2" css={styles.item}>
              <Typography component="span" variant="small2" css={styles.labelListItem}>
                <p>Discount Rate</p>
              </Typography>
              {discountRate}
            </Typography>
          </ul>
        </div>
      </Paper>
    </>
  );
};

export default Redemption;
