import * as React from 'react';
import { SVGProps } from 'react';

const SvgDgt = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath={`url(#${id}__c`}>
      <circle cx="125" cy="125" r="75" fill="white" />
      <path d="M103.646 0.981842C61.8803 8.08625 24.6052 37.9699 8.7745 76.875C1.47666 95.0307 0.0170898 102.924 0.0170898 125.027C0.0170898 141.717 0.353913 146.227 2.48713 154.009C14.5005 200.131 47.1724 233.961 91.5208 246.366C100.166 248.734 105.331 249.411 120.039 249.862C146.311 250.764 161.468 247.381 183.474 235.991C220.3 216.933 246.01 178.254 249.491 136.868C252.635 99.5414 240.734 65.0343 215.696 38.5337C197.62 19.3631 173.93 6.50749 147.77 1.43292C138.901 -0.25861 112.628 -0.484147 103.646 0.981842ZM148.556 69.8833C178.534 87.1369 179.319 87.7007 178.084 89.2795C177.411 90.0689 172.358 92.8881 166.745 95.5945L156.64 100.669L140.697 91.4221C131.94 86.3475 123.744 81.8368 122.396 81.6112C120.824 81.1602 114.2 84.4304 102.075 91.4221L84.1107 101.91L83.7739 123.448C83.5493 142.844 83.6616 145.1 85.458 146.679C86.5807 147.693 95.1136 152.881 104.432 158.294L121.498 168.105L137.553 158.745C161.019 145.325 159.896 146.34 159.896 139.01V132.921L154.282 135.74C151.139 137.319 148.107 138.334 147.546 137.995C146.872 137.544 146.423 132.019 146.423 125.478V113.75L166.632 103.601C177.748 98.0754 187.066 93.4519 187.403 93.4519C187.74 93.4519 187.965 98.9776 187.965 105.631C187.965 117.584 187.852 117.923 185.158 119.389C182.351 120.855 182.351 120.967 182.351 136.529C182.351 154.234 181.34 158.181 175.502 162.466C173.369 164.045 160.345 171.713 146.648 179.607L121.498 194.041L93.3172 177.803C70.0764 164.27 64.7995 160.775 63.1154 157.617C61.319 154.234 61.0944 150.625 61.0944 121.418V89.1667L86.1316 74.7324C119.814 55.2234 118.242 56.0128 122.733 56.6894C124.866 57.0277 136.543 63.0044 148.556 69.8833Z" fill="#D45657" />
    </g>
    <rect x="25" y="121" width="100" height="100" rx="50" fill={`url(#${id}__a`} />
    <path d="M87.75 204H81.5V197.75H75.25V204H69H62.75H56.5V197.75H50.25V191.5V185.25V179V172.75V166.5V160.25H56.5V154H62.75H69H75.25V160.25H81.5V154V147.75V141.5V135.25H87.75H94V141.5V147.75V154V160.25V166.5V172.75V179V185.25V191.5V197.75V204H87.75ZM69 191.5H75.25V185.25H81.5V179V172.75H75.25V166.5H69H62.75V172.75V179V185.25V191.5H69Z" fill={`url(#${id}__b`} />
    <defs>
      <radialGradient
        id={`${id}__a`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(50.6 149) rotate(71.2791) scale(76.0221)"
      >
        <stop stopColor="#66A9F7" />
        <stop offset={1} stopColor="#0075FF" />
      </radialGradient>
      <radialGradient
        id={`${id}__b`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(80.0548 138.253) rotate(75.4706) scale(85.4812 97.7006)"
      >
        <stop stopColor="#334155" />
        <stop offset={1} stopColor="#050A30" />
      </radialGradient>
      <clipPath id={`${id}__c`}>
        <rect width="250" height="250" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgDgt;
