import React, { useContext } from 'react';
import BigNumber from 'bignumber.js';
import { ICErcToken } from 'types';
import { getToken } from 'utilities';
import { convertWeiToCoins } from 'utilities/common';
import { VTOKEN_DECIMALS } from 'config';
import { useGetBlock, useGetMarkets } from 'clients/api';
import { BLOCKS_PER_DAY } from 'constants/blocksPerDay';
import { BLOCKS_PER_YEAR } from 'constants/blocksPerYear';
import { MarketContext } from 'context/MarketContext';

const useGetMarketData = ({
  cTokenId,
  cTokenAddress,
}: {
  cTokenId: ICErcToken['id'];
  cTokenAddress: ICErcToken['address'];
}) => {
  const { data: blockNumber } = useGetBlock();
  const { data: getMarketData } = useGetMarkets({
    meta: {
      blockNumber,
    },
  });
  const assetMarket = (getMarketData?.markets || []).find(
    market => market.address.toLowerCase() === cTokenAddress.toLowerCase(),
  );
  const { bdammPrice } = useContext(MarketContext);

  const props = React.useMemo(() => {
    const totalBorrowBalanceCents = assetMarket && +assetMarket.totalBorrowsUsd * 100;
    const totalSupplyBalanceCents = assetMarket && +assetMarket.totalSupplyUsd * 100;
    const borrowApyPercentage = assetMarket?.borrowApy;
    const supplyApyPercentage = assetMarket && +assetMarket.supplyApy;
    const bdammBorrowApy = ((new BigNumber(assetMarket?.borrowerDailyBdamm || 0).times(BLOCKS_PER_YEAR).times(bdammPrice))
    .div(new BigNumber(10).pow(getToken('bdamm').decimals)))
    .div(assetMarket?.totalBorrowsUsd || 0)
    .times(100) || 0;
    const bdammSupplyApy = ((new BigNumber(assetMarket?.supplierDailyBdamm || 0).times(BLOCKS_PER_YEAR).times(bdammPrice))
    .div(new BigNumber(10).pow(getToken('bdamm').decimals)))
    .div(assetMarket?.totalSupplyUsd || 0)
    .times(100) || 0;
    const tokenPriceDollars = assetMarket && +assetMarket.tokenPrice;
    const marketLiquidityTokens = assetMarket && new BigNumber(assetMarket.liquidity);
    const supplierCount = assetMarket?.supplierCount;
    const borrowerCount = assetMarket?.borrowerCount;
    const borrowCapCents = assetMarket && +assetMarket.borrowCaps * +assetMarket.tokenPrice * 100;
    // DAMM: This is changed because we're using totalSupply2 like the *underlying* totalSupply
    const mintedTokens = assetMarket && new BigNumber(assetMarket.totalSupply).shiftedBy(-8);
    const totalSupplyCoins = assetMarket && new BigNumber(assetMarket?.totalSupply2 || 0).toNumber();
    const ratePerBlock = new BigNumber(assetMarket?.supplyRatePerBlock || 0).shiftedBy(-18);
    const dailyInterestRate = ratePerBlock.isZero() ? new BigNumber(0) : (new BigNumber(ratePerBlock)).plus(1).pow(BLOCKS_PER_DAY).minus(1);
    const dailyInterestsPaid = (dailyInterestRate).times(totalSupplyCoins || 0);
    const dailyInterestsCoins = new BigNumber(dailyInterestsPaid);
    const dailyInterestsCents = new BigNumber(dailyInterestsCoins).times(tokenPriceDollars || 0).times(100);
    const bdammPerDay = (new BigNumber(assetMarket?.supplierDailyBdamm || 0).times(BLOCKS_PER_DAY))
    .plus(new BigNumber(assetMarket?.borrowerDailyBdamm || 0).times(BLOCKS_PER_DAY))
    .div(new BigNumber(10).pow(getToken('bdamm').decimals));

    const reserveFactor =
      assetMarket &&
      convertWeiToCoins({
        valueWei: new BigNumber(assetMarket.reserveFactor),
        tokenId: cTokenId,
      })
        // Convert to percentage
        .multipliedBy(100)
        .toNumber();

    const collateralFactor =
      assetMarket &&
      convertWeiToCoins({
        valueWei: new BigNumber(assetMarket.collateralFactor),
        tokenId: cTokenId,
      })
        // Convert to percentage
        .multipliedBy(100)
        .toNumber();

    const reserveTokens =
      assetMarket &&
      convertWeiToCoins({
        valueWei: new BigNumber(assetMarket.totalReserves),
        tokenId: cTokenId,
      });

    const exchangeRateCTokens =
      assetMarket &&
      new BigNumber(1).div(
        new BigNumber(assetMarket.exchangeRate).div(
          new BigNumber(10).pow(18 + getToken(cTokenId).decimals - VTOKEN_DECIMALS),
        ),
      );

    // const currentUtilizationRate = 0;

    const supply = new BigNumber(totalSupplyBalanceCents || 0);
    const borrow = new BigNumber(totalBorrowBalanceCents || 0);
    const currentUtilizationRate = (borrow.div(supply)).times(100).toNumber();

    return {
      totalBorrowBalanceCents,
      totalSupplyBalanceCents,
      borrowApyPercentage,
      supplyApyPercentage,
      bdammBorrowApy,
      bdammSupplyApy,
      bdammPerDay,
      tokenPriceDollars,
      marketLiquidityTokens,
      supplierCount,
      borrowerCount,
      borrowCapCents,
      mintedTokens,
      dailyInterestsCents,
      dailyInterestsCoins,
      reserveFactor,
      collateralFactor,
      reserveTokens,
      exchangeRateCTokens,
      currentUtilizationRate,
    };
  }, [JSON.stringify(assetMarket)]);

  return props;
};

export default useGetMarketData;
