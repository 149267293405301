import * as React from 'react';
import { SVGProps } from 'react';

const SvgGt = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath={`url(#${id}__a`}>
      <circle cx="125" cy="125" r="75" fill="white" />
      <path d="M103.646 0.981842C61.8798 8.08625 24.6047 37.9699 8.77401 76.875C1.47617 95.0307 0.0166016 102.924 0.0166016 125.027C0.0166016 141.717 0.353425 146.227 2.48664 154.009C14.5 200.131 47.1719 233.961 91.5203 246.366C100.165 248.734 105.33 249.411 120.038 249.862C146.31 250.764 161.467 247.381 183.473 235.991C220.299 216.933 246.01 178.254 249.491 136.868C252.634 99.5414 240.733 65.0343 215.696 38.5337C197.62 19.3631 173.93 6.50749 147.77 1.43292C138.9 -0.25861 112.628 -0.484147 103.646 0.981842ZM148.556 69.8833C178.533 87.1369 179.319 87.7007 178.084 89.2795C177.41 90.0689 172.358 92.8881 166.744 95.5945L156.64 100.669L140.697 91.4221C131.939 86.3475 123.743 81.8368 122.396 81.6112C120.824 81.1602 114.2 84.4304 102.074 91.4221L84.1102 101.91L83.7734 123.448C83.5488 142.844 83.6611 145.1 85.4575 146.679C86.5802 147.693 95.1131 152.881 104.432 158.294L121.498 168.105L137.553 158.745C161.018 145.325 159.895 146.34 159.895 139.01V132.921L154.282 135.74C151.138 137.319 148.107 138.334 147.545 137.995C146.872 137.544 146.423 132.019 146.423 125.478V113.75L166.632 103.601C177.747 98.0754 187.066 93.4519 187.403 93.4519C187.74 93.4519 187.964 98.9776 187.964 105.631C187.964 117.584 187.852 117.923 185.157 119.389C182.35 120.855 182.35 120.967 182.35 136.529C182.35 154.234 181.34 158.181 175.502 162.466C173.368 164.045 160.345 171.713 146.647 179.607L121.498 194.041L93.3167 177.803C70.0759 164.27 64.799 160.775 63.1149 157.617C61.3185 154.234 61.0939 150.625 61.0939 121.418V89.1667L86.1311 74.7324C119.813 55.2234 118.242 56.0128 122.733 56.6894C124.866 57.0277 136.542 63.0044 148.556 69.8833Z" fill="#D45657" />
    </g>
    <defs>
      <clipPath id={`url(#${id}__a`}>
        <rect width="250" height="250" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default SvgGt;
