import type { TransactionReceipt } from 'web3-core';
import { Comptroller } from 'types/contracts';
import { checkForComptrollerTransactionError } from 'utilities/errors';

export interface IExitMarketInput {
  comptrollerContract: Comptroller;
  accountAddress?: string;
  ctokenAddress: string;
}

export type ExitMarketOutput = TransactionReceipt;

const exitMarket = async ({
  comptrollerContract,
  accountAddress,
  ctokenAddress,
}: IExitMarketInput): Promise<ExitMarketOutput> => {
  const resp = await comptrollerContract.methods
    .exitMarket(ctokenAddress)
    .send({ from: accountAddress });
  return checkForComptrollerTransactionError(resp);
};

export default exitMarket;
