import * as React from 'react';
import { SVGProps } from 'react';

const SvgDageur = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="125" cy="125" r="123.5" fill={`url(#${id}__a`} stroke="black" strokeWidth="3" />
    <path
      d="M45 104.818L125 24L205 104.818L164.999 145.227L125 104.818L85 145.227L45 104.818Z"
      fill="white"
      stroke="#131620"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M77.7969 204V169.641H103.273V175.453H84.7344V183.07H101.984V188.859H84.7344V198.211H103.93V204H77.7969ZM109.766 169.641H116.703V188.25C116.703 191.203 116.789 193.117 116.961 193.992C117.258 195.398 117.961 196.531 119.07 197.391C120.195 198.234 121.727 198.656 123.664 198.656C125.633 198.656 127.117 198.258 128.117 197.461C129.117 196.648 129.719 195.656 129.922 194.484C130.125 193.312 130.227 191.367 130.227 188.648V169.641H137.164V187.688C137.164 191.812 136.977 194.727 136.602 196.43C136.227 198.133 135.531 199.57 134.516 200.742C133.516 201.914 132.172 202.852 130.484 203.555C128.797 204.242 126.594 204.586 123.875 204.586C120.594 204.586 118.102 204.211 116.398 203.461C114.711 202.695 113.375 201.711 112.391 200.508C111.406 199.289 110.758 198.016 110.445 196.688C109.992 194.719 109.766 191.812 109.766 187.969V169.641ZM144.523 204V169.641H159.125C162.797 169.641 165.461 169.953 167.117 170.578C168.789 171.188 170.125 172.281 171.125 173.859C172.125 175.438 172.625 177.242 172.625 179.273C172.625 181.852 171.867 183.984 170.352 185.672C168.836 187.344 166.57 188.398 163.555 188.836C165.055 189.711 166.289 190.672 167.258 191.719C168.242 192.766 169.562 194.625 171.219 197.297L175.414 204H167.117L162.102 196.523C160.32 193.852 159.102 192.172 158.445 191.484C157.789 190.781 157.094 190.305 156.359 190.055C155.625 189.789 154.461 189.656 152.867 189.656H151.461V204H144.523ZM151.461 184.172H156.594C159.922 184.172 162 184.031 162.828 183.75C163.656 183.469 164.305 182.984 164.773 182.297C165.242 181.609 165.477 180.75 165.477 179.719C165.477 178.562 165.164 177.633 164.539 176.93C163.93 176.211 163.062 175.758 161.938 175.57C161.375 175.492 159.688 175.453 156.875 175.453H151.461V184.172Z"
      fill="#283439"
    />
    <rect x="25" y="119" width="100" height="100" rx="50" fill={`url(#${id}__b`} />
    <path d="M87.75 202H81.5V195.75H75.25V202H69H62.75H56.5V195.75H50.25V189.5V183.25V177V170.75V164.5V158.25H56.5V152H62.75H69H75.25V158.25H81.5V152V145.75V139.5V133.25H87.75H94V139.5V145.75V152V158.25V164.5V170.75V177V183.25V189.5V195.75V202H87.75ZM69 189.5H75.25V183.25H81.5V177V170.75H75.25V164.5H69H62.75V170.75V177V183.25V189.5H69Z" fill={`url(#${id}__c`} />
    <defs>
        <linearGradient id={`${id}__a`} x1="125" y1="0" x2="125" y2="250" gradientUnits="userSpaceOnUse">
            <stop stopColor="#9BBA98" />
            <stop offset="0.510417" stopColor="#CCC4A1" />
            <stop offset="1" stopColor="#FBCEAA" />
        </linearGradient>
        <radialGradient id={`${id}__b`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(50.6 147) rotate(71.2791) scale(76.0221)">
            <stop stopColor="#66A9F7" />
            <stop offset="1" stopColor="#0075FF" />
        </radialGradient>
        <radialGradient id={`${id}__c`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(80.0548 136.987) rotate(76.1548) scale(89.6165 97.9959)">
            <stop stopColor="#334155" />
            <stop offset="1" stopColor="#050A30" />
        </radialGradient>
    </defs>
  </svg>
);

export default SvgDageur;
