import * as React from 'react';
import { SVGProps } from 'react';

const SvgDperp = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M108.785 1.03766C90.8103 3.41913 73.297 9.14537 57.8857 18.8417C43.9569 27.6055 31.955 38.9974 22.3833 52.3489C13.8983 64.1846 8.0063 77.2052 3.99212 91.1489C0.750681 102.409 0 113.321 0 124.975C0 139.804 1.66219 153.686 6.6851 167.754C26.4452 223.1 83.8583 256.133 141.72 249.049C158.717 246.968 176.113 240.753 190.624 231.74C208.004 220.944 222.608 206.204 233.087 188.647C263.58 137.551 251.708 68.3133 206.592 29.7654C192.296 17.5511 175.691 9.40532 157.689 4.30631C142.457 -0.00796221 124.516 -1.04654 108.785 1.03766Z" fill="white" />
<path d="M113 188.619C111.919 178.331 118.149 173.47 125 166.619C122.205 162.652 118.797 159.356 115.17 156.133C113.48 154.632 110.727 152.878 110.167 150.526C108.627 144.053 121.193 139.48 122.654 133.619C123.366 130.766 120.733 128.464 118.98 126.619C115.217 122.657 111.155 119.098 108 114.619C103.523 117.773 99.9369 121.821 96.0002 125.61C94.4175 127.133 92.4226 129.563 90.0002 129.563C87.5778 129.563 85.583 127.133 84.0002 125.61C80.3953 122.14 77.0985 118.506 73.0002 115.619L98.0002 90.6198C100.395 88.2368 104.15 83.1384 108 83.5048C113.605 84.0382 120.973 94.3222 124 98.619C128.799 95.2386 132.938 90.8746 136.98 86.619C138.502 85.0167 140.793 83.0411 140.793 80.619C140.793 76.8329 135.439 73.0139 133 70.6337C125.125 62.9478 116.873 54.5596 105 55.7934C92.9758 57.0428 83.0854 69.5468 75.0002 77.619C66.0483 86.5565 49.9217 97.5353 47.4693 110.619C45.1859 122.802 53.1047 130.723 61.0002 138.619C72.5638 150.183 84.212 161.578 96.0897 172.803C101.682 178.088 106.697 184.179 113 188.619Z" fill="#3CEAAA" />
<path d="M125 97.6193C128.103 102.024 131.98 105.615 135.995 109.195C137.803 110.807 140.741 112.823 140.369 115.623C139.449 122.544 127.207 125.433 127.207 132.619C127.207 139.638 141.681 143.43 139.793 150.619C138.992 153.668 135.275 156.368 133.09 158.435C125.595 165.524 106.121 177.98 114.603 190.575C118.052 195.697 125.614 197.066 131 194.301C140.95 189.194 148.74 177.388 156.961 169.908C168.008 159.856 178.441 149.178 189 138.619C195.193 132.426 201.742 126.827 202.815 117.619C204.205 105.686 195.694 98.3135 188 90.6193C179.24 81.8595 169.88 70.5315 156 73.0877C143.163 75.4518 133.72 88.8993 125 97.6193Z" fill="#03BCE4" />
<path d="M124 99.9646C119.689 101.039 114.917 107.428 112.063 110.619C110.825 112.004 109.207 113.612 109.207 115.619C109.207 119.119 113.777 122.396 116 124.619C118.358 126.977 122.111 132.243 126 131.273C130.504 130.15 135.992 123.077 138.847 119.619C140.017 118.202 141.317 116.602 141.079 114.634C140.666 111.222 136.288 108.661 134 106.529C131.548 104.246 127.904 98.9914 124 99.9646Z" fill="#00A6E2" />
<path d="M143 114.619C146.064 118.968 154.863 131.176 161 129.867C166.877 128.615 172.895 119.724 177 115.619C173.865 111.169 165.293 99.7747 159 100.675C153.184 101.508 146.957 110.662 143 114.619Z" fill="white" />
    <rect x="25" y="121" width="100" height="100" rx="50" fill={`url(#${id}__a`} />
    <path d="M87.75 204H81.5V197.75H75.25V204H69H62.75H56.5V197.75H50.25V191.5V185.25V179V172.75V166.5V160.25H56.5V154H62.75H69H75.25V160.25H81.5V154V147.75V141.5V135.25H87.75H94V141.5V147.75V154V160.25V166.5V172.75V179V185.25V191.5V197.75V204H87.75ZM69 191.5H75.25V185.25H81.5V179V172.75H75.25V166.5H69H62.75V172.75V179V185.25V191.5H69Z" fill={`url(#${id}__b`} />
    <defs>
      <radialGradient
        id={`${id}__a`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(50.6 149) rotate(71.2791) scale(76.0221)"
      >
        <stop stopColor="#66A9F7" />
        <stop offset={1} stopColor="#0075FF" />
      </radialGradient>
      <radialGradient
        id={`${id}__b`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(80.0548 138.253) rotate(75.4706) scale(85.4812 97.7006)"
      >
        <stop stopColor="#334155" />
        <stop offset={1} stopColor="#050A30" />
      </radialGradient>
    </defs>
  </svg>
);

export default SvgDperp;
