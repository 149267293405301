import * as React from 'react';
import { SVGProps } from 'react';

const SvgDbtrfly = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg width="250" height="250" viewBox="0 0 251 251" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M125.101 248.656C193.412 248.656 248.79 193.279 248.79 124.967C248.79 56.655 193.412 1.27734 125.101 1.27734C56.7888 1.27734 1.41113 56.655 1.41113 124.967C1.41113 193.279 56.7888 248.656 125.101 248.656Z" fill="black" />
    <path d="M126.982 118.985L128.461 118.078L129.771 113.877L131.518 110.416L189.818 69.5898H212.096L207.358 109.005L182.527 129.301L185.551 129.939L188.676 154.704L161.458 190.322L142.742 166.834L134.744 175.168L131.115 180.208L125.571 175.067L120.027 180.208L116.364 175.168L108.367 166.834L89.6838 190.322L62.4324 154.704L65.5574 129.939L68.5816 129.301L43.7496 109.005L39.0117 69.5898H61.2899L119.59 110.416L121.337 113.877L122.647 118.078L124.126 118.985L125.571 125.403L126.982 118.985Z" fill="#F6F6F6" />
    <path d="M143.783 162.802L166.028 125.739L168.985 120.833L143.783 162.802Z" fill="#E4E5E6" />
    <path d="M189.819 69.5898L129.771 113.877L168.985 120.833L189.819 69.5898Z" fill="#FBFBFB" />
    <path d="M128.058 119.288L128.461 118.078L126.982 118.985L131.115 180.209L134.745 175.168L140.927 162.399L128.058 119.288Z" fill="#F6F6F6" />
    <path d="M207.359 109.005L212.097 69.5898H189.819L168.985 120.833L166.028 125.739L182.527 129.301L207.359 109.005Z" fill="#F6F6F6" />
    <path d="M143.784 162.803L168.985 120.834L129.771 113.878L128.461 118.078L143.784 162.803Z" fill="#FBFBFB" />
    <path d="M142.238 165.356L143.783 162.803L128.461 118.078L128.058 119.288L140.927 162.399L142.238 165.356Z" fill="#FBFBFB" />
    <path d="M143.784 162.802L142.238 165.356L142.742 166.835L161.459 190.323L188.676 154.704L185.551 129.94L166.029 125.739L143.784 162.802Z" fill="#F6F6F6" />
    <path d="M125.571 125.403V175.067L131.116 180.208L126.983 118.985L125.571 125.403Z" fill="#F3F3F4" />
    <path d="M134.744 175.168L142.741 166.835L142.237 165.356L140.927 162.399L134.744 175.168Z" fill="#E4E5E6" />
    <path d="M107.326 162.802L85.081 125.739L82.124 120.833L107.326 162.802Z" fill="#E4E5E6" />
    <path d="M61.29 69.5898L121.337 113.877L82.1234 120.833L61.29 69.5898Z" fill="#FBFBFB" />
    <path d="M123.051 119.288L122.648 118.078L124.127 118.985L120.027 180.209L116.364 175.168L110.182 162.399L123.051 119.288Z" fill="#F6F6F6" />
    <path d="M43.7496 109.005L39.0117 69.5898H61.2899L82.1233 120.833L85.0803 125.739L68.5816 129.301L43.7496 109.005Z" fill="#F6F6F6" />
    <path d="M107.326 162.803L82.124 120.834L121.338 113.878L122.648 118.078L107.326 162.803Z" fill="#FBFBFB" />
    <path d="M108.871 165.356L107.325 162.803L122.648 118.078L123.051 119.288L110.181 162.399L108.871 165.356Z" fill="#F7F7F8" />
    <path d="M107.325 162.802L108.871 165.356L108.367 166.835L89.684 190.323L62.4326 154.704L65.5576 129.94L85.0805 125.739L107.325 162.802Z" fill="#F6F6F6" />
    <path d="M125.572 125.403V175.067L120.027 180.208L124.127 118.985L125.572 125.403Z" fill="#FBFBFB" />
    <path d="M116.364 175.168L108.367 166.835L108.871 165.356L110.182 162.399L116.364 175.168Z" fill="#E4E5E6" />
    <path opacity="0.1" d="M142.238 165.323L140.928 162.366L128.058 119.255L128.461 118.045L126.983 118.952L125.571 125.37L124.127 118.952L122.648 118.045L123.051 119.255L110.182 162.366L108.871 165.323L108.367 166.802L116.364 175.135L120.027 180.175L125.571 175.034L131.116 180.175L134.745 175.135L142.742 166.802L142.238 165.323Z" fill={`url(#${id}__a`} />
    <path opacity="0.1" d="M108.435 167.171L116.432 175.504L120.061 180.544L125.605 175.403L131.15 180.544L134.812 175.504L142.81 167.171L142.306 165.692L140.995 162.735L128.125 119.624L128.529 118.414L127.05 119.321L125.605 125.739L124.194 119.321L122.715 118.414L123.119 119.624L110.249 162.735L108.939 165.692L108.435 167.171Z" fill={`url(#${id}__b`} />
    <path opacity="0.1" d="M142.238 165.323L140.928 162.366L128.058 119.255L128.461 118.045L126.983 118.952L125.571 125.37L124.127 118.952L122.648 118.045L123.051 119.255L110.182 162.366L108.871 165.323L108.367 166.802L116.364 175.135L120.027 180.175L125.571 175.034L131.116 180.175L134.745 175.135L142.742 166.802L142.238 165.323Z" fill={`url(#${id}__c`} />
    <path opacity="0.03" d="M182.527 129.268L166.028 125.706L168.985 120.8L189.819 69.5566H212.097L207.359 108.972L182.527 129.268Z" fill={`url(#${id}__d`} />
    <path opacity="0.03" d="M143.784 162.769L142.238 165.323L142.742 166.801L161.459 190.289L188.676 154.671L185.585 129.906L166.029 125.706L143.784 162.769Z" fill={`url(#${id}__e`} />
    <path opacity="0.03" d="M68.5816 129.268L85.0803 125.706L82.1233 120.8L61.2899 69.5566H39.0117L43.7496 108.972L68.5816 129.268Z" fill={`url(#${id}__f`} />
    <path opacity="0.03" d="M107.325 162.769L108.871 165.323L108.367 166.801L89.684 190.289L62.4326 154.671L65.5576 129.906L85.0805 125.706L107.325 162.769Z" fill={`url(#${id}__g`} />
    <g opacity="0.2">
    <path opacity="0.22" d="M134.745 175.135L142.742 166.802L142.238 165.323L140.928 162.366L134.745 175.135Z" fill={`url(#${id}__h`} />
    <path opacity="0.22" d="M134.745 175.135L142.742 166.802L142.238 165.323L140.928 162.366L134.745 175.135Z" fill={`url(#${id}__i`} />
    <path opacity="0.1" d="M134.745 175.135L142.742 166.802L142.238 165.323L140.928 162.366L134.745 175.135Z" fill={`url(#${id}__j`} />
    </g>
    <g opacity="0.2">
    <path opacity="0.22" d="M116.364 175.135L108.367 166.802L108.871 165.323L110.182 162.366L116.364 175.135Z" fill={`url(#${id}__k`} />
    <path opacity="0.22" d="M116.364 175.135L108.367 166.802L108.871 165.323L110.182 162.366L116.364 175.135Z" fill={`url(#${id}__l`} />
    <path opacity="0.1" d="M116.364 175.135L108.367 166.802L108.871 165.323L110.182 162.366L116.364 175.135Z" fill={`url(#${id}__m`} />
    </g>
    <path opacity="0.02" d="M61.29 69.5566L121.337 113.844L82.1234 120.8L61.29 69.5566Z" fill={`url(#${id}__n`} />
    <path opacity="0.1" d="M182.527 129.335L165.995 125.773L168.952 120.867L189.819 69.5566H212.131L207.359 109.039L182.527 129.335Z" fill={`url(#${id}__o`} />
    <path opacity="0.1" d="M68.582 129.335L85.1143 125.773L82.1573 120.867L61.2903 69.5566H38.9785L43.75 109.039L68.582 129.335Z" fill={`url(#${id}__p`} />
    <rect x="24.9668" y="119" width="99.8658" height="100" rx="49.9329" fill={`url(#${id}__q`} />
<path d="M87.6914 202H81.4414V195.75H75.1914V202H68.9414H62.6914H56.4414V195.75H50.1914V189.5V183.25V177V170.75V164.5V158.25H56.4414V152H62.6914H68.9414H75.1914V158.25H81.4414V152V145.75V139.5V133.25H87.6914H93.9414V139.5V145.75V152V158.25V164.5V170.75V177V183.25V189.5V195.75V202H87.6914ZM68.9414 189.5H75.1914V183.25H81.4414V177V170.75H75.1914V164.5H68.9414H62.6914V170.75V177V183.25V189.5H68.9414Z" fill={`url(#${id}__r`} />
    <defs>
    <linearGradient id={`${id}__a`} x1="118.716" y1="151.513" x2="136.305" y2="146.305" gradientUnits="userSpaceOnUse">
    <stop offset="0.85" stopColor="white" stopOpacity="0" />
    <stop offset="0.9534" stopColor="#333333" stopOpacity="0.7962" />
    <stop offset="1" />
    </linearGradient>
    <linearGradient id={`${id}__b`} x1="132.461" y1="151.887" x2="114.872" y2="146.679" gradientUnits="userSpaceOnUse">
    <stop offset="0.85" stopColor="white" stopOpacity="0" />
    <stop offset="0.9534" stopColor="#333333" stopOpacity="0.7962" />
    <stop offset="1" />
    </linearGradient>
    <linearGradient id={`${id}__c`} x1="126.741" y1="173.331" x2="115.913" y2="113.657" gradientUnits="userSpaceOnUse">
    <stop offset="0.04311" stopColor="white" stopOpacity="0" />
    <stop offset="0.9004" />
    </linearGradient>
    <linearGradient id={`${id}__d`} x1="238.182" y1="114.739" x2="179.392" y2="94.263" gradientUnits="userSpaceOnUse">
    <stop offset="0.9008" stopColor="white" stopOpacity="0" />
    <stop offset="0.9528" stopColor="#333333" stopOpacity="0.7962" />
    <stop offset="1" />
    </linearGradient>
    <linearGradient id={`${id}__e`} x1="192.907" y1="172.179" x2="152.99" y2="147.337" gradientUnits="userSpaceOnUse">
    <stop offset="0.9" stopColor="white" stopOpacity="0" />
    <stop offset="0.9534" stopColor="#333333" stopOpacity="0.7962" />
    <stop offset="1" />
    </linearGradient>
    <linearGradient id={`${id}__f`} x1="12.9304" y1="114.739" x2="71.7197" y2="94.263" gradientUnits="userSpaceOnUse">
    <stop offset="0.9" stopColor="white" stopOpacity="0" />
    <stop offset="0.9528" stopColor="#333333" stopOpacity="0.7962" />
    <stop offset="1" />
    </linearGradient>
    <linearGradient id={`${id}__g`} x1="58.2061" y1="172.179" x2="98.1231" y2="147.337" gradientUnits="userSpaceOnUse">
    <stop offset="0.9" stopColor="white" stopOpacity="0" />
    <stop offset="0.9534" stopColor="#333333" stopOpacity="0.7962" />
    <stop offset="1" />
    </linearGradient>
    <linearGradient id={`${id}__h`} x1="136.548" y1="169.798" x2="142.756" y2="164.772" gradientUnits="userSpaceOnUse">
    <stop offset="0.7236" stopColor="white" stopOpacity="0" />
    <stop offset="0.9534" stopColor="#333333" stopOpacity="0.7962" />
    <stop offset="1" />
    </linearGradient>
    <linearGradient id={`${id}__i`} x1="143.165" y1="173.379" x2="137.132" y2="168.144" gradientUnits="userSpaceOnUse">
    <stop offset="0.7236" stopColor="white" stopOpacity="0" />
    <stop offset="0.9534" stopColor="#333333" stopOpacity="0.7962" />
    <stop offset="1" />
    </linearGradient>
    <linearGradient id={`${id}__j`} x1="145.158" y1="172.233" x2="137.943" y2="168.806" gradientUnits="userSpaceOnUse">
    <stop offset="0.7236" stopColor="white" stopOpacity="0" />
    <stop offset="0.9534" stopColor="#333333" stopOpacity="0.7962" />
    <stop offset="1" />
    </linearGradient>
    <linearGradient id={`${id}__k`} x1="114.565" y1="169.798" x2="108.357" y2="164.772" gradientUnits="userSpaceOnUse">
    <stop offset="0.6256" stopColor="white" stopOpacity="0" />
    <stop offset="0.8645" stopColor="#444444" stopOpacity="0.2639" />
    <stop offset="0.8946" stopOpacity="0.36" />
    </linearGradient>
    <linearGradient id={`${id}__l`} x1="107.948" y1="173.379" x2="113.982" y2="168.144" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0" />
    <stop offset="0.9099" stopColor="#333333" stopOpacity="0.7962" />
    <stop offset="1" />
    </linearGradient>
    <linearGradient id={`${id}__m`} x1="105.955" y1="172.233" x2="113.171" y2="168.806" gradientUnits="userSpaceOnUse">
    <stop offset="0.5789" stopColor="white" stopOpacity="0" />
    <stop offset="0.9405" stopColor="#878787" stopOpacity="0.4684" />
    <stop offset="1" />
    </linearGradient>
    <linearGradient id={`${id}__n`} x1="85.989" y1="96.5146" x2="67.661" y2="113.068" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0" />
    <stop offset="0.1013" stopColor="#E0DFDF" stopOpacity="0.1392" />
    <stop offset="0.6485" stopColor="#555253" stopOpacity="0.7693" />
    <stop offset="1" stopColor="#231F20" />
    </linearGradient>
    <linearGradient id={`${id}__o`} x1="200.359" y1="73.9132" x2="181.668" y2="133.368" gradientUnits="userSpaceOnUse">
    <stop offset="0.85" stopColor="white" stopOpacity="0" />
    <stop offset="0.9592" stopColor="#333333" stopOpacity="0.7962" />
    <stop offset="1" />
    </linearGradient>
    <linearGradient id={`${id}__p`} x1="50.7544" y1="73.9132" x2="69.4445" y2="133.368" gradientUnits="userSpaceOnUse">
    <stop offset="0.85" stopColor="white" stopOpacity="0" />
    <stop offset="0.9592" stopColor="#333333" stopOpacity="0.7962" />
    <stop offset="1" />
    </linearGradient>
    <radialGradient id={`${id}__q`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(50.5324 147) rotate(71.3024) scale(76.0116 75.9306)">
    <stop stopColor="#66A9F7" />
    <stop offset="1" stopColor="#0075FF" />
    </radialGradient>
    <radialGradient id={`${id}__r`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(79.9478 136.987) rotate(76.1726) scale(89.6096 97.8719)">
    <stop stopColor="#334155" />
    <stop offset="1" stopColor="#050A30" />
    </radialGradient>
    </defs>
  </svg>

);

export default SvgDbtrfly;
