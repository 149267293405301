/* eslint-disable react/no-unescaped-entities */
/** @jsxImportSource @emotion/react */
import React from 'react';
// import BigNumber from 'bignumber.js';
import { Paper } from '@mui/material';
import { TextButton } from 'components';
import { useStyles } from './styles';


interface IHeaderProps {
  className?: string;
}

export const Header: React.FC<IHeaderProps> = ({
  className,

}) => {
  const styles = useStyles();

  return (
    <>
      <Paper css={styles.header} className={className}>
        <div css={styles.headerRoot}>
            <div>
              <h3 css={styles.whiteText}>
                Boost Pools:
              </h3>
            </div>
            <p>
              Deposit assets into Compound or Aave, then lend  your cTokens or aTokens (LP Tokens) to the dAMM Protocol to earn bdAMM rewards plus additional yield on top of Compound and Aave's native yield. <br />
            </p>
            <TextButton css={styles.button}>
                <a href="https://docs.damm.finance/damm-finance/damm-token/boosted-pools" target="_blank" rel="noreferrer">
                  More Info
                </a>
            </TextButton>

        </div>
        {/* <div css={styles.headerRoot}>
          <div css={styles.slider}>
            <LabeledProgressBar
              css={styles.progressBar}
              min={1}
              max={100}
              step={1}
              value={percentOfDammDistributed}
              ariaLabel={t('damm.progressBar')}
              greyLeftText={t('damm.dailyDistribution')}
              whiteLeftText={readableDailyDistribution}
              greyRightText={t('damm.remaining')}
              whiteRightText={readableRemainingDistribution}
            />
          </div> */}
        {/* </div> */}
      </Paper>
    </>
  );
};

export default Header;
