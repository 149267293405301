/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';
import { MarketContext } from 'context/MarketContext';
import { Typography } from '@mui/material';
import { AuthContext } from 'context/AuthContext';
import { Table, Token, TableProps, LayeredValues, ProgressBar } from 'components';
import { useTranslation } from 'translation';
import { Asset, TokenId } from 'types';
import { useUserMarketInfo } from 'clients/api';
import {
  formatCoinsToReadableValue,
  formatToReadablePercentage,
  isWhitelisted,
} from 'utilities/common';
import { formatCentsToReadableValue } from 'utilities';
import { useStyles as useSharedStyles } from '../styles';
import { useStyles as useLocalStyles } from './styles';


export interface IMarketTableProps extends Pick<TableProps, 'getRowHref'> {
  assets: Asset[];
}

export const MarketTableUi: React.FC<IMarketTableProps> = ({ assets, getRowHref }) => {
  const { t } = useTranslation();
  const sharedStyles = useSharedStyles();
  const localStyles = useLocalStyles();
  const styles = { ...sharedStyles, ...localStyles };
  const { userTotalBorrowLimit } = useContext(MarketContext);


  const columns = useMemo(
    () => {
      const columnsResult = [
      { key: 'asset', label: t('market.columns.asset'), orderable: false },
      { key: 'totalApy', label: t('Supply APY w/ bdAMM'), orderable: true },
      { key: 'totalSupply', label: t('market.columns.totalSupply'), orderable: true },
      { key: 'supplyApy', label: t('market.columns.supplyApy'), orderable: true },
      { key: 'liquidity', label: t('market.columns.liquidity'), orderable: true },
      { key: 'price', label: t('market.columns.price'), orderable: true },
      { key: 'utilization', label: t('Utilization'), orderable: true },
    ];
    if (isWhitelisted(userTotalBorrowLimit)) {
      columnsResult.splice(4, 0, { key: 'borrowApy', label: t('market.columns.borrowApy'), orderable: true });
    }
    return columnsResult;
    },
    [userTotalBorrowLimit],
  );

  const cardColumns = useMemo(() => {
    const newColumns = [...columns];
    const [liquidityCol] = newColumns.splice(5, 1);
    newColumns.splice(3, 0, liquidityCol);
    return newColumns;
  }, [columns]);

  const { vampMarkets } =
    useMemo(() => {
      const sortedAssets = assets.reduce(
        (acc, curr) => {
          if (curr.assetType === 'comp') {
            acc.vampMarkets.push(curr);
          } else if (curr.assetType === 'aave') {
            acc.vampMarkets.push(curr);
          }
          return acc;
        },
        {
          vampMarkets: [] as Asset[],
        },
      );
      return sortedAssets;
    }, [JSON.stringify(assets)]);

  // Format assets to rows
  const rows: TableProps['data'] = vampMarkets.map(asset => [
    {
      key: 'asset',
      render: () => <Token symbol={asset.symbol as TokenId} />,
      value: asset.id,
    },
    {
      key: 'totalApy',
      render: () => (
        <Typography variant="small1" css={styles.whiteText}>
          {formatToReadablePercentage(asset.supplyApy.plus(asset.bdammSupplyApy))}
        </Typography>
      ),
      value: asset.supplyApy.toFixed(),
    },
    {
      key: 'totalSupply',
      render: () => (
        <LayeredValues
          topValue={formatCentsToReadableValue({
            value: asset.totalSupplyUsd.times(100),
            shorthand: true,
          })}
          bottomValue={formatCoinsToReadableValue({
            value: asset.totalSupply2,
            tokenId: asset.id as TokenId,
            shorthand: true,
            suffix: true,
          })}
        />
      ),
      align: 'right',
      value: asset.totalSupplyUsd.toFixed(),
    },
    {
      key: 'supplyApy',
      render: () => (
        <Typography variant="small1" css={styles.whiteText}>
          {formatToReadablePercentage(asset.supplyApy)}
        </Typography>
      ),
      value: asset.supplyApy.plus(asset.supplyApy).toFixed(),
    },
    ...(
      isWhitelisted(userTotalBorrowLimit)
        ? [
          {
            key: 'borrowApy',
            render: () => (

              <Typography variant="small1" css={styles.whiteText}>
                {formatToReadablePercentage(asset.borrowApy)}
              </Typography>
            ),
            value: asset.borrowApy.toFixed(),
          },
        ] : []
    ),
    {
      key: 'liquidity',
      render: () => (
        <Typography variant="small1" css={styles.whiteText}>
          {formatCentsToReadableValue({
            value: asset.liquidity.multipliedBy(100),
            shorthand: true,
          })}
        </Typography>
      ),
      value: asset.liquidity.toFixed(),
    },
    {
      key: 'price',
      render: () => (
        <Typography variant="small1" css={styles.whiteText}>
          {formatCentsToReadableValue({
            value: asset.tokenPrice.multipliedBy(100),
            shorthand: true,
            })}
        </Typography>
      ),
      value: asset.tokenPrice.toFixed(),
    },
    {
      key: 'utilization',
      render: () => (
        <div css={styles.utilization}>
          <Typography variant="small2" css={styles.white}>
            {formatToReadablePercentage(asset.currentUtilizationRate)}
          </Typography>
          <ProgressBar
            min={0}
            max={100}
            value={asset.currentUtilizationRate.toNumber()}
            step={1}
            ariaLabel={t('Utilization %')}
            css={styles.utilizationProgressBar}
          />
        </div>
      ),
      value: asset.currentUtilizationRate.toFixed(),
    },
  ]);


  return (
    <div css={styles.container}>
      <Table
        title="Boost Markets"
        columns={columns}
        cardColumns={cardColumns}
        data={rows}
        initialOrder={{
          orderBy: 'totalSupply',
          orderDirection: 'desc',
        }}
        rowKeyIndex={0}
        getRowHref={getRowHref}
        tableCss={sharedStyles.table}
        cardsCss={sharedStyles.cards}
        css={sharedStyles.cardContentGrid}
      />
    </div>
  );
};

const MarketTable = () => {
  const { account } = useContext(AuthContext);
  const { assets } = useUserMarketInfo({ accountAddress: account?.address || '' });
  return <MarketTableUi assets={assets} getRowHref={row => `/market/${row[0].value}`} />;
};

export default MarketTable;
