import * as React from 'react';
import { SVGProps } from 'react';

const SvgUni = ({ ...props }: SVGProps<SVGSVGElement>) => (
    <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="125" cy="125" r="125" fill="#FF007A" />
        <path fillRule="evenodd" clipRule="evenodd" d="M106.104 100.9C103.704 101.6 101.404 104.2 100.704 106.8C100.304 108.4 100.504 111.3 101.204 112.2C102.304 113.6 103.304 114 106.104 114C111.604 114 116.304 111.6 116.804 108.7C117.304 106.3 115.204 103 112.304 101.5C110.804 100.7 107.704 100.4 106.104 100.9ZM112.504 105.9C113.304 104.7 113.004 103.4 111.504 102.5C108.804 100.8 104.704 102.2 104.704 104.8C104.704 106.1 106.804 107.5 108.804 107.5C110.104 107.5 111.904 106.7 112.504 105.9Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M48.004 27.5007C47.5039 28.0012 94.1041 83.6005 95.9041 86.0005C97.4041 88.0005 96.8041 89.9005 94.3041 91.3005C92.9041 92.1005 90.0041 92.9005 88.6041 92.9005C87.0041 92.9005 85.1041 92.1005 83.8041 90.8005C82.9041 89.9005 79.0041 84.2005 70.2041 70.5005C63.5041 60.0005 57.8041 51.3005 57.7041 51.2005C57.3041 51.0005 57.3041 51.0005 69.5041 72.8005C77.2041 86.5005 79.7041 91.4005 79.7041 92.0005C79.7041 93.3005 79.3041 94.0005 77.7041 95.8005C75.0041 98.8005 73.8041 102.201 72.9041 109.301C71.9041 117.201 69.2041 122.801 61.5041 132.301C57.0041 137.901 56.3041 138.9 55.2041 141.2C53.8041 144 53.4041 145.6 53.2041 149.2C53.0041 153 53.4041 155.4 54.5041 159C55.5041 162.2 56.6041 164.3 59.3041 168.4C61.6041 172 63.0041 174.7 63.0041 175.7C63.0041 176.5 63.2041 176.5 66.8041 175.7C75.4041 173.7 82.5041 170.3 86.4041 166.1C88.8041 163.5 89.4041 162.1 89.4041 158.5C89.4041 156.2 89.3041 155.7 88.7041 154.3C87.7041 152.1 85.8041 150.3 81.7041 147.5C76.3041 143.8 74.0041 140.801 73.4041 136.801C72.9041 133.401 73.5041 131.101 76.5041 124.801C79.6041 118.301 80.4041 115.601 80.9041 109.001C81.2041 104.801 81.7041 103.101 82.9041 101.801C84.2041 100.401 85.3041 99.9005 88.4041 99.5005C93.5041 98.8005 96.8041 97.5005 99.4041 95.0005C101.704 92.9005 102.704 90.8005 102.804 87.7005L102.904 85.4005L101.604 84.0005C96.9041 78.6005 48.5039 27 48.5039 27C48.5039 27 48.5041 27.0003 48.004 27.5007ZM65.6041 153.5C66.7041 151.6 66.1041 149.2 64.3041 148C62.6041 146.9 60.0041 147.4 60.0041 148.9C60.0041 149.3 60.2041 149.7 60.8041 149.9C61.7041 150.4 61.8041 150.9 61.1041 152C60.4041 153.1 60.4041 154.1 61.3041 154.8C62.7041 155.9 64.6041 155.3 65.6041 153.5Z" fill="white" />
        <path d="M87.5043 172.2C94.9043 166 104.004 161.6 112.404 160.2C116.004 159.6 122.004 159.8 125.304 160.7C130.604 162.1 135.404 165.1 137.904 168.8C140.304 172.4 141.404 175.5 142.504 182.4C142.904 185.1 143.404 187.9 143.504 188.5C144.304 192.1 145.904 194.9 147.904 196.4C151.004 198.7 156.404 198.8 161.704 196.8C162.604 196.5 163.404 196.2 163.404 196.3C163.604 196.5 160.904 198.3 159.104 199.2C156.604 200.5 154.604 200.9 151.904 200.9C147.104 200.9 143.004 198.4 139.704 193.4C139.004 192.4 137.604 189.5 136.404 186.8C132.904 178.7 131.104 176.3 127.004 173.6C123.404 171.3 118.804 170.8 115.304 172.5C110.704 174.7 109.504 180.6 112.704 184.2C114.004 185.7 116.404 186.9 118.404 187.2C122.104 187.7 125.304 184.8 125.304 181.1C125.304 178.7 124.404 177.3 122.004 176.2C118.804 174.8 115.304 176.4 115.404 179.5C115.404 180.8 116.004 181.6 117.304 182.2C118.104 182.6 118.104 182.6 117.504 182.5C114.604 181.9 113.904 178.3 116.204 176C119.004 173.2 124.904 174.4 126.904 178.3C127.704 179.9 127.804 183.1 127.104 185.1C125.404 189.5 120.604 191.8 115.704 190.5C112.404 189.6 111.004 188.7 107.004 184.6C100.004 177.4 97.3043 176 87.3043 174.5L85.4043 174.2L87.5043 172.2Z" fill="white" />
        <path d="M162.004 141.6C159.404 135.9 158.804 130.5 160.204 125.4C160.404 124.9 160.604 124.4 160.804 124.4C161.004 124.4 161.604 124.7 162.204 125.1C163.404 125.9 165.904 127.3 172.304 130.8C180.404 135.2 185.004 138.6 188.204 142.5C191.004 145.9 192.704 149.8 193.504 154.6C194.004 157.3 193.704 163.8 193.004 166.5C190.804 175 185.804 181.8 178.504 185.7C177.404 186.3 176.504 186.7 176.404 186.7C176.304 186.7 176.704 185.7 177.304 184.5C179.704 179.4 180.004 174.5 178.204 169C177.104 165.6 174.804 161.5 170.204 154.6C164.704 146.6 163.404 144.5 162.004 141.6Z" fill="white" />
        <path d="M152.904 125.5C152.704 124 152.204 122.1 151.904 121.3L151.404 119.8L152.304 120.9C153.604 122.4 154.604 124.2 155.504 126.7C156.204 128.6 156.204 129.2 156.204 132.3C156.204 135.3 156.104 136 155.504 137.7C154.504 140.4 153.304 142.3 151.304 144.4C147.704 148.1 143.004 150.1 136.304 151C135.104 151.1 131.704 151.4 128.704 151.6C121.204 152 116.204 152.8 111.704 154.4C111.104 154.6 110.504 154.8 110.404 154.7C110.204 154.5 113.304 152.7 115.804 151.5C119.304 149.8 122.904 148.9 130.804 147.5C134.704 146.9 138.704 146.1 139.704 145.7C149.604 142.6 154.504 134.9 152.904 125.5Z" fill="white" />
        <path d="M166.904 122.2C147.104 114.2 140.104 107.3 140.104 95.6C140.104 93.9 140.204 92.5 140.204 92.5C140.304 92.5 141.004 93.1 141.904 93.8C145.904 97 150.404 98.4 162.904 100.2C170.204 101.3 174.404 102.1 178.204 103.4C190.304 107.4 197.804 115.6 199.604 126.7C200.104 129.9 199.804 136 199.004 139.2C198.304 141.7 196.304 146.3 195.804 146.4C195.704 146.4 195.504 145.9 195.504 145.1C195.304 140.9 193.204 136.9 189.704 133.8C185.504 130.2 180.104 127.5 166.904 122.2Z" fill="white" />
        <path d="M101.604 78.6996C97.4039 72.8996 94.7039 63.8996 95.3039 57.1996L95.5039 55.0996L96.5039 55.2996C98.3039 55.5996 101.404 56.7996 102.904 57.6996C106.904 60.0996 108.704 63.3996 110.404 71.5996C110.904 73.9996 111.604 76.7996 111.904 77.6996C112.404 79.1996 114.304 82.6996 115.904 84.8996C117.004 86.4996 116.304 87.2996 113.804 87.0996C110.004 86.6996 104.904 83.1996 101.604 78.6996Z" fill="white" />
        <path d="M172.004 63.8C172.204 60 172.704 57.5 173.804 55.2C174.204 54.3 174.604 53.5 174.704 53.5C174.804 53.5 174.604 54.2 174.304 55C173.504 57.2 173.404 60.3 173.904 63.8C174.604 68.3 174.904 68.9 179.704 73.8C181.904 76.1 184.504 79 185.504 80.2L187.204 82.4L185.504 80.8C183.404 78.8 178.604 75 177.504 74.5C176.804 74.1 176.704 74.1 176.204 74.6C175.804 75 175.704 75.6 175.704 78.5C175.604 83 175.004 85.8 173.504 88.7C172.704 90.2 172.604 89.9 173.304 88.2C173.804 86.9 173.904 86.3 173.904 82C173.904 73.3 172.904 71.2 166.804 67.7C165.304 66.8 162.704 65.5 161.204 64.8C159.604 64.1 158.404 63.5 158.504 63.5C158.704 63.3 164.604 65 166.904 66C170.404 67.4 171.004 67.5 171.404 67.4C171.704 67.1 171.904 66.3 172.004 63.8Z" fill="white" />
        <path d="M97.5041 51.0998C95.4041 50.7998 95.3042 50.6998 96.3042 50.5998C98.2042 50.2998 102.604 50.6998 105.704 51.3998C112.904 53.0998 119.404 57.4998 126.304 65.1998L128.104 67.2998L130.704 66.8998C141.804 65.0998 153.204 66.4998 162.704 70.8998C165.304 72.0998 169.404 74.4998 169.904 75.0998C170.104 75.2998 170.404 76.5998 170.604 77.8998C171.304 82.5998 171.004 86.0998 169.504 88.7998C168.704 90.2998 168.704 90.6998 169.204 91.9998C169.604 92.9998 170.804 93.6998 171.904 93.6998C174.304 93.6998 176.804 89.8998 178.004 84.5998L178.504 82.4998L179.404 83.4998C184.504 89.1998 188.504 97.0998 189.104 102.7L189.304 104.2L188.404 102.9C186.904 100.6 185.504 99.0998 183.604 97.7998C180.204 95.4998 176.604 94.7998 167.104 94.2998C158.504 93.7998 153.604 93.0998 148.804 91.4998C140.604 88.7998 136.404 85.2998 126.704 72.3998C122.404 66.6998 119.704 63.5998 117.004 60.9998C111.104 55.2998 105.204 52.2998 97.5041 51.0998Z" fill="white" />
    </svg>

);

export default SvgUni;
