export const legacyStableCoinInterestRates = [
  {
    "borrowApyPercentage": 0,
    "supplyApyPercentage": 0,
    "utilizationRate": 0
  },
  {
    "borrowApyPercentage": 0.16,
    "supplyApyPercentage": 0,
    "utilizationRate": 1
  },
  {
    "borrowApyPercentage": 0.31,
    "supplyApyPercentage": 0.01,
    "utilizationRate": 2
  },
  {
    "borrowApyPercentage": 0.47,
    "supplyApyPercentage": 0.01,
    "utilizationRate": 3
  },
  {
    "borrowApyPercentage": 0.63,
    "supplyApyPercentage": 0.02,
    "utilizationRate": 4
  },
  {
    "borrowApyPercentage": 0.78,
    "supplyApyPercentage": 0.03,
    "utilizationRate": 5
  },
  {
    "borrowApyPercentage": 0.94,
    "supplyApyPercentage": 0.05,
    "utilizationRate": 6
  },
  {
    "borrowApyPercentage": 1.1,
    "supplyApyPercentage": 0.06,
    "utilizationRate": 7
  },
  {
    "borrowApyPercentage": 1.26,
    "supplyApyPercentage": 0.08,
    "utilizationRate": 8
  },
  {
    "borrowApyPercentage": 1.42,
    "supplyApyPercentage": 0.1,
    "utilizationRate": 9
  },
  {
    "borrowApyPercentage": 1.57,
    "supplyApyPercentage": 0.13,
    "utilizationRate": 10
  },
  {
    "borrowApyPercentage": 1.73,
    "supplyApyPercentage": 0.16,
    "utilizationRate": 11
  },
  {
    "borrowApyPercentage": 1.89,
    "supplyApyPercentage": 0.19,
    "utilizationRate": 12
  },
  {
    "borrowApyPercentage": 2.05,
    "supplyApyPercentage": 0.22,
    "utilizationRate": 13
  },
  {
    "borrowApyPercentage": 2.21,
    "supplyApyPercentage": 0.25,
    "utilizationRate": 14
  },
  {
    "borrowApyPercentage": 2.37,
    "supplyApyPercentage": 0.29,
    "utilizationRate": 15
  },
  {
    "borrowApyPercentage": 2.53,
    "supplyApyPercentage": 0.33,
    "utilizationRate": 16
  },
  {
    "borrowApyPercentage": 2.69,
    "supplyApyPercentage": 0.37,
    "utilizationRate": 17
  },
  {
    "borrowApyPercentage": 2.85,
    "supplyApyPercentage": 0.42,
    "utilizationRate": 18
  },
  {
    "borrowApyPercentage": 3.01,
    "supplyApyPercentage": 0.47,
    "utilizationRate": 19
  },
  {
    "borrowApyPercentage": 3.17,
    "supplyApyPercentage": 0.52,
    "utilizationRate": 20
  },
  {
    "borrowApyPercentage": 3.34,
    "supplyApyPercentage": 0.57,
    "utilizationRate": 21
  },
  {
    "borrowApyPercentage": 3.5,
    "supplyApyPercentage": 0.63,
    "utilizationRate": 22
  },
  {
    "borrowApyPercentage": 3.66,
    "supplyApyPercentage": 0.68,
    "utilizationRate": 23
  },
  {
    "borrowApyPercentage": 3.82,
    "supplyApyPercentage": 0.75,
    "utilizationRate": 24
  },
  {
    "borrowApyPercentage": 3.98,
    "supplyApyPercentage": 0.81,
    "utilizationRate": 25
  },
  {
    "borrowApyPercentage": 4.15,
    "supplyApyPercentage": 0.88,
    "utilizationRate": 26
  },
  {
    "borrowApyPercentage": 4.31,
    "supplyApyPercentage": 0.94,
    "utilizationRate": 27
  },
  {
    "borrowApyPercentage": 4.47,
    "supplyApyPercentage": 1.02,
    "utilizationRate": 28
  },
  {
    "borrowApyPercentage": 4.64,
    "supplyApyPercentage": 1.09,
    "utilizationRate": 29
  },
  {
    "borrowApyPercentage": 4.8,
    "supplyApyPercentage": 1.17,
    "utilizationRate": 30
  },
  {
    "borrowApyPercentage": 4.96,
    "supplyApyPercentage": 1.25,
    "utilizationRate": 31
  },
  {
    "borrowApyPercentage": 5.13,
    "supplyApyPercentage": 1.33,
    "utilizationRate": 32
  },
  {
    "borrowApyPercentage": 5.29,
    "supplyApyPercentage": 1.41,
    "utilizationRate": 33
  },
  {
    "borrowApyPercentage": 5.46,
    "supplyApyPercentage": 1.5,
    "utilizationRate": 34
  },
  {
    "borrowApyPercentage": 5.62,
    "supplyApyPercentage": 1.59,
    "utilizationRate": 35
  },
  {
    "borrowApyPercentage": 5.79,
    "supplyApyPercentage": 1.68,
    "utilizationRate": 36
  },
  {
    "borrowApyPercentage": 5.95,
    "supplyApyPercentage": 1.78,
    "utilizationRate": 37
  },
  {
    "borrowApyPercentage": 6.12,
    "supplyApyPercentage": 1.88,
    "utilizationRate": 38
  },
  {
    "borrowApyPercentage": 6.28,
    "supplyApyPercentage": 1.98,
    "utilizationRate": 39
  },
  {
    "borrowApyPercentage": 6.45,
    "supplyApyPercentage": 2.08,
    "utilizationRate": 40
  },
  {
    "borrowApyPercentage": 6.62,
    "supplyApyPercentage": 2.19,
    "utilizationRate": 41
  },
  {
    "borrowApyPercentage": 6.78,
    "supplyApyPercentage": 2.3,
    "utilizationRate": 42
  },
  {
    "borrowApyPercentage": 6.95,
    "supplyApyPercentage": 2.41,
    "utilizationRate": 43
  },
  {
    "borrowApyPercentage": 7.12,
    "supplyApyPercentage": 2.53,
    "utilizationRate": 44
  },
  {
    "borrowApyPercentage": 7.28,
    "supplyApyPercentage": 2.64,
    "utilizationRate": 45
  },
  {
    "borrowApyPercentage": 7.45,
    "supplyApyPercentage": 2.77,
    "utilizationRate": 46
  },
  {
    "borrowApyPercentage": 7.62,
    "supplyApyPercentage": 2.89,
    "utilizationRate": 47
  },
  {
    "borrowApyPercentage": 7.79,
    "supplyApyPercentage": 3.01,
    "utilizationRate": 48
  },
  {
    "borrowApyPercentage": 7.96,
    "supplyApyPercentage": 3.14,
    "utilizationRate": 49
  },
  {
    "borrowApyPercentage": 8.13,
    "supplyApyPercentage": 3.28,
    "utilizationRate": 50
  },
  {
    "borrowApyPercentage": 8.29,
    "supplyApyPercentage": 3.41,
    "utilizationRate": 51
  },
  {
    "borrowApyPercentage": 8.46,
    "supplyApyPercentage": 3.55,
    "utilizationRate": 52
  },
  {
    "borrowApyPercentage": 8.63,
    "supplyApyPercentage": 3.69,
    "utilizationRate": 53
  },
  {
    "borrowApyPercentage": 8.8,
    "supplyApyPercentage": 3.83,
    "utilizationRate": 54
  },
  {
    "borrowApyPercentage": 8.97,
    "supplyApyPercentage": 3.98,
    "utilizationRate": 55
  },
  {
    "borrowApyPercentage": 9.14,
    "supplyApyPercentage": 4.13,
    "utilizationRate": 56
  },
  {
    "borrowApyPercentage": 9.31,
    "supplyApyPercentage": 4.28,
    "utilizationRate": 57
  },
  {
    "borrowApyPercentage": 9.49,
    "supplyApyPercentage": 4.43,
    "utilizationRate": 58
  },
  {
    "borrowApyPercentage": 9.66,
    "supplyApyPercentage": 4.59,
    "utilizationRate": 59
  },
  {
    "borrowApyPercentage": 9.83,
    "supplyApyPercentage": 4.75,
    "utilizationRate": 60
  },
  {
    "borrowApyPercentage": 10,
    "supplyApyPercentage": 4.91,
    "utilizationRate": 61
  },
  {
    "borrowApyPercentage": 10.17,
    "supplyApyPercentage": 5.08,
    "utilizationRate": 62
  },
  {
    "borrowApyPercentage": 10.34,
    "supplyApyPercentage": 5.25,
    "utilizationRate": 63
  },
  {
    "borrowApyPercentage": 10.52,
    "supplyApyPercentage": 5.42,
    "utilizationRate": 64
  },
  {
    "borrowApyPercentage": 10.69,
    "supplyApyPercentage": 5.6,
    "utilizationRate": 65
  },
  {
    "borrowApyPercentage": 10.86,
    "supplyApyPercentage": 5.78,
    "utilizationRate": 66
  },
  {
    "borrowApyPercentage": 11.04,
    "supplyApyPercentage": 5.96,
    "utilizationRate": 67
  },
  {
    "borrowApyPercentage": 11.21,
    "supplyApyPercentage": 6.14,
    "utilizationRate": 68
  },
  {
    "borrowApyPercentage": 11.38,
    "supplyApyPercentage": 6.33,
    "utilizationRate": 69
  },
  {
    "borrowApyPercentage": 11.56,
    "supplyApyPercentage": 6.52,
    "utilizationRate": 70
  },
  {
    "borrowApyPercentage": 11.73,
    "supplyApyPercentage": 6.71,
    "utilizationRate": 71
  },
  {
    "borrowApyPercentage": 11.91,
    "supplyApyPercentage": 6.91,
    "utilizationRate": 72
  },
  {
    "borrowApyPercentage": 12.08,
    "supplyApyPercentage": 7.11,
    "utilizationRate": 73
  },
  {
    "borrowApyPercentage": 12.26,
    "supplyApyPercentage": 7.31,
    "utilizationRate": 74
  },
  {
    "borrowApyPercentage": 12.43,
    "supplyApyPercentage": 7.52,
    "utilizationRate": 75
  },
  {
    "borrowApyPercentage": 12.61,
    "supplyApyPercentage": 7.73,
    "utilizationRate": 76
  },
  {
    "borrowApyPercentage": 12.78,
    "supplyApyPercentage": 7.94,
    "utilizationRate": 77
  },
  {
    "borrowApyPercentage": 12.96,
    "supplyApyPercentage": 8.16,
    "utilizationRate": 78
  },
  {
    "borrowApyPercentage": 13.14,
    "supplyApyPercentage": 8.38,
    "utilizationRate": 79
  },
  {
    "borrowApyPercentage": 13.31,
    "supplyApyPercentage": 8.6,
    "utilizationRate": 80
  },
  {
    "borrowApyPercentage": 14.17,
    "supplyApyPercentage": 9.26,
    "utilizationRate": 81
  },
  {
    "borrowApyPercentage": 15.03,
    "supplyApyPercentage": 9.93,
    "utilizationRate": 82
  },
  {
    "borrowApyPercentage": 15.89,
    "supplyApyPercentage": 10.63,
    "utilizationRate": 83
  },
  {
    "borrowApyPercentage": 16.77,
    "supplyApyPercentage": 11.34,
    "utilizationRate": 84
  },
  {
    "borrowApyPercentage": 17.64,
    "supplyApyPercentage": 12.07,
    "utilizationRate": 85
  },
  {
    "borrowApyPercentage": 18.53,
    "supplyApyPercentage": 12.82,
    "utilizationRate": 86
  },
  {
    "borrowApyPercentage": 19.42,
    "supplyApyPercentage": 13.59,
    "utilizationRate": 87
  },
  {
    "borrowApyPercentage": 20.32,
    "supplyApyPercentage": 14.37,
    "utilizationRate": 88
  },
  {
    "borrowApyPercentage": 21.23,
    "supplyApyPercentage": 15.18,
    "utilizationRate": 89
  },
  {
    "borrowApyPercentage": 22.14,
    "supplyApyPercentage": 16.01,
    "utilizationRate": 90
  },
  {
    "borrowApyPercentage": 23.06,
    "supplyApyPercentage": 16.86,
    "utilizationRate": 91
  },
  {
    "borrowApyPercentage": 23.99,
    "supplyApyPercentage": 17.73,
    "utilizationRate": 92
  },
  {
    "borrowApyPercentage": 24.92,
    "supplyApyPercentage": 18.62,
    "utilizationRate": 93
  },
  {
    "borrowApyPercentage": 25.86,
    "supplyApyPercentage": 19.53,
    "utilizationRate": 94
  },
  {
    "borrowApyPercentage": 26.81,
    "supplyApyPercentage": 20.46,
    "utilizationRate": 95
  },
  {
    "borrowApyPercentage": 27.76,
    "supplyApyPercentage": 21.41,
    "utilizationRate": 96
  },
  {
    "borrowApyPercentage": 28.72,
    "supplyApyPercentage": 22.39,
    "utilizationRate": 97
  },
  {
    "borrowApyPercentage": 29.69,
    "supplyApyPercentage": 23.39,
    "utilizationRate": 98
  },
  {
    "borrowApyPercentage": 30.67,
    "supplyApyPercentage": 24.42,
    "utilizationRate": 99
  },
  {
    "borrowApyPercentage": 31.65,
    "supplyApyPercentage": 25.47,
    "utilizationRate": 100
  }
];

export const wethWbtcInterestRates = [
  {
    "borrowApyPercentage": 0,
    "supplyApyPercentage": 0,
    "utilizationRate": 0
  },
  {
    "borrowApyPercentage": 0.06,
    "supplyApyPercentage": 0,
    "utilizationRate": 1
  },
  {
    "borrowApyPercentage": 0.13,
    "supplyApyPercentage": 0,
    "utilizationRate": 2
  },
  {
    "borrowApyPercentage": 0.19,
    "supplyApyPercentage": 0,
    "utilizationRate": 3
  },
  {
    "borrowApyPercentage": 0.25,
    "supplyApyPercentage": 0.01,
    "utilizationRate": 4
  },
  {
    "borrowApyPercentage": 0.31,
    "supplyApyPercentage": 0.01,
    "utilizationRate": 5
  },
  {
    "borrowApyPercentage": 0.38,
    "supplyApyPercentage": 0.02,
    "utilizationRate": 6
  },
  {
    "borrowApyPercentage": 0.44,
    "supplyApyPercentage": 0.03,
    "utilizationRate": 7
  },
  {
    "borrowApyPercentage": 0.5,
    "supplyApyPercentage": 0.03,
    "utilizationRate": 8
  },
  {
    "borrowApyPercentage": 0.56,
    "supplyApyPercentage": 0.04,
    "utilizationRate": 9
  },
  {
    "borrowApyPercentage": 0.63,
    "supplyApyPercentage": 0.05,
    "utilizationRate": 10
  },
  {
    "borrowApyPercentage": 0.69,
    "supplyApyPercentage": 0.06,
    "utilizationRate": 11
  },
  {
    "borrowApyPercentage": 0.75,
    "supplyApyPercentage": 0.07,
    "utilizationRate": 12
  },
  {
    "borrowApyPercentage": 0.82,
    "supplyApyPercentage": 0.09,
    "utilizationRate": 13
  },
  {
    "borrowApyPercentage": 0.88,
    "supplyApyPercentage": 0.1,
    "utilizationRate": 14
  },
  {
    "borrowApyPercentage": 0.94,
    "supplyApyPercentage": 0.12,
    "utilizationRate": 15
  },
  {
    "borrowApyPercentage": 1.01,
    "supplyApyPercentage": 0.13,
    "utilizationRate": 16
  },
  {
    "borrowApyPercentage": 1.07,
    "supplyApyPercentage": 0.15,
    "utilizationRate": 17
  },
  {
    "borrowApyPercentage": 1.13,
    "supplyApyPercentage": 0.17,
    "utilizationRate": 18
  },
  {
    "borrowApyPercentage": 1.19,
    "supplyApyPercentage": 0.19,
    "utilizationRate": 19
  },
  {
    "borrowApyPercentage": 1.26,
    "supplyApyPercentage": 0.21,
    "utilizationRate": 20
  },
  {
    "borrowApyPercentage": 1.32,
    "supplyApyPercentage": 0.23,
    "utilizationRate": 21
  },
  {
    "borrowApyPercentage": 1.38,
    "supplyApyPercentage": 0.25,
    "utilizationRate": 22
  },
  {
    "borrowApyPercentage": 1.45,
    "supplyApyPercentage": 0.27,
    "utilizationRate": 23
  },
  {
    "borrowApyPercentage": 1.51,
    "supplyApyPercentage": 0.3,
    "utilizationRate": 24
  },
  {
    "borrowApyPercentage": 1.57,
    "supplyApyPercentage": 0.32,
    "utilizationRate": 25
  },
  {
    "borrowApyPercentage": 1.64,
    "supplyApyPercentage": 0.35,
    "utilizationRate": 26
  },
  {
    "borrowApyPercentage": 1.7,
    "supplyApyPercentage": 0.38,
    "utilizationRate": 27
  },
  {
    "borrowApyPercentage": 1.77,
    "supplyApyPercentage": 0.41,
    "utilizationRate": 28
  },
  {
    "borrowApyPercentage": 1.83,
    "supplyApyPercentage": 0.43,
    "utilizationRate": 29
  },
  {
    "borrowApyPercentage": 1.89,
    "supplyApyPercentage": 0.47,
    "utilizationRate": 30
  },
  {
    "borrowApyPercentage": 1.96,
    "supplyApyPercentage": 0.5,
    "utilizationRate": 31
  },
  {
    "borrowApyPercentage": 2.02,
    "supplyApyPercentage": 0.53,
    "utilizationRate": 32
  },
  {
    "borrowApyPercentage": 2.08,
    "supplyApyPercentage": 0.56,
    "utilizationRate": 33
  },
  {
    "borrowApyPercentage": 2.15,
    "supplyApyPercentage": 0.6,
    "utilizationRate": 34
  },
  {
    "borrowApyPercentage": 2.21,
    "supplyApyPercentage": 0.63,
    "utilizationRate": 35
  },
  {
    "borrowApyPercentage": 2.28,
    "supplyApyPercentage": 0.67,
    "utilizationRate": 36
  },
  {
    "borrowApyPercentage": 2.34,
    "supplyApyPercentage": 0.71,
    "utilizationRate": 37
  },
  {
    "borrowApyPercentage": 2.4,
    "supplyApyPercentage": 0.75,
    "utilizationRate": 38
  },
  {
    "borrowApyPercentage": 2.47,
    "supplyApyPercentage": 0.79,
    "utilizationRate": 39
  },
  {
    "borrowApyPercentage": 2.53,
    "supplyApyPercentage": 0.83,
    "utilizationRate": 40
  },
  {
    "borrowApyPercentage": 2.6,
    "supplyApyPercentage": 0.87,
    "utilizationRate": 41
  },
  {
    "borrowApyPercentage": 2.66,
    "supplyApyPercentage": 0.91,
    "utilizationRate": 42
  },
  {
    "borrowApyPercentage": 2.72,
    "supplyApyPercentage": 0.96,
    "utilizationRate": 43
  },
  {
    "borrowApyPercentage": 2.79,
    "supplyApyPercentage": 1,
    "utilizationRate": 44
  },
  {
    "borrowApyPercentage": 2.85,
    "supplyApyPercentage": 1.05,
    "utilizationRate": 45
  },
  {
    "borrowApyPercentage": 2.92,
    "supplyApyPercentage": 1.1,
    "utilizationRate": 46
  },
  {
    "borrowApyPercentage": 2.98,
    "supplyApyPercentage": 1.15,
    "utilizationRate": 47
  },
  {
    "borrowApyPercentage": 3.05,
    "supplyApyPercentage": 1.2,
    "utilizationRate": 48
  },
  {
    "borrowApyPercentage": 3.11,
    "supplyApyPercentage": 1.25,
    "utilizationRate": 49
  },
  {
    "borrowApyPercentage": 3.17,
    "supplyApyPercentage": 1.3,
    "utilizationRate": 50
  },
  {
    "borrowApyPercentage": 3.24,
    "supplyApyPercentage": 1.35,
    "utilizationRate": 51
  },
  {
    "borrowApyPercentage": 3.3,
    "supplyApyPercentage": 1.4,
    "utilizationRate": 52
  },
  {
    "borrowApyPercentage": 3.37,
    "supplyApyPercentage": 1.46,
    "utilizationRate": 53
  },
  {
    "borrowApyPercentage": 3.43,
    "supplyApyPercentage": 1.51,
    "utilizationRate": 54
  },
  {
    "borrowApyPercentage": 3.5,
    "supplyApyPercentage": 1.57,
    "utilizationRate": 55
  },
  {
    "borrowApyPercentage": 3.56,
    "supplyApyPercentage": 1.63,
    "utilizationRate": 56
  },
  {
    "borrowApyPercentage": 3.63,
    "supplyApyPercentage": 1.69,
    "utilizationRate": 57
  },
  {
    "borrowApyPercentage": 3.69,
    "supplyApyPercentage": 1.75,
    "utilizationRate": 58
  },
  {
    "borrowApyPercentage": 3.76,
    "supplyApyPercentage": 1.81,
    "utilizationRate": 59
  },
  {
    "borrowApyPercentage": 3.82,
    "supplyApyPercentage": 1.87,
    "utilizationRate": 60
  },
  {
    "borrowApyPercentage": 3.89,
    "supplyApyPercentage": 1.94,
    "utilizationRate": 61
  },
  {
    "borrowApyPercentage": 3.95,
    "supplyApyPercentage": 2,
    "utilizationRate": 62
  },
  {
    "borrowApyPercentage": 4.02,
    "supplyApyPercentage": 2.07,
    "utilizationRate": 63
  },
  {
    "borrowApyPercentage": 4.08,
    "supplyApyPercentage": 2.13,
    "utilizationRate": 64
  },
  {
    "borrowApyPercentage": 4.15,
    "supplyApyPercentage": 2.2,
    "utilizationRate": 65
  },
  {
    "borrowApyPercentage": 4.21,
    "supplyApyPercentage": 2.27,
    "utilizationRate": 66
  },
  {
    "borrowApyPercentage": 4.28,
    "supplyApyPercentage": 2.34,
    "utilizationRate": 67
  },
  {
    "borrowApyPercentage": 4.34,
    "supplyApyPercentage": 2.41,
    "utilizationRate": 68
  },
  {
    "borrowApyPercentage": 4.41,
    "supplyApyPercentage": 2.49,
    "utilizationRate": 69
  },
  {
    "borrowApyPercentage": 4.47,
    "supplyApyPercentage": 2.56,
    "utilizationRate": 70
  },
  {
    "borrowApyPercentage": 4.54,
    "supplyApyPercentage": 2.63,
    "utilizationRate": 71
  },
  {
    "borrowApyPercentage": 4.6,
    "supplyApyPercentage": 2.71,
    "utilizationRate": 72
  },
  {
    "borrowApyPercentage": 4.67,
    "supplyApyPercentage": 2.79,
    "utilizationRate": 73
  },
  {
    "borrowApyPercentage": 4.73,
    "supplyApyPercentage": 2.86,
    "utilizationRate": 74
  },
  {
    "borrowApyPercentage": 4.8,
    "supplyApyPercentage": 2.94,
    "utilizationRate": 75
  },
  {
    "borrowApyPercentage": 4.86,
    "supplyApyPercentage": 3.02,
    "utilizationRate": 76
  },
  {
    "borrowApyPercentage": 4.93,
    "supplyApyPercentage": 3.1,
    "utilizationRate": 77
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.19,
    "utilizationRate": 78
  },
  {
    "borrowApyPercentage": 5.06,
    "supplyApyPercentage": 3.27,
    "utilizationRate": 79
  },
  {
    "borrowApyPercentage": 5.13,
    "supplyApyPercentage": 3.36,
    "utilizationRate": 80
  },
  {
    "borrowApyPercentage": 5.57,
    "supplyApyPercentage": 3.69,
    "utilizationRate": 81
  },
  {
    "borrowApyPercentage": 6.02,
    "supplyApyPercentage": 4.04,
    "utilizationRate": 82
  },
  {
    "borrowApyPercentage": 6.48,
    "supplyApyPercentage": 4.39,
    "utilizationRate": 83
  },
  {
    "borrowApyPercentage": 6.93,
    "supplyApyPercentage": 4.75,
    "utilizationRate": 84
  },
  {
    "borrowApyPercentage": 7.38,
    "supplyApyPercentage": 5.12,
    "utilizationRate": 85
  },
  {
    "borrowApyPercentage": 7.84,
    "supplyApyPercentage": 5.5,
    "utilizationRate": 86
  },
  {
    "borrowApyPercentage": 8.3,
    "supplyApyPercentage": 5.89,
    "utilizationRate": 87
  },
  {
    "borrowApyPercentage": 8.76,
    "supplyApyPercentage": 6.29,
    "utilizationRate": 88
  },
  {
    "borrowApyPercentage": 9.23,
    "supplyApyPercentage": 6.69,
    "utilizationRate": 89
  },
  {
    "borrowApyPercentage": 9.69,
    "supplyApyPercentage": 7.11,
    "utilizationRate": 90
  },
  {
    "borrowApyPercentage": 10.16,
    "supplyApyPercentage": 7.53,
    "utilizationRate": 91
  },
  {
    "borrowApyPercentage": 10.63,
    "supplyApyPercentage": 7.97,
    "utilizationRate": 92
  },
  {
    "borrowApyPercentage": 11.1,
    "supplyApyPercentage": 8.41,
    "utilizationRate": 93
  },
  {
    "borrowApyPercentage": 11.57,
    "supplyApyPercentage": 8.86,
    "utilizationRate": 94
  },
  {
    "borrowApyPercentage": 12.05,
    "supplyApyPercentage": 9.32,
    "utilizationRate": 95
  },
  {
    "borrowApyPercentage": 12.52,
    "supplyApyPercentage": 9.8,
    "utilizationRate": 96
  },
  {
    "borrowApyPercentage": 13,
    "supplyApyPercentage": 10.28,
    "utilizationRate": 97
  },
  {
    "borrowApyPercentage": 13.48,
    "supplyApyPercentage": 10.77,
    "utilizationRate": 98
  },
  {
    "borrowApyPercentage": 13.97,
    "supplyApyPercentage": 11.27,
    "utilizationRate": 99
  },
  {
    "borrowApyPercentage": 14.45,
    "supplyApyPercentage": 11.78,
    "utilizationRate": 100
  }
];

export const altcoinInterestRates = [
  {
    "borrowApyPercentage": 0,
    "supplyApyPercentage": 0,
    "utilizationRate": 0
  },
  {
    "borrowApyPercentage": 0.19,
    "supplyApyPercentage": 0,
    "utilizationRate": 1
  },
  {
    "borrowApyPercentage": 0.38,
    "supplyApyPercentage": 0.01,
    "utilizationRate": 2
  },
  {
    "borrowApyPercentage": 0.56,
    "supplyApyPercentage": 0.01,
    "utilizationRate": 3
  },
  {
    "borrowApyPercentage": 0.75,
    "supplyApyPercentage": 0.02,
    "utilizationRate": 4
  },
  {
    "borrowApyPercentage": 0.94,
    "supplyApyPercentage": 0.04,
    "utilizationRate": 5
  },
  {
    "borrowApyPercentage": 1.13,
    "supplyApyPercentage": 0.06,
    "utilizationRate": 6
  },
  {
    "borrowApyPercentage": 1.32,
    "supplyApyPercentage": 0.08,
    "utilizationRate": 7
  },
  {
    "borrowApyPercentage": 1.51,
    "supplyApyPercentage": 0.1,
    "utilizationRate": 8
  },
  {
    "borrowApyPercentage": 1.7,
    "supplyApyPercentage": 0.13,
    "utilizationRate": 9
  },
  {
    "borrowApyPercentage": 1.89,
    "supplyApyPercentage": 0.15,
    "utilizationRate": 10
  },
  {
    "borrowApyPercentage": 2.08,
    "supplyApyPercentage": 0.19,
    "utilizationRate": 11
  },
  {
    "borrowApyPercentage": 2.28,
    "supplyApyPercentage": 0.22,
    "utilizationRate": 12
  },
  {
    "borrowApyPercentage": 2.47,
    "supplyApyPercentage": 0.26,
    "utilizationRate": 13
  },
  {
    "borrowApyPercentage": 2.66,
    "supplyApyPercentage": 0.3,
    "utilizationRate": 14
  },
  {
    "borrowApyPercentage": 2.85,
    "supplyApyPercentage": 0.35,
    "utilizationRate": 15
  },
  {
    "borrowApyPercentage": 3.05,
    "supplyApyPercentage": 0.4,
    "utilizationRate": 16
  },
  {
    "borrowApyPercentage": 3.24,
    "supplyApyPercentage": 0.45,
    "utilizationRate": 17
  },
  {
    "borrowApyPercentage": 3.43,
    "supplyApyPercentage": 0.5,
    "utilizationRate": 18
  },
  {
    "borrowApyPercentage": 3.63,
    "supplyApyPercentage": 0.56,
    "utilizationRate": 19
  },
  {
    "borrowApyPercentage": 3.82,
    "supplyApyPercentage": 0.62,
    "utilizationRate": 20
  },
  {
    "borrowApyPercentage": 4.02,
    "supplyApyPercentage": 0.68,
    "utilizationRate": 21
  },
  {
    "borrowApyPercentage": 4.21,
    "supplyApyPercentage": 0.75,
    "utilizationRate": 22
  },
  {
    "borrowApyPercentage": 4.41,
    "supplyApyPercentage": 0.82,
    "utilizationRate": 23
  },
  {
    "borrowApyPercentage": 4.6,
    "supplyApyPercentage": 0.89,
    "utilizationRate": 24
  },
  {
    "borrowApyPercentage": 4.8,
    "supplyApyPercentage": 0.97,
    "utilizationRate": 25
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.05,
    "utilizationRate": 26
  },
  {
    "borrowApyPercentage": 5.19,
    "supplyApyPercentage": 1.13,
    "utilizationRate": 27
  },
  {
    "borrowApyPercentage": 5.39,
    "supplyApyPercentage": 1.22,
    "utilizationRate": 28
  },
  {
    "borrowApyPercentage": 5.59,
    "supplyApyPercentage": 1.31,
    "utilizationRate": 29
  },
  {
    "borrowApyPercentage": 5.79,
    "supplyApyPercentage": 1.4,
    "utilizationRate": 30
  },
  {
    "borrowApyPercentage": 5.98,
    "supplyApyPercentage": 1.5,
    "utilizationRate": 31
  },
  {
    "borrowApyPercentage": 6.18,
    "supplyApyPercentage": 1.6,
    "utilizationRate": 32
  },
  {
    "borrowApyPercentage": 6.38,
    "supplyApyPercentage": 1.7,
    "utilizationRate": 33
  },
  {
    "borrowApyPercentage": 6.58,
    "supplyApyPercentage": 1.8,
    "utilizationRate": 34
  },
  {
    "borrowApyPercentage": 6.78,
    "supplyApyPercentage": 1.91,
    "utilizationRate": 35
  },
  {
    "borrowApyPercentage": 6.98,
    "supplyApyPercentage": 2.02,
    "utilizationRate": 36
  },
  {
    "borrowApyPercentage": 7.18,
    "supplyApyPercentage": 2.14,
    "utilizationRate": 37
  },
  {
    "borrowApyPercentage": 7.38,
    "supplyApyPercentage": 2.26,
    "utilizationRate": 38
  },
  {
    "borrowApyPercentage": 7.59,
    "supplyApyPercentage": 2.38,
    "utilizationRate": 39
  },
  {
    "borrowApyPercentage": 7.79,
    "supplyApyPercentage": 2.51,
    "utilizationRate": 40
  },
  {
    "borrowApyPercentage": 7.99,
    "supplyApyPercentage": 2.63,
    "utilizationRate": 41
  },
  {
    "borrowApyPercentage": 8.19,
    "supplyApyPercentage": 2.77,
    "utilizationRate": 42
  },
  {
    "borrowApyPercentage": 8.4,
    "supplyApyPercentage": 2.9,
    "utilizationRate": 43
  },
  {
    "borrowApyPercentage": 8.6,
    "supplyApyPercentage": 3.04,
    "utilizationRate": 44
  },
  {
    "borrowApyPercentage": 8.8,
    "supplyApyPercentage": 3.18,
    "utilizationRate": 45
  },
  {
    "borrowApyPercentage": 9.01,
    "supplyApyPercentage": 3.33,
    "utilizationRate": 46
  },
  {
    "borrowApyPercentage": 9.21,
    "supplyApyPercentage": 3.48,
    "utilizationRate": 47
  },
  {
    "borrowApyPercentage": 9.42,
    "supplyApyPercentage": 3.63,
    "utilizationRate": 48
  },
  {
    "borrowApyPercentage": 9.62,
    "supplyApyPercentage": 3.78,
    "utilizationRate": 49
  },
  {
    "borrowApyPercentage": 9.83,
    "supplyApyPercentage": 3.94,
    "utilizationRate": 50
  },
  {
    "borrowApyPercentage": 10.03,
    "supplyApyPercentage": 4.11,
    "utilizationRate": 51
  },
  {
    "borrowApyPercentage": 10.24,
    "supplyApyPercentage": 4.27,
    "utilizationRate": 52
  },
  {
    "borrowApyPercentage": 10.45,
    "supplyApyPercentage": 4.44,
    "utilizationRate": 53
  },
  {
    "borrowApyPercentage": 10.66,
    "supplyApyPercentage": 4.61,
    "utilizationRate": 54
  },
  {
    "borrowApyPercentage": 10.86,
    "supplyApyPercentage": 4.79,
    "utilizationRate": 55
  },
  {
    "borrowApyPercentage": 11.07,
    "supplyApyPercentage": 4.97,
    "utilizationRate": 56
  },
  {
    "borrowApyPercentage": 11.28,
    "supplyApyPercentage": 5.15,
    "utilizationRate": 57
  },
  {
    "borrowApyPercentage": 11.49,
    "supplyApyPercentage": 5.34,
    "utilizationRate": 58
  },
  {
    "borrowApyPercentage": 11.7,
    "supplyApyPercentage": 5.53,
    "utilizationRate": 59
  },
  {
    "borrowApyPercentage": 11.91,
    "supplyApyPercentage": 5.73,
    "utilizationRate": 60
  },
  {
    "borrowApyPercentage": 12.12,
    "supplyApyPercentage": 5.92,
    "utilizationRate": 61
  },
  {
    "borrowApyPercentage": 12.33,
    "supplyApyPercentage": 6.13,
    "utilizationRate": 62
  },
  {
    "borrowApyPercentage": 12.54,
    "supplyApyPercentage": 6.33,
    "utilizationRate": 63
  },
  {
    "borrowApyPercentage": 12.75,
    "supplyApyPercentage": 6.54,
    "utilizationRate": 64
  },
  {
    "borrowApyPercentage": 12.96,
    "supplyApyPercentage": 6.75,
    "utilizationRate": 65
  },
  {
    "borrowApyPercentage": 13.17,
    "supplyApyPercentage": 6.97,
    "utilizationRate": 66
  },
  {
    "borrowApyPercentage": 13.39,
    "supplyApyPercentage": 7.19,
    "utilizationRate": 67
  },
  {
    "borrowApyPercentage": 13.6,
    "supplyApyPercentage": 7.41,
    "utilizationRate": 68
  },
  {
    "borrowApyPercentage": 13.81,
    "supplyApyPercentage": 7.64,
    "utilizationRate": 69
  },
  {
    "borrowApyPercentage": 14.03,
    "supplyApyPercentage": 7.87,
    "utilizationRate": 70
  },
  {
    "borrowApyPercentage": 14.24,
    "supplyApyPercentage": 8.11,
    "utilizationRate": 71
  },
  {
    "borrowApyPercentage": 14.45,
    "supplyApyPercentage": 8.35,
    "utilizationRate": 72
  },
  {
    "borrowApyPercentage": 14.67,
    "supplyApyPercentage": 8.59,
    "utilizationRate": 73
  },
  {
    "borrowApyPercentage": 14.88,
    "supplyApyPercentage": 8.84,
    "utilizationRate": 74
  },
  {
    "borrowApyPercentage": 15.1,
    "supplyApyPercentage": 9.09,
    "utilizationRate": 75
  },
  {
    "borrowApyPercentage": 15.32,
    "supplyApyPercentage": 9.35,
    "utilizationRate": 76
  },
  {
    "borrowApyPercentage": 15.53,
    "supplyApyPercentage": 9.61,
    "utilizationRate": 77
  },
  {
    "borrowApyPercentage": 15.75,
    "supplyApyPercentage": 9.87,
    "utilizationRate": 78
  },
  {
    "borrowApyPercentage": 15.97,
    "supplyApyPercentage": 10.14,
    "utilizationRate": 79
  },
  {
    "borrowApyPercentage": 16.18,
    "supplyApyPercentage": 10.41,
    "utilizationRate": 80
  },
  {
    "borrowApyPercentage": 17.23,
    "supplyApyPercentage": 11.21,
    "utilizationRate": 81
  },
  {
    "borrowApyPercentage": 18.29,
    "supplyApyPercentage": 12.04,
    "utilizationRate": 82
  },
  {
    "borrowApyPercentage": 19.36,
    "supplyApyPercentage": 12.89,
    "utilizationRate": 83
  },
  {
    "borrowApyPercentage": 20.44,
    "supplyApyPercentage": 13.76,
    "utilizationRate": 84
  },
  {
    "borrowApyPercentage": 21.53,
    "supplyApyPercentage": 14.65,
    "utilizationRate": 85
  },
  {
    "borrowApyPercentage": 22.63,
    "supplyApyPercentage": 15.57,
    "utilizationRate": 86
  },
  {
    "borrowApyPercentage": 23.74,
    "supplyApyPercentage": 16.52,
    "utilizationRate": 87
  },
  {
    "borrowApyPercentage": 24.86,
    "supplyApyPercentage": 17.49,
    "utilizationRate": 88
  },
  {
    "borrowApyPercentage": 25.99,
    "supplyApyPercentage": 18.48,
    "utilizationRate": 89
  },
  {
    "borrowApyPercentage": 27.12,
    "supplyApyPercentage": 19.51,
    "utilizationRate": 90
  },
  {
    "borrowApyPercentage": 28.27,
    "supplyApyPercentage": 20.56,
    "utilizationRate": 91
  },
  {
    "borrowApyPercentage": 29.43,
    "supplyApyPercentage": 21.63,
    "utilizationRate": 92
  },
  {
    "borrowApyPercentage": 30.6,
    "supplyApyPercentage": 22.73,
    "utilizationRate": 93
  },
  {
    "borrowApyPercentage": 31.78,
    "supplyApyPercentage": 23.87,
    "utilizationRate": 94
  },
  {
    "borrowApyPercentage": 32.98,
    "supplyApyPercentage": 25.03,
    "utilizationRate": 95
  },
  {
    "borrowApyPercentage": 34.18,
    "supplyApyPercentage": 26.22,
    "utilizationRate": 96
  },
  {
    "borrowApyPercentage": 35.39,
    "supplyApyPercentage": 27.44,
    "utilizationRate": 97
  },
  {
    "borrowApyPercentage": 36.62,
    "supplyApyPercentage": 28.69,
    "utilizationRate": 98
  },
  {
    "borrowApyPercentage": 37.85,
    "supplyApyPercentage": 29.98,
    "utilizationRate": 99
  },
  {
    "borrowApyPercentage": 39.1,
    "supplyApyPercentage": 31.29,
    "utilizationRate": 100
  }
];

export const fixedInterestRates7_5 = [
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 0,
    "utilizationRate": 0
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 0.06,
    "utilizationRate": 1
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 0.12,
    "utilizationRate": 2
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 0.18,
    "utilizationRate": 3
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 0.25,
    "utilizationRate": 4
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 0.31,
    "utilizationRate": 5
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 0.37,
    "utilizationRate": 6
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 0.43,
    "utilizationRate": 7
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 0.49,
    "utilizationRate": 8
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 0.55,
    "utilizationRate": 9
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 0.62,
    "utilizationRate": 10
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 0.68,
    "utilizationRate": 11
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 0.74,
    "utilizationRate": 12
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 0.8,
    "utilizationRate": 13
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 0.86,
    "utilizationRate": 14
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 0.93,
    "utilizationRate": 15
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 0.99,
    "utilizationRate": 16
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 1.05,
    "utilizationRate": 17
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 1.11,
    "utilizationRate": 18
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 1.17,
    "utilizationRate": 19
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 1.24,
    "utilizationRate": 20
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 1.3,
    "utilizationRate": 21
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 1.36,
    "utilizationRate": 22
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 1.42,
    "utilizationRate": 23
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 1.49,
    "utilizationRate": 24
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 1.55,
    "utilizationRate": 25
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 1.61,
    "utilizationRate": 26
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 1.67,
    "utilizationRate": 27
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 1.74,
    "utilizationRate": 28
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 1.8,
    "utilizationRate": 29
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 1.86,
    "utilizationRate": 30
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 1.92,
    "utilizationRate": 31
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 1.99,
    "utilizationRate": 32
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 2.05,
    "utilizationRate": 33
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 2.11,
    "utilizationRate": 34
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 2.17,
    "utilizationRate": 35
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 2.24,
    "utilizationRate": 36
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 2.3,
    "utilizationRate": 37
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 2.36,
    "utilizationRate": 38
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 2.43,
    "utilizationRate": 39
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 2.49,
    "utilizationRate": 40
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 2.55,
    "utilizationRate": 41
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 2.62,
    "utilizationRate": 42
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 2.68,
    "utilizationRate": 43
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 2.74,
    "utilizationRate": 44
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 2.8,
    "utilizationRate": 45
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 2.87,
    "utilizationRate": 46
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 2.93,
    "utilizationRate": 47
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 2.99,
    "utilizationRate": 48
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 3.06,
    "utilizationRate": 49
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 3.12,
    "utilizationRate": 50
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 3.18,
    "utilizationRate": 51
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 3.25,
    "utilizationRate": 52
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 3.31,
    "utilizationRate": 53
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 3.38,
    "utilizationRate": 54
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 3.44,
    "utilizationRate": 55
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 3.5,
    "utilizationRate": 56
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 3.57,
    "utilizationRate": 57
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 3.63,
    "utilizationRate": 58
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 3.69,
    "utilizationRate": 59
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 3.76,
    "utilizationRate": 60
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 3.82,
    "utilizationRate": 61
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 3.88,
    "utilizationRate": 62
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 3.95,
    "utilizationRate": 63
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 4.01,
    "utilizationRate": 64
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 4.08,
    "utilizationRate": 65
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 4.14,
    "utilizationRate": 66
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 4.2,
    "utilizationRate": 67
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 4.27,
    "utilizationRate": 68
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 4.33,
    "utilizationRate": 69
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 4.4,
    "utilizationRate": 70
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 4.46,
    "utilizationRate": 71
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 4.53,
    "utilizationRate": 72
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 4.59,
    "utilizationRate": 73
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 4.65,
    "utilizationRate": 74
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 4.72,
    "utilizationRate": 75
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 4.78,
    "utilizationRate": 76
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 4.85,
    "utilizationRate": 77
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 4.91,
    "utilizationRate": 78
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 4.98,
    "utilizationRate": 79
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 5.04,
    "utilizationRate": 80
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 5.11,
    "utilizationRate": 81
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 5.17,
    "utilizationRate": 82
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 5.23,
    "utilizationRate": 83
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 5.3,
    "utilizationRate": 84
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 5.36,
    "utilizationRate": 85
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 5.43,
    "utilizationRate": 86
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 5.49,
    "utilizationRate": 87
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 5.56,
    "utilizationRate": 88
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 5.62,
    "utilizationRate": 89
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 5.69,
    "utilizationRate": 90
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 5.75,
    "utilizationRate": 91
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 5.82,
    "utilizationRate": 92
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 5.88,
    "utilizationRate": 93
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 5.95,
    "utilizationRate": 94
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 6.01,
    "utilizationRate": 95
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 6.08,
    "utilizationRate": 96
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 6.14,
    "utilizationRate": 97
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 6.21,
    "utilizationRate": 98
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 6.27,
    "utilizationRate": 99
  },
  {
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 6.34,
    "utilizationRate": 100
  }
];

export const fixedInterestRates5 = [
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0,
    "utilizationRate": 0
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.04148,
    "utilizationRate": 1
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.082978,
    "utilizationRate": 2
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.124492,
    "utilizationRate": 3
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.166024,
    "utilizationRate": 4
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.207573,
    "utilizationRate": 5
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.24914,
    "utilizationRate": 6
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.290723,
    "utilizationRate": 7
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.332324,
    "utilizationRate": 8
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.373942,
    "utilizationRate": 9
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.415578,
    "utilizationRate": 10
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.45723,
    "utilizationRate": 11
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.4989,
    "utilizationRate": 12
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.540587,
    "utilizationRate": 13
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.582292,
    "utilizationRate": 14
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.624014,
    "utilizationRate": 15
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.665753,
    "utilizationRate": 16
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.707509,
    "utilizationRate": 17
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.749283,
    "utilizationRate": 18
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.791074,
    "utilizationRate": 19
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.832882,
    "utilizationRate": 20
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.874708,
    "utilizationRate": 21
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.916551,
    "utilizationRate": 22
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 0.958411,
    "utilizationRate": 23
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.000289,
    "utilizationRate": 24
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.042184,
    "utilizationRate": 25
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.084097,
    "utilizationRate": 26
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.126027,
    "utilizationRate": 27
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.167974,
    "utilizationRate": 28
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.209939,
    "utilizationRate": 29
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.251921,
    "utilizationRate": 30
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.29392,
    "utilizationRate": 31
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.335937,
    "utilizationRate": 32
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.377972,
    "utilizationRate": 33
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.420024,
    "utilizationRate": 34
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.462093,
    "utilizationRate": 35
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.50418,
    "utilizationRate": 36
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.546284,
    "utilizationRate": 37
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.588405,
    "utilizationRate": 38
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.630545,
    "utilizationRate": 39
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.672701,
    "utilizationRate": 40
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.714875,
    "utilizationRate": 41
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.757067,
    "utilizationRate": 42
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.799276,
    "utilizationRate": 43
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.841503,
    "utilizationRate": 44
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.883747,
    "utilizationRate": 45
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.926008,
    "utilizationRate": 46
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.968287,
    "utilizationRate": 47
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.010584,
    "utilizationRate": 48
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.052898,
    "utilizationRate": 49
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.09523,
    "utilizationRate": 50
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.137579,
    "utilizationRate": 51
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.179946,
    "utilizationRate": 52
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.222331,
    "utilizationRate": 53
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.264733,
    "utilizationRate": 54
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.307153,
    "utilizationRate": 55
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.34959,
    "utilizationRate": 56
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.392045,
    "utilizationRate": 57
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.434517,
    "utilizationRate": 58
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.477007,
    "utilizationRate": 59
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.519515,
    "utilizationRate": 60
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.56204,
    "utilizationRate": 61
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.604583,
    "utilizationRate": 62
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.647144,
    "utilizationRate": 63
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.689722,
    "utilizationRate": 64
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.732318,
    "utilizationRate": 65
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.774932,
    "utilizationRate": 66
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.817563,
    "utilizationRate": 67
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.860212,
    "utilizationRate": 68
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.902879,
    "utilizationRate": 69
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.945563,
    "utilizationRate": 70
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.988265,
    "utilizationRate": 71
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.030985,
    "utilizationRate": 72
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.073722,
    "utilizationRate": 73
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.116478,
    "utilizationRate": 74
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.159251,
    "utilizationRate": 75
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.202041,
    "utilizationRate": 76
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.24485,
    "utilizationRate": 77
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.287676,
    "utilizationRate": 78
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.33052,
    "utilizationRate": 79
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.373382,
    "utilizationRate": 80
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.416261,
    "utilizationRate": 81
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.459159,
    "utilizationRate": 82
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.502074,
    "utilizationRate": 83
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.545007,
    "utilizationRate": 84
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.587957,
    "utilizationRate": 85
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.630926,
    "utilizationRate": 86
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.673912,
    "utilizationRate": 87
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.716916,
    "utilizationRate": 88
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.759938,
    "utilizationRate": 89
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.802978,
    "utilizationRate": 90
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.846036,
    "utilizationRate": 91
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.889112,
    "utilizationRate": 92
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.932205,
    "utilizationRate": 93
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 3.975316,
    "utilizationRate": 94
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 4.018446,
    "utilizationRate": 95
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 4.061593,
    "utilizationRate": 96
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 4.104758,
    "utilizationRate": 97
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 4.147941,
    "utilizationRate": 98
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 4.191141,
    "utilizationRate": 99
  },
  {
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 4.23436,
    "utilizationRate": 100
  }
];

export const IRM_09_25_80 = [
  {
    "utilizationRate": 0,
    "borrowApyPercentage": 0,
    "supplyApyPercentage": 0
  },
  {
    "utilizationRate": 1,
    "borrowApyPercentage": 0.11250000000000002,
    "supplyApyPercentage": 0.000928125
  },
  {
    "utilizationRate": 2,
    "borrowApyPercentage": 0.22500000000000003,
    "supplyApyPercentage": 0.0037125
  },
  {
    "utilizationRate": 3,
    "borrowApyPercentage": 0.3375,
    "supplyApyPercentage": 0.008353125000000001
  },
  {
    "utilizationRate": 4,
    "borrowApyPercentage": 0.45000000000000007,
    "supplyApyPercentage": 0.01485
  },
  {
    "utilizationRate": 5,
    "borrowApyPercentage": 0.5625,
    "supplyApyPercentage": 0.023203124999999998
  },
  {
    "utilizationRate": 6,
    "borrowApyPercentage": 0.675,
    "supplyApyPercentage": 0.033412500000000005
  },
  {
    "utilizationRate": 7,
    "borrowApyPercentage": 0.7875,
    "supplyApyPercentage": 0.045478124999999994
  },
  {
    "utilizationRate": 8,
    "borrowApyPercentage": 0.9000000000000001,
    "supplyApyPercentage": 0.0594
  },
  {
    "utilizationRate": 9,
    "borrowApyPercentage": 1.0125,
    "supplyApyPercentage": 0.075178125
  },
  {
    "utilizationRate": 10,
    "borrowApyPercentage": 1.125,
    "supplyApyPercentage": 0.09281249999999999
  },
  {
    "utilizationRate": 11,
    "borrowApyPercentage": 1.2375,
    "supplyApyPercentage": 0.11230312499999999
  },
  {
    "utilizationRate": 12,
    "borrowApyPercentage": 1.35,
    "supplyApyPercentage": 0.13365000000000002
  },
  {
    "utilizationRate": 13,
    "borrowApyPercentage": 1.4625000000000001,
    "supplyApyPercentage": 0.156853125
  },
  {
    "utilizationRate": 14,
    "borrowApyPercentage": 1.575,
    "supplyApyPercentage": 0.18191249999999998
  },
  {
    "utilizationRate": 15,
    "borrowApyPercentage": 1.6875,
    "supplyApyPercentage": 0.20882812500000003
  },
  {
    "utilizationRate": 16,
    "borrowApyPercentage": 1.8000000000000003,
    "supplyApyPercentage": 0.2376
  },
  {
    "utilizationRate": 17,
    "borrowApyPercentage": 1.9124999999999999,
    "supplyApyPercentage": 0.268228125
  },
  {
    "utilizationRate": 18,
    "borrowApyPercentage": 2.025,
    "supplyApyPercentage": 0.3007125
  },
  {
    "utilizationRate": 19,
    "borrowApyPercentage": 2.1375,
    "supplyApyPercentage": 0.33505312499999995
  },
  {
    "utilizationRate": 20,
    "borrowApyPercentage": 2.25,
    "supplyApyPercentage": 0.37124999999999997
  },
  {
    "utilizationRate": 21,
    "borrowApyPercentage": 2.3625000000000003,
    "supplyApyPercentage": 0.4093031250000001
  },
  {
    "utilizationRate": 22,
    "borrowApyPercentage": 2.475,
    "supplyApyPercentage": 0.44921249999999996
  },
  {
    "utilizationRate": 23,
    "borrowApyPercentage": 2.5875,
    "supplyApyPercentage": 0.490978125
  },
  {
    "utilizationRate": 24,
    "borrowApyPercentage": 2.7,
    "supplyApyPercentage": 0.5346000000000001
  },
  {
    "utilizationRate": 25,
    "borrowApyPercentage": 2.8125,
    "supplyApyPercentage": 0.580078125
  },
  {
    "utilizationRate": 26,
    "borrowApyPercentage": 2.9250000000000003,
    "supplyApyPercentage": 0.6274125
  },
  {
    "utilizationRate": 27,
    "borrowApyPercentage": 3.0375,
    "supplyApyPercentage": 0.676603125
  },
  {
    "utilizationRate": 28,
    "borrowApyPercentage": 3.15,
    "supplyApyPercentage": 0.7276499999999999
  },
  {
    "utilizationRate": 29,
    "borrowApyPercentage": 3.2625,
    "supplyApyPercentage": 0.780553125
  },
  {
    "utilizationRate": 30,
    "borrowApyPercentage": 3.375,
    "supplyApyPercentage": 0.8353125000000001
  },
  {
    "utilizationRate": 31,
    "borrowApyPercentage": 3.4875000000000003,
    "supplyApyPercentage": 0.8919281250000001
  },
  {
    "utilizationRate": 32,
    "borrowApyPercentage": 3.6000000000000005,
    "supplyApyPercentage": 0.9504
  },
  {
    "utilizationRate": 33,
    "borrowApyPercentage": 3.7125,
    "supplyApyPercentage": 1.010728125
  },
  {
    "utilizationRate": 34,
    "borrowApyPercentage": 3.8249999999999997,
    "supplyApyPercentage": 1.0729125
  },
  {
    "utilizationRate": 35,
    "borrowApyPercentage": 3.9375,
    "supplyApyPercentage": 1.136953125
  },
  {
    "utilizationRate": 36,
    "borrowApyPercentage": 4.05,
    "supplyApyPercentage": 1.20285
  },
  {
    "utilizationRate": 37,
    "borrowApyPercentage": 4.1625000000000005,
    "supplyApyPercentage": 1.270603125
  },
  {
    "utilizationRate": 38,
    "borrowApyPercentage": 4.275,
    "supplyApyPercentage": 1.3402124999999998
  },
  {
    "utilizationRate": 39,
    "borrowApyPercentage": 4.3875,
    "supplyApyPercentage": 1.411678125
  },
  {
    "utilizationRate": 40,
    "borrowApyPercentage": 4.5,
    "supplyApyPercentage": 1.4849999999999999
  },
  {
    "utilizationRate": 41,
    "borrowApyPercentage": 4.6125,
    "supplyApyPercentage": 1.560178125
  },
  {
    "utilizationRate": 42,
    "borrowApyPercentage": 4.7250000000000005,
    "supplyApyPercentage": 1.6372125000000004
  },
  {
    "utilizationRate": 43,
    "borrowApyPercentage": 4.8375,
    "supplyApyPercentage": 1.7161031250000003
  },
  {
    "utilizationRate": 44,
    "borrowApyPercentage": 4.95,
    "supplyApyPercentage": 1.7968499999999998
  },
  {
    "utilizationRate": 45,
    "borrowApyPercentage": 5.0625,
    "supplyApyPercentage": 1.8794531250000004
  },
  {
    "utilizationRate": 46,
    "borrowApyPercentage": 5.175,
    "supplyApyPercentage": 1.9639125
  },
  {
    "utilizationRate": 47,
    "borrowApyPercentage": 5.2875000000000005,
    "supplyApyPercentage": 2.050228125
  },
  {
    "utilizationRate": 48,
    "borrowApyPercentage": 5.4,
    "supplyApyPercentage": 2.1384000000000003
  },
  {
    "utilizationRate": 49,
    "borrowApyPercentage": 5.5125,
    "supplyApyPercentage": 2.2284281249999998
  },
  {
    "utilizationRate": 50,
    "borrowApyPercentage": 5.625,
    "supplyApyPercentage": 2.3203125
  },
  {
    "utilizationRate": 51,
    "borrowApyPercentage": 5.7375,
    "supplyApyPercentage": 2.414053125
  },
  {
    "utilizationRate": 52,
    "borrowApyPercentage": 5.8500000000000005,
    "supplyApyPercentage": 2.50965
  },
  {
    "utilizationRate": 53,
    "borrowApyPercentage": 5.9625,
    "supplyApyPercentage": 2.607103125
  },
  {
    "utilizationRate": 54,
    "borrowApyPercentage": 6.075,
    "supplyApyPercentage": 2.7064125
  },
  {
    "utilizationRate": 55,
    "borrowApyPercentage": 6.1875,
    "supplyApyPercentage": 2.8075781249999996
  },
  {
    "utilizationRate": 56,
    "borrowApyPercentage": 6.3,
    "supplyApyPercentage": 2.9105999999999996
  },
  {
    "utilizationRate": 57,
    "borrowApyPercentage": 6.4125000000000005,
    "supplyApyPercentage": 3.015478125
  },
  {
    "utilizationRate": 58,
    "borrowApyPercentage": 6.525,
    "supplyApyPercentage": 3.1222125
  },
  {
    "utilizationRate": 59,
    "borrowApyPercentage": 6.6375,
    "supplyApyPercentage": 3.230803125
  },
  {
    "utilizationRate": 60,
    "borrowApyPercentage": 6.75,
    "supplyApyPercentage": 3.3412500000000005
  },
  {
    "utilizationRate": 61,
    "borrowApyPercentage": 6.862499999999999,
    "supplyApyPercentage": 3.4535531249999996
  },
  {
    "utilizationRate": 62,
    "borrowApyPercentage": 6.9750000000000005,
    "supplyApyPercentage": 3.5677125000000003
  },
  {
    "utilizationRate": 63,
    "borrowApyPercentage": 7.0875,
    "supplyApyPercentage": 3.683728125
  },
  {
    "utilizationRate": 64,
    "borrowApyPercentage": 7.200000000000001,
    "supplyApyPercentage": 3.8016
  },
  {
    "utilizationRate": 65,
    "borrowApyPercentage": 7.3125,
    "supplyApyPercentage": 3.9213281249999996
  },
  {
    "utilizationRate": 66,
    "borrowApyPercentage": 7.425,
    "supplyApyPercentage": 4.0429125
  },
  {
    "utilizationRate": 67,
    "borrowApyPercentage": 7.537500000000001,
    "supplyApyPercentage": 4.1663531250000005
  },
  {
    "utilizationRate": 68,
    "borrowApyPercentage": 7.6499999999999995,
    "supplyApyPercentage": 4.29165
  },
  {
    "utilizationRate": 69,
    "borrowApyPercentage": 7.7625,
    "supplyApyPercentage": 4.418803124999999
  },
  {
    "utilizationRate": 70,
    "borrowApyPercentage": 7.875,
    "supplyApyPercentage": 4.5478125
  },
  {
    "utilizationRate": 71,
    "borrowApyPercentage": 7.9875,
    "supplyApyPercentage": 4.678678124999999
  },
  {
    "utilizationRate": 72,
    "borrowApyPercentage": 8.1,
    "supplyApyPercentage": 4.8114
  },
  {
    "utilizationRate": 73,
    "borrowApyPercentage": 8.2125,
    "supplyApyPercentage": 4.945978124999999
  },
  {
    "utilizationRate": 74,
    "borrowApyPercentage": 8.325000000000001,
    "supplyApyPercentage": 5.0824125
  },
  {
    "utilizationRate": 75,
    "borrowApyPercentage": 8.4375,
    "supplyApyPercentage": 5.220703124999999
  },
  {
    "utilizationRate": 76,
    "borrowApyPercentage": 8.55,
    "supplyApyPercentage": 5.360849999999999
  },
  {
    "utilizationRate": 77,
    "borrowApyPercentage": 8.6625,
    "supplyApyPercentage": 5.502853124999999
  },
  {
    "utilizationRate": 78,
    "borrowApyPercentage": 8.775,
    "supplyApyPercentage": 5.6467125
  },
  {
    "utilizationRate": 79,
    "borrowApyPercentage": 8.887500000000001,
    "supplyApyPercentage": 5.792428125
  },
  {
    "utilizationRate": 80,
    "borrowApyPercentage": 9,
    "supplyApyPercentage": 5.9399999999999995
  },
  {
    "utilizationRate": 81,
    "borrowApyPercentage": 9.8,
    "supplyApyPercentage": 6.548850000000001
  },
  {
    "utilizationRate": 82,
    "borrowApyPercentage": 10.6,
    "supplyApyPercentage": 7.1709
  },
  {
    "utilizationRate": 83,
    "borrowApyPercentage": 11.399999999999999,
    "supplyApyPercentage": 7.806149999999999
  },
  {
    "utilizationRate": 84,
    "borrowApyPercentage": 12.2,
    "supplyApyPercentage": 8.454599999999997
  },
  {
    "utilizationRate": 85,
    "borrowApyPercentage": 13,
    "supplyApyPercentage": 9.116249999999999
  },
  {
    "utilizationRate": 86,
    "borrowApyPercentage": 13.8,
    "supplyApyPercentage": 9.7911
  },
  {
    "utilizationRate": 87,
    "borrowApyPercentage": 14.600000000000001,
    "supplyApyPercentage": 10.47915
  },
  {
    "utilizationRate": 88,
    "borrowApyPercentage": 15.4,
    "supplyApyPercentage": 11.1804
  },
  {
    "utilizationRate": 89,
    "borrowApyPercentage": 16.2,
    "supplyApyPercentage": 11.894849999999996
  },
  {
    "utilizationRate": 90,
    "borrowApyPercentage": 17,
    "supplyApyPercentage": 12.6225
  },
  {
    "utilizationRate": 91,
    "borrowApyPercentage": 17.8,
    "supplyApyPercentage": 13.363350000000002
  },
  {
    "utilizationRate": 92,
    "borrowApyPercentage": 18.6,
    "supplyApyPercentage": 14.1174
  },
  {
    "utilizationRate": 93,
    "borrowApyPercentage": 19.400000000000002,
    "supplyApyPercentage": 14.884650000000002
  },
  {
    "utilizationRate": 94,
    "borrowApyPercentage": 20.200000000000003,
    "supplyApyPercentage": 15.665099999999999
  },
  {
    "utilizationRate": 95,
    "borrowApyPercentage": 21,
    "supplyApyPercentage": 16.45875
  },
  {
    "utilizationRate": 96,
    "borrowApyPercentage": 21.8,
    "supplyApyPercentage": 17.2656
  },
  {
    "utilizationRate": 97,
    "borrowApyPercentage": 22.6,
    "supplyApyPercentage": 18.085649999999998
  },
  {
    "utilizationRate": 98,
    "borrowApyPercentage": 23.4,
    "supplyApyPercentage": 18.9189
  },
  {
    "utilizationRate": 99,
    "borrowApyPercentage": 24.2,
    "supplyApyPercentage": 19.765349999999998
  },
  {
    "utilizationRate": 100,
    "borrowApyPercentage": 25,
    "supplyApyPercentage": 20.625
  }
];

export const IRM_08_25_80 = [
  {
    "utilizationRate": 0,
    "borrowApyPercentage": 0,
    "supplyApyPercentage": 0
  },
  {
    "utilizationRate": 1,
    "borrowApyPercentage": 0.1,
    "supplyApyPercentage": 0.0008250000000000001
  },
  {
    "utilizationRate": 2,
    "borrowApyPercentage": 0.2,
    "supplyApyPercentage": 0.0033000000000000004
  },
  {
    "utilizationRate": 3,
    "borrowApyPercentage": 0.30000000000000004,
    "supplyApyPercentage": 0.007425
  },
  {
    "utilizationRate": 4,
    "borrowApyPercentage": 0.4,
    "supplyApyPercentage": 0.013200000000000002
  },
  {
    "utilizationRate": 5,
    "borrowApyPercentage": 0.5,
    "supplyApyPercentage": 0.020625
  },
  {
    "utilizationRate": 6,
    "borrowApyPercentage": 0.6000000000000001,
    "supplyApyPercentage": 0.0297
  },
  {
    "utilizationRate": 7,
    "borrowApyPercentage": 0.7000000000000001,
    "supplyApyPercentage": 0.040425
  },
  {
    "utilizationRate": 8,
    "borrowApyPercentage": 0.8,
    "supplyApyPercentage": 0.05280000000000001
  },
  {
    "utilizationRate": 9,
    "borrowApyPercentage": 0.9000000000000001,
    "supplyApyPercentage": 0.06682500000000001
  },
  {
    "utilizationRate": 10,
    "borrowApyPercentage": 1,
    "supplyApyPercentage": 0.0825
  },
  {
    "utilizationRate": 11,
    "borrowApyPercentage": 1.1,
    "supplyApyPercentage": 0.099825
  },
  {
    "utilizationRate": 12,
    "borrowApyPercentage": 1.2000000000000002,
    "supplyApyPercentage": 0.1188
  },
  {
    "utilizationRate": 13,
    "borrowApyPercentage": 1.3,
    "supplyApyPercentage": 0.13942500000000002
  },
  {
    "utilizationRate": 14,
    "borrowApyPercentage": 1.4000000000000001,
    "supplyApyPercentage": 0.1617
  },
  {
    "utilizationRate": 15,
    "borrowApyPercentage": 1.5,
    "supplyApyPercentage": 0.18562499999999998
  },
  {
    "utilizationRate": 16,
    "borrowApyPercentage": 1.6,
    "supplyApyPercentage": 0.21120000000000003
  },
  {
    "utilizationRate": 17,
    "borrowApyPercentage": 1.7000000000000002,
    "supplyApyPercentage": 0.238425
  },
  {
    "utilizationRate": 18,
    "borrowApyPercentage": 1.8000000000000003,
    "supplyApyPercentage": 0.26730000000000004
  },
  {
    "utilizationRate": 19,
    "borrowApyPercentage": 1.9000000000000004,
    "supplyApyPercentage": 0.297825
  },
  {
    "utilizationRate": 20,
    "borrowApyPercentage": 2,
    "supplyApyPercentage": 0.33
  },
  {
    "utilizationRate": 21,
    "borrowApyPercentage": 2.1,
    "supplyApyPercentage": 0.36382499999999995
  },
  {
    "utilizationRate": 22,
    "borrowApyPercentage": 2.2,
    "supplyApyPercentage": 0.3993
  },
  {
    "utilizationRate": 23,
    "borrowApyPercentage": 2.3000000000000003,
    "supplyApyPercentage": 0.436425
  },
  {
    "utilizationRate": 24,
    "borrowApyPercentage": 2.4000000000000004,
    "supplyApyPercentage": 0.4752
  },
  {
    "utilizationRate": 25,
    "borrowApyPercentage": 2.5,
    "supplyApyPercentage": 0.515625
  },
  {
    "utilizationRate": 26,
    "borrowApyPercentage": 2.6,
    "supplyApyPercentage": 0.5577000000000001
  },
  {
    "utilizationRate": 27,
    "borrowApyPercentage": 2.7,
    "supplyApyPercentage": 0.6014250000000001
  },
  {
    "utilizationRate": 28,
    "borrowApyPercentage": 2.8000000000000003,
    "supplyApyPercentage": 0.6468
  },
  {
    "utilizationRate": 29,
    "borrowApyPercentage": 2.9000000000000004,
    "supplyApyPercentage": 0.6938250000000001
  },
  {
    "utilizationRate": 30,
    "borrowApyPercentage": 3,
    "supplyApyPercentage": 0.7424999999999999
  },
  {
    "utilizationRate": 31,
    "borrowApyPercentage": 3.1,
    "supplyApyPercentage": 0.792825
  },
  {
    "utilizationRate": 32,
    "borrowApyPercentage": 3.2,
    "supplyApyPercentage": 0.8448000000000001
  },
  {
    "utilizationRate": 33,
    "borrowApyPercentage": 3.3000000000000003,
    "supplyApyPercentage": 0.8984249999999999
  },
  {
    "utilizationRate": 34,
    "borrowApyPercentage": 3.4000000000000004,
    "supplyApyPercentage": 0.9537
  },
  {
    "utilizationRate": 35,
    "borrowApyPercentage": 3.5000000000000004,
    "supplyApyPercentage": 1.010625
  },
  {
    "utilizationRate": 36,
    "borrowApyPercentage": 3.6000000000000005,
    "supplyApyPercentage": 1.0692000000000002
  },
  {
    "utilizationRate": 37,
    "borrowApyPercentage": 3.7000000000000006,
    "supplyApyPercentage": 1.1294250000000001
  },
  {
    "utilizationRate": 38,
    "borrowApyPercentage": 3.8000000000000007,
    "supplyApyPercentage": 1.1913
  },
  {
    "utilizationRate": 39,
    "borrowApyPercentage": 3.900000000000001,
    "supplyApyPercentage": 1.2548250000000003
  },
  {
    "utilizationRate": 40,
    "borrowApyPercentage": 4,
    "supplyApyPercentage": 1.32
  },
  {
    "utilizationRate": 41,
    "borrowApyPercentage": 4.1000000000000005,
    "supplyApyPercentage": 1.386825
  },
  {
    "utilizationRate": 42,
    "borrowApyPercentage": 4.2,
    "supplyApyPercentage": 1.4552999999999998
  },
  {
    "utilizationRate": 43,
    "borrowApyPercentage": 4.3,
    "supplyApyPercentage": 1.5254249999999996
  },
  {
    "utilizationRate": 44,
    "borrowApyPercentage": 4.4,
    "supplyApyPercentage": 1.5972
  },
  {
    "utilizationRate": 45,
    "borrowApyPercentage": 4.5,
    "supplyApyPercentage": 1.6706249999999998
  },
  {
    "utilizationRate": 46,
    "borrowApyPercentage": 4.6000000000000005,
    "supplyApyPercentage": 1.7457
  },
  {
    "utilizationRate": 47,
    "borrowApyPercentage": 4.7,
    "supplyApyPercentage": 1.8224250000000002
  },
  {
    "utilizationRate": 48,
    "borrowApyPercentage": 4.800000000000001,
    "supplyApyPercentage": 1.9008
  },
  {
    "utilizationRate": 49,
    "borrowApyPercentage": 4.9,
    "supplyApyPercentage": 1.9808250000000003
  },
  {
    "utilizationRate": 50,
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 2.0625
  },
  {
    "utilizationRate": 51,
    "borrowApyPercentage": 5.1000000000000005,
    "supplyApyPercentage": 2.145825
  },
  {
    "utilizationRate": 52,
    "borrowApyPercentage": 5.2,
    "supplyApyPercentage": 2.2308000000000003
  },
  {
    "utilizationRate": 53,
    "borrowApyPercentage": 5.300000000000001,
    "supplyApyPercentage": 2.317425
  },
  {
    "utilizationRate": 54,
    "borrowApyPercentage": 5.4,
    "supplyApyPercentage": 2.4057000000000004
  },
  {
    "utilizationRate": 55,
    "borrowApyPercentage": 5.5,
    "supplyApyPercentage": 2.495625
  },
  {
    "utilizationRate": 56,
    "borrowApyPercentage": 5.6000000000000005,
    "supplyApyPercentage": 2.5872
  },
  {
    "utilizationRate": 57,
    "borrowApyPercentage": 5.7,
    "supplyApyPercentage": 2.6804249999999996
  },
  {
    "utilizationRate": 58,
    "borrowApyPercentage": 5.800000000000001,
    "supplyApyPercentage": 2.7753000000000005
  },
  {
    "utilizationRate": 59,
    "borrowApyPercentage": 5.9,
    "supplyApyPercentage": 2.8718250000000003
  },
  {
    "utilizationRate": 60,
    "borrowApyPercentage": 6,
    "supplyApyPercentage": 2.9699999999999998
  },
  {
    "utilizationRate": 61,
    "borrowApyPercentage": 6.1000000000000005,
    "supplyApyPercentage": 3.0698250000000002
  },
  {
    "utilizationRate": 62,
    "borrowApyPercentage": 6.2,
    "supplyApyPercentage": 3.1713
  },
  {
    "utilizationRate": 63,
    "borrowApyPercentage": 6.3,
    "supplyApyPercentage": 3.2744249999999995
  },
  {
    "utilizationRate": 64,
    "borrowApyPercentage": 6.4,
    "supplyApyPercentage": 3.3792000000000004
  },
  {
    "utilizationRate": 65,
    "borrowApyPercentage": 6.5,
    "supplyApyPercentage": 3.4856249999999998
  },
  {
    "utilizationRate": 66,
    "borrowApyPercentage": 6.6000000000000005,
    "supplyApyPercentage": 3.5936999999999997
  },
  {
    "utilizationRate": 67,
    "borrowApyPercentage": 6.7,
    "supplyApyPercentage": 3.7034249999999997
  },
  {
    "utilizationRate": 68,
    "borrowApyPercentage": 6.800000000000001,
    "supplyApyPercentage": 3.8148
  },
  {
    "utilizationRate": 69,
    "borrowApyPercentage": 6.9,
    "supplyApyPercentage": 3.927825
  },
  {
    "utilizationRate": 70,
    "borrowApyPercentage": 7.000000000000001,
    "supplyApyPercentage": 4.0425
  },
  {
    "utilizationRate": 71,
    "borrowApyPercentage": 7.1000000000000005,
    "supplyApyPercentage": 4.158825
  },
  {
    "utilizationRate": 72,
    "borrowApyPercentage": 7.200000000000001,
    "supplyApyPercentage": 4.276800000000001
  },
  {
    "utilizationRate": 73,
    "borrowApyPercentage": 7.300000000000001,
    "supplyApyPercentage": 4.396425000000001
  },
  {
    "utilizationRate": 74,
    "borrowApyPercentage": 7.400000000000001,
    "supplyApyPercentage": 4.5177000000000005
  },
  {
    "utilizationRate": 75,
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 4.640625
  },
  {
    "utilizationRate": 76,
    "borrowApyPercentage": 7.600000000000001,
    "supplyApyPercentage": 4.7652
  },
  {
    "utilizationRate": 77,
    "borrowApyPercentage": 7.7,
    "supplyApyPercentage": 4.891425
  },
  {
    "utilizationRate": 78,
    "borrowApyPercentage": 7.800000000000002,
    "supplyApyPercentage": 5.019300000000001
  },
  {
    "utilizationRate": 79,
    "borrowApyPercentage": 7.9,
    "supplyApyPercentage": 5.1488249999999995
  },
  {
    "utilizationRate": 80,
    "borrowApyPercentage": 8,
    "supplyApyPercentage": 5.28
  },
  {
    "utilizationRate": 81,
    "borrowApyPercentage": 8.85,
    "supplyApyPercentage": 5.914012499999999
  },
  {
    "utilizationRate": 82,
    "borrowApyPercentage": 9.700000000000001,
    "supplyApyPercentage": 6.56205
  },
  {
    "utilizationRate": 83,
    "borrowApyPercentage": 10.549999999999999,
    "supplyApyPercentage": 7.224112499999999
  },
  {
    "utilizationRate": 84,
    "borrowApyPercentage": 11.4,
    "supplyApyPercentage": 7.9002
  },
  {
    "utilizationRate": 85,
    "borrowApyPercentage": 12.25,
    "supplyApyPercentage": 8.5903125
  },
  {
    "utilizationRate": 86,
    "borrowApyPercentage": 13.100000000000001,
    "supplyApyPercentage": 9.294449999999998
  },
  {
    "utilizationRate": 87,
    "borrowApyPercentage": 13.950000000000001,
    "supplyApyPercentage": 10.012612500000001
  },
  {
    "utilizationRate": 88,
    "borrowApyPercentage": 14.800000000000002,
    "supplyApyPercentage": 10.744800000000001
  },
  {
    "utilizationRate": 89,
    "borrowApyPercentage": 15.65,
    "supplyApyPercentage": 11.491012499999998
  },
  {
    "utilizationRate": 90,
    "borrowApyPercentage": 16.5,
    "supplyApyPercentage": 12.25125
  },
  {
    "utilizationRate": 91,
    "borrowApyPercentage": 17.349999999999998,
    "supplyApyPercentage": 13.0255125
  },
  {
    "utilizationRate": 92,
    "borrowApyPercentage": 18.2,
    "supplyApyPercentage": 13.8138
  },
  {
    "utilizationRate": 93,
    "borrowApyPercentage": 19.05,
    "supplyApyPercentage": 14.616112499999998
  },
  {
    "utilizationRate": 94,
    "borrowApyPercentage": 19.900000000000002,
    "supplyApyPercentage": 15.432450000000001
  },
  {
    "utilizationRate": 95,
    "borrowApyPercentage": 20.75,
    "supplyApyPercentage": 16.262812500000003
  },
  {
    "utilizationRate": 96,
    "borrowApyPercentage": 21.6,
    "supplyApyPercentage": 17.107200000000002
  },
  {
    "utilizationRate": 97,
    "borrowApyPercentage": 22.450000000000003,
    "supplyApyPercentage": 17.9656125
  },
  {
    "utilizationRate": 98,
    "borrowApyPercentage": 23.299999999999997,
    "supplyApyPercentage": 18.83805
  },
  {
    "utilizationRate": 99,
    "borrowApyPercentage": 24.15,
    "supplyApyPercentage": 19.7245125
  },
  {
    "utilizationRate": 100,
    "borrowApyPercentage": 25,
    "supplyApyPercentage": 20.625
  }
];

export const IRM_11_30_80 = [
  {
    "utilizationRate": 0,
    "borrowApyPercentage": 0,
    "supplyApyPercentage": 0
  },
  {
    "utilizationRate": 1,
    "borrowApyPercentage": 0.1375,
    "supplyApyPercentage": 0.0011343750000000002
  },
  {
    "utilizationRate": 2,
    "borrowApyPercentage": 0.275,
    "supplyApyPercentage": 0.004537500000000001
  },
  {
    "utilizationRate": 3,
    "borrowApyPercentage": 0.41250000000000003,
    "supplyApyPercentage": 0.010209375
  },
  {
    "utilizationRate": 4,
    "borrowApyPercentage": 0.55,
    "supplyApyPercentage": 0.018150000000000003
  },
  {
    "utilizationRate": 5,
    "borrowApyPercentage": 0.6875,
    "supplyApyPercentage": 0.028359375
  },
  {
    "utilizationRate": 6,
    "borrowApyPercentage": 0.8250000000000001,
    "supplyApyPercentage": 0.0408375
  },
  {
    "utilizationRate": 7,
    "borrowApyPercentage": 0.9625000000000001,
    "supplyApyPercentage": 0.05558437500000001
  },
  {
    "utilizationRate": 8,
    "borrowApyPercentage": 1.1,
    "supplyApyPercentage": 0.07260000000000001
  },
  {
    "utilizationRate": 9,
    "borrowApyPercentage": 1.2375,
    "supplyApyPercentage": 0.09188437499999999
  },
  {
    "utilizationRate": 10,
    "borrowApyPercentage": 1.375,
    "supplyApyPercentage": 0.1134375
  },
  {
    "utilizationRate": 11,
    "borrowApyPercentage": 1.5125000000000002,
    "supplyApyPercentage": 0.13725937500000002
  },
  {
    "utilizationRate": 12,
    "borrowApyPercentage": 1.6500000000000001,
    "supplyApyPercentage": 0.16335
  },
  {
    "utilizationRate": 13,
    "borrowApyPercentage": 1.7875,
    "supplyApyPercentage": 0.19170937500000002
  },
  {
    "utilizationRate": 14,
    "borrowApyPercentage": 1.9250000000000003,
    "supplyApyPercentage": 0.22233750000000005
  },
  {
    "utilizationRate": 15,
    "borrowApyPercentage": 2.0625,
    "supplyApyPercentage": 0.255234375
  },
  {
    "utilizationRate": 16,
    "borrowApyPercentage": 2.2,
    "supplyApyPercentage": 0.29040000000000005
  },
  {
    "utilizationRate": 17,
    "borrowApyPercentage": 2.3375000000000004,
    "supplyApyPercentage": 0.32783437500000007
  },
  {
    "utilizationRate": 18,
    "borrowApyPercentage": 2.475,
    "supplyApyPercentage": 0.36753749999999996
  },
  {
    "utilizationRate": 19,
    "borrowApyPercentage": 2.6125000000000003,
    "supplyApyPercentage": 0.40950937499999995
  },
  {
    "utilizationRate": 20,
    "borrowApyPercentage": 2.75,
    "supplyApyPercentage": 0.45375
  },
  {
    "utilizationRate": 21,
    "borrowApyPercentage": 2.8875,
    "supplyApyPercentage": 0.500259375
  },
  {
    "utilizationRate": 22,
    "borrowApyPercentage": 3.0250000000000004,
    "supplyApyPercentage": 0.5490375000000001
  },
  {
    "utilizationRate": 23,
    "borrowApyPercentage": 3.1625,
    "supplyApyPercentage": 0.6000843749999999
  },
  {
    "utilizationRate": 24,
    "borrowApyPercentage": 3.3000000000000003,
    "supplyApyPercentage": 0.6534
  },
  {
    "utilizationRate": 25,
    "borrowApyPercentage": 3.4375000000000004,
    "supplyApyPercentage": 0.708984375
  },
  {
    "utilizationRate": 26,
    "borrowApyPercentage": 3.575,
    "supplyApyPercentage": 0.7668375000000001
  },
  {
    "utilizationRate": 27,
    "borrowApyPercentage": 3.7125000000000004,
    "supplyApyPercentage": 0.8269593750000002
  },
  {
    "utilizationRate": 28,
    "borrowApyPercentage": 3.8500000000000005,
    "supplyApyPercentage": 0.8893500000000002
  },
  {
    "utilizationRate": 29,
    "borrowApyPercentage": 3.9875000000000003,
    "supplyApyPercentage": 0.9540093749999999
  },
  {
    "utilizationRate": 30,
    "borrowApyPercentage": 4.125,
    "supplyApyPercentage": 1.0209375
  },
  {
    "utilizationRate": 31,
    "borrowApyPercentage": 4.2625,
    "supplyApyPercentage": 1.090134375
  },
  {
    "utilizationRate": 32,
    "borrowApyPercentage": 4.4,
    "supplyApyPercentage": 1.1616000000000002
  },
  {
    "utilizationRate": 33,
    "borrowApyPercentage": 4.5375000000000005,
    "supplyApyPercentage": 1.235334375
  },
  {
    "utilizationRate": 34,
    "borrowApyPercentage": 4.675000000000001,
    "supplyApyPercentage": 1.3113375000000003
  },
  {
    "utilizationRate": 35,
    "borrowApyPercentage": 4.8125,
    "supplyApyPercentage": 1.389609375
  },
  {
    "utilizationRate": 36,
    "borrowApyPercentage": 4.95,
    "supplyApyPercentage": 1.4701499999999998
  },
  {
    "utilizationRate": 37,
    "borrowApyPercentage": 5.0875,
    "supplyApyPercentage": 1.552959375
  },
  {
    "utilizationRate": 38,
    "borrowApyPercentage": 5.2250000000000005,
    "supplyApyPercentage": 1.6380374999999998
  },
  {
    "utilizationRate": 39,
    "borrowApyPercentage": 5.362500000000001,
    "supplyApyPercentage": 1.7253843750000002
  },
  {
    "utilizationRate": 40,
    "borrowApyPercentage": 5.5,
    "supplyApyPercentage": 1.815
  },
  {
    "utilizationRate": 41,
    "borrowApyPercentage": 5.6375,
    "supplyApyPercentage": 1.9068843749999997
  },
  {
    "utilizationRate": 42,
    "borrowApyPercentage": 5.775,
    "supplyApyPercentage": 2.0010375
  },
  {
    "utilizationRate": 43,
    "borrowApyPercentage": 5.9125000000000005,
    "supplyApyPercentage": 2.097459375
  },
  {
    "utilizationRate": 44,
    "borrowApyPercentage": 6.050000000000001,
    "supplyApyPercentage": 2.1961500000000003
  },
  {
    "utilizationRate": 45,
    "borrowApyPercentage": 6.187500000000001,
    "supplyApyPercentage": 2.2971093750000002
  },
  {
    "utilizationRate": 46,
    "borrowApyPercentage": 6.325,
    "supplyApyPercentage": 2.4003374999999996
  },
  {
    "utilizationRate": 47,
    "borrowApyPercentage": 6.4625,
    "supplyApyPercentage": 2.5058343749999996
  },
  {
    "utilizationRate": 48,
    "borrowApyPercentage": 6.6000000000000005,
    "supplyApyPercentage": 2.6136
  },
  {
    "utilizationRate": 49,
    "borrowApyPercentage": 6.737500000000001,
    "supplyApyPercentage": 2.723634375
  },
  {
    "utilizationRate": 50,
    "borrowApyPercentage": 6.875000000000001,
    "supplyApyPercentage": 2.8359375
  },
  {
    "utilizationRate": 51,
    "borrowApyPercentage": 7.012500000000001,
    "supplyApyPercentage": 2.950509375
  },
  {
    "utilizationRate": 52,
    "borrowApyPercentage": 7.15,
    "supplyApyPercentage": 3.0673500000000002
  },
  {
    "utilizationRate": 53,
    "borrowApyPercentage": 7.2875000000000005,
    "supplyApyPercentage": 3.186459375
  },
  {
    "utilizationRate": 54,
    "borrowApyPercentage": 7.425000000000001,
    "supplyApyPercentage": 3.3078375000000007
  },
  {
    "utilizationRate": 55,
    "borrowApyPercentage": 7.562500000000001,
    "supplyApyPercentage": 3.4314843750000006
  },
  {
    "utilizationRate": 56,
    "borrowApyPercentage": 7.700000000000001,
    "supplyApyPercentage": 3.557400000000001
  },
  {
    "utilizationRate": 57,
    "borrowApyPercentage": 7.8375,
    "supplyApyPercentage": 3.685584375
  },
  {
    "utilizationRate": 58,
    "borrowApyPercentage": 7.9750000000000005,
    "supplyApyPercentage": 3.8160374999999997
  },
  {
    "utilizationRate": 59,
    "borrowApyPercentage": 8.1125,
    "supplyApyPercentage": 3.9487593750000003
  },
  {
    "utilizationRate": 60,
    "borrowApyPercentage": 8.25,
    "supplyApyPercentage": 4.08375
  },
  {
    "utilizationRate": 61,
    "borrowApyPercentage": 8.387500000000001,
    "supplyApyPercentage": 4.221009375
  },
  {
    "utilizationRate": 62,
    "borrowApyPercentage": 8.525,
    "supplyApyPercentage": 4.3605375
  },
  {
    "utilizationRate": 63,
    "borrowApyPercentage": 8.662500000000001,
    "supplyApyPercentage": 4.502334375
  },
  {
    "utilizationRate": 64,
    "borrowApyPercentage": 8.8,
    "supplyApyPercentage": 4.646400000000001
  },
  {
    "utilizationRate": 65,
    "borrowApyPercentage": 8.9375,
    "supplyApyPercentage": 4.792734374999999
  },
  {
    "utilizationRate": 66,
    "borrowApyPercentage": 9.075000000000001,
    "supplyApyPercentage": 4.9413375
  },
  {
    "utilizationRate": 67,
    "borrowApyPercentage": 9.2125,
    "supplyApyPercentage": 5.0922093749999995
  },
  {
    "utilizationRate": 68,
    "borrowApyPercentage": 9.350000000000001,
    "supplyApyPercentage": 5.245350000000001
  },
  {
    "utilizationRate": 69,
    "borrowApyPercentage": 9.4875,
    "supplyApyPercentage": 5.400759375
  },
  {
    "utilizationRate": 70,
    "borrowApyPercentage": 9.625,
    "supplyApyPercentage": 5.5584375
  },
  {
    "utilizationRate": 71,
    "borrowApyPercentage": 9.762500000000001,
    "supplyApyPercentage": 5.718384375
  },
  {
    "utilizationRate": 72,
    "borrowApyPercentage": 9.9,
    "supplyApyPercentage": 5.880599999999999
  },
  {
    "utilizationRate": 73,
    "borrowApyPercentage": 10.037500000000001,
    "supplyApyPercentage": 6.045084375
  },
  {
    "utilizationRate": 74,
    "borrowApyPercentage": 10.175,
    "supplyApyPercentage": 6.2118375
  },
  {
    "utilizationRate": 75,
    "borrowApyPercentage": 10.3125,
    "supplyApyPercentage": 6.380859375
  },
  {
    "utilizationRate": 76,
    "borrowApyPercentage": 10.450000000000001,
    "supplyApyPercentage": 6.552149999999999
  },
  {
    "utilizationRate": 77,
    "borrowApyPercentage": 10.5875,
    "supplyApyPercentage": 6.725709374999999
  },
  {
    "utilizationRate": 78,
    "borrowApyPercentage": 10.725000000000001,
    "supplyApyPercentage": 6.901537500000001
  },
  {
    "utilizationRate": 79,
    "borrowApyPercentage": 10.8625,
    "supplyApyPercentage": 7.079634375
  },
  {
    "utilizationRate": 80,
    "borrowApyPercentage": 11,
    "supplyApyPercentage": 7.26
  },
  {
    "utilizationRate": 81,
    "borrowApyPercentage": 11.95,
    "supplyApyPercentage": 7.985587499999999
  },
  {
    "utilizationRate": 82,
    "borrowApyPercentage": 12.9,
    "supplyApyPercentage": 8.72685
  },
  {
    "utilizationRate": 83,
    "borrowApyPercentage": 13.850000000000001,
    "supplyApyPercentage": 9.4837875
  },
  {
    "utilizationRate": 84,
    "borrowApyPercentage": 14.800000000000002,
    "supplyApyPercentage": 10.256400000000001
  },
  {
    "utilizationRate": 85,
    "borrowApyPercentage": 15.75,
    "supplyApyPercentage": 11.044687499999998
  },
  {
    "utilizationRate": 86,
    "borrowApyPercentage": 16.7,
    "supplyApyPercentage": 11.84865
  },
  {
    "utilizationRate": 87,
    "borrowApyPercentage": 17.65,
    "supplyApyPercentage": 12.6682875
  },
  {
    "utilizationRate": 88,
    "borrowApyPercentage": 18.6,
    "supplyApyPercentage": 13.503599999999999
  },
  {
    "utilizationRate": 89,
    "borrowApyPercentage": 19.55,
    "supplyApyPercentage": 14.3545875
  },
  {
    "utilizationRate": 90,
    "borrowApyPercentage": 20.5,
    "supplyApyPercentage": 15.22125
  },
  {
    "utilizationRate": 91,
    "borrowApyPercentage": 21.45,
    "supplyApyPercentage": 16.1035875
  },
  {
    "utilizationRate": 92,
    "borrowApyPercentage": 22.400000000000002,
    "supplyApyPercentage": 17.0016
  },
  {
    "utilizationRate": 93,
    "borrowApyPercentage": 23.349999999999998,
    "supplyApyPercentage": 17.915287499999998
  },
  {
    "utilizationRate": 94,
    "borrowApyPercentage": 24.3,
    "supplyApyPercentage": 18.844649999999998
  },
  {
    "utilizationRate": 95,
    "borrowApyPercentage": 25.25,
    "supplyApyPercentage": 19.7896875
  },
  {
    "utilizationRate": 96,
    "borrowApyPercentage": 26.200000000000003,
    "supplyApyPercentage": 20.7504
  },
  {
    "utilizationRate": 97,
    "borrowApyPercentage": 27.150000000000002,
    "supplyApyPercentage": 21.7267875
  },
  {
    "utilizationRate": 98,
    "borrowApyPercentage": 28.099999999999998,
    "supplyApyPercentage": 22.718849999999996
  },
  {
    "utilizationRate": 99,
    "borrowApyPercentage": 29.049999999999997,
    "supplyApyPercentage": 23.726587499999997
  },
  {
    "utilizationRate": 100,
    "borrowApyPercentage": 30,
    "supplyApyPercentage": 24.749999999999996
  }
];

export const IRM_10_30_80 = [
  {
    "utilizationRate": 0,
    "borrowApyPercentage": 0,
    "supplyApyPercentage": 0
  },
  {
    "utilizationRate": 1,
    "borrowApyPercentage": 0.125,
    "supplyApyPercentage": 0.00103125
  },
  {
    "utilizationRate": 2,
    "borrowApyPercentage": 0.25,
    "supplyApyPercentage": 0.004125
  },
  {
    "utilizationRate": 3,
    "borrowApyPercentage": 0.375,
    "supplyApyPercentage": 0.009281249999999998
  },
  {
    "utilizationRate": 4,
    "borrowApyPercentage": 0.5,
    "supplyApyPercentage": 0.0165
  },
  {
    "utilizationRate": 5,
    "borrowApyPercentage": 0.625,
    "supplyApyPercentage": 0.025781250000000002
  },
  {
    "utilizationRate": 6,
    "borrowApyPercentage": 0.75,
    "supplyApyPercentage": 0.03712499999999999
  },
  {
    "utilizationRate": 7,
    "borrowApyPercentage": 0.8750000000000001,
    "supplyApyPercentage": 0.05053125
  },
  {
    "utilizationRate": 8,
    "borrowApyPercentage": 1,
    "supplyApyPercentage": 0.066
  },
  {
    "utilizationRate": 9,
    "borrowApyPercentage": 1.125,
    "supplyApyPercentage": 0.08353124999999999
  },
  {
    "utilizationRate": 10,
    "borrowApyPercentage": 1.25,
    "supplyApyPercentage": 0.10312500000000001
  },
  {
    "utilizationRate": 11,
    "borrowApyPercentage": 1.375,
    "supplyApyPercentage": 0.12478124999999998
  },
  {
    "utilizationRate": 12,
    "borrowApyPercentage": 1.5,
    "supplyApyPercentage": 0.14849999999999997
  },
  {
    "utilizationRate": 13,
    "borrowApyPercentage": 1.625,
    "supplyApyPercentage": 0.17428124999999997
  },
  {
    "utilizationRate": 14,
    "borrowApyPercentage": 1.7500000000000002,
    "supplyApyPercentage": 0.202125
  },
  {
    "utilizationRate": 15,
    "borrowApyPercentage": 1.875,
    "supplyApyPercentage": 0.23203125
  },
  {
    "utilizationRate": 16,
    "borrowApyPercentage": 2,
    "supplyApyPercentage": 0.264
  },
  {
    "utilizationRate": 17,
    "borrowApyPercentage": 2.125,
    "supplyApyPercentage": 0.29803124999999997
  },
  {
    "utilizationRate": 18,
    "borrowApyPercentage": 2.25,
    "supplyApyPercentage": 0.33412499999999995
  },
  {
    "utilizationRate": 19,
    "borrowApyPercentage": 2.375,
    "supplyApyPercentage": 0.37228125
  },
  {
    "utilizationRate": 20,
    "borrowApyPercentage": 2.5,
    "supplyApyPercentage": 0.41250000000000003
  },
  {
    "utilizationRate": 21,
    "borrowApyPercentage": 2.625,
    "supplyApyPercentage": 0.45478124999999997
  },
  {
    "utilizationRate": 22,
    "borrowApyPercentage": 2.75,
    "supplyApyPercentage": 0.49912499999999993
  },
  {
    "utilizationRate": 23,
    "borrowApyPercentage": 2.875,
    "supplyApyPercentage": 0.54553125
  },
  {
    "utilizationRate": 24,
    "borrowApyPercentage": 3,
    "supplyApyPercentage": 0.5939999999999999
  },
  {
    "utilizationRate": 25,
    "borrowApyPercentage": 3.125,
    "supplyApyPercentage": 0.64453125
  },
  {
    "utilizationRate": 26,
    "borrowApyPercentage": 3.25,
    "supplyApyPercentage": 0.6971249999999999
  },
  {
    "utilizationRate": 27,
    "borrowApyPercentage": 3.375,
    "supplyApyPercentage": 0.7517812500000001
  },
  {
    "utilizationRate": 28,
    "borrowApyPercentage": 3.5000000000000004,
    "supplyApyPercentage": 0.8085
  },
  {
    "utilizationRate": 29,
    "borrowApyPercentage": 3.6249999999999996,
    "supplyApyPercentage": 0.86728125
  },
  {
    "utilizationRate": 30,
    "borrowApyPercentage": 3.75,
    "supplyApyPercentage": 0.928125
  },
  {
    "utilizationRate": 31,
    "borrowApyPercentage": 3.875,
    "supplyApyPercentage": 0.9910312499999999
  },
  {
    "utilizationRate": 32,
    "borrowApyPercentage": 4,
    "supplyApyPercentage": 1.056
  },
  {
    "utilizationRate": 33,
    "borrowApyPercentage": 4.125,
    "supplyApyPercentage": 1.1230312500000001
  },
  {
    "utilizationRate": 34,
    "borrowApyPercentage": 4.25,
    "supplyApyPercentage": 1.1921249999999999
  },
  {
    "utilizationRate": 35,
    "borrowApyPercentage": 4.375,
    "supplyApyPercentage": 1.26328125
  },
  {
    "utilizationRate": 36,
    "borrowApyPercentage": 4.5,
    "supplyApyPercentage": 1.3364999999999998
  },
  {
    "utilizationRate": 37,
    "borrowApyPercentage": 4.625,
    "supplyApyPercentage": 1.4117812499999998
  },
  {
    "utilizationRate": 38,
    "borrowApyPercentage": 4.75,
    "supplyApyPercentage": 1.489125
  },
  {
    "utilizationRate": 39,
    "borrowApyPercentage": 4.875,
    "supplyApyPercentage": 1.56853125
  },
  {
    "utilizationRate": 40,
    "borrowApyPercentage": 5,
    "supplyApyPercentage": 1.6500000000000001
  },
  {
    "utilizationRate": 41,
    "borrowApyPercentage": 5.125,
    "supplyApyPercentage": 1.7335312499999997
  },
  {
    "utilizationRate": 42,
    "borrowApyPercentage": 5.25,
    "supplyApyPercentage": 1.8191249999999999
  },
  {
    "utilizationRate": 43,
    "borrowApyPercentage": 5.375,
    "supplyApyPercentage": 1.9067812499999997
  },
  {
    "utilizationRate": 44,
    "borrowApyPercentage": 5.5,
    "supplyApyPercentage": 1.9964999999999997
  },
  {
    "utilizationRate": 45,
    "borrowApyPercentage": 5.625,
    "supplyApyPercentage": 2.08828125
  },
  {
    "utilizationRate": 46,
    "borrowApyPercentage": 5.75,
    "supplyApyPercentage": 2.182125
  },
  {
    "utilizationRate": 47,
    "borrowApyPercentage": 5.875,
    "supplyApyPercentage": 2.2780312499999997
  },
  {
    "utilizationRate": 48,
    "borrowApyPercentage": 6,
    "supplyApyPercentage": 2.3759999999999994
  },
  {
    "utilizationRate": 49,
    "borrowApyPercentage": 6.125,
    "supplyApyPercentage": 2.4760312499999997
  },
  {
    "utilizationRate": 50,
    "borrowApyPercentage": 6.25,
    "supplyApyPercentage": 2.578125
  },
  {
    "utilizationRate": 51,
    "borrowApyPercentage": 6.375,
    "supplyApyPercentage": 2.68228125
  },
  {
    "utilizationRate": 52,
    "borrowApyPercentage": 6.5,
    "supplyApyPercentage": 2.7884999999999995
  },
  {
    "utilizationRate": 53,
    "borrowApyPercentage": 6.625,
    "supplyApyPercentage": 2.8967812499999996
  },
  {
    "utilizationRate": 54,
    "borrowApyPercentage": 6.75,
    "supplyApyPercentage": 3.0071250000000003
  },
  {
    "utilizationRate": 55,
    "borrowApyPercentage": 6.875000000000001,
    "supplyApyPercentage": 3.11953125
  },
  {
    "utilizationRate": 56,
    "borrowApyPercentage": 7.000000000000001,
    "supplyApyPercentage": 3.234
  },
  {
    "utilizationRate": 57,
    "borrowApyPercentage": 7.124999999999999,
    "supplyApyPercentage": 3.3505312499999995
  },
  {
    "utilizationRate": 58,
    "borrowApyPercentage": 7.249999999999999,
    "supplyApyPercentage": 3.469125
  },
  {
    "utilizationRate": 59,
    "borrowApyPercentage": 7.375,
    "supplyApyPercentage": 3.5897812499999993
  },
  {
    "utilizationRate": 60,
    "borrowApyPercentage": 7.5,
    "supplyApyPercentage": 3.7125
  },
  {
    "utilizationRate": 61,
    "borrowApyPercentage": 7.625,
    "supplyApyPercentage": 3.8372812499999998
  },
  {
    "utilizationRate": 62,
    "borrowApyPercentage": 7.75,
    "supplyApyPercentage": 3.9641249999999997
  },
  {
    "utilizationRate": 63,
    "borrowApyPercentage": 7.875,
    "supplyApyPercentage": 4.093031249999999
  },
  {
    "utilizationRate": 64,
    "borrowApyPercentage": 8,
    "supplyApyPercentage": 4.224
  },
  {
    "utilizationRate": 65,
    "borrowApyPercentage": 8.125,
    "supplyApyPercentage": 4.35703125
  },
  {
    "utilizationRate": 66,
    "borrowApyPercentage": 8.25,
    "supplyApyPercentage": 4.492125000000001
  },
  {
    "utilizationRate": 67,
    "borrowApyPercentage": 8.375,
    "supplyApyPercentage": 4.62928125
  },
  {
    "utilizationRate": 68,
    "borrowApyPercentage": 8.5,
    "supplyApyPercentage": 4.7684999999999995
  },
  {
    "utilizationRate": 69,
    "borrowApyPercentage": 8.625,
    "supplyApyPercentage": 4.90978125
  },
  {
    "utilizationRate": 70,
    "borrowApyPercentage": 8.75,
    "supplyApyPercentage": 5.053125
  },
  {
    "utilizationRate": 71,
    "borrowApyPercentage": 8.875,
    "supplyApyPercentage": 5.19853125
  },
  {
    "utilizationRate": 72,
    "borrowApyPercentage": 9,
    "supplyApyPercentage": 5.345999999999999
  },
  {
    "utilizationRate": 73,
    "borrowApyPercentage": 9.125,
    "supplyApyPercentage": 5.49553125
  },
  {
    "utilizationRate": 74,
    "borrowApyPercentage": 9.25,
    "supplyApyPercentage": 5.647124999999999
  },
  {
    "utilizationRate": 75,
    "borrowApyPercentage": 9.375,
    "supplyApyPercentage": 5.80078125
  },
  {
    "utilizationRate": 76,
    "borrowApyPercentage": 9.5,
    "supplyApyPercentage": 5.9565
  },
  {
    "utilizationRate": 77,
    "borrowApyPercentage": 9.625,
    "supplyApyPercentage": 6.1142812499999994
  },
  {
    "utilizationRate": 78,
    "borrowApyPercentage": 9.75,
    "supplyApyPercentage": 6.274125
  },
  {
    "utilizationRate": 79,
    "borrowApyPercentage": 9.875,
    "supplyApyPercentage": 6.436031249999999
  },
  {
    "utilizationRate": 80,
    "borrowApyPercentage": 10,
    "supplyApyPercentage": 6.6000000000000005
  },
  {
    "utilizationRate": 81,
    "borrowApyPercentage": 11,
    "supplyApyPercentage": 7.350749999999999
  },
  {
    "utilizationRate": 82,
    "borrowApyPercentage": 12.000000000000002,
    "supplyApyPercentage": 8.118
  },
  {
    "utilizationRate": 83,
    "borrowApyPercentage": 13,
    "supplyApyPercentage": 8.90175
  },
  {
    "utilizationRate": 84,
    "borrowApyPercentage": 14.000000000000002,
    "supplyApyPercentage": 9.702000000000002
  },
  {
    "utilizationRate": 85,
    "borrowApyPercentage": 15.000000000000002,
    "supplyApyPercentage": 10.518750000000002
  },
  {
    "utilizationRate": 86,
    "borrowApyPercentage": 16,
    "supplyApyPercentage": 11.352
  },
  {
    "utilizationRate": 87,
    "borrowApyPercentage": 17,
    "supplyApyPercentage": 12.20175
  },
  {
    "utilizationRate": 88,
    "borrowApyPercentage": 18,
    "supplyApyPercentage": 13.068
  },
  {
    "utilizationRate": 89,
    "borrowApyPercentage": 19,
    "supplyApyPercentage": 13.950750000000001
  },
  {
    "utilizationRate": 90,
    "borrowApyPercentage": 20,
    "supplyApyPercentage": 14.85
  },
  {
    "utilizationRate": 91,
    "borrowApyPercentage": 21.000000000000004,
    "supplyApyPercentage": 15.76575
  },
  {
    "utilizationRate": 92,
    "borrowApyPercentage": 22,
    "supplyApyPercentage": 16.698
  },
  {
    "utilizationRate": 93,
    "borrowApyPercentage": 23,
    "supplyApyPercentage": 17.64675
  },
  {
    "utilizationRate": 94,
    "borrowApyPercentage": 24.000000000000004,
    "supplyApyPercentage": 18.612000000000002
  },
  {
    "utilizationRate": 95,
    "borrowApyPercentage": 25,
    "supplyApyPercentage": 19.59375
  },
  {
    "utilizationRate": 96,
    "borrowApyPercentage": 26,
    "supplyApyPercentage": 20.592
  },
  {
    "utilizationRate": 97,
    "borrowApyPercentage": 27,
    "supplyApyPercentage": 21.606749999999998
  },
  {
    "utilizationRate": 98,
    "borrowApyPercentage": 28.000000000000004,
    "supplyApyPercentage": 22.638
  },
  {
    "utilizationRate": 99,
    "borrowApyPercentage": 29.000000000000004,
    "supplyApyPercentage": 23.68575
  },
  {
    "utilizationRate": 100,
    "borrowApyPercentage": 30.000000000000004,
    "supplyApyPercentage": 24.750000000000004
  }
];

export const IRM_06_20_80 = [
  {
    "utilizationRate": 0,
    "borrowApyPercentage": 0,
    "supplyApyPercentage": 0
  },
  {
    "utilizationRate": 1,
    "borrowApyPercentage": 0.075,
    "supplyApyPercentage": 0.0006187499999999999
  },
  {
    "utilizationRate": 2,
    "borrowApyPercentage": 0.15,
    "supplyApyPercentage": 0.0024749999999999998
  },
  {
    "utilizationRate": 3,
    "borrowApyPercentage": 0.22499999999999998,
    "supplyApyPercentage": 0.005568749999999998
  },
  {
    "utilizationRate": 4,
    "borrowApyPercentage": 0.3,
    "supplyApyPercentage": 0.009899999999999999
  },
  {
    "utilizationRate": 5,
    "borrowApyPercentage": 0.375,
    "supplyApyPercentage": 0.015468749999999998
  },
  {
    "utilizationRate": 6,
    "borrowApyPercentage": 0.44999999999999996,
    "supplyApyPercentage": 0.022274999999999993
  },
  {
    "utilizationRate": 7,
    "borrowApyPercentage": 0.525,
    "supplyApyPercentage": 0.030318750000000002
  },
  {
    "utilizationRate": 8,
    "borrowApyPercentage": 0.6,
    "supplyApyPercentage": 0.039599999999999996
  },
  {
    "utilizationRate": 9,
    "borrowApyPercentage": 0.6749999999999999,
    "supplyApyPercentage": 0.05011874999999999
  },
  {
    "utilizationRate": 10,
    "borrowApyPercentage": 0.75,
    "supplyApyPercentage": 0.06187499999999999
  },
  {
    "utilizationRate": 11,
    "borrowApyPercentage": 0.8250000000000001,
    "supplyApyPercentage": 0.07486875
  },
  {
    "utilizationRate": 12,
    "borrowApyPercentage": 0.8999999999999999,
    "supplyApyPercentage": 0.08909999999999997
  },
  {
    "utilizationRate": 13,
    "borrowApyPercentage": 0.975,
    "supplyApyPercentage": 0.10456875
  },
  {
    "utilizationRate": 14,
    "borrowApyPercentage": 1.05,
    "supplyApyPercentage": 0.12127500000000001
  },
  {
    "utilizationRate": 15,
    "borrowApyPercentage": 1.125,
    "supplyApyPercentage": 0.13921874999999997
  },
  {
    "utilizationRate": 16,
    "borrowApyPercentage": 1.2,
    "supplyApyPercentage": 0.15839999999999999
  },
  {
    "utilizationRate": 17,
    "borrowApyPercentage": 1.275,
    "supplyApyPercentage": 0.17881875
  },
  {
    "utilizationRate": 18,
    "borrowApyPercentage": 1.3499999999999999,
    "supplyApyPercentage": 0.20047499999999996
  },
  {
    "utilizationRate": 19,
    "borrowApyPercentage": 1.425,
    "supplyApyPercentage": 0.22336874999999995
  },
  {
    "utilizationRate": 20,
    "borrowApyPercentage": 1.5,
    "supplyApyPercentage": 0.24749999999999997
  },
  {
    "utilizationRate": 21,
    "borrowApyPercentage": 1.575,
    "supplyApyPercentage": 0.27286875
  },
  {
    "utilizationRate": 22,
    "borrowApyPercentage": 1.6500000000000001,
    "supplyApyPercentage": 0.299475
  },
  {
    "utilizationRate": 23,
    "borrowApyPercentage": 1.7249999999999999,
    "supplyApyPercentage": 0.32731874999999994
  },
  {
    "utilizationRate": 24,
    "borrowApyPercentage": 1.7999999999999998,
    "supplyApyPercentage": 0.3563999999999999
  },
  {
    "utilizationRate": 25,
    "borrowApyPercentage": 1.875,
    "supplyApyPercentage": 0.38671874999999994
  },
  {
    "utilizationRate": 26,
    "borrowApyPercentage": 1.95,
    "supplyApyPercentage": 0.418275
  },
  {
    "utilizationRate": 27,
    "borrowApyPercentage": 2.025,
    "supplyApyPercentage": 0.4510687500000001
  },
  {
    "utilizationRate": 28,
    "borrowApyPercentage": 2.1,
    "supplyApyPercentage": 0.48510000000000003
  },
  {
    "utilizationRate": 29,
    "borrowApyPercentage": 2.175,
    "supplyApyPercentage": 0.5203687499999999
  },
  {
    "utilizationRate": 30,
    "borrowApyPercentage": 2.25,
    "supplyApyPercentage": 0.5568749999999999
  },
  {
    "utilizationRate": 31,
    "borrowApyPercentage": 2.3249999999999997,
    "supplyApyPercentage": 0.5946187499999999
  },
  {
    "utilizationRate": 32,
    "borrowApyPercentage": 2.4,
    "supplyApyPercentage": 0.6335999999999999
  },
  {
    "utilizationRate": 33,
    "borrowApyPercentage": 2.475,
    "supplyApyPercentage": 0.67381875
  },
  {
    "utilizationRate": 34,
    "borrowApyPercentage": 2.55,
    "supplyApyPercentage": 0.715275
  },
  {
    "utilizationRate": 35,
    "borrowApyPercentage": 2.625,
    "supplyApyPercentage": 0.7579687499999999
  },
  {
    "utilizationRate": 36,
    "borrowApyPercentage": 2.6999999999999997,
    "supplyApyPercentage": 0.8018999999999998
  },
  {
    "utilizationRate": 37,
    "borrowApyPercentage": 2.775,
    "supplyApyPercentage": 0.84706875
  },
  {
    "utilizationRate": 38,
    "borrowApyPercentage": 2.85,
    "supplyApyPercentage": 0.8934749999999998
  },
  {
    "utilizationRate": 39,
    "borrowApyPercentage": 2.925,
    "supplyApyPercentage": 0.9411187499999999
  },
  {
    "utilizationRate": 40,
    "borrowApyPercentage": 3,
    "supplyApyPercentage": 0.9899999999999999
  },
  {
    "utilizationRate": 41,
    "borrowApyPercentage": 3.0749999999999997,
    "supplyApyPercentage": 1.04011875
  },
  {
    "utilizationRate": 42,
    "borrowApyPercentage": 3.15,
    "supplyApyPercentage": 1.091475
  },
  {
    "utilizationRate": 43,
    "borrowApyPercentage": 3.225,
    "supplyApyPercentage": 1.1440687500000002
  },
  {
    "utilizationRate": 44,
    "borrowApyPercentage": 3.3000000000000003,
    "supplyApyPercentage": 1.1979
  },
  {
    "utilizationRate": 45,
    "borrowApyPercentage": 3.375,
    "supplyApyPercentage": 1.25296875
  },
  {
    "utilizationRate": 46,
    "borrowApyPercentage": 3.4499999999999997,
    "supplyApyPercentage": 1.3092749999999997
  },
  {
    "utilizationRate": 47,
    "borrowApyPercentage": 3.5249999999999995,
    "supplyApyPercentage": 1.3668187499999997
  },
  {
    "utilizationRate": 48,
    "borrowApyPercentage": 3.5999999999999996,
    "supplyApyPercentage": 1.4255999999999995
  },
  {
    "utilizationRate": 49,
    "borrowApyPercentage": 3.675,
    "supplyApyPercentage": 1.4856187499999998
  },
  {
    "utilizationRate": 50,
    "borrowApyPercentage": 3.75,
    "supplyApyPercentage": 1.5468749999999998
  },
  {
    "utilizationRate": 51,
    "borrowApyPercentage": 3.8249999999999997,
    "supplyApyPercentage": 1.6093687499999998
  },
  {
    "utilizationRate": 52,
    "borrowApyPercentage": 3.9,
    "supplyApyPercentage": 1.6731
  },
  {
    "utilizationRate": 53,
    "borrowApyPercentage": 3.974999999999999,
    "supplyApyPercentage": 1.7380687499999996
  },
  {
    "utilizationRate": 54,
    "borrowApyPercentage": 4.05,
    "supplyApyPercentage": 1.8042750000000003
  },
  {
    "utilizationRate": 55,
    "borrowApyPercentage": 4.125,
    "supplyApyPercentage": 1.8717187500000003
  },
  {
    "utilizationRate": 56,
    "borrowApyPercentage": 4.2,
    "supplyApyPercentage": 1.9404000000000001
  },
  {
    "utilizationRate": 57,
    "borrowApyPercentage": 4.2749999999999995,
    "supplyApyPercentage": 2.0103187499999997
  },
  {
    "utilizationRate": 58,
    "borrowApyPercentage": 4.35,
    "supplyApyPercentage": 2.0814749999999997
  },
  {
    "utilizationRate": 59,
    "borrowApyPercentage": 4.425,
    "supplyApyPercentage": 2.1538687499999996
  },
  {
    "utilizationRate": 60,
    "borrowApyPercentage": 4.5,
    "supplyApyPercentage": 2.2274999999999996
  },
  {
    "utilizationRate": 61,
    "borrowApyPercentage": 4.575,
    "supplyApyPercentage": 2.3023687500000003
  },
  {
    "utilizationRate": 62,
    "borrowApyPercentage": 4.6499999999999995,
    "supplyApyPercentage": 2.3784749999999995
  },
  {
    "utilizationRate": 63,
    "borrowApyPercentage": 4.725,
    "supplyApyPercentage": 2.4558187499999997
  },
  {
    "utilizationRate": 64,
    "borrowApyPercentage": 4.8,
    "supplyApyPercentage": 2.5343999999999998
  },
  {
    "utilizationRate": 65,
    "borrowApyPercentage": 4.875,
    "supplyApyPercentage": 2.61421875
  },
  {
    "utilizationRate": 66,
    "borrowApyPercentage": 4.95,
    "supplyApyPercentage": 2.695275
  },
  {
    "utilizationRate": 67,
    "borrowApyPercentage": 5.0249999999999995,
    "supplyApyPercentage": 2.7775687499999995
  },
  {
    "utilizationRate": 68,
    "borrowApyPercentage": 5.1,
    "supplyApyPercentage": 2.8611
  },
  {
    "utilizationRate": 69,
    "borrowApyPercentage": 5.175,
    "supplyApyPercentage": 2.9458687499999994
  },
  {
    "utilizationRate": 70,
    "borrowApyPercentage": 5.25,
    "supplyApyPercentage": 3.0318749999999994
  },
  {
    "utilizationRate": 71,
    "borrowApyPercentage": 5.325,
    "supplyApyPercentage": 3.1191187499999997
  },
  {
    "utilizationRate": 72,
    "borrowApyPercentage": 5.3999999999999995,
    "supplyApyPercentage": 3.2075999999999993
  },
  {
    "utilizationRate": 73,
    "borrowApyPercentage": 5.475,
    "supplyApyPercentage": 3.297318749999999
  },
  {
    "utilizationRate": 74,
    "borrowApyPercentage": 5.55,
    "supplyApyPercentage": 3.388275
  },
  {
    "utilizationRate": 75,
    "borrowApyPercentage": 5.625,
    "supplyApyPercentage": 3.48046875
  },
  {
    "utilizationRate": 76,
    "borrowApyPercentage": 5.7,
    "supplyApyPercentage": 3.573899999999999
  },
  {
    "utilizationRate": 77,
    "borrowApyPercentage": 5.7749999999999995,
    "supplyApyPercentage": 3.66856875
  },
  {
    "utilizationRate": 78,
    "borrowApyPercentage": 5.85,
    "supplyApyPercentage": 3.7644749999999996
  },
  {
    "utilizationRate": 79,
    "borrowApyPercentage": 5.925,
    "supplyApyPercentage": 3.8616187499999994
  },
  {
    "utilizationRate": 80,
    "borrowApyPercentage": 6,
    "supplyApyPercentage": 3.9599999999999995
  },
  {
    "utilizationRate": 81,
    "borrowApyPercentage": 6.7,
    "supplyApyPercentage": 4.477275
  },
  {
    "utilizationRate": 82,
    "borrowApyPercentage": 7.3999999999999995,
    "supplyApyPercentage": 5.006099999999999
  },
  {
    "utilizationRate": 83,
    "borrowApyPercentage": 8.1,
    "supplyApyPercentage": 5.546474999999999
  },
  {
    "utilizationRate": 84,
    "borrowApyPercentage": 8.799999999999999,
    "supplyApyPercentage": 6.0984
  },
  {
    "utilizationRate": 85,
    "borrowApyPercentage": 9.5,
    "supplyApyPercentage": 6.661874999999999
  },
  {
    "utilizationRate": 86,
    "borrowApyPercentage": 10.200000000000001,
    "supplyApyPercentage": 7.2369
  },
  {
    "utilizationRate": 87,
    "borrowApyPercentage": 10.9,
    "supplyApyPercentage": 7.823475
  },
  {
    "utilizationRate": 88,
    "borrowApyPercentage": 11.600000000000001,
    "supplyApyPercentage": 8.4216
  },
  {
    "utilizationRate": 89,
    "borrowApyPercentage": 12.3,
    "supplyApyPercentage": 9.031274999999999
  },
  {
    "utilizationRate": 90,
    "borrowApyPercentage": 13,
    "supplyApyPercentage": 9.6525
  },
  {
    "utilizationRate": 91,
    "borrowApyPercentage": 13.700000000000001,
    "supplyApyPercentage": 10.285274999999999
  },
  {
    "utilizationRate": 92,
    "borrowApyPercentage": 14.400000000000002,
    "supplyApyPercentage": 10.9296
  },
  {
    "utilizationRate": 93,
    "borrowApyPercentage": 15.1,
    "supplyApyPercentage": 11.585474999999999
  },
  {
    "utilizationRate": 94,
    "borrowApyPercentage": 15.8,
    "supplyApyPercentage": 12.2529
  },
  {
    "utilizationRate": 95,
    "borrowApyPercentage": 16.499999999999996,
    "supplyApyPercentage": 12.931874999999998
  },
  {
    "utilizationRate": 96,
    "borrowApyPercentage": 17.200000000000003,
    "supplyApyPercentage": 13.6224
  },
  {
    "utilizationRate": 97,
    "borrowApyPercentage": 17.9,
    "supplyApyPercentage": 14.324475
  },
  {
    "utilizationRate": 98,
    "borrowApyPercentage": 18.6,
    "supplyApyPercentage": 15.038100000000002
  },
  {
    "utilizationRate": 99,
    "borrowApyPercentage": 19.3,
    "supplyApyPercentage": 15.763274999999998
  },
  {
    "utilizationRate": 100,
    "borrowApyPercentage": 20,
    "supplyApyPercentage": 16.5
  }
];

export const IRM_04_15_80 = [
  {
    "utilizationRate": 0,
    "borrowApyPercentage": 0,
    "supplyApyPercentage": 0
  },
  {
    "utilizationRate": 1,
    "borrowApyPercentage": 0.05,
    "supplyApyPercentage": 0.00041250000000000005
  },
  {
    "utilizationRate": 2,
    "borrowApyPercentage": 0.1,
    "supplyApyPercentage": 0.0016500000000000002
  },
  {
    "utilizationRate": 3,
    "borrowApyPercentage": 0.15000000000000002,
    "supplyApyPercentage": 0.0037125
  },
  {
    "utilizationRate": 4,
    "borrowApyPercentage": 0.2,
    "supplyApyPercentage": 0.006600000000000001
  },
  {
    "utilizationRate": 5,
    "borrowApyPercentage": 0.25,
    "supplyApyPercentage": 0.0103125
  },
  {
    "utilizationRate": 6,
    "borrowApyPercentage": 0.30000000000000004,
    "supplyApyPercentage": 0.01485
  },
  {
    "utilizationRate": 7,
    "borrowApyPercentage": 0.35000000000000003,
    "supplyApyPercentage": 0.0202125
  },
  {
    "utilizationRate": 8,
    "borrowApyPercentage": 0.4,
    "supplyApyPercentage": 0.026400000000000003
  },
  {
    "utilizationRate": 9,
    "borrowApyPercentage": 0.45000000000000007,
    "supplyApyPercentage": 0.033412500000000005
  },
  {
    "utilizationRate": 10,
    "borrowApyPercentage": 0.5,
    "supplyApyPercentage": 0.04125
  },
  {
    "utilizationRate": 11,
    "borrowApyPercentage": 0.55,
    "supplyApyPercentage": 0.0499125
  },
  {
    "utilizationRate": 12,
    "borrowApyPercentage": 0.6000000000000001,
    "supplyApyPercentage": 0.0594
  },
  {
    "utilizationRate": 13,
    "borrowApyPercentage": 0.65,
    "supplyApyPercentage": 0.06971250000000001
  },
  {
    "utilizationRate": 14,
    "borrowApyPercentage": 0.7000000000000001,
    "supplyApyPercentage": 0.08085
  },
  {
    "utilizationRate": 15,
    "borrowApyPercentage": 0.75,
    "supplyApyPercentage": 0.09281249999999999
  },
  {
    "utilizationRate": 16,
    "borrowApyPercentage": 0.8,
    "supplyApyPercentage": 0.10560000000000001
  },
  {
    "utilizationRate": 17,
    "borrowApyPercentage": 0.8500000000000001,
    "supplyApyPercentage": 0.1192125
  },
  {
    "utilizationRate": 18,
    "borrowApyPercentage": 0.9000000000000001,
    "supplyApyPercentage": 0.13365000000000002
  },
  {
    "utilizationRate": 19,
    "borrowApyPercentage": 0.9500000000000002,
    "supplyApyPercentage": 0.1489125
  },
  {
    "utilizationRate": 20,
    "borrowApyPercentage": 1,
    "supplyApyPercentage": 0.165
  },
  {
    "utilizationRate": 21,
    "borrowApyPercentage": 1.05,
    "supplyApyPercentage": 0.18191249999999998
  },
  {
    "utilizationRate": 22,
    "borrowApyPercentage": 1.1,
    "supplyApyPercentage": 0.19965
  },
  {
    "utilizationRate": 23,
    "borrowApyPercentage": 1.1500000000000001,
    "supplyApyPercentage": 0.2182125
  },
  {
    "utilizationRate": 24,
    "borrowApyPercentage": 1.2000000000000002,
    "supplyApyPercentage": 0.2376
  },
  {
    "utilizationRate": 25,
    "borrowApyPercentage": 1.25,
    "supplyApyPercentage": 0.2578125
  },
  {
    "utilizationRate": 26,
    "borrowApyPercentage": 1.3,
    "supplyApyPercentage": 0.27885000000000004
  },
  {
    "utilizationRate": 27,
    "borrowApyPercentage": 1.35,
    "supplyApyPercentage": 0.30071250000000005
  },
  {
    "utilizationRate": 28,
    "borrowApyPercentage": 1.4000000000000001,
    "supplyApyPercentage": 0.3234
  },
  {
    "utilizationRate": 29,
    "borrowApyPercentage": 1.4500000000000002,
    "supplyApyPercentage": 0.34691250000000007
  },
  {
    "utilizationRate": 30,
    "borrowApyPercentage": 1.5,
    "supplyApyPercentage": 0.37124999999999997
  },
  {
    "utilizationRate": 31,
    "borrowApyPercentage": 1.55,
    "supplyApyPercentage": 0.3964125
  },
  {
    "utilizationRate": 32,
    "borrowApyPercentage": 1.6,
    "supplyApyPercentage": 0.42240000000000005
  },
  {
    "utilizationRate": 33,
    "borrowApyPercentage": 1.6500000000000001,
    "supplyApyPercentage": 0.44921249999999996
  },
  {
    "utilizationRate": 34,
    "borrowApyPercentage": 1.7000000000000002,
    "supplyApyPercentage": 0.47685
  },
  {
    "utilizationRate": 35,
    "borrowApyPercentage": 1.7500000000000002,
    "supplyApyPercentage": 0.5053125
  },
  {
    "utilizationRate": 36,
    "borrowApyPercentage": 1.8000000000000003,
    "supplyApyPercentage": 0.5346000000000001
  },
  {
    "utilizationRate": 37,
    "borrowApyPercentage": 1.8500000000000003,
    "supplyApyPercentage": 0.5647125000000001
  },
  {
    "utilizationRate": 38,
    "borrowApyPercentage": 1.9000000000000004,
    "supplyApyPercentage": 0.59565
  },
  {
    "utilizationRate": 39,
    "borrowApyPercentage": 1.9500000000000004,
    "supplyApyPercentage": 0.6274125000000002
  },
  {
    "utilizationRate": 40,
    "borrowApyPercentage": 2,
    "supplyApyPercentage": 0.66
  },
  {
    "utilizationRate": 41,
    "borrowApyPercentage": 2.0500000000000003,
    "supplyApyPercentage": 0.6934125
  },
  {
    "utilizationRate": 42,
    "borrowApyPercentage": 2.1,
    "supplyApyPercentage": 0.7276499999999999
  },
  {
    "utilizationRate": 43,
    "borrowApyPercentage": 2.15,
    "supplyApyPercentage": 0.7627124999999998
  },
  {
    "utilizationRate": 44,
    "borrowApyPercentage": 2.2,
    "supplyApyPercentage": 0.7986
  },
  {
    "utilizationRate": 45,
    "borrowApyPercentage": 2.25,
    "supplyApyPercentage": 0.8353124999999999
  },
  {
    "utilizationRate": 46,
    "borrowApyPercentage": 2.3000000000000003,
    "supplyApyPercentage": 0.87285
  },
  {
    "utilizationRate": 47,
    "borrowApyPercentage": 2.35,
    "supplyApyPercentage": 0.9112125000000001
  },
  {
    "utilizationRate": 48,
    "borrowApyPercentage": 2.4000000000000004,
    "supplyApyPercentage": 0.9504
  },
  {
    "utilizationRate": 49,
    "borrowApyPercentage": 2.45,
    "supplyApyPercentage": 0.9904125000000001
  },
  {
    "utilizationRate": 50,
    "borrowApyPercentage": 2.5,
    "supplyApyPercentage": 1.03125
  },
  {
    "utilizationRate": 51,
    "borrowApyPercentage": 2.5500000000000003,
    "supplyApyPercentage": 1.0729125
  },
  {
    "utilizationRate": 52,
    "borrowApyPercentage": 2.6,
    "supplyApyPercentage": 1.1154000000000002
  },
  {
    "utilizationRate": 53,
    "borrowApyPercentage": 2.6500000000000004,
    "supplyApyPercentage": 1.1587125
  },
  {
    "utilizationRate": 54,
    "borrowApyPercentage": 2.7,
    "supplyApyPercentage": 1.2028500000000002
  },
  {
    "utilizationRate": 55,
    "borrowApyPercentage": 2.75,
    "supplyApyPercentage": 1.2478125
  },
  {
    "utilizationRate": 56,
    "borrowApyPercentage": 2.8000000000000003,
    "supplyApyPercentage": 1.2936
  },
  {
    "utilizationRate": 57,
    "borrowApyPercentage": 2.85,
    "supplyApyPercentage": 1.3402124999999998
  },
  {
    "utilizationRate": 58,
    "borrowApyPercentage": 2.9000000000000004,
    "supplyApyPercentage": 1.3876500000000003
  },
  {
    "utilizationRate": 59,
    "borrowApyPercentage": 2.95,
    "supplyApyPercentage": 1.4359125000000001
  },
  {
    "utilizationRate": 60,
    "borrowApyPercentage": 3,
    "supplyApyPercentage": 1.4849999999999999
  },
  {
    "utilizationRate": 61,
    "borrowApyPercentage": 3.0500000000000003,
    "supplyApyPercentage": 1.5349125000000001
  },
  {
    "utilizationRate": 62,
    "borrowApyPercentage": 3.1,
    "supplyApyPercentage": 1.58565
  },
  {
    "utilizationRate": 63,
    "borrowApyPercentage": 3.15,
    "supplyApyPercentage": 1.6372124999999997
  },
  {
    "utilizationRate": 64,
    "borrowApyPercentage": 3.2,
    "supplyApyPercentage": 1.6896000000000002
  },
  {
    "utilizationRate": 65,
    "borrowApyPercentage": 3.25,
    "supplyApyPercentage": 1.7428124999999999
  },
  {
    "utilizationRate": 66,
    "borrowApyPercentage": 3.3000000000000003,
    "supplyApyPercentage": 1.7968499999999998
  },
  {
    "utilizationRate": 67,
    "borrowApyPercentage": 3.35,
    "supplyApyPercentage": 1.8517124999999999
  },
  {
    "utilizationRate": 68,
    "borrowApyPercentage": 3.4000000000000004,
    "supplyApyPercentage": 1.9074
  },
  {
    "utilizationRate": 69,
    "borrowApyPercentage": 3.45,
    "supplyApyPercentage": 1.9639125
  },
  {
    "utilizationRate": 70,
    "borrowApyPercentage": 3.5000000000000004,
    "supplyApyPercentage": 2.02125
  },
  {
    "utilizationRate": 71,
    "borrowApyPercentage": 3.5500000000000003,
    "supplyApyPercentage": 2.0794125
  },
  {
    "utilizationRate": 72,
    "borrowApyPercentage": 3.6000000000000005,
    "supplyApyPercentage": 2.1384000000000003
  },
  {
    "utilizationRate": 73,
    "borrowApyPercentage": 3.6500000000000004,
    "supplyApyPercentage": 2.1982125000000003
  },
  {
    "utilizationRate": 74,
    "borrowApyPercentage": 3.7000000000000006,
    "supplyApyPercentage": 2.2588500000000002
  },
  {
    "utilizationRate": 75,
    "borrowApyPercentage": 3.75,
    "supplyApyPercentage": 2.3203125
  },
  {
    "utilizationRate": 76,
    "borrowApyPercentage": 3.8000000000000007,
    "supplyApyPercentage": 2.3826
  },
  {
    "utilizationRate": 77,
    "borrowApyPercentage": 3.85,
    "supplyApyPercentage": 2.4457125
  },
  {
    "utilizationRate": 78,
    "borrowApyPercentage": 3.900000000000001,
    "supplyApyPercentage": 2.5096500000000006
  },
  {
    "utilizationRate": 79,
    "borrowApyPercentage": 3.95,
    "supplyApyPercentage": 2.5744124999999998
  },
  {
    "utilizationRate": 80,
    "borrowApyPercentage": 4,
    "supplyApyPercentage": 2.64
  },
  {
    "utilizationRate": 81,
    "borrowApyPercentage": 4.55,
    "supplyApyPercentage": 3.0405374999999997
  },
  {
    "utilizationRate": 82,
    "borrowApyPercentage": 5.1000000000000005,
    "supplyApyPercentage": 3.45015
  },
  {
    "utilizationRate": 83,
    "borrowApyPercentage": 5.65,
    "supplyApyPercentage": 3.8688374999999997
  },
  {
    "utilizationRate": 84,
    "borrowApyPercentage": 6.2,
    "supplyApyPercentage": 4.2966
  },
  {
    "utilizationRate": 85,
    "borrowApyPercentage": 6.75,
    "supplyApyPercentage": 4.733437500000001
  },
  {
    "utilizationRate": 86,
    "borrowApyPercentage": 7.300000000000001,
    "supplyApyPercentage": 5.17935
  },
  {
    "utilizationRate": 87,
    "borrowApyPercentage": 7.850000000000001,
    "supplyApyPercentage": 5.6343375
  },
  {
    "utilizationRate": 88,
    "borrowApyPercentage": 8.4,
    "supplyApyPercentage": 6.0984
  },
  {
    "utilizationRate": 89,
    "borrowApyPercentage": 8.95,
    "supplyApyPercentage": 6.571537499999999
  },
  {
    "utilizationRate": 90,
    "borrowApyPercentage": 9.5,
    "supplyApyPercentage": 7.05375
  },
  {
    "utilizationRate": 91,
    "borrowApyPercentage": 10.05,
    "supplyApyPercentage": 7.5450375
  },
  {
    "utilizationRate": 92,
    "borrowApyPercentage": 10.600000000000001,
    "supplyApyPercentage": 8.045399999999999
  },
  {
    "utilizationRate": 93,
    "borrowApyPercentage": 11.150000000000002,
    "supplyApyPercentage": 8.554837500000001
  },
  {
    "utilizationRate": 94,
    "borrowApyPercentage": 11.700000000000003,
    "supplyApyPercentage": 9.073350000000001
  },
  {
    "utilizationRate": 95,
    "borrowApyPercentage": 12.25,
    "supplyApyPercentage": 9.600937499999999
  },
  {
    "utilizationRate": 96,
    "borrowApyPercentage": 12.8,
    "supplyApyPercentage": 10.137599999999999
  },
  {
    "utilizationRate": 97,
    "borrowApyPercentage": 13.350000000000001,
    "supplyApyPercentage": 10.683337499999999
  },
  {
    "utilizationRate": 98,
    "borrowApyPercentage": 13.900000000000002,
    "supplyApyPercentage": 11.23815
  },
  {
    "utilizationRate": 99,
    "borrowApyPercentage": 14.45,
    "supplyApyPercentage": 11.802037499999999
  },
  {
    "utilizationRate": 100,
    "borrowApyPercentage": 15,
    "supplyApyPercentage": 12.374999999999998
  }
];