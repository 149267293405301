import { UseQueryResult } from 'react-query';
import { useHistory } from 'react-router';
import BigNumber from 'bignumber.js';
import { useContext } from 'react';
import { GetMarketsOutput } from 'clients/api/queries/getMarkets';
import { Asset, Market } from 'types';
import { indexBy, convertCoinsToWei } from 'utilities/common';
import { calculateCollateralValue, getCErcToken, getToken } from 'utilities';
import { VBEP_TOKENS, TOKENS } from 'constants/tokens';
import {
  useGetMarkets,
  useGetAssetsInAccount,
  useGetCTokenBalancesAll,
  useGetHypotheticalLiquidityQueries,
  useGetBlock,
  IGetCTokenBalancesAllOutput,
} from 'clients/api';
import { BLOCKS_PER_DAY } from 'constants/blocksPerDay';
import { BLOCKS_PER_YEAR } from 'constants/blocksPerYear';
import { MarketContext } from '../../../context/MarketContext';


const useUserMarketInfo = ({
  accountAddress,
}: {
  accountAddress?: string;
}): {
  assets: Asset[];
  userTotalBorrowLimitCents: BigNumber;
  userTotalBorrowBalanceCents: BigNumber;
  userTotalSupplyBalanceCents: BigNumber;
  totalDammDistributedWei: BigNumber;
  dailyBdamm: BigNumber | undefined;
  protocolTotalBorrows: string;
  protocolTotalSupply: string;
  protocolLiquidity: string;
  bdammPrice: number;
} => {
  const { userTotalBorrowLimit } = useContext(MarketContext);
  const history = useHistory();
  const { data: blockNumber } = useGetBlock();
  const vtAddresses = Object.values(VBEP_TOKENS)
    .filter(item => item.address)
    .map(item => item.address);
  const {
    data = {
      markets: [],
      dailyBdamm: undefined,
      totalBorrows: 'loading...',
      totalSupply: 'loading...',
      liquidity: 'loading...',
      bdamm: 'loading...',
      damm: 'loading...',
    },
    error,
  } = useGetMarkets({
    placeholderData: {
      markets: [],
      dailyBdamm: undefined,
      totalBorrows: 'loading...',
      totalSupply: 'loading...',
      liquidity: 'loading...',
      bdamm: 0,
      damm: 0,
    },
    meta: {
      blockNumber,
    },
  });
  const {
    markets,
    dailyBdamm,
    totalBorrows: protocolTotalBorrows = 'loading...',
    totalSupply: protocolTotalSupply = 'loading...',
    liquidity: protocolLiquidity = 'loading...',
  } = data;
  if (error && error.message === 'ACCESS_BLOCKED') {
    history.push('/blocked');
  }
  const { data: assetsInAccount = [] } = useGetAssetsInAccount(
    { account: accountAddress },
    { placeholderData: [], enabled: Boolean(accountAddress) },
  );
  const { data: cTokenBalancesAccount = [] } = useGetCTokenBalancesAll(
    { account: accountAddress || '', vtAddresses },
    { placeholderData: [], enabled: Boolean(accountAddress) },
  );

  let balances: Record<string, IGetCTokenBalancesAllOutput[number]> = {};
  balances = indexBy(
    (item: IGetCTokenBalancesAllOutput[number]) => item.cToken.toLowerCase(), // index by cToken address
    cTokenBalancesAccount,
  );

  const marketsMap = indexBy(
    (item: Market) => item.underlyingSymbol.toLowerCase(),
    markets as GetMarketsOutput['markets'],
  );
  const { bdammPrice } = useContext(MarketContext);
  // const { dammPrice } = useContext(MarketContext);
  const {
    assets,
    userTotalBorrowBalanceCents,
    userTotalBorrowLimitCents,
    userTotalSupplyBalanceCents,
    totalDammDistributedWei,
  } = Object.values(TOKENS as any).reduce(
    (acc, item, index) => {
      const { assets: assetAcc } = (acc as any);
      const toDecimalAmount = (mantissa: string): BigNumber =>
        new BigNumber(mantissa).shiftedBy(-(item as any).decimals);
      const cErcToken = getCErcToken((item as any).id);
      // if no corresponding vassets, skip
      if (!cErcToken) {
        return acc;
      }

      const market = marketsMap[(item as any).id] || {};
      const ctokenAddress = cErcToken.address.toLowerCase();
      const collateral = assetsInAccount
        .map((address: string) => address.toLowerCase())
        .includes(ctokenAddress);

      let walletBalance = new BigNumber(0);
      let supplyBalance = new BigNumber(0);
      let borrowBalance = new BigNumber(0);
      let isEnabled = false;
      const percentOfLimit = '0';

      const wallet = balances[ctokenAddress];
      if (accountAddress && wallet) {
        walletBalance = toDecimalAmount(wallet.tokenBalance);
        supplyBalance = toDecimalAmount(wallet.balanceOfUnderlying);
        borrowBalance = toDecimalAmount(wallet.borrowBalanceCurrent);
        isEnabled = toDecimalAmount(wallet.tokenAllowance).isGreaterThan(walletBalance);
      }

      const asset = {
        key: index,
        id: (item as any).id,
        img: (item as any).asset,
        vimg: (item as any).vasset,
        symbol: market.underlyingSymbol || (item as any).id.toUpperCase(),
        decimals: (item as any).decimals,
        assetType: (item as any).assetType || '',
        tokenAddress: market.underlyingAddress,
        vsymbol: market.symbol,
        ctokenAddress,
        supplyApy: new BigNumber(market.supplyApy || 0),
        borrowApy: new BigNumber(market.borrowApy || 0),
        collateralFactor: new BigNumber(market.collateralFactor || 0).div(1e18),
        tokenPrice: new BigNumber(market.tokenPrice || 0),
        liquidity: new BigNumber(market.liquidity || 0),
        borrowCaps: new BigNumber(market.borrowCaps || 0),
        totalSupply: new BigNumber(market.totalSupply || 0),
        totalSupply2: new BigNumber(market.totalSupply2 || 0),
        totalSupplyUsd: new BigNumber(market.totalSupplyUsd || 0),
        totalBorrows: new BigNumber(market.totalBorrows || 0),
        totalBorrows2: new BigNumber(market.totalBorrows2 || 0),
        totalBorrowsUsd: new BigNumber(market.totalBorrowsUsd || 0),
        walletBalance,
        supplyBalance,
        borrowBalance,
        isEnabled,
        collateral,
        percentOfLimit,
        hypotheticalLiquidity: ['0', '0', '0'] as [string, string, string],
        bdammPerDay: (new BigNumber(market.supplierDailyBdamm).times(BLOCKS_PER_DAY))
        .plus(new BigNumber(market.borrowerDailyBdamm).times(BLOCKS_PER_DAY))
        .div(new BigNumber(10).pow(getToken('bdamm').decimals)),
        bdammBorrowApy: ((new BigNumber(market.borrowerDailyBdamm).times(BLOCKS_PER_YEAR).times(bdammPrice))
        .div(new BigNumber(10).pow(getToken('bdamm').decimals)))
        .div(market.totalSupplyUsd)
        .times(100) || 0,
        bdammSupplyApy: ((new BigNumber(market.supplierDailyBdamm).times(BLOCKS_PER_YEAR).times(bdammPrice))
        .div(new BigNumber(10).pow(getToken('bdamm').decimals)))
        .div(market.totalSupplyUsd)
        .times(100) || 0,
        currentUtilizationRate: (new BigNumber(market.totalBorrowsUsd).div(market.totalSupplyUsd)).times(100),

      };
      // user totals
      const borrowBalanceUsdCents = asset.borrowBalance.times(asset.tokenPrice).times(100);
      const supplyBalanceUsdCents = asset.supplyBalance.times(asset.tokenPrice).times(100);
      (acc as any).userTotalBorrowBalanceCents = (acc as any).userTotalBorrowBalanceCents.plus(borrowBalanceUsdCents);
      (acc as any).userTotalSupplyBalanceCents = (acc as any).userTotalSupplyBalanceCents.plus(supplyBalanceUsdCents);

      // total distributed Damm
      (acc as any).totalDammDistributedWei = (acc as any).totalDammDistributedWei.plus(
        new BigNumber(market.totalDistributed).times(
          new BigNumber(10).pow(getToken('bdamm').decimals),
        ),
      );

      // Create borrow limit based on assets supplied as collateral
      if (asset.collateral) {
        (acc as any).userTotalBorrowLimitCents = (acc as any).userTotalBorrowLimitCents.plus(
          calculateCollateralValue({
            amountWei: convertCoinsToWei({ value: asset.supplyBalance, tokenId: asset.id }),
            asset,
          }).times(100),
        );
      }

      return { ...(acc as any), assets: [...assetAcc, asset] };
    },
    {
      assets: [],
      userTotalBorrowBalanceCents: new BigNumber(0),
      userTotalBorrowLimitCents: new BigNumber(userTotalBorrowLimit).shiftedBy(-16),
      userTotalSupplyBalanceCents: new BigNumber(0),
      totalDammDistributedWei: new BigNumber(0),
    },
  ) as any;

  let assetList = assets;

  // We use "hypothetical liquidity upon exiting a market" to disable the "exit market"
  // toggle. Sadly, the current VenusLens contract does not provide this info, so we
  // still have to query each market.
  const hypotheticalLiquidityQueries = useGetHypotheticalLiquidityQueries(
    { assetList, account: accountAddress, balances },
    { enabled: Boolean(accountAddress) },
  );

  assetList = (hypotheticalLiquidityQueries as Array<UseQueryResult<Asset>>).reduce(
    (acc: Asset[], result: UseQueryResult<Asset>, idx: number) => {
      const assetCopy = { ...assetList[idx] };
      if (result.data) {
        assetCopy.hypotheticalLiquidity = result.data;
      }
      acc.push(assetCopy);
      return acc;
    },
    [],
  );

  // percent of limit
  assetList = assetList.map((item: Asset) => ({
    ...item,
    percentOfLimit: new BigNumber(userTotalBorrowLimitCents).isZero()
      ? '0'
      : item.borrowBalance
          .times(item.tokenPrice)
          .div(userTotalBorrowLimitCents)
          .times(100)
          .dp(0, 1)
          .toFixed(),
  }));

  return {
    assets: assetList,
    userTotalBorrowLimitCents,
    userTotalBorrowBalanceCents,
    userTotalSupplyBalanceCents,
    dailyBdamm,
    totalDammDistributedWei,
    protocolLiquidity,
    protocolTotalBorrows,
    protocolTotalSupply,
    bdammPrice,
  };
};

export default useUserMarketInfo;
