enum Path {
  ROOT = '/',
  DASHBOARD = '/dashboard',
  BONDING = '/bonding',
  STAKING = '/staking',
  MARKET = '/market',
  MARKET_DETAILS = '/market/:cTokenId',
  TRANSACTION = '/transaction',
  BOOST = '/boost',
  FAUCET = '/faucet',
  BLOCKED = '/blocked',
}

export default Path;
