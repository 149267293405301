import { useCallback, useEffect, useState } from 'react';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector';
import { toast } from 'components/Toast';
import { LS_KEY_CONNECTED_CONNECTOR } from 'config';
import { useHistory } from 'react-router';
import { accountRestService } from 'utilities/accountRestService';
import { connectorsByName } from '../connectors';
import { Connector } from '../types';
import setupNetwork from './setUpNetwork';

const getConnectedConnector = (): Connector | undefined => {
  const lsConnectedConnector = window.localStorage.getItem(LS_KEY_CONNECTED_CONNECTOR);

  return (
    lsConnectedConnector &&
    Object.values(Connector).includes(lsConnectedConnector as Connector)
  ) ? (lsConnectedConnector as Connector) : undefined;
};

const useAuth = () => {
  const { activate, deactivate, account } = useWeb3React();
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const history = useHistory();
  const [registeredAccount, setRegisteredAccount] = useState<string>();

  useEffect(() => { if (isBlocked) history.push('/blocked'); }, [isBlocked]);
  const [connectedConnector, setConnectedConnector] = useState(getConnectedConnector());

  const logOut = useCallback(() => {
    deactivate();
    // This localStorage key is set by @web3-react/walletconnect-connector
    if (window.localStorage.getItem('walletconnect')) {
      connectorsByName[Connector.WalletConnect].close();
      connectorsByName[Connector.WalletConnect].walletConnectProvider = undefined;
    }

    // Remove flag indicating user is logged in
    window.localStorage.removeItem(LS_KEY_CONNECTED_CONNECTOR);
    setConnectedConnector(undefined);
  }, [deactivate]);

  const login = useCallback(
    async (connectorID: Connector) => {
      const connector = connectorsByName[connectorID];
      if (!connector) {
        // TODO: log error to Sentry (this case should never happen, as it means
        // an incorrect connectorID was passed to this function)

        toast.error({
          message: 'An internal error occurred: unsupported wallet. Please try again later',
        });
        return;
      }
      try {
        // Log user in
        await activate(connector, undefined, true);
        const accountAddress = await connector.getAccount();
        if (!accountAddress) {
          deactivate();
          return;
        }
        const accountRegistration = await accountRestService({
          method: 'POST',
          params: {
            account: accountAddress,
          },
        });
        if ('error' in accountRegistration) {
          deactivate();
          logOut();
          setRegisteredAccount('');
          if (accountRegistration.status === 403) {
            setIsBlocked(true);
          } else {
            toast.error({ message: accountRegistration.error });
          }
        } else {
          setRegisteredAccount(accountAddress.toLowerCase());
        }
        // Mark user as logged in
        window.localStorage.setItem(LS_KEY_CONNECTED_CONNECTOR, connectorID);
        setConnectedConnector(connectorID);
      } catch (error) {
        if (error instanceof UnsupportedChainIdError) {
          const hasSetup = await setupNetwork();
          if (hasSetup) {
            await activate(connector);
          }
          return;
        }

        // Reset wallet connect provider if user denied access to their account
        if (
          (error instanceof UserRejectedRequestErrorInjected ||
            error instanceof UserRejectedRequestErrorWalletConnect) &&
          connector instanceof WalletConnectConnector
        ) {
          connector.walletConnectProvider = undefined;
        }

        // Display error message
        let errorMessage;

        if (
          error instanceof UserRejectedRequestErrorInjected ||
          error instanceof UserRejectedRequestErrorWalletConnect
        ) {
          errorMessage = 'You need to authorize access to your account';
        } else if (
          error instanceof NoEthereumProviderError
        ) {
          // TODO: log error to Sentry
          errorMessage = 'An internal error occurred: no provider found. Please try again later';
        } else {
          errorMessage = (error as Error).message;
        }

        toast.error({ message: errorMessage });
      }
    },
    [activate],
  );
  useEffect(() => {
    const register = async () => {
      if (
        account
        // && registeredAccount
        // && connectedAccount
      ) {
        if (account.toLowerCase() !== registeredAccount) {
          // force login again, user has changed accounts after initial login
          const accountRegistration = await accountRestService({
            method: 'POST',
            params: {
              account,
            },
          });
          if ('error' in accountRegistration) {
            deactivate();
            logOut();
            setRegisteredAccount('');
            if (accountRegistration.status === 403) {
              setIsBlocked(true);
            } else {
              deactivate();
              setRegisteredAccount('');
              toast.error({ message: accountRegistration.error });
            }
          } else {
            setRegisteredAccount(account.toLowerCase());
          }
        }
      } else if (!account) {
        setRegisteredAccount('');
        deactivate();
      }
    };
    register();
  }, [account]);

  return { login, logOut, accountAddress: account, connectedConnector };
};

export default useAuth;
