import { MutationObserverOptions } from 'react-query';

import { CTokenId } from 'types';
import {
  useRepayNonEthCToken,
  // useRepayEth,
  RepayEthOutput,
  RepayNonEthCTokenOutput,
  IRepayNonEthCTokenInput,
  IRepayEthInput,
} from 'clients/api';

type Options = MutationObserverOptions<
  RepayEthOutput | RepayNonEthCTokenOutput,
  Error,
  Omit<IRepayNonEthCTokenInput, 'cTokenContract'> | Omit<IRepayEthInput, 'web3'>
>;

const useRepayCToken = ({ cTokenId }: { cTokenId: CTokenId }, options?: Options) => {
  const useRepayNonEthCTokenResult = useRepayNonEthCToken(
    { cTokenId: cTokenId as Exclude<CTokenId, 'weth'> },
    options,
  );
  // const useRepayEthResult = useRepayEth(options);
  // return cTokenId === 'weth' ? useRepayEthResult : useRepayNonEthCTokenResult;
  return useRepayNonEthCTokenResult;
};

export default useRepayCToken;
