import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    title: css`
      display: none;
      max-width: 1200px;
      ${theme.breakpoints.down('md')} {
        display: block;
        margin-left: ${theme.spacing(4)}; 
        margin-right: auto;
        margin-bottom: ${theme.spacing(2)};
        height: ${theme.spacing(6)};
        color: ${theme.palette.text.primary};
      }
    `,
  };
};
