import * as React from 'react';
import { SVGProps } from 'react';

const SvgCusdt = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
<svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M125.014 249.999C194.041 249.999 250 194.061 250 125.058C250 56.0552 194.041 0.117188 125.014 0.117188C55.9856 0.117188 0.0273438 56.0552 0.0273438 125.058C0.0273438 194.061 55.9856 249.999 125.014 249.999Z" fill="#EDF0F2" />
<path d="M204.363 221.475C185.308 237.203 161.952 246.874 137.326 249.218C104.394 252.442 71.4618 242.478 45.761 221.475C20.1579 200.473 3.93604 170.19 0.613503 137.172C-1.82954 112.555 3.05655 87.84 14.6854 66.056C26.3143 44.2719 44.1974 26.3953 65.9894 14.7706C87.7813 3.14588 112.505 -1.83613 137.131 0.60603C161.757 3.04819 185.014 12.6215 204.168 28.349L178.956 59.1202C165.959 48.4724 150.03 41.8297 133.222 40.2667C116.414 38.6061 99.6057 42.0251 84.752 49.9377C69.8982 57.8503 57.7807 70.0611 49.7676 84.9095C41.8521 99.7578 38.5295 116.658 40.1908 133.362C42.4384 155.83 53.481 176.539 70.9732 190.802C88.4654 205.162 110.941 211.902 133.417 209.655C150.226 207.994 166.056 201.352 179.053 190.704L204.363 221.475Z" fill={`url(#${id}__a`} />
<path d="M125.404 227.337C181.79 227.337 227.426 181.619 227.426 125.352C227.426 68.9868 181.79 23.2695 125.404 23.2695C69.0189 23.2695 23.2852 68.9868 23.2852 125.352C23.2852 181.717 69.0189 227.337 125.404 227.337Z" fill="white" />
<path fillRule="evenodd" clipRule="evenodd" d="M134.589 128.087C134.003 128.087 131.267 128.282 125.013 128.282C120.029 128.282 116.511 128.087 115.24 128.087C95.9891 127.208 81.7218 123.984 81.7218 119.979C81.7218 115.974 96.0869 112.75 115.24 111.871V124.863C116.511 124.961 120.126 125.156 125.11 125.156C131.071 125.156 134.101 124.863 134.589 124.863V111.968C153.743 112.848 168.108 116.071 168.108 120.076C168.108 123.984 153.84 127.305 134.589 128.087V128.087ZM134.589 110.601V99.0738H161.365V81.4902H88.5623V99.0738H115.338V110.601C93.5461 111.578 77.2266 115.876 77.2266 120.956C77.2266 126.035 93.5461 130.333 115.338 131.31V168.334H134.687V131.31C156.381 130.333 172.701 126.035 172.701 120.956C172.701 115.876 156.381 111.578 134.589 110.601V110.601Z" fill="#283947" />
<defs>
<linearGradient id={`${id}__a`} x1="89.8278" y1="13.8382" x2="128.981" y2="230.994" gradientUnits="userSpaceOnUse">
<stop stopColor="#27D3A2" />
<stop offset="1" stopColor="#9388FD" />
</linearGradient>
</defs>
</svg>


);

export default SvgCusdt;
