import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  return {
    container: css`
      max-width: 1200px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    `,
    marketHeader: css`
      margin-bottom: ${theme.spacing(6)};
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
      background-color: transparent;
      ${theme.breakpoints.down('md')} {
        margin-bottom: 0;
      }
    `,
    row: css`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    `,
    box: css`
      flex-basis: calc(33% - ${theme.spacing(2)});
      flex-grow: 1;
      border: 1px solid;
      border-color: ${theme.palette.secondary.light};
      background-color: ${theme.palette.background.paper};
      border-radius: ${theme.shape.borderRadius.large}px;
      padding: ${theme.spacing(4)};
      :first-of-type {
        margin-right: ${theme.spacing(1)};
        margin-bottom: ${theme.spacing(1)};
        margin-left: ${theme.spacing(1)};
      }
      :nth-of-type(2) {
        margin-left: ${theme.spacing(1)};
        margin-bottom: ${theme.spacing(1)};
        margin-right: ${theme.spacing(1)};
      }
      :last-of-type {
        margin-left: ${theme.spacing(1)};
        margin-bottom: ${theme.spacing(1)};
        margin-right: ${theme.spacing(1)};
      }
      ${theme.breakpoints.down('md')} {
        flex-basis: calc(100% - ${theme.spacing(2)});
        border-right: none;
        background-color: ${theme.palette.background.paper};
        border-radius: ${theme.shape.borderRadius.large}px;
        padding: ${theme.spacing(4)};
        flex-grow: 1;
      }
    `,
    title: css`
      display: block;
      margin-bottom: ${theme.spacing(1)};
      ${theme.breakpoints.down('xl')} {
        font-size: 0.75rem;
      }
    `,
    tableContainer: css`
      width: 100%;
      padding: 0;
      margin-bottom: ${theme.spacing(8)};
      border: 1px solid;
      border-color: ${theme.palette.secondary.light};

      ${theme.breakpoints.down('md')} {
        background-color: transparent;
        border: none;
      }
    `,
    value: css`
      ${theme.breakpoints.down('xl')} {
        font-size: ${theme.typography.body2.fontSize};
        font-weight: ${theme.typography.body2.fontWeight};
        letter-spacing: ${theme.typography.body2.letterSpacing};
        color: ${theme.palette.text.primary};
      }
    `,
    table: css`
      display: initial;
      ${theme.breakpoints.down('md')} {
        display: none;
      }
    `,
    cards: css`
      display: none;
      ${theme.breakpoints.down('md')} {
        display: initial;
      }
    `,
    cardContentGrid: css`
      ${theme.breakpoints.down('md')} {
        background-color: initial;
        padding-top: 0;
      }
      .table__table-cards__card-content {
        ${theme.breakpoints.down('md')} {
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr;
        }
      }
    `,
  };
};
