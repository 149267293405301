import * as React from 'react';
import { SVGProps } from 'react';

const SvgFrax = (props: SVGProps<SVGSVGElement>) => (
  <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="125.5" cy="125.5" r="112.5" fill="#D9D9D9" />
    <path d="M105.974 1.00415C64.3736 8.00415 30.9736 32.4041 12.5736 69.2041C3.57363 87.2041 -0.0263672 103.004 -0.0263672 124.604C-0.0263672 151.404 6.27363 172.404 21.1736 195.004C27.1736 204.104 44.8736 221.804 53.9736 227.804C97.6736 256.604 151.474 256.704 194.574 228.004C203.974 221.804 217.174 209.104 224.574 199.304C231.674 189.904 241.574 170.204 244.474 159.604C250.874 136.304 250.874 112.904 244.474 89.6041C241.574 79.0041 231.674 59.3041 224.574 49.9041C206.074 25.3041 179.374 8.50415 148.974 2.10415C138.274 -0.195853 116.274 -0.695853 105.974 1.00415ZM71.4736 51.2041C74.4736 54.2041 79.5736 59.0041 82.7736 61.8041L88.5736 66.9041L96.3736 63.2041C115.574 54.2041 139.774 55.0041 157.474 65.3041L161.874 67.9041L172.574 56.6041L183.174 45.2041L187.074 48.3041C189.274 50.0041 193.874 54.4041 197.374 58.0041L203.774 64.5041L192.774 76.2041L181.774 87.9041L185.174 95.3041C193.874 114.104 193.474 135.604 184.274 154.304L181.074 160.804L192.974 172.104L204.874 183.304L195.474 193.404C190.274 199.004 185.774 203.604 185.474 203.604C185.174 203.604 183.274 202.004 181.374 200.004C179.574 198.004 174.174 192.904 169.474 188.604L160.874 180.804L153.974 184.204C143.474 189.204 134.474 190.904 121.174 190.304C109.374 189.804 103.174 188.204 92.5736 182.804L87.1736 180.104L75.8736 192.004L64.5736 203.904L58.7736 198.804C55.5736 196.004 50.9736 191.704 48.5736 189.204L44.1736 184.704L55.7736 172.304L67.4736 159.904L64.0736 152.504C55.3736 133.804 55.5736 113.404 64.3736 94.9041L68.2736 86.8041L57.3736 76.6041C51.3736 71.0041 46.4736 66.1041 46.4736 65.7041C46.4736 64.7041 64.4736 45.6041 65.3736 45.6041C65.7736 45.6041 68.4736 48.1041 71.4736 51.2041Z" fill="black" />
    <path d="M112.374 85.4037C101.774 89.0037 92.9736 96.7037 87.8736 107.204C85.2736 112.604 84.9736 114.004 84.9736 123.604C84.9736 133.304 85.1736 134.604 88.1736 140.804C100.274 166.404 133.074 172.104 153.074 152.204C165.274 140.004 168.174 122.804 160.874 106.604C157.274 98.6037 147.874 89.7037 139.774 86.6037C131.774 83.5037 119.874 83.0037 112.374 85.4037Z" fill="black" />
  </svg>
);

export default SvgFrax;
