/** @jsxImportSource @emotion/react */
import React from 'react';
import { Paper } from '@mui/material';
import BigNumber from 'bignumber.js';
import { AuthContext } from 'context/AuthContext';
import { useUserMarketInfo } from 'clients/api';
import { Asset } from 'types';
import Distribution from './Distribution';
import { useStyles } from './styles';
import BaseTable from './BaseTable';
import VampTable from './VampTable';
import Redemption from './Redemption';

interface IBondingUiProps {
  accountAddress: string;
  userTotalBorrowBalanceCents: BigNumber;
  userTotalSupplyBalanceCents: BigNumber;
  assets: Asset[];
}

const BondingUi: React.FC<IBondingUiProps> = ({
  assets,
  userTotalBorrowBalanceCents,
  userTotalSupplyBalanceCents,
}) => {
  const styles = useStyles();
  return (
    <div css={styles.container}>
      <div css={styles.row}>
        <Distribution
          assets={assets}
          css={styles.column}
          userTotalBorrowBalanceCents={userTotalBorrowBalanceCents}
          userTotalSupplyBalanceCents={userTotalSupplyBalanceCents}
        />

      </div>
      <Paper css={styles.redemptionContainer}>
        <Redemption />
      </Paper>
      <Paper css={styles.tableContainer}>
        <BaseTable />
      </Paper>
      <Paper css={styles.tableContainer}>
        <VampTable />
      </Paper>

    </div>
  );
};

const Bonding: React.FC = () => {
  const { account } = React.useContext(AuthContext);
  const {
    assets,
    userTotalBorrowBalanceCents,
    userTotalSupplyBalanceCents,
  } = useUserMarketInfo({
    accountAddress: account?.address || '',
  });

  return (
    <BondingUi
      accountAddress={account?.address || ''}
      assets={assets}
      userTotalBorrowBalanceCents={userTotalBorrowBalanceCents}
      userTotalSupplyBalanceCents={userTotalSupplyBalanceCents}
    />
  );
};

export default Bonding;
