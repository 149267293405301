import { MutationObserverOptions, useMutation } from 'react-query';

import {
  queryClient,
  claimDammReward,
  IClaimDammRewardInput,
  ClaimDammRewardOutput,
} from 'clients/api';
import FunctionKey from 'constants/functionKey';
import { useComptrollerContract } from 'clients/contracts/hooks';

type Options = MutationObserverOptions<
  ClaimDammRewardOutput,
  Error,
  Omit<IClaimDammRewardInput, 'comptrollerContract' | 'venusLensContract'>
>;

const useClaimDammReward = (type: 'core' | 'staking' = 'core', options?: Options) => {
  const comptrollerContract = useComptrollerContract(type);
  // const venusLensContract = useVenusLensContract();

  return useMutation(
    FunctionKey.CLAIM_DAMM_REWARD,
    (params: Omit<IClaimDammRewardInput, 'comptrollerContract' | 'venusLensContract'>) =>
      claimDammReward({
        comptrollerContract,
        // venusLensContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: async (...onSuccessParams) => {
        // Trigger refetch of DAMM reward
        await Promise.all([
          queryClient.resetQueries(FunctionKey.GET_BDAMM_ACCRUED),
          queryClient.resetQueries(FunctionKey.GET_DAMM_INITIAL_INDEX),
          queryClient.resetQueries(FunctionKey.GET_CLAIM_MARKET_DATA),
          queryClient.resetQueries(FunctionKey.GET_DAMM_REWARD),
        ]);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useClaimDammReward;
