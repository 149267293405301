import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  return {
    container: css`
      width: 100%;
      max-width: 1200px;
      margin: auto;
    `,
    header: css`
      margin-bottom: ${theme.spacing(12)};
      border: 1px solid;
      border-color: ${theme.palette.secondary.light};
      padding: ${theme.spacing(6)};
    `,
    headerRoot: css`
      display: inline-flex;
      flex-direction: row;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      ${theme.breakpoints.down('md')} {
        flex-direction: column;
        align-items: flex-start;
      }
    `,
    button: css`
      margin-top: ${theme.spacing(4)};
      height: ${theme.spacing(8)};
      width: ${theme.spacing(40)}

    `,
    whiteText: css`
      color: ${theme.palette.text.primary};
      margin-right: ${theme.spacing(8)}
    `,
  };
};
