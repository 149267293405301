import * as React from 'react';
import { SVGProps } from 'react';

const SvgWeth = ({ ...props }: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M68.7005 250C133.123 250 133.122 249.045 144.046 245.816C158.321 241.596 103.742 203.975 68.7005 203.975C33.6588 203.975 5.25195 214.278 5.25195 226.987C5.25195 239.697 33.6603 250 68.7005 250Z" fill="black" />
        <path d="M214.984 54.3069L217.947 57.4868L215.356 60.9746C131.338 174.099 82.9385 225.318 62.4029 218.448C49.8947 214.264 37.3507 201.68 24.8065 183.407C20.3972 176.984 16.2567 170.221 12.491 163.491C9.98337 159.009 7.86089 154.921 6.75885 152.619C5.35306 149.682 4.75955 147.872 4.28867 145.496C4.25994 145.351 4.25994 145.351 4.23264 145.211C4.0361 144.202 3.96811 143.883 3.8212 143.332C1.29306 133.843 0 124.013 0 114.017C0 51.0471 51.2614 0 114.495 0C134.306 0 153.397 5.02074 170.315 14.4456L170.752 14.7177C184.604 24.3214 199.338 37.5176 214.984 54.3069ZM164.963 23.4499C149.657 14.9729 132.411 10.4603 114.495 10.4603C57.0626 10.4603 10.5042 56.8241 10.5042 114.017C10.5042 123.105 11.6786 132.034 13.9741 140.649C14.2011 141.501 14.3037 141.982 14.5445 143.218C14.5698 143.348 14.5698 143.348 14.5941 143.471C14.898 145.005 15.2186 145.982 16.2409 148.118C17.2463 150.218 19.2666 154.109 21.667 158.399C25.2864 164.868 29.2637 171.365 33.478 177.504C44.8115 194.013 56.0515 205.289 65.7479 208.533C78.6803 212.859 127.365 161.538 204.305 58.2581C190.337 43.5757 177.215 31.9728 164.963 23.4499Z" fill="black" />
        <path d="M164.963 23.4496C149.657 14.9726 132.411 10.46 114.495 10.46C57.0624 10.46 10.5039 56.8238 10.5039 114.017C10.5039 123.105 11.6784 132.034 13.9738 140.649C14.2008 141.501 14.3034 141.982 14.5443 143.218C14.5695 143.348 14.5695 143.348 14.5938 143.471C14.8977 145.004 15.2183 145.982 16.2407 148.117C17.246 150.218 19.2663 154.109 21.6668 158.399C25.2861 164.868 29.2635 171.365 33.4777 177.504C44.8113 194.013 56.0513 205.289 65.7477 208.532C78.68 212.859 127.365 161.537 204.305 58.2578C190.336 43.5754 177.214 31.9725 164.963 23.4496Z" fill="#EC1C79" />
        <path d="M135.505 248.956C72.2711 248.956 21.0098 197.909 21.0098 134.939C21.0098 71.969 72.2711 20.9219 135.505 20.9219C198.739 20.9219 250.001 71.969 250.001 134.939C250.001 197.909 198.739 248.956 135.505 248.956ZM135.505 238.495C192.938 238.495 239.496 192.131 239.496 134.939C239.496 77.746 192.938 31.3821 135.505 31.3821C78.0724 31.3821 31.5139 77.746 31.5139 134.939C31.5139 192.131 78.0724 238.495 135.505 238.495Z" fill="black" />
        <path d="M135.505 238.495C192.938 238.495 239.496 192.131 239.496 134.938C239.496 77.7457 192.938 31.3818 135.505 31.3818C78.0721 31.3818 31.5137 77.7457 31.5137 134.938C31.5137 192.131 78.0721 238.495 135.505 238.495Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M57.7149 156.151L43.9502 114.853H56.009L63.1854 138.577L71.1266 114.736H80.9501L88.8912 138.577L96.0677 114.853H107.891L94.1265 156.151H84.1854L75.8913 132.544L67.656 156.151H57.7149ZM112.421 155.858V114.853H145.538V124.519H123.715V130.728H143.479V139.69H123.715V146.192H145.832V155.858H112.421ZM161.715 155.858V124.812H149.362V114.853H185.479V124.812H173.126V155.858H161.715ZM190.479 155.858V114.853H201.891V130.201H216.538V114.853H227.95V155.858H216.538V140.276H201.891V155.858H190.479Z" fill="black" />
        <path d="M6.82328 102.517C3.91753 101.071 2.73974 97.5519 4.19262 94.6583C5.64549 91.7647 9.17885 90.5918 12.0846 92.0386L28.8913 100.407C31.797 101.854 32.9748 105.372 31.5219 108.266C30.0691 111.159 26.5357 112.332 23.6299 110.886L6.82328 102.517Z" fill="black" />
        <path d="M4.88953 130.273C1.93979 128.917 0.652142 125.437 2.01348 122.499C3.37482 119.562 6.86964 118.28 9.81938 119.635L26.626 127.359C29.5758 128.715 30.8634 132.195 29.5021 135.133C28.1408 138.07 24.6459 139.352 21.6962 137.997L4.88953 130.273Z" fill="black" />
    </svg>
);

export default SvgWeth;
