import * as React from 'react';
import { SVGProps } from 'react';

const SvgAngle = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="125" cy="125" r="123.5" fill="#D9D9D9" />
    <path d="M45.834 161.771L125 81.7949L204.167 161.771L164.583 201.759L125 161.771L85.417 201.758L45.834 161.771Z" fill="#FAF1E7" stroke="#131620" strokeWidth="3" strokeLinejoin="round" />
    <path d="M125 161.771L85.4166 201.764L77.5 181.961L125 133.976V161.771Z" fill="#131620" stroke="#131620" strokeWidth="3" strokeLinejoin="round" />
    <path d="M45.8285 161.763L85.4161 201.752L77.4999 181.961L30 133.976L45.8285 161.763Z" fill="#FAF1E7" stroke="#131620" strokeWidth="3" strokeLinejoin="round" />
    <path d="M124.999 161.771L164.583 201.764L172.499 181.961L124.999 133.976V161.771Z" fill="#FAF1E7" stroke="#131620" strokeWidth="3" strokeLinejoin="round" />
    <path d="M204.171 161.763L164.583 201.752L172.5 181.961L219.999 133.976L204.171 161.763Z" fill="#131620" stroke="#131620" strokeWidth="3" strokeLinejoin="round" />
    <path d="M30.001 133.971L125 38L220 133.971L172.5 181.956L125 133.971L77.5007 181.956L30.001 133.971Z" fill={`url(#${id}__a`} stroke="#131620" strokeWidth="3" strokeLinejoin="round" />
    <defs>
        <linearGradient id={`${id}__a`} x1="30.0024" y1="109.977" x2="220" y2="109.977" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FAF1E7" />
            <stop offset="0.26021" stopColor="#FDCEAA" />
            <stop offset="0.61201" stopColor="#F4837D" />
            <stop offset="0.9999" stopColor="#B9AAFD" />
        </linearGradient>
    </defs>
  </svg>
);

export default SvgAngle;
