/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import {
  formatCoinsToReadableValue,
  formatToReadablePercentage,
} from 'utilities/common';
import { formatCentsToReadableValue } from 'utilities';
import { Asset, TokenId } from 'types';
import { Table, TableProps, Token, LayeredValues } from 'components';
import { useTranslation } from 'translation';
import { useStyles } from './styles';
import { useStyles as useSharedStyles } from '../styles';

export interface ISuppliedTableUiProps {
  assets: Asset[];
  rowOnClick: (e: React.MouseEvent<HTMLElement>, row: TableProps['data'][number]) => void;
  collateralOnChange: (asset: Asset) => void;
}

export const SuppliedTable: React.FC<ISuppliedTableUiProps> = ({
  assets,
  rowOnClick,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const sharedStyles = useSharedStyles();

  const columns = useMemo(
    () => [
      { key: 'asset', label: t('markets.columns.asset'), orderable: false },
      { key: 'balance', label: t('markets.columns.balance'), orderable: true },
      { key: 'apy', label: t('markets.columns.apy'), orderable: true },
      { key: 'wallet', label: t('markets.columns.wallet'), orderable: true },
    ],
    [],
  );

  // Format assets to rows
  const rows: TableProps['data'] = assets.map(asset => [
    {
      key: 'asset',
      render: () => <Token symbol={asset.symbol as TokenId} />,
      value: asset.id,
    },
    {
      key: 'balance',
      render: () => (
        <LayeredValues
          topValue={formatCentsToReadableValue({
            value: asset.supplyBalance.times(asset.tokenPrice).times(100),
            shorthand: true,
          })}
          bottomValue={formatCoinsToReadableValue({
            value: asset.supplyBalance,
            tokenId: asset.id as TokenId,
            shorthand: true,
            suffix: true,
          })}
        />
      ),
      value: asset.supplyBalance.toFixed(),
    },
    {
      key: 'apy',
      render: () => {
        const apy = asset.supplyApy;
        return formatToReadablePercentage(apy);
      },
      value: asset.supplyApy.toFixed(),
    },
    {
      key: 'wallet',
      render: () =>
        formatCoinsToReadableValue({
          value: asset.walletBalance,
          tokenId: asset.symbol as TokenId,
          shorthand: true,
        }),
      value: asset.walletBalance.toFixed(),
    },
  ]);

  return (
    <>
      <h4 css={styles.title}>Supplied Assets</h4>
      <Table
        columns={columns}
        data={rows}
        initialOrder={{
          orderBy: 'apy',
          orderDirection: 'desc',
        }}
        rowOnClick={rowOnClick}
        rowKeyIndex={0}
        tableCss={sharedStyles.table}
        cardsCss={sharedStyles.cards}
        css={[sharedStyles.marketTable, styles.cardContentGrid]}
      />
    </>
  );
};

export default SuppliedTable;
