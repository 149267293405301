import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  const gap = theme.spacing(8);

  return {
    row: css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: ${gap};

    `,
    column: css`
      width: 100%;
      margin-bottom: ${theme.spacing(1)};
      justify-content: center;

    `,
    container: css`
      max-width: 1200px;
      margin: auto;
    `,
  };
};
