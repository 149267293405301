// IGetAllowanceInput
import { useQuery, QueryObserverOptions } from 'react-query';

import getAllowance, {
  IGetAllowanceInput,
  GetAllowanceOutput,
} from 'clients/api/queries/getAllowance';
import FunctionKey from 'constants/functionKey';
import { TokenId } from 'types';
import { useTokenContract } from 'clients/contracts/hooks';

type Options = QueryObserverOptions<
  GetAllowanceOutput,
  Error,
  GetAllowanceOutput,
  GetAllowanceOutput,
  FunctionKey.GET_ALLOWANCE
>;

const useGetAllowance = (
  { ownerAddress, spenderAddress, tokenId }: Omit<IGetAllowanceInput, 'tokenContract'> & { tokenId: TokenId },
  options?: Options,
) => {
  const tokenContract = useTokenContract(tokenId);
  return useQuery(
    `${FunctionKey.GET_ALLOWANCE}_${tokenId}` as FunctionKey.GET_ALLOWANCE,
    () => getAllowance({ ownerAddress, spenderAddress, tokenContract }),
    options,
  );
};

export default useGetAllowance;
