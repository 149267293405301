import { useQuery, QueryObserverOptions } from 'react-query';

import FunctionKey from 'constants/functionKey';
import { useVenusLensContract } from 'clients/contracts/hooks';
import { VBEP_TOKENS } from 'constants/tokens';
import getClaimMarketData, {
  IGetClaimMarketDataOutput,
} from './getClaimMarketData';

type Options = QueryObserverOptions<
  IGetClaimMarketDataOutput,
  Error,
  IGetClaimMarketDataOutput,
  IGetClaimMarketDataOutput,
  [FunctionKey.GET_CLAIM_MARKET_DATA, string]
>;

const markets = Object.values(VBEP_TOKENS)
  .filter(item => item.address && item.id !== 'damm')
  .map(item => item.address);

const useGetClaimMarketData = (
  account: string,
  options?: Options,
) => {
  const venusLensContract = useVenusLensContract();
  return useQuery(
    [FunctionKey.GET_CLAIM_MARKET_DATA, account],
    () => getClaimMarketData({ venusLensContract, account, markets }),
    options,
  );
};

export default useGetClaimMarketData;
