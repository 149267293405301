/** @jsxImportSource @emotion/react */
import { BigNumber } from 'bignumber.js';
import React from 'react';
import {
  calculateDailyEarningsCents,
  calculateYearlyBdammEarningsForAssets,
  calculateNetBdammApy,
} from 'utilities';
import { Asset } from 'types';
import DistributionUi, { IDistributionUiProps } from './DistributionUi';

interface IDistributionProps {
  className?: string;
  assets: Asset[];
  userTotalBorrowBalanceCents: BigNumber;
  userTotalSupplyBalanceCents: BigNumber;
}
/* TODO: Borrower Access - Get BorrowLimit Amount from query */
const Distribution: React.FC<IDistributionProps> = ({
  className,
  assets,
  userTotalBorrowBalanceCents,
  userTotalSupplyBalanceCents,
}) => {
  const calculations: Pick<
    IDistributionUiProps,
    'netBdammApyPercentage' | 'dailyBdammEarningsCents'
  > = React.useMemo(() => {
    const yearlyBdammEarningsCents = calculateYearlyBdammEarningsForAssets({
      assets,
    });
    const netBdammApyPercentage =
      userTotalSupplyBalanceCents &&
      yearlyBdammEarningsCents &&
      calculateNetBdammApy({
        borrowBalanceCents: userTotalBorrowBalanceCents,
        supplyBalanceCents: userTotalSupplyBalanceCents,
        yearlyBdammEarningsCents,
      });
    const dailyBdammEarningsCents =
      yearlyBdammEarningsCents && +calculateDailyEarningsCents(yearlyBdammEarningsCents).toFixed(0);
    return {
      netBdammApyPercentage,
      dailyBdammEarningsCents,
      supplyBalanceCents: userTotalSupplyBalanceCents?.toNumber(),
    };
  }, [JSON.stringify(assets)]);

  return (
    <DistributionUi
      className={className}
      {...calculations}
    />
  );
};

export default Distribution;
