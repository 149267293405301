import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Set a stale time of 10 seconds so query results don't get wiped out of
      // the cache instantly after their hook unmounts (see documentation for
      // more info: https://react-query.tanstack.com/guides/important-defaults)
      staleTime: 12000000, // 12,000,000 ms, 200 minutes
      cacheTime: 12000000, // 12,000,000 ms, 200 minutes
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});
export default queryClient;
