/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import { MarketContext } from 'context/MarketContext';
import { formatToReadablePercentage, isWhitelisted } from 'utilities/common';
import { formatCentsToReadableValue } from 'utilities';
import { useTranslation } from 'translation';
import { Icon, Tooltip, BorrowLimitUsedAccountHealth } from 'components';
import Paper from '@mui/material/Paper';
import { useMyAccountStyles as useStyles } from './styles';


export interface IMyAccountUiProps {
  netApyPercentage: number | undefined;
  dailyEarningsCents: number | undefined;
  supplyBalanceCents: number | undefined;
  borrowBalanceCents: number | undefined;
  borrowLimitCents: number | undefined;
  safeBorrowLimitPercentage: number;
  className?: string;
}

export const MyAccountUi = ({
  netApyPercentage,
  dailyEarningsCents,
  supplyBalanceCents,
  borrowBalanceCents,
  borrowLimitCents,
  safeBorrowLimitPercentage,
  className,
}: IMyAccountUiProps) => {
  const styles = useStyles();
  const { t } = useTranslation();


  const availableBorrowLimitCents =
    typeof borrowLimitCents === 'number'
      ? Math.floor(borrowLimitCents - (borrowBalanceCents || 0))
      : undefined;

  const readableAvailableBorrowLimit = formatCentsToReadableValue({
    value: availableBorrowLimitCents,
  });

  const readableNetApyPercentage = formatToReadablePercentage(netApyPercentage);

  const readableBorrowBalance = formatCentsToReadableValue({
    value: borrowBalanceCents,
  });

  const readableDailyEarnings = formatCentsToReadableValue({
    value: dailyEarningsCents,
  });

  const readableSupplyBalance = formatCentsToReadableValue({
    value: supplyBalanceCents,
  });
  const { userTotalBorrowLimit } = useContext(MarketContext);

  return (
    <Paper css={styles.container} className={className}>
      <div css={[styles.row, styles.header]}>
        <Typography variant="h4">{t('myAccount.title')}</Typography>
      </div>

      <div css={styles.netApyContainer}>
        <div css={styles.netApy}>
          <Typography component="span" variant="small2" css={styles.netApyLabel}>
            {t('myAccount.netApy')}
          </Typography>

          <Tooltip css={styles.tooltip} title={t('myAccount.netApyTooltip')}>
            <Icon css={styles.infoIcon} name="info" />
          </Tooltip>
        </div>

        <Typography
          variant="h1"
          color={
            netApyPercentage !== undefined && netApyPercentage >= 0
              ? 'interactive.success'
              : 'interactive.error'
          }
          component="span"
        >
          {readableNetApyPercentage}
        </Typography>
      </div>

      <ul css={styles.list}>
        <Typography component="li" variant="h4" css={styles.item}>
          <Typography component="span" variant="small2" css={styles.labelListItem}>
            {t('myAccount.dailyEarnings')}
          </Typography>

          {readableDailyEarnings}
        </Typography>

        <Typography component="li" variant="h4" css={styles.item}>
          <Typography component="span" variant="small2" css={styles.labelListItem}>
            {t('myAccount.supplyBalance')}
          </Typography>

          {readableSupplyBalance}
        </Typography>

        {isWhitelisted(userTotalBorrowLimit) ?
          <Typography component="li" variant="h4" css={styles.item}>
            <Typography component="span" variant="small2" css={styles.labelListItem}>
              {t('myAccount.borrowBalance')}
            </Typography>

            {readableBorrowBalance}
          </Typography> : null}
      </ul>

      {isWhitelisted(userTotalBorrowLimit) ?
      <>
        <BorrowLimitUsedAccountHealth
          css={styles.progressBar}
          borrowBalanceCents={borrowBalanceCents}
          safeBorrowLimitPercentage={safeBorrowLimitPercentage}
          borrowLimitCents={borrowLimitCents}
        />
        <div css={styles.bottom}>
          {/* <Icon name="shield" css={styles.shieldIcon} /> */}

          <Typography component="span" variant="small2" css={styles.inlineLabel}>
            {t('myAccount.availableLimit')}
          </Typography>

          <Typography component="span" variant="small1" color="text.primary" css={styles.safeLimit}>
            {readableAvailableBorrowLimit}
          </Typography>

          {/* <Tooltip
            css={styles.tooltip}
            title={t('myAccount.safeLimitTooltip', { safeBorrowLimitPercentage })}
          >
            <Icon css={styles.infoIcon} name="info" />
          </Tooltip> */}
        </div>
      </> : null
      }
    </Paper>
  );
};

export default MyAccountUi;
