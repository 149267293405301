/* eslint-disable react/no-unescaped-entities */
/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import { Paper, Typography } from '@mui/material';
import { Asset, TokenId } from 'types';
import BigNumber from 'bignumber.js';
import { useGetBalanceOf,
  // useUserMarketInfo
} from 'clients/api';
import { AuthContext } from 'context/AuthContext';
import {
  EllipseText,
  Icon,
  LayeredValues,
  PrimaryButton,
  // TertiaryButton,
  Tooltip,
  // IModalProps,
} from 'components';
import copy from 'copy-to-clipboard';
import {
  formatCentsToReadableValue,
  // calculateNetBdammApy,
  // calculateYearlyBdammEarningsForAssets,
  generateEtherScanUrl,
  getCErcToken,
} from 'utilities';
import {
  convertWeiToCoins,
  formatCoinsToReadableValue,
  formatToReadablePercentage } from 'utilities/common';
import { useTranslation } from 'translation';
import CollectUsdcRewardButton from 'components/Layout/CollectUsdcRewardButton';
import { useStyles } from '../styles';
import StakingModal from './StakingModal';

export interface IStakingProps {
  className?: string;
  userDamm: Asset;
  dailyEarningsCents: number | undefined;
  bdammPrice: number;
  // TODO:DAMM (?) use onClose?
  // onClose: IModalProps['handleClose'];
}

export const StakingContainer: React.FC<IStakingProps> = ({
  dailyEarningsCents,
  className,
  userDamm,
  bdammPrice,
}) => {
  const styles = useStyles();
  const gdammAddress = getCErcToken('damm').address;
  const etherscanUrl = generateEtherScanUrl('damm', 'market');
  const copyAddress = () => copy(etherscanUrl);
  const { t } = useTranslation();
  const { account } = useContext(AuthContext);
  const sharedRevenue = formatToReadablePercentage(10);
  const { data: userDammBalance } = useGetBalanceOf({
    tokenId: 'damm',
    accountAddress: account?.address || '',
  });
  const dammBalanceWei = (new BigNumber(userDammBalance || 0));
  const dammBalance = convertWeiToCoins({
    valueWei: dammBalanceWei,
    tokenId: 'damm',
    shorthand: true,
  });
  const readableDammBalance = formatCoinsToReadableValue({
    value: dammBalance,
    tokenId: 'damm',
    shorthand: true,
  });

  const [isStakingModalOpen, setIsStakingModalOpen] = React.useState(false);
  const openStakingModal = () => setIsStakingModalOpen(true);
  const closeStakingModal = () => setIsStakingModalOpen(false);

  const readableDailyBdammEarnings = formatCentsToReadableValue({
    value: (dailyEarningsCents || 0) / bdammPrice,
  });

  const stakingApy = new BigNumber(userDamm.bdammSupplyApy.div(bdammPrice));
  const readableStakingApyPercentage = formatToReadablePercentage(stakingApy);

  return (
    <>
      <Paper className={className}>
        <div css={styles.headerRoot}>
          <div>
            <h3 css={styles.whiteText}>
              Staking:
            </h3>
            <p>
              Stake dAMM Tokens to receive gdAMM and earn a percentage of dAMM protocols total revenues payed out in USDC.
            </p>
          </div>

          <PrimaryButton onClick={openStakingModal}>
            Stake dAMM
          </PrimaryButton>
          <StakingModal
            dammBalance={dammBalance}
            isOpen={isStakingModalOpen}
            onClose={closeStakingModal}
            userDamm={userDamm}
          />
        </div>
        <div css={styles.headerRoot}>
          <EllipseText css={styles.address} text={gdammAddress}>
            <div css={styles.dammIconContainer}>
              <Icon name="gdamm" size={styles.iconSizeXl} />
            </div>
            <div>
              <Icon name="gdammtype" css={styles.dammTypeContainer} />
            </div>
            <Typography
              className="ellipse-text"
              href={etherscanUrl}
              target="_blank"
              rel="noreferrer"
              variant="small2"
              component="a"
              css={[styles.whiteText, styles.addressText]}
            />
            <div css={styles.copyIconContainer}>
              <Tooltip title="Copy Address">
                <Icon name="copy" onClick={copyAddress} css={styles.copyIcon} size={styles.iconSizeXl} />
              </Tooltip>
            </div>
          </EllipseText>
          <div css={styles.stakeContainer}>
            <div css={styles.netApy}>
              <Typography component="span" variant="small2" css={styles.netApyLabel}>
                {t('Your Stake')}
              </Typography>
            </div>
            <LayeredValues
              topValue={formatCentsToReadableValue({
                value: userDamm.supplyBalance.multipliedBy(userDamm.tokenPrice).multipliedBy(100),
                shorthand: true,
              })}
              bottomValue={formatCoinsToReadableValue({
                value: userDamm.supplyBalance,
                tokenId: userDamm.id as TokenId,
                shorthand: true,
                suffix: true,
              })}
            />
          </div>
          <div css={styles.netApyContainer}>
            <div css={styles.netApy}>
              <Typography component="span" variant="small2" css={styles.netApyLabel}>
                {t('Total Staked')}
              </Typography>
            </div>
            <LayeredValues
              topValue={formatCentsToReadableValue({
                value: userDamm.totalSupplyUsd.times(100),
                shorthand: true,
              })}
              bottomValue={formatCoinsToReadableValue({
                value: userDamm.totalSupply2,
                tokenId: userDamm.id as TokenId,
                shorthand: true,
                suffix: true,
              })}
            />
          </div>
        </div>
        <div css={styles.headerRoot}>
          <div css={styles.netApyContainer}>
            <div css={styles.netApy}>
              <Typography component="span" variant="small2" css={styles.netApyLabel}>
                {t('Staking APY')}
              </Typography>
            </div>
            <Typography
              variant="h2"
              component="span"
            >
              {readableStakingApyPercentage}
            </Typography>
          </div>
          <div css={styles.netApyContainer}>
            <div css={styles.netApy}>
              <Typography component="span" variant="small2" css={styles.netApyLabel}>
                {t('Staking Daily Earnings')}
              </Typography>
            </div>
            <Typography
              variant="h2"
              component="span"
            >
              {readableDailyBdammEarnings}
            </Typography>
          </div>
          <div css={styles.netApyContainer}>
            <div css={styles.netApy}>
              <Typography component="span" variant="small2" css={styles.netApyLabel}>
                {t('dAMM Balance')}
              </Typography>
            </div>
            <Typography
              variant="h2"
              component="span"
            >
              {readableDammBalance}
            </Typography>
          </div>
          <div css={styles.netApyContainer}>
            <div css={styles.netApy}>
              <Typography component="span" variant="small2" css={styles.netApyLabel}>
                {t('Percentage of protocol fees.')}
              </Typography>

              <Tooltip css={styles.tooltip} title={t('Percentage of total protocol fees and revenues payed out to dAMM Stakers. ')}>
                <Icon css={styles.infoIcon} name="info" />
              </Tooltip>
            </div>
            <Typography
              variant="h2"
              component="span"
            >
              {sharedRevenue}
            </Typography>
          </div>
          <CollectUsdcRewardButton />
        </div>

      </Paper>
    </>
  );
};

export default StakingContainer;
