import BigNumber from 'bignumber.js';
import type { TransactionReceipt } from 'web3-core/types';

import { CTokenId } from 'types';
import { CTokenContract } from 'clients/contracts/types';
import { checkForTokenTransactionError } from 'utilities/errors';

export interface IRepayNonEthCTokenInput {
  cTokenContract: CTokenContract<CTokenId>;
  fromAccountAddress: string;
  amountWei: BigNumber;
}

export type RepayNonEthCTokenOutput = TransactionReceipt;

const repayNonEthCToken = async ({
  cTokenContract,
  fromAccountAddress,
  amountWei,
}: IRepayNonEthCTokenInput): Promise<RepayNonEthCTokenOutput> => {
  const resp = await cTokenContract.methods
    .repayBorrow(amountWei.toFixed())
    .send({ from: fromAccountAddress });
  return checkForTokenTransactionError(resp);
};

export default repayNonEthCToken;
