import * as React from 'react';
import { SVGProps } from 'react';

const SvgDusdc = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id={`${id}__c`} maskUnits="userSpaceOnUse" x="0" y="0" width="250" height="250">
        <path d="M0 0H250V250H0V0Z" fill="white" />
    </mask>
    <g mask={`url(#${id}__c`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M125 0C194.037 0 250 55.9631 250 125C250 194.031 194.037 250 125 250C55.9631 250 0 194.031 0 125C0 55.9631 55.9631 0 125 0Z" fill="#2775CA" />
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M101.567 211.909C101.567 214.849 99.2649 216.52 96.4211 215.632C58.6271 203.575 31.2251 168.136 31.2251 126.321C31.2251 84.5068 58.6271 49.0665 96.4211 37.0096C99.2649 36.1275 101.567 37.7923 101.567 40.7327V47.9912C101.567 49.9486 100.05 52.2525 98.1853 52.8903C68.2833 63.8704 46.8629 92.5963 46.8629 126.271C46.8629 159.946 68.2833 188.677 98.1853 199.652C100.05 200.339 101.567 202.592 101.567 204.557V211.909Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M132.794 184.898C132.794 187.057 131.03 188.822 128.874 188.822H121.078C118.922 188.822 117.158 187.057 117.158 184.898V172.547C100.098 170.245 91.7674 160.684 89.5117 147.695C89.1182 145.486 90.8824 143.478 93.1381 143.478H102.06C103.922 143.478 105.492 144.803 105.884 146.662C107.551 154.408 112.012 160.34 125.686 160.34C135.784 160.34 142.944 154.702 142.944 146.275C142.944 137.84 138.726 134.655 123.922 132.202C102.06 129.262 91.7177 122.643 91.7177 105.486C91.7177 92.252 101.719 81.9593 117.208 79.752V67.644C117.208 65.4863 118.973 63.7207 121.128 63.7207H128.924C131.08 63.7207 132.844 65.4863 132.844 67.644V80.0957C145.442 82.3542 153.432 89.5062 156.032 101.368C156.52 103.627 154.756 105.731 152.453 105.731H144.216C142.452 105.731 140.982 104.553 140.444 102.839C138.238 95.2932 132.844 92.0588 123.48 92.0588C113.138 92.0588 107.794 97.0574 107.794 104.066C107.794 111.468 110.836 115.191 126.719 117.499C148.186 120.439 159.267 126.566 159.267 144.854C159.267 158.725 148.972 169.951 132.844 172.547V184.898H132.794Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M153.579 215.632C150.738 216.52 148.433 214.849 148.433 211.909V204.656C148.433 202.498 149.756 200.49 151.815 199.751C181.717 188.771 203.137 160.045 203.137 126.37C203.137 92.6957 181.717 63.9699 151.815 52.9898C149.953 52.3023 148.433 50.0494 148.433 48.0849V40.8321C148.433 37.8918 150.738 36.1773 153.579 37.1034C191.373 49.1673 218.775 84.6062 218.775 126.42C218.728 168.135 191.373 203.525 153.579 215.632Z" fill="white" />
    <rect x="25" y="121" width="100" height="100" rx="50" fill={`url(#${id}__a`} />
    <path d="M87.75 204H81.5V197.75H75.25V204H69H62.75H56.5V197.75H50.25V191.5V185.25V179V172.75V166.5V160.25H56.5V154H62.75H69H75.25V160.25H81.5V154V147.75V141.5V135.25H87.75H94V141.5V147.75V154V160.25V166.5V172.75V179V185.25V191.5V197.75V204H87.75ZM69 191.5H75.25V185.25H81.5V179V172.75H75.25V166.5H69H62.75V172.75V179V185.25V191.5H69Z" fill={`url(#${id}__b`} />
    <defs>
      <radialGradient
        id={`${id}__a`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(50.6 149) rotate(71.2791) scale(76.0221)"
      >
        <stop stopColor="#66A9F7" />
        <stop offset={1} stopColor="#0075FF" />
      </radialGradient>
      <radialGradient
        id={`${id}__b`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(80.0548 138.253) rotate(75.4706) scale(85.4812 97.7006)"
      >
        <stop stopColor="#334155" />
        <stop offset={1} stopColor="#050A30" />
      </radialGradient>
    </defs>
  </svg>
);

export default SvgDusdc;
