import * as React from 'react';
import { SVGProps } from 'react';

const SvgDadai = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
<svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M124.987 249.885C192.397 249.885 247.044 195.258 247.044 127.872C247.044 60.4864 192.397 5.85938 124.987 5.85938C57.5764 5.85938 2.92969 60.4864 2.92969 127.872C2.92969 195.258 57.5764 249.885 124.987 249.885Z" fill="#EDF0F2" />
    <path d="M125 250C116.538 250.002 108.097 249.152 99.8049 247.461C83.8156 244.186 68.6277 237.794 55.1077 228.651C35.0107 215.085 19.2794 195.982 9.81975 173.656C6.62477 166.096 4.18612 158.237 2.5395 150.196C-0.846499 133.572 -0.846499 116.436 2.5395 99.8119C5.81438 83.8227 12.2055 68.6348 21.348 55.1147C34.9144 35.0166 54.0183 19.2842 76.345 9.8238C83.9054 6.62738 91.7637 4.18742 99.8049 2.53964C116.429 -0.846546 133.565 -0.846546 150.189 2.53964C166.178 5.81447 181.366 12.2055 194.886 21.3482C214.983 34.9145 230.714 54.018 240.174 76.3442C243.371 83.9048 245.812 91.7634 247.461 99.805C250.847 116.429 250.847 133.565 247.461 150.189C244.186 166.178 237.795 181.366 228.652 194.886C215.085 214.982 195.982 230.714 173.656 240.174C166.096 243.371 158.237 245.812 150.195 247.461C141.904 249.152 133.463 250.002 125 250V250ZM125 13.3922C117.445 13.3905 109.91 14.1502 102.507 15.6597C88.2305 18.5835 74.6695 24.29 62.5979 32.4536C44.6566 44.5678 30.613 61.6247 22.1684 81.558C19.3146 88.3093 17.1363 95.3266 15.6654 102.507C12.6422 117.35 12.6422 132.65 15.6654 147.492C18.5903 161.769 24.2978 175.329 32.4623 187.401C44.5754 205.344 61.632 219.39 81.5657 227.837C88.3174 230.69 95.335 232.868 102.516 234.339C117.358 237.363 132.658 237.363 147.501 234.339C161.778 231.415 175.339 225.708 187.409 217.542C205.347 205.428 219.388 188.373 227.832 168.443C230.686 161.691 232.864 154.673 234.335 147.492C237.358 132.65 237.358 117.35 234.335 102.507C231.41 88.2312 225.702 74.6708 217.538 62.6C205.427 44.6591 188.373 30.6149 168.442 22.1685C161.691 19.3149 154.673 17.1366 147.492 15.6656C140.09 14.1545 132.555 13.3931 125 13.3931V13.3922Z" fill={`url(#${id}__a`} />
    <path d="M125 220.982C178.009 220.982 220.981 178.01 220.981 125.001C220.981 71.9918 178.009 29.0195 125 29.0195C71.9908 29.0195 29.0186 71.9918 29.0186 125.001C29.0186 178.01 71.9908 220.982 125 220.982Z" fill={`url(#${id}__b`} />
    <path d="M181.918 109.165H170.527C164.257 91.7607 147.395 79.8262 125.16 79.8262H88.5908V109.165H75.8896V119.691H88.5908V130.726H75.8896V141.252H88.5908V170.238H125.16C147.139 170.238 163.873 158.4 170.303 141.252H181.918V130.728H172.862C173.093 128.839 173.21 126.937 173.214 125.033V124.777C173.214 123.05 173.116 121.353 172.958 119.69H181.948V109.165H181.918ZM98.829 89.2011H125.16C141.476 89.2011 153.602 97.2314 159.202 109.134H98.829V89.2011ZM125.16 160.771H98.829V141.223H159.137C153.507 152.932 141.417 160.77 125.16 160.77V160.771ZM162.625 125.258C162.628 127.078 162.5 128.896 162.241 130.697H98.829V119.662H162.273C162.505 121.434 162.622 123.218 162.625 125.005V125.258Z" fill="white" />
    <rect x="25" y="119" width="100" height="100" rx="50" fill={`url(#${id}__c`} />
    <path d="M87.75 202H81.5V195.75H75.25V202H69H62.75H56.5V195.75H50.25V189.5V183.25V177V170.75V164.5V158.25H56.5V152H62.75H69H75.25V158.25H81.5V152V145.75V139.5V133.25H87.75H94V139.5V145.75V152V158.25V164.5V170.75V177V183.25V189.5V195.75V202H87.75ZM69 189.5H75.25V183.25H81.5V177V170.75H75.25V164.5H69H62.75V170.75V177V183.25V189.5H69Z" fill={`url(#${id}__d`} />
    <defs>
    <linearGradient id={`${id}__a`} x1="210.75" y1="33.75" x2="51.5003" y2="221.5" gradientUnits="userSpaceOnUse">
    <stop stopColor="#B6509E" />
    <stop offset="1" stopColor="#2EBAC6" />
    </linearGradient>
    <linearGradient id={`${id}__b`} x1="125" y1="248.241" x2="125" y2="8.86347" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F9A606" />
    <stop offset="1" stopColor="#FBCC5F" />
    </linearGradient>
    <radialGradient id={`${id}__c`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(50.6 147) rotate(71.2791) scale(76.0221)">
    <stop stopColor="#66A9F7" />
    <stop offset="1" stopColor="#0075FF" />
    </radialGradient>
    <radialGradient id={`${id}__d`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(80.0548 136.987) rotate(76.1548) scale(89.6165 97.9959)">
    <stop stopColor="#334155" />
    <stop offset="1" stopColor="#050A30" />
    </radialGradient>
    </defs>
</svg>

);

export default SvgDadai;
