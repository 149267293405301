import * as React from 'react';
import { SVGProps } from 'react';

const SvgUsdt = (props: SVGProps<SVGSVGElement>) => (
  <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="125" cy="125" r="100" fill="#D9D9D9" />
    <path d="M106.401 1.34069C86.8007 4.34069 65.3007 13.3407 50.2007 24.7407C13.6007 52.3407 -5.49926 98.4407 1.40074 142.841C3.60074 157.241 6.40074 166.141 12.9007 179.441C19.9007 193.841 27.8007 204.741 39.2007 215.441C71.5007 246.041 116.201 256.941 159.901 244.841C170.501 241.941 190.201 232.041 199.601 224.941C204.001 221.641 211.201 215.141 215.501 210.541C246.101 178.241 257.001 133.541 244.901 89.8407C242.001 79.2407 232.101 59.5407 225.001 50.1407C217.601 40.2407 204.701 27.9407 195.101 21.5407C185.701 15.2407 169.301 7.54069 159.901 4.84069C142.601 0.0406945 122.801 -1.25931 106.401 1.34069ZM83.7007 92.0407C84.8007 95.5407 88.5007 107.941 91.9007 119.541C95.4007 131.241 98.5007 140.541 98.9007 140.341C99.3007 140.041 102.001 134.341 104.901 127.641C107.801 120.841 111.001 114.141 112.001 112.741C116.001 107.041 125.701 105.141 132.701 108.741C137.001 110.941 138.801 113.941 145.401 129.641C148.001 135.941 150.501 140.641 150.901 140.141C151.301 139.741 152.201 137.241 152.901 134.541L154.201 129.841H164.601H175.101L174.401 132.641C174.101 134.241 171.401 143.341 168.401 152.941C164.601 165.241 162.401 170.841 160.901 172.041C159.201 173.441 156.801 173.841 150.501 173.841C143.201 173.841 142.001 173.541 140.101 171.541C138.901 170.341 135.201 162.541 131.801 154.341C128.501 146.041 125.401 139.041 125.001 138.641C124.601 138.241 121.501 144.941 118.001 153.641C110.201 173.141 109.501 173.841 98.9007 173.841C92.1007 173.841 90.6007 173.541 88.5007 171.541C86.4007 169.741 83.7007 162.141 73.4007 128.041C66.5007 105.341 60.9007 86.5407 60.9007 86.3407C60.9007 86.0407 65.6007 85.8407 71.4007 85.8407H81.8007L83.7007 92.0407ZM188.901 86.4407C188.901 86.8407 186.601 94.2407 183.901 102.941L178.801 118.841H168.401C162.601 118.841 157.901 118.641 157.901 118.341C157.901 117.541 167.101 87.4407 167.601 86.5407C168.101 85.6407 188.901 85.5407 188.901 86.4407Z" fill="#101825" />
  </svg>

);

export default SvgUsdt;
