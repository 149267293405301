import * as React from 'react';
import { SVGProps } from 'react';

const SvgCuni = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
<svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M125.014 250C194.042 250 250 194.062 250 125.059C250 56.0554 194.042 0.117188 125.014 0.117188C55.9857 0.117188 0.0273438 56.0554 0.0273438 125.059C0.0273438 194.062 55.9857 250 125.014 250Z" fill="#EDF0F2" />
<path d="M204.364 221.476C185.308 237.203 161.953 246.874 137.327 249.219C104.394 252.443 71.462 242.479 45.7611 221.476C20.1579 200.473 3.93606 170.19 0.613505 137.172C-1.82955 112.555 3.05656 87.8403 14.6855 66.0562C26.3144 44.272 44.1976 26.3953 65.9896 14.7706C87.7816 3.14589 112.505 -1.83614 137.131 0.606032C161.757 3.0482 185.015 12.6215 204.169 28.3491L178.956 59.1204C165.959 48.4726 150.031 41.8299 133.222 40.2669C116.414 38.6062 99.606 42.0252 84.7522 49.9379C69.8985 57.8505 57.7809 70.0613 49.7677 84.9097C41.8522 99.7581 38.5297 116.658 40.191 133.362C42.4386 155.83 53.4812 176.54 70.9734 190.802C88.4657 205.162 110.942 211.903 133.418 209.656C150.226 207.995 166.057 201.352 179.054 190.705L204.364 221.476Z" fill={`url(#${id}__a`} />
<path d="M125.405 227.337C181.79 227.337 227.427 181.62 227.427 125.352C227.427 68.9869 181.79 23.2695 125.405 23.2695C69.0191 23.2695 23.2852 68.9869 23.2852 125.352C23.2852 181.717 69.0191 227.337 125.405 227.337Z" fill="white" />
<path d="M103.72 73.2818C102.177 73.0428 102.112 73.0148 102.838 72.9028C105.17 72.7687 107.509 72.9762 109.781 73.5188C115.068 74.7758 119.881 77.9948 125.013 83.7118L126.377 85.2308L128.329 84.9168C136.31 83.5644 144.513 84.5943 151.911 87.8778C153.791 88.7325 155.58 89.7759 157.249 90.9918C157.502 91.6591 157.667 92.3562 157.741 93.0658C158.261 96.5108 158.001 99.1508 156.948 101.123C156.686 101.446 156.525 101.84 156.485 102.255C156.446 102.67 156.53 103.087 156.727 103.454C156.911 103.833 157.197 104.154 157.553 104.379C157.909 104.604 158.321 104.725 158.742 104.728C160.481 104.728 162.352 101.915 163.219 98.0048L163.563 96.4518L164.246 97.2248C167.988 101.465 170.928 107.247 171.433 111.363L171.564 112.437L170.935 111.461C170.033 109.97 168.817 108.692 167.372 107.717C164.861 106.056 162.207 105.49 155.172 105.117C148.822 104.783 145.228 104.24 141.664 103.078C135.601 101.101 132.545 98.4678 125.342 89.0188C123.189 86.0358 120.801 83.2301 118.199 80.6288C114.301 76.6695 109.218 74.09 103.72 73.2818V73.2818Z" fill="#283947" />
<path d="M158.683 82.6513C158.709 80.4665 159.153 78.3068 159.991 76.2893C160.161 75.8629 160.373 75.4545 160.623 75.0703C160.577 75.4487 160.478 75.8188 160.33 76.1703C159.748 78.2915 159.657 80.5178 160.065 82.6793C160.552 85.9993 160.829 86.4793 164.338 90.0643C165.984 91.7463 167.898 93.8643 168.592 94.7783L169.854 96.4343L168.592 95.2503C166.745 93.5575 164.781 91.996 162.715 90.5773C162.19 90.3063 162.115 90.3113 161.787 90.6343C161.487 90.9343 161.425 91.3783 161.387 93.4903C161.475 96.0892 160.925 98.6701 159.787 101.008C159.202 102.151 159.109 101.908 159.639 100.617C160.039 99.6543 160.075 99.2313 160.072 96.0443C160.065 89.6443 159.305 88.1013 154.847 85.4643C153.504 84.6859 152.124 83.9714 150.712 83.3233C150.016 83.0433 149.34 82.7134 148.691 82.3363C150.809 82.7844 152.889 83.3915 154.915 84.1523C157.515 85.1523 157.948 85.2833 158.264 85.1623C158.477 85.0823 158.579 84.4673 158.683 82.6513V82.6513Z" fill="#283947" />
<path d="M106.719 93.6051C103.488 88.9597 101.855 83.3896 102.067 77.7351L102.197 76.2051L102.91 76.3351C104.562 76.6954 106.154 77.288 107.64 78.0951C110.607 79.8951 111.891 82.2701 113.198 88.3621C113.581 90.1461 114.084 92.1621 114.314 92.8501C115.106 94.7235 116.085 96.5125 117.235 98.1901C118.059 99.3901 117.512 99.9621 115.692 99.7981C112.912 99.5471 109.147 96.9491 106.719 93.6051Z" fill="#283947" />
<path d="M154.883 125.708C140.242 119.813 135.083 114.696 135.083 106.062C135.053 105.291 135.086 104.518 135.183 103.752C135.624 104.033 136.045 104.344 136.442 104.684C139.411 107.065 142.736 108.084 151.942 109.425C155.764 109.872 159.538 110.662 163.218 111.786C172.154 114.75 177.683 120.764 179.002 128.956C179.351 132.027 179.194 135.135 178.539 138.156C178.048 140.014 176.552 143.364 176.156 143.492C176.046 143.528 175.938 143.106 175.91 142.532C175.793 140.926 175.349 139.361 174.608 137.931C173.866 136.502 172.841 135.239 171.595 134.218C168.621 131.664 164.633 129.633 154.883 125.708V125.708Z" fill="#283947" />
<path d="M144.603 128.157C144.43 127.111 144.194 126.075 143.896 125.057L143.522 123.943L144.216 124.722C145.279 125.984 146.082 127.443 146.578 129.016C147.063 130.325 147.248 131.726 147.12 133.116C147.241 134.461 147.064 135.816 146.601 137.084C145.97 138.942 144.911 140.625 143.511 141.999C140.837 144.721 137.398 146.228 132.436 146.853C131.573 146.961 129.059 147.145 126.849 147.26C121.279 147.55 117.613 148.148 114.32 149.305C114.023 149.437 113.705 149.513 113.381 149.529C113.247 149.397 115.49 148.06 117.343 147.168C119.955 145.91 122.555 145.223 128.38 144.253C131.258 143.773 134.23 143.192 134.98 142.96C142.112 140.775 145.776 135.137 144.603 128.157Z" fill="#283947" />
<path d="M151.316 140.078C149.421 136.38 148.949 132.116 149.99 128.093C150.104 127.693 150.29 127.361 150.397 127.361C150.762 127.494 151.107 127.68 151.418 127.913C152.318 128.513 154.112 129.533 158.902 132.144C164.879 135.403 168.286 137.926 170.602 140.809C172.655 143.387 173.996 146.458 174.492 149.716C174.795 152.641 174.671 155.594 174.124 158.482C173.438 161.457 172.133 164.255 170.295 166.692C168.457 169.13 166.126 171.154 163.455 172.632C162.952 172.922 162.432 173.18 161.898 173.405C162.026 172.841 162.24 172.299 162.53 171.798C163.412 170.035 163.923 168.11 164.033 166.142C164.144 164.173 163.85 162.203 163.171 160.352C162.342 157.869 160.65 154.838 157.235 149.717C153.266 143.762 152.293 142.178 151.316 140.078V140.078Z" fill="#283947" />
<path d="M96.3211 162.638C101.563 158.141 107.89 155.091 114.673 153.791C117.849 153.381 121.071 153.505 124.206 154.16C128.145 155.168 131.669 157.426 133.506 160.117C135.297 162.746 136.065 165.038 136.865 170.135C137.181 172.146 137.524 174.166 137.628 174.622C138.001 176.873 139.138 178.926 140.847 180.437C143.147 182.121 147.111 182.226 151.008 180.706C151.414 180.517 151.843 180.382 152.284 180.306C152.426 180.446 150.463 181.76 149.078 182.452C147.445 183.327 145.616 183.772 143.764 183.743C140.194 183.743 137.23 181.927 134.757 178.223C133.848 176.658 133.036 175.038 132.326 173.373C129.715 167.417 128.426 165.603 125.394 163.618C124.143 162.772 122.699 162.257 121.195 162.12C119.691 161.983 118.177 162.23 116.794 162.836C116.015 163.198 115.33 163.736 114.793 164.406C114.256 165.077 113.881 165.862 113.698 166.702C113.515 167.541 113.528 168.411 113.737 169.245C113.946 170.078 114.345 170.852 114.902 171.505C116.026 172.676 117.5 173.448 119.102 173.705C119.74 173.79 120.388 173.737 121.003 173.549C121.618 173.361 122.185 173.044 122.667 172.618C123.148 172.191 123.532 171.667 123.793 171.079C124.054 170.491 124.186 169.854 124.179 169.211C124.238 168.428 124.031 167.647 123.592 166.995C123.153 166.343 122.507 165.858 121.759 165.617C119.394 164.55 116.851 165.798 116.859 168.017C116.842 168.45 116.966 168.877 117.212 169.233C117.459 169.589 117.816 169.855 118.227 169.99C118.837 170.266 118.851 170.29 118.353 170.185C116.183 169.735 115.674 167.118 117.42 165.385C119.515 163.3 123.848 164.22 125.336 167.066C125.974 168.668 126.028 170.444 125.489 172.081C124.835 173.667 123.615 174.954 122.065 175.691C120.516 176.428 118.749 176.564 117.105 176.071C114.649 175.444 113.649 174.765 110.687 171.714C105.542 166.414 103.544 165.386 96.1251 164.228L94.7031 164.006L96.3211 162.638Z" fill="#283947" />
<path fillRule="evenodd" clipRule="evenodd" d="M75.5275 69.172C92.7125 90.029 101.197 97.232 102.512 99.05C103.598 100.55 103.189 101.901 101.329 102.958C100.011 103.626 98.5745 104.028 97.1015 104.142C95.7719 104.089 94.5082 103.549 93.5516 102.624C92.8516 101.962 90.0345 97.752 83.5265 87.655C78.5475 79.929 74.3805 73.519 74.2665 73.412C74.0035 73.164 74.0085 73.172 83.0185 89.295C88.6775 99.419 90.5915 103 90.5915 103.477C90.5915 104.45 90.3255 104.962 89.1245 106.302C87.1245 108.536 86.2245 111.045 85.5815 116.24C84.8575 122.062 82.8205 126.175 77.1765 133.214C73.8765 137.335 73.3315 138.09 72.4985 139.751C71.5175 141.561 71.0163 143.592 71.0425 145.651C70.8747 148.114 71.2046 150.585 72.0125 152.917C72.8284 155.403 74.0148 157.753 75.5315 159.885C77.2515 162.549 78.2415 164.528 78.2415 165.303C78.2415 165.919 78.3595 165.92 81.0245 165.318C87.4025 163.878 92.5815 161.345 95.4945 158.241C96.2827 157.547 96.8961 156.676 97.2853 155.701C97.6745 154.725 97.8284 153.671 97.7346 152.625C97.8632 151.572 97.6836 150.505 97.2175 149.552C96.4595 147.94 95.0806 146.599 92.0396 144.52C88.0556 141.796 86.3535 139.604 85.8835 136.589C85.4975 134.115 85.9455 132.369 88.1495 127.749C90.4305 122.968 90.9965 120.93 91.3785 116.11C91.6255 112.997 91.9675 111.768 92.8625 110.783C93.7955 109.755 94.6355 109.407 96.9445 109.092C100.71 108.578 103.107 107.604 105.077 105.792C105.865 105.134 106.5 104.312 106.938 103.383C107.376 102.454 107.605 101.441 107.612 100.414L107.692 98.68L106.736 97.566C103.275 93.533 73.2115 66 72.9985 66C72.9525 66 74.0915 67.428 75.5275 69.172V69.172ZM80.1795 148.813C80.5567 148.145 80.6692 147.359 80.4947 146.612C80.3203 145.865 79.8716 145.21 79.2375 144.778C78.0015 143.956 76.0815 144.343 76.0815 145.414C76.0815 145.741 76.2625 145.979 76.6695 146.188C77.3555 146.541 77.4055 146.938 76.8695 147.749C76.3335 148.56 76.3695 149.293 76.9935 149.783C78.0005 150.575 79.4305 150.14 80.1795 148.813V148.813Z" fill="#283947" />
<path fillRule="evenodd" clipRule="evenodd" d="M110.065 110.001C109.101 110.368 108.236 110.956 107.539 111.716C106.841 112.476 106.331 113.389 106.048 114.381C105.731 115.7 105.852 117.086 106.394 118.33C107.179 119.379 107.938 119.655 109.994 119.641C114.019 119.613 117.518 117.887 117.925 115.728C118.259 113.959 116.725 111.508 114.605 110.428C113.167 109.841 111.587 109.692 110.065 110.001V110.001ZM114.77 113.68C114.917 113.479 115.016 113.248 115.06 113.003C115.104 112.758 115.092 112.507 115.024 112.267C114.956 112.028 114.834 111.808 114.667 111.623C114.501 111.438 114.295 111.294 114.064 111.201C112.053 109.973 109.014 110.989 109.014 112.888C109.014 113.833 110.599 114.865 112.052 114.865C113.078 114.84 114.054 114.415 114.77 113.68Z" fill="#283947" />
<defs>
<linearGradient id={`${id}__a`} x1="89.8278" y1="13.8382" x2="128.981" y2="230.994" gradientUnits="userSpaceOnUse">
<stop stopColor="#27D3A2" />
<stop offset="1" stopColor="#9388FD" />
</linearGradient>
</defs>
</svg>


);

export default SvgCuni;
