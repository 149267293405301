/** @jsxImportSource @emotion/react */
import React, { useMemo, useContext } from 'react';
import { MarketContext } from 'context/MarketContext';
import BigNumber from 'bignumber.js';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import PLACEHOLDER_KEY from 'constants/placeholderKey';
import { getToken, getCErcToken, formatCentsToReadableValue } from 'utilities';
import { CTokenId } from 'types';
import { useTranslation } from 'translation';
import {
  formatToReadablePercentage,
  formatCoinsToReadableValue,
  isWhitelisted,
} from 'utilities/common';
// import { ApyChart, IApyChartProps, InterestRateChart, IInterestRateChartProps } from 'components';
import { IApyChartProps, InterestRateChart, IInterestRateChartProps } from 'components';
import Path from 'constants/path';
import {
  wethWbtcInterestRates,
  altcoinInterestRates,
  fixedInterestRates7_5,
  legacyStableCoinInterestRates,
  fixedInterestRates5,
  // IRM_04_15_80,
  IRM_06_20_80,
  IRM_08_25_80,
  IRM_09_25_80,
  IRM_10_30_80,
  IRM_11_30_80,
} from './__mocks__/models';
import MarketInfo, { IMarketInfoProps } from './MarketInfo';
import useGetChartData from './useGetChartData';

import useGetMarketData from './useGetMarketData';
import Card, { ICardProps } from './Card';
import { useStyles } from './styles';
import MarketTitle from './MarketTitle';

export interface IMarketDetailsUiProps {
  cTokenId: CTokenId;
  currentUtilizationRate: number;
  supplyChartData: IApyChartProps['data'];
  borrowChartData: IApyChartProps['data'];
  interestRateChartData: IInterestRateChartProps['data'];
  totalBorrowBalanceCents?: number;
  totalSupplyBalanceCents?: number;
  borrowApyPercentage?: number;
  supplyApyPercentage?: number;
  bdammSupplyApy?: BigNumber;
  bdammBorrowApy?: BigNumber;
  bdammPerDay?: BigNumber;
  tokenPriceDollars?: number;
  marketLiquidityTokens?: BigNumber;
  supplierCount?: number;
  borrowerCount?: number;
  borrowCapCents?: number;
  dailyInterestsCoins?: BigNumber;
  reserveFactor?: number;
  collateralFactor?: number;
  mintedTokens?: BigNumber;
  reserveTokens?: BigNumber;
  exchangeRateCTokens?: BigNumber;
}

export const MarketDetailsUi: React.FC<IMarketDetailsUiProps> = ({
  cTokenId,
  totalBorrowBalanceCents,
  borrowApyPercentage,
  totalSupplyBalanceCents,
  supplyApyPercentage,
  bdammBorrowApy,
  bdammSupplyApy,
  bdammPerDay,
  currentUtilizationRate,
  tokenPriceDollars,
  marketLiquidityTokens,
  // supplierCount,
  // borrowerCount,
  dailyInterestsCoins,
  mintedTokens,
  exchangeRateCTokens,
  // supplyChartData,
  // borrowChartData,
  interestRateChartData,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const token = getToken(cTokenId);
  const cToken = getCErcToken(cTokenId);
  const { userTotalBorrowLimit } = useContext(MarketContext);

  const supplyInfoStats: ICardProps['stats'] = [
    {
      label: t('marketDetails.supplyInfo.stats.totalSupply'),
      value: formatCentsToReadableValue({
        value: totalSupplyBalanceCents,
        shorthand: true,
      }),
    },
    {
      label: t('marketDetails.supplyInfo.stats.apy'),
      value: formatToReadablePercentage(supplyApyPercentage),
    },
    {
      label: t('bdAMM APY'),
      value: formatToReadablePercentage(bdammSupplyApy),
    },
  ];

  const supplyInfoLegends: ICardProps['legends'] = [
    {
      label: t('marketDetails.legends.supplyApy'),
      color: styles.legendColors.supplyApy,
    },
  ];

  const borrowInfoStats: ICardProps['stats'] = useMemo(
    () => {
      const borrowInfoStatsResult = [
      {
        label: t('marketDetails.borrowInfo.stats.totalBorrow'),
        value: formatCentsToReadableValue({
          value: totalBorrowBalanceCents,
          shorthand: true,
        }),
      },
      {
        label: t('marketDetails.borrowInfo.stats.apy'),
        value: formatToReadablePercentage(borrowApyPercentage),
      },
    ];

    if (isWhitelisted(userTotalBorrowLimit)) {
      borrowInfoStatsResult.splice(2, 0, { label: t('bdAMM APY'), value: formatToReadablePercentage(bdammBorrowApy) });
    }
    return borrowInfoStatsResult;
  },
  [userTotalBorrowLimit],
    );

  const borrowInfoLegends: ICardProps['legends'] = [
    {
      label: t('marketDetails.legends.borrowApy'),
      color: styles.legendColors.borrowApy,
    },
  ];

  const interestRateModelLegends: ICardProps['legends'] = [
    {
      label: t('marketDetails.legends.utilizationRate'),
      color: styles.legendColors.utilizationRate,
    },
    {
      label: t('marketDetails.legends.borrowApy'),
      color: styles.legendColors.borrowApy,
    },
    {
      label: t('marketDetails.legends.supplyApy'),
      color: styles.legendColors.supplyApy,
    },
  ];

  const marketInfoStats: IMarketInfoProps['stats'] = [
    {
      label: t('marketDetails.marketInfo.stats.priceLabel'),
      value: tokenPriceDollars === undefined ? PLACEHOLDER_KEY : `$${tokenPriceDollars}`,
    },
    {
      label: t('marketDetails.marketInfo.stats.marketLiquidityLabel'),
      value: formatCoinsToReadableValue({
        value: marketLiquidityTokens,
        shorthand: true,
        tokenId: cTokenId,
      }),
    },
    // {
    //   label: t('marketDetails.marketInfo.stats.supplierCountLabel'),
    //   value: supplierCount ?? '-',
    // },
    // {
    //   label: t('marketDetails.marketInfo.stats.borrowerCountLabel'),
    //   value: borrowerCount ?? '-',
    // },
    {
      label: t('marketDetails.marketInfo.stats.dailyInterestsLabel'),
      value: formatCoinsToReadableValue({
        value: dailyInterestsCoins,
        tokenId: cTokenId,
      }),
    },
    {
      label: t('marketDetails.marketInfo.stats.bdammPerDay'),
      value: formatCoinsToReadableValue({
        value: bdammPerDay,
        tokenId: 'bdamm',
        shorthand: true,
      }),
    },
    {
      label: t('marketDetails.marketInfo.stats.mintedTokensLabel', { cTokenSymbol: cToken.symbol }),
      value: formatCoinsToReadableValue({
        value: mintedTokens,
        shorthand: true,
        tokenId: cTokenId,
      }),
    },
    {
      label: t('marketDetails.marketInfo.stats.exchangeRateLabel'),
      value: exchangeRateCTokens
        ? t('marketDetails.marketInfo.stats.exchangeRateValue', {
            tokenSymbol: token.symbol,
            cTokenSymbol: cToken.symbol,
            rate: exchangeRateCTokens.dp(6).toFixed(),
          })
        : PLACEHOLDER_KEY,
    },
  ];

  // DAMM:TODO add back when we have apy history api
  // if (!supplyChartData.length || !borrowChartData.length) {
  //   return <LoadingSpinner />;
  // }

  // @TODO: handle fetching errors

  return (
    <div css={styles.container}>
      <div css={[styles.column, styles.graphsColumn]}>
        <MarketTitle />
        <Card
          testId="market-details-supply-info"
          title={t('marketDetails.supplyInfo.title')}
          css={styles.graphCard}
          stats={supplyInfoStats}
          legends={supplyInfoLegends}
        >
          <div css={styles.apyChart}>
            {/* DAMM:TODO add back when we have apy history api */}
            {/* <ApyChart data={supplyChartData} type="supply" /> */}
          </div>
        </Card>

        <Card
          testId="market-details-borrow-info"
          title={t('marketDetails.borrowInfo.title')}
          css={styles.graphCard}
          stats={borrowInfoStats}
          legends={borrowInfoLegends}
        >
          <div css={styles.apyChart}>
            {/* DAMM:TODO add back when we have apy history api */}
            {/* <ApyChart data={borrowChartData} type="borrow" /> */}
          </div>
        </Card>

        <Card
          testId="market-details-interest-rate-model"
          title={t('marketDetails.interestRateModel.title')}
          css={styles.graphCard}
          legends={interestRateModelLegends}
        >
          <div css={styles.apyChart}>
            <InterestRateChart
              data={interestRateChartData}
              currentUtilizationRate={currentUtilizationRate}
            />
          </div>
        </Card>
      </div>

      <div css={[styles.column, styles.statsColumn]}>
        <MarketInfo stats={marketInfoStats} testId="market-details-market-info" />
      </div>
    </div>
  );
};

export type MarketDetailsProps = RouteComponentProps<{ cTokenId: CTokenId }>;

const MarketDetails: React.FC<MarketDetailsProps> = ({
  match: {
    params: { cTokenId },
  },
}) => {
  const cToken = getCErcToken(cTokenId);

  // Redirect to market page if cTokenId passed through route params is invalid
  if (!cToken) {
    return <Redirect to={Path.MARKET} />;
  }

  const chartData = useGetChartData({
    cTokenId,
  });

  const marketData = useGetMarketData({
    cTokenId,
    cTokenAddress: cToken.address,
  });

  const interestRateData = useMemo(() => {
    if (['wbtc', 'awbtc', 'cwbtc'].includes(cTokenId)) {
      return wethWbtcInterestRates;
    }
    if (cTokenId === 'usdt') {
      return IRM_11_30_80;
    }
    if (cTokenId === 'usdc') {
      return IRM_09_25_80;
    }
    if (cTokenId === 'dai') {
      return fixedInterestRates7_5;
    }
    if (cTokenId === 'ausdt' || cTokenId === 'cusdt') {
      return IRM_10_30_80;
    }
    if (cTokenId === 'ausdc' || cTokenId === 'cusdc') {
      return IRM_08_25_80;
    }
    if (cTokenId === 'cdai') {
      return fixedInterestRates5;
    }
    if (['ceth', 'aeth', 'weth'].includes(cTokenId)) {
      return IRM_06_20_80;
    }
    if (cTokenId === 'tusd') {
      return fixedInterestRates7_5;
    }
    if (['ageur', 'frax', 'adai', 'atusd', 'ctusd', 'cdai'].includes(cTokenId)) {
      return legacyStableCoinInterestRates;
    }
    return altcoinInterestRates;
  }, [cTokenId]);

  return (
    <>
      <MarketDetailsUi
        cTokenId={cTokenId}
        // TODO: pass actual data (see https://app.clickup.com/t/29xmavh)
        interestRateChartData={interestRateData}
        {...chartData}
        {...marketData}
      />
    </>
  );
};

export default MarketDetails;
