import * as React from 'react';
import { SVGProps } from 'react';

const SvgCusdc = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
<svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M125.014 250C194.042 250 250 194.062 250 125.059C250 56.0554 194.042 0.117188 125.014 0.117188C55.9857 0.117188 0.0273438 56.0554 0.0273438 125.059C0.0273438 194.062 55.9857 250 125.014 250Z" fill="#EDF0F2" />
<path d="M204.364 221.476C185.308 237.203 161.953 246.874 137.327 249.219C104.394 252.443 71.462 242.479 45.7611 221.476C20.1579 200.473 3.93606 170.19 0.613505 137.172C-1.82955 112.555 3.05656 87.8403 14.6855 66.0562C26.3144 44.272 44.1976 26.3953 65.9896 14.7706C87.7816 3.14589 112.505 -1.83614 137.131 0.606032C161.757 3.0482 185.015 12.6215 204.169 28.3491L178.956 59.1204C165.959 48.4726 150.031 41.8299 133.222 40.2669C116.414 38.6062 99.606 42.0252 84.7522 49.9379C69.8985 57.8505 57.7809 70.0613 49.7677 84.9097C41.8522 99.7581 38.5297 116.658 40.191 133.362C42.4386 155.83 53.4812 176.54 70.9734 190.802C88.4657 205.162 110.942 211.903 133.418 209.656C150.226 207.995 166.057 201.352 179.054 190.705L204.364 221.476Z" fill={`url(#${id}__a`} />
<path d="M125.405 227.337C181.79 227.337 227.427 181.62 227.427 125.352C227.427 68.9869 181.79 23.2695 125.405 23.2695C69.0191 23.2695 23.2852 68.9869 23.2852 125.352C23.2852 181.717 69.0191 227.337 125.405 227.337Z" fill="white" />
<path d="M142.409 133.461C142.409 123.887 136.545 120.664 125.112 119.198C116.708 118.026 115.047 116.072 115.047 112.165C115.047 108.257 117.881 105.913 123.353 105.913C128.337 105.913 131.171 107.574 132.343 111.579C132.441 111.969 132.734 112.36 133.125 112.653C133.516 112.946 133.907 113.044 134.396 113.044H138.793C139.086 113.044 139.379 112.946 139.575 112.849C139.868 112.751 140.063 112.556 140.259 112.36C140.454 112.165 140.552 111.872 140.65 111.676C140.748 111.383 140.748 111.09 140.65 110.797C139.282 104.545 134.982 100.833 128.337 99.6609V93.1159C128.337 92.5298 128.141 92.0413 127.75 91.6506C127.36 91.2598 126.871 91.0645 126.285 91.0645H122.083C121.496 91.0645 121.008 91.2598 120.617 91.6506C120.226 92.0413 120.03 92.5298 120.03 93.1159V99.4655C111.724 100.638 106.447 106.011 106.447 112.946C106.447 121.933 112.017 125.353 123.548 126.916C131.464 128.185 133.712 129.846 133.712 134.242C133.712 138.638 129.9 141.569 124.526 141.569C117.294 141.569 114.851 138.443 113.972 134.437C113.874 133.949 113.581 133.558 113.288 133.265C112.897 132.972 112.408 132.777 112.017 132.777H107.229C106.936 132.777 106.643 132.874 106.447 132.972C106.154 133.07 105.958 133.265 105.763 133.461C105.568 133.656 105.47 133.949 105.372 134.144C105.274 134.437 105.274 134.73 105.372 134.926C106.545 141.764 111.04 146.746 120.128 147.918V154.365C120.128 154.952 120.324 155.44 120.714 155.831C121.105 156.222 121.594 156.417 122.18 156.417H126.382C126.969 156.417 127.457 156.222 127.848 155.831C128.239 155.44 128.435 154.952 128.435 154.365V147.918C136.936 146.551 142.409 140.787 142.409 133.461V133.461Z" fill="#283947" />
<path d="M110.844 162.768C102.929 159.935 96.0882 154.66 91.2021 147.822C86.316 140.984 83.7752 132.778 83.7752 124.475C83.7752 116.171 86.4137 107.966 91.2021 101.128C96.0882 94.2896 102.929 89.0146 110.844 86.1816C111.333 85.9863 111.821 85.5955 112.115 85.1071C112.408 84.6187 112.603 84.1302 112.603 83.5441V79.7343C112.603 79.4413 112.603 79.0505 112.408 78.7574C112.31 78.4644 112.017 78.1713 111.724 77.9759C111.431 77.7806 111.137 77.6829 110.746 77.5852C110.356 77.4875 110.062 77.5852 109.769 77.7806C99.8016 80.9066 91.1043 87.1585 84.8501 95.5596C78.6936 103.961 75.3711 114.022 75.3711 124.475C75.3711 134.83 78.6936 144.989 84.8501 153.39C91.0066 161.791 99.7039 167.945 109.769 171.169C110.062 171.267 110.453 171.365 110.746 171.365C111.04 171.365 111.431 171.169 111.724 170.974C112.017 170.778 112.212 170.485 112.408 170.192C112.505 169.899 112.603 169.508 112.603 169.215V165.406C112.603 164.82 112.408 164.331 112.115 163.843C111.821 163.354 111.431 162.963 110.844 162.768V162.768Z" fill="#657786" />
<path d="M139.186 77.879C138.892 77.7813 138.501 77.6836 138.208 77.6836C137.915 77.6836 137.524 77.879 137.231 78.0743C136.938 78.2697 136.742 78.5628 136.547 78.8558C136.449 79.1489 136.352 79.5396 136.352 79.8327V83.6425C136.352 84.2286 136.547 84.717 136.84 85.2055C137.133 85.6939 137.622 85.987 138.111 86.28C146.026 89.113 152.867 94.388 157.753 101.226C162.639 108.064 165.18 116.27 165.18 124.573C165.18 132.877 162.541 141.082 157.753 147.92C152.867 154.758 146.026 160.034 138.111 162.866C137.622 163.062 137.133 163.453 136.84 163.843C136.547 164.332 136.352 164.82 136.352 165.406V169.216C136.352 169.509 136.352 169.9 136.547 170.193C136.645 170.486 136.938 170.779 137.231 170.974C137.524 171.17 137.817 171.268 138.208 171.365C138.501 171.365 138.892 171.365 139.186 171.17C149.153 168.044 157.85 161.792 164.105 153.391C170.261 144.99 173.584 134.928 173.584 124.476C173.584 114.121 170.261 103.961 164.105 95.5603C157.85 87.2569 149.153 81.0049 139.186 77.879V77.879Z" fill="#657786" />
<defs>
<linearGradient id={`${id}__a`} x1="89.8278" y1="13.8382" x2="128.981" y2="230.994" gradientUnits="userSpaceOnUse">
<stop stopColor="#27D3A2" />
<stop offset="1" stopColor="#9388FD" />
</linearGradient>
</defs>
</svg>


);

export default SvgCusdc;
