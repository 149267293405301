import React, { useState, useEffect, useContext } from 'react';
import BigNumber from 'bignumber.js';

import { useWeb3 } from 'clients/web3';
import { Asset, Market } from 'types';
import { VBEP_TOKENS, TOKENS } from 'constants/tokens';
import { getCErcToken, getToken } from 'utilities';
import { fetchMarkets } from 'utilities/api';
import { indexBy, notNull } from 'utilities/common';
import { useComptrollerContract, useVenusLensContract } from 'clients/contracts/hooks';
import { BLOCKS_PER_DAY } from 'constants/blocksPerDay';
import { BLOCKS_PER_YEAR } from 'constants/blocksPerYear';
import { useGetBlock } from 'clients/api';
import { AuthContext } from './AuthContext';

const MarketContext = React.createContext({
  markets: [] as $TSFixMe[],
  dailyBdamm: new BigNumber(0),
  userMarketInfo: [] as Array<Asset>,
  userTotalBorrowLimit: new BigNumber(0),
  userTotalBorrowBalance: new BigNumber(0),
  userBdammBalance: new BigNumber(0),
  protocolTotalBorrows: 'loading...',
  protocolTotalSupply: 'loading...',
  protocolLiquidity: 'loading...',
  bdammPrice: 0,
  dammPrice: 0,
});

// This context provide a way for all the components to share the market data, thus avoid
// duplicated requests

const MarketContextProvider = ({ children }: $TSFixMe) => {
  const [markets, setMarkets] = useState<$TSFixMe[]>([]);
  const [protocolLiquidity, setProtocolLiquidity] = useState<string>('loading...');
  const [protocolTotalBorrows, setProtocolTotalBorrows] = useState<string>('loading...');
  const [protocolTotalSupply, setProtocolTotalSupply] = useState<string>('loading...');
  const [bdammPrice, setBdammPrice] = useState<number>(0);
  const [dammPrice, setDammPrice] = useState<number>(0);
  const [dailyBdamm, setDailyBdamm] = useState(new BigNumber(0));
  const [userMarketInfo, setUserMarketInfo] = useState<Array<Asset>>([]);
  const [userTotalBorrowLimit, setUserTotalBorrowLimit] = useState(new BigNumber(0));
  const [userTotalBorrowBalance, setUserTotalBorrowBalance] = useState(new BigNumber(0));
  const [userBdammBalance] = useState(new BigNumber(0));
  const comptrollerContract = useComptrollerContract();
  const lens = useVenusLensContract();
  const { account } = useContext(AuthContext);
  const web3 = useWeb3();
  const { data: blockNumber } = useGetBlock();

  useEffect(() => {
    let isMounted = true;
    const getMarkets = async () => {
      const res = await fetchMarkets(blockNumber);
      if (!res.data || !res.data.status) {
        return;
      }
      const data = Object.keys(VBEP_TOKENS)
        .map(item => {
          if (res && res.data && res.data.data) {
            return res.data.data.markets.find(
              (market: Market) => market.underlyingSymbol.toLowerCase() === item.toLowerCase(),
            );
          }
          return undefined;
        })
        .filter(item => !!item);
      if (!isMounted) {
        return;
      }

      setMarkets(data);
      setDailyBdamm(res.data.data.dailyBdamm);
      setProtocolTotalBorrows(res.data.data.totalBorrows);
      setProtocolTotalSupply(res.data.data.totalSupply);
      setProtocolLiquidity(res.data.data.liquidity);
      setBdammPrice(res.data.data.bdamm || 0);
      setDammPrice(res.data.data.damm || 0);
    };
    getMarkets();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    const updateMarketUserInfo = async () => {
      if (!markets) {
        return;
      }

      try {
        // let bdammBalance = new BigNumber(0);
        const assetsIn = account
          ? await comptrollerContract.methods.getAssetsIn(account.address).call()
          : [];

        const vtAddresses = Object.values(VBEP_TOKENS)
          .filter(item => item.address)
          .map(item => item.address);

        let balances = {};
        if (account) {
          balances = indexBy(
            (item: $TSFixMe) => item.cToken.toLowerCase(), // index by cToken address
            await lens.methods.cTokenBalancesAll(vtAddresses, account.address).call(),
          );
          // bdammBalance = getBdammBalance(balances);
        }

        const marketsMap = indexBy(
          (item: $TSFixMe) => item.underlyingSymbol.toLowerCase(),
          markets,
        );

        const assetAndNullList = Object.values(TOKENS).map((item, index) => {
          const toDecimalAmount = (mantissa: string) =>
            new BigNumber(mantissa).shiftedBy(-item.decimals);

          // if no corresponding vassets, skip
          if (!getCErcToken((item as any).id)) {
            return null;
          }

          let market = marketsMap[item.symbol.toLowerCase()];
          if (!market) {
            market = {};
          }

          const ctokenAddress = getCErcToken((item as any).id).address.toLowerCase();

          const collateral = assetsIn
            .map((address: $TSFixMe) => address.toLowerCase())
            .includes(ctokenAddress);

          let walletBalance = new BigNumber(0);
          let supplyBalance = new BigNumber(0);
          let borrowBalance = new BigNumber(0);
          let isEnabled = false;
          const percentOfLimit = '0';

          if (account) {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            const wallet = balances[ctokenAddress];

            walletBalance = toDecimalAmount(wallet.tokenBalance);
            supplyBalance = toDecimalAmount(wallet.balanceOfUnderlying);
            borrowBalance = toDecimalAmount(wallet.borrowBalanceCurrent);
            if (item.id === 'eth') {
              isEnabled = true;
            } else {
              isEnabled = toDecimalAmount(wallet.tokenAllowance).isGreaterThan(walletBalance);
            }
          }

          return {
            key: index,
            id: item.id,
            img: item.asset,
            vimg: item.vasset,
            symbol: market.underlyingSymbol || '',
            decimals: item.decimals,
            tokenAddress: market.underlyingAddress,
            vsymbol: market.symbol,
            ctokenAddress,
            supplyApy: new BigNumber(market.supplyApy || 0),
            borrowApy: new BigNumber(market.borrowApy || 0),
            collateralFactor: new BigNumber(market.collateralFactor || 0).div(1e18),
            tokenPrice: new BigNumber(market.tokenPrice || 0),
            liquidity: new BigNumber(market.liquidity || 0),
            borrowCaps: new BigNumber(market.borrowCaps || 0),
            walletBalance,
            supplyBalance,
            borrowBalance,
            isEnabled,
            collateral,
            percentOfLimit,
            hypotheticalLiquidity: ['0', '0', '0'] as [string, string, string],
            bdammPerDay: (new BigNumber(market.supplierDailyBdamm).times(BLOCKS_PER_DAY))
            .plus(new BigNumber(market.borrowerDailyBdamm).times(BLOCKS_PER_DAY))
            .div(new BigNumber(10).pow(getToken('bdamm').decimals)),
            bdammBorrowApy: ((new BigNumber(market.borrowerDailyBdamm).times(BLOCKS_PER_YEAR).times(bdammPrice))
            .div(new BigNumber(10).pow(getToken('bdamm').decimals)))
            .div(market.totalSupplyUsd)
            .times(100),
            bdammSupplyApy: ((new BigNumber(market.supplierDailyBdamm).times(BLOCKS_PER_YEAR).times(bdammPrice))
            .div(new BigNumber(10).pow(getToken('bdamm').decimals)))
            .div(market.totalSupplyUsd)
            .times(100),
            currentUtilizationRate: (new BigNumber(market.totalBorrowsUsd).div(market.totalSupplyUsd)).times(100),
          };
        }) as any;

        let assetList = assetAndNullList.filter(notNull);

        // We use "hypothetical liquidity upon exiting a market" to disable the "exit market"
        // toggle. Sadly, the current VenusLens contract does not provide this info, so we
        // still have to query each market.
        assetList = await Promise.all(
          assetList.map(async (asset: any) => {
            const hypotheticalLiquidity: [string, string, string] = (
              account
                ? await comptrollerContract.methods
                    .getHypotheticalAccountLiquidity(
                      account.address,
                      asset.ctokenAddress,
                      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      balances[asset.ctokenAddress.toLowerCase()].balanceOf,
                      0,
                    )
                    .call()
                : ['0', '0', '0']
            ) as [string, string, string];

            return {
              ...asset,
              hypotheticalLiquidity,
            };
          }),
        );

        // borrower limits
        const totalBorrowBalance = assetList
          .reduce((acc: any, asset: any) => {
            const borrowBalanceUSD = asset.borrowBalance.times(asset.tokenPrice);
            return acc.plus(borrowBalanceUSD);
          }, new BigNumber(0));

        const totalBorrowLimit = account
          ? new BigNumber(
            await comptrollerContract.methods.getBorrowerLimits(account.address).call(),
          ) : new BigNumber(0);

        // percent of limit
        assetList = assetList.map((item: Asset) => ({
          ...item,
          percentOfLimit: new BigNumber(totalBorrowLimit).isZero()
            ? '0'
            : item.borrowBalance
                .times(item.tokenPrice)
                .div(totalBorrowLimit)
                .times(100)
                .dp(0, 1)
                .toString(10),
        }));


        if (!isMounted) {
          return;
        }


        setUserMarketInfo(assetList);
        setUserTotalBorrowLimit(totalBorrowLimit);
        setUserTotalBorrowBalance(totalBorrowBalance);
        // setUserBdammBalance(bdammBalance);
      } catch (error) {
        if (
          error instanceof Error
          && error.message
          && error.message.match('Address not permitted to borrow')
        ) {
          // this is an expected error
          return;
        }
        console.log('error on get market data', error);
      }
    };
    updateMarketUserInfo();
    return () => {
      isMounted = false;
    };
  }, [markets, account, web3]);

  return (
    <MarketContext.Provider
      value={{
        markets,
        dailyBdamm,
        bdammPrice,
        dammPrice,
        protocolLiquidity,
        protocolTotalBorrows,
        protocolTotalSupply,
        userMarketInfo,
        userTotalBorrowLimit,
        userTotalBorrowBalance,
        userBdammBalance,
      }}
    >
      {children}
    </MarketContext.Provider>
  );
};

export { MarketContext, MarketContextProvider };
