import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import type { TransactionReceipt } from 'web3-core';

import { CEthToken } from 'types/contracts';
import { getCErcToken } from 'utilities';
import { checkForTokenTransactionError } from 'utilities/errors';

export interface ISupplyEthInput {
  tokenContract: CEthToken;
  web3: Web3;
  account: string;
  amountWei: BigNumber;
}
// DAMM: this should never be used; no eth market, 'weth' inserted here to avoid type errors
const cEthAddress = getCErcToken('weth').address;

export type SupplyEthOutput = TransactionReceipt;

const supplyEth = async ({
  web3,
  tokenContract,
  account,
  amountWei,
}: ISupplyEthInput): Promise<SupplyEthOutput> => {
  const contractData = tokenContract.methods.mint().encodeABI();
  const tx = {
    from: account,
    to: cEthAddress,
    value: amountWei.toFixed(),
    data: contractData,
  };

  const resp = await web3.eth.sendTransaction(tx);
  return checkForTokenTransactionError(resp);
};

export default supplyEth;
