import * as React from 'react';
import { SVGProps } from 'react';

const SvgDausdt = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
<svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M124.987 246.955C192.397 246.955 247.044 192.328 247.044 124.942C247.044 57.5567 192.397 2.92969 124.987 2.92969C57.5764 2.92969 2.92969 57.5567 2.92969 124.942C2.92969 192.328 57.5764 246.955 124.987 246.955Z" fill="#EDF0F2" />
    <path d="M125 250C116.538 250.002 108.097 249.152 99.8049 247.461C83.8156 244.186 68.6277 237.794 55.1077 228.651C35.0107 215.085 19.2794 195.982 9.81975 173.656C6.62477 166.096 4.18612 158.237 2.5395 150.196C-0.846499 133.572 -0.846499 116.436 2.5395 99.8119C5.81438 83.8227 12.2055 68.6348 21.348 55.1147C34.9144 35.0166 54.0183 19.2842 76.345 9.8238C83.9054 6.62738 91.7637 4.18742 99.8049 2.53964C116.429 -0.846546 133.565 -0.846546 150.189 2.53964C166.178 5.81447 181.366 12.2055 194.886 21.3482C214.983 34.9145 230.714 54.018 240.174 76.3442C243.371 83.9048 245.812 91.7634 247.461 99.805C250.847 116.429 250.847 133.565 247.461 150.189C244.186 166.178 237.795 181.366 228.652 194.886C215.085 214.982 195.982 230.714 173.656 240.174C166.096 243.371 158.237 245.812 150.195 247.461C141.904 249.152 133.463 250.002 125 250V250ZM125 13.3922C117.445 13.3905 109.91 14.1502 102.507 15.6597C88.2305 18.5835 74.6695 24.29 62.5979 32.4536C44.6566 44.5678 30.613 61.6247 22.1684 81.558C19.3146 88.3093 17.1363 95.3266 15.6654 102.507C12.6422 117.35 12.6422 132.65 15.6654 147.492C18.5903 161.769 24.2978 175.329 32.4623 187.401C44.5754 205.344 61.632 219.39 81.5657 227.837C88.3174 230.69 95.335 232.868 102.516 234.339C117.358 237.363 132.658 237.363 147.501 234.339C161.778 231.415 175.339 225.708 187.409 217.542C205.347 205.428 219.388 188.373 227.832 168.443C230.686 161.691 232.864 154.673 234.335 147.492C237.358 132.65 237.358 117.35 234.335 102.507C231.41 88.2312 225.702 74.6708 217.538 62.6C205.427 44.6591 188.373 30.6149 168.442 22.1685C161.691 19.3149 154.673 17.1366 147.492 15.6656C140.09 14.1545 132.555 13.3931 125 13.3931V13.3922Z" fill={`url(#${id}__a`} />
    <path d="M124.999 29.0176C143.982 29.0176 162.539 34.6468 178.323 45.1933C194.107 55.7399 206.409 70.7301 213.674 88.2684C220.938 105.807 222.839 125.105 219.136 143.724C215.432 162.342 206.291 179.445 192.868 192.868C179.445 206.291 162.342 215.432 143.724 219.136C125.105 222.839 105.807 220.938 88.2684 213.674C70.7301 206.409 55.7399 194.107 45.1933 178.323C34.6468 162.539 29.0176 143.982 29.0176 124.999C29.0184 99.5432 39.1309 75.1305 57.1307 57.1307C75.1305 39.1309 99.5432 29.0184 124.999 29.0176Z" fill="#53AE94" />
    <path d="M136.847 112.211V97.9326H169.498V76.1777H80.5879V97.9326H113.243V112.199C86.7041 113.418 66.75 118.674 66.75 124.971C66.75 131.267 86.7138 136.523 113.243 137.75V183.485H136.854V137.746C163.345 136.523 183.259 131.271 183.259 124.98C183.259 118.689 163.345 113.437 136.854 112.215M136.854 133.872V133.86C136.188 133.902 132.765 134.108 125.144 134.108C119.052 134.108 114.765 133.935 113.254 133.856V133.876C89.8076 132.838 72.3066 128.754 72.3066 123.869C72.3066 118.984 89.8095 114.906 113.254 113.866V129.808C114.79 129.914 119.182 130.173 125.244 130.173C132.525 130.173 136.181 129.869 136.858 129.808V113.866C160.259 114.908 177.719 118.995 177.719 123.863C177.719 128.731 160.251 132.82 136.858 133.862" fill="white" />
    <rect x="25" y="119" width="100" height="100" rx="50" fill={`url(#${id}__b`} />
    <path d="M87.75 202H81.5V195.75H75.25V202H69H62.75H56.5V195.75H50.25V189.5V183.25V177V170.75V164.5V158.25H56.5V152H62.75H69H75.25V158.25H81.5V152V145.75V139.5V133.25H87.75H94V139.5V145.75V152V158.25V164.5V170.75V177V183.25V189.5V195.75V202H87.75ZM69 189.5H75.25V183.25H81.5V177V170.75H75.25V164.5H69H62.75V170.75V177V183.25V189.5H69Z" fill={`url(#${id}__c`} />
    <defs>
    <linearGradient id={`${id}__a`} x1="210.75" y1="33.75" x2="51.5003" y2="221.5" gradientUnits="userSpaceOnUse">
    <stop stopColor="#B6509E" />
    <stop offset="1" stopColor="#2EBAC6" />
    </linearGradient>
    <radialGradient id={`${id}__b`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(50.6 147) rotate(71.2791) scale(76.0221)">
    <stop stopColor="#66A9F7" />
    <stop offset="1" stopColor="#0075FF" />
    </radialGradient>
    <radialGradient id={`${id}__c`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(80.0548 136.987) rotate(76.1548) scale(89.6165 97.9959)">
    <stop stopColor="#334155" />
    <stop offset="1" stopColor="#050A30" />
    </radialGradient>
    </defs>
</svg>


);

export default SvgDausdt;
