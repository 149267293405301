/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { useStyles } from './styles';
import Header from '../Header';
import Title from '../Header/Title';

interface IPageContainerProps {
  children: ReactNode;
}

export const PageContainer = ({ children }: IPageContainerProps) => {
  const styles = useStyles();
  return (
    <main>
      <div css={styles.background} />
      <div css={styles.overlay} />
      <Header />
      <Title />
      <Box component="main" css={styles.main}>
        {children}
      </Box>
    </main>
  );
};
