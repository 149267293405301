import * as React from 'react';
import { SVGProps } from 'react';

const SvgLink = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="125" cy="125" r="125" fill="#2A5ADA" />
    <path d="M125.5 24L106.981 34.6481L56.5185 63.8148L38 74.463V175.389L56.5185 186.037L107.444 215.204L125.963 225.852L144.481 215.204L194.481 186.037L213 175.389V74.463L194.481 63.8148L144.019 34.6481L125.5 24ZM75.037 154.093V95.7593L125.5 66.5926L175.963 95.7593V154.093L125.5 183.259L75.037 154.093Z" fill="white" />
  </svg>
);

export default SvgLink;
