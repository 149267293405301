import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  return {
    container: css`
      width: 100%;
      border: 1px solid;
      border-color: ${theme.palette.secondary.light};
    `,
    row: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
    header: css`
      margin-bottom: ${theme.spacing(6)};
      padding: ${theme.spacing(2)} 0;
    `,
    headerRoot: css`
      display: inline-flex;
      flex-direction: row;
      flex: 1;
      align-items: center;
      margin-bottom: ${theme.spacing(6)};
      justify-content: space-between;
      width: 100%;
      ${theme.breakpoints.down('md')} {
        flex-direction: column;
        align-items: flex-start;
      }
      :last-of-type {
        margin-bottom: 0;
      }
    `,
    button: css`
    display: inline-block;
    ${theme.breakpoints.down('md')} {
        margin-top: ${theme.spacing(4)};
      }
    `,
    whiteText: css`
      color: ${theme.palette.text.primary};
    `,
    tooltip: css`
      display: flex;
    `,
    infoIcon: () => css`
      cursor: help;
    `,
    labelListItem: css`
      display: block;
      margin-bottom: ${theme.spacing(1)};
    `,
    inline: css`
      display: inline-block;
    `,
    netApyContainer: css`
      margin-bottom: ${theme.spacing(4)};
    `,
    netApy: css`
      display: flex;
      align-items: center;
      margin-bottom: ${theme.spacing(1)};
    `,
    netApyLabel: css`
      margin-right: ${theme.spacing(2)};
    `,
    list: css`
      padding-left: 0;
      display: flex;
      margin-bottom: ${theme.spacing(6)};

      ${theme.breakpoints.down('sm')} {
        flex-direction: column;
      }
    `,
    item: css`
      list-style: none;
      padding-left: ${theme.spacing(8)};
      padding-right: ${theme.spacing(8)};
      border-right: 1px solid ${theme.palette.secondary.light};

      ${theme.breakpoints.down('sm')} {
        border-right: none;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;

        & + & {
          margin-top: ${theme.spacing(2)};
        }
      }

      :first-of-type {
        border-left: none;
        padding-left: 0;
      }
      :last-child {
        border-right: none;
        padding-right: 0;
      }
    `,
    inlineLabel: css`
      margin-right: ${theme.spacing(1)};
    `,
    progressBar: css`
      margin-bottom: ${theme.spacing(3)};
    `,
    shieldIcon: css`
      margin-right: ${theme.spacing(2)};
    `,
    safeLimit: css`
      margin-right: ${theme.spacing(2)};
    `,
    bottom: css`
      display: flex;
      justify-content: flex-end;
      align-items: center;
    `,
    dammIconContainer: css`
      height: ${theme.shape.iconSize.large}px;
      width: ${theme.shape.iconSize.large}px;
      margin-right: ${theme.spacing(3)};
      margin-bottom: ${theme.spacing(1)}
    `,
    dammTypeContainer: css`
      height: 25px  ;
      width: 70px ;
      padding-top: ${theme.spacing(1)};
    `,
    copyIconContainer: css`
      height: ${theme.shape.iconSize.xLarge}px;
      width: ${theme.shape.iconSize.xLarge}px;
      cursor: pointer;
      &:hover > svg {
        color: ${theme.palette.button.medium};
      }
    `,
    address: css`
      flex: 1;
      display: inline-flex;
      align-items: center;
      max-width: 80%;
      min-width: none;
      justify-content: flex-start;
      margin-right: 2rem;
      ${theme.breakpoints.down('md')} {
        max-width: 50%;
        min-width: none;
        justify-content: flex-start;
      }
    `,
    copyIcon: css`
      color: ${theme.palette.button.main};
    `,
    addressText: css`
      margin: 0 ${theme.spacing(3)};
    `,
    iconSize: `${theme.shape.iconSize.large}px`,
    iconSizeXl: `${theme.shape.iconSize.xLarge}px`,
    slider: css`
      justify-content: flex-end;
      flex: 1;
      max-width: 100%;
      ${theme.breakpoints.down('md')} {
        padding-bottom: ${theme.spacing(6)};
        max-width: 100%;
        width: 100%;
      }
    `,
    fontWeight400: css`
      font-weight: 400;
    `,
  };
};
