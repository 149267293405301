/** @jsxImportSource @emotion/react */
import React from 'react';
import { Paper } from '@mui/material';
import { Spinner } from 'components/Spinner';
import { useUserMarketInfo } from 'clients/api';
import { AuthContext } from 'context/AuthContext';
import { calculateDailyEarningsCents, calculateYearlyBdammEarningsForAssets } from 'utilities';
import { useStyles } from './styles';
import StakingContainer from './StakingContainer';


const Staking: React.FC = () => {
  const styles = useStyles();
  const { account } = React.useContext(AuthContext);
  // const { userMarketInfo: marketContext } = useContext(MarketContext);
  const { assets, bdammPrice } = useUserMarketInfo({ accountAddress: account?.address || '' });
  // const userDamms = marketContext.filter((asset) => asset.id === 'damm');
  const userDamm = assets.filter((asset) => asset.id === 'damm');

  const yearlyEarningsCents = calculateYearlyBdammEarningsForAssets({
    assets: userDamm,
  });
  const dailyEarningsCents =
    yearlyEarningsCents
      ? calculateDailyEarningsCents(yearlyEarningsCents).toNumber()
      : 0;

  if (userDamm.length === 0) {
    return <Spinner />;
  }
  return (
    <div css={styles.container}>
      <Paper css={styles.redemptionContainer}>
        <StakingContainer
          userDamm={userDamm[0]}
          dailyEarningsCents={dailyEarningsCents}
          bdammPrice={bdammPrice}
        />
      </Paper>
    </div>
  );
};


export default Staking;
