export interface IGetCTokenBalanceOfInput {
  tokenContract: $TSFixMe; // @TODO: use contract type once defined (Typechain?)
  account: string;
}

export type GetCTokenBalanceOfOutput = string;

const getCTokenBalanceOf = async ({
  tokenContract,
  account,
}: IGetCTokenBalanceOfInput): Promise<GetCTokenBalanceOfOutput> =>
  tokenContract.methods.balanceOf(account).call();
export default getCTokenBalanceOf;
