import * as React from 'react';
import { SVGProps } from 'react';

const SvgDsteth = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="125" cy="125" r="125" fill="#D9D9D9" />
    <path d="M74.9999 111.044L73.6282 113.148C58.1606 136.876 61.6153 167.95 81.9333 187.859C93.887 199.57 109.553 205.427 125.219 205.429C125.219 205.429 125.219 205.429 74.9999 111.044Z" fill="#00A3FF" />
    <path opacity="0.6" d="M125.22 139.73L75 111.044C125.22 205.429 125.22 205.429 125.22 205.429C125.22 184.873 125.22 161.318 125.22 139.73Z" fill="#00A3FF" />
    <path opacity="0.6" d="M175.489 111.044L176.861 113.148C192.328 136.876 188.874 167.95 168.556 187.859C156.603 199.57 140.936 205.427 125.27 205.429C125.27 205.429 125.27 205.429 175.489 111.044Z" fill="#00A3FF" />
    <path opacity="0.2" d="M125.27 139.73L175.489 111.044C125.27 205.429 125.27 205.429 125.27 205.429C125.27 184.873 125.27 161.318 125.27 139.73Z" fill="#00A3FF" />
    <path opacity="0.2" d="M125.279 75.6543V125.129L168.538 100.408L125.279 75.6543Z" fill="#00A3FF" />
    <path opacity="0.6" d="M125.28 75.6543L81.9902 100.407L125.28 125.129V75.6543Z" fill="#00A3FF" />
    <path d="M125.28 34.0312L81.9902 100.409L125.28 75.5859V34.0312Z" fill="#00A3FF" />
    <path opacity="0.6" d="M125.279 75.5869L168.571 100.411L125.279 34V75.5869Z" fill="#00A3FF" />
    <rect x="25" y="121" width="100" height="100" rx="50" fill={`url(#${id}__a`} />
    <path d="M87.75 204H81.5V197.75H75.25V204H69H62.75H56.5V197.75H50.25V191.5V185.25V179V172.75V166.5V160.25H56.5V154H62.75H69H75.25V160.25H81.5V154V147.75V141.5V135.25H87.75H94V141.5V147.75V154V160.25V166.5V172.75V179V185.25V191.5V197.75V204H87.75ZM69 191.5H75.25V185.25H81.5V179V172.75H75.25V166.5H69H62.75V172.75V179V185.25V191.5H69Z" fill={`url(#${id}__b`} />
    <defs>
      <radialGradient
        id={`${id}__a`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(50.6 149) rotate(71.2791) scale(76.0221)"
      >
        <stop stopColor="#66A9F7" />
        <stop offset={1} stopColor="#0075FF" />
      </radialGradient>
      <radialGradient
        id={`${id}__b`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(80.0548 138.253) rotate(75.4706) scale(85.4812 97.7006)"
      >
        <stop stopColor="#334155" />
        <stop offset={1} stopColor="#050A30" />
      </radialGradient>
    </defs>
  </svg>
);

export default SvgDsteth;
