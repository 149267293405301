
import { Asset } from 'types';
import BigNumber from 'bignumber.js';

/**
 * Takes an asset, a supply balance (in wei of that asset) and a borrow balance (in wei of that asset)
 * and returns the resulting daily earnings (in dollar cents, rounded to the cent)
 * Daily Earnings calculation
 *
 * @param {asset: Asset, supplyBalance: BigNumber, borrowBalance: BigNumber } argument
 * @returns BigNumber of daily earnings (in dollar cents, rounded to the cent)
 */
export const calculateYearlyBdammEarningsCents = ({
  asset,
  yearlyBdammEarningsCents = new BigNumber(0),
}: {
  asset: Asset;
  yearlyBdammEarningsCents?: BigNumber;
}) => {
  const assetBorrowBalanceCents = asset.borrowBalance
    .multipliedBy(asset.tokenPrice)
    .multipliedBy(100);
  const assetSupplyBalanceCents = asset.supplyBalance
    .multipliedBy(asset.tokenPrice)
    .multipliedBy(100);

  const supplyYearlyBdammEarningsCents = assetSupplyBalanceCents.multipliedBy(
    asset.bdammSupplyApy.dividedBy(100),
  );
  // Note that borrowYearlyInterests will always be negative (or 0), since
  // the borrow APY is expressed with a negative percentage)
  const borrowYearlyBdammInterestsCents = assetBorrowBalanceCents.multipliedBy(
    asset.bdammBorrowApy.dividedBy(100),
  );

  const totalYearlyBdammEarningsCents = yearlyBdammEarningsCents.plus(
    supplyYearlyBdammEarningsCents.plus(borrowYearlyBdammInterestsCents),
  );

  return totalYearlyBdammEarningsCents;
};


/**
 * Takes an array of assets, a supply balance (in wei of that asset) and a borrow balance (in wei of that asset)
 * and returns the resulting daily earnings (in dollar cents, rounded to the cent)
 * Daily Earnings calculation
 *
 * @param {assets: Asset[], supplyBalance: BigNumber, borrowBalance: BigNumber } argument
 * @returns BigNumber of daily earnings (in dollar cents, rounded to the cent)
 */
export const calculateYearlyBdammEarningsForAssets = ({
  assets,
}: {
  assets: Asset[];
}) => {
  // We use the yearly earnings to calculate the daily earnings the net APY
  let yearlyBdammEarningsCents: BigNumber | undefined;
  assets.forEach(asset => {
    if (!yearlyBdammEarningsCents) {
      yearlyBdammEarningsCents = new BigNumber(0);
    }
    const assetYearlyBdammEarningsCents = calculateYearlyBdammEarningsCents({
      asset,
      yearlyBdammEarningsCents,
    });
    yearlyBdammEarningsCents = assetYearlyBdammEarningsCents;
  });
  return yearlyBdammEarningsCents;
};
