import * as React from 'react';
import { SVGProps } from 'react';

const SvgDlink = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="125" cy="125" r="125" fill="#2A5ADA" />
    <path d="M125.5 24L106.981 34.6481L56.5185 63.8148L38 74.463V175.389L56.5185 186.037L107.444 215.204L125.963 225.852L144.481 215.204L194.481 186.037L213 175.389V74.463L194.481 63.8148L144.019 34.6481L125.5 24ZM75.037 154.093V95.7593L125.5 66.5926L175.963 95.7593V154.093L125.5 183.259L75.037 154.093Z" fill="white" />
    <rect x="25" y="119" width="100" height="100" rx="50" fill={`url(#${id}__a`} />
    <path d="M87.75 202H81.5V195.75H75.25V202H69H62.75H56.5V195.75H50.25V189.5V183.25V177V170.75V164.5V158.25H56.5V152H62.75H69H75.25V158.25H81.5V152V145.75V139.5V133.25H87.75H94V139.5V145.75V152V158.25V164.5V170.75V177V183.25V189.5V195.75V202H87.75ZM69 189.5H75.25V183.25H81.5V177V170.75H75.25V164.5H69H62.75V170.75V177V183.25V189.5H69Z" fill={`url(#${id}__b`} />
    <defs>
        <radialGradient id={`${id}__a`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(50.6 147) rotate(71.2791) scale(76.0221)">
            <stop stopColor="#66A9F7" />
            <stop offset="1" stopColor="#0075FF" />
        </radialGradient>
        <radialGradient id={`${id}__b`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(80.0548 136.987) rotate(76.1548) scale(89.6165 97.9959)">
            <stop stopColor="#334155" />
            <stop offset="1" stopColor="#050A30" />
        </radialGradient>
    </defs>
  </svg>
);

export default SvgDlink;
