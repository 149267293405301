/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';
import {
  useUserMarketInfo,
} from 'clients/api';
import { Token, Table, TableProps } from 'components';
import { AuthContext } from 'context/AuthContext';
import { useTranslation } from 'translation';
import { Asset, TokenId } from 'types';
import { formatToReadablePercentage, formatCoinsToReadableValue } from 'utilities/common';
import { useStyles } from '../styles';

type TableAsset = Pick<Asset, 'id' | 'symbol'> & {
  bdammPerDay: Asset['bdammPerDay'] | undefined;
  bdammSupplyApy: Asset['bdammSupplyApy'] | undefined;
  bdammBorrowApy: Asset['bdammBorrowApy'] | undefined;
  assetType: Asset['assetType'];
};

interface IDammTableProps {
  assets: TableAsset[];
}

const DammTableUi: React.FC<IDammTableProps> = ({ assets }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const styles = useStyles();

  const columns = useMemo(
    () => [
      { key: 'asset', label: t('damm.columns.asset'), orderable: false },
      { key: 'bdammPerDay', label: t('damm.columns.bdammPerDay'), orderable: true },
      { key: 'supplyBdammApy', label: t('damm.columns.supplyBdammApy'), orderable: true },
    ],
    [],
  );

  const { baseMarkets } =
  useMemo(() => {
    const sortedAssets = assets.reduce(
      (acc, curr) => {
        if (curr.assetType === 'base') {
          acc.baseMarkets.push(curr);
        }
        return acc;
      },
      {
        baseMarkets: [] as TableAsset[],
      },
    );
    return sortedAssets;
  }, [JSON.stringify(assets)]);

  const rowOnClick = (e: React.MouseEvent<HTMLElement>, row: TableProps['data'][number]) => {
    history.push(`/market/${row[0].value}`);
  };
  // Format assets to rows
  const rows: TableProps['data'] = baseMarkets.map(asset => [
    {
      key: 'asset',
      render: () => <Token symbol={asset.symbol as TokenId} />,
      value: asset.id,
    },
    {
      key: 'bdammPerDay',
      render: () => (
        <Typography variant="small1" css={[styles.whiteText, styles.fontWeight400]}>
          {formatCoinsToReadableValue({
            value: asset.bdammPerDay,
            tokenId: 'bdamm',
            shorthand: true,
          })}
        </Typography>
      ),
      value: asset.bdammPerDay?.toFixed() || 0,
    },
    {
      key: 'supplyBdammApy',
      render: () => (
        <Typography variant="small1" css={[styles.whiteText, styles.fontWeight400]}>
          {formatToReadablePercentage(asset.bdammSupplyApy)}
        </Typography>
      ),
      value: asset.bdammSupplyApy?.toFixed() || 0,
    },
  ]);

  return (
    <Table
      title="Base Markets"
      columns={columns}
      data={rows}
      initialOrder={{
        orderBy: 'asset',
        orderDirection: 'desc',
      }}
      rowKeyIndex={0}
      rowOnClick={rowOnClick}
      tableCss={styles.table}
      cardsCss={styles.cards}
      css={styles.cardContentGrid}
    />
  );
};

const DammTable: React.FC = () => {
  const { account } = useContext(AuthContext);
  const { assets } = useUserMarketInfo({ accountAddress: account?.address });

  return <DammTableUi assets={assets} />;
};

export default DammTable;
