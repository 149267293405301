/** @jsxImportSource @emotion/react */
import React from 'react';
import BigNumber from 'bignumber.js';
import {
  toast,
  FormikSubmitButton,
  // ValueUpdate,
} from 'components';
import { AmountAndFeeForm, IAmountAndFeeFormProps,
  // ErrorCode
} from 'containers/AmountAndFeeForm';
import { RedemptionTextFields } from '../../../../components/RedemptionTextFields';

interface IRedemptionFormUiProps {
  maxInput: BigNumber;
  enabledButtonKey: string;
  disabledButtonKey: string;
  isTransactionLoading: boolean;
  redeemAmountValue: string;
  feeAmountValue: string;
}

type OnSubmitProps = {
  redeemAmount: string;
  feeAmount: string;
};

export const RedemptionContent: React.FC<IRedemptionFormUiProps> = ({
  enabledButtonKey,
  disabledButtonKey,
  isTransactionLoading,
  redeemAmountValue,
  feeAmountValue,
}) => {
  const redeemAmount = new BigNumber(redeemAmountValue || 0);
  const validRedeemAmount = redeemAmount && !redeemAmount.isZero() && !redeemAmount.isNaN();
  const feeAmount = new BigNumber(feeAmountValue || 0);
  const validFeeAmount = feeAmount && !feeAmount.isZero() && !feeAmount.isNaN();
  // const [feeAmount, setFeeAmount] = useState<BigNumber>(new BigNumber(0));
  // useEffect(() => {
  //   console.log('amount changed', redeemAmountValue);
  //   setFeeAmount(redeemAmount);
  // }, [redeemAmountValue]);

  return (
    <>
      <RedemptionTextFields />

      {/* change to damm Redeemable balance/reflect bdamm input balance */}

      <FormikSubmitButton
        fullWidth
        disabled={!validRedeemAmount || !validFeeAmount}
        loading={isTransactionLoading}
        enabledLabel={enabledButtonKey}
        disabledLabel={disabledButtonKey}
      />
    </>
  );
};

interface IRedemptionFormProps extends Omit<IRedemptionFormUiProps, 'redeemAmountValue' | 'feeAmountValue'> {
  onSubmit: IAmountAndFeeFormProps['onSubmit'];
}

const RedemptionForm: React.FC<IRedemptionFormProps> = ({
  onSubmit,
  maxInput,
  ...props
}) => {
  const onSubmitHandleError: IAmountAndFeeFormProps['onSubmit'] = async ({ redeemAmount, feeAmount: feeAmountForm }: OnSubmitProps) => {
    try {
      console.log('passed to onsubmit', redeemAmount, feeAmountForm);
      await onSubmit({ redeemAmount, feeAmount: feeAmountForm });
    } catch (err) {
      toast.error({ message: (err as Error).message });
    }
  };
  return (
    <AmountAndFeeForm onSubmit={onSubmitHandleError} maxRedeemAmount={maxInput.toFixed()} maxFeeAmount={maxInput.toFixed()}>
      {({ values }) => (
        <RedemptionContent maxInput={maxInput} redeemAmountValue={values.redeemAmount} feeAmountValue={values.redeemAmount} {...props} />
      )}
    </AmountAndFeeForm>
  );
};

export default RedemptionForm;
