export interface IGetCTokenBalancesAllInput {
  venusLensContract: $TSFixMe; // @TODO: use contract type (through Typechain?)
  account: string;
  vtAddresses: string[];
}

interface IGetCTokenBalancesAllResponse extends Array<string> {
  balanceOf: string;
  balanceOfUnderlying: string;
  borrowBalanceCurrent: string;
  tokenAllowance: string;
  tokenBalance: string;
  cToken: string;
}

interface IGetCTokenBalanceOutput {
  balanceOf: string;
  balanceOfUnderlying: string;
  borrowBalanceCurrent: string;
  tokenAllowance: string;
  tokenBalance: string;
  cToken: string;
}

export type IGetCTokenBalancesAllOutput = IGetCTokenBalanceOutput[];

const getCTokenBalancesAll = async ({
  venusLensContract,
  vtAddresses,
  account,
}: IGetCTokenBalancesAllInput): Promise<IGetCTokenBalancesAllOutput> => {
  let response = await venusLensContract.methods
    .cTokenBalancesAll(vtAddresses, account?.toLowerCase())
    .call();

  // This is original returned as an array with these properties
  // but at some point the properties are getting lost
  response = response.map((item: IGetCTokenBalancesAllResponse) => ({
    balanceOf: item.balanceOf,
    balanceOfUnderlying: item.balanceOfUnderlying,
    borrowBalanceCurrent: item.borrowBalanceCurrent,
    tokenAllowance: item.tokenAllowance,
    tokenBalance: item.tokenBalance,
    cToken: item.cToken,
  }));

  return response;
};

export default getCTokenBalancesAll;
