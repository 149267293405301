import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    box: css`
      max-height: calc(90% - ${theme.spacing(8)});
    `,
    button: css`
      margin-top: ${theme.spacing(4)};
    `,
  };
};
