import * as React from 'react';
import { SVGProps } from 'react';

const SvgCnv = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M125 250C194.036 250 250 194.036 250 125C250 55.9644 194.036 0 125 0C55.9644 0 0 55.9644 0 125C0 194.036 55.9644 250 125 250Z" fill="#000818" />
    <g opacity="0.15">
      <path d="M125 250C194.036 250 250 194.036 250 125C250 55.9644 194.036 0 125 0C55.9644 0 0 55.9644 0 125C0 194.036 55.9644 250 125 250Z" fill="#0012FF" />
    </g>
    <path d="M90.0425 72.5L72 85H57.4025C52.54 97.4225 50 111.102 50 125C50 138.898 52.595 152.72 57.445 165.143L49.4625 177.5H49.2125C41.75 162.052 37.5 144.125 37.5 125C37.5 105.875 41.75 87.9475 49.2075 72.5H90.0425Z" fill={`url(#${id}__a`} />
    <path d="M90.0425 72.5L71.7775 85C63.8509 111.078 63.8509 138.922 71.7775 165H57.39L49.2075 177.5H90.0425C82.595 162.052 78.3325 144.125 78.3325 125C78.3325 105.875 82.595 87.9475 90.0425 72.5Z" fill="#7418FF" />
    <g opacity="0.65">
      <path d="M90.0425 72.5L72 85H57.4025C52.54 97.4225 50 111.102 50 125C50 138.898 52.595 152.72 57.445 165.143L49.4625 177.5H49.2125C41.75 162.052 37.5 144.125 37.5 125C37.5 105.875 41.75 87.9475 49.2075 72.5H90.0425Z" fill="#00F0FF" />
      <path d="M90.0425 72.5L71.7775 85C63.8509 111.078 63.8509 138.922 71.7775 165H57.39L49.2075 177.5H90.0425C82.595 162.052 78.3325 144.125 78.3325 125C78.3325 105.875 82.595 87.9475 90.0425 72.5Z" fill="#00F0FF" />
    </g>
    <path d="M212.5 125C212.5 144.125 208.25 162.052 200.793 177.5H159.958L160.003 177.405L178.16 164.982L178.223 164.997H192.61C197.46 152.577 200 138.898 200 125C200 111.102 197.46 97.4225 192.61 85H192.57L200.793 72.5C208.25 87.9475 212.5 105.875 212.5 125Z" fill="#7418FF" />
    <path d="M184.168 125C184.18 138.554 182.176 152.035 178.223 165L159.958 177.5C167.405 162.052 171.668 144.125 171.668 125C171.668 105.875 167.418 87.9475 159.958 72.5L178.225 85C182.177 97.9652 184.18 111.446 184.168 125V125Z" fill={`url(#${id}__b`} />
    <path d="M192.61 85H178.223L159.955 72.5H200.793L192.61 85Z" fill="#00D2FF" />
    <g opacity="0.65">
      <path d="M212.5 125C212.5 144.125 208.25 162.052 200.793 177.5H159.958L160.003 177.405L178.16 164.982L178.223 164.997H192.61C197.46 152.577 200 138.898 200 125C200 111.102 197.46 97.4225 192.61 85H192.57L200.793 72.5C208.25 87.9475 212.5 105.875 212.5 125Z" fill="#00F0FF" />
      <path d="M184.168 125C184.18 138.554 182.176 152.035 178.223 165L159.958 177.5C167.405 162.052 171.668 144.125 171.668 125C171.668 105.875 167.418 87.9475 159.958 72.5L178.225 85C182.177 97.9652 184.18 111.446 184.168 125V125Z" fill="#00F0FF" />
      <path d="M192.61 85H178.223L159.955 72.5H200.793L192.61 85Z" fill="#00F0FF" />
    </g>
    <path d="M142.5 125C142.5 144.125 146.75 162.052 154.207 177.5H95.7925L114.057 165H135.942C128.023 138.927 128.023 111.088 135.942 85.015L154.207 72.5C146.75 87.9475 142.5 105.875 142.5 125Z" fill="#7418FF" />
    <path d="M135.937 85.015C135.937 85.015 135.937 85.015 135.937 85H114.057C114.057 85.0125 114.057 85.025 114.057 85.0375L95.7925 72.5H154.207L135.937 85.015Z" fill="#00D2FF" />
    <path d="M120 125C120.012 138.554 118.009 152.035 114.057 165L95.7925 177.5C103.25 162.052 107.5 144.125 107.5 125C107.5 105.875 103.25 87.9475 95.7925 72.5L114.07 85.0375C118.014 97.9914 120.012 111.459 120 125Z" fill={`url(#${id}__c`} />
    <g opacity="0.65">
      <path d="M142.5 125C142.5 144.125 146.75 162.052 154.207 177.5H95.7925L114.057 165H135.942C128.023 138.927 128.023 111.088 135.942 85.015L154.207 72.5C146.75 87.9475 142.5 105.875 142.5 125Z" fill="#00F0FF" />
      <path d="M135.937 85.015C135.937 85.015 135.937 85.015 135.937 85H114.057C114.057 85.0125 114.057 85.025 114.057 85.0375L95.7925 72.5H154.207L135.937 85.015Z" fill="#00F0FF" />
      <path d="M120 125C120.012 138.554 118.009 152.035 114.057 165L95.7925 177.5C103.25 162.052 107.5 144.125 107.5 125C107.5 105.875 103.25 87.9475 95.7925 72.5L114.07 85.0375C118.014 97.9914 120.012 111.459 120 125Z" fill="#00F0FF" />
    </g>
    <defs>
      <linearGradient id={`${id}__a`} x1="63.77" y1="75.7375" x2="63.77" y2="173.923" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00D2FF" />
        <stop offset="1" stopColor="#0012FF" />
      </linearGradient>
      <linearGradient id={`${id}__b`} x1="172.063" y1="177.5" x2="172.063" y2="72.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00D2FF" />
        <stop offset="1" stopColor="#0012FF" />
      </linearGradient>
      <linearGradient id={`${id}__c`} x1="107.895" y1="177.5" x2="107.895" y2="72.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00D2FF" />
        <stop offset="1" stopColor="#0012FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgCnv;
