import * as React from 'react';
import { SVGProps } from 'react';

const SvgUsdt = (props: SVGProps<SVGSVGElement>) => (
  <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M246.232 155.238C229.537 222.203 161.713 262.957 94.7399 246.258C27.7951 229.563 -12.9584 161.734 3.74357 94.7747C20.4309 27.8024 88.2555 -12.9542 155.208 3.74043C222.176 20.4351 262.927 88.2713 246.23 155.239L246.232 155.238H246.232Z" fill="#50AF95" />
    <path fillRule="evenodd" clipRule="evenodd" d="M140.817 139.172V139.172C139.933 139.238 135.366 139.511 125.18 139.511C117.078 139.511 111.325 139.268 109.307 139.172V139.172C77.9964 137.794 54.6256 132.344 54.6256 125.818C54.6256 119.292 77.9964 113.849 109.307 112.45V133.743C111.355 133.891 117.218 134.237 125.32 134.237C135.042 134.237 139.911 133.832 140.788 133.751V112.464C172.032 113.856 195.351 119.307 195.351 125.818C195.351 132.329 172.039 137.78 140.788 139.164V139.164L140.817 139.172ZM140.817 110.262V91.2074H184.421V62.1504H65.7033V91.2074H109.3V110.255C73.8643 111.882 47.2158 118.902 47.2158 127.313C47.2158 135.725 73.8643 142.737 109.3 144.372V205.432H140.81V144.35C176.164 142.722 202.768 135.71 202.768 127.306C202.768 118.902 176.186 111.89 140.81 110.255V110.255L140.817 110.262Z" fill="white" />
  </svg>
);

export default SvgUsdt;
