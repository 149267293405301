/** @jsxImportSource @emotion/react */
import React from 'react';
import { Paper } from '@mui/material';
import Header from './Header';
import MarketTable from './MarketTable';
import VampMarketTable from './VampMarketTable';
import { useStyles } from './styles';

const MarketUi: React.FC = () => {
  const styles = useStyles();

  return (
    <div css={styles.container}>
      <Header />
      <Paper css={styles.tableContainer}>
        <MarketTable />
      </Paper>
      <Paper css={styles.tableContainer}>
        <VampMarketTable />
      </Paper>
    </div>
  );
};

const Market: React.FC = () => <MarketUi />;

export default Market;
