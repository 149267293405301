import * as React from 'react';
import { SVGProps } from 'react';

const SvgCvx = (props: SVGProps<SVGSVGElement>) => (
  <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M125 250C194.036 250 250 194.036 250 125C250 55.9644 194.036 0 125 0C55.9644 0 0 55.9644 0 125C0 194.036 55.9644 250 125 250Z" fill="white" />
    <path d="M198.018 108.157V74.4952H181.162V57.6012H147.462V40.7578H113.763V57.6012H80.0758V74.4952H63.2197V175.505H80.0632V192.361H113.763V209.205H147.45V192.399H181.162V175.505H198.018V141.844H164.318V158.7H147.462V175.505H113.763V158.7H96.9192V91.3007H113.763V74.4952H147.462V91.3007H164.318V108.157H198.018Z" fill="#3A3A3A" />
    <path d="M164.318 91.3135H153.081V108.157H164.318V91.3135Z" fill="#1682FE" />
    <path d="M147.462 74.457H136.225V91.3005H147.462V74.457Z" fill="#1682FE" />
    <path d="M113.775 40.7578H102.538V57.6012H113.775V40.7578Z" fill="#1682FE" />
    <path d="M113.763 192.399H102.525V209.243H113.763V192.399Z" fill="#FF5A5A" />
    <path d="M80.0635 175.543H68.8262V192.386H80.0635V175.543Z" fill="#F4BB3B" />
    <path d="M63.2198 125H51.9824V175.543H63.2198V125Z" fill="#F4BB3B" />
    <path d="M63.2198 74.457H51.9824V125H63.2198V74.457Z" fill="#60D8A4" />
    <path d="M80.0762 57.6133H68.8389V74.4567H80.0762V57.6133Z" fill="#1682FE" />
  </svg>

);

export default SvgCvx;
