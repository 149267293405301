import * as React from 'react';
import { SVGProps } from 'react';

const SvgDwoo = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse cx="125.135" cy="125.175" rx="100.108" ry="100.14" fill="#D9D9D9" />
    <path d="M106.516 1.34049C86.8946 4.34468 65.3713 13.3573 50.255 24.7732C13.6154 52.4117 -5.50522 98.5761 1.40224 143.038C3.60462 157.458 6.40765 166.371 12.9147 179.689C19.9222 194.109 27.8308 205.025 39.2431 215.74C71.578 246.382 116.326 257.298 160.074 245.181C170.685 242.277 190.406 232.363 199.817 225.253C204.221 221.948 211.429 215.439 215.734 210.833C246.367 178.488 257.279 133.725 245.166 89.9641C242.262 79.3493 232.352 59.6218 225.244 50.2086C217.836 40.2948 204.922 27.9776 195.312 21.5687C185.902 15.2599 169.484 7.54915 160.074 4.84538C142.755 0.0386739 122.934 -1.26314 106.516 1.34049ZM83.7912 92.1672C84.8924 95.6721 88.5964 108.089 92.0001 119.706C95.5039 131.422 98.6072 140.735 99.0077 140.535C99.4081 140.234 102.111 134.526 105.014 127.817C107.917 121.007 111.121 114.298 112.122 112.896C116.126 107.188 125.837 105.285 132.844 108.89C137.149 111.094 138.951 114.098 145.558 129.82C148.161 136.128 150.663 140.835 151.064 140.334C151.464 139.934 152.365 137.43 153.066 134.727L154.367 130.02H164.779H175.29L174.589 132.824C174.289 134.426 171.586 143.539 168.583 153.152C164.779 165.469 162.576 171.077 161.075 172.279C159.373 173.681 156.97 174.081 150.663 174.081C143.356 174.081 142.154 173.781 140.252 171.778C139.051 170.577 135.347 162.766 131.943 154.554C128.64 146.243 125.536 139.233 125.136 138.832C124.735 138.432 121.632 145.141 118.128 153.853C110.32 173.38 109.619 174.081 99.0077 174.081C92.2003 174.081 90.6987 173.781 88.5964 171.778C86.4942 169.976 83.7912 162.365 73.4801 128.217C66.5726 105.486 60.9666 86.6595 60.9666 86.4592C60.9666 86.1588 65.6717 85.9585 71.4779 85.9585H81.8892L83.7912 92.1672ZM189.105 86.5593C189.105 86.9599 186.802 94.3702 184.1 103.082L178.994 119.005H168.583C162.777 119.005 158.071 118.804 158.071 118.504C158.071 117.703 167.281 87.5607 167.782 86.6595C168.282 85.7582 189.105 85.6581 189.105 86.5593Z" fill="#101825" />
    <rect x="25" y="121" width="100" height="100" rx="50" fill={`url(#${id}__a`} />
    <path d="M87.75 204H81.5V197.75H75.25V204H69H62.75H56.5V197.75H50.25V191.5V185.25V179V172.75V166.5V160.25H56.5V154H62.75H69H75.25V160.25H81.5V154V147.75V141.5V135.25H87.75H94V141.5V147.75V154V160.25V166.5V172.75V179V185.25V191.5V197.75V204H87.75ZM69 191.5H75.25V185.25H81.5V179V172.75H75.25V166.5H69H62.75V172.75V179V185.25V191.5H69Z" fill={`url(#${id}__b`} />
    <defs>
      <radialGradient
        id={`${id}__a`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(50.6 149) rotate(71.2791) scale(76.0221)"
      >
        <stop stopColor="#66A9F7" />
        <stop offset={1} stopColor="#0075FF" />
      </radialGradient>
      <radialGradient
        id={`${id}__b`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(80.0548 138.253) rotate(75.4706) scale(85.4812 97.7006)"
      >
        <stop stopColor="#334155" />
        <stop offset={1} stopColor="#050A30" />
      </radialGradient>
    </defs>
  </svg>
);

export default SvgDwoo;
