import * as React from 'react';
import { SVGProps } from 'react';

const SvgDeth = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="125" cy="125" r="125" fill="#D9D9D9" />
    <path d="M125.239 45.8673V45.9461C125.318 46.3402 125.318 46.8131 125.318 47.286V104.114C125.239 104.429 125.002 104.508 124.766 104.665C123.111 105.454 121.534 106.163 119.879 106.872C117.594 107.897 115.229 109 112.943 110.025L104.668 113.808C102.382 114.833 100.096 115.857 97.8892 116.882C95.2094 118.143 92.4508 119.325 89.771 120.587C87.4852 121.611 85.1995 122.715 82.835 123.739C80.9434 124.606 79.0517 125.394 77.2389 126.261C77.0813 126.34 76.9237 126.419 76.766 126.419C76.6872 126.419 76.6872 126.419 76.6084 126.34L78.7365 122.793C82.835 116.015 86.8547 109.316 90.9532 102.537C95.2882 95.286 99.702 88.0348 104.037 80.7836C108.057 74.0841 112.155 67.3846 116.175 60.685C119.091 55.7983 122.086 50.9116 125.002 46.0249C125.081 45.8673 125.16 45.7885 125.16 45.6309H125.239C125.16 45.7097 125.239 45.7885 125.239 45.8673Z" fill="#8A92B2" />
    <path d="M173.63 126.34L173.709 126.419L162.122 133.276L125.709 154.793C125.551 154.872 125.393 154.951 125.315 155.03C125.078 155.03 125.078 154.793 125.078 154.714V154.005V105.296C125.078 105.059 125.078 104.744 125.157 104.507C125.236 104.192 125.472 104.271 125.709 104.35C126.733 104.823 127.837 105.296 128.861 105.769C131.935 107.187 135.009 108.606 138.083 109.946C140.763 111.128 143.364 112.389 146.044 113.571C148.723 114.754 151.403 116.015 154.083 117.197C156.369 118.222 158.733 119.325 161.019 120.35C163.305 121.374 165.669 122.478 167.955 123.503C169.768 124.291 171.581 125.158 173.393 125.946C173.393 126.182 173.472 126.261 173.63 126.34Z" fill="#454A75" />
    <path d="M125.236 203.976C125.236 204.055 125.157 204.134 125.157 204.213H125.078C125.078 204.055 124.921 203.976 124.842 203.819C119.955 196.961 115.069 190.025 110.182 183.168C105.216 176.154 100.172 169.06 95.2065 162.045C90.3986 155.267 85.5119 148.41 80.704 141.631C79.4429 139.819 78.1818 138.085 76.9207 136.272C76.8419 136.114 76.7631 136.035 76.6055 135.799C76.8419 135.799 76.9996 135.956 77.0784 136.035C83.9355 140.055 90.7138 144.075 97.571 148.094C105.453 152.745 113.256 157.395 121.137 162.045L125.157 164.41C125.315 164.567 125.315 164.725 125.315 164.883V202.873C125.315 203.267 125.315 203.661 125.236 203.976Z" fill="#8A92B2" />
    <path d="M76.5269 126.498V126.419C79.049 125.316 81.4924 124.134 84.0145 123.03C87.2461 121.533 90.4776 120.114 93.7091 118.616C96.1525 117.513 98.6746 116.331 101.118 115.227C104.744 113.572 108.29 111.996 111.916 110.34C114.359 109.237 116.803 108.134 119.325 106.951C121.059 106.163 122.872 105.375 124.606 104.587C124.763 104.508 125 104.429 125.079 104.271C125.157 104.271 125.157 104.35 125.079 104.429V154.478C125.079 154.715 125 154.951 125.157 155.109C125 155.345 124.842 155.109 124.763 155.03C124.054 154.636 123.345 154.242 122.635 153.769C107.423 144.784 92.1328 135.72 76.9209 126.735C76.8421 126.656 76.6845 126.577 76.5269 126.498Z" fill="#62688F" />
    <path d="M173.473 135.799H173.551C173.551 135.956 173.394 136.114 173.315 136.272C158.812 156.686 144.31 177.178 129.807 197.592C128.31 199.72 126.734 201.848 125.236 203.976C125.157 203.897 125.157 203.819 125.157 203.74V203.267V165.04V164.331C128.468 162.36 131.699 160.469 135.009 158.498C147.778 150.932 160.546 143.444 173.236 135.878C173.315 135.956 173.394 135.878 173.473 135.799Z" fill="#62688F" />
    <path d="M125.158 104.43V104.272V103.799V46.5778C125.158 46.3414 125.079 46.1837 125.236 45.9473C141.236 72.5088 157.236 98.9916 173.158 125.553C173.315 125.79 173.552 126.105 173.631 126.42C172.527 126.026 171.502 125.474 170.478 125.001C169.217 124.45 167.877 123.819 166.616 123.267C165.828 122.873 164.961 122.558 164.172 122.164C162.833 121.533 161.493 120.982 160.153 120.351C159.365 120.036 158.576 119.642 157.788 119.248L152.586 116.883C151.719 116.489 150.852 116.095 149.906 115.701L146.123 114.046C145.335 113.731 144.547 113.336 143.759 112.942L138.557 110.578C137.69 110.184 136.823 109.79 135.877 109.396L132.094 107.74C131.227 107.346 130.438 106.952 129.571 106.558C128.074 105.849 126.576 105.139 125 104.509C125.236 104.43 125.158 104.43 125.158 104.43Z" fill="#62688F" />
    <rect x="25" y="121" width="100" height="100" rx="50" fill={`url(#${id}__a`} />
    <path d="M87.75 204H81.5V197.75H75.25V204H69H62.75H56.5V197.75H50.25V191.5V185.25V179V172.75V166.5V160.25H56.5V154H62.75H69H75.25V160.25H81.5V154V147.75V141.5V135.25H87.75H94V141.5V147.75V154V160.25V166.5V172.75V179V185.25V191.5V197.75V204H87.75ZM69 191.5H75.25V185.25H81.5V179V172.75H75.25V166.5H69H62.75V172.75V179V185.25V191.5H69Z" fill={`url(#${id}__b`} />
    <defs>
      <radialGradient
        id={`${id}__a`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(50.6 149) rotate(71.2791) scale(76.0221)"
      >
        <stop stopColor="#66A9F7" />
        <stop offset={1} stopColor="#0075FF" />
      </radialGradient>
      <radialGradient
        id={`${id}__b`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(80.0548 138.253) rotate(75.4706) scale(85.4812 97.7006)"
      >
        <stop stopColor="#334155" />
        <stop offset={1} stopColor="#050A30" />
      </radialGradient>
    </defs>
  </svg>
);

export default SvgDeth;
