/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import BigNumber from 'bignumber.js';
import Typography from '@mui/material/Typography';
import { MarketContext } from 'context/MarketContext';
import { convertWeiToCoins, formatCoinsToReadableValue, formatToReadablePercentage, isWhitelisted } from 'utilities/common';
import { formatCentsToReadableValue, generateEtherScanUrl, getToken } from 'utilities';
import { useTranslation } from 'translation';
import { EllipseText, Icon, Tooltip } from 'components';
import Paper from '@mui/material/Paper';
import ClaimBorrowerDammRewardButton from 'components/Layout/ClaimBorrowerDammRewardButton';
import ClaimDammRewardButton from 'components/Layout/ClaimDammRewardButton';
import copy from 'copy-to-clipboard';
import { useGetBalanceOf, useGetRedemptionUsdcPrice } from 'clients/api';
import { AuthContext } from 'context/AuthContext';
import { useStyles } from './styles';

export interface IDistributionUiProps {
  netBdammApyPercentage: number | undefined;
  dailyBdammEarningsCents: number | undefined;
  className?: string;
}

export const DistributionUi = ({
  netBdammApyPercentage,
  dailyBdammEarningsCents,
  className,
}: IDistributionUiProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const dammAddress = getToken('bdamm').address;
  const copyAddress = () => copy(dammAddress);


  const readableNetApyPercentage = formatToReadablePercentage(netBdammApyPercentage);

  const readableDailyBdammEarnings = formatCentsToReadableValue({
    value: dailyBdammEarningsCents || 0,
  });
  const { data: redemptionUSDCPrice = new BigNumber(1) } = useGetRedemptionUsdcPrice();
  const redemptionUSDCPriceFormatted = redemptionUSDCPrice.shiftedBy(-6).toNumber();
  const { dammPrice } = useContext(MarketContext);
  const bdammPrice = ((dammPrice - redemptionUSDCPriceFormatted) > 0)
  ? (dammPrice - redemptionUSDCPriceFormatted).toFixed(8) : 0;

  const { userTotalBorrowLimit } = useContext(MarketContext);

  const { account } = useContext(AuthContext);
  const { data: userBdammBalance } = useGetBalanceOf({
        tokenId: 'bdamm',
        accountAddress: account?.address || '',
      });
  const bdammBalance = (new BigNumber(userBdammBalance || 0));
  const balance = convertWeiToCoins({
    valueWei: bdammBalance,
    tokenId: 'bdamm',
    shorthand: true,
  });
  const readableBalance = formatCoinsToReadableValue({
    value: balance,
    tokenId: 'bdamm',
    shorthand: true,
  });
  // const { data: bdammRemainingDistribution } = useGetBalanceOf({
  //   tokenId: 'bdamm',
  //   accountAddress: getContractAddress('comptroller'),
  // });

  //   const readableRemainingDistribution = useMemo(
  //   () =>
  //     convertWeiToCoins({
  //       valueWei: bdammRemainingDistribution,
  //       tokenId: 'bdamm',
  //       returnInReadableFormat: true,
  //       shorthand: true,
  //     }),
  //   [bdammRemainingDistribution.toFixed()],
  // );

  // const percentOfDammDistributed = useMemo(
  //   () => totalDammDistributedWei.dividedBy(MINTED_DAMM_WEI).multipliedBy(100).toNumber(),
  //   [],
  // );

  return (
    <Paper css={styles.container} className={className}>
      <div css={styles.headerRoot}>
          <div>
            <h3 css={styles.whiteText}>
              Distribution:
            </h3>
            <p>
              Earn bonded dAMM (bdAMM) tokens over time for depositing into dAMM Pools.
            </p>
          </div>
          {isWhitelisted(userTotalBorrowLimit) ?
          <ClaimBorrowerDammRewardButton css={styles.button} /> :
          <ClaimDammRewardButton css={styles.button} />
          }
      </div>
      <div css={styles.headerRoot}>
          <EllipseText css={styles.address} text={dammAddress}>
            <div css={styles.dammIconContainer}>
              <Icon name="bdamm" size={styles.iconSizeXl} />
            </div>
            <div>
              <Icon name="bdammtype" css={styles.dammTypeContainer} />
            </div>
            <Typography
              className="ellipse-text"
              href={generateEtherScanUrl('bdamm', 'token')}
              target="_blank"
              rel="noreferrer"
              variant="small2"
              component="a"
              css={[styles.whiteText, styles.addressText]}
            />
            <div css={styles.copyIconContainer}>
              <Tooltip title="Copy Address">
                <Icon name="copy" onClick={copyAddress} css={styles.copyIcon} size={styles.iconSizeXl} />
              </Tooltip>
            </div>
          </EllipseText>
      </div>
      <div css={styles.headerRoot}>
        <div css={styles.netApyContainer}>
          <div css={styles.netApy}>
            <Typography component="span" variant="small2" css={styles.netApyLabel}>
              {t('Net Distribution APY')}
            </Typography>

            <Tooltip css={styles.tooltip} title={t('Percentage of your total supply balance received as bdAMM distributions. (Calculated using bdAMM fair market price)')}>
              <Icon css={styles.infoIcon} name="info" />
            </Tooltip>
          </div>

          <Typography
            variant="h1"
            component="span"
          >
            {readableNetApyPercentage}
          </Typography>
        </div>
        <div css={styles.netApyContainer}>
          <div css={styles.netApy}>
            <Typography component="span" variant="small2" css={styles.netApyLabel}>
              {t('bdAMM Daily Earnings')}
            </Typography>

            <Tooltip css={styles.tooltip} title={t('USD Value of bdAMM distributed daily. (Calculated using bdAMM fair market price)')}>
              <Icon css={styles.infoIcon} name="info" />
            </Tooltip>
          </div>

          <Typography
            variant="h3"
            component="span"
          >
            {readableDailyBdammEarnings}
          </Typography>
        </div>
        <div css={styles.netApyContainer}>
          <div css={styles.netApy}>
            <Typography component="span" variant="small2" css={styles.netApyLabel}>
              {t('bdAMM Wallet Balance')}
            </Typography>

            <Tooltip css={styles.tooltip} title={t('Balance of bdAMM tokens currently held in wallet.')}>
              <Icon css={styles.infoIcon} name="info" />
            </Tooltip>
          </div>

          <Typography
            variant="h3"
            component="span"
          >
            {readableBalance.toString()}
          </Typography>
        </div>
        <div css={styles.netApyContainer}>
          <div css={styles.netApy}>
            <Typography component="span" variant="small2" css={styles.netApyLabel}>
              {t('bdAMM Price')}
            </Typography>

            <Tooltip css={styles.tooltip} title={t('The fair market price of bdAMM is calculated by multiplying the price of dAMM Tokens by the current redemption discount rate, and may differ from prices on secondary markets.')}>
              <Icon css={styles.infoIcon} name="info" />
            </Tooltip>
          </div>

          <Typography
            variant="h3"
            component="span"
          >
            ${bdammPrice}
          </Typography>
        </div>

        {/* <div css={styles.headerRoot}>
          <div css={styles.slider}>
            <LabeledProgressBar
              css={styles.progressBar}
              min={1}
              max={100}
              step={1}
              value={percentOfDammDistributed}
              ariaLabel={t('damm.progressBar')}
              // greyLeftText={t('damm.dailyDistribution')}
              // whiteLeftText={readableDailyDistribution}
              greyRightText={t('damm.remaining')}
              whiteRightText={readableRemainingDistribution}
            />
          </div>
        </div> */}
      </div>
    </Paper>
  );
};

export default DistributionUi;
