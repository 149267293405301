import * as React from 'react';
import { SVGProps } from 'react';

const SvgDfrax = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse cx="125.879" cy="125.77" rx="112.816" ry="112.738" fill="#D9D9D9" />
    <path d="M106.298 1.01172C64.5808 8.02653 31.087 32.4782 12.6354 69.356C3.61011 87.3941 0 103.228 0 124.873C0 151.73 6.31769 172.774 21.2595 195.422C27.2764 204.541 45.0261 222.279 54.1516 228.292C97.9743 257.152 151.925 257.253 195.146 228.492C204.573 222.279 217.81 209.552 225.231 199.731C232.351 190.311 242.278 170.57 245.187 159.947C251.604 136.598 251.604 113.148 245.187 89.7992C242.278 79.1767 232.351 59.4351 225.231 50.0152C206.679 25.3631 179.904 8.52759 149.418 2.11405C138.688 -0.190816 116.627 -0.691874 106.298 1.01172ZM71.7008 51.3179C74.7092 54.3243 79.8235 59.1344 83.0325 61.9403L88.8488 67.0511L96.6707 63.3433C115.925 54.3243 140.193 55.126 157.942 65.4478L162.355 68.0533L173.085 56.7293L183.714 45.3052L187.625 48.4118C189.832 50.1154 194.444 54.5247 197.954 58.1323L204.372 64.6461L193.341 76.3708L182.31 88.0956L185.72 95.5112C194.444 114.351 194.043 135.896 184.817 154.636L181.608 161.15L193.542 172.474L205.475 183.697L196.049 193.819C190.834 199.431 186.322 204.04 186.021 204.04C185.72 204.04 183.815 202.437 181.909 200.433C180.104 198.428 174.689 193.318 169.976 189.009L161.352 181.192L154.432 184.599C143.903 189.61 134.878 191.313 121.54 190.712C109.707 190.211 103.49 188.608 92.86 183.196L87.4448 180.491L76.1131 192.416L64.7814 204.341L58.9651 199.23C55.7561 196.424 51.1432 192.115 48.7365 189.61L44.3241 185.1L55.9567 172.674L67.6895 160.248L64.28 152.832C55.5556 134.093 55.7561 113.65 64.5808 95.1104L68.4918 86.9932L57.5612 76.7717C51.5443 71.1598 46.6306 66.2494 46.6306 65.8486C46.6306 64.8465 64.6811 45.7061 65.5836 45.7061C65.9848 45.7061 68.6923 48.2114 71.7008 51.3179Z" fill="black" />
    <path d="M112.716 85.5896C102.086 89.1973 93.2612 96.9135 88.1469 107.436C85.5396 112.847 85.2388 114.25 85.2388 123.87C85.2388 133.591 85.4393 134.894 88.4478 141.107C100.582 166.761 133.474 172.473 153.53 152.531C165.764 140.305 168.672 123.069 161.352 106.834C157.742 98.8176 148.315 89.8987 140.193 86.7922C132.17 83.6856 120.237 83.1846 112.716 85.5896Z" fill="black" />
    <rect x="25" y="121" width="100" height="100" rx="50" fill={`url(#${id}__a`} />
    <path d="M87.75 204H81.5V197.75H75.25V204H69H62.75H56.5V197.75H50.25V191.5V185.25V179V172.75V166.5V160.25H56.5V154H62.75H69H75.25V160.25H81.5V154V147.75V141.5V135.25H87.75H94V141.5V147.75V154V160.25V166.5V172.75V179V185.25V191.5V197.75V204H87.75ZM69 191.5H75.25V185.25H81.5V179V172.75H75.25V166.5H69H62.75V172.75V179V185.25V191.5H69Z" fill={`url(#${id}__b`} />
    <defs>
      <radialGradient
        id={`${id}__a`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(50.6 149) rotate(71.2791) scale(76.0221)"
      >
        <stop stopColor="#66A9F7" />
        <stop offset={1} stopColor="#0075FF" />
      </radialGradient>
      <radialGradient
        id={`${id}__b`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(80.0548 138.253) rotate(75.4706) scale(85.4812 97.7006)"
      >
        <stop stopColor="#334155" />
        <stop offset={1} stopColor="#050A30" />
      </radialGradient>
    </defs>
  </svg>
);

export default SvgDfrax;
