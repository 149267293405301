// import BigNumber from 'bignumber.js';
import type { TransactionReceipt } from 'web3-core/types';
import { VBEP_TOKENS } from 'constants/tokens';
// import BigNumber from 'bignumber.js';
import { Comptroller } from 'types/contracts';
import { checkForComptrollerTransactionError } from 'utilities/errors';
// import getCTokenBalancesAll from '../queries/getCTokenBalancesAll';


export interface IClaimDammRewardInput {
  comptrollerContract: Comptroller;
  // venusLensContract: VenusLens;
  fromAccountAddress: string;
  markets?: string[];
}

export type ClaimDammRewardOutput = TransactionReceipt;

/*
  Take list of markets the user is in, if provided, and ensure at least one market is
  passed to the claim function
*/
const getMarketsToClaim = (markets: string[] | undefined, fallback: string[]) => {
  if (!markets) {
    return fallback;
  }
  if (markets.length === 0 && fallback.length > 0) {
    return [fallback[0]];
  }
  return markets;
};

const claimDammReward = async ({
  comptrollerContract,
  // venusLensContract,
  fromAccountAddress,
  markets,

}: IClaimDammRewardInput): Promise<ClaimDammRewardOutput> => {
  // Fetch list of tokens for which user have a positive balance, since these
  // are the tokens susceptible to have generated DAMM rewards
  const cTokenAddresses = Object.values(VBEP_TOKENS).map(cToken => cToken.address);

  // const cTokenBalances = await getCTokenBalancesAll({
  //   venusLensContract,
  //   vtAddresses: cTokenAddresses,
  //   account: fromAccountAddress,
  // });

  // const filteredCTokenAddresses = cTokenBalances
  //   .filter(
  //     cTokenBalance =>
  //       new BigNumber(cTokenBalance.borrowBalanceCurrent).isGreaterThan(0) ||
  //       new BigNumber(cTokenBalance.balanceOfUnderlying).isGreaterThan(0),
  //   )
  //   .map(cTokenBalance => cTokenBalance.cToken);

  // Send query to claim DAMM reward
  const resp = await comptrollerContract.methods['claimComp(address[],address[],bool,bool)'](
    [fromAccountAddress], getMarketsToClaim(markets, cTokenAddresses), false, true,
  ).send({
    from: fromAccountAddress,
  });
  return checkForComptrollerTransactionError(resp);
};

export default claimDammReward;
