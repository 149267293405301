import { useQuery, QueryObserverOptions } from 'react-query';
import getMarkets, { GetMarketsOutput } from 'clients/api/queries/getMarkets';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  GetMarketsOutput,
  Error,
  GetMarketsOutput,
  GetMarketsOutput,
  [getMarketsKey: FunctionKey.GET_MARKETS, blockNumber: number | void]
>;

const useGetMarkets = (options?: Options) => useQuery(
  [
    FunctionKey.GET_MARKETS,
    typeof options?.meta?.blockNumber === 'number' ? options.meta.blockNumber : undefined,
  ],
  () => getMarkets(typeof options?.meta?.blockNumber === 'number' ? options.meta.blockNumber : undefined),
  options,
);

export default useGetMarkets;
