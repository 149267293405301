/** @jsxImportSource @emotion/react */
import React from 'react';
import Fade from '@mui/material/Fade';
import { Icon } from 'components';
import { useStyles } from './styles';


export const Blocked: React.FC = () => {
  const s = useStyles();
  const isOpen = true;
  return (
    <>
      <Fade in={isOpen}>
        <div css={s.box}>
          <div css={s.titleWrapper}>
            <div css={s.title}>
                <Icon name="notice" css={s.icon} />
                <h2>Warning</h2>
            </div>
          </div>
          <div css={s.contentWrapper}>
            <p>This interface is currently not available to residents of any jurisdiction in which accessing or using the dAMM Protocol is prohibited. In using this interface, you confirm that you are not located in, incorporated or otherwise established in, or a citizen or resident of, a Prohibited Jurisdiction.</p>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default Blocked;
