import * as React from 'react';
import { SVGProps } from 'react';

const SvgDaave = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M125 250C194.036 250 250 194.036 250 125C250 55.9644 194.036 0 125 0C55.9644 0 0 55.9644 0 125C0 194.036 55.9644 250 125 250Z" fill={`url(#${id}__c`} />
    <path d="M179.703 174.806L137.435 72.6157C135.051 67.3322 131.508 64.7549 126.836 64.7549H123.099C118.428 64.7549 114.884 67.3322 112.5 72.6157L94.1042 117.139H80.1866C76.0307 117.171 72.648 120.522 72.6158 124.71V124.806C72.648 128.962 76.0307 132.345 80.1866 132.377H87.6609L70.1029 174.806C69.7807 175.741 69.5874 176.707 69.5874 177.706C69.5874 180.09 70.3284 181.958 71.6493 183.408C72.9701 184.858 74.8709 185.567 77.2549 185.567C78.8335 185.535 80.3477 185.051 81.6042 184.117C82.9572 183.183 83.8915 181.83 84.6325 180.283L103.962 132.345H117.364C121.52 132.313 124.903 128.962 124.935 124.774V124.581C124.903 120.425 121.52 117.042 117.364 117.01H110.212L124.968 80.251L165.174 180.251C165.915 181.797 166.849 183.151 168.202 184.085C169.459 185.019 171.005 185.502 172.551 185.535C174.935 185.535 176.804 184.826 178.157 183.376C179.51 181.926 180.219 180.058 180.219 177.674C180.251 176.707 180.09 175.708 179.703 174.806Z" fill="white" />
    <rect x="25" y="119" width="100" height="100" rx="50" fill={`url(#${id}__a`} />
    <path d="M87.75 202H81.5V195.75H75.25V202H69H62.75H56.5V195.75H50.25V189.5V183.25V177V170.75V164.5V158.25H56.5V152H62.75H69H75.25V158.25H81.5V152V145.75V139.5V133.25H87.75H94V139.5V145.75V152V158.25V164.5V170.75V177V183.25V189.5V195.75V202H87.75ZM69 189.5H75.25V183.25H81.5V177V170.75H75.25V164.5H69H62.75V170.75V177V183.25V189.5H69Z" fill={`url(#${id}__b`} />
    <defs>
        <radialGradient id={`${id}__a`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(50.6 147) rotate(71.2791) scale(76.0221)">
            <stop stopColor="#66A9F7" />
            <stop offset="1" stopColor="#0075FF" />
        </radialGradient>
        <radialGradient id={`${id}__b`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(80.0548 136.987) rotate(76.1548) scale(89.6165 97.9959)">
            <stop stopColor="#334155" />
            <stop offset="1" stopColor="#050A30" />
        </radialGradient>
        <linearGradient id={`${id}__c`} x1="218.198" y1="46.5807" x2="32.1982" y2="203.081" gradientUnits="userSpaceOnUse">
            <stop stopColor="#B6509E" />
            <stop offset="1" stopColor="#2EBAC6" />
        </linearGradient>
    </defs>
  </svg>
);

export default SvgDaave;
