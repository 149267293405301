import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  return {
    cardContentGrid: css`
      .table__table-cards__card-content {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
      }
    `,
    title: css` 
    margin-top: ${theme.spacing(6)};
      padding: ${theme.spacing(6, 6)};
      ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(2)};
        margin-top: ${theme.spacing(4)};
      }
      `,
  };
};
