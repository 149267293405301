import * as React from 'react';
import { SVGProps } from 'react';

const SvgUsdc = (props: SVGProps<SVGSVGElement>) => (
  <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="mask0_702_23155" maskUnits="userSpaceOnUse" x="0" y="0" width="250" height="250">
      <path d="M0 0H250V250H0V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_702_23155)">
      <path fillRule="evenodd" clipRule="evenodd" d="M125 0C194.037 0 250 55.9631 250 125C250 194.031 194.037 250 125 250C55.9631 250 0 194.031 0 125C0 55.9631 55.9631 0 125 0Z" fill="#2775CA" />
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M101.568 211.909C101.568 214.849 99.2654 216.52 96.4216 215.632C58.6276 203.575 31.2256 168.136 31.2256 126.321C31.2256 84.5068 58.6276 49.0665 96.4216 37.0096C99.2654 36.1275 101.568 37.7923 101.568 40.7327V47.9912C101.568 49.9486 100.051 52.2525 98.1858 52.8903C68.2838 63.8704 46.8634 92.5963 46.8634 126.271C46.8634 159.946 68.2838 188.677 98.1858 199.652C100.051 200.339 101.568 202.592 101.568 204.557V211.909Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M132.794 184.898C132.794 187.057 131.03 188.822 128.873 188.822H121.078C118.922 188.822 117.158 187.057 117.158 184.898V172.547C100.098 170.245 91.7669 160.684 89.5112 147.695C89.1177 145.486 90.8819 143.478 93.1376 143.478H102.059C103.922 143.478 105.491 144.803 105.883 146.662C107.551 154.408 112.011 160.34 125.686 160.34C135.784 160.34 142.943 154.702 142.943 146.275C142.943 137.84 138.726 134.655 123.922 132.202C102.06 129.262 91.7172 122.643 91.7172 105.486C91.7172 92.252 101.719 81.9593 117.207 79.752V67.644C117.207 65.4863 118.973 63.7207 121.128 63.7207H128.923C131.079 63.7207 132.844 65.4863 132.844 67.644V80.0957C145.442 82.3542 153.432 89.5062 156.031 101.368C156.52 103.627 154.756 105.731 152.453 105.731H144.216C142.452 105.731 140.981 104.553 140.443 102.839C138.237 95.2932 132.844 92.0588 123.48 92.0588C113.138 92.0588 107.794 97.0574 107.794 104.066C107.794 111.468 110.835 115.191 126.719 117.499C148.186 120.439 159.267 126.566 159.267 144.854C159.267 158.725 148.971 169.951 132.844 172.547V184.898H132.794Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M153.579 215.632C150.738 216.52 148.433 214.849 148.433 211.909V204.656C148.433 202.498 149.756 200.49 151.815 199.751C181.717 188.771 203.137 160.045 203.137 126.37C203.137 92.6957 181.717 63.9699 151.815 52.9898C149.953 52.3023 148.433 50.0494 148.433 48.0849V40.8321C148.433 37.8918 150.738 36.1773 153.579 37.1034C191.373 49.1673 218.775 84.6062 218.775 126.42C218.728 168.135 191.373 203.525 153.579 215.632Z" fill="white" />
  </svg>
);

export default SvgUsdc;
