import * as React from 'react';
import { SVGProps } from 'react';

const SvgDamm = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="250" height="250" rx="125" fill={`url(#${id}__a`} />
    <path
      d="M89.1688 62.6875H78.931V73.0504H68.6934V166.786H89.1688V72.9252H99.4065V62.6875H89.1688Z"
      fill={`url(#${id}__b`}
    />
    <path
      d="M130.119 62.6875H119.882V72.9252H109.645V187.26H119.882H130.119V72.9252H140.356V62.6875H130.119Z"
      fill={`url(#${id}__c`}
    />
    <path
      d="M181.306 72.9264V62.6875H171.067H160.831V72.9264H150.593V166.786V207.736H171.068V166.786H171.067V72.9264H181.306Z"
      fill={`url(#${id}__d`}
    />
    <defs>
      <radialGradient
        id={`${id}__a`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(64 70) rotate(71.2791) scale(190.055)"
      >
        <stop stopColor="#66A9F7" />
        <stop offset={1} stopColor="#0075FF" />
      </radialGradient>
      <radialGradient
        id={`${id}__b`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(78.3225 77.9831) rotate(86.3098) scale(88.9877 26.8991)"
      >
        <stop stopColor="#334155" />
        <stop offset={1} stopColor="#050A30" />
      </radialGradient>
      <radialGradient
        id={`${id}__c`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(119.273 80.9915) rotate(86.9151) scale(106.423 26.9149)"
      >
        <stop offset={0} stopColor="#334155" />
        <stop offset={1} stopColor="#050A30" />
      </radialGradient>
      <radialGradient
        id={`${id}__d`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(160.222 84) rotate(87.3498) scale(123.868 26.9263)"
      >
        <stop stopColor="#334155" />
        <stop offset={1} stopColor="#050A30" />
      </radialGradient>
    </defs>
  </svg>
);

export default SvgDamm;
