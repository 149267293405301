/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import BigNumber from 'bignumber.js';

import { AuthContext } from 'context/AuthContext';
import useSuccessfulTransactionModal from 'hooks/useSuccessfulTransactionModal';
import { useClaimDammReward, useGetDammAccrued } from 'clients/api';
import { useTranslation } from 'translation';
import { TokenId } from 'types';
// import { convertWeiToCoins } from 'utilities/common';
import { UiError, TransactionError } from 'utilities/errors';
import {
  ComptrollerTransactionErrorsError,
  ComptrollerTransactionErrorsFailureInfo,
} from 'translation/transactionErrors';
import { formatCoinsToReadableValue } from 'utilities/common';
import useGetClaimMarketData from 'clients/api/queries/useGetClaimMarketData';
import { VBEP_TOKENS } from 'constants/tokens';
import { toast } from '../../Toast';
import { Icon } from '../../Icon';
import { PrimaryButton, IButtonProps } from '../../Button';
import { useStyles } from './styles';


const DAMM_SYMBOL = 'bdamm';

export const TEST_ID = 'claim-bdamm-reward-button';

export interface IClaimDammRewardButton extends Omit<IButtonProps, 'onClick'> {
  onClaim: () => Promise<string | undefined>;
  dammAccrued: BigNumber;
}

export const ClaimDammRewardButtonUi: React.FC<IClaimDammRewardButton> = ({
  dammAccrued,
  onClaim,
  ...otherProps
}) => {
  const { t, Trans } = useTranslation();
  const styles = useStyles();

  const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();

  const handleClick = async () => {
    try {
      const transactionHash = await onClaim();
      if (transactionHash) {
        // Display successful transaction modal
        openSuccessfulTransactionModal({
          title: t('claimDammRewardButton.successfulTransactionModal.title'),
          content: t('claimDammRewardButton.successfulTransactionModal.message'),
          amount: {
            valueWei: dammAccrued.shiftedBy(18),
            tokenId: 'bdamm' as TokenId,
          },
          transactionHash,
        });
      }
    } catch (error) {
      toast.error({
        message: (error as UiError).message,
      });
    }
  };

  return (
    <PrimaryButton
      data-testid={TEST_ID}
      css={styles.button}
      onClick={handleClick}
      {...otherProps}
    >
      <Trans
        i18nKey="claimDammRewardButton.title"
        components={{
          Icon: <Icon css={styles.icon} name={DAMM_SYMBOL} />,
        }}
        values={{
          amount: dammAccrued.isNaN() ? '...' : formatCoinsToReadableValue({
            value: dammAccrued,
            tokenId: 'bdamm',
            shorthand: true,
          }),
        }}
      />
    </PrimaryButton>
  );
};

export const ClaimDammRewardButton: React.FC<IButtonProps> = props => {
  const { account } = useContext(AuthContext);
  const { t } = useTranslation();

  const { data: storedDammAccrued } = useGetDammAccrued(account?.address || '');
  const { data: marketClaimData } = useGetClaimMarketData(account?.address || '');
  const dammAccrued = marketClaimData
    ? marketClaimData
      .reduce((a, b) => a.plus(b), new BigNumber(0))
      .plus(storedDammAccrued?.shiftedBy(-18) || 0)
    : undefined;
  // const { data: dammAccrued } = useGetDammReward(account?.address || '');

  const { mutateAsync: claimDammReward, isLoading: isClaimDammRewardLoading } = useClaimDammReward();

  const handleClaim = async () => {
    if (!account?.address) {
      throw new UiError(t('errors.walletNotConnected'));
    }
    const markets = Object.values(VBEP_TOKENS)
      .filter(item => item.address && item.id !== 'damm')
      .map(item => item.address);
    if (!marketClaimData || marketClaimData.length !== markets.length) {
      throw new UiError('No markets to claim');
    }
    try {
      const res = await claimDammReward({
        fromAccountAddress: account.address,
        markets: markets.filter((_, i) => (new BigNumber(marketClaimData[i])).isGreaterThan(0)),
      });
      return res.transactionHash;
    } catch (err) {
      if (err instanceof TransactionError) {
        throw new UiError(
          ComptrollerTransactionErrorsError[
            err.error as keyof typeof ComptrollerTransactionErrorsError
          ],
          ComptrollerTransactionErrorsFailureInfo[
            err.info as keyof typeof ComptrollerTransactionErrorsFailureInfo
          ],
        );
      }
    }
  };

  return (
    <ClaimDammRewardButtonUi
      dammAccrued={dammAccrued || new BigNumber(NaN)}
      loading={isClaimDammRewardLoading}
      onClaim={handleClaim}
      {...props}
    />
  );
};

export default ClaimDammRewardButton;
