import * as React from 'react';
import { SVGProps } from 'react';

const SvgCdai = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
<svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M125.014 250C194.042 250 250 194.062 250 125.059C250 56.0554 194.042 0.117188 125.014 0.117188C55.9857 0.117188 0.0273438 56.0554 0.0273438 125.059C0.0273438 194.062 55.9857 250 125.014 250Z" fill="#EDF0F2" />
<path d="M204.364 221.476C185.308 237.203 161.953 246.874 137.327 249.219C104.394 252.443 71.462 242.479 45.7611 221.476C20.1579 200.473 3.93606 170.19 0.613505 137.172C-1.82955 112.555 3.05656 87.8403 14.6855 66.0562C26.3144 44.272 44.1976 26.3953 65.9896 14.7706C87.7816 3.14589 112.505 -1.83614 137.131 0.606032C161.757 3.0482 185.015 12.6215 204.169 28.3491L178.956 59.1204C165.959 48.4726 150.031 41.8299 133.222 40.2669C116.414 38.6062 99.606 42.0252 84.7522 49.9379C69.8985 57.8505 57.7809 70.0613 49.7677 84.9097C41.8522 99.7581 38.5297 116.658 40.191 133.362C42.4386 155.83 53.4812 176.54 70.9734 190.802C88.4657 205.162 110.942 211.903 133.418 209.656C150.226 207.995 166.057 201.352 179.054 190.705L204.364 221.476Z" fill={`url(#${id}__a`} />
<path d="M125.405 227.337C181.79 227.337 227.427 181.62 227.427 125.352C227.427 68.9869 181.79 23.2695 125.405 23.2695C69.0191 23.2695 23.2852 68.9869 23.2852 125.352C23.2852 181.717 69.0191 227.337 125.405 227.337Z" fill="white" />
<path d="M169.868 112.164H160.389C155.21 97.7062 141.235 87.8398 122.766 87.8398H92.472V112.164H81.918V120.858H92.472V130.041H81.918V138.735H92.472V162.766H122.766C140.942 162.766 154.819 152.997 160.193 138.735H169.868V130.041H162.343C162.539 128.478 162.636 126.915 162.636 125.352V125.156C162.636 123.691 162.539 122.323 162.441 120.956H169.868V112.164ZM100.974 95.6548H122.766C136.251 95.6548 146.317 102.297 151.007 112.164H100.974V95.6548ZM122.766 154.951H100.974V138.735H151.007C146.317 148.503 136.251 154.951 122.766 154.951V154.951ZM153.841 125.547C153.841 127.11 153.744 128.575 153.548 130.041H100.974V120.858H153.548C153.744 122.323 153.841 123.789 153.841 125.254V125.547Z" fill="#283947" />
<defs>
<linearGradient id={`${id}__a`} x1="89.8278" y1="13.8382" x2="128.981" y2="230.994" gradientUnits="userSpaceOnUse">
<stop stopColor="#27D3A2" />
<stop offset="1" stopColor="#9388FD" />
</linearGradient>
</defs>
</svg>


);

export default SvgCdai;
