import * as React from 'react';
import { SVGProps } from 'react';

const SvgDrbn = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="124" cy="124" r="124" fill="white" />
    <path d="M111.957 0.644921C83.0366 3.75704 56.4255 16.6071 36.3416 37.0868C10.0318 63.8911 -3.02267 100.233 0.592424 136.273C1.69703 147.717 5.01087 162.375 8.02344 169.703L9.73057 173.618L14.8519 168.197C17.6637 165.186 24.8939 157.455 31.0194 151.131C47.9903 133.562 61.2456 119.708 84.342 95.4139C95.8902 83.2666 109.848 68.8103 115.171 63.2888L125.012 53.2497L130.234 58.7712C133.045 61.7829 147.405 76.7412 162.167 91.9002C194.602 125.431 197.314 128.342 197.314 129.346C197.314 129.848 181.447 147.216 161.966 167.996C142.585 188.777 126.116 206.547 125.313 207.35C124.208 208.554 123.305 208.052 118.485 203.334C115.472 200.322 112.961 197.511 112.961 197.009C112.961 196.608 127.02 181.047 144.192 162.575L175.422 128.944L161.464 114.689C153.832 106.858 142.384 95.1127 136.058 88.5873L124.51 76.7412L119.991 81.5599C113.765 88.0853 23.2872 182.654 22.5842 183.256C22.283 183.557 20.9775 184.962 19.6721 186.468L17.3624 189.179L22.6846 196.909C25.6972 201.226 32.124 208.755 36.9442 213.574C68.3754 244.896 113.363 257.043 156.644 246C177.129 240.78 197.916 228.833 213.381 213.373C244.812 181.85 257.163 136.474 245.916 93.3057C237.18 59.5743 212.879 29.4571 181.648 13.5953C160.962 3.0543 134.451 -1.86485 111.957 0.644921Z" fill="#FC0A54" />
    <rect x="25" y="121" width="100" height="100" rx="50" fill={`url(#${id}__a`} />
    <path d="M87.75 204H81.5V197.75H75.25V204H69H62.75H56.5V197.75H50.25V191.5V185.25V179V172.75V166.5V160.25H56.5V154H62.75H69H75.25V160.25H81.5V154V147.75V141.5V135.25H87.75H94V141.5V147.75V154V160.25V166.5V172.75V179V185.25V191.5V197.75V204H87.75ZM69 191.5H75.25V185.25H81.5V179V172.75H75.25V166.5H69H62.75V172.75V179V185.25V191.5H69Z" fill={`url(#${id}__b`} />
    <defs>
      <radialGradient
        id={`${id}__a`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(50.6 149) rotate(71.2791) scale(76.0221)"
      >
        <stop stopColor="#66A9F7" />
        <stop offset={1} stopColor="#0075FF" />
      </radialGradient>
      <radialGradient
        id={`${id}__b`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(80.0548 138.253) rotate(75.4706) scale(85.4812 97.7006)"
      >
        <stop stopColor="#334155" />
        <stop offset={1} stopColor="#050A30" />
      </radialGradient>
    </defs>
  </svg>
);

export default SvgDrbn;
