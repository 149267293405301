import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import { TokenId, CTokenId } from 'types';
import { getWeb3NoAccount } from 'clients/web3';
import erc20Abi from 'constants/contracts/abis/erc20.json';
import comptrollerAbi from 'constants/contracts/abis/comptroller.json';
import interestModelAbi from 'constants/contracts/abis/interestModel.json';
import oracleAbi from 'constants/contracts/abis/oracle.json';
import cErc20Abi from 'constants/contracts/abis/cErc20.json';
import dammTokenAbi from 'constants/contracts/abis/dammToken.json';
import venusLensAbi from 'constants/contracts/abis/venusLens.json';
import bdammTokenAbi from 'constants/contracts/abis/bdammToken.json';
import redemptionAbi from 'constants/contracts/abis/redemption.json';

import {
  Erc20,
  Comptroller,
  InterestModel,
  Oracle,
  VenusLens,
  DammToken,
  Redemption,
} from 'types/contracts';
import { getContractAddress, getToken, getCErcToken } from 'utilities';
import { TokenContract, CTokenContract } from './types';

const getContract = <T>(abi: AbiItem | AbiItem[], address: string, web3Instance: Web3) => {
  const web3 = web3Instance ?? getWeb3NoAccount();
  return new web3.eth.Contract(abi, address) as unknown as T;
};

export const getTokenContract = <T extends TokenId>(tokenId: T, web3: Web3): TokenContract<T> => {
  const tokenAddress = getToken(tokenId).address;

  if (tokenId === 'bdamm') {
    return getContract<TokenContract<T>>(bdammTokenAbi as AbiItem[], tokenAddress, web3);
  }

  return getContract<TokenContract<T>>(erc20Abi as AbiItem[], tokenAddress, web3);
};

export const getTokenContractByAddress = (address: string, web3: Web3): Erc20 =>
  getContract(erc20Abi as AbiItem[], address, web3) as unknown as Erc20;

export const getCTokenContract = <T extends CTokenId>(
  tokenId: T,
  web3: Web3,
): CTokenContract<T> => {
  const cErcTokenAddress = getCErcToken(tokenId).address;

  // if (tokenId === 'eth') {
  //   return getContract(
  //     cEthTokenAbi as AbiItem[],
  //     cErcTokenAddress,
  //     web3,
  //   ) as unknown as CTokenContract<T>;
  // }

  return getContract(
    cErc20Abi as AbiItem[],
    cErcTokenAddress,
    web3,
  ) as unknown as CTokenContract<T>;
};

export const getComptrollerContract = (target: 'core' | 'staking', web3: Web3) =>
  getContract(
    comptrollerAbi as AbiItem[],
    getContractAddress(target === 'core' ? 'comptroller' : 'dammtroller'),
    web3,
  ) as unknown as Comptroller;

export const getPriceOracleContract = (web3: Web3) =>
  getContract(oracleAbi as AbiItem[], getContractAddress('oracle'), web3) as unknown as Oracle;

export const getInterestModelContract = (address: string, web3: Web3) =>
  getContract(interestModelAbi as AbiItem[], address, web3) as unknown as InterestModel;

export const getVenusLensContract = (web3: Web3) =>
  getContract(
    venusLensAbi as AbiItem[],
    getContractAddress('lens'),
    web3,
  ) as unknown as VenusLens;

export const getDammToken = (web3: Web3) => getContract(
  dammTokenAbi as AbiItem[],
  getToken('damm').address,
  web3,
) as unknown as DammToken;

export const getRedemptionContract = (web3: Web3) =>
getContract(
  redemptionAbi as AbiItem[],
  getContractAddress('redemption'),
  web3,
) as unknown as Redemption;
