import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

// import { RPC_URL, CHAIN_ID, BscChainId } from 'config';
import { RPC_URL, CHAIN_ID } from 'config';
import { Connector } from './types';

export const injectedConnector = new InjectedConnector({ supportedChainIds: [CHAIN_ID] });

// const walletConnectConnector = new WalletConnectConnector({
//   rpc: { [BscChainId.MAINNET]: RPC_URL },
//   chainId: BscChainId.MAINNET,
//   bridge: 'https://bridge.walletconnect.org',
//   qrcode: true,
// });
const walletConnectConnector = new WalletConnectConnector({
  rpc: { 1: RPC_URL },
  chainId: 1,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
});


const coinbaseWalletConnector = new WalletLinkConnector({
  url: RPC_URL,
  appName: 'DAMM',
});

export const connectorsByName = {
  [Connector.MetaMask]: injectedConnector,
  [Connector.WalletConnect]: walletConnectConnector,
  [Connector.CoinbaseWallet]: coinbaseWalletConnector,
  [Connector.TrustWallet]: injectedConnector,
};
