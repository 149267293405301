import * as React from 'react';
import { SVGProps } from 'react';

const SvgDsnx = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="125" cy="125" r="123.5" fill={`url(#${id}__a`} stroke="black" strokeWidth="3" />
    <path d="M124.986 249.973C194.015 249.973 249.973 194.015 249.973 124.986C249.973 55.9584 194.015 0 124.986 0C55.9584 0 0 55.9584 0 124.986C0 194.015 55.9584 249.973 124.986 249.973Z" fill={`url(#${id}__a`} />
    <path d="M83.8184 93.912C82.8194 92.6718 81.3035 91.9828 79.6844 91.9828H46.3018C46.0262 91.9828 45.785 91.8794 45.5783 91.7072C45.4061 91.5349 45.3027 91.3282 45.3027 91.0871V68.5909C45.3027 68.3497 45.4061 68.143 45.5783 67.9708C45.785 67.7641 46.0262 67.6607 46.3018 67.6952H81.5791C90.4674 67.6952 98.1498 71.3469 104.627 78.616L113.205 89.0889L96.4962 109.449L83.8184 93.912ZM145.485 78.5126C151.927 71.3125 159.644 67.6952 168.636 67.6952H203.81C204.051 67.6607 204.292 67.7641 204.464 67.9019C204.636 68.0741 204.705 68.3153 204.671 68.5909V91.0871C204.671 91.3282 204.602 91.5349 204.464 91.7072C204.292 91.9139 204.051 92.0172 203.81 91.9828H170.427C168.842 91.9483 167.326 92.6718 166.293 93.912L141.695 123.849L166.396 153.994C167.395 155.131 168.877 155.82 170.393 155.785H203.775C204.016 155.751 204.292 155.888 204.43 156.061C204.567 156.267 204.671 156.543 204.636 156.784V179.28C204.636 179.522 204.567 179.728 204.43 179.9C204.257 180.107 204.016 180.211 203.775 180.176H168.636C159.644 180.176 151.996 176.524 145.588 169.255L125.125 144.279L104.661 169.255C98.2187 176.524 90.5018 180.176 81.5102 180.176H46.3018C46.0606 180.211 45.785 180.073 45.6472 179.9C45.475 179.694 45.4061 179.418 45.4405 179.177V156.681C45.4405 156.44 45.5094 156.233 45.6472 156.061C45.8195 155.854 46.0606 155.751 46.3018 155.785H79.6844C81.2691 155.785 82.7849 155.062 83.8184 153.856L107.968 124.366L145.485 78.5126Z" fill="#00D1FF" />
    <rect x="25" y="115.602" width="100" height="98.8249" rx="49.4125" fill={`url(#${id}__b`} />
    <path d="M87.75 198.566H81.5V192.316H75.25V198.566H69H62.75H56.5V192.316H50.25V186.066V179.816V173.566V167.316V161.066V154.816H56.5V148.566H62.75H69H75.25V154.816H81.5V148.566V142.316V136.066V129.816H87.75H94V136.066V142.316V148.566V154.816V161.066V167.316V173.566V179.816V186.066V192.316V198.566H87.75ZM69 186.066H75.25V179.816H81.5V173.566V167.316H75.25V161.066H69H62.75V167.316V173.566V179.816V186.066H69Z" fill={`url(#${id}__c`} />
    <defs>
        <linearGradient id={`${id}__a`} x1="125.004" y1="-0.000723461" x2="125.004" y2="66.1443" gradientUnits="userSpaceOnUse">
            <stop stopColor="#090220" />
            <stop offset="1" stopColor="#170659" />
        </linearGradient>
        <radialGradient id={`${id}__b`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(50.6 143.273) rotate(71.0722) scale(75.2213 75.9286)">
            <stop stopColor="#66A9F7" />
            <stop offset="1" stopColor="#0075FF" />
        </radialGradient>
        <radialGradient id={`${id}__c`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(80.0548 133.378) rotate(75.9966) scale(88.6241 97.9289)">
            <stop stopColor="#334155" />
            <stop offset="1" stopColor="#050A30" />
        </radialGradient>
    </defs>
  </svg>
);

export default SvgDsnx;
