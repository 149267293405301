import type { TransactionReceipt } from 'web3-core';

import { Erc20, DammToken } from 'types/contracts';
import ALLOWANCE_AMOUNT_WEI from 'constants/allowanceAmountWei';

export interface IApproveTokenInput {
  tokenContract: Erc20 | DammToken;
  accountAddress: string | undefined;
  ctokenAddress: string;
  allowance?: string;
}

export type ApproveTokenOutput = TransactionReceipt;

const approveToken = ({
  tokenContract,
  accountAddress,
  ctokenAddress,
  allowance = ALLOWANCE_AMOUNT_WEI,
}: IApproveTokenInput): Promise<ApproveTokenOutput> =>
  tokenContract.methods.approve(ctokenAddress, allowance).send({ from: accountAddress });

export default approveToken;
