import * as React from 'react';
import { SVGProps } from 'react';

const SvgDclink = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
<svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M125.014 250C194.042 250 250 194.062 250 125.059C250 56.0554 194.042 0.117188 125.014 0.117188C55.9857 0.117188 0.0273438 56.0554 0.0273438 125.059C0.0273438 194.062 55.9857 250 125.014 250Z" fill="#EDF0F2" />
    <path d="M204.364 221.476C185.308 237.203 161.953 246.874 137.327 249.219C104.394 252.443 71.462 242.479 45.7611 221.476C20.1579 200.473 3.93606 170.19 0.613505 137.172C-1.82955 112.555 3.05656 87.8403 14.6855 66.0562C26.3144 44.272 44.1976 26.3953 65.9896 14.7706C87.7816 3.14589 112.505 -1.83614 137.131 0.606032C161.757 3.0482 185.015 12.6215 204.169 28.3491L178.956 59.1204C165.959 48.4726 150.031 41.8299 133.222 40.2669C116.414 38.6062 99.606 42.0252 84.7522 49.9379C69.8985 57.8505 57.7809 70.0613 49.7677 84.9097C41.8522 99.7581 38.5297 116.658 40.191 133.362C42.4386 155.83 53.4812 176.54 70.9734 190.802C88.4657 205.162 110.942 211.903 133.418 209.656C150.226 207.995 166.057 201.352 179.054 190.705L204.364 221.476Z" fill={`url(#${id}__a`} />
    <path d="M125.405 227.337C181.79 227.337 227.427 181.62 227.427 125.352C227.427 68.9869 181.79 23.2695 125.405 23.2695C69.0191 23.2695 23.2852 68.9869 23.2852 125.352C23.2852 181.717 69.0191 227.337 125.405 227.337Z" fill="white" />
    <path d="M124.999 67L114.417 73.0848L85.5817 89.7489L75 95.8354V153.506L85.5817 159.592L114.682 176.256L125.263 182.34L135.848 176.256L164.418 159.592L175 153.507V95.8354L164.418 89.7506L135.583 73.0865L124.999 67V67ZM96.1634 141.337V108.004L124.999 91.3402L153.834 108.004V141.337L124.999 158.001L96.1634 141.337Z" fill="#283947" />
    <path d="M126.088 150.357V176.342L158.434 131.602L126.088 150.357Z" fill="#657786" />
    <rect x="25" y="119" width="100" height="100" rx="50" fill={`url(#${id}__b`} />
    <path d="M87.75 202H81.5V195.75H75.25V202H69H62.75H56.5V195.75H50.25V189.5V183.25V177V170.75V164.5V158.25H56.5V152H62.75H69H75.25V158.25H81.5V152V145.75V139.5V133.25H87.75H94V139.5V145.75V152V158.25V164.5V170.75V177V183.25V189.5V195.75V202H87.75ZM69 189.5H75.25V183.25H81.5V177V170.75H75.25V164.5H69H62.75V170.75V177V183.25V189.5H69Z" fill={`url(#${id}__c`} />
    <defs>
    <linearGradient id={`${id}__a`} x1="89.8278" y1="13.8382" x2="128.981" y2="230.994" gradientUnits="userSpaceOnUse">
    <stop stopColor="#27D3A2" />
    <stop offset="1" stopColor="#9388FD" />
    </linearGradient>
    <radialGradient id={`${id}__b`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(50.6 147) rotate(71.2791) scale(76.0221)">
    <stop stopColor="#66A9F7" />
    <stop offset="1" stopColor="#0075FF" />
    </radialGradient>
    <radialGradient id={`${id}__c`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(80.0548 136.987) rotate(76.1548) scale(89.6165 97.9959)">
    <stop stopColor="#334155" />
    <stop offset="1" stopColor="#050A30" />
    </radialGradient>
    </defs>
</svg>


);

export default SvgDclink;
