import * as React from 'react';
import { SVGProps } from 'react';

const SvgAtusd = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
<svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M124.987 245.002C192.397 245.002 247.044 190.375 247.044 122.989C247.044 55.6035 192.397 0.976562 124.987 0.976562C57.5764 0.976562 2.92969 55.6035 2.92969 122.989C2.92969 190.375 57.5764 245.002 124.987 245.002Z" fill="#EDF0F2" />
<path d="M125 250C116.538 250.002 108.097 249.152 99.8049 247.461C83.8156 244.186 68.6277 237.794 55.1077 228.651C35.0107 215.085 19.2794 195.982 9.81975 173.656C6.62477 166.096 4.18612 158.237 2.5395 150.196C-0.846499 133.572 -0.846499 116.436 2.5395 99.8119C5.81438 83.8227 12.2055 68.6348 21.348 55.1147C34.9144 35.0166 54.0183 19.2842 76.345 9.8238C83.9054 6.62738 91.7637 4.18742 99.8049 2.53964C116.429 -0.846546 133.565 -0.846546 150.189 2.53964C166.178 5.81447 181.366 12.2055 194.886 21.3482C214.983 34.9145 230.714 54.018 240.174 76.3442C243.371 83.9048 245.812 91.7634 247.461 99.805C250.847 116.429 250.847 133.565 247.461 150.189C244.186 166.178 237.795 181.366 228.652 194.886C215.085 214.982 195.982 230.714 173.656 240.174C166.096 243.371 158.237 245.812 150.195 247.461C141.904 249.152 133.463 250.002 125 250V250ZM125 13.3922C117.445 13.3905 109.91 14.1502 102.507 15.6597C88.2305 18.5835 74.6695 24.29 62.5979 32.4536C44.6566 44.5678 30.613 61.6247 22.1684 81.558C19.3146 88.3093 17.1363 95.3266 15.6654 102.507C12.6422 117.35 12.6422 132.65 15.6654 147.492C18.5903 161.769 24.2978 175.329 32.4623 187.401C44.5754 205.344 61.632 219.39 81.5657 227.837C88.3174 230.69 95.335 232.868 102.516 234.339C117.358 237.363 132.658 237.363 147.501 234.339C161.778 231.415 175.339 225.708 187.409 217.542C205.347 205.428 219.388 188.373 227.832 168.443C230.686 161.691 232.864 154.673 234.335 147.492C237.358 132.65 237.358 117.35 234.335 102.507C231.41 88.2312 225.702 74.6708 217.538 62.6C205.427 44.6591 188.373 30.6149 168.442 22.1685C161.691 19.3149 154.673 17.1366 147.492 15.6656C140.09 14.1545 132.555 13.3931 125 13.3931V13.3922Z" fill={`url(#${id}__a`} />
<path d="M125 235C64.5 235 15 185.5 15 125C15 64.5 64.5 15 125 15C185.5 15 235 64.5 235 125C235 185.5 185.5 235 125 235Z" fill="#002868" />
<path d="M180.44 191H69.56C63.752 191 59 186.248 59 180.44V69.56C59 63.752 63.752 59 69.56 59H180.44C186.248 59 191 63.752 191 69.56V180.44C191 186.248 186.248 191 180.44 191Z" fill="white" />
<path d="M84.7563 177.714C84.4087 177.714 84.1271 177.397 84.1271 177.05V159.313C84.1271 158.966 83.8455 158.649 83.4979 158.649H77.2367C76.8847 158.649 76.5723 158.367 76.5723 158.02V154.592C76.5723 154.245 76.8891 153.928 77.2367 153.928H96.5087C96.8563 153.928 97.1731 154.24 97.1731 154.592V158.02C97.1731 158.367 96.8563 158.649 96.5087 158.649H90.2827C89.9351 158.649 89.6183 158.961 89.6183 159.313V177.05C89.6183 177.397 89.3367 177.714 88.9891 177.714H84.7563Z" fill="#002868" />
<path d="M116.409 154.592C116.409 154.245 116.69 153.928 117.038 153.928H121.236C121.583 153.928 121.9 154.24 121.9 154.592V166.697C121.9 173.869 117.843 178.031 111.406 178.031C105.004 178.031 100.982 173.869 100.982 166.697V154.592C100.982 154.245 101.264 153.928 101.612 153.928H105.809C106.157 153.928 106.474 154.24 106.474 154.592V166.868C106.474 170.401 108.291 172.918 111.441 172.918C114.592 172.918 116.409 170.401 116.409 166.868V154.592V154.592Z" fill="#002868" />
<path d="M141.178 161.694C140.62 161.694 140.444 161.482 140.232 161.064C139.498 159.348 137.887 158.266 135.441 158.266C133.236 158.266 131.384 159.036 131.384 160.506C131.384 162.116 133.1 162.78 136.875 163.234C142.613 163.898 145.9 165.649 145.9 170.194C145.9 175.303 141.42 177.996 135.687 177.996C130.72 177.996 126.452 175.58 125.787 170.511C125.717 170.023 125.963 169.847 126.452 169.847H130.302C130.79 169.847 131.072 170.058 131.212 170.441C131.736 172.122 133.522 173.415 136.11 173.415C138.384 173.415 140.378 172.575 140.378 170.793C140.378 168.659 138.279 168.276 134.081 167.748C129.254 167.119 125.897 165.72 125.897 161.276C125.897 156.832 129.712 153.65 135.692 153.65C141.077 153.65 144.822 156.554 145.592 160.893C145.697 161.381 145.556 161.698 145.033 161.698H141.178V161.694Z" fill="#002868" />
<path d="M150.657 177.714C150.309 177.714 150.027 177.397 150.027 177.05V154.592C150.027 154.245 150.309 153.928 150.657 153.928H160.169C167.236 153.928 172.727 158.79 172.727 165.891C172.727 172.887 167.306 177.714 160.169 177.714H150.657ZM160.169 172.989C163.984 172.989 166.954 169.979 166.954 165.817C166.954 161.654 163.98 158.645 160.134 158.645H156.183C155.835 158.645 155.554 158.957 155.554 159.309V172.355C155.554 172.703 155.835 172.984 156.183 172.984H160.169V172.989Z" fill="#002868" />
<path d="M187.48 140.84H62.5198C61.5518 140.84 60.7598 140.048 60.7598 139.08V69.5598C60.7598 64.7198 64.7198 60.7598 69.5598 60.7598H180.44C185.28 60.7598 189.24 64.7198 189.24 69.5598V139.08C189.24 140.048 188.448 140.84 187.48 140.84Z" fill="#002868" />
<path d="M131.481 126.567H120.041C119.315 126.567 118.721 125.973 118.721 125.247V76.8473C118.721 76.1213 119.315 75.5273 120.041 75.5273H131.481C132.207 75.5273 132.801 76.1213 132.801 76.8473V125.247C132.801 125.973 132.207 126.567 131.481 126.567Z" fill="white" />
<path d="M150.961 89.6073H99.0407C98.3147 89.6073 97.7207 89.0133 97.7207 88.2873V76.8473C97.7207 76.1213 98.3147 75.5273 99.0407 75.5273H150.961C151.687 75.5273 152.281 76.1213 152.281 76.8473V88.2873C152.281 89.0133 151.687 89.6073 150.961 89.6073Z" fill="white" />
<defs>
<linearGradient id={`${id}__a`} x1="210.75" y1="33.75" x2="51.5003" y2="221.5" gradientUnits="userSpaceOnUse">
<stop stopColor="#B6509E" />
<stop offset="1" stopColor="#2EBAC6" />
</linearGradient>
</defs>
</svg>

);

export default SvgAtusd;
