/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import { Paper } from '@mui/material';
import { Asset } from 'types';
import { Tabs } from 'components';
import { useTranslation } from 'translation';
import { MarketContext } from 'context/MarketContext';
import { isWhitelisted } from 'utilities/common';
import SupplyMarket from './SupplyMarket';
import BorrowMarket from './BorrowMarket';
import { useStyles as useLocalStyles } from './styles';
import { useStyles as useSharedStyles } from '../styles';


export interface IMarketsProps {
  accountAddress: string;
  aaveMarkets: Asset[];
}

const Markets: React.FC<IMarketsProps> = ({
  accountAddress,
  aaveMarkets,
}) => {
  const { t } = useTranslation();
  const sharedStyles = useSharedStyles();
  const localStyles = useLocalStyles();
  const styles = {
    ...sharedStyles,
    ...localStyles,
  };


  const tabsContent = [
    {
      title: t('Supply'),
      content: (
        <SupplyMarket
          css={styles.market}
          aaveMarkets={aaveMarkets}
          accountAddress={accountAddress}
        />
      ),
    },
    {
      title: t('Borrow'),
      content: (
        <BorrowMarket
          css={styles.column}
          aaveMarkets={aaveMarkets}
        />
      ),
    },
  ];

  const { userTotalBorrowLimit } = useContext(MarketContext);
  return (
    <>
      {isWhitelisted(userTotalBorrowLimit) ?
      <>
        <Paper css={styles.container}>
          <Tabs
            css={styles.tabsHeader}
            tabsContent={tabsContent}
          />
        </Paper>
      </>
      :
        <>
          <Paper css={styles.container}>

            <SupplyMarket
              css={styles.column}
              aaveMarkets={aaveMarkets}
              accountAddress={accountAddress}
            />
          </Paper>
        </>
      }
    </>
  );
};

export default Markets;
