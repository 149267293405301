import BigNumber from 'bignumber.js';
import type { TransactionReceipt } from 'web3-core/types';

import { checkForTokenTransactionError } from 'utilities/errors';
import { CTokenId } from 'types';
import { CTokenContract } from 'clients/contracts/types';

export interface IBorrowCTokenInput {
  cTokenContract: CTokenContract<CTokenId>;
  fromAccountAddress: string;
  amountWei: BigNumber;
}

export type BorrowCTokenOutput = TransactionReceipt;

const borrowCToken = async ({
  cTokenContract,
  fromAccountAddress,
  amountWei,
}: IBorrowCTokenInput): Promise<BorrowCTokenOutput> => {
  const resp = await cTokenContract.methods
    .borrow(amountWei.toFixed())
    .send({ from: fromAccountAddress });
  return checkForTokenTransactionError(resp);
};

export default borrowCToken;
