import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    box: css`
      position: relative;
      margin: auto;
      top: 50%;
      width: calc(100% - ${theme.spacing(8)});
      max-width: ${theme.spacing(136)};
      border-radius: ${theme.spacing(6)};
      background-color: ${theme.palette.background.paper};
      overflow: auto;
      max-height: calc(100% - ${theme.spacing(8)});
    `,
    titleWrapper: css`
      padding-left: ${theme.spacing(6)};
      padding-right: ${theme.spacing(6)};
      padding-top: ${theme.spacing(6)};
      padding-bottom: $theme.spacing(6)};
      border-bottom: ${`1px solid ${theme.palette.secondary.light}`};
      position: sticky;
      top: 0;
      background-color: ${theme.palette.background.paper};
      margin-bottom: ${theme.spacing(10)};
      display: flex;
      align-items: center;
      justify-content: center;
      ${theme.breakpoints.down('md')} {
        margin-bottom: ${theme.spacing(4)};
      }
    `,
    title: css`
      align-self: center;
      display: flex;
      justify-content: left;
      align-items: center;
      min-height: ${theme.shape.iconSize.xLarge}px;
      padding-left: ${theme.shape.iconSize.xLarge}px;
      padding-right: ${theme.shape.iconSize.xLarge}px;
      padding-bottom:  ${theme.shape.iconSize.xLarge}px;
    `,
    icon: css`
    align-self: left;
    display: flex;
    height: 24px;
    width: 24px;
    color: red;
    margin-right: 30%;
    ${theme.breakpoints.down('md')} {
        margin-right: 10%;
  `,
    contentWrapper: css`
      padding-bottom: ${theme.spacing(10)};
      padding-left: ${theme.spacing(10)};
      padding-right: ${theme.spacing(10)};
      }
    `,
  };
};
