import * as React from 'react';
import { SVGProps } from 'react';

const SvgEth = (props: SVGProps<SVGSVGElement>) => (
  <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="125" cy="125" r="125" fill="#D9D9D9" />
    <path d="M125.24 45.8673V45.9461C125.319 46.3402 125.319 46.8131 125.319 47.286V104.114C125.24 104.429 125.003 104.508 124.767 104.665C123.112 105.454 121.535 106.163 119.88 106.872C117.595 107.897 115.23 109 112.944 110.025L104.668 113.808C102.383 114.833 100.097 115.857 97.8902 116.882C95.2104 118.143 92.4517 119.325 89.7719 120.587C87.4862 121.611 85.2005 122.715 82.836 123.739C80.9443 124.606 79.0527 125.394 77.2399 126.261C77.0823 126.34 76.9246 126.419 76.767 126.419C76.6882 126.419 76.6882 126.419 76.6094 126.34L78.7375 122.793C82.836 116.015 86.8557 109.316 90.9542 102.537C95.2892 95.286 99.703 88.0348 104.038 80.7836C108.058 74.0841 112.156 67.3846 116.176 60.685C119.092 55.7983 122.087 50.9116 125.003 46.0249C125.082 45.8673 125.161 45.7885 125.161 45.6309H125.24C125.161 45.7097 125.24 45.7885 125.24 45.8673Z" fill="#8A92B2" />
    <path d="M173.631 126.34L173.71 126.419L162.123 133.276L125.71 154.793C125.552 154.872 125.394 154.951 125.316 155.03C125.079 155.03 125.079 154.793 125.079 154.714V154.005V105.296C125.079 105.059 125.079 104.744 125.158 104.507C125.237 104.192 125.473 104.271 125.71 104.35C126.734 104.823 127.838 105.296 128.862 105.769C131.936 107.187 135.01 108.606 138.084 109.946C140.764 111.128 143.365 112.389 146.045 113.571C148.724 114.754 151.404 116.015 154.084 117.197C156.37 118.222 158.734 119.325 161.02 120.35C163.306 121.374 165.67 122.478 167.956 123.503C169.769 124.291 171.582 125.158 173.394 125.946C173.394 126.182 173.473 126.261 173.631 126.34Z" fill="#454A75" />
    <path d="M125.236 203.976C125.236 204.055 125.157 204.134 125.157 204.213H125.078C125.078 204.055 124.921 203.976 124.842 203.819C119.955 196.961 115.069 190.025 110.182 183.168C105.216 176.154 100.172 169.06 95.2065 162.045C90.3986 155.267 85.5119 148.41 80.704 141.631C79.4429 139.819 78.1818 138.085 76.9207 136.272C76.8419 136.114 76.7631 136.035 76.6055 135.799C76.8419 135.799 76.9996 135.956 77.0784 136.035C83.9355 140.055 90.7138 144.075 97.571 148.094C105.453 152.745 113.256 157.395 121.137 162.045L125.157 164.41C125.315 164.567 125.315 164.725 125.315 164.883V202.873C125.315 203.267 125.315 203.661 125.236 203.976Z" fill="#8A92B2" />
    <path d="M76.5273 126.498V126.419C79.0495 125.316 81.4929 124.134 84.015 123.03C87.2466 121.533 90.4781 120.114 93.7096 118.616C96.153 117.513 98.6751 116.331 101.118 115.227C104.744 113.572 108.291 111.996 111.917 110.34C114.36 109.237 116.803 108.134 119.325 106.951C121.059 106.163 122.872 105.375 124.606 104.587C124.764 104.508 125 104.429 125.079 104.271C125.158 104.271 125.158 104.35 125.079 104.429V154.478C125.079 154.715 125 154.951 125.158 155.109C125 155.345 124.843 155.109 124.764 155.03C124.054 154.636 123.345 154.242 122.636 153.769C107.424 144.784 92.1333 135.72 76.9214 126.735C76.8426 126.656 76.685 126.577 76.5273 126.498Z" fill="#62688F" />
    <path d="M173.473 135.799H173.551C173.551 135.956 173.394 136.114 173.315 136.272C158.812 156.686 144.31 177.178 129.807 197.592C128.31 199.72 126.734 201.848 125.236 203.976C125.157 203.897 125.157 203.819 125.157 203.74V203.267V165.04V164.331C128.468 162.36 131.699 160.469 135.009 158.498C147.778 150.932 160.546 143.444 173.236 135.878C173.315 135.956 173.394 135.878 173.473 135.799Z" fill="#62688F" />
    <path d="M125.158 104.43V104.272V103.799V46.5778C125.158 46.3414 125.079 46.1837 125.236 45.9473C141.236 72.5088 157.236 98.9916 173.158 125.553C173.315 125.79 173.552 126.105 173.631 126.42C172.527 126.026 171.502 125.474 170.478 125.001C169.217 124.45 167.877 123.819 166.616 123.267C165.828 122.873 164.961 122.558 164.172 122.164C162.833 121.533 161.493 120.982 160.153 120.351C159.365 120.036 158.576 119.642 157.788 119.248L152.586 116.883C151.719 116.489 150.852 116.095 149.906 115.701L146.123 114.046C145.335 113.731 144.547 113.336 143.759 112.942L138.557 110.578C137.69 110.184 136.823 109.79 135.877 109.396L132.094 107.74C131.227 107.346 130.438 106.952 129.571 106.558C128.074 105.849 126.576 105.139 125 104.509C125.236 104.43 125.158 104.43 125.158 104.43Z" fill="#62688F" />
  </svg>
);

export default SvgEth;
