import { MutationObserverOptions, useMutation } from 'react-query';

import {
  queryClient,
  repayNonEthCToken,
  IRepayNonEthCTokenInput,
  RepayEthOutput,
} from 'clients/api';
import FunctionKey from 'constants/functionKey';
import { CTokenId } from 'types';
import { useCTokenContract } from 'clients/contracts/hooks';

type Options = MutationObserverOptions<
  RepayEthOutput,
  Error,
  Omit<IRepayNonEthCTokenInput, 'cTokenContract'>
>;

const useRepayNonEthCToken = (
  { cTokenId }: { cTokenId: Exclude<CTokenId, 'eth'> },
  options?: Options,
) => {
  const cTokenContract = useCTokenContract(cTokenId);

  return useMutation(
    FunctionKey.REPAY_NON_BNB_V_TOKEN,
    params =>
      repayNonEthCToken({
        cTokenContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: (...onSuccessParams) => {
        queryClient.invalidateQueries(FunctionKey.GET_V_TOKEN_BALANCES_ALL);
        queryClient.invalidateQueries(FunctionKey.GET_ASSETS_IN_ACCOUNT);
        queryClient.invalidateQueries(FunctionKey.GET_MARKETS);
        queryClient.invalidateQueries([FunctionKey.GET_V_TOKEN_BORROW_BALANCE, cTokenId]);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useRepayNonEthCToken;
