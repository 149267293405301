/** @jsxImportSource @emotion/react */
import React from 'react';
import { Formik, Form, FormikProps, FormikConfig, FormikHelpers } from 'formik';

import getValidationSchema, { FormValues } from './validationSchema';

export * from './validationSchema';

type OnSubmitProps = {
  redeemAmount: string;
  feeAmount: string;
};

export interface IAmountAndFeeFormProps extends Omit<
    FormikConfig<FormValues>,
    'onSubmit'
      | 'initialValues'
      | 'initialRedeemAmount'
      | 'initialFeeAmount'
      | 'maxRedeemAmount'
      | 'maxFeeAmount'
  > {
  onSubmit: ({ redeemAmount, feeAmount }: OnSubmitProps) => Promise<void> | void;
  children: (formProps: FormikProps<FormValues>) => React.ReactNode;
  initialRedeemAmount?: FormikConfig<FormValues>['initialValues']['redeemAmount'];
  initialFeeAmount?: FormikConfig<FormValues>['initialValues']['feeAmount'];
  maxRedeemAmount?: FormikConfig<FormValues>['initialValues']['redeemAmount'];
  maxFeeAmount?: FormikConfig<FormValues>['initialValues']['feeAmount'];
  className?: string;
  // feeAmount: string;
}

export const AmountAndFeeForm: React.FC<IAmountAndFeeFormProps> = ({
  children,
  onSubmit,
  className,
  initialRedeemAmount = '',
  initialFeeAmount = '',
  maxRedeemAmount = '',
  maxFeeAmount = '',
  // feeAmount = '',
}) => {
  const handleSubmit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    if (values.redeemAmount && values.feeAmount) {
      await onSubmit({ redeemAmount: values.redeemAmount.trim(), feeAmount: values.feeAmount.trim() });
      resetForm();
    }
  };

  return (
    <Formik
      initialValues={{
        redeemAmount: initialRedeemAmount,
        feeAmount: initialFeeAmount,
      }}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(maxRedeemAmount, maxFeeAmount)}
      validateOnMount
      validateOnChange
      initialRedeemAmount={initialRedeemAmount}
      initialFeeAmount={initialFeeAmount}
      maxRedeemAmount={maxRedeemAmount}
      maxFeeAmount={maxFeeAmount}
    >
      {formikProps => <Form className={className}>{children(formikProps)}</Form>}
    </Formik>
  );
};
