/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { formatCoinsToReadableValue, formatToReadablePercentage } from 'utilities/common';
import { formatCentsToReadableValue } from 'utilities';
import { Asset, TokenId } from 'types';
import { LayeredValues, Table, TableProps, Token } from 'components';
import { useTranslation } from 'translation';
import { useStyles as useSharedStyles } from '../styles';
import { useStyles } from './styles';


export interface ISupplyMarketTableUiProps {
  assets: Asset[];
  rowOnClick: (e: React.MouseEvent<HTMLElement>, row: TableProps['data'][number]) => void;
  collateralOnChange: (asset: Asset) => void;
}

export const SupplyMarketTable: React.FC<ISupplyMarketTableUiProps> = ({
  assets,
  rowOnClick,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const sharedStyles = useSharedStyles();

  const columns = useMemo(
    () => [
      { key: 'asset', label: t('markets.columns.asset'), orderable: false },
      { key: 'totalSupply', label: t('markets.columns.totalSupply'), orderable: false },
      { key: 'supplyBalance', label: t('Supply Balance'), orderable: true },
      { key: 'apy', label: t('Base APY'), orderable: true },
      { key: 'bdammApy', label: t('Supply APY w/ bdAMM'), orderable: true },
      { key: 'wallet', label: t('markets.columns.wallet'), orderable: true },
    ],
    [],
  );

  // Format assets to rows
  const rows: TableProps['data'] = assets.map(asset => {
    const { supplyApy } = asset;
    const totalApy = asset.bdammSupplyApy.plus(asset.supplyApy);

    return [
      {
        key: 'asset',
        render: () => <Token symbol={asset.symbol as TokenId} />,
        value: asset.id,
      },
      {
        key: 'totalSupply',
        render: () => (
          <LayeredValues
            topValue={formatCentsToReadableValue({
              value: asset.totalSupplyUsd.times(100),
              shorthand: true,
            })}
            bottomValue={formatCoinsToReadableValue({
              value: asset.totalSupply2,
              tokenId: asset.id as TokenId,
              shorthand: true,
              suffix: true,
            })}
          />
        ),
        align: 'right',
        value: asset.totalSupplyUsd.toFixed(),
      },
      {
        key: 'supplyBalance',
        render: () => (
          <LayeredValues
            topValue={formatCentsToReadableValue({
              value: asset.supplyBalance.times(asset.tokenPrice).times(100),
              shorthand: true,
            })}
            bottomValue={formatCoinsToReadableValue({
              value: asset.supplyBalance,
              tokenId: asset.id as TokenId,
              shorthand: true,
              suffix: true,
            })}
          />
        ),
        value: asset.supplyBalance.toFixed(),
      },
      {
        key: 'apy',
        render: () => formatToReadablePercentage(supplyApy),
        value: supplyApy.toNumber(),
      },
      {
        key: 'bdammApy',
        render: () => formatToReadablePercentage(totalApy),
        value: totalApy.toNumber(),
      },
      {
        key: 'wallet',
        render: () =>
          formatCoinsToReadableValue({
            value: asset.walletBalance,
            tokenId: asset.symbol as TokenId,
            shorthand: true,
            suffix: true,
          }),
        value: asset.walletBalance.toFixed(),
      },
    ];
  });

  return (
    <>
      <Table
        columns={columns}
        data={rows}
        initialOrder={{
          orderBy: 'apy',
          orderDirection: 'desc',
        }}
        rowOnClick={rowOnClick}
        rowKeyIndex={0}
        tableCss={sharedStyles.table}
        cardsCss={sharedStyles.cards}
        css={[styles.cardContentGrid]}
      />
    </>
  );
};

export default SupplyMarketTable;
