import { useQuery, QueryObserverOptions } from 'react-query';

import getCTokenBalancesAll, {
  IGetCTokenBalancesAllInput,
  IGetCTokenBalancesAllOutput,
} from 'clients/api/queries/getCTokenBalancesAll';
import { useVenusLensContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  IGetCTokenBalancesAllOutput,
  Error,
  IGetCTokenBalancesAllOutput,
  IGetCTokenBalancesAllOutput,
  [FunctionKey.GET_V_TOKEN_BALANCES_ALL, string]
>;

const useGetCTokenBalancesAll = (
  { account, vtAddresses }: Omit<IGetCTokenBalancesAllInput, 'venusLensContract'>,
  options?: Options,
) => {
  const venusLensContract = useVenusLensContract();
  return useQuery(
    [FunctionKey.GET_V_TOKEN_BALANCES_ALL, account],
    () => getCTokenBalancesAll({ venusLensContract, account, vtAddresses }),
    options,
  );
};

export default useGetCTokenBalancesAll;
