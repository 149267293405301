/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import BigNumber from 'bignumber.js';
import {
  ConnectWallet,
  EnableToken,
  Tabs,
  Modal,
  IModalProps,
  Token,
  TabContent,
} from 'components';
import {
  useRedeem,
  useRedeemUnderlying,
  useGetCTokenBalanceOf,
} from 'clients/api';
import { isAssetEnabled } from 'utilities';
import { IAmountFormProps } from 'containers/AmountForm';
import { AuthContext } from 'context/AuthContext';
import useSupply from 'clients/api/mutations/useSupply';
import useSuccessfulTransactionModal from 'hooks/useSuccessfulTransactionModal';
import { useTranslation } from 'translation';
import { TokenId, Asset } from 'types';
import { convertCoinsToWei } from 'utilities/common';
import StakingForm from './StakingForm';
import { useStyles } from '../../styles';

export interface IStakingUiProps {
  userDamm: Asset;
  isOpen: boolean;
  className?: string;
  onClose: IModalProps['handleClose'];
  dammBalance: BigNumber;
}

export interface IStakingProps {
  onSubmitSupply: IAmountFormProps['onSubmit'];
  onSubmitWithdraw: IAmountFormProps['onSubmit'];
  isSupplyLoading: boolean;
  isWithdrawLoading: boolean;
}

/**
 * The fade effect on this component results in that it is still rendered after the asset has been set to undefined
 * when closing the modal.
 */
export const StakingUi: React.FC<IStakingUiProps & IStakingProps> = ({
  className,
  isOpen,
  onClose,
  onSubmitSupply,
  onSubmitWithdraw,
  isSupplyLoading,
  isWithdrawLoading,
  userDamm,
  dammBalance,
}) => {
  const styles = useStyles();

  const { id: assetId, isEnabled, symbol } = userDamm;
  const { t } = useTranslation();

  const renderTabContent = ({
    type,
    message,
    title,
    inputLabel,
    enabledButtonKey,
    disabledButtonKey,
    calculateNewBalance,
    isTransactionLoading,
    onSubmit,
  }: {
    type: 'stake' | 'unstake';
    message: string;
    title: string;
    inputLabel: string;
    enabledButtonKey: string;
    disabledButtonKey: string;
    calculateNewBalance: (initial: BigNumber, amount: BigNumber) => BigNumber;
    isTransactionLoading: boolean;
    onSubmit: IAmountFormProps['onSubmit'];
  }) => {
    const maxInput = (() => {
      let maxInputTokens = userDamm.walletBalance;

      // If asset isn't used as collateral user can withdraw the entire supply
      // balance without affecting their borrow limit
      if (type === 'unstake' && !userDamm.collateral) {
        maxInputTokens = userDamm.supplyBalance;
      } else if (type === 'stake') {
        maxInputTokens = dammBalance;
      }
      return maxInputTokens;
    })();

    return (
      <div className={className} css={styles.container}>
        <ConnectWallet message={message}>
            <EnableToken
              assetId={userDamm.id as TokenId}
              title={title}
              isEnabled={!!isEnabled}
              ctokenAddress={userDamm.ctokenAddress}
            >
              <StakingForm
                key={`form-${type}`}
                asset={userDamm}
                type={type}
                onSubmit={onSubmit}
                inputLabel={inputLabel}
                enabledButtonKey={enabledButtonKey}
                disabledButtonKey={disabledButtonKey}
                maxInput={maxInput}
                calculateNewBalance={calculateNewBalance}
                isTransactionLoading={isTransactionLoading}
              />
            </EnableToken>
        </ConnectWallet>
      </div>
    );
  };

  const tabsContent: TabContent[] = [
    {
      title: t('Staking.withdraw'),
      content: renderTabContent({
        type: 'unstake',
        message: t('Staking.connectWalletToWithdraw'),
        title: t('Staking.enableToWithdraw', { symbol }),
        inputLabel: t('Staking.withdrawableAmount'),
        enabledButtonKey: t('Staking.withdraw'),
        disabledButtonKey: t('Staking.enterValidAmountWithdraw'),
        calculateNewBalance: (initial: BigNumber, amount: BigNumber) => initial.minus(amount),
        isTransactionLoading: isWithdrawLoading,
        onSubmit: onSubmitWithdraw,
      }),
    },
  ];

  if (isAssetEnabled(assetId)) {
    tabsContent.unshift({
      title: t('Staking.supply'),
      content: renderTabContent({
        type: 'stake',
        message: t('Staking.connectWalletToSupply'),
        title: t('Staking.enableToSupply', { symbol }),
        inputLabel: t('Staking.walletBalance'),
        enabledButtonKey: t('Staking.supply'),
        disabledButtonKey: t('Staking.enterValidAmountSupply'),
        calculateNewBalance: (initial: BigNumber, amount: BigNumber) => initial.plus(amount),
        isTransactionLoading: isSupplyLoading,
        onSubmit: onSubmitSupply,
      }),
    });
  }

  return (
    <Modal
      isOpened={isOpen}
      handleClose={onClose}
      title={assetId ? <Token symbol={assetId as TokenId} variant="h4" /> : undefined}
    >
      <Tabs tabsContent={tabsContent} />
    </Modal>
  );
};

const StakingModal: React.FC<IStakingUiProps> = props => {
  const { onClose, userDamm, ...rest } = props;
  const { account: { address: accountAddress = '' } = {} } = useContext(AuthContext);

  const { t } = useTranslation();
  const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();
  const { data: cTokenBalanceWei } = useGetCTokenBalanceOf(
    { account: accountAddress, cTokenId: 'damm' },
    { enabled: !!accountAddress },
  );
  const { mutateAsync: supply, isLoading: isSupplyLoading } = useSupply({
    asset: userDamm,
    account: accountAddress,
  });
  const { mutateAsync: redeem, isLoading: isRedeemLoading } = useRedeem({
    assetId: 'damm',
    account: accountAddress,
  });
  const { mutateAsync: redeemUnderlying, isLoading: isRedeemUnderlyingLoading } =
    useRedeemUnderlying({
      assetId: 'damm',
      account: accountAddress,
    });
  const isWithdrawLoading = isRedeemLoading || isRedeemUnderlyingLoading;
  const onSubmitSupply: IAmountFormProps['onSubmit'] = async value => {
    const supplyAmount = new BigNumber(value).times(new BigNumber(10).pow(18));
    const res = await supply({
      amountWei: supplyAmount,
    });
    onClose();

    openSuccessfulTransactionModal({
      title: t('Staking.successfulSupplyTransactionModal.title'),
      content: t('Staking.successfulSupplyTransactionModal.message'),
      amount: {
        valueWei: convertCoinsToWei({ value: new BigNumber(value), tokenId: 'damm' }),
        tokenId: 'damm',
      },
      transactionHash: res.transactionHash,
    });
  };

  const onSubmitWithdraw: IAmountFormProps['onSubmit'] = async value => {
    const amount = new BigNumber(value);
    const amountEqualsSupplyBalance = amount.eq(userDamm.supplyBalance);
    let transactionHash;
    if (amountEqualsSupplyBalance && cTokenBalanceWei) {
      const res = await redeem({ amountWei: new BigNumber(cTokenBalanceWei) });
      ({ transactionHash } = res);
      // Display successful transaction modal
    } else {
      const withdrawAmount = amount.times(new BigNumber(10).pow(18)).integerValue();
      const res = await redeemUnderlying({
        amountWei: withdrawAmount,
      });
      ({ transactionHash } = res);
    }
    onClose();
    if (transactionHash) {
      openSuccessfulTransactionModal({
        title: t('Staking.successfulWithdrawTransactionModal.title'),
        content: t('Staking.successfulWithdrawTransactionModal.message'),
        amount: {
          valueWei: convertCoinsToWei({ value: amount, tokenId: 'damm' }),
          tokenId: 'damm',
        },
        transactionHash,
      });
    }
  };
  return (
    <StakingUi
      {...rest}
      onClose={onClose}
      userDamm={userDamm}
      onSubmitSupply={onSubmitSupply}
      onSubmitWithdraw={onSubmitWithdraw}
      isSupplyLoading={isSupplyLoading}
      isWithdrawLoading={isWithdrawLoading}
    />
  );
};

export default StakingModal;
