/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import BigNumber from 'bignumber.js';
import { Paper, Typography } from '@mui/material';
import { useTranslation } from 'translation';
import { formatCentsToReadableValue } from 'utilities';
import { MarketContext } from 'context/MarketContext';
import { useStyles } from '../styles';

interface IHeaderProps {
  totalSupply: BigNumber;
  totalBorrow: BigNumber;
  availableLiquidity: BigNumber;
}

export const HeaderUi: React.FC<IHeaderProps> = ({
  totalSupply,
  totalBorrow,
  availableLiquidity,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  return (
    <Paper css={styles.marketHeader}>
      <div css={styles.row}>
        <Paper css={styles.box}>
          <Typography variant="small1" css={styles.title}>
            {t('market.totalSupply')}
          </Typography>
          <Typography variant="h3" css={styles.value}>
            { totalSupply.isNaN() ? (
              'loading...'
            ) : (
              formatCentsToReadableValue({
                value: totalSupply.times(100),
                shorthand: true,
              })
            )}
          </Typography>
        </Paper>
        <Paper css={styles.box}>
          <Typography variant="small1" css={styles.title}>
            {t('market.totalBorrow')}
          </Typography>
          <Typography variant="h3" css={styles.value}>
            { totalBorrow.isNaN() ? (
              'loading...'
            ) : (
              formatCentsToReadableValue({
                value: totalBorrow.times(100),
                shorthand: true,
              })
            )}
          </Typography>
        </Paper>
        <Paper css={styles.box}>
          <Typography variant="small1" css={styles.title}>
            {t('market.availableLiquidity')}
          </Typography>
          <Typography variant="h3" css={styles.value}>
            { availableLiquidity.isNaN() ? (
              'loading...'
            ) : (
              formatCentsToReadableValue({
                value: availableLiquidity.times(100),
                shorthand: true,
              })
            )}
          </Typography>
        </Paper>
      </div>
    </Paper>
  );
};

const Header = () => {
  const {
    protocolTotalSupply,
    protocolTotalBorrows,
    protocolLiquidity,
  } = useContext(MarketContext);
  return (
    <HeaderUi
      totalSupply={new BigNumber(protocolTotalSupply)}
      totalBorrow={new BigNumber(protocolTotalBorrows)}
      availableLiquidity={new BigNumber(protocolLiquidity)}
    />
  );
};

export default Header;
