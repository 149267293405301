/** @jsxImportSource @emotion/react */
import React from 'react';
import { Paper } from '@mui/material';
import { Asset, TokenId } from 'types';
import BorrowRepayModal from 'pages/Dashboard/Modals/BorrowRepay';
import BorrowMarketTable, { IBorrowMarketTableProps } from './BorrowMarketTable';
import { useStyles } from '../styles';

export interface IBorrowMarketUiProps {
  className?: string;
  compMarkets: Asset[];
}

export const BorrowMarketUi: React.FC<IBorrowMarketUiProps> = ({
  className,
  compMarkets,
}) => {
  const [selectedAssetId, setSelectedAssetId] = React.useState<Asset['id'] | undefined>(undefined);
  const styles = useStyles();

  const rowOnClick: IBorrowMarketTableProps['rowOnClick'] = (
    _e,
    row,
  ) => {
    setSelectedAssetId(row[0].value as TokenId);
  };

  const selectedAsset = React.useMemo(
    () =>
      [...compMarkets].find(
        marketAsset => marketAsset.id === selectedAssetId,
      ),
    [selectedAssetId, JSON.stringify(compMarkets)],
  );

  return (
    <>
      <Paper className={className} css={styles.tableContainer}>
        <BorrowMarketTable
          assets={compMarkets}
          rowOnClick={rowOnClick}
        />
      </Paper>

      {selectedAsset && (
        <BorrowRepayModal
          asset={selectedAsset}
          onClose={() => setSelectedAssetId(undefined)}
        />
      )}
    </>
  );
};

const BorrowMarket: React.FC<IBorrowMarketUiProps> = ({
  className,
  compMarkets,
}) => (
  <BorrowMarketUi
    className={className}
    compMarkets={compMarkets}
  />
);

export default BorrowMarket;
