import { useMemo } from 'react';

import { TokenId, CTokenId } from 'types';
import { useWeb3 } from 'clients/web3';
import {
  getComptrollerContract,
  getInterestModelContract,
  getPriceOracleContract,
  getTokenContract,
  getCTokenContract,
  getVenusLensContract,
  getTokenContractByAddress,
  getRedemptionContract,
} from './getters';

export const useTokenContract = <T extends TokenId>(name: T) => {
  const web3 = useWeb3();
  return useMemo(() => getTokenContract<T>(name, web3), [web3, name]);
};

export const useTokenContractByAddress = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getTokenContractByAddress(address, web3), [web3, address]);
};

export const useCTokenContract = <T extends CTokenId>(name: T) => {
  const web3 = useWeb3();
  return useMemo(() => getCTokenContract<T>(name, web3), [web3, name]);
};

export const useComptrollerContract = (target: 'core' | 'staking' = 'core') => {
  const web3 = useWeb3();
  return target === 'core'
    ? useMemo(() => getComptrollerContract('core', web3), [web3])
    : useMemo(() => getComptrollerContract('staking', web3), [web3]);
};

export const useRedemptionContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getRedemptionContract(web3), [web3]);
};

export const usePriceOracleContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getPriceOracleContract(web3), [web3]);
};

export const useInterestModelContract = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getInterestModelContract(address, web3), [web3]);
};

export const useVenusLensContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getVenusLensContract(web3), [web3]);
};

export const useBdammContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getTokenContract('bdamm', web3), [web3]);
};
