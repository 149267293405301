enum FunctionKey {
  // Queries
  GET_VAI_TREASURY_PERCENTAGE = 'GET_VAI_TREASURY_PERCENTAGE',
  GET_BLOCK = 'GET_BLOCK',
  GET_MARKETS = 'GET_MARKETS',
  GET_MARKET_HISTORY = 'GET_MARKET_HISTORY',
  GET_ASSETS_IN_ACCOUNT = 'GET_ASSETS_IN_ACCOUNT',
  GET_V_TOKEN_BALANCES_ALL = 'GET_V_TOKEN_BALANCES_ALL',
  GET_HYPOTHETICAL_LIQUIDITY = 'GET_HYPOTHETICAL_LIQUIDITY',
  GET_V_TOKEN_BALANCE = 'GET_V_TOKEN_BALANCE',
  GET_BDAMM_ACCRUED = 'GET_BDAMM_ACCRUED',
  GET_VENUS_VAI_STATE = 'GET_VENUS_VAI_STATE',
  GET_MINTED_VAI = 'GET_MINTED_VAI',
  GET_VENUS_VAI_MINTER_INDEX = 'GET_VENUS_VAI_MINTER_INDEX',
  GET_DAMM_INITIAL_INDEX = 'GET_DAMM_INITIAL_INDEX',
  GET_DAMM_REWARD = 'GET_DAMM_REWARD',
  GET_V_TOKEN_BORROW_BALANCE = 'GET_V_TOKEN_BORROW_BALANCE',
  GET_BALANCE_OF = 'GET_BALANCE_OF',
  GET_VRT_CONVERSION_END_TIME = 'GET_VRT_CONVERSION_END_TIME',
  GET_VRT_CONVERSION_RATIO = 'GET_VRT_CONVERSION_RATIO',
  GET_DAMM_WITHDRAWABLE_AMOUNT = 'GET_DAMM_WITHDRAWABLE_AMOUNT',
  GET_BORROWER_WHITELISTED = 'GET_BORROWER_WHITELISTED',
  GET_BORROWER_LIMITS = 'GET_BORROWER_LIMITS',
  DISCOUNTED_REDEMPTION = 'DISCOUNTED_REDEMPTION',
  GET_DAMM_REDEMPTION_LIQUIDITY = 'GET_DAMM_REDEMPTION_LIQUIDITY',
  GET_ALLOWANCE = 'GET_ALLOWANCE',
  // Get the USDC Price for redeeming 1 bdAMM
  GET_REDEMPTION_PRICE = 'GET_REDEMPTION_PRICE',

  // Mutations
  REQUEST_FAUCET_FUNDS = 'REQUEST_FAUCET_FUNDS',
  MINT_VAI = 'MINT_VAI',
  ENTER_MARKETS = 'ENTER_MARKETS',
  EXIT_MARKET = 'EXIT_MARKET',
  REPAY_VAI = 'REPAY_VAI',
  APPROVE_TOKEN = 'APPROVE_TOKEN',
  APPROVE_CONTRACT = 'APPROVE_CONTRACT',
  APPROVE_VRT = 'APPROVE_VRT',
  CONVERT_VRT = 'CONVERT_VRT',
  SUPPLY = 'SUPPLY',
  SUPPLY_BNB = 'SUPPLY_BNB',
  REDEEM = 'REDEEM',
  REDEEM_UNDERLYING = 'REDEEM_UNDERLYING',
  CLAIM_DAMM_REWARD = 'CLAIM_DAMM_REWARD',
  CLAIM_BORROWER_DAMM_REWARD = 'CLAIM_BORROWER_DAMM_REWARD',
  REPAY_NON_BNB_V_TOKEN = 'REPAY_NON_BNB_V_TOKEN',
  REPAY_BNB = 'REPAY_BNB',
  BORROW_V_TOKEN = 'BORROW_V_TOKEN',
  WITHDRAW_DAMM = 'WITHDRAW_DAMM',
  GET_CLAIM_MARKET_DATA = 'GET_CLAIM_MARKET_DATA',
}

export default FunctionKey;
