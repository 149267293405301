/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import BigNumber from 'bignumber.js';
import { Paper } from '@mui/material';
import { Asset } from 'types';
import { Tabs } from 'components';
import { useTranslation } from 'translation';
import { MarketContext } from 'context/MarketContext';
import { isWhitelisted } from 'utilities/common';
import SupplyMarket from './SupplyMarket';
import BorrowMarket from './BorrowMarket';
import { useStyles as useLocalStyles } from './styles';
import { useStyles as useSharedStyles } from '../styles';


export interface IMarketsProps {
  accountAddress: string;
  userTotalBorrowLimitCents: BigNumber;
  suppliedAssets: Asset[];
  supplyMarketAssets: Asset[];
  borrowingAssets: Asset[];
  borrowMarketAssets: Asset[];
}

const Markets: React.FC<IMarketsProps> = ({
  accountAddress,
  userTotalBorrowLimitCents,
  suppliedAssets,
  supplyMarketAssets,
  borrowingAssets,
  borrowMarketAssets,
}) => {
  const { t } = useTranslation();
  const sharedStyles = useSharedStyles();
  const localStyles = useLocalStyles();
  const styles = {
    ...sharedStyles,
    ...localStyles,
  };


  const tabsContent = [
    {
      title: t('dashboard.markets.tabSupply'),
      content: (
        <SupplyMarket
          css={styles.market}
          suppliedAssets={suppliedAssets}
          supplyMarketAssets={supplyMarketAssets}
          accountAddress={accountAddress}
        />
      ),
    },
    {
      name: t('markets.borrowMarketTableTitle'),
      title: t('dashboard.markets.tabBorrow'),
      content: (
        <BorrowMarket
          css={styles.market}
          borrowingAssets={borrowingAssets}
          borrowMarketAssets={borrowMarketAssets}
          userTotalBorrowLimitCents={userTotalBorrowLimitCents}
        />
      ),
    },
  ];

  const { userTotalBorrowLimit } = useContext(MarketContext);
  return (
    <>
      {isWhitelisted(userTotalBorrowLimit) ?
      <>
        <Paper css={styles.desktopViewContainer}>
          <Tabs
            css={styles.tabsHeader}
            tabsContent={tabsContent}
          />
        </Paper>
        <Paper css={styles.mobileViewContainer}>
          <h4 css={[styles.tabsHeader, styles.tabsTitle]}>{t('dashboard.markets.title')}</h4>
          <Tabs
            css={styles.tabsHeader}
            tabsContent={tabsContent}
          />
        </Paper>
      </>
      :
        <>
          <div css={[styles.row]}>
            <SupplyMarket
              css={styles.column}
              suppliedAssets={suppliedAssets}
              supplyMarketAssets={supplyMarketAssets}
              accountAddress={accountAddress}
            />
          </div>
          <Paper css={styles.mobileViewContainer}>
          <h4 css={[styles.tabsHeader, styles.tabsTitle]}>{t('dashboard.markets.title')}</h4>
            <SupplyMarket
              css={styles.market}
              suppliedAssets={suppliedAssets}
              supplyMarketAssets={supplyMarketAssets}
              accountAddress={accountAddress}
            />
          </Paper>
        </>
      }
    </>
  );
};

export default Markets;
