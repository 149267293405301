import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  return {
    container: css`
      width: 100%;
      max-width: 1200px;
      margin: auto;
    `,
    header: css`
      margin-bottom: ${theme.spacing(6)};
      border: 1px solid;
      border-color: ${theme.palette.secondary.light};
      padding: ${theme.spacing(8)};
      ${theme.breakpoints.down('md')} {
        padding: ${theme.spacing(6)};
      }
      ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(4)};
      }
      
    `,
    column: css`
      width: 100%;
      margin-bottom: ${theme.spacing(1)};
      justify-content: center;

    `,
    headerRoot: css`
      display: inline-flex;
      flex-direction: row;
      flex: 1;
      align-items: center;
      margin-bottom: ${theme.spacing(6)};
      justify-content: space-between;
      width: 100%;
      ${theme.breakpoints.down('md')} {
        flex-direction: column;
        align-items: flex-start;
      }
      :last-of-type {
        margin-bottom: 0;
      }
    `,
    row: css`
      display: inline-flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: ${theme.spacing(2)};

    `,
    whiteText: css`
      color: ${theme.palette.text.primary};
    `,
    table: css`
      display: initial;
      ${theme.breakpoints.down('sm')} {
        display: none;
      }
    `,
    tableContainer: css`
      width: 100%;
      border: 1px solid;
      border-color: ${theme.palette.secondary.light};
      padding: 0;
      margin-bottom: ${theme.spacing(8)};

      ${theme.breakpoints.down('sm')} {
        background-color: transparent;
        border: none;
      }
    `,
    itemContainer: css`
      margin-bottom: ${theme.spacing(4)};
    `,
    rowItem: css`
      display: flex;
      align-items: center;
      margin-bottom: ${theme.spacing(1)};
    `,
    itemLabel: css`
      margin-right: ${theme.spacing(2)};
    `,
    redemptionContainer: css`
      width: 100%;
      border: 1px solid;
      border-color: ${theme.palette.secondary.light};
      padding: 0;
      margin-top: ${theme.spacing(2)};
      margin-bottom: ${theme.spacing(5)};

      ${theme.breakpoints.down('sm')} {
        background-color: transparent;
        border: none;
      }
    `,
    fontWeight400: css`
      font-weight: 400;
    `,
    cards: css`
      display: none;
      ${theme.breakpoints.down('sm')} {
        display: initial;
      }
    `,
    cardContentGrid: css`
      .table__table-cards__card-content {
        grid-template-columns: 1fr 1fr minmax(${theme.spacing(30)}, 1fr);
        grid-template-rows: 1fr;
      }
    `,
    list: css`
      padding-left: 0;
      display: flex;
      margin-bottom: ${theme.spacing(6)};

      ${theme.breakpoints.down('sm')} {
        flex-direction: column;
        width: 100%;
      }
    `,
    dammIconContainer: css`
      height: ${theme.shape.iconSize.large}px;
      width: ${theme.shape.iconSize.large}px;
      margin-right: ${theme.spacing(3)};
      margin-bottom: ${theme.spacing(1)}
    `,
    dammTypeContainer: css`
      height: 25px  ;
      width: 70px ;
      padding-top: ${theme.spacing(1)};
    `,
    copyIconContainer: css`
      height: ${theme.shape.iconSize.xLarge}px;
      width: ${theme.shape.iconSize.xLarge}px;
      cursor: pointer;
      &:hover > svg {
        color: ${theme.palette.button.medium};
      }
    `,
    address: css`
      flex: 1;
      display: inline-flex;
      align-items: center;
      max-width: 80%;
      min-width: none;
      justify-content: flex-start;
      margin-right: 2rem;
      ${theme.breakpoints.down('md')} {
        max-width: 50%;
        min-width: none;
        justify-content: flex-start;
      }
    `,
    copyIcon: css`
      color: ${theme.palette.button.main};
    `,
    addressText: css`
      margin: 0 ${theme.spacing(3)};
    `,
    iconSize: `${theme.shape.iconSize.large}px`,
    iconSizeXl: `${theme.shape.iconSize.xLarge}px`,
    item: css`
      list-style: none;
      padding-left: ${theme.spacing(8)};
      padding-right: ${theme.spacing(8)};
      border-right: 1px solid ${theme.palette.secondary.light};

      ${theme.breakpoints.down('sm')} {
        border-right: none;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        width: 100%;

        & + & {
          margin-top: ${theme.spacing(2)};
        }
      }

      :first-of-type {
        border-left: none;
        padding-left: 0;
      }
      :last-child {
        border-right: none;
        padding-right: 0;
      }
    `,
    labelListItem: css`
      display: block;
      margin-bottom: ${theme.spacing(1)};
    `,
    button: css`
    display: inline-block;
    ${theme.breakpoints.down('md')} {
        margin-top: ${theme.spacing(4)};
      }
    `,
  };
};
