import { REACT_APP_ACCOUNT_API } from '../config';

interface IAccountRestServiceRegistration {
  method: 'POST';
  params: {
    account: string;
  };
}

interface IAccountRestServiceCheck {
  method: 'GET';
  params: {
    account: string;
    market: string;
  };
}

type IAccountRestServiceInput = IAccountRestServiceRegistration | IAccountRestServiceCheck;

interface APISuccessResponse {
  account: string;
  markets: string[];
}
interface APIErrorResponse {
  error: string;
}

interface ServiceErrorResponse {
  error: string;
  status: number;
}

type APIResponse = APISuccessResponse | APIErrorResponse;

type ServiceResponse = APISuccessResponse | ServiceErrorResponse;

export async function accountRestService(requestArgs: IAccountRestServiceInput): Promise<ServiceResponse> {
  const { account } = requestArgs.params;
  let path = `${REACT_APP_ACCOUNT_API}/${account}`;
  if (requestArgs.method === 'GET') {
    path += `?market=${requestArgs.params.market}`;
    console.log(`getting market ${requestArgs.params.market} data for ${account} from account API`);
  } else {
    console.log(`registering ${account} with account API`);
  }
  // const path = `${REACT_APP_ACCOUNT_API}${endpoint}`;
  let response: Response;
  try {
    response = await fetch(path, { method: requestArgs.method });
  } catch (e) {
    return ({
      error: 'error fetching account info',
      status: 0,
    });
  }

  let resJson: APIResponse;
  try {
    resJson = await response.json();
  } catch (e) {
    if (response.status !== 200) {
      return ({
        error: `error parsing account API response: ${response.body}`,
        status: response.status,
      });
    }
    return ({
      error: `error parsing API response: ${e}`,
      status: 200,
    });
  }
  if (response.status === 200) {
    if (!('account' in resJson)) {
      return ({
        error: `received malformed response: ${resJson}`,
        status: 200,
      });
    }
    return resJson;
  }
  return ({
    error: 'error' in resJson ? resJson.error : 'unknown error',
    status: response.status,
  });
}
