import * as React from 'react';
import { SVGProps } from 'react';

const SvgDcusdt = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
<svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M125.014 250.999C194.041 250.999 250 195.061 250 126.058C250 57.0552 194.041 1.11719 125.014 1.11719C55.9856 1.11719 0.0273438 57.0552 0.0273438 126.058C0.0273438 195.061 55.9856 250.999 125.014 250.999Z" fill="#EDF0F2" />
    <path d="M204.363 222.475C185.308 238.203 161.952 247.874 137.326 250.218C104.394 253.442 71.4618 243.478 45.761 222.475C20.1579 201.473 3.93604 171.19 0.613503 138.172C-1.82954 113.555 3.05655 88.84 14.6854 67.056C26.3143 45.2719 44.1974 27.3953 65.9894 15.7706C87.7813 4.14588 112.505 -0.836132 137.131 1.60603C161.757 4.04819 185.014 13.6215 204.168 29.349L178.956 60.1202C165.959 49.4724 150.03 42.8297 133.222 41.2667C116.414 39.6061 99.6057 43.0251 84.752 50.9377C69.8982 58.8503 57.7807 71.0611 49.7676 85.9095C41.8521 100.758 38.5295 117.658 40.1908 134.362C42.4384 156.83 53.481 177.539 70.9732 191.802C88.4654 206.162 110.941 212.902 133.417 210.655C150.226 208.994 166.056 202.352 179.053 191.704L204.363 222.475Z" fill={`url(#${id}__a`} />
    <path d="M125.404 228.337C181.79 228.337 227.426 182.619 227.426 126.352C227.426 69.9868 181.79 24.2695 125.404 24.2695C69.0189 24.2695 23.2852 69.9868 23.2852 126.352C23.2852 182.717 69.0189 228.337 125.404 228.337Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M134.589 129.087C134.003 129.087 131.267 129.282 125.013 129.282C120.029 129.282 116.511 129.087 115.24 129.087C95.9891 128.208 81.7218 124.984 81.7218 120.979C81.7218 116.974 96.0869 113.75 115.24 112.871V125.863C116.511 125.961 120.126 126.156 125.11 126.156C131.071 126.156 134.101 125.863 134.589 125.863V112.968C153.743 113.848 168.108 117.071 168.108 121.076C168.108 124.984 153.84 128.305 134.589 129.087V129.087ZM134.589 111.601V100.074H161.365V82.4902H88.5623V100.074H115.338V111.601C93.5461 112.578 77.2266 116.876 77.2266 121.956C77.2266 127.035 93.5461 131.333 115.338 132.31V169.334H134.687V132.31C156.381 131.333 172.701 127.035 172.701 121.956C172.701 116.876 156.381 112.578 134.589 111.601V111.601Z" fill="#283947" />
    <rect x="25" y="119" width="100" height="100" rx="50" fill={`url(#${id}__b`} />
    <path d="M87.75 202H81.5V195.75H75.25V202H69H62.75H56.5V195.75H50.25V189.5V183.25V177V170.75V164.5V158.25H56.5V152H62.75H69H75.25V158.25H81.5V152V145.75V139.5V133.25H87.75H94V139.5V145.75V152V158.25V164.5V170.75V177V183.25V189.5V195.75V202H87.75ZM69 189.5H75.25V183.25H81.5V177V170.75H75.25V164.5H69H62.75V170.75V177V183.25V189.5H69Z" fill={`url(#${id}__c`} />
    <defs>
    <linearGradient id={`${id}__a`} x1="89.8278" y1="13.8382" x2="128.981" y2="230.994" gradientUnits="userSpaceOnUse">
    <stop stopColor="#27D3A2" />
    <stop offset="1" stopColor="#9388FD" />
    </linearGradient>
    <radialGradient id={`${id}__b`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(50.6 147) rotate(71.2791) scale(76.0221)">
    <stop stopColor="#66A9F7" />
    <stop offset="1" stopColor="#0075FF" />
    </radialGradient>
    <radialGradient id={`${id}__c`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(80.0548 136.987) rotate(76.1548) scale(89.6165 97.9959)">
    <stop stopColor="#334155" />
    <stop offset="1" stopColor="#050A30" />
    </radialGradient>
    </defs>
</svg>


);

export default SvgDcusdt;
