import { IMenuItem } from './types';

export const menuItems: IMenuItem[] = [
  {
    href: '/dashboard',
    // Translation key: do not remove this comment
    // t('layout.menuItems.dashboard')
    i18nKey: 'layout.menuItems.dashboard',
    icon: 'dashboard',
  },
  {
    href: '/market',
    // Translation key: do not remove this comment
    // t('layout.menuItems.market')
    i18nKey: 'layout.menuItems.market',
    icon: 'market',
  },
  {
    href: '/boost',
    // Translation key: do not remove this comment
    // t('layout.menuItems.market')
    i18nKey: 'layout.menuItems.boost',
    icon: 'market',
  },
  {
    href: '/bonding',
    // Translation key: do not remove this comment
    // t('layout.menuItems.damm')
    i18nKey: 'layout.menuItems.bonding',
    icon: 'dammOutlined',
  },
  {
    href: '/staking',
    // Translation key: do not remove this comment
    // t('layout.menuItems.damm')
    i18nKey: 'layout.menuItems.staking',
    icon: 'dammOutlined',
  },

  // {
  //   href: '/transaction',
  //   // Translation key: do not remove this comment
  //   // t('layout.menuItems.history')
  //   i18nKey: 'layout.menuItems.history',
  //   icon: 'history',
  // },
];
