import BigNumber from 'bignumber.js';
import type { TransactionReceipt } from 'web3-core';
import { Redemption } from 'types/contracts';

export interface IDiscountedRedemptionInput {
  RedemptionContract: Redemption;
  account: string;
  amountWei: BigNumber;
  redemptionFee: BigNumber;
}

export type IDiscountedRedemptionOutput = TransactionReceipt;
const discountedRedemption = async ({
  RedemptionContract,
  account,
  amountWei,
  redemptionFee,
}: IDiscountedRedemptionInput): Promise<IDiscountedRedemptionOutput> => {
  const resp = await RedemptionContract.methods.redeem(amountWei.toFixed(), redemptionFee.toFixed()).send({ from: account });
  if (!resp.status) {
    console.error(resp.logs);
    throw new Error('Something went wrong during redemption');
  }
  return resp;
};

export default discountedRedemption;
