import { useQuery, QueryObserverOptions } from 'react-query';
import { useWeb3 } from 'clients/web3';

import getBlock, {
  GetBlockOutput,
} from 'clients/api/queries/getBlock';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  GetBlockOutput,
  Error,
  GetBlockOutput,
  GetBlockOutput,
  FunctionKey.GET_BLOCK
>;

const useGetBlock = (
  options?: Options,
) => {
  const web3 = useWeb3();
  return useQuery(
    FunctionKey.GET_BLOCK,
    () => getBlock(web3),
    {
      ...options,
      staleTime: 10000,
      cacheTime: 10000,
      refetchInterval: 10000,
    },
  );
};

export default useGetBlock;
