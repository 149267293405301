import React, { useContext } from 'react';
import BigNumber from 'bignumber.js';
import { IAmountAndFeeFormProps } from 'containers/AmountAndFeeForm';
import { Modal, IModalProps, ConnectWallet, Delimiter, EnableToken } from 'components';
import { useGetBalanceOf, useGetAllowance, queryClient } from 'clients/api';
import FunctionKey from 'constants/functionKey';
import { AuthContext } from 'context/AuthContext';
import { getContractAddress } from 'utilities';
import { Spinner } from 'components/Spinner';
import { useStyles } from './styles';
import RedemptionForm from './RedemptionForm';


export interface IRedemptionModalProps {
  // asset: Asset;
  bdammAddress: string;
  swapContractAddress: string;
  isOpen: boolean;
  onClose: IModalProps['handleClose'];
  title: string;
  // inputLabel: string;
  enabledButtonKey: string;
  disabledButtonKey: string;
  isTransactionLoading: boolean;
  onSubmit: IAmountAndFeeFormProps['onSubmit'];
}

export const RedemptionModal: React.FC<IRedemptionModalProps> = ({
  // bdammAddress,
  isOpen,
  onClose,
  title,
  // inputLabel,
  enabledButtonKey,
  disabledButtonKey,
  isTransactionLoading,
  onSubmit,
}) => {
    const styles = useStyles();
    const { account } = useContext(AuthContext);
    const { data: userBdammBalance } = useGetBalanceOf({
      tokenId: 'bdamm',
      accountAddress: account?.address || '',
    });
    const { data: bdammAllowance } = useGetAllowance({
      tokenId: 'bdamm',
      ownerAddress: account?.address || '',
      spenderAddress: getContractAddress('redemption'),
    }, {
      refetchInterval: 20000,
    });
    const { data: usdcAllowance } = useGetAllowance({
      tokenId: 'usdc',
      ownerAddress: account?.address || '',
      spenderAddress: getContractAddress('redemption'),
    }, {
      refetchInterval: 20000,
    });
    const bdammBalance = (new BigNumber(userBdammBalance || 0)).shiftedBy(-18).decimalPlaces(2);
    console.log('bdamm allowance', bdammAllowance?.toString(), 'usdc allowance', usdcAllowance?.toString());
  return (
    <Modal
      css={styles.box}
      className="damm-modal"
      isOpened={isOpen}
      handleClose={onClose}
    >
      <>
        <div css={styles.header}>
          <h3>Redemption</h3>
          <p>Swap bdAMM Tokens for dAMM Tokens at a discount.</p>
          <br />
        </div>
        <Delimiter css={styles.getRow({ isLast: true })} />
        <br />
        <ConnectWallet
          message="Connect Wallet"
        >
          { (!bdammAllowance || !usdcAllowance)
            ? <Spinner variant="small" />
            : (
              <EnableToken
                assetId="bdamm"
                title={title}
                isEnabled={Boolean(bdammAllowance && bdammAllowance.gt(0))}
                ctokenAddress={getContractAddress('redemption')}
                invalidateFn={() => queryClient.invalidateQueries(`${FunctionKey.GET_ALLOWANCE}_bdamm`)}
              >
                <EnableToken
                  assetId="usdc"
                  title={title}
                  isEnabled={Boolean(usdcAllowance && usdcAllowance.gt(0))}
                  ctokenAddress={getContractAddress('redemption')}
                  invalidateFn={() => queryClient.invalidateQueries(`${FunctionKey.GET_ALLOWANCE}_usdc`)}
                >
                  <RedemptionForm
                    onSubmit={onSubmit}
                    enabledButtonKey={enabledButtonKey}
                    disabledButtonKey={disabledButtonKey}
                    maxInput={bdammBalance}
                    isTransactionLoading={isTransactionLoading}
                  />
                </EnableToken>
              </EnableToken>
             )
          }
        </ConnectWallet>
      </>
    </Modal>
  );
};
