import * as React from 'react';
import { SVGProps } from 'react';

const SvgComp = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M125 250C194.037 250 250 194.037 250 125C250 55.9625 194.037 0 125 0C55.9625 0 0 55.9625 0 125C0 194.037 55.9625 250 125 250Z" fill="#070A0E" />
    <path fillRule="evenodd" clipRule="evenodd" d="M72.2125 166.913C68.475 164.625 66.1875 160.563 66.1875 156.188V131.763C66.1875 128.863 68.55 126.513 71.45 126.525C72.375 126.525 73.2875 126.775 74.0875 127.238L129.2 159.375C132.425 161.25 134.413 164.7 134.413 168.438V193.738C134.425 197.213 131.612 200.038 128.137 200.038C126.975 200.038 125.825 199.713 124.838 199.113L72.2125 166.913ZM154.363 120.55C157.588 122.425 159.562 125.888 159.575 129.613V180.963C159.575 182.475 158.763 183.875 157.438 184.613L145.375 191.4C145.225 191.488 145.062 191.55 144.887 191.6V163.088C144.887 159.4 142.95 155.975 139.775 154.075L91.375 125.125V92.9377C91.375 90.0377 93.7375 87.6877 96.6375 87.7002C97.5625 87.7002 98.475 87.9502 99.275 88.4127L154.363 120.55ZM178.488 82.6252C181.725 84.5002 183.712 87.9627 183.712 91.7002V166.7C183.7 168.238 182.85 169.65 181.5 170.388L170.062 176.563V124.35C170.062 120.663 168.125 117.25 164.975 115.35L115.5 85.6752V55.1502C115.5 54.2252 115.75 53.3127 116.2 52.5127C117.662 50.0127 120.875 49.1627 123.375 50.6127L178.488 82.6252Z" fill="#00D395" />
  </svg>
);

export default SvgComp;
