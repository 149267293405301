import { MutationObserverOptions, useMutation } from 'react-query';

import { queryClient, borrowCToken, IBorrowCTokenInput, BorrowCTokenOutput } from 'clients/api';
import FunctionKey from 'constants/functionKey';
import { CTokenId } from 'types';
import { useCTokenContract } from 'clients/contracts/hooks';

type Options = MutationObserverOptions<
  BorrowCTokenOutput,
  Error,
  Omit<IBorrowCTokenInput, 'cTokenContract'>
>;

const useBorrowCToken = ({ cTokenId }: { cTokenId: CTokenId }, options?: Options) => {
  const cTokenContract = useCTokenContract(cTokenId);

  return useMutation(
    FunctionKey.BORROW_V_TOKEN,
    params =>
      borrowCToken({
        cTokenContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: (...onSuccessParams) => {
        queryClient.invalidateQueries(FunctionKey.GET_V_TOKEN_BALANCES_ALL);
        queryClient.invalidateQueries(FunctionKey.GET_ASSETS_IN_ACCOUNT);
        queryClient.invalidateQueries(FunctionKey.GET_MARKETS);
        queryClient.invalidateQueries([FunctionKey.GET_V_TOKEN_BORROW_BALANCE, cTokenId]);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useBorrowCToken;
