import { MutationObserverOptions } from 'react-query';
import { Asset, CTokenId } from 'types';
import {
  queryClient,
  useSupplyNonEth,
  // useSupplyEth,
  ISupplyNonEthInput,
  ISupplyEthInput,
  SupplyEthOutput,
  SupplyEthParams,
  SupplyNonEthOutput,
  SupplyNonEthParams,
} from 'clients/api';
import FunctionKey from 'constants/functionKey';

interface IUseSupplyArgs {
  asset: Asset;
  account: string;
}

type OptionsSupplyEth = MutationObserverOptions<SupplyEthOutput, Error, SupplyEthParams>;
type OptionsSupplyNonEth = MutationObserverOptions<SupplyNonEthOutput, Error, SupplyNonEthParams>;

export type UseSupplyParams =
  | Omit<ISupplyNonEthInput, 'tokenContract' | 'assetId' | 'account'>
  | Omit<ISupplyEthInput, 'tokenContract' | 'assetId' | 'account'>;

const useSupply = (
  { asset, account }: IUseSupplyArgs,
  options?: OptionsSupplyEth | OptionsSupplyNonEth,
) => {
  const useSupplyNonEthResult = useSupplyNonEth(
    {
      assetId: asset?.id as CTokenId,
      account,
    },
    {
      ...options as OptionsSupplyNonEth,
      onSuccess: () => {
        queryClient.invalidateQueries(FunctionKey.GET_V_TOKEN_BALANCES_ALL);
      },
    },
  );
  // const useSupplyEthResult = useSupplyEth({ account }, options as OptionsSupplyEth);
  // return asset.id === 'eth' ? useSupplyEthResult : useSupplyNonEthResult;
  return useSupplyNonEthResult;
};

export default useSupply;
