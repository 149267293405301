import BigNumber from 'bignumber.js';
import { getContractAddress, getToken } from 'utilities';

const comptroller = getContractAddress('comptroller');
const bdamm = getToken('bdamm').address;

export interface IGetClaimMarketDataInput {
  venusLensContract: $TSFixMe; // @TODO: use contract type (through Typechain?)
  account: string;
  markets: string[];
  // comptroller: string?
  // bdamm: string?
}

type IGetClaimMarketDataResponse = string[];

export type IGetClaimMarketDataOutput = IGetClaimMarketDataResponse;

const getClaimMarketData = async ({
  venusLensContract,
  markets,
  account,
}: IGetClaimMarketDataInput): Promise<IGetClaimMarketDataOutput> => {
  const response = await venusLensContract.methods
    .getClaimMarketData(bdamm, comptroller, account.toLowerCase(), markets)
    .call();

  return response.map((claimableAmount: string) => (new BigNumber(claimableAmount)).shiftedBy(-18).dp(3, 1).toString());
};

export default getClaimMarketData;
