import * as React from 'react';
import { SVGProps } from 'react';

const SvgStg = (props: SVGProps<SVGSVGElement>) => (
  <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="125" cy="125" r="125" fill="black" />
    <path d="M142.85 45.0601L147.506 56.0257C156.67 77.3562 173.644 94.4807 195.124 103.644L206.09 108.3C208.944 109.502 211.197 111.305 213 113.408C207.592 74.5021 176.798 43.7081 137.893 38.3004C139.845 39.9528 141.648 42.206 142.85 45.0601ZM45.0601 108.15L56.0257 103.494C77.3562 94.3305 94.4807 77.3562 103.644 55.8755L108.3 44.9099C109.352 42.206 111.155 39.8026 113.408 38C74.5021 43.4077 43.7082 74.2017 38.3004 113.107C39.9528 111.154 42.206 109.352 45.0601 108.15ZM205.94 142.85L194.974 147.506C173.644 156.67 156.519 173.644 147.356 195.124L142.7 205.94C141.648 208.644 139.845 211.047 137.592 212.85C176.498 207.442 207.292 176.648 212.7 137.742C211.047 139.996 208.644 141.798 205.94 142.85ZM108.15 205.94L103.494 195.124C94.3305 173.794 77.3562 156.67 55.8755 147.506L44.9099 142.85C42.206 141.798 39.8026 139.996 38 137.742C43.4077 176.648 74.2017 207.442 113.107 212.85C111.004 211.047 109.352 208.644 108.15 205.94Z" fill="#999999" />
    <path d="M85.9192 117.014L91.3269 114.76C101.842 110.254 110.254 101.842 114.76 91.3269L117.014 85.9192C120.168 78.4085 130.833 78.4085 134.138 85.9192L136.391 91.3269C140.898 101.842 149.31 110.254 159.825 114.76L165.232 117.014C172.743 120.168 172.743 130.833 165.232 134.138L159.825 136.391C149.31 140.898 140.898 149.31 136.391 159.825L134.138 165.232C130.984 172.743 120.318 172.743 117.014 165.232L114.76 159.825C110.254 149.31 101.842 140.898 91.3269 136.391L85.9192 134.138C78.4085 130.833 78.4085 120.168 85.9192 117.014Z" fill="white" />
  </svg>

);

export default SvgStg;
