import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  const gap = theme.spacing(8);

  return {
    row: css`
      justify-content: space-between;
      margin-bottom: ${gap};

    `,
    column: css`
      width: 100%;
      margin-bottom: ${theme.spacing(1)};
      justify-content: center;

    `,
    container: css`
      max-width: 1200px;
      margin: auto;
    `,
    tabsHeader: css`
      display: flex;
      padding-left: ${theme.spacing(6)};
      margin-right: ${theme.spacing(6)};
      padding-top: ${theme.spacing(6)};
      width: 20%;
      /* float: right; */

      ${theme.breakpoints.down('xl')} {
        padding-top: ${theme.spacing(6)};
      }

      ${theme.breakpoints.down('sm')} {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    `,
    tabsTitle: css`
      text-align: center;
    `,
  };
};
