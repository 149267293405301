/** @jsxImportSource @emotion/react */
import React from 'react';
import {
  ResponsiveContainer,
  YAxis,
  XAxis,
  CartesianGrid,
  LineChart,
  Line,
  ReferenceLine,
  Tooltip,
} from 'recharts';

import { useTranslation } from 'translation';
import { formatToReadablePercentage } from 'utilities/common';
import TooltipContent from '../TooltipContent';
import { useStyles as useSharedStyles } from '../styles';
import { useStyles as useLocalStyles } from './styles';

export interface IInterestRateItem {
  utilizationRate: number;
  borrowApyPercentage: number;
  supplyApyPercentage: number;
}

export interface IInterestRateChartProps {
  data: IInterestRateItem[];
  currentUtilizationRate: number;
  className?: string;
}

export const InterestRateChart: React.FC<IInterestRateChartProps> = ({
  className,
  currentUtilizationRate,
  data,
}) => {
  const sharedStyles = useSharedStyles();
  const localStyles = useLocalStyles();

  const { t } = useTranslation();

  return (
    <div css={sharedStyles.container} className={className}>
      <ResponsiveContainer>
        <LineChart data={data} margin={sharedStyles.chartMargin}>
          <XAxis
            dataKey="utilizationRate"
            axisLine={false}
            tickLine={false}
            tickFormatter={formatToReadablePercentage}
            stroke={sharedStyles.accessoryColor}
            tickMargin={sharedStyles.tickMargin}
            tickCount={5}
            type="number"
            style={sharedStyles.axis}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickFormatter={formatToReadablePercentage}
            tickMargin={sharedStyles.tickMargin}
            stroke={sharedStyles.accessoryColor}
            style={sharedStyles.axis}
            tickCount={10}
          />
          <Tooltip
            isAnimationActive={false}
            cursor={sharedStyles.cursor}
            content={({ payload }) =>
              payload && payload[0] ? (
                <TooltipContent
                  items={[
                    {
                      label: t('interestRateChart.tooltipItemLabels.utilizationRate'),
                      value: formatToReadablePercentage(
                        (payload[0].payload as IInterestRateItem).utilizationRate,
                      ),
                    },
                    {
                      label: t('interestRateChart.tooltipItemLabels.borrowApy'),
                      value: formatToReadablePercentage(
                        (payload[0].payload as IInterestRateItem).borrowApyPercentage,
                      ),
                    },
                    {
                      label: t('interestRateChart.tooltipItemLabels.supplyApy'),
                      value: formatToReadablePercentage(
                        (payload[0].payload as IInterestRateItem).supplyApyPercentage,
                      ),
                    },
                  ]}
                />
              ) : null
            }
          />
          <CartesianGrid vertical={false} stroke={sharedStyles.gridLineColor} />
          <Line
            type="monotone"
            dataKey="borrowApyPercentage"
            stroke={localStyles.lineBorrowApyColor}
            strokeWidth={sharedStyles.lineStrokeWidth}
            isAnimationActive={false}
            activeDot={localStyles.lineActiveDot}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="supplyApyPercentage"
            stroke={localStyles.lineSupplyApyColor}
            strokeWidth={sharedStyles.lineStrokeWidth}
            activeDot={localStyles.lineActiveDot}
            isAnimationActive={false}
            dot={false}
          />
          <ReferenceLine
            x={currentUtilizationRate}
            stroke={localStyles.referenceLineColor}
            // Note: we can not use the spread operator to extend
            // styles.referenceLineLabel because its type is not accepted for
            // that
            label={Object.assign(localStyles.referenceLineLabel || {}, {
              value: t('interestRateChart.currentUtilizationRateLabelValue', {
                percentage: formatToReadablePercentage(currentUtilizationRate),
              }),
            })}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
