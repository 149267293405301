import { BASE_BSC_SCAN_URL } from 'config';
import { CTokenId, TokenId } from 'types';
import getCErcToken from './getCErcToken';
import getToken from './getToken';

export type UrlType = 'address' | 'token' | 'tx' | 'market';

export const generateEtherScanUrl = <T extends UrlType = 'address'>(
  identifier: T extends 'token' ? TokenId : string,
  urlType?: T,
) => {
  const safeUrlType = urlType || 'address';

  let suffix: string = identifier;
  if (safeUrlType === 'token') {
    suffix = getToken(identifier as TokenId).address;
  } else if (safeUrlType === 'market') {
    return getCErcToken(identifier as CTokenId).address;
  }

  return `${BASE_BSC_SCAN_URL}/${safeUrlType}/${suffix}`;
};

export default generateEtherScanUrl;
