import { MutationObserverOptions, useMutation } from 'react-query';
import {
  discountedRedemption,
  IDiscountedRedemptionOutput,
  IDiscountedRedemptionInput,
  // queryClient,
} from 'clients/api';
import FunctionKey from 'constants/functionKey';
import { useRedemptionContract } from 'clients/contracts';

const useDiscountedRedemption = (
  { account }: { account: string },
  options?: MutationObserverOptions<
    IDiscountedRedemptionOutput,
    Error,
    Omit<IDiscountedRedemptionInput, 'RedemptionContract' | 'account'>
  >,
) => {
  const RedemptionContract = useRedemptionContract();

  return useMutation(
    FunctionKey.DISCOUNTED_REDEMPTION,
    (params: Omit<IDiscountedRedemptionInput, 'RedemptionContract' | 'account'>) =>
      discountedRedemption({
        RedemptionContract,
        account,
        ...params,
      }),
    {
      ...options,
      onSuccess: (...onSuccessParams) => {
        // DAMM:TODO invalidate related quries
        // queryClient.invalidateQueries(FunctionKey.XXX);
        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useDiscountedRedemption;
