import React from 'react';
import noop from 'noop-ts';
import copyToClipboard from 'copy-to-clipboard';

import { Connector, useAuth } from 'clients/web3';
import { toast } from 'components/Toast';
import { AuthModal } from 'components/AuthModal';
import { TermsModal } from 'components/TermsModal';

export interface IAccount {
  address: string;
  connector?: Connector;
}

export interface IAuthContextValue {
  login: (connector: Connector) => Promise<void>;
  logOut: () => void;
  openAuthModal: () => void;
  closeAuthModal: () => void;
  openTermsModal: () => void;
  closeTermsModal: () => void;
  account?: IAccount;
}

export const AuthContext = React.createContext<IAuthContextValue>({
  login: noop,
  logOut: noop,
  openAuthModal: noop,
  closeAuthModal: noop,
  openTermsModal: noop,
  closeTermsModal: noop,
});

export const AuthProvider: React.FC = ({ children }) => {
  const [isAuthModalOpen, setIsAuthModalOpen] = React.useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = React.useState(false);


  const { login, accountAddress, logOut, connectedConnector } = useAuth();

  const openTermsModal = () => setIsTermsModalOpen(true);
  const closeTermsModal = () => setIsTermsModalOpen(false);

  const openAuthModal = () => setIsAuthModalOpen(true);
  const closeAuthModal = () => setIsAuthModalOpen(false);

  const handleLogin = (connector: Connector) => {
    login(connector).then(closeAuthModal);
  };

  const handleCopyAccountAddress = (accountAddressToCopy: string) => {
    copyToClipboard(accountAddressToCopy);

    toast.success({
      message: 'Wallet address copied to clipboard',
    });
  };

  const account = accountAddress
    ? {
        address: accountAddress,
        connector: connectedConnector,
      }
    : undefined;

  return (
    <AuthContext.Provider
      value={{
        account,
        login,
        logOut,
        openTermsModal,
        closeTermsModal,
        openAuthModal,
        closeAuthModal,
      }}
    >
      <TermsModal
        isOpen={isTermsModalOpen}
        onClose={closeTermsModal}
      />
      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={closeAuthModal}
        account={account}
        onLogOut={logOut}
        onLogin={handleLogin}
        onCopyAccountAddress={handleCopyAccountAddress}
      />

      {children}
    </AuthContext.Provider>
  );
};
