import sample from 'lodash/sample';

export enum BscChainId {
  'MAINNET' = 1,
  'TESTNET' = 31337,
  'GOERLI' = 5,
  'FORK' = 31336,
}

// export const CHAIN_ID: BscChainId = process.env.REACT_APP_CHAIN_ID
//   ? parseInt(process.env.REACT_APP_CHAIN_ID, 10)
//   : BscChainId.MAINNET;

export const CHAIN_ID: BscChainId = parseInt(process.env.REACT_APP_CHAIN_ID || '5', 10);

export const isOnTestnet = CHAIN_ID !== BscChainId.MAINNET;

const BASE_BSC_SCAN_URLS = {
  [BscChainId.MAINNET]: 'https://etherscan.io/',
  [BscChainId.TESTNET]: 'https://ropsten.etherscan.io/',
  [BscChainId.GOERLI]: 'https://goerli.etherscan.io/',
  [BscChainId.FORK]: 'https://etherscan.io/',
};

const API_ENDPOINT_URLS = {
  [BscChainId.MAINNET]: process.env.REACT_APP_MAINNET_API || 'http://api.damm.followyourhart.ca:3001',
  // [BscChainId.TESTNET]: 'http://api-damm.followyourhart.ca:3001',
  [BscChainId.TESTNET]: 'http://localhost:3001',
  [BscChainId.GOERLI]: process.env.REACT_APP_GOERLI_API || 'http://api.damm.followyourhart.ca:3001',
  [BscChainId.FORK]: process.env.REACT_APP_MAINNET_API || 'http://api.damm.followyourhart.ca:3001',
};

export const RPC_URLS: {
  [key: string]: string[];
} = {
  [BscChainId.MAINNET]: [
    'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  ],
  [BscChainId.TESTNET]: [
    // 'https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    'http://localhost:8545',
  ],
  [BscChainId.GOERLI]: [
    // 'https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  ],
  [BscChainId.FORK]: ['http://localhost:8545'],
};

export const RPC_URL = sample(RPC_URLS[CHAIN_ID]) as string;

export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[CHAIN_ID];

export const API_ENDPOINT_URL = API_ENDPOINT_URLS[CHAIN_ID];

export const LS_KEY_CONNECTED_CONNECTOR = 'connected-connector';

export const VTOKEN_DECIMALS = 8;

// Note: this is a temporary fix. Once we start refactoring this part we should
// probably fetch the treasury address using the Comptroller contract
// const TREASURY_ADDRESSES = {
//   1: '0xF322942f644A996A617BD29c16bd7d231d9F35E9',
//   // When querying comptroller.treasuryAddress() we get an empty address back,
//   // so for now I've let it as it is
//   4: '0x0000000000000000000000000000000000000000',
// };

// export const TREASURY_ADDRESS = TREASURY_ADDRESSES[CHAIN_ID];

export const DAMM_MEDIUM_URL = 'https://medium.com/@joshua_baker/introducing-damm-4978bc4b8eff';
export const DAMM_DISCORD_URL = 'https://discord.com/invite/zn8PkeTyGw';
export const DAMM_TWITTER_URL = 'https://twitter.com/dammfinance';
export const DAMM_GITHUB_URL = 'https://github.com/';

// DAMM:TODO: update
export const DAMM_TERMS_OF_SERVICE_URL = 'https://damm.finance/terms';
export const DAMM_PRIVACY_POLICY_URL = 'https://damm.finance/privacy';

export const SAFE_BORROW_LIMIT_PERCENTAGE = 100;
export const REACT_APP_ACCOUNT_API = process.env.REACT_APP_ACCOUNT_API || '';
