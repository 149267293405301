import * as React from 'react';
import { SVGProps } from 'react';

const SvgDcvx = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="125" cy="125" r="125" fill="#D9D9D9" />
    <path d="M164.305 141.856H147.475V158.713H164.305V141.856Z" fill="#FF5A5A" />
    <path d="M147.475 158.699H136.237V175.543H147.475V158.699Z" fill="#FF5A5A" />
    <path d="M125 250C194.036 250 250 194.036 250 125C250 55.9644 194.036 0 125 0C55.9644 0 0 55.9644 0 125C0 194.036 55.9644 250 125 250Z" fill="white" />
    <path d="M198.018 108.157V74.4952H181.162V57.6012H147.462V40.7578H113.763V57.6012H80.0758V74.4952H63.2197V175.505H80.0632V192.361H113.763V209.205H147.45V192.399H181.162V175.505H198.018V141.844H164.318V158.7H147.462V175.505H113.763V158.7H96.9192V91.3007H113.763V74.4952H147.462V91.3007H164.318V108.157H198.018Z" fill="#3A3A3A" />
    <path d="M164.318 91.3135H153.081V108.157H164.318V91.3135Z" fill="#1682FE" />
    <path d="M147.462 74.457H136.225V91.3005H147.462V74.457Z" fill="#1682FE" />
    <path d="M113.775 40.7578H102.538V57.6012H113.775V40.7578Z" fill="#1682FE" />
    <path d="M113.763 192.399H102.525V209.243H113.763V192.399Z" fill="#FF5A5A" />
    <path d="M80.0635 175.543H68.8262V192.386H80.0635V175.543Z" fill="#F4BB3B" />
    <path d="M63.2198 125H51.9824V175.543H63.2198V125Z" fill="#F4BB3B" />
    <path d="M63.2198 74.457H51.9824V125H63.2198V74.457Z" fill="#60D8A4" />
    <path d="M80.0762 57.6133H68.8389V74.4567H80.0762V57.6133Z" fill="#1682FE" />
    <rect x="25" y="121" width="100" height="100" rx="50" fill={`url(#${id}__a`} />
    <path d="M87.75 204H81.5V197.75H75.25V204H69H62.75H56.5V197.75H50.25V191.5V185.25V179V172.75V166.5V160.25H56.5V154H62.75H69H75.25V160.25H81.5V154V147.75V141.5V135.25H87.75H94V141.5V147.75V154V160.25V166.5V172.75V179V185.25V191.5V197.75V204H87.75ZM69 191.5H75.25V185.25H81.5V179V172.75H75.25V166.5H69H62.75V172.75V179V185.25V191.5H69Z" fill={`url(#${id}__b`} />
    <defs>
      <radialGradient
        id={`${id}__a`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(50.6 149) rotate(71.2791) scale(76.0221)"
      >
        <stop stopColor="#66A9F7" />
        <stop offset={1} stopColor="#0075FF" />
      </radialGradient>
      <radialGradient
        id={`${id}__b`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(80.0548 138.253) rotate(75.4706) scale(85.4812 97.7006)"
      >
        <stop stopColor="#334155" />
        <stop offset={1} stopColor="#050A30" />
      </radialGradient>
    </defs>
  </svg>
);

export default SvgDcvx;
