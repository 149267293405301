import * as React from 'react';
import { SVGProps } from 'react';

const SvgDammtype = (props: SVGProps<SVGSVGElement>) => (

  <svg width="76" height="17" viewBox="0 0 76 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9.5 17H8V15.5H6.5V17H5H3.5H2V15.5H0.5V14V12.5V11V9.5V8V6.5H2V5H3.5H5H6.5V6.5H8V5V3.5V2V0.5H9.5H11V2V3.5V5V6.5V8V9.5V11V12.5V14V15.5V17H9.5ZM5 14H6.5V12.5H8V11V9.5H6.5V8H5H3.5V9.5V11V12.5V14H5ZM24.5 17H23V15.5V14V12.5V11H21.5H20H18.5H17V12.5V14V15.5V17H15.5H14V15.5V14V12.5V11V9.5V8V6.5V5V3.5H15.5V2H17V0.5H18.5H20H21.5H23V2H24.5V3.5H26V5V6.5V8V9.5V11V12.5V14V15.5V17H24.5ZM21.5 8H23V6.5V5H21.5V3.5H20H18.5V5H17V6.5V8H18.5H20H21.5ZM42.5 17H41V15.5V14V12.5V11V9.5V8V6.5H39.5V8H38V9.5H36.5H35V8H33.5V6.5H32V8V9.5V11V12.5V14V15.5V17H30.5H29V15.5V14V12.5V11V9.5V8V6.5V5V3.5V2V0.5H30.5H32V2H33.5V3.5H35V5H36.5H38V3.5H39.5V2H41V0.5H42.5H44V2V3.5V5V6.5V8V9.5V11V12.5V14V15.5V17H42.5ZM60.5 17H59V15.5V14V12.5V11V9.5V8V6.5H57.5V8H56V9.5H54.5H53V8H51.5V6.5H50V8V9.5V11V12.5V14V15.5V17H48.5H47V15.5V14V12.5V11V9.5V8V6.5V5V3.5V2V0.5H48.5H50V2H51.5V3.5H53V5H54.5H56V3.5H57.5V2H59V0.5H60.5H62V2V3.5V5V6.5V8V9.5V11V12.5V14V15.5V17H60.5Z" fill="white" />
  </svg>
);

export default SvgDammtype;
