/** @jsxImportSource @emotion/react */
import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import Path from 'constants/path';
import { getToken } from 'utilities';
import { CTokenId } from 'types';
import { Icon } from '../../../components/Icon';
import { useStyles } from './styles';

const MarketTitle: React.FC = () => {
  const styles = useStyles();
  const { pathname } = useLocation();

  // Handle special case of Market Details page
  const cTokenId = pathname.substring(pathname.lastIndexOf('/') + 1) as CTokenId;
  const token = getToken(cTokenId);

  return (
    <Link to={Path.MARKET} css={styles.backButton}>
      <Icon name="chevronLeft" css={styles.backButtonChevronIcon} />
      <Icon name={cTokenId} css={styles.backButtonTokenIcon} />
      <h3 css={styles.backButtonTokenSymbol}>{token.symbol}</h3>
    </Link>
  );
};

export default MarketTitle;
