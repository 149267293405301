import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { queryClient } from 'clients/api';
import { Web3Wrapper } from 'clients/web3';
import { AuthProvider } from 'context/AuthContext';
import { SuccessfulTransactionModalProvider } from 'context/SuccessfulTransactionModalContext';
import { store } from 'core';
import { Layout, ResetScrollOnRouteChange } from 'components';
import { init as initTranslationLibrary } from 'translation';
import Dashboard from 'pages/Dashboard';
import Blocked from 'pages/Blocked';
import Boost from 'pages/Boost';
import Bonding from 'pages/Bonding';
import Market from 'pages/Market';
import MarketDetails from 'pages/MarketDetails';
// import Transaction from 'containers/Main/Transaction';
import Theme from 'theme';
import { MarketContextProvider } from 'context/MarketContext';
import { MuiThemeProvider } from 'theme/MuiThemeProvider/MuiThemeProvider';
import Path from 'constants/path';
import 'assets/styles/App.scss';
import Staking from './Staking';

initTranslationLibrary();

const App = () => (
  <Theme>
    <Web3Wrapper>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <MuiThemeProvider>
            <BrowserRouter>
              <AuthProvider>
                <MarketContextProvider>
                  <SuccessfulTransactionModalProvider>
                    <ToastContainer />
                    <Layout>
                      <Switch>
                        <ResetScrollOnRouteChange>
                          <Route
                            exact
                            path={Path.BONDING}
                            component={Bonding}
                          />
                          <Route exact path={Path.BLOCKED} component={Blocked} />
                          <Route exact path={Path.DASHBOARD} component={Dashboard} />
                          <Route
                            exact
                            path={Path.MARKET}
                            component={Market}
                          />
                          <Route
                            exact
                            path={Path.MARKET_DETAILS}
                            component={MarketDetails}
                          />
                          <Route
                            exact
                            path={Path.BOOST}
                            component={Boost}
                          />
                          <Route
                            exact
                            path={Path.STAKING}
                            component={Staking}
                          />
                          {/* <Route exact path={Path.TRANSACTION} component={Transaction} /> */}
                          {/*  <Route
                            exact
                            path={Path.CONVERT_VRT}
                          /> */}
                          <Redirect from={Path.ROOT} to={Path.DASHBOARD} />
                        </ResetScrollOnRouteChange>
                      </Switch>
                    </Layout>
                  </SuccessfulTransactionModalProvider>
                </MarketContextProvider>
              </AuthProvider>
            </BrowserRouter>
          </MuiThemeProvider>
        </Provider>
      </QueryClientProvider>
    </Web3Wrapper>
  </Theme>
);

export default App;
